import { IRoute } from "Model/Order/Route";
import {
    ROUTE_DETAILS
} from "../ActionTypes/ActionTypes";
import { RESET_ROUTE_STATE } from "Redux/ActionTypes/ActionTypes";

export function updateRouteDetails(data: IRoute[]) {
    return {
        type: ROUTE_DETAILS,
        data
    };
}

export function resetRouteState() {
    return {
        type: RESET_ROUTE_STATE
    };
}
