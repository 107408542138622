
import {
  OverviewHeader,
  // list
  ID,
  Name,
  //revenue
  Quantity,
  UOM,
  UnitPrice,
  Currency,
  Amount,
  BillingCurrency,
  ExRate,
  Tax,
  SalesTaxAmt,
  BillAmount,
  AdjBillAmount,
  //cost
  Supplier,
  EstUnitCost,
  EstCurrency,
  EstExRate,
  EstTaxType,
  EstTax,
  EstAmt,
  EstAmtTax,
  // operating income 
  OperatingIncome,
  // eye_share
  EsActualCost,
  CostUOM,
  CostQty,
} from 'Containers/JobGrid/Jobs.styles';
import { Color } from 'Utils/Color';

type Props = {
  service: any,
  charge?: any;
  index: number
};

const ServiceViewMode = (props: Props) => {
  const {
    service,
    charge,
    index } = props;
  const styleClass = "text-left text-xs font-medium text-gray-500 tracking-wider bg-gray-50";

  return (
    <OverviewHeader className={styleClass} id="stickyPosition">
      <ID isService>
        {index + 1}
      </ID>
      <Name isService>
        <div>{service?.Name}</div>
      </Name>
      <OperatingIncome isService groupColor={Color.operatingIncome} />
      <UOM isService />
      <Quantity isService />
      <UnitPrice isService groupColor={Color.revenue} />
      <Currency isService groupColor={Color.revenue}>
        {service?.PriceCurrencyCode}
      </Currency>
      <Amount isService groupColor={Color.revenue} />
      <BillingCurrency isService groupColor={Color.revenue}>
        {service?.BillingCurrencyCode}
      </BillingCurrency>
      <ExRate isService groupColor={Color.revenue}>
        {service?.BillingExchangeRate}
      </ExRate>
      <Tax isService groupColor={Color.revenue} />
      <SalesTaxAmt isService groupColor={Color.revenue} />
      <BillAmount isService groupColor={Color.revenue} />
      <AdjBillAmount isService groupColor={Color.revenue} />
      <Supplier isService groupColor={Color.cost}>
        {service?.SupplierName}
      </Supplier>
      <EstUnitCost isService groupColor={Color.cost} />
      <EstCurrency isService groupColor={Color.cost}>
        {service?.CostCurrencyCode}
      </EstCurrency>
      <EstExRate isService groupColor={Color.cost}>{service?.CostExchangeRate}</EstExRate>
      <EstTaxType isService groupColor={Color.cost} />
      <EstTax isService groupColor={Color.cost} />
      <EstAmt isService groupColor={Color.cost} />
      <EstAmtTax isService groupColor={Color.cost} />
      <EsActualCost isService groupColor={Color.cost} />
    </OverviewHeader>
  )
};

export default ServiceViewMode;
