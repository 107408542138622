import styled from "styled-components";

interface IStylesProps {
  isHeader?: boolean;
  isService?: boolean;
  isSticky?: boolean;
  groupColor?: any;
  colour?: any;
  showBorder?: boolean;
  isSelected?: boolean;
}

export const Row = styled.div<IStylesProps>`
  display: flex;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  height: 36px;
  background-color: ${(props) => (props.isHeader ? '#f4f5f8' : props.isService ? "#FFF" : '#FFF')};
  border-bottom: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
`;

export const Details = styled.div<IStylesProps>`
 display:flex;
`;

export const GridView = styled.div<IStylesProps>`
  height: 58vh;
  padding: 12px 0px;
`;

export const Break = styled.div<IStylesProps>`
  flex-basis: 100%;
  height: 0;
`;

export const Overview = styled.div`
  padding:10px;
  width: 50vw;
  background-color: white;
  height: 32vh;
  margin: 5px;
  z-index: 0;
`;

export const OverviewGrid = styled.div`
  overflow: auto;
  padding-bottom:12px;
  width: 100%;
  height: calc(58vh - 140px);
`;

export const GridOptions = styled.div`
    display: flex;
    align-items: center;
    margin-bottom:12px;
`;
export const Typography = styled.h2`
  text-align: left;
  margin-bottom: 12px;
`;

export const GroupOptions = styled.div<IStylesProps>`
   padding: 0px 8px;
   height: 24px;
   margin-right : 12px;
   background-color: ${(props) => (props.colour ? props.colour : '')};
   cursor: pointer;
   border-radius: 4px;
   display: flex;
   justify-content: center;
   align-items: center;
   span{
     padding: 0px 4px;
     font-size: 12px;
     font-weight: 500;
     text-transform: uppercase;
   }
`;

export const Layout = styled.div<IStylesProps>`
  width: 100vw;
  height: 100vh;
  padding: 16px;
  overflow-x: hidden;
`;
export const VisibilityGridWrapper = styled.div<IStylesProps>`
   height: 30vh;
   overflow-y: auto;
`;
export const DetailsWrapper = styled.div<IStylesProps>`
   height: 30vh;
`;

export const Section = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px 12px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  
`;
export const VisibilitySection = styled.div`
    display: flex;
    align-items: center;
    width: auto;
    margin: 24px 12px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  
`;

export const Left = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
`;

export const Right = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
`;


export const Label = styled.div`
  font-size: 12px;
  width: 100px;
`;

export const Title = styled.div<IStylesProps>`
  font-size: 16px;
`;

export const ProductTabOverview = styled.div<IStylesProps>`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const TabItem = styled.div<IStylesProps>`
  padding: 4px 12px;
  background-color: #ffffff;
  border: ${(props) => (props.isSelected ? '2px solid blue' : '2px solid #d8d8d8')}; 
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  margin: 0px 8px;
`;

export const Name = styled(Row)`
  min-width: 200px;
  max-width: 200px;
  z-index: 1;
  position: sticky;
  left: 60px;
`;

export const MarginLeft = styled.div<IStylesProps>`
  display: flex;
  margin-left: auto;
  align-items: center;
  span{
    color: red;
    margin-right: 12px;
    font-size: 14px;
  }
`;
export const FooterOverview = styled.div`
    display: flex;
    align-items: center;
    margin:12px 0px;
`;

export const OverviewHeader = styled.div`
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index:100;
`;

export const Group = styled.div<IStylesProps>`
  z-index: ${(props) => (props.isSticky ? '1' : '')};
  position: ${(props) => (props.isSticky ? 'sticky' : '')};
  left: ${(props) => (props.isSticky ? '0px' : '')};
  background-color: ${(props) => (props.groupColor ? props.groupColor : '#C1C1C1')};
`;

export const SecondaryGroup = styled(Group)`
left: ${(props) => (props.isSticky ? '60px' : '')};
`;

export const NameHeader = styled(Row)`
  min-width: 200px;
`;
export const EmptyResult = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  font-size: 16px;
`;