
import {
    QUOTATION_LIST_LOADING,
    UPDATE_QUOTATION_LIST
} from '../ActionTypes/QuotationList';

var initialState = {
    quotationList: null,
    isLoading: true
}

export const quotationList = (state = initialState, action: any) => {
    switch (action.type) {

        case QUOTATION_LIST_LOADING:
            return {
                ...state,
                isLoading: action.value
            };

        case UPDATE_QUOTATION_LIST:
            return {
                ...state,
                quotationList: action.payload,
            };

        default:
            return state
    }
}