import CargoDetails from "Containers/CargoDetails/CargoDetails";
import { CargoDetail } from "Model/Order/CargoDetail";

export const setPackageValue = (obj: any, key: string, event: any) => {
  switch (key) {
    case 'quantity':
      obj.Quantity = event.target.value
      break;
    case 'grossWeight':
      obj.GrossWeight = event.target.value
      break;
    case 'wUOM':
      if (event) {
        obj.WeightUomCode = event.code
        obj.WeightUomName = event.name
      } else {
        obj.WeightUomCode = ""
        obj.WeightUomName = ""
      }
      break;
    case 'length':
      obj.Length = event.target.value
      break;
    case 'width':
      obj.Width = event.target.value
      break;
    case 'Height':
      obj.DangerousCargo = event.target.value
      break;

    case 'dUOM':
      if (event) {
        obj.DimensionUomCode = event.code
        obj.DimensionUomName = event.name
      } else {
        obj.DimensionUomCode = ""
        obj.DimensionUomName = ""
      }
      break;

    case 'vUOM':
      if (event) {
        obj.VolumeUomCode = event.code
        obj.VolumeUomName = event.name
      } else {
        obj.VolumeUomCode = ""
        obj.VolumeUomName = ""
      }
      break;

    case 'gVolume':
      obj.GrossVolume = event.target.value
      break;

    default:
      break;
  }
  return obj;
}

export const setCargoDetailsValue = (obj: CargoDetail, key: string, event: any) => {
  switch (key) {
    case 'commodityType':
      obj.CommodityType = event ? event.code : ''
      break;
    case 'commodityName':
      obj.CommodityName = event.target.value
      break;
    case 'hsCode':
      obj.HSCode = event ? event.code : ''
      obj.HSName = event ? event.name : ''
      break;
    case 'readinessDate':
      obj.ReadinessDate = event.target.value
      break;
    case 'dangerousYes':
      obj.DangerousCargo = event.target.value
      break;
    case 'dangerousNo':
      obj.DangerousCargo = event.target.value
      break;
    default:
  }
  return obj
}

export const setContainerValue = (obj: any, key: string, event: any) => {
  switch (key) {
    case 'cargoWeight':
      obj.GrossWeight = event.target.value
      break;
    case 'containerType':
      if (event) {
        obj.ContainerTypeCode = event.code
        obj.ContainerTypeName = event.name
      } else {
        obj.ContainerTypeCode = ""
        obj.ContainerTypeName = ""
      }
      break;
    case 'quantity':
      obj.Quantity = event.target.value
      break;
    default:
  }
  return obj
}

export const setBulkValue = (obj: any, key: string, event: any) => {
  switch (key) {
    case 'cargoType':
      if (event) {
        obj.CargoTypeCode = event.code
        obj.CargoTypeName = event.name
      } else {
        obj.CargoTypeCode = ""
        obj.CargoTypeName = ""
      }
      break;
    case 'cargoOperation':
      if (event) {
        obj.WeightUomCode = event.code
        obj.WeightUomName = event.name
      } else {
        obj.WeightUomCode = ""
        obj.WeightUomName = ""
      }
      break;
    case 'grossWeight':
      obj.GrossWeight = event.target.value
      break;
    default:
  }
  return obj
}