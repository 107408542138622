
import {
  Overview,
  Section,
  Label,
  Left,
  Right
} from './edit.styles';
import Dropdown from 'Controls/Dropdown';
import DatePicker from 'Controls/DateRange';

import { formatBillingPartyCode } from 'Utils/Generic';
import { formatQuoteBillingPartyAddress } from 'Utils/QuotationGrid';
import { getCustomOption, getValueContainer } from 'Components/BillingParty/DropdownComponents';
import { IBillingParty } from 'Model/Job/types';


type IProps = {
  onEditServices: (key: any, value: any) => void;
  billingParties: any;
  taxList: any[];
};

const EditForm = (props: IProps) => {
  const {
    onEditServices,
    billingParties,
    taxList,
  } = props

  let billingPartiesData: any[] = [];

  billingParties?.forEach((bp: any) => {
    billingPartiesData.push({
      ...bp,
      code: formatBillingPartyCode(bp.code, bp.postalAddress),
      operationalProcessPartyId: bp.operationalProcessPartyId
    });
  })

  const ValueContainer = getValueContainer<IBillingParty>();
  const CustomOption = getCustomOption<IBillingParty>(formatQuoteBillingPartyAddress);

  return (
    <Overview>
      <Section>
        <Left>
          <Label>Tax Type</Label>
          <Dropdown
            id="taxType"
            label={'Name'}
            isLookup={false}
            dropdownOptions={taxList}
            dropdownLabel={'Name'}
            dropdownValue={'Code'}
            appendParams={false}
            style={{ width: 199 }}
            placeHolder="Tax Type"
            onChangeDropdown={onEditServices}
            isMenuPortalTarget={'true'}
            domId={'stickyPosition'}
          />
        </Left>
        <Right>
          <Label>Billing Party</Label>
          <Dropdown
            customComponents={{ Option: CustomOption, ValueContainer }}
            id="billingParty"
            onChangeDropdown={onEditServices}
            dropdownOptions={billingParties}
            dropdownLabel={'Node'}
            dropdownValue={'Code'}
            appendParams={false}
            useDebounce={true}
            placeHolder="Select Party"
            isMenuPortalTarget={'true'}
            domId={'stickyPosition'}
            style={{ width: 199 }}
            dropdownStyle={{ menuPortal: { width: 400 }, control: { boxShadow: "none" } }}
            removeIsClear={true}
          />
        </Right>
      </Section>
      <Section>
        <Left>
          <Label>Start date</Label>
          <DatePicker
            id="startDate"
            type={"dateTime"}
            width={199}
            timeFormat={'HH:mm'}
            format={'dd/MM/yyyy HH:mm'}
            onChange={onEditServices} />
        </Left>
        <Right>
          <Label>End date</Label>
          <DatePicker
            id="endDate"
            type={"dateTime"}
            width={199}
            timeFormat={'HH:mm'}
            format={'dd/MM/yyyy HH:mm'}
            onChange={onEditServices} />
        </Right>
      </Section>
      <Section>
        <Left>
          <Label>Price currency</Label>
          <Dropdown
            id={"priceCurrency"}
            isLookup={false}
            dropdownLabel={'code'}
            dropdownValue={'code'}
            appendParams={false}
            style={{ width: 199 }}
            isMenuPortalTarget={'true'}
            domId={'stickyPosition'}
            placeHolder="Select currency"
            url={`/mdm-currency?searchText=&code=`}
            onChangeDropdown={onEditServices}
          />
        </Left>
        <Right>
          <Label>Cost currency</Label>
          <Dropdown
            id={"costCurrency"}
            isLookup={false}
            dropdownLabel={'code'}
            dropdownValue={'code'}
            appendParams={false}
            style={{ width: 199 }}
            isMenuPortalTarget={'true'}
            domId={'stickyPosition'}
            placeHolder="Select currency"
            url={`/mdm-currency?searchText=&code=`}
            onChangeDropdown={onEditServices}
          />
        </Right>
      </Section>
    </Overview>
  );
};

export default EditForm;
