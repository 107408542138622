import React from 'react';
import { connect } from 'react-redux';
import CrewLine from './CrewLine';
import {
  createCrewRow,
  deleteCrewRow,
  updateCrewDetails,
  updateCrewActivities
} from 'Redux/Actions/Crew';
import PegButton from 'Controls/Button/PegButton';
import {
  CrewDetails,
  ICrewDetails
} from 'Model/Order/CrewDetail';
import { ICrewItems } from 'Constants/Constant';

type MyProps = {
  crewDetails?: any;
  createCrewRow: () => void;
  deleteCrewRow: (index: number) => void;
  updateCrewDetails: (event: any, key: string, index: number) => void;
  updateCrewActivities: (activity: any) => void;
  enabledRequests: ICrewItems[]
};
type MyState = {
  crew: ICrewDetails | {};
};

const EmptyRow = () => {
  return CrewDetails.length === 0 ?
    <div className="flex flex-row justify-center p-10">
      <h3 className={"text-sm text-medium"}>No Crew</h3>
    </div> : null
}
class CrewTableView extends React.Component<MyProps, MyState>{
  constructor(props: any) {
    super(props);
    this.state = {
      crew: []
    };
  }

  handleAddCrew = () => {
    const { createCrewRow } = this.props;
    createCrewRow();
  }

  onUpdateValue = (event: any, key: any, index: number) => {
    const {
      updateCrewDetails
    } = this.props;
    updateCrewDetails(event, key, index);
  }

  onClickActivity = (index: number, obj: ICrewItems) => {
    let {
      updateCrewActivities,
      enabledRequests
    } = this.props
    enabledRequests[index] = obj
    updateCrewActivities(enabledRequests)
  }

  render() {
    const { crewDetails, enabledRequests } = this.props;
    return (
      <div className="col-span-2">
        <div className={'flex mb-4 justify-between'}>
          <PegButton id={'btnAddBulk'} onClick={this.handleAddCrew} variant={'btn-primary btn-outline'} size={'sm'}>
            Add New Crew
          </PegButton>
        </div>
        {
          crewDetails.length === 0 ? <EmptyRow /> : <>
            {crewDetails?.map((item: any, index: number) => {
              return (
                <CrewLine
                  key={index}
                  index={index}
                  enabledRequests={enabledRequests}
                  crewDetail={item}
                  onClickActivity={(index: any, obj: any) => this.onClickActivity(index, obj)}
                  handleOnDeleteRow={() => { this.props.deleteCrewRow(index) }}
                  onUpdateValue={(event: any, key: any) => this.onUpdateValue(event, key, index)}
                />
              )
            })}
          </>
        }
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  createCrewRow: (payload: object) => dispatch(createCrewRow(payload)),
  deleteCrewRow: (index: number) => dispatch(deleteCrewRow(index)),
  updateCrewDetails: (event: any, key: string, index: number) => dispatch(updateCrewDetails(event, key, index)),
  updateCrewActivities: (activity: any) => dispatch(updateCrewActivities(activity))
});

const mapStateToProps = (state: any) => ({
  crewDetails: state.crew.crewDetails,
  enabledRequests: state.crew.enabledRequests
});

export default connect(mapStateToProps, mapDispatchToProps)(CrewTableView);