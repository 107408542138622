import {
  SET_SERVICE_TEMPLATE,
  UPDATE_SERVICE_TEMPLATE,
  SET_SERVICE_LOADING,
  SET_VISIBILITY_GRID_LOADING,
  SET_BUSINESS_PRODUCT,
  UPDATE_BUSINESS_PRODUCT,
  ON_ADD_TEMPLATE_SERVICE,
  ON_DELETE_TEMPLATE_SERVICE,
  ON_ADD_TEMPLATE_CHARGE,
  ON_DELETE_TEMPLATE_CHARGE,
  ON_UPDATE_TEMPLATE_CHARGE,
  ON_UPDATE_TEMPLATE_SERVICE,
  ON_BULK_DELETE_SERVICE,
  ON_UPDATE_BUSINESS_PRODUCT_SERVICES,
  ON_UPDATE_BUSINESS_PRODUCT_DETAILS,
  ON_UPDATE_OP_TAGS,
  ON_BULK_UPDATE_SERVICE_CHARGES,
  ON_BULK_DELETE_SERVICE_CHARGES,
  SET_IS_DUPLICATE
} from "../ActionTypes/ActionTypes";
import { Service } from 'Model/ServiceTemplate/service';
import { Charge } from 'Model/ServiceTemplate/charge';
import { SET_VISIBILITY_GRID_DATA, IS_SERVICE_TEMPLATE_SERVICE_SAVING, DELETE_SERVICE_VISIBILITY, REFRESH_SERVICE_VISIBILITY } from '../ActionTypes/ActionTypes';
import _ from "lodash";


var initialState = {
  visibilityGridData: [],
  templateDetails: {},
  selectedProduct: {},
  serviceTemplate: {
    ServiceTemplateDetails: {},
    BusinessProducts: [],
    ServiceTemplateVisibility: []
  },
  businessProducts: [],
  serviceAndCharges: [],
  isModified: false,
  isLoading: true,
  isTemplateLoading: true,
  isError: false,
  isServiceTemplateSaving: false,
  isDuplicate: false
}
let serviceList: any[];
let charges: any;

export const ServiceTemplates = (state = initialState, action: any) => {

  switch (action.type) {

    case SET_SERVICE_TEMPLATE:
      const {
        response
      } = action
      return {
        ...state,
        serviceTemplate: response,
        businessProducts: response.BusinessProducts,
        isLoading: false,
        isError: false
      };

    case SET_VISIBILITY_GRID_DATA:
      const {
        data
      } = action
      return {
        ...state,
        visibilityGridData: data,
        isTemplateLoading: false,
        isError: false
      };

    case UPDATE_SERVICE_TEMPLATE:
      let serviceTemplateDetails: any = state?.serviceTemplate?.ServiceTemplateDetails
      let { ServiceTemplateId, VisibilityType } = serviceTemplateDetails;
      let existingVisibilityData: any = [...state?.serviceTemplate?.ServiceTemplateVisibility];
      let visibilityDetails = action.serviceTemplate.ServiceTemplateVisibility;
      if (ServiceTemplateId) {
        if (VisibilityType === 'Office') {
          let ids = new Set(visibilityDetails.map((e: any) => e.OfficeFk));
          visibilityDetails = existingVisibilityData.filter((a: any) => !ids.has(a.OfficeFk)).concat(visibilityDetails);
        } else if (VisibilityType === 'Department') {
          let ids = new Set(visibilityDetails.map((e: any) => e.DepartmentFk));
          visibilityDetails = existingVisibilityData.filter((a: any) => !ids.has(a.DepartmentFk)).concat(visibilityDetails);
        }
      }

      return {
        ...state,
        isLoading: false,
        serviceTemplate: {
          ServiceTemplateDetails: {
            ...state.serviceTemplate.ServiceTemplateDetails,
            ...action.serviceTemplate.ServiceTemplateDetails
          },
          ServiceTemplateVisibility: visibilityDetails,
          BusinessProducts: state.serviceTemplate?.BusinessProducts
        }
      }
    case SET_SERVICE_LOADING:
      return {
        ...state,
        isLoading: action.value
      };


    case SET_VISIBILITY_GRID_LOADING:
      return {
        ...state,
        isTemplateLoading: action.value
      };

    case REFRESH_SERVICE_VISIBILITY:
      let visibilityData: any[];
      if (action?.visibilityType === 'Company') {
        visibilityData = [...state?.serviceTemplate?.ServiceTemplateVisibility];
      } else {
        visibilityData = []
      }
      return {
        ...state,
        serviceTemplate: {
          ServiceTemplateDetails: {
            ...state.serviceTemplate.ServiceTemplateDetails
          },
          ServiceTemplateVisibility: visibilityData,
          BusinessProducts: [
            ...state.serviceTemplate.BusinessProducts
          ]
        }
      };

    case DELETE_SERVICE_VISIBILITY:
      let Visibility;
      let type = action.visibiltyType
      if (type === 'Office') {
        const cloneData: any[] = [...state.serviceTemplate.ServiceTemplateVisibility];
        const index = cloneData.indexOf(action.id);
        cloneData.splice(index, 1);
        Visibility = cloneData;
      } else if (type === 'Department') {
        const cloneData: any[] = [...state.serviceTemplate.ServiceTemplateVisibility];
        const index = cloneData.indexOf(action.id);
        cloneData.splice(index, 1);
        Visibility = cloneData;
      }
      return {
        ...state,
        serviceTemplate: {
          ServiceTemplateDetails: {
            ...state.serviceTemplate.ServiceTemplateDetails,
          },
          ServiceTemplateVisibility: Visibility,
          BusinessProducts: [...state.serviceTemplate.BusinessProducts]
        },
        visibilityGridData: [...state.visibilityGridData]
      };

    case SET_BUSINESS_PRODUCT:
      let servChar: any = []
      state.businessProducts.map((item: any) => {
        if (item.Code === action.product.Code) {
          servChar = item.servicesAndCharges
        }
      })
      return {
        ...state,
        selectedProduct: action.product,
        serviceAndCharges: servChar ? servChar : []
      };

    case UPDATE_BUSINESS_PRODUCT:
      return {
        ...state,
        serviceTemplate: {
          ServiceTemplateDetails: {
            ...state.serviceTemplate.ServiceTemplateDetails,
          },
          ServiceTemplateVisibility: [...state.serviceTemplate.ServiceTemplateVisibility],
          BusinessProducts: action.products
        },
        businessProducts: action.products,
      };

    case ON_ADD_TEMPLATE_SERVICE:
      let serviceObj = {
        charges: [{ ...new Charge }],
        services: { ...new Service },
      }
      return {
        ...state,
        serviceAndCharges: [...state.serviceAndCharges, { ...serviceObj }]
      };

    case ON_DELETE_TEMPLATE_SERVICE:

      const selectedProductTemp: any = state.selectedProduct
      const selectedProduct: any = state.businessProducts.find((item: any) => item.Code == selectedProductTemp.Code);

      serviceList = state.serviceAndCharges.filter((item, index) => index !== action.serviceIndex);
      const opToBeDeleted = selectedProduct?.opCode?.filter((item: any) => item.Code == action.opCode).map((item: any) => item.BpCode)

      const servicesExisting = (state.serviceAndCharges.filter((item: any) => opToBeDeleted.includes(item.services.Code)))

      if (servicesExisting?.length > 1) {
        return {
          ...state,
          serviceAndCharges: [...serviceList]
        };
      }

      const restOfProducts = state.businessProducts.filter((item: any) => item.Code != selectedProduct?.Code);
      const remainingOpCode = selectedProduct?.opCode?.filter((item: any) => item.Code != action.opCode)
      const updatedProduct = {
        ...selectedProduct,
        opCode: remainingOpCode
      };

      return {
        ...state,
        businessProducts: [...restOfProducts, updatedProduct],
        serviceAndCharges: [...serviceList]
      };

    case ON_ADD_TEMPLATE_CHARGE:
      serviceList = state.serviceAndCharges
      charges = serviceList[action.serviceIndex].charges;
      charges.push({ ...new Charge });
      serviceList[action.serviceIndex].charges = charges
      return {
        ...state,
        serviceAndCharges: [...serviceList]
      };

    case ON_DELETE_TEMPLATE_CHARGE:
      serviceList = state.serviceAndCharges;
      if (serviceList[action.serviceIndex].charges && serviceList[action.serviceIndex].charges.length > 1) {
        charges = serviceList[action.serviceIndex].charges.filter((item: any, index: any) => index !== action.chargeIndex);
        serviceList[action.serviceIndex].charges = charges;
      }
      return {
        ...state,
        serviceAndCharges: [...serviceList]
      };

    case ON_UPDATE_TEMPLATE_CHARGE:
      serviceList = state.serviceAndCharges
      serviceList[action.serviceIndex].charges[action.chargeIndex] = action.charge
      return {
        ...state,
        serviceAndCharges: [...serviceList]
      };

    case ON_UPDATE_TEMPLATE_SERVICE:
      serviceList = state.serviceAndCharges
      serviceList[action.serviceIndex].services = action.service
      return {
        ...state,
        serviceAndCharges: [...serviceList]
      };

    case ON_UPDATE_BUSINESS_PRODUCT_SERVICES:
      let bpProducts = state.businessProducts.map((item: any) => {
        if (item.Code === action.product.Code) {
          item.servicesAndCharges = action.servicesAndCharges;
        }
        return item
      })
      let services: any = [...state.serviceAndCharges];
      let selectedCode: any = state.selectedProduct
      if (action?.product?.Code === selectedCode?.Code) {
        services = action?.servicesAndCharges
      }
      return {
        ...state,
        businessProducts: [...bpProducts],
        serviceAndCharges: services ? [...services] : []
      };

    case ON_UPDATE_BUSINESS_PRODUCT_DETAILS:
      let temp = state.businessProducts.map((item: any) => {
        if (item.Code === action.product.Code) {
          item.servicesAndCharges = action.details;
        }
        return item
      })
      let tempServices: any = state.businessProducts.find((item: any) => {
        if (item.Code === action.newProduct.Code) {
          return item;
        }
      })
      return {
        ...state,
        businessProducts: [...temp],
        serviceAndCharges: tempServices?.servicesAndCharges ? tempServices.servicesAndCharges : []
      };

    case ON_UPDATE_OP_TAGS:
      let opTagList = state.businessProducts.map((item: any) => {
        if (item.Code === action.product.Code) {
          let tags = item.opCode ? [...item.opCode, ...action.opTags] : [...action.opTags]
          item.opCode = _.uniqBy(tags, function (e: any) {
            return e.TagId;
          });
        }
        return item
      })
      return {
        ...state,
        businessProducts: [...opTagList]
      };

    case IS_SERVICE_TEMPLATE_SERVICE_SAVING:
      return {
        ...state,
        isServiceTemplateSaving: action.isSaving
      }

    case ON_BULK_UPDATE_SERVICE_CHARGES:
      return {
        ...state,
        isModified: true,
        serviceAndCharges: [...action.services]
      };

    case ON_BULK_DELETE_SERVICE_CHARGES:
      serviceList = state.serviceAndCharges.filter((item: any, index: any) => item.services.Id !== action.list.services.Id);
      return {
        ...state,
        isModified: true,
        serviceAndCharges: serviceList
      };

    case ON_BULK_DELETE_SERVICE:
      return state;

    case SET_IS_DUPLICATE:
      return {
        ...state,
        isDuplicate: action.value
      };

    default:
      return state;

  }
}