export const GET_PORT_DETAILS = "GET_PORT_DETAILS";
export const UPDATE_PORT_DETAILS = "UPDATE_PORT_DETAILS";
export const SAVE_PORT_DETAILS = "SAVE_PORT_DETAILS";
export const PORT_LOADING = "PORT_LOADING";
export const PORT_SAVING = "PORT_SAVING";
export const SET_ERROR_DATA = "SET_ERROR_DATA";
export const UPDATE_CENTER = "UPDATE_CENTER";

//editor

export const GET_PORT_EDITOR_DETAILS = "GET_PORT_EDITOR_DETAILS";
export const SET_PORT_EDITOR_LOADING = "SET_PORT_EDITOR_LOADING";
export const SAVE_PORT_EDITOR_DETAILS = "SAVE_PORT_EDITOR_DETAILS";
export const UPDATE_TERMINAL_DETAILS = "UPDATE_TERMINAL_DETAILS";
export const UPDATE_PORT_EDITOR_DETAILS = "UPDATE_PORT_EDITOR_DETAILS";
export const PORT_EDITOR_SAVING = "PORT_EDITOR_SAVING";