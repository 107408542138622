import {
  CREATE_CREW_ROW,
  DELETE_CREW_ROW,
  UPDATE_CREW_DETAILS,
  UPDATE_CREW_ACTIVITIES
} from "Redux/ActionTypes/Crew";
import { setCrewTableValue } from "Utils/Quotation";
import { CrewDetails } from "Model/Order/CrewDetail";
import { RESET_CREW_STATE } from "Redux/ActionTypes/ActionTypes";
import { ICrewItems } from "Constants/Constant";

let initialState = {
  crewDetails: [] as CrewDetails[],
  enabledRequests: [] as ICrewItems[],
  errorCount: 0,
}


export const crew = (state = initialState, action: any) => {

  switch (action.type) {

    case CREATE_CREW_ROW:
      let crewDetail = new CrewDetails();
      let newCrew: CrewDetails[] = state.crewDetails.slice();
      newCrew.push(crewDetail)
      return {
        ...state,
        crewDetails: newCrew
      }

    case DELETE_CREW_ROW:
      let newState = [...state.crewDetails];
      newState.splice(action.index, 1);
      return {
        ...state,
        crewDetails: newState
      }

    case UPDATE_CREW_DETAILS:

      const row = setCrewTableValue(state.crewDetails[action.index], action.key, action.event, action.index)

      let tempCrewDetail = [...state.crewDetails];
      tempCrewDetail.splice(action.index, 1);

      return {
        ...state,
        crewDetails: [
          ...tempCrewDetail,
          row
        ]
      };

    case UPDATE_CREW_ACTIVITIES:
      return {
        ...state,
        enabledRequests: [...action.activity]
      };

    case RESET_CREW_STATE:
      return {
        crewDetails: [] as CrewDetails[],
        enabledRequests: [],
        errorCount: 0,
      };

    default:
      return state;
  }
};

