import { QuotationAreaType } from "../../Constants/QuotationAreaType";
import { QuotationArea } from "./QuotationArea";

export interface IQuoteService {
  QuoteServiceId?: number;
  Version?: number;
  QuotationAreaType?: QuotationAreaType;
  ServiceType?: string;
  Code?: string;
  Name?: string;
  CreatedAt?: Date;
  CreatedBy?: string;
  ModifiedAt?: Date | null;
  ModifiedBy?: string;
  IsActive?: boolean;
}


export class QuoteService implements IQuoteService {
  QuoteServiceId?: number;

  Version?: number;

  QuotationAreaType?: QuotationAreaType;

  ServiceType?: string;

  Code?: string;

  Name?: string;

  CreatedAt?: Date;

  CreatedBy?: string;

  ModifiedAt?: Date | null;

  ModifiedBy?: string;

  IsActive?: boolean;

  constructor() {
    this.QuoteServiceId = 0;
    this.Version = 0;
    this.QuotationAreaType = QuotationAreaType.Generic;
    this.ServiceType = '';
    this.Code = '';
    this.Name = '';
    this.CreatedAt = new Date();
    this.CreatedBy = '';
    this.ModifiedAt = new Date();
    this.ModifiedBy = '';
    this.IsActive = true;
  }
}