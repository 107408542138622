import { connect } from 'react-redux';

import { IFreightDetail } from 'Model/Order/FreightDetails';
import { updateFreightDetails } from 'Redux/Actions/freight';
import FreightDetails from './FreightDetails';

const mapDispatchToProps = (dispatch: any) => ({
    updateFreightDetails: (frightDetails: IFreightDetail) => dispatch(updateFreightDetails(frightDetails))
});

const mapStateToProps = (state: any) => ({
    freightDetails: state.freight ? state.freight.FreightDetail : state
});

export default connect(mapStateToProps, mapDispatchToProps)(FreightDetails);