
import { connect } from 'react-redux';
import { updateQuotationCustomerDetails } from 'Redux/Actions/Customer';
import { updateQuotationVesselDetails } from 'Redux/Actions/Vessel';
import { updateQuotationOptionDetails } from 'Redux/Actions/Vessel';
import { updateQuotationCargoDetails } from 'Redux/Actions/cargo';
import OverviewPage from './OverviewPage'

const mapDispatchToProps = (dispatch: any) => ({
    updateQuotationCustomerDetails: (data: object) => dispatch(updateQuotationCustomerDetails(data)),
    updateQuotationVesselDetails: (data: object) => dispatch(updateQuotationVesselDetails(data)),
    updateQuotationCargoDetails: (data: object) => dispatch(updateQuotationCargoDetails(data)),
    updateQuotationOptionDetails: (data: []) => dispatch(updateQuotationOptionDetails(data))

});

const mapStateToProps = (state: any) => ({
    customerDetails: state.customer.customerDetails,
    vesselDetails: state.vessel.vesselDetails,
    cargoDetails: state.cargo.cargoDetails,
    optionDetails: state.vessel.optionDetails,
    quotationList: state.quotationList.quotationList
});

export default connect(mapStateToProps, mapDispatchToProps)(OverviewPage);