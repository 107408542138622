
import { useCallback } from 'react';
import { Overview } from '../../services.styles';
import Dropdown from 'Controls/Dropdown';
import DatePicker from 'Controls/DateRange/PegDatePicker';
import InputBox from 'Controls/Input/PegInputBox';
import { ReactComponent as Expanded } from 'Assets/arrow-down.svg'
import { ReactComponent as Collapsed } from 'Assets/arrow-up.svg'
import { ReactComponent as AddNew } from 'Assets/add-new.svg'
import { ReactComponent as Delete } from 'Assets/delete-item.svg'

type Props = {
  data: any;
  id?: number;
  type?: string;
  showCharges?: boolean;
  service?: any;
  onExpandService?: (service: any) => void;
  onAddCharge?: (service: any) => void;
  onChangeInput?: (event: any, id: any, type: any, service: any, charge: any) => void;
  onChangeDate?: (event: any, id: any, type: any, service: any, charge: any) => void;
  onChangeDropdown?: (event: any, id: any, type: any, service: any, charge: any) => void;
  deleteService?: (service: any) => void;
  url?: string;
  isLookup?: boolean;
  charge?: any;
  selectedList?: any;
  isAllSelected?: any;
  isSelected?: any;
  onSelectOptions?: (service: any, charge: any) => void;
  serviceRequestDetails?: any
};

const Service = (props: Props) => {
  const {
    data,
    onExpandService,
    onChangeDropdown,
    onChangeInput,
    onChangeDate,
    service,
    charge,
    type,
    deleteService,
    onAddCharge,
    isAllSelected,
    isSelected,
    onSelectOptions,
    showCharges,
    serviceRequestDetails } = props;

  let showCharge = service?.showCharges;
  if (type === 'job') {
    showCharge = showCharges;
  }

  const deleteServiceObject = useCallback(() => {
    if (deleteService) {
      deleteService(service);
    }
  }, [deleteService, service]);

  const addCharge = useCallback(() => {
    if (onAddCharge) {
      onAddCharge(service);
    }
  }, [onAddCharge, service]);

  const onClickExpand = useCallback(() => {
    if (onExpandService) {
      onExpandService(service);
    }
  }, [onExpandService, service]);

  const onChangeInputValue = useCallback(
    (e: any, id: any) => {
      if (onChangeInput) {
        onChangeInput(e, id, type, service, charge);
      }
    },
    [onChangeInput, type, service, charge]
  );

  const onChangeDropdownValue = useCallback(
    (e: any, id: any) => {
      if (onChangeDropdown) {
        onChangeDropdown(e, id, type, service, charge);
      }
    },
    [onChangeDropdown, type, service, charge]
  );

  const onChangeDateValue = useCallback(
    (e: any, id: any) => {
      if (onChangeDate) {
        onChangeDate(e, id, type, service, charge);
      }
    },
    [onChangeDate, type, service, charge]
  );

  const renderData = () => {
    switch (data.key) {
      case 'name':
        return (
          <Dropdown
            id={data.key}
            defaultValue={service.Name}
            onChangeDropdown={onChangeDropdownValue}
            label={'name'}
            isLookup={false}
            removeBorder={true}
            dropdownLabel={'name'}
            dropdownValue={'name'}
            style={{ width: 199 }}
            placeHolder="Select service"
            appendParams={false}
            dropdownMap={'services'}
            dropdownType="service.name"
            url={`oum-getservices?company-code=GDUB&bp-code=BP0001%3BBP0002`}
          />
        );

      case 'costCurrency':
        return (
          <Dropdown
            id={data.key}
            defaultValue={service.CostCurrencyName}
            onChangeDropdown={onChangeDropdownValue}
            label={'name'}
            removeBorder={true}
            isLookup={false}
            dropdownLabel={'name'}
            dropdownValue={'label'}
            placeHolder="Select currency"
            appendParams={false}
            style={{ width: 199 }}
            url={`/mdm-currency?searchText=&code=`}
          />
        );
      case 'currency':
        return (
          <Dropdown
            id={data.key}
            defaultValue={service.CostCurrencyName}
            onChangeDropdown={onChangeDropdownValue}
            label={'name'}
            removeBorder={true}
            isLookup={false}
            dropdownLabel={'name'}
            dropdownValue={'label'}
            placeHolder="Select currency"
            appendParams={false}
            style={{ width: 199 }}
            url={`/mdm-currency?searchText=&code=`}
          />
        );
      case 'supplier':
        return (
          <Dropdown
            id={data.key}
            defaultValue={service.SupplierName}
            onChangeDropdown={onChangeDropdownValue}
            isLookup={true}
            useDebounce={true}
            removeBorder={true}
            dropdownLabel={'name'}
            dropdownValue={'name'}
            placeHolder="Select supplier"
            appendParams={false}
            style={{ width: 199 }}
            url={`/mdm-search-supplier?searchText=`}
          />
        );

      case 'priceCurrency':
        return (
          <Dropdown
            id={data.key}
            defaultValue={service.PriceCurrencyName}
            onChangeDropdown={onChangeDropdownValue}
            isLookup={false}
            removeBorder={true}
            dropdownLabel={'name'}
            dropdownValue={'label'}
            appendParams={false}
            style={{ width: 199 }}
            placeHolder="Select currency"
            url={`/mdm-currency?searchText=&code=`}
          />
        );


      case 'estCurrency':
        return (
          <Dropdown
            id={data.key}
            defaultValue={service.PriceCurrencyName}
            onChangeDropdown={onChangeDropdownValue}
            isLookup={false}
            removeBorder={true}
            dropdownLabel={'name'}
            dropdownValue={'label'}
            appendParams={false}
            style={{ width: 199 }}
            placeHolder="Select currency"
            url={`/mdm-currency?searchText=&code=`}
          />
        );

      case 'UOM':
        return (
          <div />
        );

      case 'srNo':
        return <div>{serviceRequestDetails?.SRNumber}</div>;

      case 'srStatus':
        return <div>{serviceRequestDetails?.SRStatus}</div>;

      case 'srRating':
        return <div>{serviceRequestDetails?.Rating}</div>;

      case 'srEndTime':
        return <div>{serviceRequestDetails?.EndDateTime}</div>;

      case 'srStartTime':
        return <div>{serviceRequestDetails?.StartDateTime}</div>;

      case 'srServiceStatus':
        return <div>Dropdown </div>;

      case 'startDate':
        return (
          <DatePicker
            id={data.key}
            type={"service"}
            style={{ height: '36px' }}
            onChange={onChangeDateValue} />
        );

      case 'endDate':
        return (
          <DatePicker
            id={data.key}
            type={"service"}
            style={{ height: '36px' }}
            onChange={onChangeDateValue} />
        );

      case "remarks":
        return (
          <InputBox
            type={"service"}
            value={service[data.key]}
            onChange={onChangeInputValue}
            id={data.key}
            style={{ height: '36px', borderRadius: '0px', border: "none" }}
          />
        );

      case 'id':
        return (
          <div>
            <input
              type="checkbox"
              checked={isAllSelected || isSelected}
              onChange={() => onSelectOptions && onSelectOptions(service, null)}
              style={{ cursor: 'pointer', marginRight: '8px' }}
            />
            {service.ServiceDetailId}
          </div>
        )

      case 'addCharge':
        return <div onClick={addCharge}><AddNew /></div>;

      case 'delete':
        return (
          <div onClick={deleteServiceObject} style={{ cursor: 'pointer' }}>
            <Delete />
          </div>
        );

      case 'expand':
        return (
          <div onClick={onClickExpand}>
            {
              showCharge ?
                <Collapsed />
                :
                <Expanded />
            }
          </div>
        )
      case 'exRate':
        return <div>calculation</div>;

      case 'billingParty':
        return <div />;

      case 'operatingIncome':
        return <div />;

      case 'quantity':
        return <div />;

      case 'unitprice':
        return <div />;

      case 'discount':
        return <div />;

      case 'margin':
        return <div />;

      case 'netprice':
        return <div />;

      case 'taxType':
        return <div />;

      case 'taxRate':
        return <div />;

      case 'netPriceLocal':
        return <div />;

      case 'netPriceSelling':
        return <div />;

      case 'unitCost':
        return <div />;

      case 'totalCost':
        return <div />;

      default:
        return <div />;
    }
  };

  return <Overview>{renderData()}</Overview>;
};

export default Service;
