import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import 'tippy.js/animations/scale-subtle.css';

interface IToolTipProps {
  id: string;
  content: any;
  children?: any;
  placement: any;
  animation: string;
  arrow: boolean;
  duration: number;
  delay: any;
  disabled?: boolean;
}


const ToolTip = (props: IToolTipProps) => {
  const { content, placement, arrow, animation, duration, delay, children, disabled } = props
  return (
    <Tippy
      disabled={disabled === true}
      content={<span>{content}</span>}
      placement={placement}
      animation={animation}
      theme="material"
      arrow={arrow}
      duration={duration}
      delay={delay}
    >
      <button>
        {children}
      </button>
    </Tippy>
  )
}
export default ToolTip;
