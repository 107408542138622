const BillingPartyWithAddress = (props: any) => {
    const { name, address } = props;

    const addressStyle = {
        fontSize: "0.9em",
        color: "silver",
    };

    return (
        <div>
            {name}<br />
            <span style={addressStyle}>{address}</span>
        </div>
    )
}

export default BillingPartyWithAddress;