import React from 'react';

interface ILabelProps {
  id: string;
  name?: string;
  children: string;
  className?: string;
}

export default function PegLabelBox(props: ILabelProps) {
  const { id, children, name, className } = props;
  return (
    <label id={id} htmlFor={name} className={className ? className : 'w-4/12 text-sm font-normal text-dark'}>
      {children}
    </label>
  );
}
