import axios, { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { getToken } from '../Utils/AuthConfig';
import { loginRequest } from '../Utils/AuthConfig';
import { Configuration, InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser'

const subscriptionKey = process.env.REACT_APP_SUBSCRIPTION_KEY;
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common['Ocp-Apim-Subscription-Key'] = subscriptionKey ? subscriptionKey : '';
axios.defaults.headers.common['Ocp-Apim-Trace'] = "true";
axios.defaults.headers.common['X-Frame-Options'] = "SAMEORIGIN";

/*axios.interceptors.request.use(function (config) {
  config!.headers!.Authorization = `Bearer ${getToken('accessToken')}`;
  return config;
});*/

function fetchAccessToken() {
  const configuration: Configuration = {
    auth: {
      clientId: process.env.REACT_APP_CLIENT_ID!,
      redirectUri: process.env.REACT_APP_REDIRECT_URI,
    },
    cache: {
      cacheLocation: "sessionStorage"
    }
  }
  const pca = new PublicClientApplication(configuration)
  return new Promise((resolve) => {
    const accounts = pca.getAllAccounts();
    if (accounts.length > 0) {
      pca.setActiveAccount(accounts[0]);
    }
    pca.acquireTokenSilent({ ...loginRequest })
      .then((response) => {
        resolve(response.accessToken)
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          pca.acquireTokenRedirect({ ...loginRequest });
        }
      });
  });
}


const sleep = (delay: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay)
  })
}

// Add a request interceptor
axios.interceptors.request.use(
  async (config) => {
    if (process.env.REACT_APP_SSO_ENABLED == "true") {
      const accessToken = await fetchAccessToken();
      if (accessToken) {
        config!.headers!.Authorization = "Bearer " + accessToken;
      }
    }
    config!.headers!["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);


axios.interceptors.response.use(
  async (response) => {
    if (process.env.NODE_ENV === "development") await sleep(1000);
    return response;
  },
  (error: AxiosError) => {
    // const { data, status, config } = error.response!;
    // switch (status) {
    //   case 400:
    //     if (typeof data === 'string') {
    //       toast.error(data);
    //     }
    //     if (config.method === 'get' && data.errors.hasOwnProperty('id')) {
    //       // history.push('/not-found');
    //     }
    //     if (data.errors) {
    //       toast.error('data.errors');
    //       const modalStateErrors = [];
    //       for (const key in data.errors) {
    //         if (data.errors[key]) {
    //           modalStateErrors.push(data.errors[key]);
    //         }
    //       }
    //       throw modalStateErrors.flat();
    //     }
    //     break;
    //   case 401:
    //     toast.error('unauthorised');
    //     break;
    //   case 404:
    //     toast.error('not-found');
    //     // history.push('/not-found');
    //     break;
    //   case 500:
    //     //mobXstore.commonStore.setServerError(data);
    //     toast.error('Oops, internal error!');
    //     break;
    // }
    return Promise.reject(error);
  }
);


export const requests = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const responseBody = <T>(response: AxiosResponse<T>) => response.data;
export const pagination = (limit: number, offset: number) => `limit = ${limit}& offset=${offset ? offset * limit : 0} `;