export interface IIndustryVertical {
    Name: string;
    IsActive: boolean;
}

export class IndustryVertical implements IIndustryVertical {
    Name: string;

    IsActive: boolean;

    constructor() {
        this.Name = "";

        this.IsActive = false;
    }
}

export interface IAddress {
    AddressLine1: string;
    AddressType: string;
    IsActive: number;
}

export class Address implements IAddress {
    AddressLine1: string;

    AddressType: string;

    IsActive: number;

    constructor() {
        this.AddressLine1 = "";

        this.AddressType = "";

        this.IsActive = 0;
    }
}

export interface ICreateCustomer {
    MasterCode: string;
    SourcePrimaryKey: string;
    InstanceCode: string;
    Name: string;
    LegalName: string;
    Phone: string;
    Telex: string;
    Email: string;
    IsActive: boolean;
    Website: string;
    VatNumber: string;
    Logo: string;
    IsPartyGroup: boolean;
    Fax: string;
    LinkedIn: string;
    SourceSystem: string;
    CountryISO2Code: string;
    PartyAddresses: IAddress[];
    PartyContacts: [];
    PartyTypes: [];
    CustomerDetails: {};
    IndustryVerticals: IndustryVertical[];
    BusinessProducts: any[];
    BusinessArea: string;
}

export class CreateCustomer implements ICreateCustomer {
    MasterCode: string;

    SourcePrimaryKey: string;

    InstanceCode: string;

    Name: string;

    LegalName: string;

    Phone: string;

    Telex: string;

    Email: string;

    IsActive: boolean;

    Website: string;

    VatNumber: string;

    Logo: string;

    IsPartyGroup: boolean;

    Fax: string;

    LinkedIn: string;

    SourceSystem: string;

    CountryISO2Code: string;

    PartyAddresses: Address[];

    PartyContacts: [];

    PartyTypes: [];

    CustomerDetails: {};

    IndustryVerticals: IndustryVertical[];

    BusinessProducts: any[];

    BusinessArea: string;

    constructor() {
        //both mastercode and source primary key should be unique in each request
        this.MasterCode = `DXB-NAN-` + Math.random().toString(16).slice(2).toString();
        this.SourcePrimaryKey = `DXB-NAN-` + (new Date()).getTime().toString();
        this.InstanceCode = '';
        this.Name = '';
        this.LegalName = '';
        this.Phone = '';
        this.Telex = '';
        this.Email = '';
        this.IsActive = false;
        this.Website = '';
        this.VatNumber = '';
        this.Logo = '';
        this.IsPartyGroup = false;
        this.Fax = '';
        this.LinkedIn = '';
        this.SourceSystem = '';
        this.CountryISO2Code = '';
        this.PartyAddresses = [new Address()];
        this.PartyContacts = [];
        this.PartyTypes = [];
        this.CustomerDetails = {};
        this.IndustryVerticals = [new IndustryVertical()];
        this.BusinessProducts = [];
        this.BusinessArea = '';
    }
}
