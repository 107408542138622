
import {
  useCallback,
} from 'react'; import {
  HeaderView,
  Overview,
  Group,
  GroupName,
  Flex,
  // list
  ID,
  Delete,
  Add,
  Expand,
  Detail,
  NameHeader,
  BillingParty,
  BillingPartyAddress,
  CustomerService,
  StartDate,
  EndDate,
  OperatingIncome,
  UOM,
  Quantity,
  UnitPrice,
  PriceCurrency,
  CostCurrency,
  EstTaxType,
  EstTax,
  EstAmt,
  EstAmtTax,
  UnitCost,
  TotalCost,
  TotalCostLocal,
  BackToBack,
  Supplier,
  Discount,
  Margin,
  TaxType,
  TaxRate,
  NetPrice,
  NetPriceLocal,
  NetPriceSelling,
  ExternalRemarks,
  InternalRemarks,
  SecondaryGroup
} from '../quote.styles'
import { Color } from 'Utils/Color';
import { ColumnPreferences } from 'Model/Common/ColumnPreferences';
import { QUOTATION_COLUMN } from 'Constants/ColumnFilterConstants';

type Props = {
  disableRevenue: boolean;
  disableCost: boolean;
  disableOperatingIncome: boolean;
  isReadOnly: boolean;
  isAllSelected?: boolean;
  onSelectAll?: () => void;
  columnPreference: ColumnPreferences
};


const Header = (props: Props) => {
  const {
    disableRevenue,
    disableCost,
    disableOperatingIncome,
    isReadOnly,
    onSelectAll,
    isAllSelected,
    columnPreference
  } = props;
  const styleClass = "text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50";

  const onClickCheckbox = useCallback(() => {
    !isReadOnly && onSelectAll && onSelectAll()
  }, []);

  return (
    <HeaderView className={styleClass}>
      <Overview>
        <Group isSticky showBorder>
          <GroupName />
          <ID isHeader={true}>
            {isReadOnly ? <input
              type="checkbox"
              checked={isAllSelected}
              disabled={isReadOnly}
              onChange={onClickCheckbox}
              style={!isReadOnly ? { cursor: 'pointer', marginRight: '8px' } : { cursor: 'not-allowed', opacity: 0.5 }} /> : null}
            {QUOTATION_COLUMN.ID.label}
          </ID>
        </Group>

        <Group showBorder={true}>
          <GroupName />
          <Delete isHeader={true} />
        </Group>

        <Group showBorder={true}>
          <GroupName />
          <Add isHeader={true} />
        </Group>
        <Group showBorder={true}>
          <GroupName />
          <Expand isHeader={true} />
        </Group>
        <Group showBorder={true}>
          <GroupName />
          <Detail isHeader={true} />
        </Group>
        <SecondaryGroup isSticky>
          <GroupName />
          <NameHeader isHeader={true}>
            {QUOTATION_COLUMN.NAME.label}
          </NameHeader>
        </SecondaryGroup>
        <Group>
          <GroupName />
          {columnPreference['CUSTOMER_SERVICE']?.isActive && <CustomerService isHeader={true}>
            {QUOTATION_COLUMN.CUSTOMER_SERVICE.label}
          </CustomerService>}
        </Group>
        <Group showBorder={true}>
          <GroupName />
          {columnPreference['BILLING_PARTY']?.isActive && <BillingParty isHeader={true}>
            {QUOTATION_COLUMN.BILLING_PARTY.label}
          </BillingParty>}
        </Group>
        <Group showBorder={true}>
          <GroupName />
          {columnPreference['BILLING_PARTY_ADDRESS']?.isActive && <BillingPartyAddress isHeader={true}>
            {QUOTATION_COLUMN.BILLING_PARTY_ADDRESS.label}
          </BillingPartyAddress>}
        </Group>

        <Group showBorder={true}>
          <GroupName />
          {columnPreference['START_DATE']?.isActive && <StartDate isHeader={true}>
            {QUOTATION_COLUMN.START_DATE.label}
          </StartDate>}
        </Group>

        <Group showBorder={true}>
          <GroupName />
          {columnPreference['END_DATE']?.isActive && <EndDate isHeader={true}>
            {QUOTATION_COLUMN.END_DATE.label}
          </EndDate>}
        </Group>
        {!disableOperatingIncome && columnPreference['OPERATING_INCOME']?.isActive &&
          <Group>
            <GroupName groupColor={Color.operatingIncomeHighlight}>OP. INCOME</GroupName>
            <Flex>
              <OperatingIncome isHeader={true}>
                {QUOTATION_COLUMN.OPERATING_INCOME.label}
              </OperatingIncome>
            </Flex>
          </Group>
        }
        {!disableRevenue &&
          <Group>
            <GroupName groupColor={Color.revenueHighlight}>Revenue</GroupName>
            <Flex>
              <UOM isHeader={true}>
                {QUOTATION_COLUMN.UOM.label}
              </UOM>

              <Quantity isHeader={true}>
                {QUOTATION_COLUMN.QUANTITY.label}
              </Quantity>

              <UnitPrice isHeader={true}>
                {QUOTATION_COLUMN.UNIT_PRICE.label}
              </UnitPrice>

              {columnPreference['PRICE_CURRENCY']?.isActive && <PriceCurrency isHeader={true}>
                {QUOTATION_COLUMN.PRICE_CURRENCY.label}
              </PriceCurrency>}

              {columnPreference['NET_PRICE']?.isActive && <NetPrice isHeader={true}>
                {QUOTATION_COLUMN.NET_PRICE.label}
              </NetPrice>}

              {columnPreference['TAX_RATE']?.isActive && <TaxType isHeader={true}>
                {QUOTATION_COLUMN.TAX_RATE.label}
              </TaxType>}

              {columnPreference['TAX_AMT']?.isActive && <TaxRate isHeader={true}>
                {QUOTATION_COLUMN.TAX_AMT.label}
              </TaxRate>}

              {columnPreference['NET_PRICE_LOCAL']?.isActive && <NetPriceLocal isHeader={true}>
                {QUOTATION_COLUMN.NET_PRICE_LOCAL.label}
              </NetPriceLocal>}

              {columnPreference['NET_PRICE_SELLING']?.isActive && <NetPriceSelling isHeader={true}>
                {QUOTATION_COLUMN.NET_PRICE_SELLING.label}
              </NetPriceSelling>}
            </Flex>
          </Group>
        }
        {!disableCost &&
          <Group>
            <GroupName groupColor={Color.costHighlight}>Cost</GroupName>
            <Flex>
              <Supplier isHeader={true}>
                {QUOTATION_COLUMN.SUPPLIER.label}
              </Supplier>

              <UnitCost isHeader={true}>
                {QUOTATION_COLUMN.UNIT_COST.label}
              </UnitCost>

              {columnPreference['COST_CURRENCY']?.isActive && <CostCurrency isHeader={true}>
                {QUOTATION_COLUMN.COST_CURRENCY.label}
              </CostCurrency>}

              {columnPreference['COST_TAX_RATE']?.isActive &&
                <EstTaxType isHeader={true} >
                  {QUOTATION_COLUMN.COST_TAX_RATE.label}
                </EstTaxType>}
              {columnPreference['PURCHASE_TAX']?.isActive &&
                <EstTax isHeader={true} >
                  {QUOTATION_COLUMN.PURCHASE_TAX.label}
                </EstTax>}
              {columnPreference['AMT']?.isActive &&
                <EstAmt isHeader={true} >
                  {QUOTATION_COLUMN.AMT.label}
                </EstAmt>}
              {columnPreference['AMT_WITH_TAX']?.isActive &&
                <EstAmtTax isHeader={true} >
                  {QUOTATION_COLUMN.AMT_WITH_TAX.label}
                </EstAmtTax>}

              {columnPreference['TOTAL_COST']?.isActive && <TotalCost isHeader={true}>
                {QUOTATION_COLUMN.TOTAL_COST.label}
              </TotalCost>}

              {columnPreference['TOTAL_COST_LOCAL']?.isActive && <TotalCostLocal isHeader={true}>
                {QUOTATION_COLUMN.TOTAL_COST_LOCAL.label}
              </TotalCostLocal>}

              {columnPreference['BACK_TO_BACK']?.isActive && <BackToBack isHeader={true}>
                {QUOTATION_COLUMN.BACK_TO_BACK.label}
              </BackToBack>}
            </Flex>
          </Group>
        }
        <Group showBorder={true}>
          <GroupName />
          {columnPreference['INTERNAL_REMARKS']?.isActive && <InternalRemarks isHeader={true}>
            {QUOTATION_COLUMN.INTERNAL_REMARKS.label}
          </InternalRemarks>}
        </Group>

        <Group showBorder={true}>
          <GroupName />
          {columnPreference['EXTERNAL_REMARKS']?.isActive && <ExternalRemarks isHeader={true}>
            {QUOTATION_COLUMN.EXTERNAL_REMARKS.label}
          </ExternalRemarks>}
        </Group>
      </Overview>
    </HeaderView >
  )
};

export default Header;
