import { AiOutlineExclamationCircle } from 'react-icons/ai';

import { GridErrorsContainer, ProductContainer, ProductName, ServiceContainer, ChargesContainer } from "./GridErrors.styles";
import { formatErrors, listMissingColumnsForItem } from './utils';
import { GiConsoleController } from 'react-icons/gi';

const GridErrors = (props: any) => {

    const { errors } = props;
    const errorsByProduct = formatErrors(errors);

    return (
        <GridErrorsContainer>
            <AiOutlineExclamationCircle
                data-tip
                data-for='errorIcon'
                style={{ color: 'red', marginRight: "10px" }}
                className="icon-style-md" />
            <div>
                {Object.keys(errorsByProduct).map((productName: string, index: number) => {
                    const product = errorsByProduct[productName];
                    const prodcuctLevelError = product?.errors?.map((error: any, index: number) => {
                        return <div key={index}>
                            <strong>{error.key}</strong>: {error.message}
                        </div>
                    })

                    return <ProductContainer key={productName}>
                        {productName !== "undefined" ? <ProductName>{productName}</ProductName> : null}
                        {product.errors && prodcuctLevelError}
                        {Object.keys(product).map((serviceName: string, index: number) => {

                            const service = product[serviceName];
                            let serviceRequiredValueErrors = null;
                            let serviceExchangeRateErrors = null;
                            let chargesErrors = null;

                            if (service?.errors?.length) {
                                serviceRequiredValueErrors = listMissingColumnsForItem(service.errors, "required");
                                serviceExchangeRateErrors = listMissingColumnsForItem(service.errors, "exchangeRate")
                            }

                            const chargesNames = Object.keys(service?.charges ?? {});
                            if (chargesNames.length) {
                                chargesErrors = chargesNames.map((chargeName: string, index: number) => {
                                    const chargeColumns = service.charges[chargeName].errors;
                                    const missingColumns = listMissingColumnsForItem(chargeColumns, "required");
                                    const missingDolphinCodeMessage = listMissingColumnsForItem(chargeColumns, "dolphinCode");

                                    return <div key={chargeName}>
                                        <strong>{chargeName}</strong> Charge line is missing required columns: {missingColumns}<br />
                                        <i>{missingDolphinCodeMessage}</i>
                                    </div>;
                                })
                            }

                            return serviceName !== "undefined" && serviceName !== "errors" &&
                                <ServiceContainer key={serviceName}>
                                    <strong>{serviceName ? serviceName : "New "}</strong> service
                                    {
                                        serviceRequiredValueErrors && (
                                            <>
                                                {" "} is missing required columns: {serviceRequiredValueErrors}
                                            </>
                                        )
                                    }
                                    {serviceRequiredValueErrors && <br />}
                                    {
                                        serviceExchangeRateErrors && (
                                            <>
                                                {" "} has invalid exchange rates: {serviceExchangeRateErrors}
                                            </>
                                        )
                                    }
                                    <ChargesContainer>{chargesErrors}</ChargesContainer>
                                </ServiceContainer>
                        })}
                    </ProductContainer>


                })}
            </div>
        </GridErrorsContainer >
    )

}

export default GridErrors;