import { useCallback, useEffect, useState } from 'react';
import JobGrid from './JobGrid';
import ProductList from './ProductList';
import {
  EmptyResult
} from '../../template.styles'

interface Prop {
  products: any;
  userDetails: any;
  selectedProduct: any;
  serviceAndCharges: any[];
  opTags: any[];
  isFeildsMissing: boolean;
  onBulkUpdate: (services: any) => void;
  onMultiDeleteServices: (list: any[]) => void;
  onAddTemplateService: () => void;
  onSetDefaultProduct: (product: any) => void;
  onDeleteTemplateService: (serviceIndex: number, opCode: number) => void;
  onAddTemplateCharge: (serviceIndex: number) => void;
  onDeleteTemplateCharge: (serviceIndex: number, chargeIndex: number) => void;
  onUpdateTemplateService: (serviceIndex: number, service: any) => void;
  onUpdateTemplateCharge: (serviceIndex: number, chargeIndex: number, charge: any) => void;
  onUpdateBPserviceCharges: (product: any, servAndChar: any) => void;
  onUpdateOpTags: (product: any, opTag: any) => void;
  onUpdateBPdetails: (product: any, newProduct: any, details: any) => void;
  onSaveOfServiceTemplate: () => void;
  serviceTemplate: any;
}

const JobTable = (props: Prop) => {
  const {
    products,
    userDetails,
    serviceAndCharges,
    onAddTemplateService,
    onDeleteTemplateService,
    onAddTemplateCharge,
    onDeleteTemplateCharge,
    onUpdateTemplateService,
    onUpdateTemplateCharge,
    onSetDefaultProduct,
    selectedProduct,
    onUpdateBPserviceCharges,
    onSaveOfServiceTemplate,
    onUpdateOpTags,
    onBulkUpdate,
    onMultiDeleteServices,
    isFeildsMissing,
    opTags,
    serviceTemplate
  } = props

  useEffect(() => {
    if (products.length > 0 && !selectedProduct) {
      onSelectProduct(products[0])
    }
    if (products.length > 0 && selectedProduct) {
      let exists = products.some((item: any) => item.Code === selectedProduct.Code)
      if (!exists) {
        onSetDefaultProduct(products[0])
      }
    }
  }, [products])

  const onSelectProduct = useCallback((product: any) => {
    onUpdateBPserviceCharges(selectedProduct, serviceAndCharges)
    onSetDefaultProduct(product)
  }, [selectedProduct, serviceAndCharges])

  // removing user details userDetails 

  if (!products || !products.length) {
    return (
      <EmptyResult>
        Select Business Products
      </EmptyResult>
    )
  } else {
    return (
      <>
        <ProductList
          products={products}
          selectedProduct={selectedProduct}
          onSelectProduct={(product: any) => onSelectProduct(product)} />
        <JobGrid
          products={products}
          userDetails={userDetails}
          serviceAndCharges={serviceAndCharges}
          product={selectedProduct}
          opTags={opTags}
          isFeildsMissing={isFeildsMissing}
          onSetDefaultProduct={(product: any) => onSetDefaultProduct(product)}
          onAddTemplateService={() => onAddTemplateService()}
          onDeleteTemplateService={(serviceIndex: number, opCode: number) => onDeleteTemplateService(serviceIndex, opCode)}
          onAddTemplateCharge={(serviceIndex: number) => onAddTemplateCharge(serviceIndex)}
          onDeleteTemplateCharge={(serviceIndex: number, chargeIndex: number) =>
            onDeleteTemplateCharge(serviceIndex, chargeIndex)}
          onUpdateTemplateService={(serviceIndex: number, service: any) =>
            onUpdateTemplateService(serviceIndex, service)}
          onUpdateTemplateCharge={(serviceIndex: number, chargeIndex: number, charge: any) =>
            onUpdateTemplateCharge(serviceIndex, chargeIndex, charge)}
          onUpdateBPserviceCharges={(product: any, servAndChar: any) =>
            onUpdateBPserviceCharges(product, servAndChar)}
          onUpdateOpTags={(product: any, opTag: any) =>
            onUpdateOpTags(product, opTag)}
          onSaveOfServiceTemplate={() => onSaveOfServiceTemplate()}
          serviceTemplate={serviceTemplate}
          onBulkUpdate={(services: any) => onBulkUpdate(services)}
          onMultiDeleteServices={(list: any) => onMultiDeleteServices(list)}
        />
      </>
    );
  }

};

export default JobTable;