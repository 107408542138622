import Header from './Header'
import BulkList from './BulkLine'
import PegButton from 'Controls/Button/PegButton';
import { Section, ButtonActions } from 'Containers/CargoDetails/cargo.styles';
import { RiAddCircleLine } from 'react-icons/ri';
import SectionHeader from 'Components/SectionHeader/SectionHeader';

type IProps = {
  cargo?: any;
  onUpdate?: (key: string, event: any, id: number) => void;
  onDelete: (id: number) => void;
  onAdd?: () => void;
  bulkDetails?: any[];
};
const BulkTableView = (props: IProps) => {
  const {
    onUpdate,
    bulkDetails,
    onDelete,
    onAdd,
  } = props

  return (
    <Section>
      <SectionHeader title={"Bulk Cargo Details"} />
      <ButtonActions className={"flex flex-row-reverse"}>
        <PegButton
          id="cargo_add"
          type="button"
          variant="OutlinePrimary"
          onClick={onAdd}>
          <RiAddCircleLine size="1.2em" className="fill-current text-primary" />
          Add Bulk
        </PegButton>
      </ButtonActions>
      {bulkDetails && bulkDetails.length > 0 && <Header />}
      {
        bulkDetails && bulkDetails.map((item: any, index: number) => {
          return (
            <BulkList
              key={index}
              bulkList={item}
              index={index}
              onUpdate={onUpdate}
              onDelete={onDelete}
            />
          )
        })
      }
    </Section>
  )
}
export default BulkTableView;