
import {
  useCallback, useEffect
} from 'react';
import { useSelector } from "react-redux";
import Dropdown from 'Controls/Dropdown';
import ToolTip from 'Controls/ToolTip/ToolTip';
import InputBox from 'Controls/Input/PegInputBox';
import { ReactComponent as Expanded } from 'Assets/arrow-down.svg'
import { ReactComponent as Collapsed } from 'Assets/arrow-up.svg'
import { ReactComponent as AddNew } from 'Assets/add-new.svg'
import { ReactComponent as DeleteIcon } from 'Assets/delete-item.svg'
import {
  Overview,
  // list
  ID,
  Delete,
  Add,
  Expand,
  //DateRange
  StartDate,
  EndDate,
  //revenue
  Quantity,
  UOM,
  UnitPrice,
  Currency,
  Amount,
  BillingCurrency,
  ExRate,
  Tax,
  SalesTaxAmt,
  BillAmount,
  //cost
  Supplier,
  EstUnitCost,
  EstCurrency,
  EstExRate,
  EstTaxType,
  EstTax,
  EstAmt,
  EstAmtTax,
  // operating income 
  OperatingIncome,
  // eye_share
  EsRefNo,
  EsActualCost,
  // remarks
  InternalRemarks,
  CostUOM,
  CostQty
} from 'Containers/JobGrid/Jobs.styles'
import {
  Name,
  OverviewHeader
} from '../../template.styles'
import {
  setServiceValue,
  setChargeValue
} from 'Utils/ServiceTemplate';
import {
  getExchangeRates
} from 'Utils/Generic';

import axios from 'axios';

type Props = {
  service: any;
  userDetails: any;
  showCharges: boolean;
  serviceList: any[];
  currencyList: any[];
  exchangeRates: any[];
  onExpandService: () => void;
  selectedList?: any;
  onSelectServices: (index: number) => void;
  onAddCharge: (index: number) => void;
  onDeleteService: (index: number) => void;
  onUpdateService: (index: number, servObj: any, key: string, value: any) => void;
  onUpdateTemplateService: (servIndex: number, serviceObj: any) => void;
  disableCost: boolean;
  disableOperatingIncome: boolean;
  disableRevenue: boolean;
  isAllSelected: boolean;
  selectedProduct: any;
  serviceIndex: number;
  isShowCostQty: boolean;
};


const Service = (props: Props) => {
  const {
    service,
    serviceList,
    currencyList,
    exchangeRates,
    disableCost,
    disableOperatingIncome,
    disableRevenue,
    onExpandService,
    onSelectServices,
    showCharges,
    selectedProduct,
    userDetails,
    onAddCharge,
    onDeleteService,
    serviceIndex,
    selectedList,
    onUpdateTemplateService,
    isShowCostQty
  } = props;

  let defaultCurrencyCode = useSelector((state: any) => state.ServiceTemplates.serviceTemplate)?.ServiceTemplateDetails?.BaseCurrencyCode;
  const styleClass = "text-left text-xs font-medium text-gray-500 tracking-wider bg-gray-50";
  const companyCode = new URLSearchParams(window.location.search).get("companycode");
  const inputStyle = {
    border: 'none',
    height: '34px',
    borderRadius: '0px',
    fontSize: '12px'
  }
  useEffect(() => {
    // const asyncFn = async () => {
    if (!service?.BillingCurrencyCode) {
      let serviceObj = setServiceValue(service, 'defaultBillingCurrency', defaultCurrencyCode, null)
      onUpdateTemplateService(serviceIndex, serviceObj)
    }
    if (!service?.PriceCurrencyCode) {
      let serviceObj = setServiceValue(service, 'defaultPriceCurrency', defaultCurrencyCode, null)
      onUpdateTemplateService(serviceIndex, serviceObj)
    }
    if (!service?.CostCurrencyCode) {
      let serviceObj = setServiceValue(service, 'defaultCostCurrency', defaultCurrencyCode, null)
      onUpdateTemplateService(serviceIndex, serviceObj)
    }


    // }
    // asyncFn()
  }, [
    serviceIndex,
    service.BillingCurrencyCode,
    service.PriceCurrencyCode,
    service.CostCurrencyCode,
  ])

  useEffect(() => {
    // const asyncFn = async () => {
    if (!service?.BillingExchangeRate) {
      getRates();
    }
    // }
    // asyncFn()
  }, [])

  useEffect(() => {
    // const asyncFn = async () => {
    if (!service?.PriceExchangeRate) {
      getPriceConversion();
    }
    // }
    // asyncFn()
  }, [])

  useEffect(() => {
    // const asyncFn = async () => {
    if (!service?.CostExchangeRate) {
      getCostConversion();
    }
    // }
    // asyncFn()
  }, [])

  const getCostConversion = useCallback(() => {
    let serviceObj: any;
    let costExRate: any = getExchangeRates(exchangeRates, service?.CostCurrencyCode, defaultCurrencyCode)
    if (costExRate) {
      serviceObj = setServiceValue(service, 'CostExchangeRate', costExRate.ExchangeRate, null);
    } else {
      serviceObj = setServiceValue(service, 'CostExchangeRate', 0, null);
    }
    onUpdateTemplateService(serviceIndex, serviceObj)
  }, [service]);

  const getRates = useCallback(() => {
    let fromCurrency = service?.BillingCurrencyCode;
    let targetCurrency = service?.PriceCurrencyCode;
    let serviceObj: any;
    if (defaultCurrencyCode !== targetCurrency) {
      fromCurrency = service?.PriceCurrencyCode
      targetCurrency = service?.BillingCurrencyCode
    }
    let billExRate: any = getExchangeRates(exchangeRates, fromCurrency, targetCurrency)
    if (billExRate) {
      serviceObj = setServiceValue(service, 'BillingExchangeRate', billExRate.ExchangeRate, null);
    } else {
      serviceObj = setServiceValue(service, 'BillingExchangeRate', 0, null);
    }
    onUpdateTemplateService(serviceIndex, serviceObj)
  }, [service]);

  const getPriceConversion = useCallback(() => {
    let serviceObj: any;
    let priceExRate: any = getExchangeRates(exchangeRates, service?.PriceCurrencyCode, defaultCurrencyCode)
    if (priceExRate) {
      serviceObj = setServiceValue(service, 'PriceExchangeRate', priceExRate.ExchangeRate, null);
    } else {
      serviceObj = setServiceValue(service, 'PriceExchangeRate', 0, null);
    }
    onUpdateTemplateService(serviceIndex, serviceObj)
  }, [service]);

  const onChangeDropdownValue = useCallback((e: any, id: string) => {
    props.onUpdateService(serviceIndex, service, id, e)
    if (id === 'estimatedCurrency') {
      getCostConversion()
    }
    if (id === 'actualCurrency') {
      getPriceConversion();
      getRates();
    }
    if (id === 'billingCurrency') {
      getRates();
    }
  }, [])

  const onChangeInputValue = useCallback((e: any, id: string) => {
    props.onUpdateService(serviceIndex, service, e.target?.id, e.target?.value)
  }, []);

  const onClickCheckbox = useCallback(() => {
    onSelectServices(serviceIndex)
  }, [serviceIndex]);

  const onChangeDateValue = useCallback((e: any, id: string) => {
    props.onUpdateService(serviceIndex, service, id, e.target.value)
  }, []);

  return (
    <Overview className={styleClass} id="serviceTemplate">
      <Overview>
        <ID isService>
          <input
            type="checkbox"
            checked={selectedList.includes(serviceIndex)}
            onChange={onClickCheckbox}
            style={{ cursor: 'pointer', marginRight: '8px' }} />
          {serviceIndex + 1}
        </ID>
        <ToolTip
          id="deleteToolTip"
          content={'Delete'}
          placement={'right'}
          animation={'scale-subtle'}
          arrow={true}
          duration={200}
          delay={[75, 0]}
        >
          <Delete
            isService>
            <DeleteIcon
              onClick={() => onDeleteService(serviceIndex)}
              style={{ cursor: 'pointer' }} />
          </Delete>
        </ToolTip>
        <ToolTip
          id="addToolTip"
          content={'Add Charge'}
          placement={'right'}
          animation={'scale-subtle'}
          arrow={true}
          duration={200}
          delay={[75, 0]}
        >
          <Add
            isService
            data-tip
            data-for='addIcon'>
            <AddNew
              style={{ cursor: 'pointer' }}
              onClick={() => onAddCharge(serviceIndex)} />
          </Add>
        </ToolTip>
        <ToolTip
          id="expandToolTip"
          content={'Hide/Show'}
          placement={'right'}
          animation={'scale-subtle'}
          arrow={true}
          duration={200}
          delay={[75, 0]}
        >
          <Expand
            isService
            data-tip
            data-for='expandIcon'>
            <div onClick={() => onExpandService()}>
              {
                showCharges ?
                  <Collapsed style={{ cursor: 'pointer' }} />
                  :
                  <Expanded style={{ cursor: 'pointer' }} />
              }
            </div>
          </Expand>
        </ToolTip>
        <Name isService>
          <Dropdown
            defaultValue={service?.Name}
            onChangeDropdown={onChangeDropdownValue}
            label={'name'}
            dropdownOptions={serviceList}
            dropdownLabel={'name'}
            dropdownValue={'name'}
            style={{ width: 199 }}
            placeHolder="Select service"
            dropdownMap={'services'}
            isMenuPortalTarget={'true'}
            domId={'serviceTemplate'}
            removeBorder={true}
            id="serviceName"
            isMandatory={true}
            removeIsClear={true}
          />
        </Name>
        {/* {selectedProduct.Code === 'BP0004' && <StartDate isService>
          <DatePicker
            style={{ height: 35 }}
            id={'startDate'}
            type={"dateTime"}
            onChange={onChangeDateValue}
          />
        </StartDate>}
        {selectedProduct.Code === 'BP0004' && <EndDate isService>
          <DatePicker
            style={{ height: 35 }}
            id={'endDate'}
            type={"dateTime"}
            onChange={onChangeDateValue}
          />
        </EndDate>} */}
        {!disableOperatingIncome &&
          <OperatingIncome isService />
        }
        {!disableRevenue && <UOM isService />}
        {!disableRevenue && <Quantity isService />}
        {!disableRevenue &&
          <UnitPrice isService />
        }
        {!disableRevenue &&
          <Currency isService>
            <Dropdown
              defaultValue={service?.PriceCurrencyCode}
              onChangeDropdown={onChangeDropdownValue}
              label={'code'}
              removeBorder={true}
              isLookup={false}
              dropdownOptions={currencyList}
              dropdownLabel={'code'}
              dropdownValue={'code'}
              placeHolder="Select currency"
              id="actualCurrency"
              appendParams={false}
              isMandatory={false}
              style={{ width: 79 }}
              isMenuPortalTarget={'true'}
              domId={'serviceTemplate'}
              removeIsClear={true}
            />
          </Currency>
        }
        {!disableRevenue &&
          <Amount isService />
        }
        {!disableRevenue &&
          <BillingCurrency isService>
            <Dropdown
              defaultValue={service?.BillingCurrencyCode}
              onChangeDropdown={onChangeDropdownValue}
              label={'code'}
              removeBorder={true}
              isLookup={false}
              dropdownOptions={currencyList}
              dropdownLabel={'code'}
              dropdownValue={'code'}
              placeHolder="Select currency"
              id="billingCurrency"
              appendParams={false}
              isMandatory={false}
              isMenuPortalTarget={'true'}
              domId={'serviceTemplate'}
              style={{ width: 85 }}
              removeIsClear={true}
            />
          </BillingCurrency>
        }
        {!disableRevenue &&
          <ExRate isService>{service?.BillingExchangeRate ? service?.BillingExchangeRate : 0}</ExRate>
        }
        {!disableRevenue &&
          <Tax isService />
        }
        {!disableRevenue &&
          <SalesTaxAmt isService />
        }
        {!disableRevenue &&
          <BillAmount isService />
        }
        {!disableCost &&
          <Supplier isService>
            <Dropdown
              defaultValue={service?.SupplierName}
              id={'supplier'}
              type={'charge'}
              isLookup={true}
              dropdownLabel={'name'}
              dropdownValue={'name'}
              appendParams={false}
              useDebounce={true}
              style={{ width: 199 }}
              removeBorder={true}
              isMenuPortalTarget={'true'}
              domId={'serviceTemplate'}
              url={`/mdm-search-supplier?serviceCode=${service?.Code}&companyCode=${companyCode}&searchText=`}
              placeHolder="Select supplier"
              onChangeDropdown={onChangeDropdownValue} />
          </Supplier>
        }
        {isShowCostQty && !disableCost && (
          <>
            <CostUOM isService />
            <CostQty isService />
          </>
        )}
        {!disableCost &&
          <EstUnitCost isService />
        }
        {!disableCost &&
          <EstCurrency isService>
            <Dropdown
              defaultValue={service?.CostCurrencyCode}
              id="estimatedCurrency"
              onChangeDropdown={onChangeDropdownValue}
              label={'code'}
              removeBorder={true}
              isLookup={false}
              dropdownOptions={currencyList}
              dropdownLabel={'code'}
              dropdownValue={'code'}
              isMandatory={false}
              isMenuPortalTarget={'true'}
              domId={'serviceTemplate'}
              placeHolder="Select currency"
              appendParams={false}
              style={{ width: 79 }}
              removeIsClear={true}
            />
          </EstCurrency>
        }
        {!disableCost &&
          <EstExRate isService>
            {service?.CostExchangeRate}
          </EstExRate>}
        {!disableCost &&
          <EstTaxType isService />
        }
        {!disableCost &&
          <EstTax isService />
        }
        {!disableCost &&
          <EstAmt isService />
        }
        {!disableCost &&
          <EstAmtTax isService />
        }
        {!disableCost &&
          <EsActualCost isService />
        }
        <InternalRemarks isService>
          <InputBox
            id="remarks"
            value={service?.InternalRemarks}
            placeholder={'Remarks'}
            onChange={onChangeInputValue}
            style={inputStyle}
          />
        </InternalRemarks>
      </Overview>
    </Overview>
  )
};

export default Service;
