
import {
  IS_SAVE_ONPROGESS,
  UPDATE_QUOTATION_DETAIL,
  SET_NAVIGATION_TAB,
  SET_BUSINESS_TYPE
} from "../ActionTypes/Quotation";
import { RESET_QUOTATION_STATE } from "Redux/ActionTypes/ActionTypes";
import { setQuotationSelectionValue } from 'Utils/Quotation';
import { Quotation } from "Model/Order/Quotation";
import { QuotationArea } from "Model/Order/QuotationArea";

var initialState: any = {
  quotation: {} as Quotation,
  selectedTab: '',
  selectedBusiness: [] as QuotationArea[],
  isSaveInProgess: false,
}

export const quotation = (state = initialState, action: any) => {
  switch (action.type) {

    case UPDATE_QUOTATION_DETAIL:
      const obj = setQuotationSelectionValue(state.quotation, action.key, action.event)
      return {
        ...state,
        quotation: { ...obj }
      }

    case IS_SAVE_ONPROGESS:
      return {
        ...state,
        isSaveInProgess: action.loading
      };

    case SET_NAVIGATION_TAB:
      return {
        ...state,
        selectedTab: action.tab
      };

    case SET_BUSINESS_TYPE:
      return {
        ...state,
        selectedBusiness: action.types
      };

    case RESET_QUOTATION_STATE:
      return {
        quotation: {},
        isSaveInProgess: false,
        selectedTab: '',
        selectedBusiness: []
      }

    default:
      return state
  }
}