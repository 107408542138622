
import { RiCloseFill } from 'react-icons/ri';
import Dropdown from 'Controls/Dropdown';
import { movementType } from 'Constants/DropdownOptions';

interface Props {
  index: number;
  optionDetail: any;
  onUpdateValue: Function;
  handleDelete: Function;
  portName: any;
  terminalName: any;
  berthName: any;
}

const VoyageRouteLine = (props: Props) => {
  const {
    index,
    onUpdateValue,
    handleDelete,
    optionDetail,
  } = props;
  return (
    <div className="grid grid-cols-12 gap-4 p-2 border-b border-light border-gray-20 items-center">
      <div className={'flex flex-col space-y-2'}>
        <h4 className="text-sm font-semibold text-dark">{index + 1}</h4>
      </div>
      <div className={'col-span-2 flex flex-col space-y-2'}>
        <Dropdown
          id={'drpPort'}
          type={'text'}
          defaultValue={optionDetail?.RouteDetail[0]?.PortName}
          value={optionDetail?.RouteDetail[0]?.PortName}
          placeHolder={'Select Port'}
          isLookup={true}
          dropdownLabel={'name'}
          dropdownValue={'name'}
          appendParams={false}
          isMandatory={true}
          getFormattedLabel={true}
          scrollToTop={true}
          showErrorMessage={'mandatory field'}
          url={`/mdm-port?searchText=`}
          onChangeDropdown={(event: any, key: any) => onUpdateValue(event, 'port')}
        />
      </div>
      <div className={'col-span-3 flex flex-col space-y-3'}>
        <Dropdown
          id={'terminal'}
          type={'text'}
          defaultValue={optionDetail?.RouteDetail[0]?.TerminalName}
          value={optionDetail?.RouteDetail[0]?.TerminalName}
          placeHolder={'Select Terminal'}
          isLookup={true}
          dropdownLabel={'name'}
          dropdownValue={'name'}
          appendParams={false}
          isMandatory={true}
          getFormattedLabel={true}
          scrollToTop={true}
          showErrorMessage={'mandatory field'}
          url={`/mdm-terminal?searchText=`}
          onChangeDropdown={(event: any, key: any) => onUpdateValue(event, 'terminal')}
        />
      </div>

      <div className={'col-span-2 flex flex-col space-y-2'}>
        <Dropdown
          id={'drpBerth'}
          type={'text'}
          defaultValue={optionDetail?.RouteDetail[0]?.BerthName}
          value={optionDetail?.RouteDetail[0]?.BerthName}
          placeHolder={'Select Berth'}
          isLookup={true}
          dropdownLabel={'code'}
          dropdownValue={'code'}
          appendParams={false}
          isMandatory={false}
          getFormattedLabel={true}
          scrollToTop={true}
          url={`/mdm-berth?searchText=&portCode=&terminalCode=&code=`}
          onChangeDropdown={(event: any, key: any) => onUpdateValue(event, 'berth')}
        />
      </div>

      <div className={'col-span-2 flex flex-col space-y-2'}>
        <Dropdown
          id={'drpAgent'}
          isLookup={false}
          dropdownLabel={'name'}
          dropdownValue={'code'}
          appendParams={false}
          scrollToTop={true}
          dropdownOptions={movementType}
          placeHolder="Select Agent"
          onChangeDropdown={(event: any, key: any) => onUpdateValue(event, 'agent')}
        />
      </div>

      <div className={'col-span-2 flex flex-col space-y-2'}>
        <div className={'self-left'}>
          <button onClick={() => handleDelete()}>
            <RiCloseFill size="1.2em" className="fill-current text-danger group-hover:text-danger-shade" />
          </button>
        </div>
      </div>
    </div>
  );
};


export default VoyageRouteLine;
