
import { useSelector } from 'react-redux';
import {
    OverviewHeader,
    // list
    OfficeID,
    OfficeName,
    OfficeTypeName,
    OfficeIsActive,
    IsMainOffice,
    DepartmentID,
    DepartmentName,
    Code,
    Description,
    DepartmentIsActive,
    LoaderOverview,
} from './ServiceVisibility.styles';
import Loader from "Components/Loader";

type Props = {
    type?: string;
};

const ServiceVisibilityHeader = (props: Props) => {
    const { type } = props;
    let isDataLoading = useSelector((state: any) => state.ServiceTemplates.isTemplateLoading);
    const styleClass = "text-left text-xs font-medium text-gray-500 tracking-wider bg-gray-50";

    if (isDataLoading) {
        return (
            <LoaderOverview>
                <Loader gridLoader={true} color={'#aacbe9'} />
            </LoaderOverview>
        );
    }

    return (
        <OverviewHeader className={styleClass} id="stickyPosition">
            {type === 'Office' ?
                (
                    <>
                        <OfficeID isHeader>

                        </OfficeID>
                        <OfficeName isHeader>
                            Name
                        </OfficeName>

                        <OfficeTypeName isHeader>
                            Office Type Name
                        </OfficeTypeName>
                        <IsMainOffice isHeader>
                            Is Main Office
                        </IsMainOffice>
                        <OfficeIsActive isHeader>
                            Is Active
                        </OfficeIsActive>

                    </>
                ) : type === 'Department' ? (
                    <>
                        <DepartmentID isHeader>
                        </DepartmentID>
                        <DepartmentName isHeader>
                            Name
                        </DepartmentName>
                        <Code isHeader>
                            Code
                        </Code>
                        <Description isHeader>
                            Description
                        </Description>
                        <DepartmentIsActive isHeader>
                            Is Active
                        </DepartmentIsActive>
                    </>
                ) : <div />}

        </OverviewHeader>
    )
};

export default ServiceVisibilityHeader;

