import styled from "styled-components";

interface IStylesProps {
  isSelected?: boolean;
}

export const DisplayCenter = styled.div<IStylesProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;
export const AlignSelf = styled.div<IStylesProps>`
    justify-content: center;
    align-items: center;
`;
export const Self = styled.div<IStylesProps>`
    
`;
export const Overview = styled.div<IStylesProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

export const Header = styled.div<IStylesProps>`
    color: #1d659c;
    font-size: 32px;
    font-weight: 500;
`;
export const Content = styled.div<IStylesProps>`
    display: flex;
    margin: 32px 0px;
`;

export const Submit = styled.div<IStylesProps>`
   display: flex;
   width: 90%;
   justify-content: flex-end;
   margin-right: 24px;
   color: #222;
   font-size: 16px;
`;

export const Proceed = styled.div<IStylesProps>`
    cursor: pointer;
`;

export const QuotationBoxOverview = styled.div<IStylesProps>`
    border: ${(props) => (props.isSelected ? '2px solid #1d659c' : '2px solid #d4d4d4')};
    cursor: pointer;
    border-radius: 8px ;
    overflow: hidden ;
    width: 240px;
    margin: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:hover {
      border: 2px solid #1d659c;
    }
`;

export const Title = styled.div<IStylesProps>`
    font-weight: 700;
    color: ${(props) => (props.isSelected ? '#1d659c' : '#000')};
    font-size: 16px;
    text-transform: capitalize;
    ${QuotationBoxOverview}:hover & {
      color: #1d659c !important;
    }
`;

export const Description = styled.div<IStylesProps>`
    color: #222 !important;
    font-size: 12px;
    margin: 4px;
    text-align: center;
    padding: 12px;
`;

export const Icon = styled.div<IStylesProps>`
  height: 60px;
  width: 60px;
`;

export const IconPosition = styled.div<IStylesProps>`
  width: 100%;
  display: flex;
  justify-content: end;
  height: 24px;
  padding-top: 8px;
  padding-right: 12px;
`;


