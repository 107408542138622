import { connect } from 'react-redux';
import { updateServices } from 'Redux/Actions/services';
import ServiceList from './ServiceList';
import { updateVesselServices } from 'Redux/Actions/Vessel';

const mapDispatchToProps = (dispatch: any) => ({
  updateServices: (services: any) => dispatch(updateServices(services)),
  updateVesselServices: (id: any, services: any) => dispatch(updateVesselServices(id, services))
});

const mapStateToProps = (state: any) => ({
  services: state.services.serviceList,
  vesselDetails: state.vessel.optionDetails,
  freightDetails: state.freightDetails
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceList);