import {
  Flex,
  ContainerQuantity,
  ContainerWeight,
  ContainerType
} from '../../cargo.styles'

const Header = (props: any) => {
  return (
    <Flex>
      <ContainerQuantity>
        <label className="text-sm font-semibold text-dark">Quantity</label>
      </ContainerQuantity>
      <ContainerWeight>
        <label className="text-sm font-semibold text-dark">Type</label>
      </ContainerWeight>
      <ContainerType>
        <label className="text-sm font-semibold text-dark">Total Cargo Weight (kg)</label>
      </ContainerType>
    </Flex>
  )
};

export default Header;