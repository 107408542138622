import React from "react";

const LifeBuoy = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 512 512"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="442.42"
          x2="69.58"
          y1="256"
          y2="256"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#5c5e9c"></stop>
          <stop offset="0.18" stopColor="#64649f"></stop>
          <stop offset="0.46" stopColor="#7975a9"></stop>
          <stop offset="0.79" stopColor="#9b91b9"></stop>
          <stop offset="1" stopColor="#b3a5c4"></stop>
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="133.39"
          x2="263.88"
          y1="136.2"
          y2="263.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#ffc782"></stop>
          <stop offset="0.18" stopColor="#fdb482"></stop>
          <stop offset="0.51" stopColor="#fa9582"></stop>
          <stop offset="0.8" stopColor="#f98382"></stop>
          <stop offset="1" stopColor="#f87c82"></stop>
        </linearGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="314.53"
          x2="356.53"
          y1="318"
          y2="354"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.01" stopColor="#fff"></stop>
          <stop offset="0.44" stopColor="#ecf1f8"></stop>
          <stop offset="0.99" stopColor="#cfdbed"></stop>
        </linearGradient>
        <linearGradient
          id="linear-gradient-4"
          x1="147.12"
          x2="203.79"
          y1="126.27"
          y2="228.27"
          xlinkHref="#linear-gradient-3"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-5"
          x1="309.66"
          x2="384.66"
          y1="171.63"
          y2="175.63"
          xlinkHref="#linear-gradient-3"
        ></linearGradient>
        <linearGradient
          id="linear-gradient-6"
          x1="175.35"
          x2="171.35"
          y1="284.69"
          y2="384.69"
          xlinkHref="#linear-gradient-3"
        ></linearGradient>
      </defs>
      <g>
        <path
          fill="url(#linear-gradient)"
          d="M256 442.42a53.29 53.29 0 01-37.95-15.72L85.3 294a53.67 53.67 0 010-75.9L218.05 85.3a53.67 53.67 0 0175.9 0L426.7 218.05a53.67 53.67 0 010 75.9L294 426.7a53.29 53.29 0 01-38 15.72zm0-364.82A45.55 45.55 0 00223.7 91L91 223.7a45.75 45.75 0 000 64.6l132.7 132.75a45.75 45.75 0 0064.6 0L421.05 288.3a45.75 45.75 0 000-64.6L288.3 91A45.55 45.55 0 00256 77.6z"
        ></path>
        <path
          fill="url(#linear-gradient-2)"
          d="M256 94.1A161.9 161.9 0 10417.9 256 161.89 161.89 0 00256 94.1zm0 243.8a81.9 81.9 0 1181.9-81.9 82 82 0 01-81.9 81.9z"
        ></path>
        <path
          fill="url(#linear-gradient-3)"
          d="M313.91 313.91a81.26 81.26 0 01-27 17.95l58.9 58.9a163.11 163.11 0 0045-45l-58.9-58.9a81.26 81.26 0 01-18 27.05z"
        ></path>
        <path
          fill="url(#linear-gradient-4)"
          d="M198.09 198.09a81.26 81.26 0 0127-18l-58.9-58.9a163.11 163.11 0 00-45 45l58.9 58.9a81.26 81.26 0 0118-27z"
        ></path>
        <path
          fill="url(#linear-gradient-5)"
          d="M345.77 121.24l-58.9 58.9a81.94 81.94 0 0145 45l58.9-58.9a163.11 163.11 0 00-45-45z"
        ></path>
        <path
          fill="url(#linear-gradient-6)"
          d="M166.23 390.76l58.9-58.9a81.94 81.94 0 01-45-45l-58.9 58.9a163.11 163.11 0 0045 45z"
        ></path>
      </g>
    </svg>
  );
}

export default LifeBuoy;
