import {
  UPDATE_CUSTOMER_DETAILS,
  UPDATE_CUSTOMER_VALIDATIONS,
  UPDATE_QUOTATION_CUSTOMER_DETAILS,
  UPDATE_CUSTOMER_ADDITIONAL_DETAIL,
  UPDATE_ACCOUNT_MANAGER,
  CREATE_NEW_CUSTOMER,
} from "../ActionTypes/Customer";
import store from "Redux/Store";
import axios from 'axios';
import { RESET_CUSTOMER_STATE, RESET_NEW_CUSTOMER_STATE } from "Redux/ActionTypes/ActionTypes";

export function updateCustomerDetails(event: any, key: string) {
  return {
    type: UPDATE_CUSTOMER_DETAILS,
    event,
    key
  };
}

export function createNewCustomer(event: any, key: string) {
  return {
    type: CREATE_NEW_CUSTOMER,
    event,
    key
  };
}

export function updateCustomerAdditionalDetails(data: object) {
  return {
    type: UPDATE_CUSTOMER_ADDITIONAL_DETAIL,
    data
  };
}

export function updateAccountManager(payload: string) {
  return {
    type: UPDATE_ACCOUNT_MANAGER,
    payload
  };
}

export function updateCustomerValidation(validation: {}) {
  return {
    type: UPDATE_CUSTOMER_VALIDATIONS,
    validation
  };
}

export function resetCustomerState() {
  return {
    type: RESET_CUSTOMER_STATE
  };
}

export function resetNewCustomerState() {
  return {
    type: RESET_NEW_CUSTOMER_STATE
  };
}

export function updateQuotationCustomerDetails(data: object) {
  return {
    type: UPDATE_QUOTATION_CUSTOMER_DETAILS,
    data
  };
}

export function addNewCustomer() {
  return (dispatch: (arg: any) => void) => {
    // requires different client id and secret key other than single sign on authentication, for this particular request
    const state = store.getState();
    const newCustomerDetails: any = state.customer
    const payload = newCustomerDetails?.newCustomer
    return axios.post(`${process.env.REACT_APP_API_URL}/mdm-create-party`,
      payload
    )
  }
}