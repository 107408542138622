import axios from 'axios';
import { toast } from 'react-toastify';
import {
  GET_PORT_DETAILS,
  UPDATE_PORT_DETAILS,
  SAVE_PORT_DETAILS,
  SET_ERROR_DATA,
  PORT_LOADING,
  PORT_SAVING,
  UPDATE_CENTER,
  GET_PORT_EDITOR_DETAILS,
  SET_PORT_EDITOR_LOADING,
  UPDATE_TERMINAL_DETAILS,
  PORT_EDITOR_SAVING,
  UPDATE_PORT_EDITOR_DETAILS
} from '../ActionTypes/Port'
import store from "Redux/Store";

export const updatePortDetail = (key: string, value: any) => {
  return {
    type: UPDATE_PORT_DETAILS,
    key,
    value
  }
};

export const updateCenter = (value: any) => {
  return {
    type: UPDATE_CENTER,
    value
  }
};

export const setLoading = (value: boolean) => {
  return {
    type: PORT_LOADING,
    value
  }
};

export const savePortDetail = (obj: object) => {
  return (dispatch: (arg: any) => void) => {
    return axios.post('/oum-savePortAreaAssignment', obj).then((response: any) => {
      if (response.data) {
        dispatch(setPortData(response.data));
        dispatch(setPortSaving(false));
      } else {
        dispatch(setPortDataError(response.data));
        dispatch(setPortSaving(false));
      }
    }).catch((err) => {
      let message = "Please Reload the window";
      if (err.response) {
        if (Array.isArray(err.response.data)) {
          if (err.response.data.length > 0)
            message = err.response.data.join(", ");
        }
        else {
          message = err.response.data;
        }
      }
      else {
        message = "Network error.Please try again.";
      }
      toast.error(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        style: { width: '400px' },
      })
      dispatch(setPortSaving(false));
    })
  }
};

export const setPortData = (response: any) => {
  return {
    type: SAVE_PORT_DETAILS,
    response
  }
};

export const setDetails = (response: any) => {
  return {
    type: GET_PORT_DETAILS,
    response
  }
};

export const setPortDataError = (response: any) => {
  return {
    type: SET_ERROR_DATA
  }
};

export const setPortSaving = (value: boolean) => {
  return {
    type: PORT_SAVING,
    value
  }
};

export const getPortDetails = (id: number) => {
  return (dispatch: (arg: any) => void) => {
    return axios.get(`/oum-getPortAreaAssignment?id=${id}`).then((response: any) => {
      if (response.data) {
        dispatch(setDetails(response.data));
      } else {
        dispatch(setPortDataError(response.data));
      }
      dispatch(setLoading(false));
    })
  }
};

export const getPortEditorDetails = (id: number) => {
  return (dispatch: (arg: any) => void) => {
    dispatch(setPortEditorLoading(true));
    return axios.get(`/oum-getPortTerminalBerth?portId=${id}`).then((response: any) => {
      if (response.data) {
        dispatch(setPortEditorData(response.data));
      }
      dispatch(setPortEditorLoading(false));
    })
  }
};

export const setPortEditorLoading = (value: boolean) => {
  return {
    type: SET_PORT_EDITOR_LOADING,
    value
  }
};

export const setPortEditorData = (response: any) => {
  return {
    type: GET_PORT_EDITOR_DETAILS,
    response
  }
};

export const savePortEditorData = () => {
  const data: any = store.getState();
  let request = {
    Port: data?.port?.portEditorDetails,
    Terminal: data?.port?.terminals
  }
  return (dispatch: (arg: any) => void) => {
    dispatch(setPortEditorSaving(true))
    return axios.post('/oum-savePortTerminalBerthArea', request).then((response: any) => {
      dispatch(setPortEditorSaving(false))
    }).catch((err) => {
      let message = "Please Reload the window";
      if (err.response) {
        if (Array.isArray(err.response.data)) {
          if (err.response.data.length > 0)
            message = err.response.data.join(", ");
        }
        else {
          message = err.response.data;
        }
      }
      else {
        message = "Network error.Please try again.";
      }
      toast.error(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        style: { width: '400px' },
      })
      dispatch(setPortEditorSaving(false));
    })
  }
};

export const updateTerminalDetails = (terminals: any) => {
  return {
    type: UPDATE_TERMINAL_DETAILS,
    terminals
  }
};

export const updatePortDetails = (port: any) => {
  return {
    type: UPDATE_PORT_EDITOR_DETAILS,
    port
  }
};

export const setPortEditorSaving = (value: boolean) => {
  return {
    type: PORT_EDITOR_SAVING,
    value
  }
};