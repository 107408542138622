import React from 'react';
import Dropdown from 'Controls/Dropdown';
import {
  ListOptions,
  Label,
  ListItem,
  Footer
} from '../editor.styles';
import PegButton from 'Controls/Button/PegButton';

type MyState = {
  teminalList: any,
  berthList: any
};

type MyProps = {
  terminals: any;
  selectedTerminal: any;
  selectedBerth: any;
  terminalPolygon: any;
  portDetails: any;
  isValidated: boolean;
  updateSelectedTeminal: (value: any) => void;
  updateSelectedBerth: (value: any) => void;
  onSubmitSave: () => void;
  onSubmitReset: () => void;
};

class Options extends React.Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      teminalList: [],
      berthList: []
    };
  }

  componentDidMount() {
    const {
      terminals
    } = this.props
    let teminalList = terminals.map((terminal: any) => {
      return {
        ...terminal?.Terminal
      }
    })
    this.setState({ teminalList })
  }

  onChangeDropdownValue = (value: any, id: any) => {
    const {
      terminals,
      updateSelectedBerth,
      updateSelectedTeminal
    } = this.props
    if (id === 'selectedTerminal') {
      let list = terminals.find((terminal: any) => terminal?.Terminal.Id === value.Id)
      this.setState({ berthList: list?.Berth })
      updateSelectedTeminal(value)
    } else {
      updateSelectedBerth(value)
    }

  }

  render() {
    const {
      teminalList,
      berthList
    } = this.state

    const {
      selectedTerminal,
      selectedBerth,
      terminalPolygon,
      portDetails,
      onSubmitSave,
      onSubmitReset
    } = this.props

    return (
      <ListOptions>
        <ListItem>
          <Label>{portDetails?.Name} - {portDetails?.CountryName}</Label>
        </ListItem>
        {
          portDetails?.Area?.length ?
            <div>
              <ListItem>
                <Label>Select Terminal</Label>
                <Dropdown
                  defaultValue={selectedTerminal}
                  onChangeDropdown={this.onChangeDropdownValue}
                  label={'Name'}
                  isLookup={false}
                  dropdownOptions={teminalList}
                  dropdownLabel={'Name'}
                  dropdownValue={'Name'}
                  placeHolder="Select Terminal"
                  id="selectedTerminal"
                  appendParams={false}
                  isMandatory={true}
                  removeIsClear={true}
                />
              </ListItem>
              {
                (selectedTerminal && terminalPolygon?.length) ?
                  <ListItem>
                    <Label>Select Berth</Label>
                    <Dropdown
                      defaultValue={selectedBerth}
                      onChangeDropdown={this.onChangeDropdownValue}
                      label={'Name'}
                      isLookup={false}
                      dropdownOptions={berthList}
                      dropdownLabel={'Name'}
                      dropdownValue={'Name'}
                      placeHolder="Select Berth"
                      id="selectedBerth"
                      appendParams={false}
                      isMandatory={true}
                      removeIsClear={true}
                    />
                  </ListItem> :
                  <div />
              }
            </div>
            :
            <Label>Draw the port area</Label>
        }
        <Footer>
          <PegButton
            id="servicetemplete_addrow"
            type="button"
            variant="Primary"
            onClick={onSubmitSave}>
            Save Port
          </PegButton>
          <PegButton
            id="servicetemplete_addrow"
            type="button"
            variant="Danger"
            styleObject={{ marginLeft: 12 }}
            onClick={onSubmitReset}>
            Reset
          </PegButton>
        </Footer>
      </ListOptions>
    )
  }
}

export default Options;
