export const isMandatory = 'Field is required'
// customer validation message
export const customerRequired = 'Customer name is required'
export const emailRequired = 'Email is required'
export const phoneRequired = 'Phone is required'
export const countryRequired = 'Country is required'
export const addressRequired = 'Address is required'
export const salesPersonRequired = 'Sales person is required'
export const quotationDateRequired = 'Quotation date is required'
export const dueDateRequired = 'Due date is required'
export const paymentTermRequired = 'Payment term required'

//vessel section validation message

export const vesselNameRequired = 'Vessel name is required'
export const cargoTypeRequired = 'Cargo Type is required'

// cargo section validation message

export const commodityNameRequired = 'Commodity name is required'
export const commodityTypeRequired = 'Commodity Type is required'
export const hsCodeRequired = 'HS code required'


export const optionNameRequired = 'Option name is required'
export const portRequired = 'Port is required'
export const terminalRequired = 'Terminal is required'
export const agentRequired = 'Agent is required'
export const portNameRequired = 'Port name is required'
export const terminalNameRequired = 'Terminal name is required'
