import {
  InternalRemarks,
  Enddate,
  Startdate,
  Totalcost,
  Costcurrency,
  Unitcost,
  Supplier,
  NetSelling,
  NetLocal,
  Taxrate,
  Taxtype,
  Netprice,
  Margin,
  Discount,
  Unitprice,
  Quantity,
  UOM,
  OpertionIncome,
  Billing,
  ServiceName,
  Delete,
  Expand,
  ID,
} from 'Containers/ServiceGrid/services.styles';
import { RiShip2Fill, RiTruckFill } from 'react-icons/ri';
import { GiMiniSubmarine } from "react-icons/gi";

export const landingRoutes = ['/login'];

export const BUSINESS_TYPES = [
  { type: 'shipping', description: "Vessel Call, Ship Agency, Husbandry Services", icon: RiShip2Fill },
  // { type: 'logistics', description: "Freight, Customs Clearances", icon: RiTruckFill },
  // { type: 'marine', description: "Warehousing solutions", icon: GiMiniSubmarine },
];

export const CHARGE = {
  AIR_FREIGHT_FEE: 'CH0571',
  SEA_FREIGHT_FEE: 'CH0572',
}

export const SERVICE = {
  AIRFREIGHT: 'SV0001',
  SEAFREIGHT: 'SV0117',
}

export const BUSINESS_CODE = [
  {
    code: 'BP0001',
    type: 'freight'
  },
  {
    code: 'BP0002',
    type: 'warehouse'
  },
  {
    code: 'BP0003',
    type: 'customs'
  },
  {
    code: 'BP0004',
    type: 'shipAgency'
  },
  {
    code: 'BP0005',
    type: 'husbandryServices'
  }
];

export const DROPDOWN_WIDTH_SIZE = '220px';

export const OFFICE_CODE = "AEDXB02";

export const STEPS = [
  "DRAFT",
  "CUSTOMER APPROVAL",
  "APPROVED",
  "JOB CREATED"
];

export const TAB_OPTIONS = [
  { id: 0, route: 'customer', label: 'Customer', enableKey: 'isCustomerCompleted' },
  { id: 1, route: 'vessel', label: 'Vessel', enableKey: 'isVesselCompleted' },
  { id: 2, route: 'freight', label: 'Freight', enableKey: 'isFreightCompleted' },
  { id: 3, route: 'route', label: 'Route', enableKey: 'isRouteCompleted' },
  { id: 4, route: 'cargo', label: 'Cargo', enableKey: 'isCargoCompleted' },
  { id: 5, route: 'crew', label: 'Crew', enableKey: 'isCrewCompleted' },
  { id: 6, route: 'services', label: 'Services', enableKey: 'isServicesCompleted' }
]
export const TAB_OPTIONS_SHIPPING = [
  { id: 0, route: 'customer', label: 'Customer', enableKey: 'isCustomerCompleted' },
  { id: 1, route: 'vessel', label: 'Vessel', enableKey: 'isVesselCompleted' },
  { id: 2, route: 'cargo', label: 'Cargo', enableKey: 'isCargoCompleted' },
  { id: 3, route: 'crew', label: 'Crew', enableKey: 'isCrewCompleted' },
  { id: 4, route: 'services', label: 'Services', enableKey: 'isServicesCompleted' }
]

export const TAB_OPTIONS_LOGISTICS = [
  { id: 0, route: 'customer', label: 'Customer', enableKey: 'isCustomerCompleted' },
  { id: 1, route: 'freight', label: 'Freight', enableKey: 'isFreightCompleted' },
  { id: 2, route: 'route', label: 'Route', enableKey: 'isRouteCompleted' },
  { id: 3, route: 'cargo', label: 'Cargo', enableKey: 'isCargoCompleted' },
  { id: 4, route: 'crew', label: 'Crew', enableKey: 'isCrewCompleted' },
  { id: 5, route: 'services', label: 'Services', enableKey: 'isServicesCompleted' }
]

export const sheetHeader = [
  {
    id: 'SR-1',
    key: 'id',
    label: 'ID',
    style: {},
    component: ID
  },
  {
    id: 'SR-2',
    key: 'delete',
    label: '',
    style: {},
    component: Expand
  },
  {
    id: 'SR-26',
    key: 'addCharge',
    label: '',
    style: {},
    component: Delete
  },
  {
    id: 'SR-3',
    key: 'expand',
    label: '',
    style: {},
    component: Delete
  },
  {
    id: 'SR-4',
    key: 'name',
    label: 'Service/Charge Name',
    style: {},
    component: ServiceName,
  },
  {
    id: 'SR-5',
    key: 'billingParty',
    label: 'Billing Party',
    style: {},
    component: Billing,
  },
  {
    id: 'SR-6',
    key: 'operatingIncome',
    label: 'Operating Income',
    style: {},
    component: OpertionIncome,
  },
  {
    id: 'SR-7',
    key: 'UOM',
    label: 'UOM',
    style: {},
    component: UOM
  },
  {
    id: 'SR-8',
    key: 'quantity',
    label: 'Quantity',
    style: {},
    component: Quantity,
  },
  {
    id: 'SR-9',
    key: 'unitprice',
    label: 'Unit price',
    style: {},
    component: Unitprice,
  },
  {
    id: 'SR-25',
    key: 'priceCurrency',
    label: 'Price currency',
    style: {},
    component: Unitprice,
  },
  {
    id: 'SR-10',
    key: 'discount',
    label: 'Discount',
    style: {},
    component: Discount,
  },
  {
    id: 'SR-11',
    key: 'margin',
    label: 'Margin price',
    style: {},
    component: Margin,
  },
  {
    id: 'SR-12',
    key: 'netprice',
    label: 'Net price',
    style: {},
    component: Netprice,
  },
  {
    id: 'SR-13',
    key: 'taxType',
    label: 'Tax type',
    style: {},
    component: Taxtype,
  },
  {
    id: 'SR-14',
    key: 'taxRate',
    label: 'Tax rate',
    style: {},
    component: Taxrate,
  },
  {
    id: 'SR-15',
    key: 'netPriceLocal',
    label: 'Net price local',
    style: {},
    component: NetLocal,
  },
  {
    id: 'SR-16',
    key: 'netPriceSelling',
    label: 'Net price selling',
    style: {},
    component: NetSelling,
  },
  {
    id: 'SR-17',
    key: 'supplier',
    label: 'Supplier',
    style: {},
    component: Supplier,
  },
  {
    id: 'SR-18',
    key: 'unitCost',
    label: 'Unit cost',
    style: {},
    component: Unitcost,
  },
  {
    id: 'SR-19',
    key: 'costCurrency',
    label: 'Cost currency',
    style: {},
    component: Costcurrency,
  },
  {
    id: 'SR-20',
    key: 'totalCost',
    label: 'Total cost',
    style: {},
    component: Totalcost,
  },
  {
    id: 'SR-22',
    key: 'startDate',
    label: 'Start date',
    style: {},
    component: Startdate,
  },
  {
    id: 'SR-24',
    key: 'endDate',
    label: 'End date',
    style: {},
    component: Enddate,
  },
  {
    id: 'SR-23',
    key: 'remarks',
    label: 'Remarks',
    style: {},
    component: InternalRemarks,
  },
];

export const chargeObject = {
  ServiceDetailId: "",
  QuotationFk: "",
  OptionFk: "",
  Version: "",
  OpTags: "",
  Code: "",
  Name: "",
  ParentServiceFk: "",
  ParentServiceCode: "",
  CustomerServiceName: "",
  SortOrder: "",
  AssignedTo: "",
  IsCharge: true,
  BillingPartyCode: "",
  BillingPartyName: "",
  SupplierCode: "",
  SupplierName: "",
  Quantity: "",
  UoMCode: "",
  UoMName: "",
  UnitCost: "",
  TotalCost: "",
  CostCurrencyCode: "",
  CostCurrencyName: "",
  CostExRate: "",
  UnitPrice: "",
  NetPrice: "",
  PriceCurrencyCode: "",
  PriceCurrencyName: "",
  PriceExRate: "",
  MarginPercent: "",
  DiscountPercent: "",
  NetPriceWithDiscount: "",
  TaxTypeCode: "",
  TaxTypeName: "",
  TaxRate: "",
  NetPriceWithDiscountTax: "",
  OperatingIncome: "",
  StartDateTime: "",
  EndDateTime: "",
  AgentRemarks: "",
  OperatorRemarks: "",
  MapReference: "",
  CreatedAt: "",
  CreatedBy: "",
  ModifiedAt: "",
  ModifiedBy: "",
  IsActive: "",
  validation: {
    Name: true,
    Code: true,
    UoMName: true,
    UoMCode: true,
  },
}

export const serviceObject = {
  ServiceDetailId: "",
  QuotationFk: "",
  OptionFk: "",
  Version: "",
  OpTags: "",
  Code: "",
  Name: "",
  ParentServiceFk: "",
  ParentServiceCode: "",
  CustomerServiceName: "",
  SortOrder: "",
  AssignedTo: "",
  IsCharge: false,
  BillingPartyCode: "",
  BillingPartyName: "",
  SupplierCode: "",
  SupplierName: "",
  Quantity: "",
  UoMCode: "",
  UoMName: "",
  UnitCost: "",
  TotalCost: "",
  CostCurrencyCode: "",
  CostCurrencyName: "",
  CostExRate: "",
  UnitPrice: "",
  NetPrice: "",
  PriceCurrencyCode: "",
  PriceCurrencyName: "",
  PriceExRate: "",
  MarginPercent: "",
  DiscountPercent: "",
  NetPriceWithDiscount: "",
  TaxTypeCode: "",
  TaxTypeName: "",
  TaxRate: "",
  NetPriceWithDiscountTax: "",
  OperatingIncome: "",
  StartDateTime: "",
  EndDateTime: "",
  AgentRemarks: "",
  OperatorRemarks: "",
  MapReference: "",
  CreatedAt: "",
  CreatedBy: "",
  ModifiedAt: "",
  ModifiedBy: "",
  IsActive: "",
  charges: [{ ...chargeObject }],
  showCharges: true,
  validation: {
    UoMName: true,
    UoMCode: true,
    Name: true,
    Code: true,
  },
}

export const bulkEditTypes = {
  CostCurrencyCode: '',
  CostCurrencyName: '',
  PriceCurrencyCode: '',
  PriceCurrencyName: '',
  TaxTypeCode: '',
  TaxTypeName: '',
  SupplierCode: '',
  SupplierName: '',
  StartDateTime: '',
  EndDateTime: ''
}

export const MODEOF_TRANSFER_LIST = ['AIR', 'SEA', 'LAND'];

export interface ICrewItems {
  crew: boolean;
  accomodation: boolean;
  documentation: boolean;
  medical: boolean;
}

export const IFRAME_ROUTES = [
  '/job-services',
  '/quotation-services',
  '/service-template',
  '/port-selection',
  '/port-editor'
]

export const SERVICE_STATUS = {
  OPEN: "Open",
  ACCEPTED: "Accepted",
  COMPLETED: "Completed",
  REOPENNED: "Reopenned"
};

export const BILLING_STATUS_OPTIONS_LIST = [
  'ready',
  'Ready For Billing',
  'Billing In Progress',
  'Invoice Generated',
  'Billing Complete',
  'Re-Open',
  'Invoiced',
  'Assigned'
];

export enum BILLING_STATUS {
  INVOICED = "Invoiced",
  ASSIGNED = "Assigned",
  BILLING_IN_PROGRESS = "Billing In Progress",
  SEND_FOR_BILLING = "Send for Billing",
};

export const validationMessageJob = [
  { key: 'Name', message: 'Name is Required', isPriority: false },
  { key: 'BillingCurrencyCode', message: 'Billing Currency is Required', isPriority: false },
  { key: 'CostCurrencyCode', message: 'Cost Currency is Required', isPriority: false },
  { key: 'SupplierName', message: 'Supplier name is Required', isPriority: false },
  { key: 'PriceCurrencyCode', message: 'Price Currency Code is Required', isPriority: false },
  { key: 'BillingExchangeRate', message: 'Billing Exchange rate should not be 0', isPriority: true },
  { key: 'OperationalProcessPartyFk', message: 'Billing party is Required', isPriority: false },
  { key: 'Quantity', message: 'Quantity is Required', isPriority: false },
  { key: 'UomCode', message: 'UOM is Required', isPriority: false },
  { key: 'UnitPrice', message: 'Unit price is Required', isPriority: false },
  { key: 'UnitPrice0', message: 'Unit price should not be 0', isPriority: false },
  { key: 'UnitCost', message: 'Unit cost is Required', isPriority: false },
  { key: 'CustomerService', message: 'Customer Service is Required', isPriority: false }
]

export const VOUCHER_STATUS = {
  OPEN: 'Open',
  SENT: 'Sent',
  UPDATED: 'Updated',
  CANCELLED: 'Cancelled',
  REJECTED: 'Rejected',
  DELETED: 'Deleted',
  APPROVED: 'Approved'
};


export const VOUCHER_STATUS_VALUES = Object.values(VOUCHER_STATUS);

export const TariffBreakdown = [
  'Unit Price',
  'Quantity',
  'Additional Fee',
  'Total Amount',
  'Tariff Logic',
  'Instruction',
  'Overtime',
  'Duration',
  'BunkerSurcharge',
  'Maximum Amount',
  'NRT',
  'Calculated NRT',
  'Tariff Additional Rates'
]


export enum JobStatus {
  Draft = 1,
  Open = 2,
  ServicesComplete = 3,
  SupplierInvoicesMatched = 4,
  SupplierInvoicesPending = 5,
  Cancelled = 6,
  OperationallyClosed = 7,
  FullyInvoicedSupplierInvoicesPending = 8,
  PartiallyInvoiced = 9,
  Disputed = 10,
  Closed = 11,
  Expected = 12,
  PreArrivalCompleted = 13,
  Arrived = 14,
  Berthed = 15,
  Sailed = 16,
  CargoOperationsCompleted = 17,
  Inprogress = 18,
  ReadyForBilling = 19,
  FullyInvoiced = 20,
  BillingInProgress = 21,
  PartiallyInvoicedSupplierInvoicesPending = 22,
  NonBillable = 23,
  FinanciallyClosed = 24,
  ClosureInProgress = 25
}

export enum QuoteStatus {
  Draft = 60,
  Saving = 62,
  AgentAcknowledged = 63,
  AgentSubmission = 64,
  AgentPricingReview = 65,
  CustomerApproval = 66,
  CustomerApproved = 67,
  CustomerRejected = 68,
  Cancelled = 69,
  JobCreated = 70,
  Expired = 71
}

export enum VoucherType {
  Cache = 'Cash Voucher',
  PaymentRequest = 'Payment Request Voucher'
}

export const UnitPriceMinimum: number = 0.0001;

export const TAX_RATE_TYPES = {
  EXEMPTED: "EX",
  NOT_REPORTABLE: "NR",
  OUT_OF_SCOPE: "OS",
  REVERSED: "RV",
  STANDARD_RATE: "SR",
  TESTTAX: "RS",
  TESTTAX1: "JS",
  VAT_0: "0",
  VAT_5: "5",
  ZERO_RATE: "ZR"
}

export const EnableCostQtySettings = 'Enable Separate Cost Quantity Fields'