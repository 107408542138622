import {
  SET_JOB_GRID,
  SET_ERROR,
  SET_JOB_GRID_LOADING,
  ADD_SERVICES_JOB_GRID,
  DELETE_SERVICES_JOB_GRID,
  ADD_CHARGES_JOB_GRID,
  DELETE_CHARGES_JOB_GRID,
  EDIT_JOB_GRID,
  BULK_DELETE_SERVICES,
  UPDATE_JOB_SERVICES,
  UPDATE_JOB_CHARGES,
  ON_SAVE_LOADING,
  ON_SAVED,
  UPDATE_JOB_SERVICES_DETAILS,
  ON_BULK_UPDATE,
  ON_ADD_SERVICE_TEMPLATE,
  ADD_SERVICE_LOADING,
  ADD_CHARGE_LOADING,
  SET_SAVE_DISABLED,
  SET_VOUCHER_LIST,
  UPDATE_JOB_GRID,
  UPDATE_ON_APPLY_TARIFF,
  RESET_JOB_SERVICES,
  UPDATE_EDIT_JOB_SERVICES,
  SET_REFRESH_MESSAGE,
  SET_EXCHANGE_RATE,
  UPDATE_JOB_CHARGE_VALIDATION
} from "../ActionTypes/ActionTypes";
import { IService, Service } from 'Model/Job/JobService';
import { Charge, ICharge } from 'Model/Job/JobCharge';
import { createServiceObj } from 'Utils/JobGrid';
import { ISalesInvoice, IServicesAndCharges, ISupplierDetails } from "Model/Job/types";

let initialState = {
  jobDetails: {},
  services: [],
  voucherList: [],
  exchangeRate: [],
  isModified: false,
  isLoading: true,
  isRefreshLoader: false,
  isSaveDisabled: false,
  isError: false,
  isJobSaving: false,
  isJobSaved: false,
  isServiceAdding: false,
  isChargeAdding: false,
  isViewer: false,
  message: '',
  serviceValidation: {
    Name: true,
    BillingCurrencyCode: true,
    CostCurrencyCode: true,
    SupplierName: true,
    PriceCurrencyCode: true,
    BillingExchangeRate: true,
    CostExchangeRate: true,
  },
  chargeValidation: {
    Name: true,
    OperationalProcessPartyFk: true,
    Quantity: true,
    UomCode: true,
    UnitPrice: true,
    UnitCost: true
  },
  masterServiceValidation: {
    Name: true,
    SupplierName: true,
  },
  masterChargeValidation: {
    Name: true,
  },
  tariffServiceValidation: {
    SupplierName: true
  },
  tariffChargeValidation: {
    UomCode: true,
    OperationalProcessPartyFk: true,
    Quantity: true
  },
}
let serviceList: any[];
let charges: any;
export const JobServices = (state = initialState, action: any) => {
  switch (action.type) {

    case SET_JOB_GRID:
      const {
        response
      } = action
      if (Array.isArray(response)) {
        return {
          ...state,
          isLoading: false,
          isModified: false,
          message: response[0],
          isError: false,
          isRefreshLoader: true,
          isViewer: false
        };
      } else {
        const sortedServices = response.ServicesAndCharges.sort((a: any, b: any) => {
          const lineNumberA = parseInt(a.service.LineNumber);
          const lineNumberB = parseInt(b.service.LineNumber);

          if (!isNaN(lineNumberA) && !isNaN(lineNumberB)) {
            return lineNumberA - lineNumberB;
          } else if (!isNaN(lineNumberA)) {
            return -1;
          } else if (!isNaN(lineNumberB)) {
            return 1;
          } else {
            return 0;
          }
        });

        return {
          ...state,
          isLoading: false,
          isModified: false,
          jobDetails: response.JobDetails,
          services: [...state.services, ...sortedServices],
          isError: false,
          isRefreshLoader: false,
          isViewer: response.isViewer
        };
      }

    case RESET_JOB_SERVICES:
      return {
        ...state,
        services: []
      };

    case UPDATE_JOB_GRID:
      serviceList = [...action.response.ServicesAndCharges]
      return {
        ...state,
        isLoading: false,
        isModified: false,
        jobDetails: action.response.JobDetails,
        services: [...state.services, ...action?.response?.ServicesAndCharges],
        isError: false
      };

    case SET_ERROR:
      return {
        ...state,
        loading: false,
        isModified: false,
        jobDetails: {},
        services: [],
        isError: true,
      };

    case SET_REFRESH_MESSAGE:
      return {
        ...state,
        loading: false,
        isModified: false,
        isLoading: false,
        jobDetails: {},
        services: [],
        message: action.message,
        isRefreshMessage: true,
      };

    case SET_VOUCHER_LIST:
      const {
        res
      } = action
      return {
        ...state,
        isError: false,
        voucherList: res
      }

    case SET_EXCHANGE_RATE:
      const {
        exchangeRate
      } = action
      return {
        ...state,
        isError: false,
        exchangeRate: exchangeRate
      }

    case SET_JOB_GRID_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };

    case SET_SAVE_DISABLED:
      return {
        ...state,
        isSaveDisabled: action.value,
      };

    case ADD_SERVICES_JOB_GRID:
      const filteredServices = state.services?.filter((service: IServicesAndCharges) => service.service.IsActive)

      const highestLineNumber = filteredServices.reduce((maxLineNumber, service: any) => {
        return Math.max(maxLineNumber, service.service.LineNumber);
      }, 0);

      const lineNumberToSet = (highestLineNumber + 1).toString()

      const isLineNumberExists = state.services.some((service: IServicesAndCharges) => service.service.LineNumber === lineNumberToSet)
      const newLineNumber = isLineNumberExists ? highestLineNumber.toString() : lineNumberToSet

      let jobServiceObj: IServicesAndCharges = {
        charges: [
          { ...new Charge, LineNumber: "1" }
        ],
        service: {
          ...new Service,
          LineNumber: newLineNumber,
          RowOrder: state.services.length + 1,
        },
        serviceActivity: [],
        serviceRequestDetails: {
          CompletedDateTime: null,
          EndDateTime: null,
          Id: null,
          Rating: null,
          SRNumber: null,
          SRStatus: null,
          ServiceFk: null,
          ServiceStatus: "Open",
          StartDateTime: null,
          isShowSRLink: null,
        },
        supplierDetails: {} as ISupplierDetails,
        salesInvoice: [] as ISalesInvoice[]
      }
      const stateUpdatedServices: IServicesAndCharges[] = [...state.services, jobServiceObj]

      return {
        ...state,
        isModified: true,
        isServiceAdding: false,
        services: stateUpdatedServices
      };

    case DELETE_SERVICES_JOB_GRID:
      const { services } = state;

      const stateActiveServices = services?.filter((service: IServicesAndCharges) => service.service.IsActive);
      const stateInactiveServices = services?.filter((service: IServicesAndCharges) => !service.service.IsActive);

      let updatedServices: IServicesAndCharges[] = [...stateActiveServices, ...stateInactiveServices];
      const currService: IServicesAndCharges = updatedServices[action.serviceIndex];

      if (currService.service.Id !== 0) {
        currService.service.IsActive = false
        currService.service.LineNumber = null
      } else {
        updatedServices = stateActiveServices.filter((service: IServicesAndCharges, i: number) => i !== action.serviceIndex);
      }

      const updatedServicesAll = updatedServices.map((service, i) => ({
        ...service,
        service: {
          ...service.service,
          LineNumber: (i + 1).toString(),
        },
      }))

      return {
        ...state,
        isModified: true,
        services: updatedServicesAll,
      };

    case UPDATE_JOB_CHARGES:
      serviceList = state.services.filter((service: IServicesAndCharges) => service.service.IsActive);
      serviceList[action.serviceIndex].charges[action.chargeIndex] = action.charge
      const inactive = state.services.filter((service: IServicesAndCharges) => !service.service.IsActive)
      return {
        ...state,
        services: [...serviceList, ...inactive]
      }

    case UPDATE_JOB_SERVICES:
      serviceList = state.services.filter((service: IServicesAndCharges) => service.service.IsActive);
      serviceList[action.serviceIndex].service = action.service

      return {
        ...state,
        services: [...serviceList]
      };

    case UPDATE_EDIT_JOB_SERVICES:
      serviceList = state.services.filter((service: IServicesAndCharges) => service.service.IsActive);
      serviceList[action.serviceIndex] = action.serviceObj
      return {
        ...state,
        services: [...serviceList]
      };

    case ADD_CHARGES_JOB_GRID:
      let charge = new Charge;
      serviceList = state.services.filter((service: IServicesAndCharges) => service.service.IsActive);
      let service = serviceList[action.serviceIndex].service

      const maxActiveChargeCount = (serviceList[action.serviceIndex].charges?.filter((charge: ICharge) => charge.IsActive)?.length) ?? 0;

      charge.LineNumber = (maxActiveChargeCount + 1).toString()
      charge.BillingCurrencyCode = service?.BillingCurrencyCode
      charge.BillingExchangeRate = service?.BillingExchangeRate
      charge.PriceCurrencyCode = service?.PriceCurrencyCode
      charge.PriceExchangeRate = service?.PriceExchangeRate
      charge.CostCurrencyCode = service?.CostCurrencyCode
      charge.CostExchangeRate = service?.CostExchangeRate
      charges = serviceList[action.serviceIndex].charges;

      charges.push(charge);
      serviceList[action.serviceIndex].charges = charges
      return {
        ...state,
        isModified: true,
        isChargeAdding: false,
        services: [...serviceList]
      };


    case DELETE_CHARGES_JOB_GRID:
      const { serviceIndex, chargeIndex } = action;
      const updatedState = { ...state };
      const serviceListCopy: IServicesAndCharges[] = [...updatedState.services];
      const chargeList = serviceListCopy[serviceIndex]?.charges || [];
      let updatedCharges: Charge[] = [];

      const currCharge = chargeList[chargeIndex];

      if (currCharge.Id !== 0) {
        updatedCharges = chargeList.map((charge: Charge, index: number) =>
          index === chargeIndex
            ? {
              ...charge,
              IsActive: false,
            }
            : charge
        );
      } else {
        updatedCharges = chargeList.filter((charge: Charge, i: number) => i !== chargeIndex);
      }

      const activeCharges = updatedCharges?.filter((charge: Charge) => charge.IsActive);
      const updatedActiveCharges = activeCharges.map((charge, i) => ({
        ...charge,
        LineNumber: (i + 1).toString(),
      }));

      const inactiveCharges = updatedCharges.filter((charge) => !charge.IsActive);

      const parsedCharges = [...updatedActiveCharges, ...inactiveCharges];

      return {
        ...updatedState,
        isModified: true,
        services: serviceListCopy.map((service, index) =>
          index === serviceIndex ? { ...service, charges: parsedCharges } : service
        ),
      };


    case BULK_DELETE_SERVICES:
      const servicesList: any = state.services.filter((service: IServicesAndCharges) => service.service.IsActive);

      action.list.forEach((item: number) => {
        if (servicesList[item]) {
          servicesList[item].service.IsActive = false;
        }
      })

      const activeServicesBulkDelete = servicesList?.filter((service: IServicesAndCharges) => service.service.IsActive);
      activeServicesBulkDelete?.forEach((service: IServicesAndCharges, i: number) => {
        service.service.LineNumber = (i + 1).toString();
      });

      const inactiveServicesBulkDelete = servicesList?.filter((service: IServicesAndCharges) => !service.service.IsActive);
      const updatedBulkDeleteServices = [...activeServicesBulkDelete, ...inactiveServicesBulkDelete]

      return {
        ...state,
        isModified: true,
        services: updatedBulkDeleteServices
      };

    case ON_SAVE_LOADING:
      return {
        ...state,
        isJobSaving: action.value

      }

    case ON_SAVED:
      return {
        ...state,
        isJobSaved: action.value

      }

    case EDIT_JOB_GRID:
      return state;

    case UPDATE_JOB_SERVICES_DETAILS:
      serviceList = state.services.filter((service: IServicesAndCharges) => service.service.IsActive);
      serviceList[action.serviceIndex] = action.service

      return {
        ...state,
        services: [...serviceList]
      };

    case ON_BULK_UPDATE:
      return {
        ...state,
        isModified: true,
        services: [...action.services]
      };

    case ON_ADD_SERVICE_TEMPLATE:
      const activeServicesList = state.services.filter((service: IServicesAndCharges) => service.service.IsActive);
      const templateArray = createServiceObj(action.list, state.jobDetails)

      const sortedTemplate = templateArray.map((service, i) => ({
        ...service,
        service: {
          ...service.service,
          LineNumber: (activeServicesList.length + i + 1).toString(),
        },
        charges: service.charges.map((charge: ICharge, j: number) => ({
          ...charge,
          LineNumber: (j + 1).toString(),
        })),
      }));

      return {
        ...state,
        isModified: true,
        services: [...state.services, ...sortedTemplate]
      };

    case ADD_CHARGE_LOADING:
      return {
        ...state,
        isChargeAdding: true,
      };

    case ADD_SERVICE_LOADING:
      return {
        ...state,
        isServiceAdding: true
      };

    case UPDATE_ON_APPLY_TARIFF:
      return {
        ...state,
        services: [...action.serviceAndCharges]
      };

    case UPDATE_JOB_CHARGE_VALIDATION:
      const { validationKey, chargeCode, value } = action;

      const newChargeValidation = JSON.parse(JSON.stringify(state.chargeValidation));

      if (!newChargeValidation[validationKey]) newChargeValidation[validationKey] = {};

      newChargeValidation[validationKey][chargeCode] = value;

      return {
        ...state,
        chargeValidation: newChargeValidation
      };

    default:
      return state;

  }
}