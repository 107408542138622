import {
    GET_USER_DETAIL,
    UPDATE_USER_DETAIL
} from '../ActionTypes/User'
import { User } from 'Api/UserAgent';
import { toast } from "react-toastify";

export const getUserDetail = (userName: string | null) => {
    return (dispatch: any) => {
        User.getDefaultDetails(userName ? userName : 'thasneem.nisa@gac.com').then((result: any) => {
            if (Array.isArray(result)) {
                if (result.length == 0) {
                    dispatch(updateUserDetail(setEmptyUserDetail()));
                    toast.error("Network error.Please try again.", {
                        position: "top-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'dark',
                        style: { width: '400px' },
                    })
                }
            } else
                dispatch(updateUserDetail(result))
        }).catch((err: any) => {
            dispatch(updateUserDetail(setEmptyUserDetail()))
            toast.error(err, {
                position: "top-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
                style: { width: '400px' },
            })
        })
    }
};

export const setEmptyUserDetail = () => {
    let userObj = {
        VesselTypeName: "",
        AirportName: "",
        WeightUomName: "",
        PortName: "",
        CurrencyCode: "",
        CurrencyRoundOff: "",
        CompanyName: "",
        ShipmentType: "",
        PortCode: "",
        OfficeCode: "",
        UUID: "",
        IndividualId: "",
        WeightUomCode: "",
        DepartmentCode: "",
        VolumeName: "",
        VesselTypeCode: "",
        FirstName: "",
        MiddleName: "",
        Code: "",
        EmailAddress: "",
        CurrencyName: "",
        CurrencyDecimalPlaces: "",
        DefaultListing: "",
        AirportCode: "",
        Username: "",
        ModeOfTransport: "",
        Signature: "",
        VolumeCode: "",
        DocumentFooter: "",
        DepartmentName: "",
        LastName: "",
        InvoiceFooter: "",
        OfficeSignature: "",
        CompanyCode: "",
        OfficeName: "",
        Team: [],
        Mailboxes: []
    };
    return {};
}

export const updateUserDetail = (result: object) => {
    return {
        type: UPDATE_USER_DETAIL,
        payload: result
    }
};

export const setUserLoading = () => {
    return {
        type: GET_USER_DETAIL
    }
};