import { useState } from 'react';
import {
  ProductTabOverview,
  TabItem
} from '../../template.styles'
interface Prop {
  products: any[];
  selectedProduct: any;
  onSelectProduct: (product: any) => void;
}

const ProductList = (props: Prop) => {
  const {
    products,
    onSelectProduct,
    selectedProduct
  } = props;
  return (
    <ProductTabOverview>
      {products.map((product: any) => {
        return (
          <TabItem
            isSelected={product.Code === selectedProduct?.Code}
            onClick={() => onSelectProduct(product)}
            key={product.Code}>
            {product.Name}
          </TabItem>
        )
      })}
    </ProductTabOverview>
  );
};

export default ProductList;