import styled from 'styled-components';
import Image from 'Assets/Ship.jpeg';

interface IStylesProps {
  color?: string;
}
export const Wrapper = styled.div`
background: url(${Image}) no-repeat center center fixed; 
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
height: 100vh;
`;

export const Container = styled.div`
display: flex;
justify-content: end;
position: relative;
top: 60px;
right: 50px;
`;

export const BtnContainer = styled.div`
display: flex;
flex-direction: column;
align-items:end;
position: relative;
top: 80px;
right: 40px;
`;

export const Title = styled.h2`
font-size: 24px;
color: #0C4A7E;
font-weight: 1400px;
`;

export const Button = styled.button<IStylesProps>`
background-color: ${(props) => (props.color ? '#425E9A' : '#468CCA')};
width: 250px; 
height: 35px;
border: none;
color: white;
padding: 5px;
text-align: center;
text-decoration: none;
font-size: 16px;
margin-bottom: 8px;
`;

export const GacLogo = styled.img`
  width: 80px;
  height: 40px;
  margin-right: 8px;
`;