
import { connect } from 'react-redux';

import {
  updateCustomerValidation,
  resetCustomerState,
  resetNewCustomerState
} from 'Redux/Actions/Customer';
import {
  resetCargoState
} from 'Redux/Actions/cargo';
import {
  resetVesselState
} from 'Redux/Actions/Vessel';
import {
  resetCrewState
} from 'Redux/Actions/Crew';
import Quotation from './Quotation'
import { updateVesselValidation } from 'Redux/Actions/Vessel';
import { createQuotation, setQuotationLoading, resetQuotationState, setQuotationTab } from 'Redux/Actions/Quotation';

const mapDispatchToProps = (dispatch: any) => ({
  setQuotationTab: (tab: any) => dispatch(setQuotationTab(tab)),
  updateCustomerValidation: (validation: any) => dispatch(updateCustomerValidation(validation)),
  updateVesselValidation: (validation: any) => dispatch(updateVesselValidation(validation)),
  createQuotation: (history: any) => dispatch(createQuotation(history)),
  resetCustomerState: () => dispatch(resetCustomerState()),
  resetNewCustomerState: () => dispatch(resetNewCustomerState()),
  resetCargoState: () => dispatch(resetCargoState()),
  resetCrewState: () => dispatch(resetVesselState()),
  resetVesselState: () => dispatch(resetCrewState()),
  resetQuotationState: () => dispatch(resetQuotationState()),
  setQuotationLoading: (loading: boolean) => dispatch(setQuotationLoading(loading))
});

const mapStateToProps = (state: any) => ({
  selectedTab: state.quotation.selectedTab,
  selectedBusiness: state.quotation.selectedBusiness,
  isCrewEnabled: state.vessel.isCrewEnabled,
  isCargoEnabled: state.vessel.isCargoEnabled,
  isShipSparesEnabled: state.vessel.isShipSparesEnabled,
  quotation: state.quotation.quotation,
  isSaveInProgess: state.quotation.isSaveInProgess,
  errorCount: state.customer.errorCount,
  customerDetails: state.customer.customerDetails,
  vesselDetails: state.vessel.vesselDetails,
  optionDetails: state.vessel.optionDetails,
  cargoDetails: state.cargo.cargoDetails,
  crewDetails: state.crew.crewDetails,
  enabledRequests: state.crew.enabledRequests,
  serviceList: state.services.serviceList,
  optionsValidation: state.vessel.optionsValidation,
  vesselValidation: state.vessel.validation,
  customerValidation: state.customer.validation,
  cargoValidation: state.cargo.cargoInfoValidation,
  containerValidation: state.cargo.containerValidation,
  bulkDetailsValidation: state.cargo.bulkDetailsValidation,
  packageDetailsValidation: state.cargo.packageDetailsValidationm
});

export default connect(mapStateToProps, mapDispatchToProps)(Quotation);

