export const UPDATE_VESSEL_DETAILS = "UPDATE_VESSEL_DETAILS";
export const GET_VESSEL_DETAILS = "GET_VESSEL_DETAILS";
export const UPDATE_VESSEL_VALIDATIONS = "UPDATE_VESSEL_VALIDATIONS";
export const UPDATE_OPTION_DETAILS = "UPDATE_OPTION_DETAILS";
export const CREATE_VOYAGE_ROW = "CREATE_VOYAGE_ROW";
export const DELETE_VOYAGE_ROW = "DELETE_VOYAGE_ROW";
export const UPDATE_SERVICES = "UPDATE_SERVICES";
export const UPDATE_QUOTATION_VESSEL_DETAILS = "UPDATE_QUOTATION_VESSEL_DETAILS";
export const UPDATE_QUOTATION_OPTION_DETAILS = "UPDATE_QUOTATION_OPTION_DETAILS";
export const UPDATE_OPTION_VALIDATIONS = "UPDATE_OPTION_VALIDATIONS";
export const UPDATE_VESSEL_ADDITIONAL_DETAILS = "UPDATE_VESSEL_ADDITIONAL_DETAILS";
export const LINK_VESSEL_CALL = 'LINK_VESSEL_CALL';
export const LINK_OPTION_DETAILS = 'LINK_OPTION_DETAILS';
export const ENABLE_CREW_TAB = "ENABLE_CREW_TAB";
export const ENABLE_CARGO_TAB = "ENABLE_CARGO_TAB";
export const ENABLE_SHIP_SPARES = "ENABLE_SHIP_SPARES";

