
import Editor from './Editor'
import { connect } from 'react-redux';
import {
  getPortEditorDetails,
  savePortEditorData,
  updateTerminalDetails,
  setPortEditorSaving,
  updatePortDetails
} from 'Redux/Actions/port';

const mapDispatchToProps = (dispatch: any) => ({
  getPortEditorDetails: (id: any) => dispatch(getPortEditorDetails(id)),
  updateTerminalDetails: (terminals: any) => dispatch(updateTerminalDetails(terminals)),
  savePortEditorData: () => dispatch(savePortEditorData()),
  setPortEditorSaving: (value: boolean) => dispatch(setPortEditorSaving(value)),
  updatePortDetails: (port: any) => dispatch(updatePortDetails(port)),
});

const mapStateToProps = (state: any) => ({
  userDetails: state.user.userDetail,
  isLoading: state.port.editorLoading,
  portDetails: state.port.portEditorDetails,
  center: state.port.center,
  terminals: state.port.terminals,
  isPortEdited: state.port.isPortEdited,
  isPortEditorSaving: state.port.isPortEditorSaving
});

export default connect(mapStateToProps, mapDispatchToProps)(Editor);