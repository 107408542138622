import React from 'react';
import axios from 'axios';
import _ from 'lodash';
import {
  setServiceTemplate
} from 'Utils/ServiceTemplate';
import {
  filterDuplicate,
  duplicateTemplate
} from 'Utils/Generic';
import {
  IServiceTemplateVisibility,
  ServiceTemplateDefault,
  IServiceTemplateDetail,
  IServiceTemplateValidation
} from 'Model/ServiceTemplate/ServiceTemplatedefault';
import Loader from 'Components/Loader';
import { QuotationAlert } from 'Containers/Quotation/quotation.styles';
import PegModal from 'Controls/PegModal';
import Grid from './components/Grid';
import {
  GridView,
  Overview,
  Layout,
  VisibilityGridWrapper,
  Typography,
  DetailsWrapper,
} from 'Containers/ServiceTemplate/template.styles';
import ServiceTemplateDetails from './components/Details/ServiceTemplateDetails';
import ServiceVisibilityGrid from './components/ServiceVisibilityGrid';
import { LoaderOverview } from 'Containers/JobGrid/Jobs.styles';
import { getUserAccount } from 'Utils/AuthConfig';
import { BsCheckCircle } from 'react-icons/bs';

type MyState = {
  currencyList: [];
  serviceTemplateVisibilityList: [];
  businessProductList: [];
  serviceTemplate: ServiceTemplateDefault;
  selectedCompany: string;
  companyDetails: any;
  visibilityType: any;
  selectedDepartmentItem: any[];
  doesExistingUpdated: boolean;
  selectedOfficeItem: any[];
  dataSource: any[];
  serviceValidation: any,
  chargeValidation: any,
  serviceTemplateValidation: IServiceTemplateValidation,
  isFeildsMissing: boolean,
  opTags: any[],
  isOpenModal: boolean,
  removedBp: any[],
  isServiceTemplateSaved: boolean
};

type MyProps = {
  match?: any;
  //serviceTemplateDetails: {};
  userDetails: any;
  businessProducts: any[];
  serviceTemplate: ServiceTemplateDefault;
  visibilityGridData: any;
  selectedBusinessProductList: [];
  selectedCompany: string;
  visibilityType: string;
  isLoading: boolean;
  isServiceTemplateSaving: boolean;
  serviceAndCharges: any[];
  selectedProduct: any;
  templateDetails: any;
  isTemplateLoading: boolean;
  isError: boolean;
  servicesAndCharges: any;
  isDuplicate: boolean;
  //functions
  setIsDuplicate: (value: boolean) => void;
  setServiceTemplateSaving: (val: boolean) => void;
  getServiceTemplate: (id: any) => void;
  onUpdateServiceTemplate: (service: any) => void;
  saveServiceTemplate: (serviceTemplate: any, isDuplicate: any) => void;
  getVisibilityGridData: (type: any, selectedCompany: any) => void;
  onAddTemplateService: () => void;
  onDeleteTemplateService: (serviceIndex: number, opCode: number) => void;
  onAddTemplateCharge: (serviceIndex: number) => void;
  deleteServiceVisibility: (id: number, type: string) => void;
  onDeleteTemplateCharge: (serviceIndex: number, chargeIndex: number) => void;
  onUpdateTemplateCharge: (serviceIndex: number, chargeIndex: number) => void;
  onBulkDeleteService: () => void;
  onUpdateBusinessProduct: (products: any[]) => void;
  onSetDefaultProduct: () => void;
  saveServiceCharges: (list: any, product: any, isDuplicate: any) => void;
  refreshServiceVisibility: (visibilityType: string) => void;
  setVisibilityGridLoading: (value: any) => void;
  onUpdateBPserviceCharges: (product: any, servAndChar: any) => void;
  getUserDetail: (userName: any) => void;
  history: any,
  location: any
};

class ServiceTemplate extends React.Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      currencyList: [],
      opTags: [],
      serviceTemplate: {},
      serviceTemplateVisibilityList: [],
      businessProductList: [],
      selectedCompany: "",
      companyDetails: {},
      visibilityType: "",
      selectedDepartmentItem: [],
      doesExistingUpdated: false,
      selectedOfficeItem: [],
      dataSource: [],
      removedBp: [],
      isOpenModal: false,
      isFeildsMissing: false,
      serviceTemplateValidation: {
        Name: true,
        BaseCurrencyCode: true,
      },
      serviceValidation: {
        Name: true,
        // BillingCurrencyCode: true,
        // CostCurrencyCode: true,
        // PriceCurrencyCode: true
      },
      chargeValidation: {
        Name: true,
        // Quantity: true,
        // UomCode: true,
        // UnitPrice: true,
      },
      isServiceTemplateSaved: false
    };


    //setting up company as default in viusiblity list
    let selectedRowsList: IServiceTemplateVisibility[] = [];
    selectedRowsList.push({ "CompanyFk": this.state.selectedCompany, "IsActive": true });
    let serviceTemplateObj = setServiceTemplate(this.state.serviceTemplate, "visibilityList", selectedRowsList);
    this.props.onUpdateServiceTemplate(serviceTemplateObj);


    //setting to default company
    serviceTemplateObj = setServiceTemplate(this.props.serviceTemplate, "visibilityType", "Company");
    this.props.onUpdateServiceTemplate(serviceTemplateObj);
  }

  componentDidMount() {
    const { match, getUserDetail } = this.props
    const userName = getUserAccount('userName');
    getUserDetail(userName);
    if (match.params && match.params.id) {
      this.getTemplate(match);
    }
    const companyCode = new URLSearchParams(window.location.search).get("companycode");
    const isDuplicate = new URLSearchParams(window.location.search).get("isDuplicate");
    if (isDuplicate) {
      this.props.setIsDuplicate(true);
    }
    this.getCompanyDetails(companyCode);
    this.getDropdownValues();
    this.getOpTags()
  }

  getOpTags = () => {
    axios.get(`/getTags`).then((response) => {
      this.setState({ opTags: response.data })
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const {
      serviceTemplate
    } = nextProps;
    if (this.props.serviceTemplate?.ServiceTemplateDetails?.VisibilityType !== nextProps.serviceTemplate?.ServiceTemplateDetails?.VisibilityType) {
      this.props.setVisibilityGridLoading(true)
    }
    if (!this.state.doesExistingUpdated && serviceTemplate?.ServiceTemplateVisibility?.length) {
      let isDepartment = serviceTemplate?.ServiceTemplateVisibility.some((item: any) => item['DepartmentFk'])
      let isOffice = serviceTemplate?.ServiceTemplateVisibility.some((item: any) => item['OfficeFk'])
      if (isDepartment) {
        this.setState({
          selectedDepartmentItem: [...serviceTemplate?.ServiceTemplateVisibility],
          doesExistingUpdated: true
        })
      }
      if (isOffice) {
        this.setState({
          selectedOfficeItem: [...serviceTemplate?.ServiceTemplateVisibility],
          doesExistingUpdated: true
        })
      }
    }
  }

  getCompanyDetails = (companyCode: any) => {
    axios.get('oum-company?company-code=' + companyCode).then((response) => {
      this.setState({ selectedCompany: companyCode, companyDetails: response.data[0] });
      let serviceTemplateObj = setServiceTemplate(this.props.serviceTemplate, "companyDetails", response.data[0]);
      this.props.onUpdateServiceTemplate(serviceTemplateObj);

    })
  }

  getTemplate = (match: any) => {
    const {
      params,
    } = match;
    this.props.getServiceTemplate(params);
  }

  getDropdownValues = () => {
    this.getCurrencies();
    this.getAllBusinessProducts();
  }

  getCurrencies = () => {
    axios.get(`/mdm-currency?searchText=&code=`).then((response) => {
      this.setState({ currencyList: response.data })
    })
  }

  getAllBusinessProducts = () => {
    axios.get(`/getAllBusinessProduct`).then((response) => {
      let data = response.data.map((item: any) => { return { ...item, BusinessProductId: null } })
      this.setState({ businessProductList: data })
    })
  }

  onSaveOfServiceTemplate = async () => {
    const {
      selectedProduct,
      serviceAndCharges,
      onUpdateBPserviceCharges
    } = this.props
    onUpdateBPserviceCharges(selectedProduct, serviceAndCharges)
    const isValidated = await this.checkValidation()
    this.setState({ isFeildsMissing: !isValidated })
    if (this.checkValidation()) {
      this.saveServiceTemplate()
    }
  }

  saveServiceTemplate = async () => {
    const {
      businessProducts,
      saveServiceCharges,
      setServiceTemplateSaving,
      isDuplicate,
      serviceTemplate,
      history
    } = this.props;

    try {
      const templateObject = await this.generateTemplateObject(serviceTemplate, isDuplicate);

      if (!templateObject) return;

      setServiceTemplateSaving(true);

      const response: any = await this.props.saveServiceTemplate(templateObject, isDuplicate);

      if (response?.ServiceTemplateDetails?.ServiceTemplateId) {
        const bpProducts = response.BusinessProducts || [];
        const savePromises = [];

        for (const product of businessProducts) {
          const businessProduct = bpProducts.find((bp: any) => bp.Code === product.Code);

          if (product.servicesAndCharges && product.servicesAndCharges.length > 0) {
            const productData = isDuplicate ? await filterDuplicate(isDuplicate, product) : product;
            savePromises.push(saveServiceCharges(productData, businessProduct, isDuplicate));
          }
        }

        await Promise.all(savePromises);

      }

      this.setState({ isServiceTemplateSaved: true });
      setTimeout(() => {
        setServiceTemplateSaving(false);

        const searchParams = new URLSearchParams(window.location.search);
        history.push({
          pathname: `/service-template/${response.ServiceTemplateDetails.ServiceTemplateId}`,
          search: `?${searchParams.toString()}`,
        });
        window.location.reload();
      }, 3000);


    } catch (error) {
      console.error("An error occurred while saving template:", error);
      setServiceTemplateSaving(false);
    }
  }


  generateTemplateObject = (template: any, isDuplicate: boolean) => {
    if (!isDuplicate) {
      return new Promise((resolve, reject) => resolve(template));
    } else {
      let obj = duplicateTemplate(template);
      return new Promise((resolve, reject) => resolve(obj));
    }

  }

  checkValidation = () => {
    let {
      chargeValidation,
      serviceValidation,
      serviceTemplateValidation
    } = this.state

    const {
      businessProducts,
      serviceTemplate
    } = this.props

    var isValidated = true;
    // this is for details
    if (serviceTemplate) {
      if (!serviceTemplate.BusinessProducts || !Object.keys(serviceTemplate?.BusinessProducts).length) {
        isValidated = false;
      }

      Object.keys(serviceTemplateValidation).forEach(function (key) {
        if (!Object.keys(serviceTemplate?.ServiceTemplateDetails?.[key as keyof IServiceTemplateDetail] ?? {}).length) {
          isValidated = false;
          return;
        }
      });
    }
    //this is for businessProducts
    businessProducts.map(async (item: any) => {
      // this need a backend fix because naming is inconsistent 
      if (item.hasOwnProperty('serviceAndCharges') || item.hasOwnProperty('servicesAndCharges')) {
        if (item.servicesAndCharges && item.servicesAndCharges.length) {
          item.servicesAndCharges.forEach((child: any) => {
            Object.keys(serviceValidation).forEach(function (key) {
              if (!child?.services[key] || !Object.keys(child?.services[key]).length) {
                isValidated = false;
              }
            })
            child.charges.forEach((charge: any) => {
              Object.keys(chargeValidation).forEach((key) => {
                if (!charge[key]) {
                  isValidated = false;
                }
              })
            })
          })
        }
        else {
          isValidated = false;
        }
      }
    })
    return isValidated;
  }

  OnChange = (e: any) => {
    let serviceTemplateObj;
    if (e.value === 'Company') {
      serviceTemplateObj = setServiceTemplate(this.props.serviceTemplate, "visibilityType", e.value);
      serviceTemplateObj.ServiceTemplateVisibility = [{ CompanyFk: this.props.serviceTemplate?.ServiceTemplateDetails?.CompanyFk, IsActive: true }]
    } else {
      serviceTemplateObj = setServiceTemplate(this.props.serviceTemplate, "visibilityType", e.value);
    }
    this.setState({ selectedOfficeItem: [], selectedDepartmentItem: [] })
    this.props.onUpdateServiceTemplate(serviceTemplateObj);
    this.props.refreshServiceVisibility(this.props.serviceTemplate?.ServiceTemplateDetails?.VisibilityType);
  }

  onChangeInput = (e: any, id: string) => {
    let serviceTemplateObj = setServiceTemplate(this.props.serviceTemplate, id, e);
    this.props.onUpdateServiceTemplate(serviceTemplateObj);
  }

  onValueChange = (event: any, id: string) => {

    const {
      businessProducts
    } = this.props
    let products;
    let removedData = businessProducts.filter((x) => !event.includes(x));
    if (removedData.length > 0) {
      this.setState({ isOpenModal: true, removedBp: removedData })
      products = businessProducts.map((item: any) => {
        let product = businessProducts.find((child: any) => child.Code == item.Code)
        if (product) {
          return {
            ...item,
            BusinessProductId: product?.BusinessProductId ? product?.BusinessProductId : null,
            servicesAndCharges: item?.servicesAndCharges ? item.servicesAndCharges : []
          }
        } else {
          return {
            ...item,
            servicesAndCharges: item?.servicesAndCharges ? item.servicesAndCharges : []
          }
        }
      })
    } else {
      products = event.map((item: any) => {
        let product = businessProducts.find((child: any) => child.Code == item.Code)
        if (product) {
          return {
            ...item,
            BusinessProductId: product?.BusinessProductId ? product?.BusinessProductId : null,
            servicesAndCharges: item?.servicesAndCharges ? item.servicesAndCharges : []
          }
        } else {
          return {
            ...item,
            servicesAndCharges: item?.servicesAndCharges ? item.servicesAndCharges : []
          }
        }
      })
    }
    this.props.onUpdateBusinessProduct(products)
  }

  deleteBp = () => {
    this.setState({ isOpenModal: false })
  }

  addBp = () => {
    let products;
    const {
      businessProducts
    } = this.props;
    const { removedBp } = this.state;
    const removedProduct = _.differenceBy(businessProducts, removedBp, 'Name');
    products = removedProduct.map((item: any) => {
      let product = businessProducts.find((child: any) => child.Code == item.Code)
      if (product) {
        return {
          ...item,
          BusinessProductId: product?.BusinessProductId ? product?.BusinessProductId : null,
          servicesAndCharges: item?.servicesAndCharges ? item.servicesAndCharges : []
        }
      } else {
        return {
          ...item,
          servicesAndCharges: item?.servicesAndCharges ? item.servicesAndCharges : []
        }
      }
    })
    this.props.onUpdateBusinessProduct(products)
    this.setState({ isOpenModal: false })
  }

  // function will be called on selection of checkbox in specific to department
  onSelectDepartmentList = (event: any, item: any) => {
    const { selectedDepartmentItem } = this.state;
    const { serviceTemplate } = this.props;
    if (event.target.checked) {
      this.setState({ selectedDepartmentItem: [...selectedDepartmentItem, item] }, () => this.onSelectList())
    } else {
      this.props.deleteServiceVisibility(item.DepartmentId, serviceTemplate?.ServiceTemplateDetails?.VisibilityType);
      const nextSelectedRows = selectedDepartmentItem.filter(selectedRow => {
        if (selectedRow.DepartmentId) {
          if (selectedRow.DepartmentId != item.DepartmentId) {
            return selectedRow
          }
        } else {
          if (selectedRow.DepartmentFk != item.DepartmentId) {
            return selectedRow
          }
        }
      });
      this.setState({ selectedDepartmentItem: nextSelectedRows }, () => this.onSelectList())
    }
  }

  // function will be called on selection of checkbox in specific to office
  onSelectOfficeList = (event: any, item: any) => {
    const { selectedOfficeItem } = this.state;
    const { serviceTemplate } = this.props;
    if (event.target.checked) {
      this.setState({ selectedOfficeItem: [...selectedOfficeItem, item] }, () => this.onSelectList())
    } else {
      this.props.deleteServiceVisibility(item.OfficeId, serviceTemplate?.ServiceTemplateDetails?.VisibilityType);
      const nextSelectedRows = selectedOfficeItem.filter(selectedRow => {
        if (selectedRow.OfficeId) {
          if (selectedRow.OfficeId != item.OfficeId) {
            return selectedRow
          }
        } else {
          if (selectedRow.OfficeFk != item.OfficeId) {
            return selectedRow
          }
        }
      });
      this.setState({ selectedOfficeItem: nextSelectedRows }, () => this.onSelectList())
    }
  }

  // function will be called on selection of checkbox in visibility grid
  onSelectList = () => {
    const { selectedDepartmentItem, selectedOfficeItem } = this.state;
    const { serviceTemplate } = this.props;
    let selectedRowsList: IServiceTemplateVisibility[] = [];
    let companyDetails = this.state.companyDetails;
    if (serviceTemplate.ServiceTemplateDetails?.VisibilityType == "Office") {
      selectedRowsList = selectedOfficeItem.filter((item: any) => item['OfficeId'] || item['OfficeFk']).map((child: any) => {
        if (child?.OfficeFk) {
          return child;
        } else {
          return { "OfficeFk": child.OfficeId, "CompanyFk": companyDetails.CompanyId, "IsActive": true }
        }
      })
    }
    else if (serviceTemplate.ServiceTemplateDetails?.VisibilityType == "Department") {
      selectedRowsList = selectedDepartmentItem.filter((item: any) => item['DepartmentId'] || item['DepartmentFk']).map((child: any) => {
        if (child?.DepartmentFk) {
          return child;
        } else {
          return { "DepartmentFk": child.DepartmentId, "CompanyFk": companyDetails.CompanyId, "IsActive": true }
        }
      })
    }
    else { //company
      selectedRowsList.push({ "CompanyFk": companyDetails.CompanyId, "IsActive": true });
    }
    let serviceTemplateObj = setServiceTemplate(this.props.serviceTemplate, "visibilityList", selectedRowsList);
    this.props.onUpdateServiceTemplate(serviceTemplateObj);
  }



  render() {
    const {
      serviceTemplate,
      isLoading,
      userDetails,
      isServiceTemplateSaving,
      serviceAndCharges,
      businessProducts,
    } = this.props
    const {
      businessProductList,
      currencyList,
      selectedCompany,
      isFeildsMissing,
      opTags,
      isOpenModal,
      isServiceTemplateSaved
    } = this.state
    if (isLoading) {
      return (
        <LoaderOverview>
          <Loader size={50} color={'#aacbe9'} />
        </LoaderOverview>
      );
    }

    if (serviceTemplate) {
      return (
        <Layout>
          {serviceTemplate?.ServiceTemplateDetails?.ServiceTemplateId ?
            <Typography>Edit Service Template</Typography>
            :
            <Typography>Create a New Service Template</Typography>
          }

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Overview>
              <DetailsWrapper>
                <ServiceTemplateDetails
                  serviceTemplate={serviceTemplate}
                  currencyList={currencyList}
                  businessProductList={businessProductList}
                  businessProducts={businessProducts}
                  onChangeInput={(e: any, id: string) => this.onChangeInput(e, id)}
                  onValueChange={(e: any, id: string) => this.onValueChange(e, id)}
                  selectedCompany={selectedCompany}
                  OnChange={this.OnChange}
                  isFeildsMissing={isFeildsMissing}
                  onSaveOfServiceTemplate={() => this.onSaveOfServiceTemplate()}
                />
              </DetailsWrapper>
            </Overview>

            <Overview>
              <VisibilityGridWrapper>
                <ServiceVisibilityGrid
                  selectedCompany={this.state.selectedCompany}
                  type={serviceTemplate.ServiceTemplateDetails?.VisibilityType}
                  onSelectDepartmentList={(event: any, item: any) => this.onSelectDepartmentList(event, item)}
                  onSelectOfficeList={(event: any, item: any) => this.onSelectOfficeList(event, item)}
                  selectedVisibilityList={serviceTemplate.ServiceTemplateVisibility}
                />
              </VisibilityGridWrapper>
            </Overview>
          </div>

          <GridView>
            <Grid
              userDetails={userDetails}
              products={businessProducts}
              serviceAndCharges={serviceAndCharges}
              isFeildsMissing={isFeildsMissing}
              opTags={opTags}
              onSaveOfServiceTemplate={() => this.onSaveOfServiceTemplate()}
            />
          </GridView>

          <PegModal
            isOpen={isServiceTemplateSaving}
            alertModal={true}
            showTemplate={true}
            isCenter={true}>
            <div style={{ overflow: 'hidden', padding: 12 }}>
              <QuotationAlert>{isServiceTemplateSaved ? "Records have been saved successfully" : "Please wait while saving"}</QuotationAlert>
              {isServiceTemplateSaved ?
                <BsCheckCircle style={{
                  height: "50px",
                  width: "100%",
                  color: "#2cd334"
                }} /> :
                <Loader
                  size={50}
                  color={'#adccf4'}
                />}
              <QuotationAlert>{isServiceTemplateSaved ? "The page will refresh in a moment" : " "}</QuotationAlert>
            </div>
          </PegModal>

          <PegModal
            isOpen={isOpenModal}
            disableHeader={true}
            tertiaryMsg={'No'}
            showTemplate={true}
            buttonText={'Yes'}
            tertiaryAction={this.addBp}
            closeModal={this.deleteBp}
            isCenter={true}>
            <div style={{ overflow: 'hidden', padding: 12 }}>
              <QuotationAlert>Deleting Business Product will delete services and charges!</QuotationAlert>
            </div>
          </PegModal>
        </Layout>
      )
    }

  }
}

export default ServiceTemplate
