
import {
  Overview,
  Section,
  Wrapper,
  Label,
  Left,
  Right
} from './edit.styles';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Dropdown from 'Controls/Dropdown';
import DatePicker from 'Controls/DateRange';
import {
  SERVICE_STATUS_OPTIONS,
  BILLING_STATUS_OPTIONS
} from 'Constants/DropdownOptions'

type IProps = {
  onEditServices: (key: any, value: any) => void;
  currencyList?: any[];
  bulkEditSR?: any;
  bulkEditList?: any;
  jobDetails?: any;
  isMaster?: boolean;
  isValidParty?: boolean;
  isCostEditDisabled?: boolean;
  openOnlyBillingStatus?: boolean;
};

const billableOptions = [
  { label: 'Billable', value: true },
  { label: 'Non-Billable', value: false }
]

const EditForm = (props: IProps) => {
  const {
    onEditServices,
    currencyList,
    bulkEditSR,
    bulkEditList,
    jobDetails,
    isMaster,
    isValidParty,
    isCostEditDisabled,
    openOnlyBillingStatus
  } = props

  return (
    <Overview>
      <Section>
        <Left>
          <Label>Service Status</Label>
          <Dropdown
            id="serviceStatus"
            onChangeDropdown={onEditServices}
            isLookup={false}
            dropdownOptions={SERVICE_STATUS_OPTIONS}
            dropdownLabel={'label'}
            dropdownValue={'label'}
            placeHolder="Select Option"
            isMenuPortalTarget={'true'}
            domId={'stickyPosition'}
            style={{ width: 200 }}
          />
        </Left>
        {
          !isMaster ?
            <div>
              {bulkEditSR.ServiceStatus === 'Completed' &&
                (jobDetails.ProductCode === 'BP0004' && !(jobDetails.ATA && jobDetails.ATD)) ?
                <Right>
                  <Label>Billing Status</Label>
                  <Wrapper>ATA & ATD is not available</Wrapper>
                </Right>
                :
                <Right data-tip data-tooltip-id='billingStatus'>
                  <Label>Billing Status</Label>
                  <Dropdown
                    id="billingStatus"
                    onChangeDropdown={onEditServices}
                    defaultValue={bulkEditList.BillingStatus}
                    isLookup={false}
                    dropdownOptions={BILLING_STATUS_OPTIONS}
                    dropdownLabel={'label'}
                    dropdownValue={'name'}
                    placeHolder={"Select Option"}
                    isMenuPortalTarget={'true'}
                    domId={'stickyPosition'}
                    disabled={bulkEditSR?.ServiceStatus !== 'Completed' || (bulkEditList && bulkEditList.IsBillable === false)}
                    style={{ width: 200 }}
                  />
                  {bulkEditSR?.ServiceStatus !== 'Completed' ?
                    <ReactTooltip id="billingStatus" place='top'>
                      <span>{'Select Service Status Completed to enable Billing Status'}</span>
                    </ReactTooltip>
                    // : !isValidParty ? <ReactTooltip id="billingStatus" effect="solid" place='top' >
                    //   <span>Not Valid Party, Please select new party!</span>
                    // </ReactTooltip> 
                    : ''}
                </Right>
              }
            </div>
            :
            <Right />
        }

      </Section>
      {!isMaster && jobDetails.ProductCode === 'BP0004' &&
        <Section>
          <Left>
            <Label>Start date</Label>
            <DatePicker
              id="startDate"
              type={"dateTime"}
              width={200}
              timeFormat={'HH:mm'}
              format={'dd/MM/yyyy HH:mm'}
              onChange={onEditServices}
              enabled={!openOnlyBillingStatus} />
          </Left>
          <Right>
            <Label>End date</Label>
            <DatePicker
              id="editDate"
              type={"dateTime"}
              width={200}
              timeFormat={'HH:mm'}
              format={'dd/MM/yyyy HH:mm'}
              onChange={onEditServices}
              enabled={!openOnlyBillingStatus} />
          </Right>
        </Section>
      }
      {
        !isMaster &&
        <Section>
          <Left>
            <Label>Price currency</Label>
            <Dropdown
              id={"priceCurrency"}
              isLookup={false}
              dropdownLabel={'code'}
              dropdownValue={'code'}
              appendParams={false}
              style={{ width: 199 }}
              placeHolder="Select currency"
              dropdownOptions={currencyList}
              onChangeDropdown={onEditServices}
              isMenuPortalTarget={'true'}
              domId={'stickyPosition'}
              disabled={openOnlyBillingStatus}
            />
          </Left>
          <Right>
            <Label>Billing currency</Label>
            <Dropdown
              id={"billingCurrency"}
              isLookup={false}
              dropdownLabel={'code'}
              dropdownValue={'code'}
              appendParams={false}
              style={{ width: 199 }}
              placeHolder="Select currency"
              dropdownOptions={currencyList}
              onChangeDropdown={onEditServices}
              isMenuPortalTarget={'true'}
              domId={'stickyPosition'}
              disabled={openOnlyBillingStatus}
            />
          </Right>
        </Section>
      }
      <Section>
        <Left data-tip data-tooltip-id='costCurrency'>
          <Label>Cost currency</Label>
          <Dropdown
            id={"costCurrency"}
            disabled={isCostEditDisabled || openOnlyBillingStatus}
            isLookup={false}
            dropdownLabel={'code'}
            dropdownValue={'code'}
            appendParams={false}
            style={{ width: 199 }}
            placeHolder="Select currency"
            dropdownOptions={currencyList}
            onChangeDropdown={onEditServices}
            isMenuPortalTarget={'true'}
            domId={'stickyPosition'}
          />
          {isCostEditDisabled ?
            <ReactTooltip id='costCurrency' place='top'>
              <span>{'Some of the selected services already have supplier invoice or credit note'}</span>
            </ReactTooltip>
            : ''}
        </Left>
        <Right />
      </Section>
      <Section>
        <Left data-tip data-for='IsBillable'>
          <Label>Bill/Non-Bill</Label>
          <Dropdown
            id={"IsBillable"}
            isLookup={false}
            dropdownLabel={'label'}
            dropdownValue={'value'}
            appendParams={false}
            style={{ width: 199 }}
            placeHolder="Select Option"
            dropdownOptions={billableOptions}
            onChangeDropdown={onEditServices}
            isMenuPortalTarget={'true'}
            domId={'stickyPosition'}
            disabled={openOnlyBillingStatus}
          />
        </Left>
        <Right />
      </Section>
    </Overview>
  );
};

export default EditForm;
