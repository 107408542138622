import React from 'react';
// Import custome components here
import WizardHeader from 'Components/WizardHeader/WizardHeader';
import PegCheckbox from 'Controls/Checkbox/PegCheckbox';
import Dropdown from 'Controls/Dropdown';
import PegLabel from 'Controls/Label/PegLabel';
import PegTextArea from 'Controls/TextArea/PegTextArea';
// import data models and master data here
import { FREIGHT_REQUIRED_SERVICES, FREIGHT_ADDITIONAL_SERVICES } from 'Constants/CheckBoxConstants';
import { addressType, MOVEMENT_TYPE_LIST } from 'Constants/DropdownOptions';
import { IFreightDetail } from 'Model/Order/FreightDetails';

type IProps = {
  freightDetails: any,
  updateFreightDetails: (freightDetails: IFreightDetail) => void;
};

class FreightDetails extends React.Component<IProps, {}> {
  constructor(props: any) {
    super(props);
    this.state = {
    };
  }

  /**
   * @method handleInputChange
   * @param $event<HtmlInputEvent>, elementName<string>, elementCode<string>
   * @desc used for updating input value changes with store
   * @returns none 
   */
  handleInputChange($event: any, elementName: string, elementCode?: string): void {
    this.props.freightDetails[elementName] = $event.name;
    if (elementCode) {
      this.props.freightDetails[elementCode] = $event.code;
    }
    this.props.updateFreightDetails(this.props.freightDetails)
  }

  /**
   * @method handleCheckBoxChanges
   * @param event<HtmlInputEvent>, elementName<string>
   * @desc used for updating additional and required services with store 
   * @returns none 
   */
  handleCheckBoxChanges(event: any, elementName: string): void {
    if (!this.props.freightDetails[elementName]) {
      this.props.freightDetails[elementName] = [];
    }
    if (event && event.target && event.target.value) {
      if (event.target.checked) {
        this.props.freightDetails[elementName].push(event.target.value)
      } else {
        this.props.freightDetails[elementName].splice(this.props.freightDetails[elementName].indexOf(event.target.value), 1);
      }
    }
    this.props.updateFreightDetails(this.props.freightDetails)
  }

  render() {
    return (
      <section className="grid grid-cols-2 gap-12 p-8 fright-details-section">
        <div className='col-span-2 flex-col'>
          <WizardHeader title={"Freight Information"} description={"Enter route origin and destination and required services"} />
        </div>
        <div className="col-span-1">
          <div className="flex flex-row items-center space-y-4">
            <PegLabel id="lblAddressType" name="addressType">
              Inco Term
            </PegLabel>
            <div className="w-8/12">
              <Dropdown
                id={'drpIncoTerm'}
                placeHolder={'Select IncoTerm'}
                isLookup={true}
                dropdownLabel={'name'}
                dropdownValue={'name'}
                appendParams={false}
                getFormattedLabel={true}
                url={`/mdm-inco-term?searchText=`}
                onChangeDropdown={(event: any) => this.handleInputChange(event, 'IncoTermName', 'IncoTermCode')}
              />
            </div>
          </div>
          <div className="flex flex-row items-center space-y-4">
            <PegLabel id="lblMovementType" name="movementType">
              Movement Type
            </PegLabel>
            <div className="w-8/12">
              <Dropdown
                id={'customerAddress'}
                isLookup={false}
                dropdownLabel={'name'}
                dropdownValue={'name'}
                appendParams={false}
                dropdownOptions={MOVEMENT_TYPE_LIST}
                placeHolder="Select Address"
                onChangeDropdown={(event: any) => this.handleInputChange(event, 'MovementType')}
              />
            </div>
          </div>

          <div className="flex flex-row items-center space-y-4 ">
            <PegLabel id="lblRequiredServices" name="requiredServices">
              Required Services
            </PegLabel>
            <span className="w-8/12 flex flex-col justify-start space-y-2 pt-4">
              {FREIGHT_REQUIRED_SERVICES.map((item, index) => <PegCheckbox value={item.value} id={'chk' + item.value} name={item.value}
                label={item.label} onChange={($event: any) => this.handleCheckBoxChanges($event.event, 'RequiredServices')} key={index} />)}
            </span>
          </div>
          <div className="flex flex-row items-center space-y-4">
            <PegLabel id="lblFrieghtAdditionalServices" name="freightAdditionalServices">
              Additional Services
            </PegLabel>
            <span className="w-8/12 flex flex-col justify-start space-y-2 pt-4">
              {FREIGHT_ADDITIONAL_SERVICES.map((item, index) => <PegCheckbox value={item.value} id={'chk' + item.value} name={item.value}
                label={item.label} onChange={($event: any) => this.handleCheckBoxChanges($event.event, 'AdditionalServices')} key={index} />)}
            </span>
          </div>
        </div>

        <div className="col-span-1">
          <div className="flex flex-row items-center space-y-4">
            <PegLabel id="lblOrigin" name="origin">
              Origin
            </PegLabel>
            <div className="w-8/12">
              <Dropdown
                id={'drpOrigin'}
                placeHolder={'Search Origin'}
                isLookup={true}
                dropdownLabel={'name'}
                dropdownValue={'name'}
                appendParams={false}
                getFormattedLabel={true}
                url={`/mdm-search-customer?searchText=`}
                onChangeDropdown={(event: any) => this.handleInputChange(event, 'OriginName', 'OriginCode')}
              />
            </div>
          </div>

          <div className="flex flex-row items-center space-y-4">
            <PegLabel id="lblDestination" name="destination">
              Destination
            </PegLabel>
            <div className="w-8/12">
              <Dropdown
                id={'drpDestination'}
                placeHolder={'Search Destination'}
                isLookup={true}
                dropdownLabel={'name'}
                dropdownValue={'name'}
                appendParams={false}
                getFormattedLabel={true}
                url={`/mdm-search-customer?searchText=`}
                onChangeDropdown={(event: any) => this.handleInputChange(event, 'DestName', 'DestCode')}
              />
            </div>
          </div>

          <div className="flex flex-row items-center space-y-4">
            <PegLabel id="lblShipper" name="shipper">
              Shipper
            </PegLabel>
            <div className="w-8/12">
              <Dropdown
                id={'drpShipper'}
                placeHolder={'Search Shipper'}
                isLookup={true}
                dropdownLabel={'name'}
                dropdownValue={'name'}
                appendParams={false}
                getFormattedLabel={true}
                url={`/mdm-search-customer?searchText=`}
                onChangeDropdown={(event: any) => this.handleInputChange(event, 'ShipperName', 'ShipperCode')} />
            </div>
          </div>

          <div className="flex flex-row items-center space-y-4">
            <PegLabel id="lblAddressType" name="addressType">
              Shipper Address Type
            </PegLabel>
            <div className="w-8/12">
              <Dropdown
                id={'drpShipperAddressType'}
                isLookup={false}
                dropdownLabel={'name'}
                dropdownValue={'name'}
                appendParams={false}
                dropdownOptions={addressType}
                placeHolder="Select Address Type"
                onChangeDropdown={(event: any) => this.handleInputChange(event, 'ShipmentTypeName', 'ShipmentTypeCode')} />
            </div>
          </div>
          <div className="flex flex-row items-center space-y-4">
            <PegLabel id="lblAddress" name="address">
              Shipper Address
            </PegLabel>
            <div className="w-8/12">
              <PegTextArea
                id={'txtShipperAddress'} name={'name'}
                onChange={(event: any) => this.handleInputChange(event, "ShipperAddress")} />
            </div>
          </div>
          <div className="flex flex-row items-center space-y-4">
            <PegLabel id="lblConsignee" name="consignee">
              Consignee
            </PegLabel>
            <div className="w-8/12">
              <Dropdown
                id={'drpConsignee'}
                placeHolder={'Search Consignee'}
                isLookup={true}
                dropdownLabel={'name'}
                dropdownValue={'name'}
                appendParams={false}
                getFormattedLabel={true}
                url={`/mdm-search-customer?searchText=`}
                onChangeDropdown={(event: any) => this.handleInputChange(event, 'ConsigneeName', 'ConsigneeCode')} />
            </div>
          </div>
          <div className="flex flex-row items-center space-y-4">
            <PegLabel id="lblAddressType" name="addressType">
              Consignee Address Type
            </PegLabel>
            <div className="w-8/12">
              <Dropdown
                id={'drpConsigneeAddressType'}
                isLookup={false}
                dropdownLabel={'name'}
                dropdownValue={'name'}
                appendParams={false}
                dropdownOptions={addressType}
                placeHolder="Select Address Type"
                onChangeDropdown={(event: any) => this.handleInputChange(event, 'ConsigneeAddressTypeName', 'ConsigneeAddressTypeCode')} />
            </div>
          </div>
          <div className="flex flex-row items-center space-y-4">
            <PegLabel id="lblAddress" name="address">
              Consignee Address
            </PegLabel>
            <div className="w-8/12">
              <PegTextArea
                id={'txtConsigneeAddress'} name={'name'}
                onChange={(event: any) => this.handleInputChange(event, "ConsigneeAddress")} />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default FreightDetails
