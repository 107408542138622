
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ServiceVisibilityHeader from "./ServiceVisibilityHeader";
import { OverviewHeader, DepartmentID, DepartmentName, OfficeName, OfficeTypeName, OfficeIsActive, IsMainOffice, DepartmentIsActive, Code, Description, OfficeID, LoaderOverview } from './ServiceVisibility.styles';
import { getVisibilityGridData } from '../../../../Redux/Actions/serviceTemplate';
import Loader from "Components/Loader";


type Prop = {
    type?: any;
    selectedCompany?: any;
    selectedVisibilityList?: any;
    onSelectVisibilityList?: (item: any) => void;
    onSelectOfficeList?: any;
    onSelectDepartmentList?: any;
}

const styleClass = "text-left text-xs font-medium text-gray-500 tracking-wider bg-indigo-50";

const ServiceVisibilityGrid = (props: Prop): any => {
    let serviceTemplate = useSelector((state: any) => state.ServiceTemplates.serviceTemplate);
    let isDataLoading = useSelector((state: any) => state.ServiceTemplates.isTemplateLoading);
    let dataSource = useSelector((state: any) => state.ServiceTemplates.visibilityGridData);
    const dispatch = useDispatch()
    const {
        selectedCompany,
        type,
        onSelectOfficeList,
        onSelectDepartmentList,
        // selectedVisibilityList,
    } = props

    useEffect(() => {
        // const asyncFn = async () => {
        dispatch(getVisibilityGridData(type, selectedCompany))
        // }
        // asyncFn()
    }, [props.type])

    const onClickCheckbox = (event: any, i: any) => {
        if (type === 'Department') {
            onSelectDepartmentList(event, dataSource[i]);
        } else if (type === 'Office') {
            onSelectOfficeList(event, dataSource[i]);
        }
    };

    const renderOfficeItems = dataSource && type === 'Office' && dataSource.map((el: any, i: any) => {
        if (el.OfficeId) {
            return (
                <OverviewHeader className={styleClass} key={i}>
                    <>
                        <OfficeID index={i}>
                            <input
                                type="checkbox"
                                // defaultChecked={false}
                                defaultChecked={el.isChecked}
                                onClick={(e: any) => onClickCheckbox(e, i)}
                                style={{ cursor: 'pointer', marginRight: '8px' }}
                            />
                        </OfficeID>
                        <OfficeName index={i}>
                            {el.Name}
                        </OfficeName>
                        <OfficeTypeName index={i}>
                            {el.OfficeTypeName}
                        </OfficeTypeName>
                        <IsMainOffice index={i}>
                            <input
                                type="checkbox"
                                checked={el.IsMainOffice}
                                style={{ cursor: 'pointer', marginRight: '8px' }}
                            />
                        </IsMainOffice>
                        <OfficeIsActive index={i}>
                            <input
                                type="checkbox"
                                checked={el.IsActive}
                                style={{ cursor: 'pointer', marginRight: '8px' }}
                            />
                        </OfficeIsActive></>
                </OverviewHeader>
            );
        }

    });


    const renderDepartmentItems = dataSource && type === 'Department' && dataSource.map((elDepartment: any, index: any) => {
        if (elDepartment.DepartmentId) {
            return (
                <>
                    <OverviewHeader className={styleClass} key={index}>
                        <>
                            <DepartmentID index={index}>
                                <input
                                    type="checkbox"
                                    defaultChecked={elDepartment.isChecked}
                                    onClick={(e: any) => onClickCheckbox(e, index)}
                                    style={{ cursor: 'pointer', marginRight: '8px' }}
                                />
                            </DepartmentID>
                            <DepartmentName index={index}>
                                {elDepartment.Name}
                            </DepartmentName>
                            <Code index={index}>
                                {elDepartment.Code}
                            </Code>
                            <Description index={index}>
                                {elDepartment.Description}
                            </Description>
                            <DepartmentIsActive index={index}>
                                <input
                                    type="checkbox"
                                    checked={elDepartment.IsActive}
                                    style={{ cursor: 'pointer', marginRight: '8px' }}
                                />
                            </DepartmentIsActive></>
                    </OverviewHeader>
                </>
            );
        }

    });
    if (isDataLoading) {
        return (
            <Loader
                gridLoader={true}
            />
        );
    }
    return (
        <>
            {dataSource &&
                props.type === 'Office' ?
                <>
                    <ServiceVisibilityHeader type={type} />
                    {renderOfficeItems}
                </> :
                props.type === 'Department' ?
                    <>
                        <ServiceVisibilityHeader type={type} />
                        {renderDepartmentItems}
                    </>
                    : props.type === 'Company' ?
                        <h2 style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', marginTop: '10vh' }}>
                            Please select Department or Office
                        </h2>
                        :
                        <div />
            }
        </>
    )
};

export default ServiceVisibilityGrid;

