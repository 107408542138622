import { FiPackage } from 'react-icons/fi';
import { GiCargoShip, GiWeight } from 'react-icons/gi';
import './index.css';

function ColoredLine(props: any): JSX.Element {
    const { color } = props;
    return (
        <hr
            style={{
                color: color,
                backgroundColor: color,
                height: 5,
                position: 'relative',
                top: 10,
                width: 150,
                marginLeft: 8,
                marginRight: 8
            }} />
    );
}
interface Props {
    details: any,
    weight: number,
}

function ConvertToJob(props: Props) {
    const { details, weight } = props;
    return (
        <div className="bg-white h-full">
            <div className="flex flex-row justify-center relative top-10">
                <h1 className="text-2xl font-bold">Job Scope</h1>
            </div>

            {/* Shipping Business Area */}
            <div className="relative top-10">
                <div className="flex flex-row justify-center mt-5 mb-5">
                    <h4 className="text-xl font-medium">Shipping Business Area</h4>
                </div>
                {details?.groupJob?.vesselCallDetails?.map((el: any, index: any) => {
                    return (<div className="flex flex-row justify-center text-center bg-white" key={el}>
                        <div style={{ marginLeft: '-57px' }}>Vessel Name : {el.VesselName}</div>
                        <div style={{ marginLeft: '77px' }}>Vessel Number : {el.VesselCode}</div>
                        <div style={{ marginLeft: '134px' }}>Port : {el.PortName}</div>
                    </div>
                    )
                })}

                <div className="flex flex-row  justify-center text-center mt-5 bg-white">
                    <h2>Pre Arrival</h2>
                    <ColoredLine color="green" />
                    <h2>Vessel Scheduling & Tracking</h2>
                </div>
            </div>

            {/* Logistics Business Area */}
            <div className="relative top-20 bg-white">
                <div className="flex flex-row justify-center mt-5 mb-5">
                    <h4 className="text-xl font-medium">Logistics Business Area</h4>
                </div>
                <div className="flex flex-row  justify-center text-center mt-5 bg-white mb-5">
                    <h2>Warehouse out </h2>
                    <ColoredLine color="green" />
                    <h2>Custom Clearance</h2>
                    <ColoredLine color="green" />
                    <h2>Land Trucking</h2>
                    <ColoredLine color="green" />
                    <h2>Port to Port Export</h2>
                </div>
                {details && details.groupJob && details.groupJob?.localJob?.map((el: any, index: any) => {
                    return (<div className="flex flex-row justify-center text-center bg-white" key={el}>
                        <div style={{ marginLeft: '-57px' }}>Vessel Name : {el.VesselName}</div>
                        <div style={{ marginLeft: '77px' }}>Vessel Number : {el.VesselCode}</div>
                        <div style={{ marginLeft: '134px' }}>Port : {el.PortName}</div>
                    </div>
                    )
                })}
                {details && details.groupJob && details.groupJob.vesselCallDetails?.map((el: any, index: number) => {
                    return (<div className="grid grid-cols-4 gap-2 text-center bg-white" key={index}>
                        <div>Vessel Name : {el.VesselName}</div>
                        <div>Vessel Number : {el.VesselCode}</div>
                        <div>Port : {el.PortName}</div>
                        <div>Port : {el.PortName}</div>
                    </div>
                    )
                })}
            </div>

            {/* Cargo Specification */}

            <div className="container px-1 lg:px-10 my-20" >
                <div className="w-auto lg:w-3/3 mx-auto lg:flex items-center">
                    <div className="w-full bg-white shadow-lg rounded-lg p-6">
                        <h2 className="text-xl font-medium text-center">Cargo Specification</h2>

                        <div className="flex justify-between text-center bg-white mt-5">
                            <div><GiCargoShip size="3em" style={{ marginLeft: '11px' }} /> {details?.groupJob?.cargo?.cargo.length} Cargo</div>
                            <div><FiPackage size="3em" style={{ marginLeft: '10px' }} />{details?.groupJob?.cargo?.packages.length} Packages</div>
                            <div><GiWeight size="3em" style={{ marginLeft: '13px' }} />{weight} Weight</div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Cost & Pricing */}

            {/* <div className="w-full bg-white shadow-lg rounded-lg p-6"> */}
            <h2 className="text-xl font-medium text-center">Cost & Pricing</h2>
            <div className="grid md:grid-cols-3">
                <div className="w-full p-4 lg:w-80">
                    <div className="p-8 rounded shadow-md">
                        <h2 className="text-2xl font-bold text-gray-800">Total Cost</h2>
                        <p className="text-gray-600">22 AED</p>

                    </div>
                </div>
                <div className="w-full p-4 lg:w-80">
                    <div className="p-8 bg-white rounded shadow-md">
                        <h2 className="text-2xl font-bold text-gray-800">Pricing</h2>
                        <p className="text-gray-600">60 AED</p>
                    </div>
                </div>
                <div className="w-full p-4 lg:w-80">
                    <div className="p-8 bg-white rounded shadow-md">
                        <h2 className="text-2xl font-bold text-gray-800">Operating Income</h2>
                        <p className="text-gray-600">20 AED</p>
                    </div>
                </div>
                {/* </div> */}
            </div>
        </div>

    )
}
export default ConvertToJob;