import JobTable from './JobTable'
import { connect } from 'react-redux';
import {
  onAddTemplateService,
  onDeleteTemplateService,
  onUpdateTemplateService,
  onAddTemplateCharge,
  onDeleteTemplateCharge,
  onUpdateTemplateCharge,
  onBulkDeleteService,
  onSetDefaultProduct,
  onUpdateBPserviceCharges,
  onUpdateBPdetails,
  onUpdateOpTags,
  onBulkUpdate,
  onMultiDeleteServices
} from 'Redux/Actions/serviceTemplate';

const mapDispatchToProps = (dispatch: any) => ({
  onAddTemplateService: () => dispatch(onAddTemplateService()),
  onDeleteTemplateService: (serviceIndex: number, opCode: number) => dispatch(onDeleteTemplateService(serviceIndex, opCode)),
  onUpdateTemplateService: (serviceIndex: number, service: any) =>
    dispatch(onUpdateTemplateService(serviceIndex, service)),
  onAddTemplateCharge: (serviceIndex: number) => dispatch(onAddTemplateCharge(serviceIndex)),
  onDeleteTemplateCharge: (serviceIndex: number, chargeIndex: number) =>
    dispatch(onDeleteTemplateCharge(serviceIndex, chargeIndex)),
  onUpdateTemplateCharge: (serviceIndex: number, chargeIndex: number, charge: any) =>
    dispatch(onUpdateTemplateCharge(serviceIndex, chargeIndex, charge)),
  onBulkDeleteService: () => dispatch(onBulkDeleteService()),
  onSetDefaultProduct: (product: any) => dispatch(onSetDefaultProduct(product)),
  onUpdateBPserviceCharges: (product: any, servAndChar: any) =>
    dispatch(onUpdateBPserviceCharges(product, servAndChar)),
  onUpdateBPdetails: (product: any, newProduct: any, details: any) =>
    dispatch(onUpdateBPdetails(product, newProduct, details)),
  onUpdateOpTags: (product: any, opTags: any) => dispatch(onUpdateOpTags(product, opTags)),
  onBulkUpdate: (services: any[]) => dispatch(onBulkUpdate(services)),
  onMultiDeleteServices: (list: any[]) => dispatch(onMultiDeleteServices(list)),
});

const mapStateToProps = (state: any) => ({
  serviceTemplate: state.ServiceTemplates.serviceTemplate,
  selectedProduct: state.ServiceTemplates.selectedProduct
});

export default connect(mapStateToProps, mapDispatchToProps)(JobTable);