
import React from 'react';

interface IChipProps {
    id: string;
    label: string;
    variant?: string;
    dataTestId?: string;
}

export default function PegChip(props: IChipProps) {
    let {
        id,
        label,
        dataTestId
    } = props;

    return (

        <div
            className={props.variant === 'success' ? `inline-flex items-center  bg-success p-px max-w-sm max-h-sm` : `inline-flex items-center  bg-danger p-px max-w-sm max-h-sm`}
            id={id}
            data-testid={dataTestId}
        >
            <span className="px-1 text-sm text-white font-bold">{label}</span>
        </div>

    );
}
