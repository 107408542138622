
import React from 'react';

interface ITextAreaProps {
  id: string;
  onChange: any;
  name?: string;
  disabled?: boolean;
  placeholder?: string;
  value?: string;
  defaultValue?: string;
  isMandatory?: boolean;
  dataTestId?: string
}

export default function PegTextArea(props: ITextAreaProps) {
  const {
    id,
    onChange,
    name,
    disabled,
    placeholder,
    isMandatory,
    value,
    defaultValue,
    dataTestId
  } = props;
  let styleObject = {}
  if (isMandatory) {
    styleObject = { border: '1px solid red' }
  }

  return (
    <div className="">
      <textarea
        id={id}
        data-testid={dataTestId}
        name={name}
        style={styleObject}
        className={'e-input'}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
        value={value}
        defaultValue={defaultValue}
      />
    </div>
  );
}
