import {
  Fragment,
  useCallback,
  useEffect,
  useState
} from 'react';
import _ from 'lodash'
import {
  Overview,
} from '../Jobs.styles'
import {
  setServiceValue,
  setServiceRequestDetails,
} from 'Utils/JobGrid';
import { showToast } from 'Utils/Generic';
import ServiceLine from './ServiceLine'
import ChargeLine from './ChargeLine'
import PegModal from 'Controls/PegModal';
import EditModal from 'Components/EditModal';
import { QuotationAlert } from 'Containers/QuoteGrid/quote.styles';
import PegButton from 'Controls/Button/PegButton';
import { IBillingParty } from 'Model/Job/types';
import { Charge, ICharge } from 'Model/Job/JobCharge';
import { ISalesInvoice, IServicesAndCharges, ISupplierDetails, IVoucher } from 'Model/Job/types';
import { Service } from 'Model/Job/JobService';
import { useDispatch } from 'react-redux';


type Props = {
  service: any
  jobDetails: any
  expandAll?: boolean;
  id: any;
  type?: string;
  index: number;
  serviceList: any[];
  services: any;
  uomList: any[];
  voucherList: any[];
  exchangeRates: any[];
  currencyList: any[];
  billingParties: IBillingParty[];
  taxList: any[];
  disableCost: boolean;
  disableServiceRequest: boolean;
  disableOperatingIncome: boolean;
  disableRevenue: boolean;
  disableBilling: boolean;
  selectedList: Number[];
  onDeleteService: (servId: number) => void;
  onAddCharge: (index: number) => void;
  onDeleteCharge: (serv: any, char: any) => void;
  onSelectServices: (index: number) => void;
  onUpdateService: (service: any, serviceIndex: any) => void;
  onUpdateEditService: (serviceObj: any, serviceIndex: any) => void;
  onUpdateServiceDetails: (service: any, serviceIndex: any) => void;
  onShowTariff: (tariff: any) => void;
  onUpdateCharge: (charge: any, serviceIndex: number, chargeIndex: number) => void;
  updateChargeValidation: (validationKey: string, chargeId: string, value: boolean) => void;
  setPartyValidModal: any;
  onSelectAll?: any;
  onSelectVoucher?: any;
  userDetails: any;
  isValidParty: boolean;
  setValidParty: any;
  columnPreference: any;
  paymentTerms: any;
  chargeValidation?: any;
  serviceValidation?: any;
  masterChargeValidation?: any;
  masterServiceValidation?: any;
  isReadOnly: boolean;

};

const ServiceWrapper = (props: Props) => {
  let {
    service,
    id,
    disableCost,
    disableServiceRequest,
    disableOperatingIncome,
    disableRevenue,
    disableBilling,
    selectedList,
    services,
    index,
    onSelectServices,
    onShowTariff,
    serviceList,
    uomList,
    voucherList,
    currencyList,
    billingParties,
    taxList,
    onUpdateCharge,
    onUpdateService,
    onUpdateEditService,
    jobDetails,
    onUpdateServiceDetails,
    setPartyValidModal,
    exchangeRates,
    isValidParty,
    setValidParty,
    userDetails,
    columnPreference,
    paymentTerms,
    isReadOnly,
    updateChargeValidation
  } = props;
  const dispatch = useDispatch();

  const [hasMissingFields, sethasMissingFields] = useState(false);
  const [closeConfirm, setCloseConfirm] = useState(false);
  const [missingValidation, setMissingValidation] = useState([]);
  const [editService, setEditService] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showTariffModal, setShowTarriffModal] = useState(false);
  const [billingPartyList, setBillingParties] = useState<IBillingParty[]>([])
  const [showCharges, setShowCharges] = useState(true)
  const [srNoDropdown, setSRNoDropdown] = useState([]);
  const [editableCharges, setEditableCharges] = useState<number[]>([]);

  const checkValidation = async (services: any, type: string) => {
    let {
      chargeValidation,
      serviceValidation,
      masterChargeValidation,
      masterServiceValidation,
      jobDetails
    } = props
    let isValidated = true;
    let serValidation = jobDetails.IsMaster ? masterServiceValidation : serviceValidation;
    let charValidation = jobDetails.IsMaster ? masterChargeValidation : chargeValidation;
    let missingValidation: any = []
    if (services?.service?.IsActive) {
      Object.keys(serValidation).forEach(function (key) {
        if (!services?.service[key]) {
          isValidated = false;
          missingValidation.push(key);
        }
      })
    }
    services?.charges?.forEach((charge: any) => {
      if (charge.IsActive) {
        Object.keys(charValidation).forEach((key) => {
          if (key === 'UnitPrice') {
            let unitPrice = charge[key];
            if (!unitPrice && unitPrice !== 0) {
              isValidated = false;
              missingValidation.push(key);
            }
            else if (!(unitPrice >= 1) && services.service.IsBillable) {
              isValidated = false;
              missingValidation.push('UnitPrice0');
            }
          }
          else if (!charge[key]) {
            if (key === 'UnitCost') {
              if (isNaN(charge[key]) || charge[key] === null || charge[key] === undefined || charge[key] === '') {
                isValidated = false;
                missingValidation.push(key);
              }
            }
            else {
              isValidated = false;
              missingValidation.push(key);
            }
          }
        })
      }
    })
    setMissingValidation(_.uniq(missingValidation))
    return isValidated;
  }

  const undoB2BwhenInvoiced = useCallback(() => {

    const { salesInvoice, service: internalService } = service

    if (!!salesInvoice?.length && !!internalService?.BackToBack) {
      const { onUpdateService } = props
      const servObj = setServiceValue(internalService, jobDetails, "BackToBack", false);
      onUpdateService(servObj, index)

      showToast(`${internalService?.Name} will no longer be B2B as the Final invoice is already generated for this service.`, 'info', 7000, 'top-center', 'colored');

      return true
    }

    return false
  }, [services, service])

  const showDetailModal = useCallback(() => {
    setShowModal(true)
  }, []);

  const onCloseModal = useCallback(() => {
    setShowModal(false)
    setCloseConfirm(false);
  }, []);

  const onClose = useCallback(() => {
    setCloseConfirm(true);
  }, []);

  const getIsSupplierInvoiceCNGenerated = (service: any) =>
    !!(service?.supplierDetails?.supplierInvoice?.[0] || service?.supplierDetails?.creditNote?.[0])

  const onEditConfirm = async (serviceObj: any, serviceIndex: number) => {
    if (services.length > 0) {
      const isValidated = await checkValidation(serviceObj, 'saveServices')
      if (isValidated) {
        onUpdateEditService(serviceObj, serviceIndex);
        sethasMissingFields(false);
        showToast('Click on save button to update the changes !', 'success', 2000, 'top-center', 'colored');
        setShowModal(false);
        setCloseConfirm(false);
      } else {
        sethasMissingFields(true)
      }
    }
  }

  const onTariffModalConfirm = () => {
    setShowTarriffModal(false)
  }

  const addToEditable = (chargeIndex: number) => {
    setEditableCharges((prev) => [...prev, chargeIndex])
  }

  const removeFromEditable = (chargeIndex: number) => {
    setEditableCharges((prev) => prev.filter((item) => item !== chargeIndex))
  }

  const onTariffModalOpen = (chargeIndex: number) => {
    addToEditable(chargeIndex)
    setShowTarriffModal(true)
  }

  const onTariffModalCancel = (chargeIndex: number) => {
    removeFromEditable(chargeIndex)
    setShowTarriffModal(false)
  }

  const onCancel = () => {
    setCloseConfirm(false)
  }

  useEffect(() => {

    if (!service?.serviceRequestDetails?.isShowSRLink && service?.service?.SupplierCode) {
      let srNoList: any = [];
      let list: any = [];

      services.forEach((item: any) => {

        if ((service?.service?.SupplierCode === item?.service?.SupplierCode) &&
          item.serviceRequestDetails?.SRNumber && item?.service?.Id != 0 &&
          (service?.service?.CostCurrencyCode === item?.service?.CostCurrencyCode) &&
          (item.serviceRequestDetails?.SRStatus === 'Open' || item.serviceRequestDetails?.SRStatus === 'Sent')) {

          srNoList.push({
            name: item.serviceRequestDetails?.SRNumber,
            code: item.serviceRequestDetails?.SRNumber,
            status: item.serviceRequestDetails?.SRStatus
          })
        }

      })

      list = _.uniqBy(srNoList, 'code');
      setSRNoDropdown(list)
    }
  }, [service?.service?.SupplierCode, service?.service?.CostCurrencyCode])

  useEffect(() => {

    if (!billingParties || billingParties.length === 0) {
      return;
    }

    const { service: innerService } = service
    let updatedList: IBillingParty[]

    if (innerService?.OperationalProcessFk) {
      updatedList = billingParties.filter(
        (item: IBillingParty) => item.jobOperationalProcessFk === innerService?.OperationalProcessFk
      )
    } else {
      updatedList = Array.from(new Map(billingParties.map(obj => [obj.postalAddress + obj.code, obj])))
        .map(([key, value]) => value);
    }

    setBillingParties(updatedList);

  }, [service.service?.OperationalProcessFk, billingParties]);

  const onExpandService = useCallback(() => {
    setShowCharges(!showCharges)
  }, [showCharges]);

  const onChangeDate = (event: any, elemId: any, serviceIndex: any, chargeIndex: any) => {
    let serviceDetailsObj = setServiceRequestDetails(service, elemId, event)
    onUpdateServiceDetails(serviceDetailsObj, index)
  }

  const onChangeInput = useCallback((event: any) => {
    let serviceObj = setServiceValue(service.service, jobDetails, event.target.id, event)
    onUpdateService(serviceObj, index)
  }, []);

  const onChangeDropdown = (event: any, elemId: any, obj: any) => {
    if (elemId === 'serviceStatus' || elemId === 'serviceSRnumber') {
      let serviceDetailsObj = setServiceRequestDetails(obj, elemId, event)
      onUpdateServiceDetails(serviceDetailsObj, index)
    } else {
      let serviceObj = setServiceValue(obj.service, jobDetails, elemId, event)
      onUpdateService(serviceObj, index)
    }
  }

  const onAddCharge = useCallback(() => {
    props.onAddCharge(index)
  }, [index]);

  const onDeleteService = useCallback(() => {
    props.onDeleteService(index)
  }, [index]);

  const onDeleteCharges = useCallback((serviceIndex: number, chargeIndex: number) => {
    let activeCharges = service?.charges.filter((item: any) => item.IsActive)
    if (activeCharges && activeCharges.length !== 1) {
      props.onDeleteCharge(serviceIndex, chargeIndex)
    }
  }, [index]);

  const onBackToBackSelected = (svc: any) => {
    const { service: innerService, charges } = service;

    const updatedService = {
      ...innerService,
      PriceCurrencyCode: innerService.CostCurrencyCode,
      PriceCurrencyName: innerService.CostCurrencyName,
      PriceExchangeRate: innerService.CostExchangeRate
    };

    const updatedCharges = charges.map((charge: any) => {
      const isTotalEyeshareAmountUndefined = _.isNil(charge?.TotalEyeshareAmount);
      if (svc?.BackToBack) {
        return {
          ...charge,
          BackToBack: true,
          Quantity: 1,
          CostQuantity: 1,
          PriceCurrencyCode: charge.CostCurrencyCode,
          PriceCurrencyName: charge.CostCurrencyName,
          PriceExchangeRate: charge.CostExchangeRate,
          UnitPrice: isTotalEyeshareAmountUndefined
            ? (charge.UnitCost ?? 0)
            : (charge.TotalEyeshareAmount ?? 0)
        };
      } else {
        return {
          ...charge,
          BackToBack: false,
          UnitPrice: 0
        };
      }
    });

    const updatedData = {
      ...service,
      ...(svc.BackToBack && { service: updatedService }),
      charges: updatedCharges,
    };

    onUpdateEditService(updatedData, index);
  }

  const onIsBillableSelected = useCallback((svc: any) => {
    service?.charges?.forEach((charge: any) => {
      if (!svc.IsBillable) {
        charge.UnitPrice = 0;
      }
    })
  }, [index]);

  const onChangeService = (e: any, serviceIndex: number) => {
    const filteredServices = services?.filter((service: IServicesAndCharges) => service.service.IsActive);
    const maxActiveServiceCount = filteredServices?.length ?? 0;
    const { service: serviceDetail } = service;

    let jobServiceObj: IServicesAndCharges = {
      charges: [{ ...new Charge(), LineNumber: '1', CostExchangeRate: serviceDetail.CostExchangeRate, PriceExchangeRate: serviceDetail.PriceExchangeRate, BillingExchangeRate: serviceDetail.BillingExchangeRate, CostCurrencyCode: serviceDetail.CostCurrencyCode }],
      service: {
        ...new Service(),
        LineNumber: (maxActiveServiceCount + 1).toString(),
        RowOrder: services.length + 1,
        Name: e.name,
        Code: e.code,
        Id: 0,
        OperationalProcessFk: e.opTags?.operationalProcessId,
        svsCategoryCodes: e.svsCategories,
        CostExchangeRate: serviceDetail.CostExchangeRate,
        PriceExchangeRate: serviceDetail.PriceExchangeRate,
        BillingExchangeRate: serviceDetail.BillingExchangeRate,
        PriceCurrencyCode: serviceDetail.PriceCurrencyCode,
        CostCurrencyCode: serviceDetail.CostCurrencyCode,
        BillingCurrencyCode: serviceDetail.BillingCurrencyCode,
      },
      serviceActivity: [],
      serviceRequestDetails: {
        CompletedDateTime: null,
        EndDateTime: null,
        Id: null,
        Rating: null,
        SRNumber: null,
        SRStatus: null,
        ServiceFk: null,
        ServiceStatus: 'Open',
        StartDateTime: null,
        isShowSRLink: null,
      },
      supplierDetails: {} as ISupplierDetails,
      salesInvoice: [] as ISalesInvoice[],
    };

    dispatch(onUpdateServiceDetails(jobServiceObj, serviceIndex));
  }

  const activeServiceCharges = service?.charges.filter((item: any) => item.IsActive)

  const isChargeEditable = (chargeIndex: number) => {
    return editableCharges.includes(chargeIndex)
  }

  return (
    <div data-testid="servicesCharges">
      <Overview data-testid="serviceJobGrid">
        <ServiceLine
          id={id}
          jobDetails={jobDetails}
          userDetails={userDetails}
          columnPreference={columnPreference}
          serviceIndex={index}
          serviceObject={service}
          service={service.service}
          salesInvoice={service.salesInvoice}
          charges={service?.charges}
          serviceRequestDetails={service?.serviceRequestDetails}
          srNoDropdown={srNoDropdown}
          supplierDetails={service.supplierDetails}
          type={'jobGrid'}
          serviceList={serviceList}
          services={services}
          uomList={uomList}
          currencyList={currencyList}
          billingParties={billingPartyList}
          taxList={taxList}
          exchangeRates={exchangeRates}
          paymentTerms={paymentTerms}
          isSupplierInvoiceAvailable={service?.supplierDetails?.supplierInvoice?.[0]}
          isSupplierCreditNoteAvailable={service?.supplierDetails?.creditNote?.[0]}
          onSelectServices={onSelectServices}
          showCharges={showCharges}
          onDeleteService={onDeleteService}
          onAddCharge={onAddCharge}
          onExpandService={onExpandService}
          onChangeInput={onChangeInput}
          onChangeDate={onChangeDate}
          onUpdateServiceDetails={onUpdateServiceDetails}
          onChangeDropdown={(event: any, elemId: any, obj: any) =>
            onChangeDropdown(event, elemId, obj)}
          onUpdateService={onUpdateService}
          disableCost={disableCost}
          disableServiceRequest={disableServiceRequest}
          disableOperatingIncome={disableOperatingIncome}
          disableRevenue={disableRevenue}
          disableBilling={disableBilling}
          selectedList={selectedList}
          voucherList={voucherList}
          isValidParty={isValidParty}
          setValidParty={setValidParty}
          setPartyValidModal={setPartyValidModal}
          editCharge={editableCharges.length > 0}
          showDetailModal={showDetailModal}
          onBackToBackSelected={onBackToBackSelected}
          onIsBillableSelected={onIsBillableSelected}
          isReadOnly={isReadOnly}
          undoB2BwhenInvoiced={undoB2BwhenInvoiced}
          onChangeService={onChangeService}
        />
      </Overview>
      {
        (showCharges) && activeServiceCharges.map((item: any, indexChild: number) =>
          <Fragment key={item.Id !== 0 ? item.Id : indexChild}>
            <Overview key={item.Id !== 0 ? item.Id : indexChild} data-testid={`${indexChild}chargesJobGrid`}>
              <ChargeLine
                key={service.service.Name + item.Id !== 0 ? item.Id : indexChild}
                service={service.service}
                charges={service?.charges}
                services={services}
                columnPreference={columnPreference}
                onShowTariff={onShowTariff}
                userDetails={userDetails}
                serviceCode={service.service?.Code}
                salesInvoice={service.salesInvoice}
                charge={item}
                jobDetails={jobDetails}
                serviceIndex={index}
                chargeIndex={indexChild}
                type={'charge'}
                uomList={uomList}
                currencyList={currencyList}
                billingParties={billingPartyList}
                taxList={taxList}
                deleteCharge={onDeleteCharges}
                onChangeInput={onChangeInput}
                onUpdateCharge={onUpdateCharge}
                onChangeDate={onChangeDate}
                disableCost={disableCost}
                disableServiceRequest={disableServiceRequest}
                disableOperatingIncome={disableOperatingIncome}
                disableRevenue={disableRevenue}
                disableBilling={disableBilling}
                isSupplierInvoiceCNGenerated={getIsSupplierInvoiceCNGenerated(service)}
                isValidParty={isValidParty}
                setValidParty={setValidParty}
                onUpdateService={onUpdateService}
                editCharge={isChargeEditable(indexChild) || (item.Id === 0 && !item.TariffDetails?.IsActive)}
                showTariffModal={showTariffModal && isChargeEditable(indexChild)}
                setShowTarriffModal={(value: boolean) => setShowTarriffModal(value)}
                onTariffModalConfirm={onTariffModalConfirm}
                onTariffModalCancel={() => onTariffModalCancel(indexChild)}
                onTariffModalOpen={() => onTariffModalOpen(indexChild)}
                isReadOnly={isReadOnly}
                updateChargeValidation={updateChargeValidation}
                addToEditable={addToEditable}
                undoB2BwhenInvoiced={undoB2BwhenInvoiced}
                removeFromEditable={removeFromEditable}
              />
            </Overview>
          </Fragment>
        )
      }
      <PegModal
        isOpen={closeConfirm}
        alertModal={true}
        showTemplate={true}
        isCenter={true}>
        <div style={{ overflow: 'hidden', padding: 30 }}>
          <QuotationAlert>
            <h1 style={{ marginBottom: '4px' }}>Save details before closing !</h1>
            <p>Please confirm, you want to close ? </p>
          </QuotationAlert>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 10 }}>
          <PegButton
            id="saveConfrimModalButton"
            type="button"
            variant="Primary"
            data-testid="refreshModalButton"
            styleObject={{ marginRight: '10px' }}
            onClick={onCancel}
          >
            Cancel
          </PegButton>
          <PegButton
            id="closeConfrimModalButton"
            type="button"
            variant="Primary"
            data-testid="refreshModalButton"
            styleObject={{ marginRight: '5px' }}
            onClick={onCloseModal}
          >
            Close
          </PegButton>
        </div>
      </PegModal>

      <PegModal
        isOpen={showModal}
        alertModal={true}
        isCenter={true}
        showTemplate={true}
      >
        <EditModal
          type={'jobGridEdit'}
          onClose={onClose}
          onEditConfirm={onEditConfirm}
          id={id}
          index={index}
          jobDetails={jobDetails}
          userDetails={userDetails}
          columnPreference={columnPreference}
          serviceIndex={index}
          serviceObject={service}
          service={service.service}
          salesInvoice={service.salesInvoice}
          charge={service?.charges}
          serviceRequestDetails={service?.serviceRequestDetails}
          srNoDropdown={srNoDropdown}
          supplierDetails={service.supplierDetails}
          serviceList={serviceList}
          services={services}
          isSupplierInvoiceCNGenerated={getIsSupplierInvoiceCNGenerated(service)}
          uomList={uomList}
          currencyList={currencyList}
          billingParties={billingPartyList}
          taxList={taxList}
          exchangeRates={exchangeRates}
          paymentTerms={paymentTerms}
          onSelectServices={onSelectServices}
          onDeleteService={onDeleteService}
          onAddCharge={onAddCharge}
          charges={service?.charges}
          onChangeInput={onChangeInput}
          onChangeDate={onChangeDate}
          onChangeDropdown={(event: any, elemId: any, obj: any) => onChangeDropdown(event, elemId, obj)}
          onUpdateServiceDetails={onUpdateServiceDetails}
          onUpdateService={onUpdateService}
          onUpdateCharge={onUpdateCharge}
          disableCost={disableCost}
          disableServiceRequest={disableServiceRequest}
          disableOperatingIncome={disableOperatingIncome}
          disableRevenue={disableRevenue}
          disableBilling={disableBilling}
          selectedList={selectedList}
          voucherList={voucherList}
          isValidParty={isValidParty}
          setValidParty={setValidParty}
          setPartyValidModal={setPartyValidModal}
          editService={editService}
          missingValidation={missingValidation}
          hasMissingFields={hasMissingFields}
        />
      </PegModal>
    </div>
  )
};

export default ServiceWrapper;

