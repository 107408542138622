/* eslint-disable @typescript-eslint/no-unused-expressions */
import { ServiceTemplateDefault } from "Model/ServiceTemplate/ServiceTemplatedefault";
import moment from "moment";
import {
  getExchangeRates
} from 'Utils/Generic';

export function duplicateTemplate(serviceTemplate: any) {
  const {
    ServiceTemplateDetails,
    BusinessProducts
  } = serviceTemplate
  let bpProducts: any = BusinessProducts.map((bp: any) => {
    return {
      ...bp,
      ServiceTemplateFk: null,
      BusinessProductId: null,
      servicesAndCharges: []
    }
  });
  let templateDetails = {
    ...ServiceTemplateDetails,
    ServiceTemplateId: null
  };
  let visibililtyGrid = serviceTemplate?.ServiceTemplateVisibility?.map((item: any) => {
    return {
      ...item,
      ServiceTemplateFk: null,
      Id: null
    }
  })
  return {
    ServiceTemplateDetails: templateDetails,
    ServiceTemplateVisibility: visibililtyGrid,
    BusinessProducts: [...bpProducts]

  }
}

export function duplicateBusinessProduct(products: any) {
  let bpList = products.map((product: any) => {
    let servChar = product?.servicesAndCharges?.map((item: any) => {
      let services = {
        ...item?.services,
        Id: null
      }
      let charges = item?.charges?.map((charge: any) => {
        return {
          ...charge,
          Id: null
        }
      })
      return {
        services: services,
        charges: charges
      }
    })
    return {
      ...product,
      ServiceTemplateFk: null,
      BusinessProductId: null,
      servicesAndCharges: [...servChar]
    }
  })
  return bpList;
}

export const onBulkEdit = (serviceList: any, selectedList: any, bulkList: any, serviceTemplate: any, exchangeRates: any) => {
  serviceList = serviceList.map((list: any, index: number) => {
    if (selectedList.includes(index)) {
      if (bulkList.BillingCurrencyCode || bulkList.PriceCurrencyCode) {
        let service = serviceList[index].services;
        let fromCurrency = bulkList.BillingCurrencyCode ? bulkList.BillingCurrencyCode : service?.BillingCurrencyCode;
        let targetCurrency = bulkList.PriceCurrencyCode ? bulkList.PriceCurrencyCode : service?.PriceCurrencyCode;
        if (serviceTemplate?.ServiceTemplateDetails?.BaseCurrencyCode !== targetCurrency) {
          fromCurrency = bulkList.PriceCurrencyCode ? bulkList.PriceCurrencyCode : service?.PriceCurrencyCode
          targetCurrency = bulkList.BillingCurrencyCode ? bulkList.BillingCurrencyCode : service?.BillingCurrencyCode
        }
        if (fromCurrency !== targetCurrency) {
          let exChangeRate: any = getExchangeRates(exchangeRates, fromCurrency, targetCurrency)
          bulkList.BillingExchangeRate = exChangeRate ? exChangeRate.ExchangeRate : 0;
        } else {
          bulkList.BillingExchangeRate = 1
        }
      }
      return {
        ...list,
        services: {
          ...list.services,
          ...bulkList
        },
        charges: [...list.charges],
      }
    } else {
      return list
    }
  })
  return serviceList;
}

export const onBulkEditAll = (serviceList: any, selectedList: any, bulkList: any, serviceTemplate: any, exchangeRates: any[]) => {
  serviceList = serviceList.map((list: any, index: number) => {
    if (bulkList.BillingCurrencyCode || bulkList.PriceCurrencyCode) {
      let service = serviceList[index].services;
      let fromCurrency = bulkList.BillingCurrencyCode ? bulkList.BillingCurrencyCode : service?.BillingCurrencyCode;
      let targetCurrency = bulkList.PriceCurrencyCode ? bulkList.PriceCurrencyCode : service?.PriceCurrencyCode;
      if (serviceTemplate?.ServiceTemplateDetails?.BaseCurrencyCode !== targetCurrency) {
        fromCurrency = bulkList.PriceCurrencyCode ? bulkList.PriceCurrencyCode : service?.PriceCurrencyCode
        targetCurrency = bulkList.BillingCurrencyCode ? bulkList.BillingCurrencyCode : service?.BillingCurrencyCode
      }
      if (fromCurrency !== targetCurrency) {
        let exChangeRate: any = getExchangeRates(exchangeRates, fromCurrency, targetCurrency)
        bulkList.BillingExchangeRate = exChangeRate ? exChangeRate.ExchangeRate : 0;
      } else {
        bulkList.BillingExchangeRate = 1
      }
    }
    return {
      ...list,
      services: {
        ...list.services,
        ...bulkList
      },
      charges: [...list.charges],
    }
  })
  return serviceList;
}

export const setServiceTemplate = (obj: ServiceTemplateDefault, key: string, event: any) => {
  switch (key) {
    case 'templateName':
      obj!.ServiceTemplateDetails!.Name = event?.target?.value
      break;
    case 'description':
      obj!.ServiceTemplateDetails!.Description = event?.target?.value
      break;
    case 'isActive':
      obj!.ServiceTemplateDetails!.IsActive = event.target.checked ? Boolean(event.target.checked) : false;
      break;
    case 'baseCurrency':
      obj!.ServiceTemplateDetails!.BaseCurrencyCode = event ? event.code : ''
      obj!.ServiceTemplateDetails!.BaseCurrencyName = event ? event.name : ''
      break;
    case 'visibilityList':
      obj!.ServiceTemplateVisibility = [];
      obj!.ServiceTemplateVisibility = event;
      break;
    case 'businessProducts':
      obj!.BusinessProducts = event;
      break;
    case 'customer':
      obj.ServiceTemplateDetails!.CustomerCode = event ? event.code : ''
      obj.ServiceTemplateDetails!.CustomerName = event ? event.name : ''
      break;
    case 'vesselType':
      obj.ServiceTemplateDetails!.VesselTypeCode = event ? event.code : ''
      obj.ServiceTemplateDetails!.VesselTypeName = event ? event.name : ''
      break;
    case 'port':
      obj.ServiceTemplateDetails!.PortCode = event ? event.code : ''
      obj.ServiceTemplateDetails!.PortName = event ? event.name : ''
      if (!event) {
        obj.ServiceTemplateDetails!.VesselTypeCode = ''
        obj.ServiceTemplateDetails!.VesselTypeName = ''
      }
      break;
    case 'visibilityType':
      obj.ServiceTemplateDetails!.VisibilityType = event ? event : ''
      break;
    case 'companyDetails':
      obj.ServiceTemplateDetails!.CompanyCode = event ? event.Code : ''
      obj.ServiceTemplateDetails!.CompanyFk = event ? event.CompanyId : ''
      obj.ServiceTemplateDetails!.CompanyLegalName = event ? event.LegalName : ''
      obj.ServiceTemplateDetails!.CompanyCommonName = event ? event.CommonName : ''
      obj.ServiceTemplateDetails!.IsActive = true;
      obj.ServiceTemplateVisibility = [{ IsActive: true, CompanyFk: event ? event.CompanyId : '' }]
      break;
  }
  return obj
}

export const setServiceValue = (obj: any, key: any, event: any, jobDetails: any,) => {
  if (key === 'actualCurrency') {
    obj.PriceCurrencyCode = event ? event.code : ''
  }
  if (key === 'startDate') {
    obj.StartDateTime = moment(event).utc().format()
  }
  if (key === 'endDate') {
    obj.EndDateTime = moment(event).utc().format()
  }
  if (key === 'estimatedCurrency') {
    obj.CostCurrencyCode = event ? event.code : ''
  }
  if (key === 'defaultCostCurrency') {
    obj.CostCurrencyCode = event
  }
  if (key === 'defaultBillingCurrency') {
    obj.BillingCurrencyCode = event
  }
  if (key === 'defaultPriceCurrency') {
    obj.PriceCurrencyCode = event
  }
  if (key === 'billingCurrency') {
    obj.BillingCurrencyCode = event ? event.code : ""
  }
  if (key === 'supplier') {
    obj.SupplierName = event ? event.name : ""
    obj.SupplierCode = event ? event.code : ""
  }
  if (key === 'serviceName') {
    obj.Name = event ? event.name : ""
    obj.Code = event ? event.code : ""
    obj.OPTags = event ? event.opTags : ""
    obj.svsCategoryCodes = event ? event.svsCategories : ""
  }
  if (key === 'qty') {
    obj.Quantity = event.target.type === "number" ? parseFloat(event.target.value) : event.target.value
  }
  if (key === 'unitPrice') {
    obj.UnitPrice = event.target.type === "number" ? parseFloat(event.target.value) : event.target.value
  }
  if (key === 'remarks') {
    obj.InternalRemarks = event
  }
  if (key === 'BillingExchangeRate') {
    obj.BillingExchangeRate = event
  }
  if (key === 'CostExchangeRate') {
    obj.CostExchangeRate = event
  }
  if (key === 'PriceExchangeRate') {
    obj.PriceExchangeRate = event
  }
  if (key === 'billingStatus') {
    if (event) {
      obj.BillingStatus = event.name
    } else {
      obj.BillingStatus = ""
    }
    if (!obj.PaymentTermCode) {
      obj.PaymentTermCode = '30'
    }
    if (!obj.BillingMethodCode) {
      obj.BillingMethodCode = 'Credit'
    }
  }
  if (key === 'paymentMethod') {
    if (event) {
      obj.PaymentTermCode = event.code
    } else {
      obj.PaymentTermCode = ""
    }
  }
  if (key === 'billingMethod') {
    if (event) {
      obj.BillingMethodCode = event.name
    } else {
      obj.BillingMethodCode = ""
    }
  }
  return obj
}

export const setChargeValue = (obj: any, key: any, event: any, jobDetails: any,) => {
  if (key === 'actualTax') {
    if (event) {
      obj.PriceTaxCode = event.Code
      obj.PriceTaxPercent = event.Percentage
    } else {
      obj.PriceTaxCode = ''
      obj.PriceTaxPercent = ''
    }
  }
  if (key === 'vatType') {
    obj.PriceTaxCode = event ? event.code : ''
    obj.PriceTaxPercent = event ? event.percentage : ''
  }
  if (key === 'estimateTax') {
    obj.CostTaxAmount = ""
    obj.CostTaxCode = event ? event.Code : ''
    obj.CostTaxPercent = event ? event.Percentage : ''
  }
  if (key === 'UOM') {
    obj.UomName = event ? event.fullName : ''
    obj.UomCode = event ? event.code : ''
    obj.CostUomName = event ? event.fullName : ''
    obj.CostUomCode = event ? event.code : ''
  }
  if (key === 'costUOM') {
    obj.CostUomName = event ? event.fullName : ''
    obj.CostUomCode = event ? event.code : ''
  }
  if (key === 'billingParty') {
    obj.OperationalProcessPartyFk = event ? event.operationalProcessPartyId : ''
  }
  if (key === 'chargeName') {
    obj.Name = event ? event.name : ''
    obj.Code = event ? event.code : ''
    obj.MasterCode = event ? event.gacMasterCode : ''
  }
  if (key === 'unit') {
    obj.UnitPrice = event.target.type === "number" ? parseFloat(event.target.value) : event.target.value
  }
  if (key === 'quantity') {
    const qtyValue = event.target.type === "number" ? parseFloat(event.target.value) : event.target.value
    obj.Quantity = qtyValue
    obj.CostQuantity = qtyValue
  }
  if (key === 'costQuantity') {
    obj.CostQuantity = event.target.type === "number" ? parseFloat(event.target.value) : event.target.value
  }
  if (key === 'remarks') {
    obj.InternalRemarks = event.target.value
  }
  if (key === 'estUnitCost') {
    obj.UnitCost = event.target.value ? event.target.type === "number" ? parseFloat(event.target.value) : event.target.value : ''
  }
  if (key === 'BillingExchangeRate') {
    obj.BillingExchangeRate = event
  }
  if (key === 'BillingCurrencyCode') {
    obj.BillingCurrencyCode = event
  }
  if (key === 'CostCurrencyCode') {
    obj.CostCurrencyCode = event
  }
  if (key === 'CostExchangeRate') {
    obj.CostExchangeRate = event
  }
  if (key === 'CostTaxAmount') {
    obj.CostTaxAmount = event
  }
  if (key === 'EstimatedCost') {
    obj.EstimatedCost = event
  }
  if (key === 'OperatingIncomeActual') {
    obj.OperatingIncomeActual = event
  }
  if (key === 'PriceTaxAmount') {
    obj.PriceTaxAmount = event
  }
  if (key === 'TotalEstimatedCost') {
    obj.TotalEstimatedCost = event
  }
  if (key === 'TotalPriceAmount') {
    obj.TotalPriceAmount = event
  }
  if (key === 'PriceExchangeRate') {
    obj.PriceExchangeRate = event
  }
  if (key === 'PriceCurrencyCode') {
    obj.PriceCurrencyCode = event
  }
  if (key === 'eyeShareCost') {
    obj.TotalEyeshareAmount = event.target.type === "number" ? parseFloat(event.target.value) : event.target.value
  }
  if (key === 'BillAmount') {
    obj.BillAmount = event
  }
  if (key === 'CostVariance') {
    obj.CostVariance = event
  }
  if (key === 'PriceVariance') {
    obj.PriceTaxCode = event ? event.code : ''
    obj.PriceTaxPercent = event ? event.percentage : ''
  }
  if (key === 'startDate') {
    obj.StartDateTime = moment(event.value).utc().format()
  }
  if (key === 'endDate') {
    obj.EndDateTime = moment(event.value).utc().format()
  }

  return obj
}
