import React from 'react';
import { ReactNode } from 'react';
import moment from "moment";
import Header from './Header';
import HeaderOptions from './HeaderOptions';
import Footer from './Footer';
import List from './List';
import {
  OverviewGrid, TabItem
} from '../../template.styles'
import axios from 'axios';
import {
  setServiceValue,
  setChargeValue,
  onBulkEdit,
  onBulkEditAll
} from 'Utils/ServiceTemplate';
import {
  getExchangeRates
} from 'Utils/Generic';

import Loader from 'Components/Loader';
import { LoaderOverviewGrid } from '../ServiceVisibilityGrid/ServiceVisibility.styles';
import EditForm from '../EditForm/EditForm';
import PegModal from 'Controls/PegModal';
import { BackOfficeCustomSettings } from 'Model/Common/types';
import { EnableCostQtySettings } from 'Constants/Constant';

interface MyProps {
  product: any;
  userDetails: any;
  products: any[],
  serviceAndCharges: any[],
  isFeildsMissing: boolean;
  opTags: any[],
  onAddTemplateService: () => void;
  onBulkUpdate: (services: any) => void;
  onSetDefaultProduct: (product: any) => void;
  onMultiDeleteServices: (list: any[]) => void;
  onDeleteTemplateService: (serviceIndex: number, opCode: number) => void;
  onAddTemplateCharge: (serviceIndex: number) => void;
  onDeleteTemplateCharge: (serviceIndex: number, chargeIndex: number) => void;
  onUpdateTemplateService: (serviceIndex: number, service: any) => void;
  onUpdateTemplateCharge: (serviceIndex: number, chargeIndex: number, charge: any) => void;
  onUpdateBPserviceCharges: (product: any, servAndChar: any) => void;
  onUpdateOpTags: (product: any, opTag: any) => void;
  onSaveOfServiceTemplate: () => void;
  serviceTemplate: any;
}

interface MyState {
  UOM: any[],
  serviceList: any[],
  uomList: any[],
  currencyList: any[],
  billingParties: any[],
  taxList: any[],
  exchangeRates: any[],
  exchangeRatesLoading?: boolean;
  disableOperatingIncome: boolean,
  disableRevenue: boolean,
  disableCost: boolean,
  isLoading: boolean,
  showEditModal: boolean;
  bulkEditList: any;
  isSelected?: boolean;
  isAllSelected: boolean;
  selectedList: any[];
  selectedIndex?: any;
  showDeleteModal: boolean;
  companySettings: BackOfficeCustomSettings[];
  isShowCostQty: boolean
}
class JobGrid extends React.Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      UOM: [],
      serviceList: [],
      uomList: [],
      currencyList: [],
      billingParties: [],
      taxList: [],
      exchangeRates: [],
      exchangeRatesLoading: true,
      isLoading: true,
      selectedList: [],
      selectedIndex: null,
      bulkEditList: {},
      showEditModal: false,
      isSelected: false,
      isAllSelected: false,
      showDeleteModal: false,
      //grouping
      disableOperatingIncome: false,
      disableRevenue: false,
      disableCost: false,
      companySettings: [],
      isShowCostQty: false
    };
  }

  componentDidMount() {
    const {
      product,
      products
    } = this.props
    // if (product) {
    //   this.setBusinessProduct(product)
    // }
    // this api is just to get all charges together
    this.getServiceCharges(products)


  }

  getCompanyDetails = (companyCode: string | null) => {

    if (!companyCode) {
      return
    }

    axios.get(`/getCompanyDefaultDetails?company-code=${companyCode}`).then((response: any) => {
      if (response.data) {
        this.setState({ companySettings: response.data }, () => this.updateShowCostColumn())
      }
    })
  }

  updateShowCostColumn = () => {
    const { product } = this.props
    const costQtySettings = this.state?.companySettings?.find((item: BackOfficeCustomSettings) => item?.name === EnableCostQtySettings)
    const isSettingAvailable = !!costQtySettings?.value?.split(';')?.find((item: string) => item === product?.Code);
    this.setState({ isShowCostQty: isSettingAvailable })
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const {
      product,
    } = nextProps;
    if (product?.Code !== this.props.product?.Code) {
      this.setBusinessProduct(product);
      if (!product['servicesAndCharges'] && product?.Code) {
        this.getServiceAndCharges(product)
      }
    }
  }

  componentWillUnmount() {
    this.setState({ isLoading: true })
    this.props.onSetDefaultProduct({})
  }

  getServiceCharges = (products: any[]) => {
    Promise.all(products.map(product => axios.get(`/oum-service-template-other-details?businessProductId=${product?.BusinessProductId}`).
      then(response => {
        this.props.onUpdateBPserviceCharges(product, response.data?.BusinessProducts?.servicesAndCharges)
        this.props.onSetDefaultProduct(this.props.product)
      })))
  }

  getServiceAndCharges = (product: any) => {
    axios.get(`/oum-service-template-other-details?businessProductId=${product?.BusinessProductId}`).then((response) => {
      if (response.data) {
        this.props.onUpdateBPserviceCharges(product, response.data?.BusinessProducts?.servicesAndCharges)
        this.props.onSetDefaultProduct(product)
      }
    })
  }

  setBusinessProduct = (product: any) => {
    if (product?.BusinessProductId) {
      this.props.onSetDefaultProduct(product)
    }
    // commenting this for api issue
    // this.props.onSetDefaultProduct(product)
    // if (product?.BusinessProductId) {
    //   this.getServiceAndCharges(product)
    // }
    this.setState({
      isLoading: true,
    }, () => this.getDropdown())
  }

  getDropdown = () => {
    const {
      product
    } = this.props
    const companyCode = new URLSearchParams(window.location.search).get("companycode");
    this.getCurrencies();
    this.getUOM();
    if (product?.Code) {
      this.getServices(companyCode)
    }
    this.getTaxes(companyCode);
    this.getExchangeRates(companyCode);
    this.getCompanyDetails(companyCode)
  }

  getServices = (companyCode: any) => {
    const {
      product
    } = this.props
    axios.get(`/oum-getservices?company-code=${companyCode}&bp-code=${product?.Code}&include-svs-category=0`).then((response) => {
      this.setState({ serviceList: response.data[0]?.services, isLoading: false })
    })
  }

  getCurrencies = () => {
    axios.get(`/mdm-currency?searchText=&code=`).then((response) => {
      this.setState({ currencyList: response.data })
    })
  }

  getUOM = () => {
    axios.get(`/mdm-unit-of-measure?uomShortNames=&uomCodes=&searchText=`).then((response) => {
      this.setState({ uomList: response.data })
    })
  }

  getTaxes = (companyCode: any) => {
    axios.get(`/oum-get-tax?company-code=${companyCode}`).then((response) => {
      this.setState({ taxList: response.data })
    })
  }

  getExchangeRates = (companyCode: any) => {
    axios.get(`/jobService-getAllCurrencyExchangeRates?company-code=${companyCode}`).then((response) => {
      this.setState({ exchangeRates: response.data })
    })
  }

  onClickGroupingOptions = (type: string) => {
    if (type === 'actuals') {
      this.setState({ disableRevenue: !this.state.disableRevenue })
    }
    if (type === 'estimates') {
      this.setState({ disableCost: !this.state.disableCost })
    }
    if (type === 'OpIncome') {
      this.setState({ disableOperatingIncome: !this.state.disableOperatingIncome })
    }
  }

  onAddCharge = (index: number) => {
    this.props.onAddTemplateCharge(index)
  }

  onDeleteService = (index: number) => {

    const selectedService = this.props.serviceAndCharges[index]
    const selectedServiceTemp = this.state.serviceList.find(sv => sv.code == selectedService.services.Code)
    const opCode = selectedServiceTemp.opTags[0].code
    this.props.onDeleteTemplateService(index, opCode)
  }

  onDeleteCharge = (serviceIndex: number, chargeIndex: number) => {
    this.props.onDeleteTemplateCharge(serviceIndex, chargeIndex)
  }

  onUpdateService = (serviceIndex: number, service: any, key: string, value: any) => {
    let servObj = setServiceValue(service, key, value, null)
    this.props.onUpdateTemplateService(serviceIndex, servObj)
  }

  onUpdateCharge = (serviceIndex: number, chargeIndex: number, charge: any, key: string, value: any) => {
    let chargeObj = setChargeValue(charge, key, value, null)
    this.props.onUpdateTemplateCharge(serviceIndex, chargeIndex, chargeObj)
  }

  onDeleteOption = () => {
    this.setState({ showDeleteModal: true })
  }

  onDeleteModalCancel = () => {
    this.setState({ showDeleteModal: false })
  }

  onDeleteModalConfirm = async () => {
    const {
      onMultiDeleteServices
    } = this.props
    const {
      selectedList
    } = this.state
    selectedList.map(x => onMultiDeleteServices(this.props.serviceAndCharges[x]))
    // await onMultiDeleteServices(selectedList)
    this.setState({ showDeleteModal: false, selectedList: [], isAllSelected: false, isSelected: false })
  }


  onSelectServices = (index: number) => {
    this.setState({ selectedIndex: index })
    let selectedList = this.state.selectedList
    if (!selectedList.includes(index)) {
      selectedList.push(index);
    } else {
      selectedList.splice(selectedList.indexOf(index), 1);
    }
    this.setState({ selectedList, isAllSelected: false });
  }

  onSelectAll = () => {
    this.setState({ isAllSelected: !this.state.isAllSelected },
      () => this.setSelectedArray(this.state.isAllSelected))
  }

  setSelectedArray = (isAllSelected: boolean) => {
    const {
      serviceAndCharges
    } = this.props
    if (isAllSelected) {
      let list: any = []
      serviceAndCharges?.forEach((item: any, index: number) => {
        list.push(index)
      })
      this.setState({ selectedList: list })
    } else {
      this.setState({ selectedList: [] })
    }
  }

  onEditOption = () => {
    this.setState({ showEditModal: true })
  }

  onEditOptionCancel = () => {
    this.setState({ showEditModal: false })
  }

  onEditOptionConfirm = () => {
    let {
      selectedList,
      bulkEditList,
      isAllSelected,
      exchangeRates
    } = this.state
    let {
      serviceAndCharges,
      serviceTemplate,
      onBulkUpdate,
      // jobDetails
    } = this.props
    let updatedList =
      isAllSelected ?
        onBulkEditAll(serviceAndCharges, selectedList, bulkEditList, serviceTemplate, exchangeRates)
        :
        onBulkEdit(serviceAndCharges, selectedList, bulkEditList, serviceTemplate, exchangeRates);
    onBulkUpdate(updatedList)
    this.setState({
      showEditModal: false,
      selectedList: [],
      isAllSelected: false,
      bulkEditList: {},
    });
  }

  onEditServices = (event: any, key: any) => {
    let {
      bulkEditList,
    } = this.state
    const {
      serviceTemplate
    } = this.props
    if (key === 'startDate') {
      bulkEditList.StartDateTime = moment(event.value).format()
    }
    if (key === 'editDate') {
      bulkEditList.EndDateTime = moment(event.value).format()
    }
    if (key === 'billingCurrency') {
      if (event) {
        bulkEditList.BillingCurrencyCode = event.code
      } else {
        bulkEditList.BillingCurrencyCode = ""
      }
    }
    if (key === 'priceCurrency') {
      if (event) {
        let priceExRate = this.getConvertionRate(event.code, serviceTemplate?.ServiceTemplateDetails?.BaseCurrencyCode)
        bulkEditList.PriceCurrencyCode = event.code
        bulkEditList.PriceExchangeRate = priceExRate ? priceExRate.ExchangeRate : 0
      } else {
        bulkEditList.PriceCurrencyCode = "";
        bulkEditList.PriceExchangeRate = 0
      }
    }
    if (key === 'costCurrency') {
      if (event) {
        let costExRate = this.getConvertionRate(event.code, serviceTemplate?.ServiceTemplateDetails?.BaseCurrencyCode)
        bulkEditList.CostCurrencyCode = event.code
        bulkEditList.CostExchangeRate = costExRate ? costExRate.ExchangeRate : 0
      } else {
        bulkEditList.CostCurrencyCode = "";
        bulkEditList.CostExchangeRate = 0;
      }
    }
    if (key === 'supplier') {
      if (event) {
        bulkEditList.SupplierName = event.name
        bulkEditList.SupplierCode = event.code
      } else {
        bulkEditList.SupplierName = ""
        bulkEditList.SupplierCode = ""
      }
    }
  }

  getConvertionRate = (fromCurrency: any, targetCurrency: any) => {
    const {
      exchangeRates
    } = this.state
    let exchangeRate: any = getExchangeRates(exchangeRates, fromCurrency, targetCurrency)
    return exchangeRate;
  }


  render(): ReactNode {
    const {
      disableRevenue,
      disableCost,
      disableOperatingIncome,
      uomList,
      currencyList,
      exchangeRates,
      taxList,
      serviceList,
      isLoading,
      isAllSelected,
      selectedList,
      showDeleteModal,
      isShowCostQty
    } = this.state
    const {
      serviceAndCharges,
      onAddTemplateService,
      product,
      isFeildsMissing,
      opTags,
      onUpdateOpTags,
      userDetails
    } = this.props

    if (isLoading) {
      return (
        <LoaderOverviewGrid>
          <Loader size={50} color={'#aacbe9'} />
        </LoaderOverviewGrid>
      );
    }
    return (
      <div>
        <HeaderOptions
          disableRevenue={disableRevenue}
          disableCost={disableCost}
          disableOperatingIncome={disableOperatingIncome}
          onClickGroupingOptions={(type: string) => this.onClickGroupingOptions(type)} />
        <OverviewGrid>
          <Header
            selectedProduct={product}
            disableRevenue={disableRevenue}
            disableCost={disableCost}
            disableOperatingIncome={disableOperatingIncome}
            isAllSelected={isAllSelected}
            onSelectAll={() => this.onSelectAll()}
            isShowCostQty={isShowCostQty}
          />
          {serviceAndCharges?.map((item: any, index: number) => {
            return (
              <div key={index}>
                <List
                  userDetails={userDetails}
                  service={item.services}
                  charges={item.charges}
                  serviceIndex={index}
                  disableRevenue={disableRevenue}
                  disableCost={disableCost}
                  selectedProduct={product}
                  opTags={opTags}
                  disableOperatingIncome={disableOperatingIncome}
                  isAllSelected={isAllSelected}
                  onAddCharge={(serviceIndex: number) => this.onAddCharge(serviceIndex)}
                  onDeleteService={(serviceIndex: number) => this.onDeleteService(serviceIndex)}
                  onDeleteCharge={(serviceIndex: number, chargeIndex: number) =>
                    this.onDeleteCharge(serviceIndex, chargeIndex)}
                  onUpdateService={(servIndex: number, service: any, key: string, value: any) =>
                    this.onUpdateService(servIndex, service, key, value)}
                  onUpdateCharge={(servIndex: number, chargeIndex: number, charge: any, key: string, value: any) =>
                    this.onUpdateCharge(servIndex, chargeIndex, charge, key, value)}
                  onUpdateTemplateCharge={(serviceIndex: number, chargeIndex: number, charge: any) =>
                    this.props.onUpdateTemplateCharge(serviceIndex, chargeIndex, charge)}
                  onUpdateTemplateService={(serviceIndex: number, serviceObj: any) =>
                    this.props.onUpdateTemplateService(serviceIndex, serviceObj)}
                  onUpdateOpTags={(prod: any, opTag: any) =>
                    onUpdateOpTags(product, opTag)}
                  onSelectServices={(indexId: number) => this.onSelectServices(indexId)}
                  selectedList={selectedList}
                  serviceList={serviceList}
                  uomList={uomList}
                  currencyList={currencyList}
                  taxList={taxList}
                  exchangeRates={exchangeRates}
                  isShowCostQty={isShowCostQty}
                />
              </div>
            )
          })}

        </OverviewGrid>
        <Footer
          selectedList={selectedList}
          isFeildsMissing={isFeildsMissing}
          onAddTemplateService={() => onAddTemplateService()}
          onSave={() => this.props.onSaveOfServiceTemplate()}
          onEditOption={() => this.onEditOption()}
          onDeleteOption={() => this.onDeleteOption()}
        />

        <PegModal
          isOpen={showDeleteModal}
          closeModal={this.onDeleteModalCancel}
          tertiaryAction={this.onDeleteModalConfirm}
          modalTitle={"Delete Items"}
          buttonText={"Delete"}
          modalContent={"Are you sure to delete the selected items"}
        />

        <div id="serviceTemplateEdit">
          <PegModal
            isOpen={this.state.showEditModal}
            closeModal={this.onEditOptionCancel}
            tertiaryAction={this.onEditOptionConfirm}
            modalTitle={"Edit Items"}
            buttonText={"Update"}
            isCenter={true}
            showTemplate={true}
          >
            <EditForm
              selectedProduct={product}
              currencyList={currencyList}
              onEditServices={(event: any, key: any) => this.onEditServices(event, key)}
            />
          </PegModal>
        </div>
      </div>
    )
  }
}

export default JobGrid;