import PegButton from 'Controls/Button/PegButton';
import Dropdown from 'Controls/Dropdown';
import PegInput from 'Controls/Input/PegInputBox';
import React from 'react';
import { MdClear } from 'react-icons/md';
import {
  PointList,
  Options,
  Footer,
  Label,
  RequiredFields,
  PointLists,
  TabList,
  Tab,
  PointDetails,
  PointIcon
} from '../port.styles';

type MyState = {
  selectedTab: any
};

type MyProps = {
  onUpdateCountry: (center: any, details: any) => void;
  onUpdateTitle: (title: any) => void;
  onUpdateUser: (users: any[]) => void;
  onSubmitPort: () => void;
  getPoints: () => void;
  points: any[];
  paths: any;
  title: any;
  users: any[];
  countryDetails: any;
  isValidated: boolean;
  getPointsLoading: boolean;
  onDeletePoint: (index: number) => void;
};

class PortAssignment extends React.Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      selectedTab: 'path'
    };
  }
  onChangeDropdownValue = (event: any) => {
    this.props.onUpdateUser(event);
  }

  onPlaceChanged = (event: any) => {
    let center: any = {}
    center.lat = event.lat;
    center.lng = event.long;
    this.props.onUpdateCountry(center, event);
  }

  onChangeInput = (event: any) => {
    this.props.onUpdateTitle(event);
  }

  onClickTab = (type: string) => {
    this.setState({ selectedTab: type })
  }


  render() {
    const {
      title,
      users,
      countryDetails,
      isValidated,
      getPoints,
      points,
      getPointsLoading,
      paths,
      onDeletePoint
    } = this.props;
    const {
      selectedTab
    } = this.state

    return (
      <PointList>
        <Options>
          <Label>Name</Label>
          <PegInput
            type={'text'}
            value={title}
            id={'txtName'}
            name={'name'}
            isMandatory={true}
            onChange={(e: any, id: string) => this.onChangeInput(e)} />
        </Options>
        <Options>
          <Label>Country</Label>
          <Dropdown
            id={'country'}
            isLookup={true}
            value={countryDetails}
            defaultValue={countryDetails?.name}
            dropdownLabel={'name'}
            dropdownValue={'name'}
            appendParams={false}
            useDebounce={true}
            removeIsClear={true}
            isMandatory={true}
            url={`/oum-country?search-text=`}
            placeHolder="Select country"
            onChangeDropdown={this.onPlaceChanged} />
        </Options>
        <Options>
          <Label>User</Label>
          <Dropdown
            id={'user'}
            isLookup={true}
            isMulti={true}
            value={users}
            defaultValue={users}
            appendOptions={true}
            dropdownLabelKeys={['FirstName', 'LastName']}
            dropdownValue={'FirstName'}
            appendParams={false}
            useDebounce={true}
            removeIsClear={true}
            isMandatory={true}
            url={`/oum-user?search-text=`}
            placeHolder="Select User"
            onChangeDropdown={this.onChangeDropdownValue} />
        </Options>
        {paths?.length ?
          <>
            <TabList>
              <Tab
                style={{
                  border: selectedTab === 'path' ? '2px solid #07bc0c' : '2px solid #D8D8D8'
                }}
                onClick={() => this.onClickTab('path')}>
                Marked points
              </Tab>
              <Tab
                style={{
                  border: selectedTab === 'points' ? '2px solid #07bc0c' : '2px solid #D8D8D8'
                }}
                onClick={() => this.onClickTab('points')}>
                Available ports
              </Tab>
            </TabList>
            {
              selectedTab === 'path' &&
              <Options>
                {paths?.map((path: any, index: any) => {
                  return (
                    <PointDetails key={`map-${path.lat}`} id={`map-${path.lat}`}>
                      <div style={{ paddingRight: 12 }}>Point - {index + 1}</div>
                      <PointIcon onClick={() => onDeletePoint(index)}>
                        <MdClear
                          style={{ color: 'red', fontSize: 16, cursor: 'pointer' }} />
                      </PointIcon>
                    </PointDetails>
                  )
                })}
              </Options>
            }
            {
              selectedTab === 'points' &&
              <Options>
                <PegButton
                  id="servicetemplete_ports"
                  type="button"
                  variant="Success"
                  onClick={() => getPoints()}>
                  Get Ports
                </PegButton>
                {
                  getPointsLoading && <PointLists>Getting list ...</PointLists>
                }
                <PointLists>
                  {
                    points.length ? points.map((point: any, key: any) => {
                      return (
                        <div>
                          {key + 1} - {point.name}
                        </div>
                      )
                    })
                      : <div />
                  }
                </PointLists>
              </Options>
            }
          </>
          :
          <div />
        }
        <Footer>
          <PegButton
            id="servicetemplete_addrow"
            type="button"
            variant="Primary"
            onClick={this.props.onSubmitPort}>
            Assign port
          </PegButton>
          {!isValidated && <RequiredFields>Fields Required</RequiredFields>}
        </Footer>
      </PointList>
    )
  }
}

export default PortAssignment;
