import styled from 'styled-components';

export const Overview = styled.div`
padding: 16px;
`;

export const Section = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px 12px;
`;

export const Left = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
`;

export const Right = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
`;


export const Label = styled.div`
  font-size: 12px;
  width: 90px;
`;
