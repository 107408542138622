import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  HeaderView,
  Overview,
  Group,
  GroupName,
  Flex,
  // list
  ID,
  Delete,
  Add,
  Expand,
  Detail,
  ExternallyEdited,
  NameHeader,
  BillingParty,
  BillingPartyAddress,
  CustomerService,
  //DateRange
  StartDate,
  EndDate,
  //revenue
  Quantity,
  ReceivedQuantity,
  UOM,
  UnitPrice,
  Currency,
  Amount,
  BillingCurrency,
  ExRate,
  Tax,
  SalesTaxAmt,
  SalesInvoice,
  BillAmount,
  AdjBillAmount,
  //cost
  Supplier,
  EstUnitCost,
  EstCurrency,
  EstExRate,
  EstTaxType,
  EstTax,
  EstAmt,
  EstAmtTax,
  CostVariance,
  IsBillable,
  BillingStatus,
  BillingOptions,
  // sr request
  SrNo,
  SrStatus,
  ServiceStatus,
  // operating income
  OperatingIncome,
  // eye_share
  EsRefNo,
  EsActualCost,
  // credit_note
  CreditNote,
  CreditAmt,
  //CashVoucher
  IsCashVoucherNeeded,
  VoucherNo,
  // remarks
  InternalRemarks,
  ExternalRemarks,
  BillingRemarks,
  BackToBack,
  SecondaryGroup
} from 'Containers/JobGrid/Jobs.styles'
import { Color } from 'Utils/Color';
import { JOB_COLUMN } from 'Constants/ColumnFilterConstants';

type Props = {
  disableCost: boolean;
  disableServiceRequest: boolean;
  disableOperatingIncome: boolean;
  disableRevenue: boolean;
  disableBilling: boolean;
  onExpandAll?: () => void;
  isViewMode?: boolean;
  jobDetails: any;
  onSelectAll?: () => void;
  isAllSelected?: boolean;
  onSelectServices?: any;
  columnPreference?: any
  showOperatingIncome?: any
  showServReq?: any
  showBilling?: any
  isReadOnly?: boolean,
  showCostQuantity?: boolean
};


const Header = (props: Props) => {
  const {
    disableCost,
    disableServiceRequest,
    disableOperatingIncome,
    jobDetails,
    disableRevenue,
    isViewMode,
    onSelectAll,
    isAllSelected,
    disableBilling,
    columnPreference,
    showOperatingIncome,
    showServReq,
    showBilling,
    isReadOnly,
    showCostQuantity
  } = props;

  const styleClass = "text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50";
  const services = useSelector((state: any) => state?.JobServices?.services);
  const isExternallyEdited = services.every((el: any) => el?.service?.IsExternallyEdited === null);

  const onClickCheckbox = useCallback(() => {
    onSelectAll && onSelectAll()
  }, []);

  const CostQtyColumns = () => {

    if (!showCostQuantity) {
      return <></>
    }

    return (
      <>
        {columnPreference['COST_UOM']?.isActive && (
          <UOM isHeader>{JOB_COLUMN.COST_UOM.label}</UOM>
        )}
        {columnPreference['COST_QTY']?.isActive && (
          <Quantity isHeader>{JOB_COLUMN.COST_QTY.label}</Quantity>
        )}
      </>
    );

  };

  return (
    <HeaderView className={styleClass}>
      <Overview>
        <Group isSticky showBorder>
          <GroupName />
          <ID isHeader={true} >
            {!isViewMode &&
              <input
                type="checkbox"
                disabled={isReadOnly}
                onChange={onClickCheckbox}
                checked={isAllSelected}
                style={!isReadOnly ? { cursor: 'pointer', marginRight: '8px' } : { cursor: 'not-allowed', opacity: 0.5 }} />}
            {JOB_COLUMN.ID.label}
          </ID>
        </Group>
        {!isViewMode &&
          <Group showBorder={true}>
            <GroupName />
            <Delete isHeader={true} />
          </Group>}

        {!isViewMode &&
          <Group showBorder={true}>
            <GroupName />
            <Add isHeader={true} />
          </Group>}
        {!isViewMode &&
          <Group showBorder={true}>
            <GroupName />
            <Expand isHeader={true} />
          </Group>}
        {!isViewMode &&
          <Group showBorder={true}>
            <GroupName />
            <Detail isHeader={true} />
          </Group>}
        {!isViewMode && !isExternallyEdited &&
          <Group showBorder={true}>
            <GroupName />
            <ExternallyEdited isHeader={true} />
          </Group>}
        <SecondaryGroup isSticky showBorder>
          <GroupName />
          <NameHeader isHeader={true} >
            {JOB_COLUMN.NAME.label}
          </NameHeader>
        </SecondaryGroup>
        {!isViewMode &&
          <Group>
            <GroupName />
            {columnPreference['CUSTOMER_SERVICE']?.isActive && <CustomerService isHeader={true}>
              {JOB_COLUMN.CUSTOMER_SERVICE.label}
            </CustomerService>}
          </Group>
        }
        {jobDetails.ProductCode === 'BP0004' &&
          <Group>
            <GroupName />
            {columnPreference['START_DATE']?.isActive && <StartDate isHeader={true} >
              {JOB_COLUMN.START_DATE.label}
            </StartDate>}
          </Group>}
        {jobDetails.ProductCode === 'BP0004' &&
          <Group>
            <GroupName />
            {columnPreference['END_DATE']?.isActive && <EndDate isHeader={true} >
              {JOB_COLUMN.END_DATE.label}
            </EndDate>}
          </Group>}
        {!disableOperatingIncome &&
          <Group groupColor={Color.operatingIncomeHighlight}>
            {showOperatingIncome && <GroupName groupColor={Color.operatingIncomeHighlight}>
              OP. INCOME
            </GroupName>}
            {
              columnPreference['OPERATING_INCOME']?.isActive &&
              <Flex>
                <OperatingIncome isHeader={true}>
                  {JOB_COLUMN.OPERATING_INCOME.label}
                </OperatingIncome>
              </Flex>
            }
          </Group >
        }
        {
          !disableRevenue &&
          <Group groupColor={Color.revenueHighlight}>
            <GroupName groupColor={Color.revenueHighlight}>Revenue</GroupName>
            <Flex>
              {!isViewMode && columnPreference['BILLING_PARTY']?.isActive &&
                <BillingParty isHeader={true} >
                  {JOB_COLUMN.BILLING_PARTY.label}
                </BillingParty>
              }
              {!isViewMode && columnPreference['BILLING_PARTY_ADDRESS']?.isActive &&
                <BillingPartyAddress isHeader={true} >
                  {JOB_COLUMN.BILLING_PARTY_ADDRESS.label}
                </BillingPartyAddress>
              }
              {columnPreference['UOM']?.isActive && <UOM isHeader={true} >
                {JOB_COLUMN.UOM.label}
              </UOM>}
              {columnPreference['QUANTITY']?.isActive && <Quantity isHeader={true} >
                {JOB_COLUMN.QUANTITY.label}
              </Quantity>}
              {columnPreference['UNIT_PRICE']?.isActive &&
                <UnitPrice isHeader={true} >
                  {JOB_COLUMN.UNIT_PRICE.label}
                </UnitPrice>}
              {columnPreference['CURRENCY']?.isActive &&
                <Currency isHeader={true} >
                  {JOB_COLUMN.CURRENCY.label}
                </Currency>}
              {columnPreference['AMOUNT']?.isActive &&
                <Amount isHeader={true} >
                  {JOB_COLUMN.AMOUNT.label}
                </Amount>}
              {columnPreference['BILLING_CURRENCY']?.isActive &&
                <BillingCurrency isHeader={true} >
                  {JOB_COLUMN.BILLING_CURRENCY.label}
                </BillingCurrency>}
              {columnPreference['EX_RATE']?.isActive &&
                <ExRate isHeader={true} >
                  {JOB_COLUMN.EX_RATE.label}
                </ExRate>}
              {columnPreference['TAX_RATE']?.isActive &&
                <Tax isHeader={true} >
                  {JOB_COLUMN.TAX_RATE.label}
                </Tax>}
              {columnPreference['SALES_TAX_AMT']?.isActive &&
                <SalesTaxAmt isHeader={true} >
                  {JOB_COLUMN.SALES_TAX_AMT.label}
                </SalesTaxAmt>}
              {columnPreference['BILL_AMT']?.isActive &&
                <BillAmount isHeader={true} >
                  {JOB_COLUMN.BILL_AMT.label}
                </BillAmount>}
              {columnPreference['ADJ_BILL_AMT']?.isActive &&
                <AdjBillAmount isHeader={true} >
                  {JOB_COLUMN.ADJ_BILL_AMT.label}
                </AdjBillAmount>}
            </Flex>
          </Group>
        }
        {
          !disableCost &&
          <Group groupColor={Color.costHighlight}>
            <GroupName groupColor={Color.costHighlight}>Cost</GroupName>
            <Flex>
              {columnPreference['SUPPLIER']?.isActive &&
                <Supplier isHeader={true} >
                  {JOB_COLUMN.SUPPLIER.label}
                </Supplier>}
              {!isViewMode && columnPreference['RECIEVED_QTY']?.isActive && <ReceivedQuantity isHeader={true} >
                {JOB_COLUMN.RECIEVED_QTY.label}
              </ReceivedQuantity>}
              <CostQtyColumns />
              {columnPreference['UNIT_COST']?.isActive &&
                <EstUnitCost isHeader={true} >
                  {JOB_COLUMN.UNIT_COST.label}
                </EstUnitCost>}
              {columnPreference['COST_CURRENCY']?.isActive &&
                <EstCurrency isHeader={true} >
                  {JOB_COLUMN.COST_CURRENCY.label}
                </EstCurrency>}
              {columnPreference['COST_EX_RATE']?.isActive &&
                <EstExRate isHeader={true} >
                  {JOB_COLUMN.COST_EX_RATE.label}
                </EstExRate>}
              {columnPreference['COST_TAX_RATE']?.isActive &&
                <EstTaxType isHeader={true} >
                  {JOB_COLUMN.COST_TAX_RATE.label}
                </EstTaxType>}
              {columnPreference['PURCHASE_TAX']?.isActive &&
                <EstTax isHeader={true} >
                  {JOB_COLUMN.PURCHASE_TAX.label}
                </EstTax>}
              {columnPreference['AMT']?.isActive &&
                <EstAmt isHeader={true} >
                  {JOB_COLUMN.AMT.label}
                </EstAmt>}
              {columnPreference['AMT_WITH_TAX']?.isActive &&
                <EstAmtTax isHeader={true} >
                  {JOB_COLUMN.AMT_WITH_TAX.label}
                </EstAmtTax>}
              {!isViewMode && columnPreference['SUPPLIER_INVOICE']?.isActive &&
                <EsRefNo isHeader={true} >
                  {JOB_COLUMN.SUPPLIER_INVOICE.label}
                </EsRefNo>}
              {!isViewMode && columnPreference['CREDIT_NOTE']?.isActive &&
                <CreditNote isHeader={true} >
                  {JOB_COLUMN.CREDIT_NOTE.label}
                </CreditNote>}
              {!isViewMode && columnPreference['CREDIT_AMT']?.isActive &&
                <CreditAmt isHeader={true} >
                  {JOB_COLUMN.CREDIT_AMT.label}
                </CreditAmt>}
              {columnPreference['ACTUAL_COST']?.isActive &&
                <EsActualCost isHeader={true} >
                  {JOB_COLUMN.ACTUAL_COST.label}
                </EsActualCost>}
              {columnPreference['ACTUAL_COST_EXCHANGE_RATE']?.isActive &&
                <EsActualCost isHeader={true} >
                  {JOB_COLUMN.ACTUAL_COST_EXCHANGE_RATE.label}
                </EsActualCost>}
              {!isViewMode && columnPreference['IS_VOUCHER_NEEDED']?.isActive &&
                <IsCashVoucherNeeded isHeader={true} >
                  {JOB_COLUMN.IS_VOUCHER_NEEDED.label}
                </IsCashVoucherNeeded>}
              {!isViewMode && columnPreference['VOUCHER_TYPE']?.isActive &&
                <IsCashVoucherNeeded isHeader={true} >
                  {JOB_COLUMN.VOUCHER_TYPE.label}
                </IsCashVoucherNeeded>}
              {!isViewMode && columnPreference['VOUCHER_NUMBER']?.isActive &&
                <VoucherNo isHeader={true} >
                  {JOB_COLUMN.VOUCHER_NUMBER.label}
                </VoucherNo>}
              {!isViewMode && columnPreference['BACK_TO_BACK']?.isActive &&
                <BackToBack isHeader={true}>
                  {JOB_COLUMN.BACK_TO_BACK.label}
                </BackToBack>}
            </Flex>
          </Group>
        }
        {
          !isViewMode && !disableServiceRequest &&
          <Group groupColor={Color.serviceRequestHighlight}>
            {showServReq && <GroupName groupColor={Color.serviceRequestHighlight}>Service Request</GroupName>}
            <Flex>
              {columnPreference['SR_NO']?.isActive &&
                <SrNo isHeader={true} >
                  {JOB_COLUMN.SR_NO.label}
                </SrNo>}
              {columnPreference['SR_STATUS']?.isActive &&
                <SrStatus isHeader={true} >
                  {JOB_COLUMN.SR_STATUS.label}
                </SrStatus>}
              {columnPreference['SERVICE_STATUS']?.isActive &&
                <ServiceStatus isHeader={true} >
                  {JOB_COLUMN.SERVICE_STATUS.label}
                </ServiceStatus>}
            </Flex>
          </Group>
        }
        {
          !isViewMode && !disableBilling &&
          <Group groupColor={Color.billingHighlight}>
            {showBilling && <GroupName groupColor={Color.billingHighlight}>{JOB_COLUMN.SERVICE_STATUS.label}</GroupName>}
            <Flex>
              {columnPreference['IS_BILLABLE']?.isActive &&
                <IsBillable isHeader={true} >
                  {JOB_COLUMN.IS_BILLABLE.label}
                </IsBillable>}
              {columnPreference['BILLING_STATUS']?.isActive &&
                <BillingStatus isHeader={true} >
                  {JOB_COLUMN.BILLING_STATUS.label}
                </BillingStatus>}
              {columnPreference['BILLING_METHOD']?.isActive &&
                <BillingOptions isHeader={true} >
                  {JOB_COLUMN.BILLING_METHOD.label}
                </BillingOptions>}
              {columnPreference['PAYMENT_TERM']?.isActive &&
                <BillingOptions isHeader={true} >
                  {JOB_COLUMN.PAYMENT_TERM.label}
                </BillingOptions>}
              {columnPreference['SALES_INVOICE']?.isActive &&
                <SalesInvoice isHeader={true}>
                  {JOB_COLUMN.SALES_INVOICE.label}
                </SalesInvoice>}
            </Flex>
          </Group>
        }
        {
          !isViewMode && columnPreference['INTERNAL_REMARKS']?.isActive &&
          <Group>
            <GroupName />
            <InternalRemarks isHeader={true} >
              {JOB_COLUMN.INTERNAL_REMARKS.label}
            </InternalRemarks>
          </Group >
        }
        {
          !isViewMode && columnPreference['EXTERNAL_REMARKS']?.isActive &&
          <Group>
            <GroupName />
            <ExternalRemarks isHeader={true} >
              {JOB_COLUMN.EXTERNAL_REMARKS.label}
            </ExternalRemarks>
          </Group>
        }
        {
          !isViewMode && columnPreference['BILLING_REMARKS']?.isActive &&
          <Group>
            <GroupName />
            <BillingRemarks isHeader={true} >
              {JOB_COLUMN.BILLING_REMARKS.label}
            </BillingRemarks>
          </Group>
        }

      </Overview >
    </HeaderView >
  )
};

export default Header;
