import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import SignInButton from "Containers/LoginPage/SignInButton";
import { Pages } from '../../App';


export default function Login() {
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  if (isAuthenticated) {
    return <Pages />
  } else
    if (inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect) {
      // inProgress check prevents sign-in button from being displayed briefly after returning from a redirect sign-in. Processing the server response takes a render cycle or two
      return <SignInButton />;
    } else {
      return null;
    }
}
