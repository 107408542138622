import { CargoDetail } from 'Model/Order/CargoDetail';
import {
  UPDATE_CARGO_DETAILS,
  ADD_CONTAINER_DETAILS,
  DELETE_BULK_DETAILS,
  ADD_BULK_DETAILS,
  DELETE_PACKAGE_DETAILS,
  ADD_PACKAGE_DETAILS,
  DELETE_CONTAINER_DETAILS,
  UPDATE_QUOTATION_CARGO_DETAILS,
} from 'Redux/ActionTypes/Cargo';
import { RESET_CARGO_STATE } from "Redux/ActionTypes/ActionTypes";
import {
  commodityTypeRequired,
  hsCodeRequired,
  isMandatory
} from 'Constants/Validations';

let initialState = {
  cargoDetails: new CargoDetail(),
  errorCount: 0,
  cargoInfoValidation: {
    CommodityType: { error: false, message: commodityTypeRequired },
    HSCode: { error: false, message: hsCodeRequired },
  },
  bulkDetailsValidation: {
    GrossWeight: { error: false, message: isMandatory },
    WeightUomName: { error: false, message: isMandatory },
    CargoTypeName: { error: false, message: isMandatory },
  },
  containerValidation: {
    Quantity: { error: false, message: isMandatory },
    GrossWeight: { error: false, message: isMandatory },
    ContainerTypeName: { error: false, message: isMandatory },
  },
  packageDetailsValidation: {
    Quantity: { error: false, message: isMandatory },
    GrossWeight: { error: false, message: isMandatory },
    WeightUomName: { error: false, message: isMandatory }
  }
}

export const cargo = (state = initialState, action: any) => {
  switch (action.type) {

    case UPDATE_CARGO_DETAILS:
      return {
        ...state,
        cargoDetails: {
          ...state.cargoDetails,
          ...action.cargoDetails
        }
      }

    case ADD_PACKAGE_DETAILS:
      return {
        ...state,
        cargoDetails: {
          ...state.cargoDetails,
          PackageDetail: [...action.packageArray]
        }
      }

    case DELETE_PACKAGE_DETAILS:
      return {
        ...state,
        cargoDetails: {
          ...state.cargoDetails,
          PackageDetail: [...action.packageArray]
        }
      }

    case ADD_CONTAINER_DETAILS:
      return {
        ...state,
        cargoDetails: {
          ...state.cargoDetails,
          ContainerDetail: [...action.containerArray]
        }
      }

    case DELETE_CONTAINER_DETAILS:
      return {
        ...state,
        cargoDetails: {
          ...state.cargoDetails,
          ContainerDetail: [...action.containerArray]
        }
      }

    case ADD_BULK_DETAILS:
      return {
        ...state,
        cargoDetails: {
          ...state.cargoDetails,
          BulkCargoDetail: [...action.bulkArray]
        }
      }

    case DELETE_BULK_DETAILS:
      return {
        ...state,
        cargoDetails: {
          ...state.cargoDetails,
          BulkCargoDetail: [...action.bulkArray]
        }
      }

    case RESET_CARGO_STATE:
      return {
        ...initialState
      };

    case UPDATE_QUOTATION_CARGO_DETAILS:
      return {
        ...state,
        cargoDetails: {
          ...state.cargoDetails,
          ...action.data
        }
      };

    default:
      return state;

  }
}