import styled from "styled-components";

interface IStylesProps {

}

export const Overview = styled.div<IStylesProps>`
    display: flex;
`;

export const ListOptions = styled.div<IStylesProps>`
    width: 20vw;
    margin: 12px 16px;
`;

export const MapOverview = styled.div<IStylesProps>`
  
`;

export const LoaderOverview = styled.div<IStylesProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const ListItem = styled.div<IStylesProps>`
  margin: 8px 0px;
`;

export const Label = styled.div<IStylesProps>`
  padding-bottom: 8px;
  font-weight: bold;
  font-size: 14px;
`;

export const Footer = styled.div<IStylesProps>`
    position: absolute;
    bottom: 0px;
    margin: 16px 0px;
    display: flex;
    align-items: center;
`;

export const RequiredFields = styled.div<IStylesProps>`
  color: red;
  font-size: 12px;
  margin-left: 12px;
`
export const LoaderView = styled.div<IStylesProps>`
  overflow: hidden;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`