import { QuotationAreaType } from '../../Constants/QuotationAreaType';

export interface IRoute {
  RouteId: number;
  Version: number;
  QuotationAreaType: string;
  PortCode: string;
  PortName: string;
  AgentName: string;
  PortLatLong: string;
  TerminalCode: string;
  TerminalName: string;
  BerthCode: string;
  BerthName: string;
  MoTCode: string;
  MoTName: string;
  OriginCode: string;
  OriginName: string;
  OriginLatLong: string;
  DestinationCode: string;
  DestinationName: string;
  DestinationLatLong: string;
  IsStorageRequired: boolean;
  ShipmentTypeCode: string;
  ShipmentTypeName: string;
  IntcotermCode: string;
  IntcotermName: string;
  CarrierCode: string;
  CarrierName: string;
  TruckTypeCode: string;
  TruckTypeName: string;
  CreatedAt: Date;
  CreatedBy: string;
  ModifiedAt: Date | null;
  ModifiedBy: string;
  IsActive: boolean;
}

export class Route implements IRoute {
  RouteId: number;

  Version: number;

  QuotationAreaType: string;

  PortCode: string;

  PortName: string;

  AgentName: string;

  PortLatLong: string;

  TerminalCode: string;

  TerminalName: string;

  BerthCode: string;

  BerthName: string;

  MoTCode: string;

  MoTName: string;

  OriginCode: string;

  OriginName: string;

  OriginLatLong: string;

  DestinationCode: string;

  DestinationName: string;

  DestinationLatLong: string;

  IsStorageRequired: boolean;

  ShipmentTypeCode: string;

  ShipmentTypeName: string;

  IntcotermCode: string;

  IntcotermName: string;

  CarrierCode: string;

  CarrierName: string;

  TruckTypeCode: string;

  TruckTypeName: string;

  CreatedAt: Date;

  CreatedBy: string;

  ModifiedAt: Date | null;

  ModifiedBy: string;

  IsActive: boolean;

  constructor() {
    this.RouteId = 0;
    this.Version = 0;
    this.QuotationAreaType = QuotationAreaType.Generic;
    this.PortCode = '';
    this.PortName = '';
    this.AgentName = '';
    this.PortLatLong = '';
    this.TerminalCode = '';
    this.TerminalName = '';
    this.BerthCode = '';
    this.BerthName = '';
    this.MoTCode = '';
    this.MoTName = '';
    this.OriginCode = '';
    this.OriginName = '';
    this.OriginLatLong = '';
    this.DestinationCode = '';
    this.DestinationName = '';
    this.DestinationLatLong = '';
    this.IsStorageRequired = false;
    this.ShipmentTypeCode = '';
    this.ShipmentTypeName = '';
    this.IntcotermCode = '';
    this.IntcotermName = '';
    this.CarrierCode = '';
    this.CarrierName = '';
    this.TruckTypeCode = '';
    this.TruckTypeName = '';
    this.CreatedAt = new Date();
    this.CreatedBy = '';
    this.ModifiedAt = new Date();
    this.ModifiedBy = '';
    this.IsActive = true;
  }
}
