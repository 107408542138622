import styled from 'styled-components';

interface IStyledProps {
  isSelected?: any;
  selectedTab?: any;
  isEnabled?: any;
  isCompleted?: any
}

export const Flex = styled.div<IStyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Content = styled.div<IStyledProps>`
  height: ${(props) => (props.selectedTab ? 'calc(100% - 120px)' : 'calc(100% - 60px)')};
  overflow: auto;
`;
export const TabView = styled(Flex)`
  color: ${(props) => (props.isSelected ? '#0095FF' : '#000')};
  border-bottom: ${(props) => (props.isCompleted ? '2px solid green' : props.isSelected ? '2px solid #0095FF' : '2px solid red')};
  height: 60px; 
  flex: 1; 
`;
export const Overview = styled(Flex)`
  height: calc(100vh - 65px);
`;
export const Container = styled.div`
  background-color: #FFFFFF;
  width: 85vw;
  max-width: 85vw;
  height: 75vh;
  max-height: 75vh;
  border-radius: 6px;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
`;
export const HeaderOverview = styled(Flex)`
  height: 60px;
`;

export const FooterOverview = styled(Flex)`
  border-top: 1px solid #D8D8D8;
  height: 60px;
  padding-right: 24px;
`;
export const Left = styled.div`
  padding: 0px 24px;
`;
export const Right = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const TabOption = styled.div<IStyledProps>`
  // cursor: ${(props) => (props.isEnabled ? 'pointer' : 'not-allowed')};
  padding: 4px 8px;
  opacity: ${(props) => (props.isEnabled ? 1 : 0.2)};
  text-align: center
`
export const Title = styled.div<IStyledProps>`
  color: #000;
  font-size : 12px;
`
export const AlertMessage = styled.div<IStyledProps>`
  color: red;
  font-size : 12px;
  padding-right: 12px;
`
export const QuotationAlert = styled.div<IStyledProps>`
  text-align: center;
  padding: 12px;
  font-size: 18px;
  font-weight: 400;
`