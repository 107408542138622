import {
  UPDATE_CARGO_DETAILS,
  ADD_CONTAINER_DETAILS,
  DELETE_CONTAINER_DETAILS,
  ADD_PACKAGE_DETAILS,
  DELETE_PACKAGE_DETAILS,
  ADD_BULK_DETAILS,
  DELETE_BULK_DETAILS,
  UPDATE_QUOTATION_CARGO_DETAILS,
} from "Redux/ActionTypes/Cargo";
import { CargoDetail } from 'Model/Order/CargoDetail';
import { RESET_CARGO_STATE } from "Redux/ActionTypes/ActionTypes";

export function updateCargoDetails(cargoDetails: CargoDetail) {
  return {
    type: UPDATE_CARGO_DETAILS,
    cargoDetails
  };
}

export function addPackageSection(packageArray: any) {
  return {
    type: ADD_PACKAGE_DETAILS,
    packageArray
  };
}

export function deletePackageSection(packageArray: any) {
  return {
    type: DELETE_PACKAGE_DETAILS,
    packageArray
  };
}

export function addContainerSection(containerArray: any) {
  return {
    type: ADD_CONTAINER_DETAILS,
    containerArray
  };
}

export function deleteContainerSection(containerArray: any) {
  return {
    type: DELETE_CONTAINER_DETAILS,
    containerArray
  };
}

export function addBulkSection(bulkArray: any) {
  return {
    type: ADD_BULK_DETAILS,
    bulkArray
  };
}

export function deleteBulkSection(bulkArray: any) {
  return {
    type: DELETE_BULK_DETAILS,
    bulkArray
  };
}

export function resetCargoState() {
  return {
    type: RESET_CARGO_STATE
  };
}
export function updateQuotationCargoDetails(data: object) {
  return {
    type: UPDATE_QUOTATION_CARGO_DETAILS,
    data
  };
}
