import { useState, useEffect } from 'react';
import QuotationAgent from '../../Api/QuotationAgent';
import PegButton from '../../Controls/Button/PegButton';
import PegModal from '../../Controls/PegModal';
import { toast } from 'react-toastify';
import ConvertToJob from '../ConvertToJob/index';

const ApprovalProcess = () => {
  const [openModalApprove, setOpenModalApprove] = useState<boolean>(false);
  const [openModalReject, setOpenModalReject] = useState<boolean>(false);
  const [openModalCancel, setOpenModalCancel] = useState<boolean>(false);
  const [openModalConvertToJob, setOpenModalConvertToJob] = useState<boolean>(false);
  const [details, setDetails] = useState<any>({});
  const [weight, setWeight] = useState<any>('')

  //why set timeout ?
  const fetchDetails = () => {
    QuotationAgent.convertToJob(5).then((data: any) => {
      setTimeout(() => {
        setDetails(data);
      }, 10)
    })
  }

  const getWeight = () => {
    const reducer = (previousValue: number, currentValue: number) => previousValue + currentValue;
    const sumWeight = details && details.groupJob && details.groupJob.cargo.packages.map((el: any) => el.Weight)
    const calculatedWeight = sumWeight && sumWeight.reduce(reducer)
    setWeight(calculatedWeight);
  }

  useEffect(() => {
    fetchDetails();
  }, [])

  const reject = () => {
    QuotationAgent.reject(4, 'hello', 'THN%2301').then((data: any) => {
      // move toaster to component and pass props if needed
      toast.error(data.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        style: { width: '400px' },
      })
    })
    setOpenModalReject(false);
  }

  const cancel = () => {
    QuotationAgent.cancel(4, 'hello', 'THN%2301').then((data: any) => {
      toast.error(data.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        style: { width: '400px' },
      })
    })
    setOpenModalCancel(false);
  }

  const approveQuote = () => {
    QuotationAgent.approve(4, 1, 'hello', 'THN%2301').then((data: any) => {
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        style: { width: '400px' },
      })
    })
    setOpenModalApprove(false);
  }

  const createJob = () => {
    if (details) {
      QuotationAgent.createJob(details)
        .then((data: any) => {
          toast.success('Job Created Successfully !', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
            style: { width: '400px' },
          })
        })
    }
    setOpenModalConvertToJob(false)
  }

  const handleReject = () => {
    setOpenModalReject(true);
  }

  const handleCancel = () => {
    setOpenModalCancel(true);
  }

  const handleApproveQuote = () => {
    setOpenModalApprove(true);
  }

  // use camelcase
  const handleConverttoJob = () => {
    getWeight();
    setOpenModalConvertToJob(true)
    // history.push("/quotation/convertToJob");
  }

  const handleClose = () => {
    if (openModalReject === true) {
      setOpenModalReject(false)
      // use openModalApprove instead of openModalApprove === true
    } else if (openModalApprove === true) {
      setOpenModalApprove(false)
    } else if (openModalCancel === true) {
      setOpenModalCancel(false)
    } else if (openModalConvertToJob === true) {
      setOpenModalConvertToJob(false)
    }
  }

  return (
    <div className="py-3 bg-light flex flex-row justify-end space-x-4">
      <PegButton
        id="btnSave"
        type="button"
        variant="Danger"
        onClick={() => handleReject()}
      >
        Reject
      </PegButton>
      <PegButton
        id="btnBack"
        type="button"
        variant="LinkPrimary"
        styleObject={{ border: '1px solid indigo' }}
        onClick={() => handleCancel()}
      >
        Cancel
      </PegButton>
      <PegButton
        id="btnNext"
        type="button"
        variant="Success"
        onClick={() => handleApproveQuote()}
      >
        Approve Quote
      </PegButton>

      <PegButton
        id="btnNext"
        type="button"
        variant="Primary"
        onClick={() => handleConverttoJob()}
      >
        Convert to Job
      </PegButton>

      <PegModal
        isOpen={openModalReject}
        tertiaryMsg={'No'}
        showTemplate={true}
        buttonText={'Yes'}
        tertiaryAction={reject}
        closeModal={handleClose}
        isCenter={true}
        modalTitle={'Reject'}
      >
        <div className="p-5 text-center">
          Do you want to Reject ?
        </div>
      </PegModal>

      <PegModal
        isOpen={openModalCancel}
        tertiaryMsg={'No'}
        showTemplate={true}
        buttonText={'Yes'}
        tertiaryAction={cancel}
        closeModal={handleClose}
        isCenter={true}
        modalTitle={'Cancel'}>
        <div className="p-5 text-center">
          Do you want to Cancel ?
        </div>
      </PegModal>

      <PegModal
        isOpen={openModalApprove}
        tertiaryMsg={'No'}
        showTemplate={true}
        buttonText={'Yes'}
        tertiaryAction={approveQuote}
        closeModal={handleClose}
        isCenter={true}
        modalTitle={'Approve'}>
        <div className="p-5 text-center">
          Do you want to Approve ?
        </div>
      </PegModal>

      <PegModal
        isOpen={openModalConvertToJob}
        tertiaryMsg={'No'}
        showTemplate={true}
        buttonText={'Yes'}
        modalTitle={'Convert Quote to Job'}
        tertiaryAction={createJob}
        closeModal={handleClose}
        isCenter={true}>
        <div>
          <ConvertToJob details={details} weight={weight} />
        </div>
      </PegModal>
    </div>
  )
}

export default ApprovalProcess;
