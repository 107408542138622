import PegButton from 'Controls/Button/PegButton';
import { useSelector } from "react-redux";
import {
  GridOptions,
  MarginLeft
} from '../Jobs.styles';
import { validationMessageJob } from 'Constants/Constant'
import ToolTip from 'Controls/ToolTip/ToolTip';
import { ErrorMessage } from 'Containers/QuoteGrid/quote.styles';
type Props = {
  onAddService: () => void;
  onDeleteOption: () => void;
  onEditOption: () => void;
  selectedList: any[];
  onSave: () => void;
  services: any;
  hasMissingFields: boolean;
  isModified: boolean;
  onUndoChange: () => void;
  onApplyTariff: () => void;
  isServiceAdding: boolean;
  missingValidation: any[];
  isAnyServiceWithSupplierInvoiceOrCR?: boolean;
};
const FooterOptions = (props: Props) => {
  const isSaveDisabled = useSelector((state: any) => state.JobServices.isSaveDisabled);
  const {
    selectedList,
    hasMissingFields,
    isModified,
    onUndoChange,
    isServiceAdding,
    missingValidation,
    services,
    isAnyServiceWithSupplierInvoiceOrCR
  } = props

  return (
    <GridOptions>
      <PegButton
        id="servicegrid_addrow"
        type="button"
        variant="Primary"
        data-testid="addServices"
        disabled={isServiceAdding}
        onClick={props.onAddService}>
        Add Services
      </PegButton>
      <ToolTip
        content={!Boolean(selectedList.length) ? 'No lines selected' : (isAnyServiceWithSupplierInvoiceOrCR ? 'Some of the selected services already have supplier invoice or credit note' : '')}
        id="bulkDeleteToolTip"
        disabled={!(!Boolean(selectedList.length) || isAnyServiceWithSupplierInvoiceOrCR)}
        placement={'top'}
        animation={'scale-subtle'}
        arrow={true}
        duration={200}
        delay={[75, 0]}>
        <PegButton
          id="servicegrid_addrow"
          type="button"
          variant="Danger"
          data-testid="deleteServices"
          disabled={!Boolean(selectedList.length) || isAnyServiceWithSupplierInvoiceOrCR}
          styleObject={{ marginLeft: '8px' }}
          onClick={props.onDeleteOption}>
          Delete
        </PegButton>
      </ToolTip>
      <PegButton
        id="servicegrid_addrow"
        type="button"
        variant="Primary"
        data-testid="bulkEdit"
        styleObject={{ marginLeft: '8px' }}
        disabled={!Boolean(selectedList.length)}
        onClick={props.onEditOption}>
        Bulk Edit
      </PegButton>
      <PegButton
        id="servicegrid_addrow"
        type="button"
        variant="Primary"
        data-testid="bulkEdit"
        styleObject={{ marginLeft: '8px' }}
        onClick={props.onApplyTariff}
        disabled={!Boolean(selectedList.length)}>
        Apply Tariff
      </PegButton>
      <MarginLeft>
        {hasMissingFields && <ErrorMessage>Please fill the required field(s)</ErrorMessage>}
        {isModified &&
          <PegButton
            id="servicegrid_addrow"
            type="button"
            variant="Primary"
            data-testid="undoChanges"
            onClick={onUndoChange}>
            Undo Changes
          </PegButton>}
        <PegButton
          id="servicegrid_addrow"
          data-testid="saveJobGrid"
          type="button"
          variant="Primary"
          styleObject={{ marginLeft: 12 }}
          disabled={isSaveDisabled}
          onClick={props.onSave}>
          Save
        </PegButton>
      </MarginLeft>
    </GridOptions >
  )
};

export default FooterOptions;
