
import {
  useCallback,
  useState,
  useEffect,
} from 'react';
import { AiFillEdit } from "react-icons/ai";
import Dropdown from 'Controls/Dropdown';
import DatePicker from 'Controls/DateRange/PegDatePicker';
import InputBox from 'Controls/Input/PegInputBox';
import {
  setServiceValue,
  setEditServiceRequestDetails,
  getStatus
} from 'Utils/JobGrid';
import {
  getExchangeRates, showToast
} from 'Utils/Generic';
import {
  SERVICE_STATUS_OPTIONS,
  BILLING_STATUS_OPTIONS,
  BILLING_METHOD_OPTIONS
} from 'Constants/DropdownOptions';
import { BILLING_STATUS_OPTIONS_LIST, VOUCHER_STATUS_VALUES } from 'Constants/Constant';
import {
  Row,
  Columns,
  Title,
  Value,
  ExRate,
  EstExRate,
  SupplierInvoiceDetail,
  CreditNoteRef,
  VoucherNo,
  SrNo,
  SrStatus,
  IsBillable,
  BillingStatus,
  Name,
  Supplier,
  StartDate,
  EndDate,
  Currency,
  BillingCurrency,
  EstCurrency,
  IsCashVoucherNeeded,
  BackToBack,
  ServiceStatus,
  BillingOptions,
  PaymentTerms
} from './details.styles';
import { useSelector } from 'react-redux';
import { Charge, ICharge } from 'Model/Job/JobCharge';
import { ISalesInvoice, IServicesAndCharges, ISupplierDetails, IVoucher } from 'Model/Job/types';
import { Service } from 'Model/Job/JobService';
import { useDispatch } from 'react-redux';
import { JOB_COLUMN } from 'Constants/ColumnFilterConstants';

type Props = {
  id?: any;
  service?: any;
  services?: any;
  serviceRequestDetails?: any;
  showCharges?: boolean;
  serviceIndex: number;
  jobDetails?: any;
  serviceList: any[];
  uomList: any[];
  voucherList: any;
  currencyList: any[];
  billingParties: any[];
  taxList: any[];
  exchangeRates: any[];
  setPartyValidModal: any;
  url?: string;
  isLookup?: boolean;
  charge?: any;
  isAllSelected?: any;
  isSelected?: any;
  srNoDropdown: any[];
  supplierDetails: any;
  disableCost?: boolean;
  disableServiceRequest?: boolean;
  disableOperatingIncome?: boolean;
  disableRevenue?: boolean;
  disableBilling?: boolean;
  salesInvoice: any;
  onSelectAll?: any;
  serviceObject: any;
  userDetails: any;
  isValidParty: boolean;
  setValidParty: any;
  columnPreference: any;
  paymentTerms: any;
  setServiceObj?: any;
  serviceObj?: any;
  isSupplierInvoiceAvailable?: boolean,
  isSupplierCreditNoteAvailable?: boolean,
  onUpdateServiceDetails: (serviceDetailsObj: any, serviceIndex: any) => void;
  onUpdateService: (service: any, serviceIndex: any) => void;
  onSelectOptions?: (service: any, charge: any) => void;
  onExpandService?: (service: any) => void;
  onAddCharge?: (service: any) => void;
  onChangeInput: (type: string, id: any, value: any, serviceIndex: any, chargeIndex: any) => void;
  onChangeDate: (type: string, event: any, id: any, serviceIndex: any, chargeIndex: any) => void;
  onUpdateCheckbox: (type: string, event: any, value: any) => void;
  onChangeDropdown: (type: string, elemId: any, value: any, serviceIndex: number, chargeIndex: any) => void;
  onDeleteService?: (service: any) => void;
};

const ServiceLine = (props: Props) => {
  const dispatch = useDispatch();
  const userDetail = useSelector((state: any) => state.user.userDetail);
  const {
    service,
    serviceRequestDetails,
    jobDetails,
    serviceList,
    currencyList,
    voucherList,
    onChangeDropdown,
    serviceIndex,
    disableCost,
    disableServiceRequest,
    disableOperatingIncome,
    disableRevenue,
    supplierDetails,
    onChangeInput,
    onChangeDate,
    onUpdateService,
    setServiceObj,
    serviceObj,
    onUpdateCheckbox,
    serviceObject,
    srNoDropdown,
    exchangeRates,
    disableBilling,
    setValidParty,
    userDetails,
    services,
    columnPreference,
    paymentTerms,
    isSupplierInvoiceAvailable,
    isSupplierCreditNoteAvailable,
    onUpdateServiceDetails
  } = props;

  const inputStyle = {
    height: '34px',
    borderRadius: '0px',
    fontSize: '12px',
    border: 'none',
  }

  const {
    CurrencyDecimalPlaces,
    CurrencyDecimalPlacesAutoCalculation
  } = userDetails
  let decimalPlace = CurrencyDecimalPlaces ? parseInt(CurrencyDecimalPlaces) : 2;
  let autoDecimal = CurrencyDecimalPlacesAutoCalculation ? parseInt(CurrencyDecimalPlacesAutoCalculation) : 2;

  const styleClass = "text-left text-xs font-medium text-gray-500 tracking-wider bg-gray-50";
  const isDisabled = BILLING_STATUS_OPTIONS_LIST.includes(service.BillingStatus);
  const [showModal, setShowModal] = useState(false);

  const disableSupplier = service.IsFromMaster || !service?.Name || isDisabled || isSupplierInvoiceAvailable || isSupplierCreditNoteAvailable || serviceRequestDetails?.SRStatus == "Accepted" || serviceRequestDetails?.SRStatus == "Completed"

  useEffect(() => {
    // const asyncFn = async () => {
    if (!voucherList?.some((e: any) => e.VoucherNumber === service.VoucherNumber)) {
      return voucherList.push({
        "VoucherId": service.VoucherFk,
        "VoucherNumber": service.VoucherNumber,
        "Status": service.VoucherStatus
      })
    }
    // }
    // asyncFn()
  }, [voucherList])

  useEffect(() => {
    // const asyncFn = async () => {
    if (!service.BillingCurrencyCode) {
      let obj = setServiceValue(service, jobDetails, 'defaultBillingCurrency', jobDetails.CurrencyCode)
      let serviceValue = {
        ...serviceObj,
        service: obj
      }
      setServiceObj(serviceValue)
    }
    if (!service.PriceCurrencyCode) {
      let obj = setServiceValue(service, jobDetails, 'defaultPriceCurrency', jobDetails.CurrencyCode)
      let serviceValue = {
        ...serviceObj,
        service: obj
      }
      setServiceObj(serviceValue)
    }
    if (!service.CostCurrencyCode) {
      let obj = setServiceValue(service, jobDetails, 'defaultCostCurrency', jobDetails.CurrencyCode)
      let serviceValue = {
        ...serviceObj,
        service: obj
      }
      setServiceObj(serviceValue)
    }
    if (!serviceRequestDetails.StartDateTime) {
      let serviceDetailsObj = setEditServiceRequestDetails(serviceObject, 'defaultStartDate', jobDetails.ETA)
      let serviceRequestDetailsValue = {
        ...serviceObj,
        serviceRequestDetails: serviceDetailsObj
      }
      setServiceObj(serviceRequestDetailsValue)
    }
    if (!serviceRequestDetails.EndDateTime) {
      let serviceDetailsObj = setEditServiceRequestDetails(serviceObject, 'defaultEndDate', jobDetails.ETD)
      let serviceRequestDetailsValue = {
        ...serviceObj,
        serviceRequestDetails: serviceDetailsObj
      }
      setServiceObj(serviceRequestDetailsValue)
    }
    // }
    // asyncFn()
  }, [
    serviceIndex,
    service.BillingCurrencyCode,
    service.PriceCurrencyCode,
    service.CostCurrencyCode,
    serviceRequestDetails?.StartDateTime,
    serviceRequestDetails?.EndDateTime,
  ])

  useEffect(() => {
    // const asyncFn = async () => {
    if (srNoDropdown.length === 1) {
      let serviceDetailsObj = setEditServiceRequestDetails(serviceObject, 'serviceSRnumber', srNoDropdown[0])
      let serviceRequestDetailsValue = {
        ...serviceObj,
        serviceRequestDetails: serviceDetailsObj
      }
      setServiceObj(serviceRequestDetailsValue)
    }
    // }
    // asyncFn()
  }, [serviceIndex, srNoDropdown])

  useEffect(() => {
    // const asyncFn = async () => {
    if (!service?.BillingExchangeRate) {
      getRates();
    }
    // }
    // asyncFn()
  }, [])

  useEffect(() => {
    // const asyncFn = async () => {
    if (!service?.PriceExchangeRate) {
      getPriceConversion();
    }
    // }
    // asyncFn()
  }, [])

  useEffect(() => {
    const asyncFn = async () => {
      if (!service?.CostExchangeRate) {
        getCostConversion();
      }
    }
    asyncFn()
  }, [])

  useEffect(() => {
    if (!service?.billingMethod) {
      onChangeDropdown('service', 'billingMethod', 'Credit', serviceIndex, null)
    }
    if (!service?.paymentMethod) {
      let paymentTerm = jobDetails?.PaymentTermCode ? jobDetails?.PaymentTermCode :
        userDetails?.PaymentTermCode ? userDetails?.PaymentTermCode : '10';
      onChangeDropdown('service', 'paymentMethod', paymentTerm, serviceIndex, null)
    }
  }, [])

  const getCostConversion = useCallback(() => {
    let obj: any;
    let costExRate: any = getExchangeRates(exchangeRates, service?.CostCurrencyCode, jobDetails?.CurrencyCode)
    if (costExRate) {
      obj = setServiceValue(service, jobDetails, 'CostExchangeRate', costExRate.ExchangeRate);
    } else {
      obj = setServiceValue(service, jobDetails, 'CostExchangeRate', 0);
    }
    let serviceValue = {
      ...serviceObj,
      service: obj
    }
    setServiceObj(serviceValue)
  }, [service]);

  const getRates = useCallback(() => {
    let fromCurrency = service?.BillingCurrencyCode;
    let targetCurrency = service?.PriceCurrencyCode;
    let obj: any;
    if (jobDetails.CurrencyCode !== targetCurrency) {
      fromCurrency = service?.PriceCurrencyCode
      targetCurrency = service?.BillingCurrencyCode
    }
    let billExRate: any = getExchangeRates(exchangeRates, fromCurrency, targetCurrency)
    if (billExRate) {
      obj = setServiceValue(service, jobDetails, 'BillingExchangeRate', billExRate.ExchangeRate);
    } else {
      obj = setServiceValue(service, jobDetails, 'BillingExchangeRate', 0);
    }
    let serviceValue = {
      ...serviceObj,
      service: obj
    }
    setServiceObj(serviceValue)
  }, [service]);

  const getPriceConversion = useCallback(() => {
    let obj: any;
    let priceExRate: any = getExchangeRates(exchangeRates, service?.PriceCurrencyCode, jobDetails?.CurrencyCode)
    if (priceExRate) {
      obj = setServiceValue(service, jobDetails, 'PriceExchangeRate', priceExRate.ExchangeRate);
    } else {
      obj = setServiceValue(service, jobDetails, 'PriceExchangeRate', 0);
    }
    let serviceValue = {
      ...serviceObj,
      service: obj
    }
    setServiceObj(serviceValue)
  }, [service]);

  const onChangeDropdownValue = (e: any, id: string) => {
    let { billingParties, setPartyValidModal } = props;
    let serviceRow = services[serviceIndex]
    if (id === 'billingStatus') {
      if (e.name === 'Send for Billing') {
        setPartyValidModal(true);
        getStatus(jobDetails, null, serviceRow, billingParties, '', null, false).then((res: any) => {
          setPartyValidModal(false);
          setValidParty(res?.IsValidParty);
          if (res?.IsValidParty) {
            setPartyValidModal(false);
            showToast('Party is valid, you can proceed for "Send to Billing"', 'success', 7000, 'top-center', 'dark');;
            onChangeDropdown('service', id, e, serviceIndex, null)
          } else {
            res?.Message.map((el: any) => {
              showToast(`${el}, cannot be "Send to Billing" !`, 'error', 7000, 'top-center', 'dark');
            })
            onChangeDropdown('service', id, '', serviceIndex, null)
          }
        })
      }
    } else {
      let event = (id === 'paymentMethod') ? e?.code :
        (id === 'billingMethod') ? e?.name : e
      onChangeDropdown('service', id, event, serviceIndex, null)
      if (id === 'estimatedCurrency') {
        getCostConversion()
      }
      if (id === 'actualCurrency') {
        getPriceConversion();
        getRates();
      }
      if (id === 'billingCurrency') {
        getRates();
      }
      if (id === 'supplier') {
        setValidParty(true)
        // let serviceDetailsObj = setEditServiceRequestDetails(service, 'isShowSRlink', false)
        // onUpdateServiceDetails(serviceDetailsObj, serviceIndex)
        // let serviceRequestDetailsValue = {
        //   ...serviceObj,
        //   serviceRequestDetails: serviceDetailsObj
        // }
        // setServiceObj(serviceRequestDetailsValue)
      }
      if (id === 'VoucherNumber') {
        let obj = setServiceValue(service, jobDetails, 'VoucherNumber', e);
        let serviceValue = {
          ...serviceObj,
          service: obj
        }
        setServiceObj(serviceValue)
      }
      if (id === 'serviceName' && service.Id == 0) {
        const filteredServices = services?.filter((service: IServicesAndCharges) => service.service.IsActive);
        const maxActiveServiceCount = filteredServices?.length ?? 0;

        let jobServiceObj: IServicesAndCharges = {
          charges: [{ ...new Charge(), LineNumber: '1' }],
          service: {
            ...new Service(),
            LineNumber: (maxActiveServiceCount + 1).toString(),
            RowOrder: services.length + 1,
            Name: e.name,
            Code: e.code,
            Id: 0,
            OperationalProcessFk: e.opTags?.operationalProcessId,
            svsCategoryCodes: e.svsCategories,
            CostExchangeRate: e.costExchangeRate,
            PriceExchangeRate: e.priceExchangeRate,
            BillingExchangeRate: e.billingExchangeRate,
            PriceCurrencyCode: e.priceCurrencyCode,
            CostCurrencyCode: e.costCurrencyCode,
            BillingCurrencyCode: e.billingCurrencyCode,
          },
          serviceActivity: [],
          serviceRequestDetails: {
            CompletedDateTime: null,
            EndDateTime: null,
            Id: null,
            Rating: null,
            SRNumber: null,
            SRStatus: null,
            ServiceFk: null,
            ServiceStatus: 'Open',
            StartDateTime: null,
            isShowSRLink: null,
          },
          supplierDetails: {} as ISupplierDetails,
          salesInvoice: [] as ISalesInvoice[],
        };

        dispatch(onUpdateServiceDetails(jobServiceObj, serviceIndex));
      }
    }

  }

  const onClickCheckbox = (e: any) => {
    if (!isDisabled) {
      onUpdateCheckbox('service', e, null)
    }
  }

  const editVoucherStatus = useCallback(() => {
    let serviceObj = setServiceValue(service, jobDetails, 'isShowVoucherLink', false)
    onUpdateService(serviceObj, serviceIndex)
  }, [serviceIndex]);

  const onChangeDateValue = useCallback((e: any, id: any) => {
    onChangeDate('service', id, e, serviceIndex, null)
  }, [serviceIndex]);

  const onChangeInputValue = (event: any) => {
    if (event?.target?.type === 'number') {
      event.target.value = (event.target.value.indexOf(".") >= 0) ? event.target.value.substr(0, event.target.value.indexOf(".")) + event.target.value.substr(event.target.value.indexOf("."), decimalPlace + 1) : event.target.value;
    }
    onChangeInput('service', event.target?.id, event, serviceIndex, null)
  }

  const hasVoucherStatus = VOUCHER_STATUS_VALUES.includes(service.VoucherStatus) || !service.VoucherStatus;
  const filteredVoucherList = voucherList && voucherList.filter((x: any) => x.Status != 'Deleted' && x.Status != 'Approved');

  return (
    <div>
      <Row>
        <Columns>
          <Title>
            {JOB_COLUMN.NAME.label}
          </Title>
          <Value>
            <Name isService isDropdown data-testid="serviceName">
              <Dropdown
                defaultValue={service.Name}
                onChangeDropdown={onChangeDropdownValue}
                label={'name'}
                dropdownOptions={serviceList}
                dropdownLabel={'name'}
                dropdownValue={'name'}
                style={{ width: 200 }}
                placeHolder="Select service"
                dropdownMap={'services'}
                isMenuPortalTarget={'true'}
                domId={'stickyPosition'}
                id="serviceName"
                dataTestId="serviceName"
                isMandatory={true}
                removeIsClear={true}
                disabled={service.Name && service.Id !== 0 ? true : isDisabled}
              />
            </Name>
          </Value>
        </Columns>
        <Columns>
          <Title>
            {JOB_COLUMN.SUPPLIER.label}
          </Title>
          <Value>
            <Supplier isService isDropdown data-testid="supplier">
              <Dropdown
                defaultValue={service.SupplierName}
                id={'supplier'}
                type={'charge'}
                isLookup={true}
                dropdownLabel={'name'}
                dropdownValue={'name'}
                appendParams={false}
                useDebounce={true}
                style={{ width: 200 }}
                isMandatory={service?.Name}
                isMenuPortalTarget={'true'}
                domId={'stickyPosition'}
                placeHolder="Select supplier"
                url={`/mdm-search-supplier?serviceCode=${service?.Code}&companyCode=${jobDetails?.CompanyCode}&searchText=`}
                disabled={disableSupplier}
                dataTestId={'supplier'}
                onChangeDropdown={onChangeDropdownValue}
              />
            </Supplier>
          </Value>
        </Columns>
      </Row>
      <Row>
        {jobDetails.ProductCode === 'BP0004' &&
          <Columns>
            <Title>
              {JOB_COLUMN.START_DATE.label}
            </Title>
            <Value>
              <StartDate isService isDate>
                <DatePicker
                  value={serviceRequestDetails?.StartDateTime}
                  style={{ height: 35 }}
                  id={'startDate'}
                  type={"dateTime"}
                  timeFormat={'HH:mm'}
                  format={'dd/MM/yyyy HH:mm'}
                  enabled={!isDisabled}
                  disableBorder={false}
                  onChange={onChangeDateValue}
                />
              </StartDate>
            </Value>
          </Columns>
        }
        {jobDetails.ProductCode === 'BP0004' &&
          <Columns>
            <Title>
              {JOB_COLUMN.END_DATE.label}
            </Title>
            <Value>
              <EndDate isService isDate>
                <DatePicker
                  value={serviceRequestDetails?.EndDateTime}
                  style={{ height: 35 }}
                  id={'endDate'}
                  type={"dateTime"}
                  timeFormat={'HH:mm'}
                  format={'dd/MM/yyyy HH:mm'}
                  enabled={!isDisabled}
                  disableBorder={false}
                  onChange={onChangeDateValue}
                />
              </EndDate>
            </Value>
          </Columns>
        }
      </Row>
      <Row>
        {/* {!disableRevenue && columnPreference['CURRENCY']?.isActive &&  */}
        <Columns>
          <Title>{JOB_COLUMN.CURRENCY.label}</Title>
          <Value>
            <Currency isService isDropdown>
              <Dropdown
                defaultValue={service?.PriceCurrencyCode}
                onChangeDropdown={onChangeDropdownValue}
                label={'code'}
                isLookup={false}
                dropdownOptions={currencyList}
                dropdownLabel={'code'}
                dropdownValue={'code'}
                placeHolder="Select currency"
                id="actualCurrency"
                appendParams={false}
                isMandatory={true}
                style={{ width: 200 }}
                isMenuPortalTarget={'true'}
                domId={'stickyPosition'}
                disabled={isDisabled}
                removeIsClear={true}
                isEdit
              />
            </Currency>
          </Value>
        </Columns>
        {/* } */}
        {/* {!disableRevenue && columnPreference['BILLING_CURRENCY']?.isActive && */}
        <Columns>
          <Title>
            {JOB_COLUMN.BILLING_CURRENCY.label}
          </Title>
          <Value>
            <BillingCurrency isService isDropdown>
              <Dropdown
                defaultValue={service.BillingCurrencyCode}
                onChangeDropdown={onChangeDropdownValue}
                label={'code'}
                isLookup={false}
                dropdownOptions={currencyList}
                dropdownLabel={'code'}
                dropdownValue={'code'}
                placeHolder="Select currency"
                id="billingCurrency"
                appendParams={false}
                isMandatory={true}
                isMenuPortalTarget={'true'}
                domId={'stickyPosition'}
                style={{ width: 200 }}
                disabled={isDisabled}
                removeIsClear={true}
                isEdit
              />
            </BillingCurrency>
          </Value>
        </Columns>
        {/* } */}
      </Row>

      <Row>
        {/* {
          !disableRevenue && columnPreference['EX_RATE']?.isActive && */}
        <Columns>
          <Title>
            {JOB_COLUMN.EX_RATE.label}
          </Title>
          <Value>
            {service.BillingCurrencyCode === service.PriceCurrencyCode ?
              (!disableRevenue && columnPreference['EX_RATE']?.isActive &&
                <ExRate isService>{service.BillingExchangeRate ? service.BillingExchangeRate.toFixed(autoDecimal) : ''}
                </ExRate>)
              :
              (!disableRevenue && columnPreference['EX_RATE']?.isActive &&
                <ExRate isService>
                  <InputBox
                    value={service.BillingExchangeRate}
                    type={'number'}
                    placeholder={'Enter Exchange Rate'}
                    onChange={onChangeInputValue}
                    id="billingExchangeRate"
                    style={inputStyle}
                    disabled={isDisabled}
                    isMandatory={true}
                    nonZeroNumber
                  />
                </ExRate>)}
          </Value>
        </Columns>
        {/* } */}
        {/* {!disableCost && columnPreference['COST_CURRENCY']?.isActive && */}
        <Columns>
          <Title>
            {JOB_COLUMN.COST_CURRENCY.label}
          </Title>
          <Value>
            <EstCurrency isService isDropdown>
              <Dropdown
                id="estimatedCurrency"
                defaultValue={service.CostCurrencyCode}
                onChangeDropdown={onChangeDropdownValue}
                label={'code'}
                isLookup={false}
                dropdownOptions={currencyList}
                dropdownLabel={'code'}
                dropdownValue={'code'}
                isMandatory={true}
                isMenuPortalTarget={'true'}
                domId={'stickyPosition'}
                placeHolder="Select currency"
                appendParams={false}
                disabled={service.IsFromMaster || isDisabled || isSupplierInvoiceAvailable || isSupplierCreditNoteAvailable}
                style={{ width: 200 }}
                removeIsClear={true}
                isEdit
              />
            </EstCurrency>
          </Value>
        </Columns>
        {/* } */}
      </Row>

      <Row>
        {/* {
          !disableCost && columnPreference['COST_EX_RATE']?.isActive && */}
        <Columns>
          <Title>
            {JOB_COLUMN.COST_EX_RATE.label}
          </Title>
          <Value>
            {service?.CostCurrencyCode === jobDetails?.CurrencyCode ?
              (!disableCost && columnPreference['COST_EX_RATE']?.isActive && <EstExRate isService>
                {service?.CostExchangeRate ? service?.CostExchangeRate.toFixed(autoDecimal) : ''}
              </EstExRate>)
              :
              (!disableCost && columnPreference['COST_EX_RATE']?.isActive &&
                <EstExRate>
                  <InputBox
                    value={service?.CostExchangeRate}
                    type={'number'}
                    placeholder={'Enter Exchange Rate'}
                    onChange={onChangeInputValue}
                    id="costExchangeRate"
                    style={inputStyle}
                    disabled={isDisabled || isSupplierInvoiceAvailable || isSupplierCreditNoteAvailable}
                    min={"0"}
                  />
                </EstExRate>
              )}
          </Value>
        </Columns>
        {/* } */}
        {/* {!disableServiceRequest && columnPreference['SR_NO']?.isActive && */}
        <Columns>
          <Title>
            {JOB_COLUMN.SR_NO.label}
          </Title>
          <Value>
            <SrNo isService>
              {(!serviceRequestDetails?.isShowSRLink) ?
                <Dropdown
                  id="serviceSRnumber"
                  defaultValue={serviceRequestDetails?.SRNumber}
                  onChangeDropdown={onChangeDropdownValue}
                  isLookup={false}
                  type={'charge'}
                  dropdownOptions={srNoDropdown}
                  dropdownLabel={'name'}
                  dropdownValue={'name'}
                  placeHolder="Select Option"
                  style={{ width: 200 }}
                  isMenuPortalTarget={'true'}
                  domId={'stickyPosition'}
                  removeBorder={true}
                  disabled={isDisabled}
                />
                :
                <a style={{ color: '#0645AD' }}
                  href={supplierDetails?.serviceRequestLink}
                  target="_blank" rel="noreferrer">
                  {serviceRequestDetails?.SRNumber}
                </a>}
            </SrNo>
          </Value>
        </Columns>
        {/* } */}
      </Row>
      <Row>
        {/* {!disableServiceRequest && columnPreference['SR_STATUS']?.isActive && */}
        <Columns>
          <Title>
            {JOB_COLUMN.SR_STATUS.label}
          </Title>
          <Value>
            <SrStatus isService>
              {serviceRequestDetails?.SRStatus}
            </SrStatus>
          </Value>
        </Columns>
        {/* } */}
        {/* {!disableServiceRequest && columnPreference['SERVICE_STATUS']?.isActive && */}
        <Columns>
          <Title>
            {JOB_COLUMN.SERVICE_STATUS.label}
          </Title>
          <Value>
            <ServiceStatus isService isDropdown>
              <Dropdown
                id="serviceStatus"
                defaultValue={serviceRequestDetails?.ServiceStatus}
                onChangeDropdown={onChangeDropdownValue}
                isLookup={false}
                type={'service'}
                dropdownOptions={SERVICE_STATUS_OPTIONS}
                dropdownLabel={'label'}
                dropdownValue={'label'}
                placeHolder="Select Option"
                style={{ width: 200 }}
                isMenuPortalTarget={'true'}
                domId={'stickyPosition'}
                removeIsClear={true}
                disabled={isDisabled || serviceRequestDetails?.SRStatus === 'Completed'}
              />
            </ServiceStatus>
          </Value>
        </Columns>
        {/* } */}
      </Row>
      <Row>

        {/* {!disableBilling && columnPreference['BILLING_STATUS']?.isActive && */}
        <Columns>
          <Title>
            {JOB_COLUMN.BILLING_STATUS.label}
          </Title>
          <Value>
            <BillingStatus>
              {serviceRequestDetails?.ServiceStatus === 'Completed' &&
                <>
                  {
                    BILLING_STATUS_OPTIONS_LIST.includes(service.BillingStatus) ?
                      service.BillingStatus
                      : <>
                        {
                          (jobDetails.ProductCode === 'BP0004' && !(jobDetails.ATA && jobDetails.ATD)) ?
                            <div>ATA & ATD is not available</div>
                            :
                            <div data-for={'disabledBillingStatus'} data-tip>
                              <Dropdown
                                id="billingStatus"
                                defaultValue={service.BillingStatus}
                                onChangeDropdown={onChangeDropdownValue}
                                isLookup={false}
                                type={'charge'}
                                dropdownOptions={BILLING_STATUS_OPTIONS}
                                dropdownLabel={'label'}
                                dropdownValue={'name'}
                                style={{ width: 200 }}
                                isMenuPortalTarget={'true'}
                                removeBorder={false}
                                domId={'stickyPosition'}
                                disabled={isDisabled || !service.IsBillable}
                                placeHolder={"Select Option"}
                              />
                            </div>
                        }

                      </>}
                </>
              }
            </BillingStatus>
          </Value>
        </Columns>
        {/* } */}
        {/* {!disableBilling && columnPreference['BILLING_METHOD']?.isActive && */}
        <Columns>
          <Title>
            {JOB_COLUMN.BILLING_METHOD.label}
          </Title>
          <Value>
            <BillingOptions isService isDropdown>
              <Dropdown
                id="billingMethod"
                defaultValue={service?.BillingMethodCode}
                onChangeDropdown={onChangeDropdownValue}
                isLookup={false}
                type={'charge'}
                dropdownOptions={BILLING_METHOD_OPTIONS}
                dropdownLabel={'label'}
                dropdownValue={'name'}
                placeHolder="Select Option"
                style={{ width: 200 }}
                isMenuPortalTarget={'true'}
                domId={'stickyPosition'}
                removeIsClear={true}
                disabled={isDisabled}
              />
            </BillingOptions>
          </Value>
        </Columns>
        {/* } */}
      </Row>
      <Row>
        {/* {!disableBilling && columnPreference['PAYMENT_TERM']?.isActive && */}
        <Columns>
          <Title>
            {JOB_COLUMN.PAYMENT_TERM.label}
          </Title>
          <Value>
            <PaymentTerms isService isDropdown>
              <Dropdown
                id="paymentMethod"
                defaultValue={service?.PaymentTermCode}
                onChangeDropdown={onChangeDropdownValue}
                isLookup={false}
                dropdownOptions={paymentTerms}
                dropdownLabel={'name'}
                type={'charge'}
                dropdownValue={'code'}
                placeHolder="Select Option"
                style={{ width: 200 }}
                isMenuPortalTarget={'true'}
                domId={'stickyPosition'}
                removeIsClear={true}
                disabled={isDisabled}
              />
            </PaymentTerms>
          </Value>
        </Columns>
        {/* } */}
        {/* {!disableCost && columnPreference['BACK_TO_BACK']?.isActive && */}
        <Columns>
          <Title>
            {JOB_COLUMN.BACK_TO_BACK.label}
          </Title>
          <Value>
            <BackToBack isService isDropdown>
              <input
                type="checkbox"
                id="BackToBack"
                disabled={service.IsFromMaster || isDisabled || isSupplierCreditNoteAvailable}
                onClick={onClickCheckbox}
                checked={service.BackToBack}
                style={{ cursor: 'pointer' }} />
            </BackToBack>
          </Value>
        </Columns>
        {/* } */}
      </Row>

      <Row>
        {/* {!disableCost && columnPreference['IS_VOUCHER_NEEDED']?.isActive && */}
        <Columns>
          <Title>
            {JOB_COLUMN.IS_VOUCHER_NEEDED.label}
          </Title>
          <Value>
            <IsCashVoucherNeeded isService isDropdown>
              <input
                type="checkbox"
                id="voucherStatus"
                disabled={service.IsFromMaster || isDisabled || !hasVoucherStatus || isSupplierInvoiceAvailable || isSupplierCreditNoteAvailable}
                onClick={onClickCheckbox}
                checked={service.IsCashVoucherNeeded}
                style={{ cursor: 'pointer' }} />
            </IsCashVoucherNeeded>
          </Value>
        </Columns>
        {/* } */}
        {/* {!disableCost && columnPreference['VOUCHER_NUMBER']?.isActive &&  */}
        <Columns>
          <Title>
            {JOB_COLUMN.VOUCHER_NUMBER.label}
          </Title>
          <Value>
            <VoucherNo isService>
              {
                service.IsCashVoucherNeeded &&
                <>
                  {service?.VoucherStatus === "Approved" || service?.isShowVoucherLink ?
                    <>
                      <a style={{ color: '#0645AD' }}
                        href={service.VoucherLink}
                        target="_blank"
                        rel="noreferrer"
                        data-tip
                        data-for='voucherLink'>
                        {service?.VoucherNumber}
                      </a>
                      <span>
                        <AiFillEdit
                          className="icon-style-sm"
                          style={{ color: 'blue', fontSize: '12px' }}
                          onClick={editVoucherStatus}
                        />
                      </span>
                    </>
                    :
                    <Dropdown
                      id="VoucherNumber"
                      defaultValue={service.VoucherNumber}
                      type={'charge'}
                      label={'VoucherNumber'}
                      isLookup={false}
                      dropdownOptions={filteredVoucherList}
                      dropdownLabel={'VoucherNumber'}
                      dropdownValue={'VoucherNumber'}
                      domId={'jobChargeView'}
                      appendParams={false}
                      style={{ width: 200 }}
                      placeHolder={"Select Voucher No"}
                      removeBorder={true}
                      removeIsClear={true}
                      onChangeDropdown={onChangeDropdownValue}
                      disabled={service.IsFromMaster || !service.IsCashVoucherNeeded || isDisabled || !hasVoucherStatus || isSupplierInvoiceAvailable || isSupplierCreditNoteAvailable}
                    />
                  }
                </>
              }
            </VoucherNo>
          </Value>
        </Columns>
      </Row>
      <Row>
        <Columns>
          <Title>
            {JOB_COLUMN.IS_BILLABLE.label}
          </Title>
          <Value>
            <IsBillable isService isDropdown>
              <input
                type="checkbox"
                id="IsBillable"
                disabled={isDisabled}
                onClick={onClickCheckbox}
                checked={service.IsBillable}
                style={{ cursor: 'pointer' }} />
            </IsBillable>
          </Value>
        </Columns>
        {/* } */}
      </Row>
    </div >
  )
};

export default ServiceLine;
