import { useState } from 'react';
import {
  FooterOverview,
  MarginLeft
} from '../../template.styles'
import PegButton from 'Controls/Button/PegButton';

interface Prop {
  onAddTemplateService: () => void;
  onSave: () => void;
  onEditOption: () => void;
  isFeildsMissing?: boolean;
  selectedList: any[];
  onDeleteOption: () => void;
}

const Footer = (props: Prop) => {
  const {
    onAddTemplateService,
    onSave,
    selectedList,
    isFeildsMissing
  } = props
  return (
    <FooterOverview>
      <PegButton
        id="servicetemplete_addrow"
        type="button"
        variant="Primary"
        onClick={() => onAddTemplateService()}>
        Add Services
      </PegButton>
      <PegButton
        id="servicetemplete_addrow"
        type="button"
        variant="Danger"
        styleObject={{ margin: '0px 12px' }}
        disabled={!Boolean(selectedList.length)}
        onClick={props.onDeleteOption} >
        Delete
      </PegButton>
      <PegButton
        id="servicetemplete_addrow"
        type="button"
        variant="Primary"
        disabled={!Boolean(selectedList.length)}
        onClick={props.onEditOption}>
        Bulk Edit
      </PegButton>
      <MarginLeft>
        {isFeildsMissing && <span>Fill the required fields</span>}
        <PegButton
          id="servicegrid_addrow"
          type="button"
          variant="Primary"
          styleObject={{ marginLeft: 12 }}
          onClick={() => onSave()}>
          Save
        </PegButton>
      </MarginLeft>
    </FooterOverview >
  );
};

export default Footer;