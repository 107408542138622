import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import axios from 'axios';
import HeaderOptions from './components/HeaderOptions';
import Footer from './components/Footer';
import ServiceList from './components/ServiceList';
import { isDraftQuotation } from 'Utils/QuotationGrid';
import { ColumnPreferences } from 'Model/Common/ColumnPreferences';
import { IProduct, IQBillingParties, IQuotationDetails, IServiceAndCharges, onShowTariffFunc, onSubmitImportTemplateFunc, onUpdateChargeFunc, onUpdateServiceFunc } from 'Model/QuotationGrid/types';
import { ExchangeRateLookupEntity } from 'Model/Master/ExchangeRateLookupEntity';
import { QuotationCharge } from 'Model/QuotationGrid/QuotationCharge';
import { QuotationService } from 'Model/QuotationGrid/QuotationService';
import { IServiceItem, ITemplate, onDeleteChargeFunc } from 'Model/Common/types';
import { UserDefault } from 'Model/User/UserDefault';
import { TaxLookupEntity } from 'Model/Master/TaxLookupEntity';
import { CurrencyLookupEntity } from 'Model/Master/CurrencyLookupEntity';
import { IUOM } from 'Model/Job/types';

type MyProps = {
    serviceList: IProduct | undefined;
    selectedProduct: IProduct;
    quoteId?: string;
    quotationDetails: IQuotationDetails;
    disableRevenue: boolean;
    disableCost: boolean;
    disableOperatingIncome: boolean;
    isAllSelected?: boolean;
    isGridDisabled: boolean;

    onSelectAll?: () => void;
    selectedList: number[];
    exchangeRates: ExchangeRateLookupEntity[];
    onSelectServices?: (index: number) => void;
    onAddService: () => void;
    onSaveServices: () => void;
    onDeleteOption: () => void;
    onEditService: () => void;
    hasMissingFields: boolean;
    onClickImportTemplate: () => void;
    onClickOptions: (type: string) => void;
    onDeleteService: (serviceIndex: number) => void;
    onAddCharge: (serviceIndex: number) => void;
    onDeleteCharge: onDeleteChargeFunc;
    onUpdateCharge: onUpdateChargeFunc;
    onUpdateService: onUpdateServiceFunc;
    showTemplateModal: boolean;
    onSubmitImportTemplate: onSubmitImportTemplateFunc;
    onCloseTemplateModal: () => void;
    onShowTariff: onShowTariffFunc;
    onApplyTariff: () => void;
    onClickFilterColumn: () => void;

    userDetails: UserDefault | undefined;
    billingParties: IQBillingParties[];
    columnPreference: ColumnPreferences,
    updateChargeValidation: (validationKey: string, chargeId: string, value: boolean) => void;


};

export const ServiceOverviewRecode: React.FC<MyProps> = (props) => {
    const [serviceOptions, setServiceOptions] = useState([]);
    const [taxList, setTaxList] = useState([]);
    const [currencyList, setCurrencyList] = useState([]);
    const [uomList, setUomList] = useState([]);

    const quotationDetails = useSelector((state: any) => state.QuoteGrid.quotationDetails);
    const userDetails = useSelector((state: any) => state.user.userDetail);

    const {
        serviceList,
        billingParties,
        onAddService,
        onAddCharge,
        onDeleteService,
        onDeleteCharge,
        onEditService,
        onSaveServices,
        onDeleteOption,
        hasMissingFields,
        disableRevenue,
        disableCost,
        disableOperatingIncome,
        onClickOptions,
        isAllSelected,
        onSelectAll,
        selectedList,
        onSelectServices,
        exchangeRates,
        showTemplateModal,
        onSubmitImportTemplate,
        onCloseTemplateModal,
        onShowTariff,
        onApplyTariff,
        isGridDisabled,
        columnPreference,
        updateChargeValidation,
        onClickImportTemplate,
        onClickFilterColumn,
        onUpdateCharge,
        onUpdateService,
        selectedProduct
    } = props

    const getDropdownValues = () => {
        getServices(selectedProduct);
        getCurrencies();
        getUOM();
        getTaxes(selectedProduct);
    }

    const getServices = (selectedProduct: IProduct) => {
        axios.get(`/oum-getservices?company-code=${selectedProduct.ProductDetails?.AssignedToCompanyCode}&bp-code=${selectedProduct.ProductDetails?.ProductCode}`).then((response) => {
            setServiceOptions(response.data?.[0]?.services)
        })
    }

    const getUOM = () => {
        axios.get(`/mdm-unit-of-measure?uomShortNames=&uomCodes=&searchText=`).then((response) => {
            setUomList(response.data)
        })
    }

    const getCurrencies = () => {
        axios.get(`/mdm-currency?searchText=&code=`).then((response) => {
            setCurrencyList(response.data)
        })
    }

    const getTaxes = (selectedProduct: IProduct) => {
        axios.get(`/oum-get-tax?company-code=${selectedProduct.ProductDetails?.AssignedToCompanyCode}`).then((response) => {
            setTaxList(response.data)
        })
    }

    useEffect(() => {
        getDropdownValues()
    }, [selectedProduct])

    const services = serviceList?.ServiceAndCharges
    const isReadOnly = isGridDisabled || !isDraftQuotation(quotationDetails);
    const disableSaveButton = !services?.length

    return (
        <div>
            <HeaderOptions
                hasMissingFields={hasMissingFields}
                disableRevenue={disableRevenue}
                disableCost={disableCost}
                disableOperatingIncome={disableOperatingIncome}
                hideImportTemplate={isReadOnly}
                onClickOptions={onClickOptions}
                onClickImportTemplate={onClickImportTemplate}
                onClickFilterColumn={onClickFilterColumn}
            />
            <ServiceList
                services={services!}
                quotationDetails={quotationDetails}
                onAddCharge={onAddCharge}
                onDeleteService={onDeleteService}
                onDeleteCharge={onDeleteCharge}
                selectedProduct={selectedProduct}
                serviceOptions={serviceOptions}
                taxList={taxList}
                currencyList={currencyList}
                uomList={uomList}
                exchangeRates={exchangeRates}
                billingParties={billingParties}
                disableRevenue={disableRevenue}
                disableCost={disableCost}
                disableOperatingIncome={disableOperatingIncome}
                onUpdateCharge={onUpdateCharge}
                onUpdateService={onUpdateService}
                isAllSelected={isAllSelected}
                onSelectAll={onSelectAll}
                onSelectServices={onSelectServices}
                selectedList={selectedList}
                showTemplateModal={showTemplateModal}
                onSubmitImportTemplate={onSubmitImportTemplate}
                onCloseTemplateModal={onCloseTemplateModal}
                userDetails={userDetails!}
                onShowTariff={onShowTariff}
                isGridDisabled={isGridDisabled}
                columnPreference={columnPreference}
                updateChargeValidation={updateChargeValidation}

            />
            {!isReadOnly ? <Footer
                hasMissingFields={hasMissingFields}
                onSaveServices={onSaveServices}
                onAddService={onAddService}
                onEditService={onEditService}
                selectedList={selectedList}
                onApplyTariff={onApplyTariff}
                onDeleteOption={onDeleteOption}
                isSaveButtonDisabled={disableSaveButton}
            /> : null}
        </div>)
}

export default ServiceOverviewRecode;