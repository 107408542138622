import styled from "styled-components";

export const Overview = styled.div`
  width: 75vw;
  height: 75vh;
`;
export const Heading = styled.div`
margin-left: 8px;
padding-bottom: 8px;
font-weight: bold;
font-size: 16px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  padding-right: 16px;
  border-bottom: 1px solid #C1C1C1;
`;
export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  padding-right: 16px;
  border-top: 1px solid #C1C1C1;
`;
export const Content = styled.div`
    height: calc(75vh - 100px);
    overflow: auto;
    padding 24px;
`;
export const Row = styled.div`
    display: flex;
`;
export const Columns = styled.div`
    display: flex;
    width: 50%;
    padding: 4px 8px;
`;
export const Title = styled.div`
    display: flex;
    width: 30%;
    font-size: 14px;
    font-weight: bold;
`;
export const Value = styled.div`
    display: flex;
    width: 70%;
    padding-left: 8px; 
    font-size: 14px;
    color: #111827;
`;

export const ChargeOverview = styled.div`
    width: 100%;
    padding: 8px 6px;
   
`;

export const ChargeHeader = styled.div`
    display: flex;
    width: 100%;
    padding: 8px 0px;
    align-items: center;
    border-bottom: 1px solid #C1C1C1;
    font-weight: bold;
    font-size: 14px;
`;

export const ChargeIcon = styled.div`
    margin-left: auto;
    padding-right: 8px;
`;

export const TariffOverview = styled.div`
    width: 100%;
    padding: 8px 6px;
   
`;

export const TariffHeader = styled.div`
    display: flex;
    width: 100%;
    padding: 8px 0px;
    align-items: center;
    border-bottom: 1px solid #C1C1C1;
    font-weight: bold;
    font-size: 14px;
`;

export const TariffIcon = styled.div`
    margin-left: auto;
    padding-right: 8px;
`;

export const DetailsView = styled.div`
padding: 16px 0px;
`;