import { RiCloseFill } from 'react-icons/ri';
import { CargoType, CargoOperation } from 'Constants/DropdownOptions';
import PegInput from 'Controls/Input/PegInputBox';
import { Bulk } from 'Model/Order/BulkCargo';
import Dropdown from 'Controls/Dropdown'
import { useCallback } from 'react';
import {
  Flex,
  BulkType,
  BulkOperation,
  BulkWeight,
  Delete
} from "Containers/CargoDetails/cargo.styles";

interface Prop {
  index: number
  bulkDetail: Bulk,
  onDelete: (id: any) => void;
  onUpdate: (key: string, event: any, index: number) => void;
}

const BulkLine = (props: any) => {
  const {
    onDelete,
    index,
    bulkList,
    onUpdate
  } = props

  const onChange = useCallback((event: any, key: string) => {
    onUpdate(key, event, index)
  }, [bulkList]);

  return (
    <Flex>
      <BulkType>
        <Dropdown
          id={'drpCargoType'}
          isLookup={false}
          dropdownLabel={'name'}
          dropdownValue={'code'}
          isMandatory={true}
          scrollToTop={true}
          defaultValue={bulkList?.CargoTypeName}
          dropdownOptions={CargoType}
          placeholder={'Select Cargo Type'}
          onChangeDropdown={(event: any) => onChange(event, 'cargoType')} />
      </BulkType>

      <BulkOperation>
        <Dropdown
          id={'drpCargoOperation'}
          isLookup={false}
          dropdownLabel={'name'}
          dropdownValue={'code'}
          isMandatory={true}
          scrollToTop={true}
          defaultValue={bulkList?.WeightUomName}
          dropdownOptions={CargoOperation}
          placeholder={'Select Cargo Operation'}
          onChangeDropdown={(event: any) => onChange(event, 'cargoOperation')} />
      </BulkOperation>

      <BulkWeight>
        <PegInput
          type={'number'}
          id={'txtTotalGrossWeight'}
          name={'totalGrossWeight'}
          isMandatory={true}
          defaultValue={bulkList?.GrossWeight}
          onChange={(event: any) => onChange(event, 'grossWeight')} />
      </BulkWeight>

      <Delete>
        <button>
          <RiCloseFill
            size="1.2em"
            className="fill-current text-danger group-hover:text-danger-shade"
            onClick={() => onDelete(index)} />
        </button>
      </Delete>
    </Flex>
  );
};

export default BulkLine


