import PegButton from 'Controls/Button/PegButton';
import { GrFormClose } from "react-icons/gr";
import {
  ColumnModalOverview,
  ColumnModalFooter,
  Content,
  Header,
} from './Preference.styles';
import ListColumn from './ListColumn';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { CheckBox } from '@syncfusion/ej2-buttons';
import PegCheckbox from 'Controls/Checkbox/PegCheckbox';

type Props = {
  details?: any;
  type: string;
  column: any,
  onSavePreferences: (list: any) => void
  onCloseFilterModal: () => void;
};

function Preferences(props: Props) {
  const {
    column,
    onSavePreferences,
    onCloseFilterModal
  } = props;

  const [columns, setColumns] = useState(column)
  const [selectAll, setSelectAll] = useState(false)

  useEffect(() => {
    const allActive = Object.keys(columns).every((key: string) => columns[key].isActive);
    setSelectAll(allActive);
  }, [columns, column]);

  const onSelectColumn = useCallback((key: string, checked: boolean) => {
    columns[key].isActive = checked
    setColumns({ ...columns })
  }, [column])

  const onSavePreferencesList = useCallback(() => {
    onSavePreferences(columns)
  }, [])

  const onSelectAll = (checked: boolean) => {

    let despread = { ...columns }
    Object.keys(despread).map((key: string) => {
      if (despread[key].isDefault) {
        return
      }
      despread[key].isActive = checked
    })

    setColumns(despread)
  }

  return (
    <ColumnModalOverview>
      <Header>
        <h2>Preferences</h2>
        <GrFormClose
          onClick={onCloseFilterModal}
          className="icon-style" />
      </Header>
      <Content>
        <PegCheckbox
          id={'preferenceSelectAll'}
          checked={selectAll}
          onChange={(e: any) => onSelectAll(e?.checked)}
          label='Select All' />
        <ListColumn
          column={column}
          onSelectColumn={(type: string, isActive: boolean) => onSelectColumn(type, isActive)}
        />
      </Content>
      <ColumnModalFooter>
        <PegButton
          id="filter_modal_close"
          type="button"
          variant='Primary'
          onClick={onCloseFilterModal}>
          Close
        </PegButton>
        <PegButton
          id="filter_modal_close"
          type="button"
          variant='Primary'
          styleObject={{ marginLeft: 12 }}
          onClick={onSavePreferencesList}>
          Save
        </PegButton>
      </ColumnModalFooter>
    </ColumnModalOverview>
  )
}

export default Preferences