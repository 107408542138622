import { Quotation } from '../Model/Order/Quotation';
import { IQuotationList } from '../Model/Order/QuotationList';
import { requests, pagination } from './Agent';

const QuotationAgent = {
  all: (offset: number, limit: number = 10) => requests.get<IQuotationList[]>(`/getQuoationListData?${pagination(offset, limit)}`),
  get: (id: number, userCode: string) => requests.get<Quotation>(`/getQuotationData?quotationId=${id}&userCode=${userCode}`),
  delete: (id: number) => requests.del(`/quote/${id}`),
  save: (quotation: any) => { return requests.post(`/saveQuotation/`, quotation) },
  approve: (id: number, optionId: number, remarks: string, userCode: string) => requests.post(`/approveQuotation?quotationId=${id}&optionId=${optionId}&remarks${remarks}=&userCode=${userCode}`, {}),
  reject: (id: number, remarks: string, userCode: string) => requests.post(`/rejectQuotation?quotationId=${id}&remarks=${remarks}&userCode=${userCode}`, {}),
  cancel: (id: number, remarks: string, userCode: string) => requests.post(`/cancelQuotation/?quotationId=${id}&remarks=${remarks}&userCode=${userCode}`, {}),
  convertToJob: (id: number) => requests.get(`/get-job-scope?quotationId=${id}`),
  createJob: (data: object) => requests.post(`/create-job`, { data }),
  quotationList: (pageNo: number, userCode: string, searchText: string) => requests.get(`/getQuoationListData?pageNo=${pageNo}&userCode=${userCode}&searchText=${searchText}`)
};

export default QuotationAgent;
