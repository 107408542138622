import styled from "styled-components";

export const GridErrorsContainer = styled.div`
    display: flex;
    background-color: #faa0a099;
    border: 2px solid #e74c3cc2;
    border-radius: 6px;
    padding: 15px 15px 5px;  
    margin: 20px 0;
    width: auto;
`;

export const ProductContainer = styled.div`
    margin-bottom: 10px;
`;

export const ProductName = styled.div`
    margin-bottom: 5px;
    font-weight: bold;
`;

export const ServiceContainer = styled.div`
    margin-bottom: 10px;
`

export const ChargesContainer = styled.div`
    padding-left: 10px;
`;