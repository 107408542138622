export interface IPackage {
  PackageId: number | null;
  Version: number;
  PackageTypeCode: string;
  PackageTypeName: string;
  Length: number | null;
  Width: number | null;
  Height: number | null;
  DimensionUomCode: string;
  DimensionUomName: string;
  Quantity: number | null;
  Weight: number | null;
  WeightUomCode: string;
  WeightUomName: string;
  GrossWeight: number | null;
  IsDangerous: boolean;
  CommodityTypeCode: string;
  CommodityTypeName: string;
  CommodityName: string;
  HSCode: string;
  HSName: string;
  GrossVolume: number | null;
  VolumeUomCode: string;
  VolumeUomName: string;
  CreatedAt: Date;
  CreatedBy: string;
  ModifiedAt: Date | null;
  ModifiedBy: string;
  IsActive: boolean;
}

export class Package implements IPackage {
  PackageId: number | null;

  Version: number;

  PackageTypeCode: string;

  PackageTypeName: string;

  Length: number | null;

  Width: number | null;

  Height: number | null;

  DimensionUomCode: string;

  DimensionUomName: string;

  Quantity: number | null;

  Weight: number | null;

  GrossWeight: number | null;

  WeightUomCode: string;

  WeightUomName: string;

  IsDangerous: boolean;

  CommodityTypeCode: string;

  CommodityTypeName: string;

  CommodityName: string;

  HSCode: string;

  HSName: string;

  GrossVolume: number | null;

  VolumeUomCode: string;

  VolumeUomName: string;

  CreatedAt: Date;

  CreatedBy: string;

  ModifiedAt: Date | null;

  ModifiedBy: string;

  IsActive: boolean;

  constructor() {
    this.PackageId = null;
    this.Version = 0;
    this.PackageTypeCode = '';
    this.PackageTypeName = '';
    this.Length = 0;
    this.Width = 0;
    this.Height = 0;
    this.DimensionUomCode = '';
    this.DimensionUomName = '';
    this.Quantity = 0;
    this.Weight = 0;
    this.GrossWeight = 0;
    this.WeightUomCode = '';
    this.WeightUomName = '';
    this.IsDangerous = false;
    this.CommodityTypeCode = '';
    this.CommodityTypeName = '';
    this.CommodityName = '';
    this.HSCode = '';
    this.HSName = '';
    this.GrossVolume = 0;
    this.VolumeUomCode = '';
    this.VolumeUomName = '';
    this.CreatedAt = new Date();
    this.CreatedBy = '';
    this.ModifiedAt = new Date();
    this.ModifiedBy = '';
    this.IsActive = true;
  }
}
