import ReactDOM from 'react-dom/client'
import './index.scss';
import { Provider } from 'react-redux';
import store from './Redux/Store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication
} from "@azure/msal-browser";
import { msalConfig } from "./Utils/AuthConfig";
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ToastContainer } from 'react-toastify';


export const history = createBrowserHistory();

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const el = document.getElementById('root')
if (el === null) throw new Error('Root container missing in index.html')
const root = ReactDOM.createRoot(el)
root.render(
  <Provider store={store}>
    <Router history={history}>
      <ToastContainer data-testid="toastify" />
      <App history={history} pca={msalInstance} />
    </Router>
  </Provider>)

reportWebVitals();
