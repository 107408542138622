import styled from "styled-components";

interface IStylesProps {

}

export const Overview = styled.div<IStylesProps>`
  display: flex;
`;

export const MapOverview = styled.div<IStylesProps>`
  width: 80vw;
`;

export const PointList = styled.div<IStylesProps>`
  width: 30%;
`;

export const PointDetails = styled.div<IStylesProps>`
    display: flex;
    align-items: center;
    font-size: 14px;
    padding-left: 4px;
`;

export const PointIcon = styled.div<IStylesProps>`
    margin: 8px;
`;

export const Footer = styled.div<IStylesProps>`
    position: absolute;
    bottom: 0px;
    margin: 16px;
    display: flex;
    align-items: center;
`;

export const Options = styled.div<IStylesProps>`
    margin: 16px;
`;

export const Label = styled.div<IStylesProps>`
    margin-bottom: 12px;
    font-size: 14px;
`;

export const RequiredFields = styled.div<IStylesProps>`
  color: red;
  font-size: 12px;
  margin-left: 12px;
`

export const LoaderView = styled.div<IStylesProps>`
  overflow: hidden;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const LoaderOverview = styled.div<IStylesProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const PointLists = styled.div<IStylesProps>`
    margin: 12px 4px;
    font-size: 14px;
    font-weight: 600;
`;

export const TabList = styled.div<IStylesProps>`
    margin: 16px;
    display: flex;
    border-bottom: none;
`;
export const Tab = styled.div<IStylesProps>`
    font-size:14px;
    color: ##121212;
    padding: 4px;
    width: 50%;
    display: flex;
    justify-content: center;
    cursor: pointer;
`;