export const Color = {
    operatingIncomeHighlight: "#FFC929",
    revenueHighlight: "#57C4FF",
    costHighlight: "#E87D7D",
    serviceRequestHighlight: "#9E9E9E",
    billingHighlight: "#84BC6C",

    operatingIncome: "#FFF5D6",
    revenue: "#DBF3FF",
    cost: "#FAE5E5",
    serviceRequest: "#EBEBEB",
    billing: "#E5F1E0",
}