import {
  SET_QUOTATION_SERVICES,
  SET_QUOTATION_ERROR,
  UPDATE_QUOTATION_SERVICE,
  UPDATE_QUOTATION_CHARGE,
  UPDATE_SELECTED_PRODUCT,
  ON_ADD_QUOTATION_SERVICE,
  ON_DELETE_QUOTATION_SERVICE,
  BULK_DELETE_QUOTATION_SERVICES,
  ON_ADD_QUOTATION_CHARGE,
  ON_DELETE_QUOTATION_CHARGE,
  IS_QUOTATION_SERVICES_SAVING,
  ON_QUOTATION_SAVED,
  ON_BULK_QUOTATION_UPDATE,
  ON_IMPORT_TEMPLATE,
  UPDATE_ON_APPLY_TARIFF_QUOTATION,
  UPDATE_QUOTE_CHARGE_VALIDATION,
  UPDATE_QUOTATION_SERVICES_DETAILS,
} from "../ActionTypes/ActionTypes";
import { Service } from 'Model/QuotationGrid/QuotationService';
import { Charge, QuotationCharge } from 'Model/QuotationGrid/QuotationCharge';
import { createServiceObj } from 'Utils/QuotationGrid';
import moment from "moment";
import { IProduct, IProductDetails, IServiceAndCharges } from "Model/QuotationGrid/types";

var initialState = {
  quotationDetails: {},
  services: [],
  isModified: false,
  selectedProduct: null,
  isLoading: true,
  isError: false,
  isQuotationSaving: false,
  isQuotationSaved: false,
  isRefreshLoader: false,
  isViewer: false,
  message: '',
  serviceValidation: {
    Name: true,
    PriceCurrencyCode: true,
    CostCurrencyCode: true,
    SupplierName: true,
  },
  chargeValidation: {
    Name: true,
    Quantity: true,
    UomName: true,
    UnitPrice: true,
    UnitCost: true
  },
  tariffServiceValidation: {
    SupplierName: true
  },
  tariffChargeValidation: {
    UomName: true,
    BillingPartyCode: true,
    Quantity: true
  },
}
let selectedProductIndex = null
let services: IProduct[] = [];
let product: IProductDetails | null = null;
let serviceList: Array<any> = []
export const QuoteGrid = (state = initialState, action: any) => {

  switch (action.type) {

    case SET_QUOTATION_SERVICES:
      const {
        response
      } = action
      if (Array.isArray(response)) {
        return {
          ...state,
          isLoading: false,
          isError: false,
          isRefreshLoader: true,
          message: response[0],
          isViewer: false
        };
      } else return {
        ...state,
        quotationDetails: { ...response.QuotationDetails },
        services: [...response?.Products],
        isLoading: false,
        isError: false,
        isViewer: response.isViewer
      };

    case SET_QUOTATION_ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false
      };

    case UPDATE_QUOTATION_SERVICE:
      const {
        selectedProduct,
        serviceIndex
      } = action
      selectedProductIndex = state.services.findIndex((item: IProduct) =>
        item?.ProductDetails?.QuotationProductId === selectedProduct?.ProductDetails?.QuotationProductId);
      services = state.services;
      services[selectedProductIndex].ServiceAndCharges[serviceIndex].service = action.service
      return {
        ...state,
        services: [...services]
      };

    case UPDATE_QUOTATION_CHARGE:
      selectedProductIndex = state.services.findIndex((item: IProduct) =>
        item?.ProductDetails?.QuotationProductId === action.selectedProduct?.ProductDetails?.QuotationProductId);
      services = state.services;

      let newSelectedProduct = { ...services[selectedProductIndex] };
      newSelectedProduct.ServiceAndCharges[action.serviceIndex].charges[action.chargeIndex] = action.charge

      services[selectedProductIndex] = newSelectedProduct;

      return {
        ...state,
        services: [...services]
      };

    case UPDATE_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: action.product
      };

    case ON_ADD_QUOTATION_SERVICE:
      selectedProductIndex = state.services.findIndex((item: IProduct) =>
        item?.ProductDetails?.QuotationProductId === action.selectedProduct?.ProductDetails?.QuotationProductId);
      services = state.services;

      const filteredServices = services[selectedProductIndex]?.ServiceAndCharges.filter((service: IServiceAndCharges) => service.service.IsActive)
      const highestLineNumber = filteredServices.reduce((maxLineNumber, service: any) => {
        return Math.max(maxLineNumber, service.service.RowOrder);
      }, 0);

      const lineNumberToSet = (highestLineNumber + 1).toString()

      const isLineNumberExists = services[selectedProductIndex]?.ServiceAndCharges?.some((service: IServiceAndCharges) => service.service.RowOrder === parseInt(lineNumberToSet))
      const newLineNumber = isLineNumberExists ? highestLineNumber.toString() : lineNumberToSet

      let jobServiceObj: IServiceAndCharges = {
        charges: [
          { ...new Charge, RowOrder: 1 }
        ],
        service: {
          ...new Service,
          RowOrder: parseInt(newLineNumber)
        },
      }
      const stateUpdatedServices = [...services[selectedProductIndex]?.ServiceAndCharges, jobServiceObj]
      services[selectedProductIndex].ServiceAndCharges = stateUpdatedServices
      return {
        ...state,
        services: [...services]
      };

    case ON_IMPORT_TEMPLATE:
      selectedProductIndex = state.services.findIndex((item: IProduct) =>
        item?.ProductDetails?.QuotationProductId === action.selectedProduct?.ProductDetails?.QuotationProductId);
      services = state.services;
      product = services[selectedProductIndex]?.ProductDetails
      let templateArray = createServiceObj(action.template, product, services[selectedProductIndex].ServiceAndCharges)
      let list = [...services[selectedProductIndex].ServiceAndCharges, ...templateArray];
      services[selectedProductIndex].ServiceAndCharges = list;
      return {
        ...state,
        services: [...services]
      };

    case ON_DELETE_QUOTATION_SERVICE:
      selectedProductIndex = state.services.findIndex((item: IProduct) =>
        item?.ProductDetails?.QuotationProductId === action.selectedProduct?.ProductDetails?.QuotationProductId);
      services = state.services;
      services[selectedProductIndex].ServiceAndCharges.splice(action.serviceIndex, 1);

      const updatedServices = services[selectedProductIndex].ServiceAndCharges.map((service: IServiceAndCharges, index: number) => ({
        ...service,
        service: {
          ...service.service,
          RowOrder: index + 1
        },
        charges: service.charges.map((charge: QuotationCharge, index: number) => ({
          ...charge,
          RowOrder: index + 1
        }))
      }));

      services[selectedProductIndex].ServiceAndCharges = updatedServices;

      return {
        ...state,
        services: [...services],
        isModified: true
      };

    case BULK_DELETE_QUOTATION_SERVICES:
      const {
        selectedList,
        isAllSelected
      } = action
      selectedProductIndex = state.services.findIndex((item: IProduct) =>
        item.ProductDetails?.QuotationProductId === action.selectedProduct?.ProductDetails?.QuotationProductId);
      services = state.services;
      serviceList = services[selectedProductIndex].ServiceAndCharges;
      if (isAllSelected) {
        serviceList = []
      } else {
        serviceList = serviceList.filter((item: IServiceAndCharges, index: number) => !selectedList.includes(index))
      }
      const updatedServiceList = serviceList.map((service: IServiceAndCharges, index: number) => ({
        ...service,
        service: {
          ...service.service,
          RowOrder: index + 1
        },
        charges: service.charges.map((charge: QuotationCharge, index: number) => ({
          ...charge,
          RowOrder: index + 1
        }))
      }));

      services[selectedProductIndex].ServiceAndCharges = updatedServiceList;

      return {
        ...state,
        services: [...services],
        isModified: true
      };

    case ON_ADD_QUOTATION_CHARGE:
      selectedProductIndex = state.services.findIndex((item: IProduct) =>
        item?.ProductDetails?.QuotationProductId === action.selectedProduct?.ProductDetails?.QuotationProductId);
      services = state.services;
      let chargeList = services[selectedProductIndex].ServiceAndCharges[action.serviceIndex].charges;
      const filteredCharges = chargeList?.filter((charge: QuotationCharge) => charge.IsActive)

      chargeList.push({ ...new Charge, RowOrder: filteredCharges.length + 1 })
      services[selectedProductIndex].ServiceAndCharges[action.serviceIndex].charges = chargeList;
      return {
        ...state,
        services: [...services]
      };

    case ON_DELETE_QUOTATION_CHARGE:
      selectedProductIndex = state.services.findIndex((item: IProduct) =>
        item?.ProductDetails?.QuotationProductId === action.selectedProduct?.ProductDetails?.QuotationProductId);
      services = state.services;
      services[selectedProductIndex].ServiceAndCharges[action.serviceIndex].charges.splice(action.chargeIndex, 1);
      return {
        ...state,
        services: [...services]
      };

    case ON_BULK_QUOTATION_UPDATE:
      return {
        ...state,
        isModified: true,
        services: [...action.services]
      };

    case IS_QUOTATION_SERVICES_SAVING:
      return {
        ...state,
        isQuotationSaving: action.isSaving
      };

    case ON_QUOTATION_SAVED:
      return {
        ...state,
        isQuotationSaved: action.isSaving
      };

    case UPDATE_ON_APPLY_TARIFF_QUOTATION:
      selectedProductIndex = state.services.findIndex((item: IProduct) =>
        action.selectedProduct?.ProductDetails?.QuotationProductId === item?.ProductDetails?.QuotationProductId);
      services[selectedProductIndex].ServiceAndCharges = [...action.servAndChar]
      return {
        ...state,
        services: [...services]
      };

    case UPDATE_QUOTE_CHARGE_VALIDATION:
      const { validationKey, chargeCode, value } = action;

      const newChargeValidation = JSON.parse(JSON.stringify(state.chargeValidation));

      if (!newChargeValidation[validationKey]) newChargeValidation[validationKey] = {};

      newChargeValidation[validationKey][chargeCode] = value;

      return {
        ...state,
        chargeValidation: newChargeValidation
      };

    case UPDATE_QUOTATION_SERVICES_DETAILS:
      selectedProductIndex = state.services.findIndex((item: IProduct) => item?.ProductDetails?.QuotationProductId === action.selectedProduct?.ProductDetails?.QuotationProductId);
      services = state.services;
      services[selectedProductIndex].ServiceAndCharges[action.serviceIndex] = action.serviceAndCharges;

      return {
        ...state,
        services: [...services],
      };

    default:
      return state;

  }
}