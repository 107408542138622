export interface ICustomerAdditionalDetail {
    Code: string;
    Name: string;
    IsActive: boolean;
    CountryCode: string;
    CountryName: string;
    IsHubCustomer: string;
    RedFlag: string;
    AccountManager: {};
    PartyAddresses: []
}
export class CustomerAdditionalDetail implements ICustomerAdditionalDetail {
    Code: string;

    Name: string;

    IsActive: boolean;

    CountryCode: string;

    CountryName: string;

    IsHubCustomer: string;

    RedFlag: string;

    AccountManager: {};

    PartyAddresses: []

    constructor() {
        this.Code = '';
        this.Name = '';
        this.IsActive = false;
        this.CountryCode = '';
        this.CountryName = '';
        this.IsHubCustomer = '';
        this.RedFlag = '';
        this.AccountManager = {};
        this.PartyAddresses = [];
    }
}