
import React from 'react';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';

interface ICheckboxProps {
  id: string;
  name?: string;
  label?: string;
  onChange: any;
  disabled?: boolean;
  checked?: boolean;
  className?: string;
  value?: string;
  style?: any;
  labelPosition?: any;
  cssClass?: any;
}
export default function PegCheckbox(props: ICheckboxProps) {
  const { id, name, onChange, label, disabled, checked, value, style, labelPosition, cssClass } = props;
  return (
    <div className="control-pane">
      <div className="control-section">
        <div className="checkbox-control">
          <div className="row" style={style}>
            <CheckBoxComponent
              id={id}
              name={name}
              checked={checked}
              label={label}
              className={'e-input'}
              data-testid={"chkPeg"}
              labelPosition={labelPosition}
              change={onChange}
              disabled={disabled}
              value={value} />
          </div>
        </div>
      </div>
    </div>
  );
}
