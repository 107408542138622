import React, { useEffect } from 'react';
import { useState, useRef, useCallback } from 'react';
import {
  GoogleMap,
  LoadScript,
  Polygon,
  DrawingManager,
  Marker
} from "@react-google-maps/api";
import {
  MapOverview
} from '../port.styles';

type Props = {
  center: any;
  paths: any[];
  point?: any;
  markerPoints: any[];
  onUpdatePath: (path: any) => void;
};

type MarkerProps = {
  pos: any;
  id: any;
  type?: any;
};

const Map = (props: Props) => {
  const {
    paths,
    onUpdatePath,
    point,
    center,
    markerPoints
  } = props;
  const polygonRef = useRef<any>(null);
  const listenersRef = useRef<any>([]);
  const [state, setState] = useState<any>({
    drawingMode: "polygon"
  });
  const [markerPoint, setMarkerPoints] = useState<any>([]);

  // drawing polygon

  const options: any = {
    drawingControl: true,
    drawingControlOptions: {
      drawingModes: ["polygon"]
    },
    polygonOptions: {
      fillColor: `#2196F3`,
      strokeColor: `#2196F3`,
      fillOpacity: 0.5,
      strokeWeight: 2,
      clickable: true,
      editable: true,
      draggable: true,
      zIndex: 1
    }
  };

  const onPolygonComplete = useCallback(
    function onPolygonComplete(poly: any) {
      const polyArray = poly.getPath().getArray();
      let paths: any = [];
      polyArray.forEach(function (path: any) {
        paths.push({ lat: path.lat(), lng: path.lng() });
      });
      onUpdatePath(paths);
      point(paths);
      noDraw();
      poly.setMap(null);
    },
    [point]
  );

  const noDraw = () => {
    setState(function set(prevState: any) {
      return Object.assign({}, prevState, {
        drawingMode: "maker"
      });
    });
  };

  const onEdit = useCallback((event: any) => {
    if (polygonRef.current) {
      const nextPath: any = polygonRef.current
      if (nextPath) {
        const coords = nextPath.getPath().getArray().map((coord: any) => {
          return {
            lat: coord.lat(),
            lng: coord.lng()
          }
        });
        onUpdatePath(coords);
      }
    }
  }, [onUpdatePath, point]);

  const onLoad = useCallback((polygon: any) => {
    polygonRef.current = polygon;
    const path: HTMLElement | any = polygon.getPath();
    if (path) {
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    }
  }, [onEdit]
  );

  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis: any) => lis.remove());
    polygonRef.current = null;
  }, []);

  const onClickMap = (event: any) => {
    // var geocoder: any = new google.maps.Geocoder();
    // let obj = {
    //   lat: event.latLng.lat(),
    //   lng: event.latLng.lng()
    // }
    // geocoder.geocode({
    //   'latLng': event.latLng
    // }, function (results: any, status: any) {
    // });
    // setMarkerPoints([...markerPoint, obj])
  }

  useEffect(() => {
    setMarkerPoints(markerPoints);
  }, [markerPoints]);

  return (
    <MapOverview>
      <GoogleMap
        id="map-canvas"
        mapContainerClassName="app-map"
        center={center}
        onClick={(event: any) => onClickMap(event)}
        options={{
          minZoom: 3,
          maxZoom: 15
        }}
        zoom={12}>
        <Polygon
          editable
          path={paths}
          onMouseUp={(event: any) => onEdit(event)}
          onDragEnd={(event: any) => onEdit(event)}
          onLoad={onLoad}
          onUnmount={onUnmount}
        />
        <DrawingManager
          drawingMode={state.drawingMode}
          options={options}
          onPolygonComplete={onPolygonComplete}
        />
        {paths &&
          paths?.map((pos, key) => {
            return (
              <div key={key}>
                <MarkerComponent
                  id={key}
                  pos={pos}
                />
              </div>

            )
          })}
        {markerPoint &&
          markerPoint?.map((pos: any, key: React.Key | null | undefined) => {
            return (
              <div key={key}>
                <MarkerComponent
                  id={pos?.place ? pos?.place : key}
                  pos={pos}
                  type="points"
                />
              </div>

            )
          })}
      </GoogleMap >
    </MapOverview >
  )
};

const MarkerComponent: React.FC<MarkerProps> = (props: MarkerProps) => {
  const {
    pos,
    type,
    id
  } = props
  return (
    <Marker
      icon={type === 'points' ? 'http://maps.google.com/mapfiles/kml/paddle/blu-blank.png' : ''}
      label={(id + 1).toString()}
      position={pos} />
  )
}

export default React.memo(Map)

