import { QuoteStatus } from "Constants/Constant";
import { ITemplate } from "Model/Common/types";
import { QuotationCharge } from "Model/QuotationGrid/QuotationCharge";
import { QuotationService } from "Model/QuotationGrid/QuotationService";
import { IProduct, IProductDetails, IQuotationDetails, IServiceAndCharges } from "Model/QuotationGrid/types";
import { UserDefault } from "Model/User/UserDefault";
import moment from "moment";
import { formatAddress, splitBillingPartyCode } from "./Generic";

export const transformQuotationGrid = (list: ITemplate[]) => {
  let tempList = list.map((item: ITemplate) => {
    return {
      service: item.services,
      charges: item.charges
    } as IServiceAndCharges;
  })
  return tempList;
}

export const applyTariff = (
  list: number[],
  serviceAndCharges: IServiceAndCharges[],
  tariffDetails: IServiceAndCharges[],
  isAllSelected: boolean
) => {
  let services = [...serviceAndCharges];

  list?.forEach((item, index) => {
    if (item < services?.length && index < tariffDetails?.length) {
      services[item] = tariffDetails[index];
    }
  });

  return services;
};

export const setTariffDetails = (userDetails: UserDefault, charge: QuotationCharge) => {
  charge.TariffDetails = {
    ...charge.TariffDetails,
    ModifiedBy: userDetails?.Code,
    ModifiedAt: moment(new Date()).utc().format(),
    TariffEditedBy: userDetails?.Code,
    TariffEditedOn: moment(new Date()).utc().format()
  }
  return charge
}

export const onBulkEdit = (serviceList: IProduct[], selectedList: number[], bulkListService: QuotationService, bulkListCharge: QuotationCharge, selectedProduct: IProduct) => {
  let selectedProductIndex = null;
  selectedProductIndex = serviceList.findIndex((el: IProduct) =>
    el?.ProductDetails?.QuotationProductId === selectedProduct?.ProductDetails?.QuotationProductId);
  const updatedList = serviceList[selectedProductIndex].ServiceAndCharges.map((list: IServiceAndCharges, index: number) => {
    if (selectedList.includes(index)) {
      return editCharge(list, bulkListService, bulkListCharge);
    } else {
      return list
    }
  })
  serviceList[selectedProductIndex].ServiceAndCharges = updatedList;
  return serviceList;
}

export const onBulkEditAll = (serviceList: IProduct[], bulkListService: QuotationService, bulkListCharge: QuotationCharge, selectedProduct: IProduct) => {
  let selectedProductIndex = null;
  selectedProductIndex = serviceList.findIndex((el: IProduct) =>
    el.ProductDetails?.QuotationProductId === selectedProduct?.ProductDetails?.QuotationProductId);
  const updatedList = serviceList[selectedProductIndex].ServiceAndCharges.map((list: IServiceAndCharges, index: number) => {
    return editCharge(list, bulkListService, bulkListCharge);
  }
  )
  serviceList[selectedProductIndex].ServiceAndCharges = updatedList;
  return serviceList;
}


export const setChargeValue = (obj: QuotationCharge, selectedProduct: IProduct, key: string, event: any) => {
  if (key === 'chargeName') {
    obj.Code = event ? event.code : ''
    obj.Name = event ? event.name : ''
    obj.GSServiceName = ''
    obj.GSServiceCode = ''
  }
  if (key === 'vatType') {
    obj.PriceTaxCode = event ? event.code : ''
    obj.PriceTaxName = event ? event.name : ''
    obj.PriceTaxPercent = event ? event.percentage : ''
  }
  if (key === 'estimateTax') {
    //check this condition
    obj.CostTaxAmount = ""
    obj.CostTaxCode = event ? event.Code : ""
    obj.CostTaxPercent = event ? event.Percentage : ""
  }
  if (key === 'billingParty') {
    const code = event ? splitBillingPartyCode(event.Code) : '';
    obj.BillingPartyCode = code[0];
    obj.BillingPartyName = event ? event.Node : ''
    obj.AddressFk = event ? event.AddressId[0] : '';
  }
  if (key === 'UOM') {
    obj.UomCode = event ? event.code : ''
    obj.UomName = event ? event.fullName : ''
  }
  if (key === 'taxType') {
    obj.PriceTaxCode = event ? event.Code : ''
    obj.PriceTaxName = event ? event.Name : ''
    obj.PriceTaxPercent = event ? event.Percentage : ''
  }
  if (key === 'OperatingIncome') {
    obj.OperatingIncomeActual = event
  }
  if (key === 'TotalCost') {
    obj.TotalEstimatedCost = event
  }
  if (key === 'quantity') {
    obj.Quantity = event ? parseFloat(event) : 0
  }
  if (key === 'unitPrice') {
    obj.UnitPrice = event ? parseFloat(event) : null
  }
  if (key === 'taxRate') {
    obj.PriceTaxPercent = event
  }
  if (key === 'netPrice') {
    obj.NetPrice = event
  }
  if (key === 'unitCost') {
    obj.UnitCost = event ? parseFloat(event) : null
  }
  if (key === 'NetPriceWithDiscount') {
    obj.NetPriceWithDiscount = event
  }
  if (key === 'NetPriceWithDiscountTax') {
    obj.NetPriceWithDiscountTax = event
  }
  if (key === 'remarks') {
    obj.InternalRemarks = event
  }
  if (key === 'externalRemarks') {
    obj.ExternalRemarks = event
  }
  if (key === 'netPriceDiscount') {
    obj.NetPriceWithDiscount = event
  }
  if (key === 'netPriceDiscountTax') {
    obj.NetPriceWithDiscountTax = event
  }
  if (key === 'priceTax') {
    obj.PriceTaxAmount = event
  }
  if (key === 'customerService') {
    obj.GSServiceName = event?.GSServiceName ? event?.GSServiceName : event?.name
    obj.GSServiceCode = event?.GSServiceCode ? event?.GSServiceCode : event?.code
    if (event?.ChargeName && event?.ChargeCode) {
      obj.Code = event.ChargeCode
      obj.Name = event.ChargeName
    }
  }
  return obj
}

export const setServiceDateValue = (obj: QuotationService, key: string, event: any) => {
  if (key === 'startDate' || key === 'defaultStartDateTime') {
    obj.StartDateTime = event ? moment(event?.value).utc().format() : ''
  }

  if (key === 'endDate' || key === 'defaultEndDateTime') {
    obj.EndDateTime = event ? moment(event?.value).utc().format() : ''
  }
  return obj;
}

export const setServiceValue = (obj: QuotationService, key: string, event: any) => {
  if (key === 'serviceName') {
    obj.Code = event ? event.code : '';
    obj.Name = event ? event.name : '';
    obj.OPTags = event ? event.opTags : '';
  }
  if (key === 'customerService') {
    if (event.ServiceCode && event.ServiceName) {
      obj.Code = event.ServiceCode
      obj.Name = event.ServiceName
    }
  }
  if (key === 'priceCurrency') {
    obj.PriceCurrencyCode = event ? event.code : '';
  }
  if (key === 'supplier') {
    obj.SupplierCode = event ? event.code : '';
    obj.SupplierName = event ? event.name : ''
  }
  if (key === 'costCurrency') {
    obj.CostCurrencyCode = event ? event.code : '';
  }
  if (key === 'PriceExRate') {
    obj.PriceExchangeRate = event
  }
  if (key === 'PriceSellingExRate') {
    obj.PriceSellingExRate = event
  }
  if (key === 'costCurrencyDefault') {
    obj.CostCurrencyCode = event.code;
    obj.CostExchangeRate = event.exRate
  }
  if (key === 'priceCurrencyDefault') {
    obj.PriceCurrencyCode = event.code;
    obj.PriceExchangeRate = event.exRate
  }
  if (key === 'CostExRate') {
    obj.CostExchangeRate = event
  }
  if (key === 'BackToBack') {
    obj.BackToBack = event.target.checked;
  }

  return obj;
}

export const setBulkEditServiceValue = (bulkEditList: any, key: any, event: any) => {
  if (key === 'supplier') {
    bulkEditList.SupplierName = event ? event.name : ''
    bulkEditList.SupplierCode = event ? event.code : ''
  }
  if (key === 'priceCurrency') {
    bulkEditList.PriceCurrencyCode = event ? event.code : ''
  }
  if (key === 'costCurrency') {
    bulkEditList.CostCurrencyCode = event ? event.code : ''
  }
  if (key === 'startDate') {
    bulkEditList.StartDateTime = event ? moment(event.value).format() : ''
  }
  if (key === 'endDate') {
    bulkEditList.EndDateTime = event ? moment(event.value).format() : ''
  }
  return bulkEditList;
}

export const setBulkEditChargeValue = (bulkEditList: any, key: any, event: any) => {
  if (key === 'billingParty') {
    bulkEditList.BillingPartyCode = event ? event.Code : ''
    bulkEditList.BillingPartyName = event ? event.Node : ''
  }
  if (key === 'taxType') {
    bulkEditList.PriceTaxCode = event ? event.Code : ''
    bulkEditList.PriceTaxName = event ? event.Name : ''
    bulkEditList.PriceTaxPercent = event ? event.Percentage : ''
  }
  return bulkEditList;
}

export const createServiceObj = (list: IServiceAndCharges[], product: IProductDetails, servCharge: IServiceAndCharges[]) => {
  let services = list.map((service: IServiceAndCharges, index: number) => {
    let charges = service.charges.map((charge: QuotationCharge) => {
      return {
        ...charge,
        Id: null
      }
    })
    return {
      ...service,
      service: {
        ...service.service,
        StartDateTime: moment(product?.ETA).format(),
        EndDateTime: moment(product?.ETD).format(),
        RowOrder: servCharge?.length + index + 1,
        IsActive: true,
        Id: null
      },
      charges: [...charges],
    }
  })
  return services
}

export const isDraftQuotation = (quotationDetails: IQuotationDetails) => quotationDetails.QuotationStatus === QuoteStatus.Draft; // check if quotation is in Draft status

function editCharge(list: IServiceAndCharges, bulkListService: QuotationService, bulkListCharge: QuotationCharge) {
  list?.charges?.map((item: QuotationCharge, i: number) => {
    if (bulkListService.PriceCurrencyCode) {
      item.PriceCurrencyCode = bulkListService.PriceCurrencyCode;
      item.PriceExchangeRate = bulkListService.PriceExchangeRate;
      item.PriceExchangeRate = bulkListService.PriceExchangeRate;
    }
    if (bulkListService.CostCurrencyCode) {
      item.CostCurrencyCode = bulkListService.CostCurrencyCode;
      item.CostExchangeRate = bulkListService.CostExchangeRate;
    }
    if (bulkListCharge.BillingPartyCode) {
      item.BillingPartyCode = bulkListCharge.BillingPartyCode;
      item.BillingPartyName = bulkListCharge.BillingPartyName;
      item.AddressFk = bulkListCharge.AddressFk;
    }
    if (bulkListCharge.PriceTaxCode) {
      item.PriceTaxCode = bulkListCharge.PriceTaxCode;
      item.PriceTaxName = bulkListCharge.PriceTaxName;
      item.PriceTaxPercent = bulkListCharge.PriceTaxPercent;
    }
  });
  return {
    ...list,
    service: {
      ...list.service,
      ...bulkListService
    }
  };
}

export function formatQuoteBillingPartyAddress(bp: any) {
  return formatAddress(bp.Address, bp.CityName, bp.CountryName);
}