
import { connect } from 'react-redux';
import { updateCustomerAdditionalDetails, updateCustomerDetails, updateAccountManager, addNewCustomer } from 'Redux/Actions/Customer';
import { updateQuotationDetails } from 'Redux/Actions/Quotation';
import CustomerDetail from './CustomerDetail'
import { createNewCustomer, resetNewCustomerState } from '../../Redux/Actions/Customer';


const mapDispatchToProps = (dispatch: any) => ({
  updateCustomerDetails: (event: any, key: string) => dispatch(updateCustomerDetails(event, key)),
  updateQuotationDetails: (event: any, key: string) => dispatch(updateQuotationDetails(event, key)),
  updateCustomerAdditionalDetails: (data: object) => dispatch(updateCustomerAdditionalDetails(data)),
  updateAccountManager: (payload: string) => dispatch(updateAccountManager(payload)),
  createNewCustomer: (event: any, key: string) => dispatch(createNewCustomer(event, key)),
  addNewCustomer: () => dispatch(addNewCustomer()),
  resetNewCustomerState: () => dispatch(resetNewCustomerState()),
});

const mapStateToProps = (state: any) => ({
  customerDetails: state.customer.customerDetails,
  newCustomer: state.customer.newCustomer,
  customerAdditionalDetails: state.customer.customerAdditionalDetails,
  quotation: state.quotation,
  user: state.user.userDetail
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetail);