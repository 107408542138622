
import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import {
  Overview,
  Container,
  Content,
  QuotationAlert
} from './quotation.styles';
import { selectedBusinessTab } from 'Utils/Quotation'
import CargoDetails from 'Containers/CargoDetails';
import CustomerDetail from 'Containers/CustomerDetail';
import ServiceDetail from 'Containers/ServiceGrid';
import VesselDetail from 'Containers/VesselDetail';
import CrewDetail from 'Containers/CrewDetail';
import BusinessSelection from 'Containers/BusinessSelection';
import FreightDetail from 'Containers/FreightDetails';
import RouteDetails from 'Containers/RouteDetails';
import PegModal from 'Controls/PegModal';
import Loader from 'Components/Loader';

type MyState = {
  errorCount: number;
  completedStatus: any;
  tabOptions: any[]
};

type MyProps = {
  history?: any;
  match?: any;
  selectedTab?: any;
  setQuotationTab?: (tab: string) => void;
  selectedBusiness?: any;
  customerValidation?: any;
  errorCount?: any;
  customerDetails?: any;
  cargoDetails?: any,
  vesselValidation?: any;
  crewDetails?: any;
  enabledRequests?: any;
  vesselDetails?: any;
  cargoValidation?: any;
  serviceList: any;
  optionsValidation?: any;
  optionDetails?: any[];
  createQuotation: (history: any) => void;
  resetCrewState: () => void;
  resetCustomerState: () => void;
  resetNewCustomerState: () => void;
  resetCargoState: () => void;
  resetVesselState: () => void;
  resetQuotationState: () => void;
  containerValidation?: any;
  bulkDetailsValidation?: any;
  packageDetailsValidation?: any;
  setQuotationLoading: (loading: boolean) => void;
  isSaveInProgess: boolean;
  isCargoEnabled: boolean | undefined;
  isCrewEnabled: boolean | undefined;
  isShipSparesEnabled: boolean | undefined;
};

class Quotation extends React.Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      errorCount: 0,
      tabOptions: [],
      completedStatus: {
        'customer': false,
        'vessel': false,
        'freight': false,
        'route': false,
        'cargo': false,
        'crew': false,
        'services': false
      }
    };
  }

  componentWillUnmount() {
    this.resetReduxState();
  }


  resetReduxState = () => {
    const {
      resetVesselState,
      resetCrewState,
      resetCargoState,
      resetCustomerState,
      resetQuotationState,
      resetNewCustomerState,
    } = this.props
    resetVesselState();
    resetCrewState();
    resetCargoState();
    resetCustomerState();
    resetQuotationState();
    resetNewCustomerState();
  }

  saveAs = () => {
    const {
      match,
      history,
      selectedTab,
      setQuotationTab
    } = this.props
    if (selectedTab === 'customer')
      history.push(`${match.path}`)
    setQuotationTab && setQuotationTab('')
  }

  onClickPrevious = () => {
    const {
      selectedTab,
      selectedBusiness,
      setQuotationTab,
      isCargoEnabled,
      isCrewEnabled,
      isShipSparesEnabled,
    } = this.props
    let TAB_OPTIONS = selectedBusinessTab(selectedBusiness, isCargoEnabled, isCrewEnabled, isShipSparesEnabled)
    let tabOption = TAB_OPTIONS.find((item: any, index: number) => item.route === selectedTab);
    const index = TAB_OPTIONS.findIndex((item: any) => item.route === selectedTab);
    let previousTab = TAB_OPTIONS[index - 1]?.route
    if (tabOption && tabOption?.id !== 0) {
      setQuotationTab && setQuotationTab(previousTab)
    }
  }

  onClickNext = () => {
    const {
      selectedTab,
      setQuotationTab,
      selectedBusiness,
      isCargoEnabled,
      isCrewEnabled,
      isShipSparesEnabled
    } = this.props

    const TAB_OPTIONS = selectedBusinessTab(selectedBusiness, isCargoEnabled, isCrewEnabled, isShipSparesEnabled)

    const tabOption = TAB_OPTIONS.find((item: any) => item.route === selectedTab);
    const index = TAB_OPTIONS.findIndex((item: any) => item.route === selectedTab);
    let nextTab = TAB_OPTIONS[index + 1]?.route

    if (tabOption && (tabOption?.id !== TAB_OPTIONS?.length)) {

      const errorCount: any = this.checkValidation(selectedTab)

      //if (!errorCount) {
      if (selectedTab !== 'services') {
        setQuotationTab && setQuotationTab(nextTab)
      } else {
        this.onPostQuotations()
      }
      //}
      this.setState({ errorCount })
    }
    if (!selectedTab) {
      setQuotationTab && setQuotationTab('customer')
    }
  }

  checkValidation = (selectedTab: string) => {
    let {
      customerDetails,
      customerValidation,
      errorCount
    } = this.props
    let { completedStatus } = this.state
    if (selectedTab === 'customer') {
      let validationObject = customerValidation;
      for (let key in validationObject) {
        if (!customerDetails[key]) {
          validationObject[key].error = true
          errorCount += 1;
        }
      }
    } else if (selectedTab === 'vessel') {
      errorCount = this.checkVesselValidation()
    } else if (selectedTab === 'cargo') {
      errorCount = this.checkCargoValidation()
    } else if (selectedTab === 'services') {
      // errorCount = serviceList && this.checkServiceValidation(serviceList)
      errorCount = 0
    } else if (selectedTab === 'crew') {
      errorCount = this.checkCrewValidation()
    }
    if (errorCount) {
      completedStatus[selectedTab] = false
    } else {
      completedStatus[selectedTab] = true
    }
    this.setState({ completedStatus })
    return errorCount
  }

  checkCargoValidation = () => {
    let {
      cargoValidation,
      containerValidation,
      bulkDetailsValidation,
      packageDetailsValidation,
      cargoDetails
    } = this.props
    let errorCount = 0
    for (let key in cargoValidation) {
      if (!cargoDetails[key]) {
        cargoValidation[key].error = true
        errorCount += 1;
      }
    }
    if (cargoDetails.ContainerDetail?.length) {
      cargoDetails.ContainerDetail.forEach((container: any) => {
        for (let key in containerValidation) {
          if (!container[key]) {
            containerValidation[key].error = true
            errorCount += 1;
          }
        }
      })
    }
    if (cargoDetails.PackageDetail?.length) {
      cargoDetails.PackageDetail.forEach((packages: any) => {
        for (let key in packageDetailsValidation) {
          if (!packages[key]) {
            packageDetailsValidation[key].error = true
            errorCount += 1;
          }
        }
      })
    }
    if (cargoDetails.BulkCargoDetail?.length) {
      cargoDetails.BulkCargoDetail.forEach((bulk: any) => {
        for (let key in bulkDetailsValidation) {
          if (!bulk[key]) {
            bulkDetailsValidation[key].error = true
            errorCount += 1;
          }
        }
      })
    }
    return errorCount;
  }

  checkCrewValidation = () => {
    const {
      crewDetails,
      enabledRequests
    } = this.props
    let errorCount = 0
    if (enabledRequests?.length) {
      enabledRequests.forEach((item: any, index: number) => {
        if (item) {
          if (item.crew) {
            if (!crewDetails[index]?.CrewMovement) {
              errorCount += 1;
            }
            if (!crewDetails[index]?.NoOfCrew) {
              errorCount += 1;
            }
          }
          if (item.accomodation) {
            if (!crewDetails[index]?.AccomodationType) {
              errorCount += 1;
            }
            if (!crewDetails[index]?.NoOfNights) {
              errorCount += 1;
            }
          }
          if (item.documentation) {
            if (!crewDetails[index]?.DocumentationServices) {
              errorCount += 1;
            }
          }
          if (item.medical) {
            if (!crewDetails[index]?.MedicalCondition) {
              errorCount += 1;
            }
          }
        }
      })
    }
    return errorCount
  }

  checkVesselValidation = () => {
    let {
      vesselValidation,
      vesselDetails,
      optionDetails,
      optionsValidation
    } = this.props;
    let errorCount = 0
    for (let key in vesselValidation) {
      if (!vesselDetails[key]) {
        vesselValidation[key].error = true
        errorCount += 1;
      }
    }
    optionDetails?.forEach((item: any) => {
      let route = item.RouteDetail[0];
      if (route) {
        for (let key in optionsValidation) {
          if (!route[key]) {
            optionsValidation[key].error = true
            errorCount += 1;
          }
        }
      }
    })
    return errorCount;
  }

  checkServiceValidation = (serviceList: any[]) => {
    let errorCount = 0;
    serviceList.forEach((item: any) => {
      let validationObject: object = item.validation;
      item.charges.forEach((charge: any) => {
        Object.keys(charge.validation).forEach(function (key) {
          if (!charge[key] || !Object.keys(charge[key]).length) {
            errorCount += 1;
          }
        })
      })
      Object.keys(validationObject).forEach(function (key) {
        if (!item[key] || !Object.keys(item[key]).length) {
          errorCount += 1;
        }
      })
    })
    return errorCount;
  }

  onSelectTab = (route: any) => {
    const {
      setQuotationTab
    } = this.props
    setQuotationTab && setQuotationTab(route)
  }

  onPostQuotations = () => {
    const {
      setQuotationLoading,
      createQuotation
    } = this.props
    setQuotationLoading(true)
    createQuotation(this.props.history)
  }

  render() {
    const {
      match,
      selectedTab,
      selectedBusiness,
      isSaveInProgess,
      isCargoEnabled,
      isCrewEnabled,
      isShipSparesEnabled,
    } = this.props
    const {
      errorCount,
      completedStatus
    } = this.state

    return (
      <Overview id="overview-id">
        <Container>
          {
            selectedTab &&
            <Header
              {...this.props}
              match={match}
              isCargoEnabled={isCargoEnabled}
              isCrewEnabled={isCrewEnabled}
              isShipSparesEnabled={isShipSparesEnabled}
              completedStatus={completedStatus}
              selectedTab={selectedTab}
              selectedBusiness={selectedBusiness}
              onClick={(route: any) => this.onSelectTab(route)} />
          }
          <Content selectedTab={selectedTab}>
            {(!selectedTab) && <BusinessSelection />}
            {(selectedTab === 'customer') && <CustomerDetail />}
            {(selectedTab === 'vessel') && <VesselDetail />}
            {(selectedTab === 'cargo') && <CargoDetails />}
            {(selectedTab === 'freight') && <FreightDetail />}
            {(selectedTab === 'route') && <RouteDetails />}
            {(selectedTab === 'crew') && <CrewDetail />}
            {(selectedTab === 'services') && <ServiceDetail />}
          </Content>
          <Footer
            onClickNext={this.onClickNext}
            onClickPrevious={this.onClickPrevious}
            saveAs={this.saveAs}
            selectedTab={selectedTab}
            selectedBusiness={selectedBusiness}
            errorCount={errorCount}
          />
        </Container>
        <PegModal
          isOpen={isSaveInProgess}
          alertModal={true}
          showTemplate={true}
          isCenter={true}>
          <div>
            <QuotationAlert>Creating quotation ...</QuotationAlert>
            <Loader
              size={100}
              color={'green'}
            />
          </div>
        </PegModal>
      </Overview>
    );
  }
}

export default Quotation
