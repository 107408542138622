import { RiCloseFill } from 'react-icons/ri';
import PegInput from 'Controls/Input/PegInputBox';
import Dropdown from 'Controls/Dropdown'
import { ContainerTypes } from 'Constants/DropdownOptions'
import {
  Flex,
  ContainerQuantity,
  ContainerWeight,
  ContainerType,
  Delete
} from '../../cargo.styles'
import { useCallback } from 'react';


interface Prop {
  index: number
  container: any;
  containerDetail?: {},
  handleOnDeleteRow?: Function,
  handleOnChangeType?: Function,
  handleOnValueChange?: Function,
  onDelete: (id: number) => void;
  onUpdate: (key: string, event: any, index: number) => void;
}
const ContainerLine = (props: Prop) => {
  const {
    index,
    onDelete,
    onUpdate,
    container
  } = props;

  const onChange = useCallback((event: any, key: string) => {
    onUpdate(key, event, index)
  }, [container]);

  return (
    <Flex id="containerList">
      <ContainerQuantity>
        <PegInput
          type={'number'}
          id={'txtQuantity'}
          name={'quantity'}
          defaultValue={container?.Quantity}
          value={container?.Quantity}
          isMandatory={true}
          onChange={(event: any) => onChange(event, 'quantity')} />
      </ContainerQuantity>
      <ContainerType>
        <Dropdown
          id={'drpType'}
          isLookup={false}
          dropdownLabel={'name'}
          dropdownValue={'code'}
          defaultValue={container?.ContainerTypeName}
          appendParams={false}
          dropdownOptions={ContainerTypes}
          isMandatory={true}
          domId={"containerList"}
          isMenuPortalTarget={'true'}
          placeHolder="Select Unit of Measures"
          onChangeDropdown={(event: any) => onChange(event, 'containerType')}
        />
      </ContainerType>
      <ContainerWeight>
        <PegInput
          type={'number'}
          id={'txtTotalCargoWeight'}
          name={'totalCargoWeight'}
          isMandatory={true}
          defaultValue={container?.GrossWeight}
          value={container?.GrossWeight}
          onChange={(event: any) => onChange(event, 'cargoWeight')} />
      </ContainerWeight>
      <Delete>
        <button>
          <RiCloseFill
            size="1.2em"
            className="fill-current text-danger group-hover:text-danger-shade"
            onClick={() => onDelete(index)} />
        </button>
      </Delete>
    </Flex>

  );
};

export default ContainerLine;
