import { getBillingPartyName } from 'Utils/Generic';
import { components, ControlProps, OptionProps } from 'react-select';
import BillingPartyWithAddress from './BillingPartyAddress';

export function getValueContainer<T>() {
    const ValueContainer: React.FC<ControlProps<T>> = ({ children, ...props }) => {

        return (
            <components.ValueContainer {...props}>
                {children}
            </components.ValueContainer>
        )
    };

    return ValueContainer;

}

export function getCustomOption<T>(formatBillingPartyAddress: any) {
    const CustomOption: React.FC<OptionProps<T>> = ({ children, data, ...props }) => {
        const bpName = getBillingPartyName(data);

        return (
            <components.Option {...{ children, data, ...props }}>
                <BillingPartyWithAddress name={bpName} address={formatBillingPartyAddress(data)} />
            </components.Option>
        );
    };

    return CustomOption;
}