import * as actionTypes from '../ActionTypes/ActionTypes';
import { RESET_ROUTE_STATE } from "Redux/ActionTypes/ActionTypes";

let initialState = {
  RouteDetails: []
}
export const route = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.ROUTE_DETAILS: {
      return { ...state, RouteDetails: action.data }
    }
    case RESET_ROUTE_STATE:
      return {
        RouteDetails: []
      };
    default:
      return state;
  }
}