import { VoucherType } from "./Constant";

export const paymentTerm: any[] = [
  { code: '001', name: '30' },
  { code: '002', name: '60' },
  { code: '003', name: '70' },
  { code: '004', name: '90' },
];

export const businessArea: any[] = [
  { code: 'shipping', name: 'Shipping' },
  { code: 'logistics', name: 'Logistics' },
  { code: 'marine', name: 'Marine' },
];

export const businessProduct: any[] = [
  { code: 'customs', name: 'Customs' },
  { code: 'freight', name: 'Freight' },
  { code: 'husbandryServices', name: 'Husbandry Services' },
  { code: 'shipAgency', name: 'Ship Agency' },
  { code: 'warehousing', name: 'Warehousing' }
];

export const addressType: any[] = [
  { code: '001', name: 'Billing Address' },
  { code: '002', name: 'Office Address' },
  { code: '003', name: 'Home Address' },
];

export const salesPerson: any[] = [
  { code: '001', name: 'Wellington' },
  { code: '002', name: 'John' },
  { code: '003', name: 'Maxmillian' },
];

export const bankInfo: any[] = [
  { code: '001', name: 'State Bank' },
  { code: '002', name: 'Central Bank' },
  { code: '003', name: 'Emirates Nbd' },
];

export const movementType: any[] = [
  { code: '001', name: 'GAC Dubai' },
  { code: '002', name: 'GAC Singapore' },
  { code: '003', name: 'GAC Norway' },
  { code: '004', name: 'Houston Office' },
];

export const crewMovement: any[] = [
  { name: 'Sign-On' },
  { name: 'Sign-Off' }
];
export const accomodationType: any[] = [
  { name: 'Normal' },
  { name: 'Deluxe' },
  { name: 'Premium' }
];

export const documentType: any[] = [
  { name: "Seamen's Book" },
  { name: 'Passport' },
  { name: "Driver's License" },
  { name: "National Identity Card" },
  { name: "Other" },
];
export const DimensionUOM: any[] = [
  { code: "cm", name: 'cm' },
  { code: "ft", name: 'ft' },
  { code: "mf", name: 'mf' },
  { code: "in", name: 'in' },
  { code: "km", name: 'km' },
  { code: "m", name: 'm' },
  { code: "mi", name: 'mi' },
  { code: "mm", name: 'mm' }];

export const WeightUOM: any[] = [
  { code: "kg", name: 'kg' },
  { code: "lb", name: 'lb' },
  { code: "ton", name: 'ton' },
  { code: "lng", name: 'lng ton' },
  { code: "sht", name: 'sht ton' }];

export const VolumeUOM: any[] = [
  { code: "CBCM", name: 'CBCM' },
  { code: "CBCM", name: 'CBFT' },
  { code: "CBCM", name: 'CBIN' },
  { code: "CBM", name: 'CBM' },
  { code: "CBMI", name: 'CBMI' },
  { code: "CBMI", name: 'CBMI' },
  { code: "imp", name: 'gal (imp)' },
  { code: "kg", name: 'Lit.' },
  { code: "kg", name: 'Ton' }];

export const ContainerTypes: any[] = [
  { code: '10STD', name: '10 STD' },
  { code: '20FTFR', name: '20 FTFR' },
  { code: '20FTOT', name: '20 FTOT' },
  { code: '20FTRE', name: '20 FTRE' },
  { code: '20ISOTANK', name: '20 ISOTANK' },
  { code: '20ISOTNK', name: '20 ISOTNK' },
  { code: '20STD', name: '20 STD' },
  { code: '20TANK', name: '20 TANK' },
  { code: '40FTFR', name: '40 FTFR' },
  { code: '40FTHC', name: '40 FTHC' },
  { code: '40HCNOR', name: '40 HCNOR' },
  { code: '40FTHCR', name: '40 FTHCR' },
  { code: '40FTOT', name: '40 FTOT' },
  { code: '40FTRE', name: '40 FTRE' },
  { code: '40STD', name: '40 STD' },
  { code: '40TANK', name: '40 TANK' },
  { code: '45FTHC', name: '45 FTHC' },
  { code: '45STD', name: '45 STD' },
];

export const CargoType: any[] = [
  { code: 'Bunkers', name: 'Bunkers' },
  { code: 'Chemicals/Vegoils', name: 'Chemicals/Vegoils' },
  { code: 'Containers', name: 'Containers' },
  { code: 'Crude', name: 'Crude' },
  { code: 'Dry Bulk', name: 'Dry Bulk' },
  { code: 'Energy-related', name: 'Energy-related' },
  { code: 'Gas', name: 'Gas' },
  { code: 'General/Project Cargo', name: 'General/Project Cargo' },
  { code: 'Government', name: 'Government' },
  { code: 'Livestock', name: 'Livestock' },
  { code: 'Not Applicable', name: 'Not Applicable' },
  { code: 'Offshore', name: 'Offshore' },
  { code: 'Other', name: 'Other' },
  { code: 'Passengers', name: 'Passengers' },
  { code: 'Products', name: 'Products' },
  { code: 'Special', name: 'Special' },
  { code: 'Vehicles', name: 'Vehicles' },
];

export const CargoOperation: any[] = [
  { code: 'Discharge', name: 'Discharge' },
  { code: 'Load', name: 'Load' },
  { code: 'Load&Discharge', name: 'Load & Discharge' },
  { code: 'Transhipment', name: 'Transhipment' },
];

export const MOVEMENT_TYPE_LIST = [
  { code: '001', name: 'Door to Door' },
  { code: '002', name: 'Port to Door' },
  { code: '003', name: 'Door to Port' },
  { code: '004', name: 'Port to Port' },
];

export const SERVICE_STATUS_OPTIONS = [
  { id: 1, label: 'Open', name: 'open' },
  { id: 2, label: 'Accepted', name: 'accepted' },
  { id: 3, label: 'Completed', name: 'completed' },
  { id: 4, label: 'Reopened', name: 'reopened' },
];

export const BILLING_STATUS_OPTIONS = [
  {
    id: 1,
    label: 'Send for Billing',
    name: 'Send for Billing',
    api: ''
  }
];

export const BILLING_METHOD_OPTIONS = [
  { id: 1, label: 'Cash', name: 'Cash' },
  { id: 2, label: 'Credit', name: 'Credit' },
];

export const VOUCHER_TYPE_OPTIONS = [
  { id: 1, label: 'Cash voucher', code: VoucherType.Cache },
  { id: 2, label: 'Payment Request Voucher', code: VoucherType.PaymentRequest },
];