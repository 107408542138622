
import { useSelector } from "react-redux";
import {
  HeaderOverview
} from "../quotation.styles";
import {
  selectedBusinessTab
} from 'Utils/Quotation';
import Tab from './Tab';

type IProps = {
  onClick: (tab: any) => void;
  match?: any;
  selectedTab?: string;
  selectedBusiness?: any;
  customerDetails?: any;
  vesselDetails?: any;
  cargoDetails?: any;
  serviceList?: any;
  completedStatus?: any;
  isCrewEnabled: boolean | undefined;
  isCargoEnabled: boolean | undefined;
  isShipSparesEnabled: boolean | undefined;
};

const Header = (props: IProps) => {
  let quotationStore = useSelector((state: any) => state.quotation)
  const {
    selectedTab,
    match,
    onClick,
    selectedBusiness,
    customerDetails,
    vesselDetails,
    cargoDetails,
    serviceList,
    completedStatus,
    isCrewEnabled,
    isCargoEnabled,
    isShipSparesEnabled,
  } = props
  let selectedBusinessObject = selectedBusinessTab(selectedBusiness, isCargoEnabled, isCrewEnabled, isShipSparesEnabled)
  return (
    <HeaderOverview>
      {
        selectedBusinessObject?.map((item: any) => {
          let header;
          if (item.route === 'customer' && customerDetails?.CustomerName) {
            header = customerDetails?.CustomerName;
          }
          if (item.route === 'vessel') {
            header = vesselDetails?.VesselName;
          }
          if (item.route === 'cargo') {
            header = cargoDetails?.CommodityType;
          }
          if (item.route === 'services' && serviceList?.length) {
            header = `${serviceList?.length} - service`;
          }
          return (
            <Tab
              key={item.id + item.label}
              label={item.label}
              route={item.route}
              match={match}
              completedStatus={completedStatus[item.route]}
              isTabEnabled={quotationStore[item.enableKey]}
              selectedTab={selectedTab}
              onClick={onClick}
              header={header} />
          )
        })
      }
    </HeaderOverview >
  );
};

export default Header;
