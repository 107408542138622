
import React from 'react';
import InputBox from 'Controls/Input/PegInputBox';
import PegLabel from 'Controls/Label/PegLabel';
import PegTextArea from 'Controls/TextArea/PegTextArea';
import PegDatePicker from 'Controls/DateRange/PegDatePicker';
import { OFFICE_CODE as officeCode } from 'Constants/Constant';
import Dropdown from 'Controls/Dropdown';
import { ICustomerDetail } from 'Model/Order/CustomerDetail';
import ReferenceAgent from 'Api/ReferenceAgent';
import PegChip from './../../Controls/Chip/PegChip';
import { AiOutlinePlusCircle } from 'react-icons/ai'
import {
  paymentTerm,
  addressType,
} from 'Constants/DropdownOptions'
import PegCheckbox from 'Controls/Checkbox/PegCheckbox';
import PegModal from 'Controls/PegModal';
import CreateCustomer from './CreateCustomer/CreateCustomer';

type MyState = {
  customer: ICustomerDetail | {};
  customerDetail: any;
  openModal?: any;
  bank?: any[];
};

type MyProps = {
  customerDetails?: any;
  newCustomer?: any;
  customerAdditionalDetails?: any;
  quotation?: any;
  user?: any;
  createNewCustomer: (event: any, key: string) => void;
  updateCustomerDetails: (event: any, key: string) => void;
  updateQuotationDetails: (event: any, key: string) => void;
  updateCustomerAdditionalDetails: (data: any) => void;
  updateAccountManager: (payload: string) => void;
  addNewCustomer: () => void;
  resetNewCustomerState: () => void;
};
class CustomerDetailComponent extends React.Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      customer: {},
      customerDetail: {},
      openModal: false,
      bank: []
    };
  }

  onUpdateValue = (event: any, key: any) => {
    const {
      updateCustomerDetails,
    } = this.props
    updateCustomerDetails(event, key);
    if (key === 'customerName') {
      if (event?.code) {
        ReferenceAgent.customerDetail(event?.code).then((response: any) => {
          this.setState({ customerDetail: response })
          this.props.updateCustomerAdditionalDetails(response);
          this.props.updateAccountManager(response?.accountManager?.FirstName + ' ' + response?.accountManager?.LastName)
        })
      } else {

      }

    }
  }

  onUpdateQuoteValue = (event: any, key: any) => {
    const {
      updateQuotationDetails
    } = this.props
    updateQuotationDetails(event, key)
  }

  handleModal = () => {
    this.setState({ openModal: true })
  }

  handleClose = () => {
    this.setState({ openModal: false })
    this.props.resetNewCustomerState();
  }

  addNewCustomer = () => {
    this.props.addNewCustomer();
    this.handleClose();
  }

  componentDidMount = () => {
    ReferenceAgent.bankInfo(officeCode).then((res: any) => {
      this.setState({ bank: res })
    })
  }

  render() {
    const {
      customerDetails,
      customerAdditionalDetails,
      quotation,
      user, newCustomer } = this.props;
    const { openModal, bank } = this.state;
    return (
      <div>
        <div className="grid grid-cols-2 gap-12 p-8">
          <div className="col-span-1">
            <PegModal
              isOpen={openModal}
              tertiaryMsg={'Cancel'}
              showTemplate={true}
              buttonText={'Save'}
              modalTitle={'Account Information'}
              tertiaryAction={this.addNewCustomer}
              closeModal={this.handleClose}
              isCenter={true}>
              <div>
                <CreateCustomer createNewCustomer={(event: any, key: any) => this.props.createNewCustomer(event, key)} newCustomer={newCustomer} />
              </div>
            </PegModal>
            <div className="flex flex-row items-center space-y-4">
              <PegLabel id="lblCustomer" name="customer">
                Customer
              </PegLabel>
              <div className="w-8/12">
                <Dropdown
                  id={'customerName'}
                  defaultValue={customerDetails?.CustomerName}
                  placeHolder={'Select Customer'}
                  isLookup={true}
                  dropdownLabel={'name'}
                  dropdownValue={'name'}
                  appendParams={false}
                  isMandatory={true}
                  getFormattedLabel={true}
                  useDebounce={true}
                  showErrorMessage={'mandatory field'}
                  url={`/mdm-search-customer?searchText=`}
                  onChangeDropdown={(event: any, key: any) => this.onUpdateValue(event, 'customerName')}
                />
              </div>
              <button className="-mr-4 relative left-4">
                <AiOutlinePlusCircle
                  size="1.2em"
                  className="fill-current text-primary group-hover:text-primary-shade"
                  onClick={() => this.handleModal()} />
              </button>
            </div>

            <div className="flex flex-row items-center space-y-4">
              <div className='w-4/12'>
              </div>
              <div className="w-8/12">
                {
                  customerAdditionalDetails?.redFlag === "0" && customerDetails?.CustomerName != '' ? (
                    <div className="flex flex-row items-center gap-4 w-full justify-start">
                      <PegChip id="customerChip" label='Green' variant='success' />
                      <p className='italic text-success text-sm'>Please proceed with this customer</p>
                    </div>
                  ) : null
                }
              </div>
            </div>


            <div className="flex flex-row items-center space-y-4">
              <PegLabel id="lblCustomerReference" name="customerReference">
                Customer Reference
              </PegLabel>
              <div className="w-8/12">
                <InputBox
                  defaultValue={customerDetails?.CustomerReference}
                  id={'customer-reference'}
                  key={'customerReference'}
                  onChange={(event: any) => this.onUpdateValue(event, 'reference')}
                />
              </div>
            </div>

            <div className="flex flex-row items-center space-y-4">
              <PegLabel id="lblCustomerContact" name="customerContact">
                Customer Contact
              </PegLabel>
              <div className="w-8/12">
                <InputBox
                  id={'txtCustomerContact'}
                  defaultValue={customerDetails?.CustomerContact}
                  key={'customerContact'}
                  type={'text'}
                  onChange={(event: any) => this.onUpdateValue(event, 'customerContact')}
                />
              </div>
            </div>

            <div className="flex flex-row items-center space-y-4">
              <PegLabel id="lblEmail" name="email">
                Email
              </PegLabel>
              <div className="w-8/12">
                <InputBox
                  id={'customer-email'}
                  defaultValue={customerDetails?.Email}
                  key={'email'}
                  type={'text'}
                  onChange={(event: any) => this.onUpdateValue(event, 'email')}
                />
              </div>
            </div>

            <div className="flex flex-row items-center space-y-4">
              <PegLabel id="lblCountry" name="country">
                Country
              </PegLabel>
              <div className="w-8/12">
                <Dropdown
                  id={'country'}
                  isLookup={true}
                  defaultValue={customerDetails?.CountryName}
                  placeHolder={'Select Country'}
                  dropdownLabel={'name'}
                  dropdownValue={'name'}
                  appendParams={false}
                  url={`/mdm-country?searchText=`}
                  onChangeDropdown={(event: any, key: any) => this.onUpdateValue(event, 'country')}
                />
              </div>
            </div>

            <div className="flex flex-row items-center space-y-4">
              <PegLabel id="lblAddressType" name="addressType">
                Address Type
              </PegLabel>
              <div className="w-8/12">
                <Dropdown
                  id={'customerAddress'}
                  isLookup={false}
                  dropdownLabel={'name'}
                  dropdownValue={'name'}
                  appendParams={false}
                  dropdownOptions={addressType}
                  placeHolder="Select Address"
                  onChangeDropdown={(event: any, key: any) => this.onUpdateValue(event, 'addressType')}
                />
              </div>
            </div>

            <div className="flex flex-row items-center space-y-4">
              <PegLabel id="lblAddress" name="address">
                Address
              </PegLabel>
              <div className="w-8/12">
                <PegTextArea
                  id={'txtAddress'}
                  value={customerDetails?.Address}
                  name={'name'}
                  onChange={(event: any, key: any) => this.onUpdateValue(event, 'address')} />
              </div>
            </div>


            <div className="flex flex-row items-center space-y-4">
              <PegLabel id="lblCreditBalance" name="creditBalance">
                Credit Balance
              </PegLabel>
              <div className="w-8/12">
                <InputBox
                  defaultValue={quotation?.CreditBalance}
                  id={'customer-balance'}
                  key={'creditBalance'}
                  onChange={(event: any, key: any) => this.onUpdateQuoteValue(event, 'balance')}
                />
              </div>
            </div>

            <div className="flex flex-row items-center space-y-4">
              <PegLabel id="lblPaymentTerm" name="paymentTerm">
                Payment Term
              </PegLabel>
              <div className="w-8/12">
                <Dropdown
                  id={'payment'}
                  isLookup={false}
                  dropdownLabel={'name'}
                  dropdownValue={'code'}
                  appendParams={false}
                  dropdownOptions={paymentTerm}
                  placeHolder="Select Payment Term"
                  defaultValue={quotation.PaymentTerm}
                  onChangeDropdown={(event: any, key: any) => this.onUpdateQuoteValue(event, 'paymentTerm')}
                />
              </div>
            </div>

            <div className="flex flex-row items-center space-y-4">
              <PegLabel id="lblApprovalBy" name="approvalBy">
                Approval By
              </PegLabel>
              <div className="w-8/12">
                <Dropdown
                  id={'txtApprovalBy'}
                  defaultValue={customerDetails?.ApprovalBy}
                  placeHolder={'Select Approver Name'}
                  isLookup={true}
                  dropdownLabel={'name'}
                  dropdownValue={'name'}
                  appendParams={false}
                  isMandatory={false}
                  getFormattedLabel={true}
                  useDebounce={true}
                  url={`/mdm-search-customer?searchText=`}
                  onChangeDropdown={(event: any, key: any) => this.onUpdateValue(event, 'approvalBy')}
                />
              </div>
            </div>

            <div className="flex flex-row items-center space-y-4">
              <PegLabel id="lblApprovalByRefNo" name="approvalByRefNo">
                Approval By Ref No.
              </PegLabel>
              <div className="w-8/12">
                <InputBox
                  defaultValue={customerDetails?.ApprovalByRefNo}
                  id={'txtApprovalByRefNo'}
                  key={'approvalByRefNo'}
                  onChange={(event: any, key: any) => this.onUpdateValue(event, 'approvalByRefNo')}
                />
              </div>
            </div>
          </div>

          <div className="col-span-1">
            <div className="grid grid-flow-row">

              <div className="flex flex-row items-center space-y-4">
                <PegLabel id="lblSellingCurrency" name="sellingCurrency" >
                  Selling Currency
                </PegLabel>
                <div className="w-5/12 relative left-8">
                  <Dropdown
                    id={'sellingCurrency'}
                    value={customerDetails?.SellingCurrency}
                    defaultInputValue={user?.CurrencyCode}
                    placeHolder={'Select Currency'}
                    isLookup={false}
                    dropdownLabel={'code'}
                    dropdownValue={'code'}
                    appendParams={false}
                    getFormattedLabel={true}
                    useDebounce={true}
                    url={`/mdm-currency?searchText=&code=`}
                    onChangeDropdown={(event: any, key: any) => this.onUpdateValue(event, 'sellingCurrency')}
                  />
                </div>

                <div className="flex flex-row items-center space-y-4 relative left-6">
                  <PegLabel id="lblExchangeRate" name="exchangeRate" className="w-6/12 text-sm font-normal text-dark relative left-8 -top-2">
                    Exchange Rate
                  </PegLabel>
                  <div className="w-2/12 relative left-8 -top-3">
                    <InputBox
                      defaultValue={customerDetails?.ExchangeRate}
                      id={'txtExchangeRate'}
                      key={'exchangeRate'}
                      onChange={(event: any, key: any) => this.onUpdateQuoteValue(event, 'exchangeRate')}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-row items-center space-y-4">
              <PegLabel id="lblRequestedDate" name="requestedDate">
                Requested Date
              </PegLabel>
              <span className="w-4/12">
                <PegDatePicker
                  id={'requestedDate'}
                  value={quotation?.RequestedDate}
                  onChange={(event: any, key: any) => this.onUpdateQuoteValue(event, 'requestedDate')}
                  name={'requestedDate'}
                  type={'Date'} />
              </span>
            </div>

            <div className="flex flex-row items-center space-y-4">
              <PegLabel id="lblQuotationDate" name="quotationDate">
                Quotation Date
              </PegLabel>
              <span className="w-4/12">
                <PegDatePicker
                  id={'quotationDate'}
                  value={quotation?.QuotationDate}
                  onChange={(event: any, key: any) => this.onUpdateQuoteValue(event, 'quotationDate')}
                  name={'quotationDate'}
                  type={'Date'} />
              </span>
            </div>

            <div className="flex flex-row items-center space-y-4">
              <PegLabel id="lblDueDate" name="dueDate">
                Due Date
              </PegLabel>
              <span className="w-4/12 flex-initial">
                <PegDatePicker
                  id={'dueDate'}
                  value={quotation?.DueDate}
                  onChange={(event: any, key: any) => this.onUpdateQuoteValue(event, 'dueDate')}
                  name={'dueDate'}
                  type={'Date'} />
              </span>
            </div>

            <div className="flex flex-row items-center space-y-4">
              <PegLabel id="lblAccountManager" name="accountManager">
                Account Manager
              </PegLabel>
              <div className="w-8/12">
                <InputBox
                  defaultValue={customerDetails?.AccountManager}
                  id={'txtAccountManager'}
                  key={'accountManager'}
                  value={customerDetails?.CustomerName && customerAdditionalDetails?.accountManager?.FirstName ? (customerAdditionalDetails?.accountManager?.FirstName + ' ' + customerAdditionalDetails?.accountManager?.LastName) : ""}
                  disabled
                  onChange={(event: any, key: any) => this.onUpdateValue(event, 'accountManager')}
                />
              </div>
            </div>

            <div className="flex flex-row items-center space-y-4 ">
              <PegLabel id="lblAdvanceFundingRequired" name="advanceFundingRequired" className="w-4/12 text-sm font-normal text-dark mt-4">
                Advance Funding Required ?
              </PegLabel>
              <span className="w-8/12">
                <PegCheckbox
                  id={'chkAdvanceFundingRequired'}
                  name={'advanceFundRequired'}
                  onChange={(event: any, key: any) => this.onUpdateValue(event, 'advanceFundingRequired')}
                  checked={false}
                  label={''} />
              </span>
            </div>

            {customerDetails?.AdvanceFundingRequired ? (
              <>
                <div className="flex flex-row items-center space-y-4">
                  <PegLabel id="lblBankInfo" name="bankInfo">
                    Bank Info
                  </PegLabel>
                  <div className="w-8/12">
                    <Dropdown
                      id={'drpBankInfo'}
                      value={customerDetails?.BankInfo}
                      defaultInputValue={customerDetails?.BankInfo}
                      placeHolder={'Select Bank Info'}
                      isLookup={false}
                      dropdownLabel={'BankName'}
                      dropdownValue={'BankName'}
                      appendParams={false}
                      dropdownOptions={bank}
                      onChangeDropdown={(event: any, key: any) => this.onUpdateValue(event, 'bankInfo')}
                      disabled={customerDetails?.AdvanceFundingRequired ? false : true}
                    />
                  </div>
                </div>

                <div className="flex flex-row items-center space-y-4 ">
                  <PegLabel id="lblAdvanceFundingRequired" name="advanceFundingRequired">
                    Percentage %
                  </PegLabel>
                  <span className="w-8/12">
                    <InputBox
                      type={'number'}
                      defaultValue={customerDetails?.Percentage}
                      value={customerDetails?.AdvanceFundingRequired ? customerDetails?.Percentage : 0}
                      id={'txtPercentage'}
                      key={'percentage'}
                      onChange={(event: any, key: any) => this.onUpdateValue(event, 'percentage')}
                      disabled={customerDetails?.AdvanceFundingRequired ? false : true}
                    />
                  </span>
                </div>
              </>
            ) : ''}


            <div className="flex flex-row items-center space-y-4">
              <PegLabel id="lblOtherRemarks" name="otherRemarks">
                Other Remarks
              </PegLabel>
              <div className="w-8/12">
                <PegTextArea
                  id={'otherRemarks'}
                  name={'otherRemarks'}
                  onChange={(event: any, key: any) => this.onUpdateQuoteValue(event, 'remarks')} />
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
};

export default CustomerDetailComponent

