import React from 'react';
import {
  Overview,
  LoaderOverview,
  LoaderView
} from './editor.styles'
import {
  LoadScript
} from "@react-google-maps/api";
import MapView from './components/MapView'
import Options from './components/Options'
import PegModal from 'Controls/PegModal';
import Loader from 'Components/Loader';
const mapKey = process.env.REACT_APP_MAPS_KEY;

const libraries: any = ["drawing", "places"];

type MyState = {
  terminalPolygon: any[];
  berthPolygon: any[];
  portPolygon: any[];
  selectedTerminal: any;
  selectedBerth: any;
  isStateUpdated: boolean;
};

type MyProps = {
  match?: any;
  isLoading: boolean;
  portDetails: any;
  terminals: any;
  isPortEdited: boolean;
  center: any;
  isPortEditorSaving: boolean;
  getPortEditorDetails: (id: any) => void;
  updateTerminalDetails: (terminals: any) => void;
  savePortEditorData: () => void;
  setPortEditorSaving: (value: any) => void;
  updatePortDetails: (port: any) => void;
};

class Editor extends React.Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      terminalPolygon: [],
      berthPolygon: [],
      portPolygon: [],
      selectedTerminal: null,
      selectedBerth: null,
      isStateUpdated: false
    };
  }

  componentDidMount() {
    let id = this.props.match.params.id
    this.props.getPortEditorDetails(id);
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const {
      isStateUpdated
    } = this.state
    const {
      portDetails
    } = nextProps;
    if (!isStateUpdated && portDetails) {
      this.setState({ portPolygon: portDetails?.Area, isStateUpdated: true })
    }

  }

  onDrawTerminal = (paths: any[]) => {
    this.setState({ terminalPolygon: paths }, () => this.setTerminal(paths))
  }

  onDrawBerth = (paths: any[]) => {
    this.setState({ berthPolygon: paths }, () => this.setBerth(paths))
  }

  onDrawPort = (paths: any[]) => {
    this.setState({ portPolygon: paths }, () => this.setPort(paths))
  }

  setTerminal = async (paths: any[]) => {
    const {
      terminals,
      updateTerminalDetails
    } = this.props
    const {
      selectedTerminal
    } = this.state
    let terminalList = await terminals.map((terminal: any) => {
      if (terminal?.Terminal?.Id === selectedTerminal?.Id) {
        return {
          ...terminal,
          Terminal: {
            ...terminal.Terminal,
            Area: paths
          }
        }
      } else {
        return terminal
      }
    })
    updateTerminalDetails(terminalList)
  }

  setBerth = async (paths: any[]) => {
    let {
      terminals,
      updateTerminalDetails
    } = this.props
    const {
      selectedTerminal,
      selectedBerth
    } = this.state
    let terminalIndex = await terminals.findIndex((terminal: any) => terminal?.Terminal.Id === selectedTerminal.Id)
    let berthIndex = await terminals[terminalIndex]?.Berth.findIndex((berth: any) => berth.Id === selectedBerth.Id)
    let terminalsList = terminals
    terminalsList[terminalIndex].Berth[berthIndex].Area = paths
    updateTerminalDetails(terminalsList)
  }

  setPort = (paths: any[]) => {
    const {
      updatePortDetails,
      portDetails
    } = this.props
    let port = portDetails
    port.Area = paths;
    updatePortDetails(port)
  }

  updateSelectedBerth = (value: any) => {
    this.setState({
      selectedBerth: value,
      berthPolygon: value?.Area ? value?.Area : [],
    })
  }

  updateSelectedTerminal = async (value: any) => {
    const {
      terminals
    } = this.props
    let terminalPolygon = await terminals.find((terminal: any) => terminal?.Terminal.Id === value.Id)
    this.setState({
      selectedTerminal: value,
      berthPolygon: [],
      terminalPolygon: terminalPolygon?.Terminal?.Area ? terminalPolygon?.Terminal?.Area : [],
      selectedBerth: null
    })
  }

  onSubmit = () => {
    this.props.savePortEditorData()
  }

  onSubmitReset = () => {
    let id = this.props.match.params.id
    this.props.getPortEditorDetails(id);
    this.setState({
      terminalPolygon: [],
      berthPolygon: [],
      portPolygon: [],
      selectedTerminal: null,
      selectedBerth: null,
    })
  }

  render() {
    const {
      isLoading,
      terminals,
      portDetails,
      isPortEdited,
      center,
      isPortEditorSaving
    } = this.props

    const {
      selectedTerminal,
      selectedBerth,
      berthPolygon,
      terminalPolygon,
      portPolygon
    } = this.state

    if (isLoading) {
      return (
        <LoaderOverview>
          <Loader gridLoader={true} color={'#aacbe9'} />
        </LoaderOverview>
      )
    }

    return (
      <Overview>
        <Options
          terminals={terminals}
          portDetails={portDetails}
          terminalPolygon={terminalPolygon}
          selectedTerminal={selectedTerminal}
          selectedBerth={selectedBerth}
          isValidated={isPortEdited}
          onSubmitSave={this.onSubmit}
          onSubmitReset={this.onSubmitReset}
          updateSelectedTeminal={(value: any) => this.updateSelectedTerminal(value)}
          updateSelectedBerth={(value: any) => this.updateSelectedBerth(value)}
        />
        <LoadScriptComponent
          id="script-loader"
          googleMapsApiKey={mapKey}
          libraries={libraries}
          language="en"
          version="weekly"
          region="us">
          <MapView
            center={center}
            selectedTerminal={selectedTerminal}
            selectedBerth={selectedBerth}
            terminalPolygon={terminalPolygon}
            berthPolygon={berthPolygon}
            portPolygon={portPolygon}
            onDrawPort={(path: any) => this.onDrawPort(path)}
            onDrawTerminal={(path: any) => this.onDrawTerminal(path)}
            onDrawBerth={(path: any) => this.onDrawBerth(path)} />
        </LoadScriptComponent>
        <PegModal
          isOpen={isPortEditorSaving}
          alertModal={true}
          showTemplate={true}
          isCenter={true}>
          <LoaderView>
            <div>Saving Port Area</div>
            <Loader
              size={100}
              color={'blue'}
            />
          </LoaderView>
        </PegModal>
      </Overview>
    )
  }
}

class LoadScriptOnlyIfNeeded extends LoadScript {
  componentDidMount() {
    const cleaningUp = true;
    const isBrowser = typeof document !== "undefined"; // require('@react-google-maps/api/src/utils/isbrowser')
    const isAlreadyLoaded =
      window.google &&
      window.google.maps &&
      document.querySelector("body.first-hit-completed"); // AJAX page loading system is adding this class the first time the app is loaded
    if (!isAlreadyLoaded && isBrowser) {
      // @ts-ignore
      if (window.google && !cleaningUp) {
        return;
      }

      this.isCleaningUp().then(this.injectScript);
    }

    if (isAlreadyLoaded) {
      this.setState({ loaded: true });
    }
  }
}

const LoadScriptComponent: any = LoadScriptOnlyIfNeeded;

export default Editor

