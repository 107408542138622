import axios from "axios";
import { IParams } from "Model/Common/types";
import { QuotationCharge } from "Model/QuotationGrid/QuotationCharge";
import { QuotationService } from "Model/QuotationGrid/QuotationService";
import { IProduct, IQuotationObject, IServiceAndCharges } from "Model/QuotationGrid/types";
import { toast } from "react-toastify";
import {
  SET_QUOTATION_SERVICES,
  SET_QUOTATION_ERROR,
  UPDATE_QUOTATION_SERVICE,
  UPDATE_QUOTATION_CHARGE,
  ON_ADD_QUOTATION_CHARGE,
  ON_ADD_QUOTATION_SERVICE,
  ON_DELETE_QUOTATION_SERVICE,
  ON_DELETE_QUOTATION_CHARGE,
  IS_QUOTATION_SERVICES_SAVING,
  ON_QUOTATION_SAVED,
  ON_BULK_QUOTATION_UPDATE,
  ON_IMPORT_TEMPLATE,
  UPDATE_ON_APPLY_TARIFF_QUOTATION,
  BULK_DELETE_QUOTATION_SERVICES,
  UPDATE_QUOTE_CHARGE_VALIDATION,
  UPDATE_QUOTATION_SERVICES_DETAILS,
} from "../ActionTypes/ActionTypes";

export function getQuotationServices(params: IParams) {
  return (dispatch: (arg: any) => void) => {
    return axios.get(`qtn-getServicesAndCharges?QuotationId=${params.id}`).then((response: any) => {
      if (response.data) {
        dispatch(setJobGridData(response.data));
      } else {
        dispatch(setError(response.data));
      }
    })
  }
}

export function saveQuotationServices(params: IQuotationObject) {
  return (dispatch: (arg: any) => void) => {
    dispatch(isQuotationSaving(true))
    const parsedProducts = params.Products.map((product: IProduct) => ({
      ...product,
      ServiceAndCharges: product.ServiceAndCharges.map((serviceAndCharge) => ({
        ...serviceAndCharge,
        charges: serviceAndCharge.charges.map((charge: QuotationCharge) => ({
          ...charge,
          RowOrder: charge.RowOrder === 0 ? 0 : serviceAndCharge.charges.indexOf(charge) + 1,
        }))
      }))
    }))

    const parsedParams = {
      ...params,
      Products: parsedProducts
    }

    return axios.post(`qtn-postServicesCharges`, {
      quotations: parsedParams
    }).then((response: any) => {
      if (response.data?.data) {
        dispatch(setJobGridData(response.data?.data));
        dispatch(onQuotationSaved(true));
      }
      setTimeout(() => {
        dispatch(onQuotationSaved(false));
        dispatch(isQuotationSaving(false));
      }, 1500);

    })
      .catch((err) => {
        let message = "Error occured";
        if (err.response) {
          if (Array.isArray(err.response.data)) {
            if (err.response.data.length > 0)
              message = err.response.data.join(", ");
          }
          else {
            message = err.response.data;
          }
        }
        else {
          message = "Network error.Please try again.";
        }
        toast.error(message, {
          position: "top-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
          style: { width: '400px' },
        })
        dispatch(isQuotationSaving(false))
      })
  }
}

function setJobGridData(response: any) {
  return {
    type: SET_QUOTATION_SERVICES,
    response
  }
}

function setError(response: any) {
  return {
    type: SET_QUOTATION_ERROR,
    response
  }
}
function isQuotationSaving(isSaving: boolean) {
  return {
    type: IS_QUOTATION_SERVICES_SAVING,
    isSaving
  }
}
function onQuotationSaved(isSaving: boolean) {
  return {
    type: ON_QUOTATION_SAVED,
    isSaving
  }
}

export function onUpdateService(service: QuotationService, serviceIndex: number, selectedProduct: IProduct) {
  return {
    type: UPDATE_QUOTATION_SERVICE,
    service,
    serviceIndex,
    selectedProduct
  }
}

export function onUpdateCharge(charge: QuotationCharge, chargeIndex: number, serviceIndex: number, selectedProduct: IProduct) {
  return {
    type: UPDATE_QUOTATION_CHARGE,
    charge,
    chargeIndex,
    serviceIndex,
    selectedProduct
  }
}

export function onAddService(selectedProduct: IProduct) {
  return {
    type: ON_ADD_QUOTATION_SERVICE,
    selectedProduct
  }
}

export function onDeleteService(selectedProduct: IProduct, serviceIndex: number) {
  return {
    type: ON_DELETE_QUOTATION_SERVICE,
    selectedProduct,
    serviceIndex
  }
}

export function onAddCharge(selectedProduct: IProduct, serviceIndex: number) {
  return {
    type: ON_ADD_QUOTATION_CHARGE,
    selectedProduct,
    serviceIndex
  }
}

export function onDeleteCharge(selectedProduct: IProduct, serviceIndex: number, chargeIndex: number) {
  return {
    type: ON_DELETE_QUOTATION_CHARGE,
    selectedProduct,
    serviceIndex,
    chargeIndex
  }
}

export function onBulkUpdate(services: IProduct[]) {
  return {
    type: ON_BULK_QUOTATION_UPDATE,
    services
  }
}

export function onImportTemplate(template: IServiceAndCharges[], selectedProduct: IProduct) {
  return {
    type: ON_IMPORT_TEMPLATE,
    template,
    selectedProduct
  }
}

export function onUpdateTariff(selectedProduct: IProduct, servAndChar: IServiceAndCharges[]) {
  return {
    type: UPDATE_ON_APPLY_TARIFF_QUOTATION,
    selectedProduct,
    servAndChar
  }
}

export function onMultiDeleteServices(selectedList: number[], isAllSelected: boolean, selectedProduct: IProduct) {
  return {
    type: BULK_DELETE_QUOTATION_SERVICES,
    selectedList,
    isAllSelected,
    selectedProduct
  }
}

export function updateChargeValidation(validationKey: string, chargeCode: string, value: boolean) {
  return {
    type: UPDATE_QUOTE_CHARGE_VALIDATION,
    validationKey,
    chargeCode,
    value
  }
}

export function onUpdateQuotationServiceDetails(serviceAndCharges: IServiceAndCharges, selectedProduct: IProduct, serviceIndex: number) {
  return {
    type: UPDATE_QUOTATION_SERVICES_DETAILS,
    serviceAndCharges,
    selectedProduct,
    serviceIndex,
  };
}