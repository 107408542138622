import styled from "styled-components";

interface IStylesProps {
  isHeader?: boolean;
}
export const Row = styled.div<IStylesProps>`
  display: flex;
  font-size: 12px;
  text-align: center;
  padding 4px;
  justify-content: center;
  align-items: center;
  height: 36px;
  background-color: ${(props) => (props.isHeader ? '#C1C1C1' : '#FFF')};
  border-bottom: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
`;

export const Overview = styled.div`
  width: 90vw;
  height: 90vh;
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  padding-right: 16px;
  border-bottom: 1px solid #C1C1C1;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  padding-right: 16px;
  border-top: 1px solid #C1C1C1;
`;

export const Content = styled.div`
    height: calc(90vh - 100px);
    overflow: auto;
    padding 24px;
`;
export const GridOverview = styled.div`
  border: 1px solid #C1C1C1;
  overflow: scroll;
  padding-bottom: 12px;
`;

export const ListOverview = styled.div`
  display: flex;
`;

export const HeaderOverview = styled.div`
  display: flex;
`;
export const Id = styled(Row)`
  min-width: 50px;
`;
export const Version = styled(Row)`
  min-width: 100px;
`;
export const PriceSummary = styled(Row)`
  min-width: 100px;
`;
export const Party = styled(Row)`
  min-width: 200px;
`;
export const BillingAddress = styled(Row)`
  min-width: 200px;
`;
export const TotalNetPrice = styled(Row)`
  min-width: 150px;
`;
export const TotalRequestedPrice = styled(Row)`
  min-width: 200px;
`;
export const FundsReceived = styled(Row)`
  min-width: 150px;
`;
export const RequestedAmount = styled(Row)`
  min-width: 150px;
`;
export const RequestedPerc = styled(Row)`
  min-width: 150px;
`;
export const BankAccount = styled(Row)`
  min-width: 200px;
`;
