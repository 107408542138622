import axios from "axios";
import { IParams } from "Model/Common/types";
import { ICharge } from "Model/Job/JobCharge";
import { IService } from "Model/Job/JobService";
import { IJob, IJobDetails, IServicesAndCharges, ITemplate } from "Model/Job/types";
import { toast } from "react-toastify";
import {
  SET_JOB_GRID,
  SET_ERROR,
  ADD_SERVICES_JOB_GRID,
  DELETE_SERVICES_JOB_GRID,
  ADD_CHARGES_JOB_GRID,
  DELETE_CHARGES_JOB_GRID,
  BULK_DELETE_SERVICES,
  ON_ADD_SERVICE_TEMPLATE,
  UPDATE_JOB_CHARGES,
  UPDATE_JOB_SERVICES,
  ON_SAVE_LOADING,
  ON_SAVED,
  UPDATE_JOB_SERVICES_DETAILS,
  ON_BULK_UPDATE,
  SET_JOB_GRID_LOADING,
  SET_SAVE_DISABLED,
  ADD_SERVICE_LOADING,
  ADD_CHARGE_LOADING,
  UPDATE_JOB_GRID,
  SET_VOUCHER_LIST,
  UPDATE_ON_APPLY_TARIFF,
  RESET_JOB_SERVICES,
  UPDATE_EDIT_JOB_SERVICES,
  SET_REFRESH_MESSAGE,
  SET_EXCHANGE_RATE,
  UPDATE_JOB_CHARGE_VALIDATION
} from "../ActionTypes/ActionTypes";
import { VoucherType } from "Constants/Constant";

function setDataToStore(response: IJob, dispatch: any, func: any) {
  if (response?.ServicesAndCharges?.length > 20) {
    let data = { ...response };
    let updatedList: IServicesAndCharges[] = [];
    let removedList: IServicesAndCharges[] = [];
    removedList = data?.ServicesAndCharges.slice(0, 20);
    updatedList = data?.ServicesAndCharges.splice(20);
    let updatedRes = {
      ...response,
      ServicesAndCharges: [...updatedList]
    }
    let res = {
      ...response,
      ServicesAndCharges: [...removedList]
    }
    dispatch(func(res));
    setTimeout(() => {
      setDataToStore(updatedRes, dispatch, func)
    }, 5000)

  } else {
    dispatch(func(response));
  }
}

export function getJobGridServices(params: IParams) {
  return (dispatch: (arg: any) => void) => {
    return axios.get(`jobServices-getJobServicesByLocalJobId?jobNumber=${params.id}&opId=${params.opId ? params.opId : ''}`).then((response: any) => {
      if (response.data) {
        let responseData = response.data;
        const sortedJobGridData = { ...responseData, ServicesAndCharges: responseData.ServicesAndCharges }
        setDataToStore(sortedJobGridData, dispatch, setJobGridData)
      } else {
        dispatch(setError(response.data));
      }

    }).catch((err) => {
      let message = "Please Reload the window";
      if (err.response) {
        if (Array.isArray(err.response.data)) {
          if (err.response.data.length > 0)
            message = err.response.data.join(", ");
        }
        else {
          message = err.response.data;
        }
      }
      else {
        message = "Network error.Please try again.";
      }
      toast.error(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        style: { width: '400px' },
      })
      dispatch(setError(true))
    })
  }
}

export function getVoucherList(companyCode: string) {
  return (dispatch: (arg: any) => void) => {
    return axios.get(`/getCashPaymentVoucher?Status=Open,Sent,Updated&companyCode=${companyCode}`).then((response: any) => {
      if (response.data) {
        const voucherList = response.data.filter((voucher: any) => {
          return voucher.VoucherType !== VoucherType.PaymentRequest;
        })
        dispatch(setVoucherList(voucherList));
      }
    })
  }
}

export function getExchangeRate(jobDetail: IJobDetails) {
  return (dispatch: (arg: any) => void) => {
    return axios.get(`/jobService-getAllCurrencyExchangeRates?company-code=${jobDetail.CompanyCode}`).then((response: any) => {
      if (response.data) {
        dispatch(setExchangeRate(response.data));
      }
    })
  }
}

export function onAddServiceTemplate(list: ITemplate[]) {
  return {
    type: ON_ADD_SERVICE_TEMPLATE,
    list
  }
}

function setJobGridData(response: any) {
  return {
    type: SET_JOB_GRID,
    response
  }
}

function updateJobGridData(response: any) {
  return {
    type: UPDATE_JOB_GRID,
    response
  }
}

function setVoucherList(res: any) {
  return {
    type: SET_VOUCHER_LIST,
    res
  }
}

function setExchangeRate(exchangeRate: any) {
  return {
    type: SET_EXCHANGE_RATE,
    exchangeRate
  }
}

function setError(error: any) {
  return {
    type: SET_ERROR,
    error
  }
}

export function addServices() {
  return {
    type: ADD_SERVICES_JOB_GRID
  }
}

export function deleteServices(serviceIndex: number) {
  return {
    type: DELETE_SERVICES_JOB_GRID,
    serviceIndex
  }
}

export function addCharges(serviceIndex: number) {
  return {
    type: ADD_CHARGES_JOB_GRID,
    serviceIndex
  }
}

export function deleteCharges(serviceIndex: number, chargeIndex: number) {
  return {
    type: DELETE_CHARGES_JOB_GRID,
    serviceIndex,
    chargeIndex
  }
}

export function onMultiDeleteServices(list: number[]) {
  return {
    type: BULK_DELETE_SERVICES,
    list
  }
}

export function onUpdateService(service: IService, serviceIndex: number) {
  return {
    type: UPDATE_JOB_SERVICES,
    service,
    serviceIndex
  }
}

export function onUpdateEditService(serviceObj: IService, serviceIndex: number) {
  return {
    type: UPDATE_EDIT_JOB_SERVICES,
    serviceObj,
    serviceIndex
  }
}

export function onUpdateServiceDetails(service: IService, serviceIndex: number) {
  return {
    type: UPDATE_JOB_SERVICES_DETAILS,
    service,
    serviceIndex
  }
}

export function onUpdateCharge(charge: ICharge, serviceIndex: number, chargeIndex: number) {
  return {
    type: UPDATE_JOB_CHARGES,
    charge,
    serviceIndex,
    chargeIndex
  }
}

export function onUpdateTariff(serviceAndCharges: IServicesAndCharges[]) {
  return {
    type: UPDATE_ON_APPLY_TARIFF,
    serviceAndCharges
  }
}

export function resetServiceAndCharges() {
  return {
    type: RESET_JOB_SERVICES
  }
}

export function onSaveJobList(services: IServicesAndCharges[], jobDetails: IJobDetails, jobId: string, opId: number) {
  return (dispatch: (arg: any) => void) => {
    dispatch(setSaveLoading(true))
    return axios.post(`/jobServices-saveJobServices?jobNumber=${jobId}&opId=${opId ? opId : ''}`, {
      services: services,
      JobDetails: jobDetails
    }).then((response: any) => {

      dispatch(resetServiceAndCharges())
      let responseData = response.data;
      const sortedJobGridData = { ...responseData, ServicesAndCharges: responseData.ServicesAndCharges }
      setDataToStore(sortedJobGridData, dispatch, updateJobGridData)

      dispatch(setSaved(true));
      setTimeout(() => {
        dispatch(setSaveLoading(false))
        dispatch(setSaved(false));
      }, 1500);

    }).catch((err) => {

      let message = "Error occured";
      if (err.response) {
        if (Array.isArray(err.response.data)) {
          if (err.response.data.length > 0)
            message = err.response.data.join(", ");
        }
        else {
          message = err.response.data;
        }
      }
      else {
        message = "Network error.Please try again.";
      }

      toast.error(message, {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        style: { width: '400px' },
      })

      dispatch(setSaveLoading(false))
      dispatch(setSaveDisabled(true));

    })
  }
}

function setSaveLoading(value: boolean) {
  return {
    type: ON_SAVE_LOADING,
    value
  }
}

function setSaved(value: boolean) {
  return {
    type: ON_SAVED,
    value
  }
}

function setSaveDisabled(value: boolean) {
  return {
    type: SET_SAVE_DISABLED,
    value
  }
}

export function setAddServiceLoading(value: boolean) {
  return {
    type: ADD_SERVICE_LOADING,
    value
  }
}

export function setAddChargeLoading(value: boolean) {
  return {
    type: ADD_CHARGE_LOADING,
    value
  }
}



export function onBulkUpdate(services: IServicesAndCharges[]) {
  return {
    type: ON_BULK_UPDATE,
    services
  }
}

function setLoading(isLoading: boolean) {
  return {
    type: SET_JOB_GRID_LOADING,
    isLoading
  }
}

export function undoChanges(params: IParams) {
  return (dispatch: (arg: any) => void) => {
    dispatch(setLoading(true))
    dispatch(resetServiceAndCharges())
    dispatch(getJobGridServices(params))
  }
}

export function updateChargeValidation(validationKey: string, chargeCode: string, value: boolean) {
  return {
    type: UPDATE_JOB_CHARGE_VALIDATION,
    validationKey,
    chargeCode,
    value
  }
}