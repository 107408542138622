
import styled from 'styled-components';

interface Ivalidator {
  showValidator?: any;
  children?: React.ReactNode;
}
export default function Validator(props: Ivalidator) {
  // falsfy condition should be revisited
  const {
    showValidator
  } = props;
  return (
    <div data-testid="validator">
      {props.children}
      {(showValidator === false) ?
        <ErrorMessage> </ErrorMessage>
        :
        <ErrorMessage data-testid="errorMessage" />}
    </div>
  );
}

const ErrorMessage = styled.div`
          color: red;
          font-size: 10px;
          `


