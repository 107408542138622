import { useIsAuthenticated } from "@azure/msal-react";
import React, { useEffect } from "react";
import { Route, RouteComponentProps, RouteProps, Redirect } from "react-router-dom";
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest, setToken, setUserAccount } from '../Utils/AuthConfig';
import { Loading } from "./Loading";
import Login from "Containers/LoginPage/Login";


interface Props extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}
export const ErrorComponent = () => {
  return <div >Error component...</div>
}

export default function PrivateRoute({ component: Component, ...rest }: Props) {
  const { instance, inProgress, accounts } = useMsal();
  const isSSOEnabled = process.env.REACT_APP_SSO_ENABLED;
  const isAuthenticated = useIsAuthenticated();
  // const isAuthenticated = true;
  const authRequest = {
    ...loginRequest
  };

  /* istanbul ignore next */
  useEffect(() => {
    if (isSSOEnabled == "true") {
      const accessTokenRequest = {
        scopes: ["api://54b438d5-7fa9-418e-be83-2f00c59edb83/Api"],
        // account: accounts[0]
      }
      if (inProgress === InteractionStatus.None) {
        instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
          // Acquire token silent success
          // let accessToken = accessTokenResponse.accessToken;
          // Setting Token 
          // setToken('accessToken', accessToken);
          setUserAccount('userName', accessTokenResponse.account?.username!);

        }).catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
          }
        })
      }
    }
  }, [instance, accounts, inProgress]);

  /* istanbul ignore next */
  return (
    isSSOEnabled == "true" ? <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}>
      <div>
        <Route
          {...rest}
          render={
            (props) => isAuthenticated
              ?
              <Component {...props} />
              :
              <Login />
          } />
      </div>
    </MsalAuthenticationTemplate> : (
      <div>
        <Route
          {...rest}
          render={
            (props) => true
              ?
              <Component {...props} />
              :
              <Redirect to="/login" />
          } />
      </div>
    )
  )
}