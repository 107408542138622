import { IDropdownOptions } from "Model/Common/types";

import { IOPTags, IQuotationTariffDetails } from "./types";

export interface QuotationCharge {
  AddressFk?: string,
  BillingPartyCode?: string;
  BillingPartyName?: string;
  Code: string;
  CostCurrencyCode?: string;
  CostExchangeRate?: null | number;
  CostTaxCode: string;
  CostTaxAmount?: string;
  CostTaxPercent?: string;
  CreatedAt?: string;
  CreatedBy?: string;
  CustomerServiceName?: string;
  GSServiceCode?: string | null;
  GSServiceName?: string | null;
  GSServices?: IDropdownOptions[];
  ExternalRemarks?: string;
  Id?: null | number;
  InternalRemarks?: string;
  IsActive?: boolean;
  IsCharge?: boolean;
  IsFromMaster?: boolean;
  ModifiedAt?: string;
  ModifiedBy?: string;
  Name?: string;
  NetPrice?: null | number;
  NetPriceWithDiscount?: null | number;
  NetPriceWithDiscountTax?: null | number;
  OperatingIncomeActual?: any;
  OPTags?: null | IOPTags;
  ParentServiceCode?: string;
  ParentServiceFk?: null | number;
  PriceCurrencyCode?: string;
  PriceExchangeRate?: null | number;
  PriceSellingExRate?: null | number,
  PriceTaxAmount?: null | number;
  PriceTaxCode?: string;
  PriceTaxName?: string;
  PriceTaxPercent?: null | number;
  Quantity?: number;
  RowOrder?: number;
  TariffDetails?: IQuotationTariffDetails;
  TotalEstimatedCost?: null | number;
  TotalPriceAmount?: null | number;
  UnitCost?: null | number;
  UnitPrice?: null | number;
  UomCode?: string;
  UomName?: string;
}
export class Charge implements QuotationCharge {
  AddressFk?: string;
  BillingPartyCode?: string;
  BillingPartyName?: string;
  Code: string;
  CostCurrencyCode?: string;
  CostExchangeRate?: null | number;
  CostTaxCode: string;
  CostTaxAmount?: string;
  CostTaxPercent?: string;
  CreatedAt: string;
  CreatedBy: string;
  CustomerServiceName?: string;
  ExternalRemarks?: string;
  GSServiceCode?: string | null;
  GSServiceName?: string | null;
  GSServices?: IDropdownOptions[];
  Id?: null | number;
  InternalRemarks?: string;
  IsActive?: boolean;
  IsCharge?: boolean;
  IsFromMaster?: boolean;
  ModifiedAt: string;
  ModifiedBy: string;
  Name?: string;
  NetPrice?: null | number;
  NetPriceWithDiscount?: null | number;
  NetPriceWithDiscountTax?: null | number;
  OperatingIncomeActual?: any;
  OPTags?: null | IOPTags;
  ParentServiceCode?: string;
  ParentServiceFk?: null | number;
  PriceCurrencyCode?: string;
  PriceExchangeRate?: null | number;
  PriceSellingExRate: null | number;
  PriceTaxAmount?: null | number;
  PriceTaxCode?: string;
  PriceTaxName?: string;
  PriceTaxPercent?: null | number;
  Quantity?: number;
  RowOrder?: number;
  TariffDetails: IQuotationTariffDetails;
  TotalEstimatedCost?: null | number;
  TotalPriceAmount: null | number;
  UnitCost?: null | number;
  UnitPrice?: null | number;
  UomCode?: string;
  UomName?: string;

  constructor() {
    this.AddressFk = "";
    this.BillingPartyCode = "";
    this.BillingPartyName = "";
    this.Code = "";
    this.CostCurrencyCode = "";
    this.CostExchangeRate = null;
    this.CostTaxCode = "";
    this.CreatedAt = "";
    this.CreatedBy = "";
    this.CostExchangeRate = null;
    this.CreatedAt = "";
    this.CreatedBy = "";
    this.CustomerServiceName = "";
    this.ExternalRemarks = "";
    this.GSServiceCode = "";
    this.GSServiceName = "";
    this.GSServices = [];
    this.InternalRemarks = "";
    this.IsActive = true;
    this.IsCharge = true;
    this.ModifiedAt = "";
    this.ModifiedBy = "";
    this.ModifiedAt = "";
    this.ModifiedBy = "";
    this.Name = "";
    this.NetPrice = null;
    this.OperatingIncomeActual = "";
    this.OPTags = null;
    this.ParentServiceCode = "";
    this.ParentServiceFk = null;
    this.ParentServiceFk = null;
    this.PriceCurrencyCode = "";
    this.PriceExchangeRate = null;
    this.PriceSellingExRate = null;
    this.PriceExchangeRate = null;
    this.PriceSellingExRate = null;
    this.PriceTaxAmount = null;
    this.Quantity = 1;
    this.Id = null;
    this.RowOrder = 0;
    this.PriceTaxPercent = 0;
    this.PriceTaxCode = "";
    this.PriceTaxName = "";
    this.TariffDetails = {
      TariffDetailId: null,
      ServiceDetailFk: null,
      TariffAppliedOn: "",
      TariffAppliedBy: "",
      TariffEditedBy: "",
      TariffEditedOn: "",
      // IsTariffErrored: false,
      TariffBreakdown: "",
      CreatedAt: "",
      CreatedBy: "",
      ModifiedAt: "",
      ModifiedBy: "",
      // IsActive: true,
      ErrorMessage: ""
    }
    this.TotalEstimatedCost = null;
    this.TotalPriceAmount = null;
    this.UnitCost = null;
    this.UnitPrice = null;
    this.UomCode = "";
    this.UomName = "";
  }
}




