import {
  TAB_OPTIONS,
  TAB_OPTIONS_LOGISTICS,
  TAB_OPTIONS_SHIPPING
} from 'Constants/Constant';
import { CustomerDetail } from 'Model/Order/CustomerDetail';
import { Quotation } from 'Model/Order/Quotation';
import { VesselDetail } from 'Model/Order/VesselDetail';
import { CrewDetails } from 'Model/Order/CrewDetail';
import { CreateCustomer } from 'Model/Order/CreateCustomer';
import { OptionDetail } from 'Model/Order/OptionDetail';

export const selectedBusinessTab = (selectedBusiness: string[], isCargoEnabled = false, isCrewEnabled = false, isShipSparesEnabled = false) => {
  let selectedBusinessObject = TAB_OPTIONS;

  let businessAreaLogistic = selectedBusiness.some((el: any) => el.AreaName === 'logistics');
  let businessAreaShipping = selectedBusiness.some((el: any) => el.AreaName === 'shipping');
  if (selectedBusiness.length === 1 && businessAreaLogistic) {
    selectedBusinessObject = TAB_OPTIONS_LOGISTICS
  }

  if (selectedBusiness.length === 1 && businessAreaShipping) {
    selectedBusinessObject = TAB_OPTIONS_SHIPPING
    if (isCargoEnabled) {
      selectedBusinessObject = selectedBusinessObject.filter(item => item.route !== "crew")
    }
    if (isCrewEnabled) {
      selectedBusinessObject = selectedBusinessObject.filter(item => item.route !== "cargo")
    }
    if (isShipSparesEnabled) {
      selectedBusinessObject = selectedBusinessObject.filter(item => item.route !== "crew")
    }
    if (isCargoEnabled && isCrewEnabled) {
      selectedBusinessObject = TAB_OPTIONS_SHIPPING
    }
    if (isShipSparesEnabled && isCrewEnabled) {
      selectedBusinessObject = TAB_OPTIONS_SHIPPING
    }
  }
  return selectedBusinessObject;
}

export const setQuotationSelectionValue = (obj: Quotation, key: any, event: any) => {
  if (key === 'balance') {
    obj.CreditBalance = event.target.value
  }

  if (key === 'paymentTerm') {
    obj.PaymentTerm = event.name
  }

  if (key === 'quotationDate') {
    obj.QuotationDate = event.target.value
  }

  if (key === 'requestedDate') {
    obj.RequestedDate = event.target.value
  }

  if (key === 'dueDate') {
    obj.DueDate = event.target.value
  }

  if (key === 'remarks') {
    obj.Remarks = event.target.value
  }

  return obj;

}

export const setNewCustomer = (obj: CreateCustomer, key: any, event: any) => {
  if (key === "accountName") {
    obj.LegalName = event.target.value
  }
  if (key === "businessArea") {
    obj.BusinessArea = event.name
  }
  if (key === "commonName") {
    obj.Name = event.target.value
  }
  if (key === "businessProduct") {
    obj.BusinessProducts.push(event?.name)
  }
  if (key === "industryVertical") {
    obj.IndustryVerticals[0].Name = event?.name
  }
  if (key === "phone") {
    obj.Phone = event.target.value
  }
  if (key === "telex") {
    obj.Telex = event.target.value
  }
  if (key === "email") {
    obj.Email = event.target.value
  }
  if (key === "country") {
    obj.CountryISO2Code = event.code
  }
  if (key === "website") {
    obj.Website = event.target.value
  }
  if (key === "address") {
    obj.PartyAddresses[0].AddressLine1 = event.target.value
  }
  return obj;
}

export const setCustomerSectionValue = (obj: CustomerDetail, key: any, event: any) => {
  if (key === 'customerName') {
    if (event) {
      obj.CustomerName = event.name
      obj.CustomerCode = event.code
    } else {
      obj.CustomerName = ""
      obj.CustomerCode = ""
      obj.AccountManager = ''
    }
  }
  if (key === 'reference') {
    obj.CustomerReference = event.target.value
  }

  if (key === 'customerContact') {
    obj.CustomerContact = event.target.value
  }

  if (key === 'email') {
    obj.Email = event.target.value
  }

  if (key === 'country') {
    if (event) {
      obj.CountryName = event.name
      obj.CountryCode = event.code
    } else {
      obj.CountryName = ""
      obj.CountryCode = ""
    }
  }
  if (key === 'sellingCurrency') {
    if (event) {
      obj.SellingCurrency = event.code
    } else {
      obj.SellingCurrency = ""
    }
  }

  if (key === 'exchangeRate') {
    obj.ExchangeRate = event.target.value
  }

  if (key === 'customerAddress') {
    if (event) {
      obj.CustomerName = event.name
    } else {
      obj.CustomerName = ""
    }
  }

  if (key === 'address') {
    obj.Address = event.target.value
  }
  if (key === 'advanceFundingRequired') {
    obj.AdvanceFundingRequired = event.checked
  }
  if (key === 'bankInfo') {
    obj.BankInfo = event.BankName
  }
  if (key === 'percentage') {
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      obj.Percentage = event.target.value
    }
  }

  if (key === 'approvalBy') {
    if (event) {
      obj.ApprovalByName = event.name
      obj.ApprovalByCode = event.code
    } else {
      obj.ApprovalByName = ""
      obj.ApprovalByCode = ""
    }
  }
  if (key === 'approvalByRefNo') {
    obj.ApprovalByReference = event.target.value
  }
  if (key === 'accountManager') {
    obj.AccountManager = event.target.value
  }
  if (key === 'addressType') {
    obj.AddressType = event.name
  }

  return obj;
}

export const setVesselDetails = (obj: VesselDetail, response: any): VesselDetail => {
  obj.VesselTypeCode = response?.vesselType.code
  obj.VesselTypeName = response?.vesselType.name

  obj.GrossTonnage = response?.grossTonnage
  obj.NetTonnage = response?.netTonnage
  obj.SummerDWT = response?.summerDWT

  obj.IndustryVerticalCode = response?.vesselType.industryVertical.code;
  obj.IndustryVerticalName = response?.vesselType.industryVertical.name;

  return obj
}

export const setVesselSectionValue = (obj: VesselDetail, key: any, event: any) => {

  if (key === 'vesselName') {
    if (event) {
      obj.VesselName = event.name
      obj.VesselImoCode = event.code
    } else {
      obj.VesselName = ""
    }
  }

  if (key === 'grossTonnage') {
    obj.GrossTonnage = event.target.value
  }

  if (key === 'netTonnage') {
    obj.NetTonnage = event.target.value
  }

  if (key === 'summerDWT') {
    obj.SummerDWT = event.target.value
  }

  if (key === 'vesselType') {
    if (event) {
      obj.VesselTypeName = event.target.value
      obj.VesselTypeCode = event.target.value
    } else {
      obj.VesselTypeName = ''
      obj.VesselTypeCode = ''
    }
  }

  if (key === 'cargoType') {
    if (event) {
      obj.CargoTypeCode = event.code
      obj.CargoTypeName = event.name
    } else {
      obj.CargoTypeCode = ''
      obj.CargoTypeName = ''
    }
  }
  if (key === 'callType') {
    if (event) {
      obj.CallTypeCode = event.Code
      obj.CallTypeName = event.Name
    } else {
      obj.CallTypeCode = ''
      obj.CallTypeName = ''
    }
  }

  if (key === 'callSubType') {
    if (event) {
      obj.CallSubTypeCode = event.Code
      obj.CallSubTypeName = event.Name
    } else {
      obj.CallSubTypeCode = ''
      obj.CallSubTypeName = ''
    }
  }

  if (key === 'callPurpose') {
    if (event) {
      obj.CallPurposeCode = event.Code
      obj.CallPurposeName = event.Name
    } else {
      obj.CallPurposeCode = ''
      obj.CallPurposeName = ''
    }
  }

  if (key === 'previousPort') {
    if (event) {
      obj.PreviousPortCode = event.code
      obj.PreviousPortName = event.name
    } else {
      obj.PreviousPortName = ''
      obj.PreviousPortCode = ''
    }
  }

  if (key === 'nextPort') {
    if (event) {
      obj.NextPortCode = event.code
      obj.NextPortName = event.name
    } else {
      obj.NextPortName = ''
      obj.NextPortCode = ''
    }
  }

  if (key === 'callCargoRelatedYes') {
    if (event) {
      obj.IsCargoRelated = event.target.value
    }
  }

  if (key === 'callCargoRelatedNo') {
    if (event) {
      obj.IsCargoRelated = event.target.value
    }
  }

  if (key === 'etaDate') {
    if (event) {
      obj.ETA = event.value
    } else {
      obj.ETA = null
    }
  }
  if (key === 'etbDate') {
    if (event) {
      obj.ETB = event.value
    } else {
      obj.ETB = null
    }
  }
  if (key === 'etcDate') {
    if (event) {
      obj.ETC = event.value
    } else {
      obj.ETC = null
    }
  }
  if (key === 'etdDate') {
    if (event) {
      obj.ETD = event.value
    } else {
      obj.ETD = null
    }
  }
  return obj;
}

export const setLinkOptionValue = (arr: any, response: OptionDetail): OptionDetail => {
  arr.RouteDetail[0].PortName = response?.PortName;
  arr.RouteDetail[0].PortCode = response?.PortCode;

  arr.RouteDetail[0].TerminalName = response?.TerminalName;
  arr.RouteDetail[0].TerminalCode = response?.TerminalCode;

  arr.RouteDetail[0].BerthName = response?.BerthName;
  arr.RouteDetail[0].BerthCode = response?.BerthCode;

  return arr;
}

export const setVoyageTableValue = (arr: any, key: any, event: any, index: any) => {

  if (arr && arr !== 'undefined') {

    if (key === 'port') {
      if (event) {
        arr.RouteDetail[0].PortName = event.name;
        arr.RouteDetail[0].PortCode = event.code;
      } else {
        arr.RouteDetail[0].PortName = '';
        arr.RouteDetail[0].PortCode = '';
      }
    }
    if (key === 'terminal' && arr !== 'undefined') {
      if (event) {
        arr.RouteDetail[0].TerminalName = event.name;
        arr.RouteDetail[0].TerminalCode = event.code;
      } else {
        arr.RouteDetail[0].TerminalName = '';
        arr.RouteDetail[0].TerminalCode = '';
      }
    }
    if (key === 'agent') {
      arr.DestAgentCode = event.code
      arr.DestAgentName = event.name
      arr.RouteDetail[0].AgentName = event.name;
    }
    if (key === 'OptionName') {
      arr.OptionName = event.target.value;
    }

  }

  return arr;
}

export const setCrewTableValue = (arr: CrewDetails, key: any, event: any, index: any): CrewDetails => {

  if (key === 'crewMovement') {
    if (event) {
      arr.CrewMovement = event.name;
    } else {
      arr.CrewMovement = '';
    }
  }
  if (key === 'noOfCrew') {
    arr.NoOfCrew = event.target.value;
  }
  if (key === 'accomodationType') {
    if (event) {
      arr.AccomodationType = event.name;
    } else {
      arr.AccomodationType = '';

    }
  }
  if (key === 'noOfNights') {
    arr.NoOfNights = event.target.value
  }

  if (key === 'documentationServices') {
    arr.DocumentationServices = event.target.value
  }

  if (key === 'documentType') {
    if (event) {
      arr.DocumentType = event.name;
    } else {
      arr.DocumentType = '';
    }
  }

  if (key === 'medicalCondition') {
    event.preventDefault();
    arr.MedicalCondition = event.target.value
  }

  return arr;
}