export interface IService {
  BackToBack?: null | number;
  BillAmount?: null | number;
  BillingCurrencyCode?: string;
  BillingExchangeRate?: null | number;
  BillingMethodCode?: string;
  BillingOptions?: string;
  BillingPriorityCode?: string;
  BillingRemarks?: string;
  BillingSequence?: null | number;
  BillingStatus?: string;
  Code?: string;
  CostUomCode?: string;
  CostUomName?: string;
  CostQuantity?: string;
  CostCurrencyCode?: string;
  CostExchangeRate?: null | number;
  CostSummaryFk: null | number;
  CostSummaryStatus: string;
  CostSummaryVersion: string;
  CostTaxAmount?: null | number;
  CostTaxCode?: string;
  CostTaxPercent?: null | number;
  CostVariance?: null | number;
  CreatedAt?: string;
  CreatedBy?: string;
  CustomerService: string;
  EstimatedCost?: null | number;
  ExternalRemarks?: string;
  EyeshareCost?: null | number;
  EyeshareTax?: null | number;
  gacMasterCode: null | number;
  GSServiceCode: string;
  GSServiceName: string;
  Id?: null | number;
  InternalRemarks: string;
  IsActive?: boolean;
  IsBillable?: boolean;
  IsCashVoucherNeeded: null | boolean;
  IsCharge?: boolean;
  IsCostDitributed?: null | boolean;
  isDeleted?: boolean; // not getting from server
  IsExternallyEdited?: null | boolean;
  IsFromMaster: null | boolean;
  LineNumber: string | null;
  LocalJobId?: null | number;
  ModifiedAt?: string;
  ModifiedBy?: string;
  Name?: string;
  OffsetAmount: null | number;
  OperatingIncomeActual?: null | number;
  OperationalProcessFk?: null | number;
  OperationalProcessPartyFk?: null | number;
  ParentServiceFk?: any;
  PaymentTermCode?: string;
  PriceCurrencyCode?: string;
  PriceExchangeRate?: null | number;
  PriceTaxAmount?: null | number;
  PriceTaxCode?: string;
  PriceTaxPercent?: any;
  Quantity?: any;
  QuotationNumber?: string;
  rowId?: null | number; // not getting from server
  RowOrder?: null | number; // not getting from server, but used in Reducer
  SupplierCode?: string;
  SupplierName?: string;
  svsCategoryCodes?: string[];
  TotalEstimatedCost?: null | number;
  TotalEyeshareAmount?: null | number;
  TotalPriceAmount?: null | number;
  UnitCost?: null | number;
  UnitPrice?: null | number;
  UomCode?: null | string;
  UomName?: null | string;
  VoucherFk: null | number;
  VoucherLink: null | string;
  VoucherNumber: null | string;
  VoucherStatus: null | string;
  VoucherType: null | string;
}
export class Service implements IService {

  BackToBack?: null | number;
  BillAmount?: null | number;
  BillingCurrencyCode?: string;
  BillingExchangeRate?: null | number;
  BillingMethodCode?: string;
  BillingOptions?: string;
  BillingPriorityCode?: string;
  BillingRemarks?: string;
  BillingSequence?: null | number;
  BillingStatus?: string;
  Code?: string;
  CostUomCode?: string;
  CostUomName?: string;
  CostQuantity?: string;
  CostCurrencyCode?: string;
  CostExchangeRate?: null | number;
  CostSummaryFk: null | number;
  CostSummaryStatus: string;
  CostSummaryVersion: string;
  CostTaxAmount?: null | number;
  CostTaxCode?: string;
  CostTaxPercent?: null | number;
  CostVariance?: null | number;
  CreatedAt?: string;
  CreatedBy?: string;
  CustomerService: string;
  EstimatedCost?: null | number;
  ExternalRemarks?: string;
  EyeshareCost?: null | number;
  EyeshareTax?: null | number;
  gacMasterCode: null | number;
  GSServiceCode: string;
  GSServiceName: string;
  Id?: null | number;
  InternalRemarks: string;
  IsActive?: boolean;
  IsBillable?: boolean;
  IsCashVoucherNeeded: null | boolean;
  IsCharge?: boolean;
  IsCostDitributed?: null | boolean;
  isDeleted?: boolean; // not getting from server
  IsExternallyEdited?: null | boolean;
  IsFromMaster: null | boolean;
  LocalJobId?: null | number;
  LineNumber: string | null;
  ModifiedAt?: string;
  ModifiedBy?: string;
  Name?: string;
  OffsetAmount: null | number;
  OperatingIncomeActual?: null | number;
  OperationalProcessFk?: null | number;
  OperationalProcessPartyFk?: null | number;
  ParentServiceFk?: any;
  PaymentTermCode?: string;
  PriceCurrencyCode?: string;
  PriceExchangeRate?: null | number;
  PriceTaxAmount?: null | number;
  PriceTaxCode?: string;
  PriceTaxPercent?: any;
  Quantity?: any;
  QuotationNumber?: string;
  rowId?: null | number; // not getting from server
  RowOrder: null | number; // not getting from server, but used in Reducer
  SupplierCode?: string;
  SupplierName?: string;
  svsCategoryCodes?: string[];
  TotalEstimatedCost?: null | number;
  TotalEyeshareAmount?: null | number;
  TotalPriceAmount?: null | number;
  UnitCost?: null | number;
  UnitPrice?: null | number;
  UomCode?: string;
  UomName?: string;
  VoucherFk: null | number;
  VoucherLink: null | string;
  VoucherNumber: null | string;
  VoucherStatus: null | string;
  VoucherType: null | string;


  constructor() {
    this.BackToBack = null;
    this.BillAmount = null;
    this.BillingCurrencyCode = "";
    this.BillingExchangeRate = null;
    this.BillingMethodCode = "";
    this.BillingOptions = "";
    this.BillingPriorityCode = "";
    this.BillingRemarks = "";
    this.BillingSequence = null;
    this.BillingStatus = "";
    this.Code = "";
    this.CostUomCode = "";
    this.CostUomName = "";
    this.CostQuantity = "";
    this.CostCurrencyCode = "";
    this.CostExchangeRate = null;
    this.CostSummaryFk = null;
    this.CostSummaryStatus = "";
    this.CostSummaryVersion = "";
    this.CostTaxAmount = null;
    this.CostTaxCode = "";
    this.CostTaxPercent = null;
    this.CostVariance = null;
    this.CreatedAt = "";
    this.CreatedBy = "";
    this.CustomerService = "";
    this.EstimatedCost = null;
    this.ExternalRemarks = "";
    this.EyeshareCost = null;
    this.EyeshareTax = null;
    this.gacMasterCode = null;
    this.GSServiceCode = "";
    this.GSServiceName = "";
    this.Id = 0;
    this.InternalRemarks = "";
    this.IsExternallyEdited = null;
    this.IsActive = true;
    this.IsCharge = false;
    this.IsCashVoucherNeeded = null;
    this.IsCostDitributed = null;
    this.IsFromMaster = null;
    this.LocalJobId = null;
    this.LineNumber = "";
    this.ModifiedAt = "";
    this.ModifiedBy = "";
    this.Name = "";
    this.OffsetAmount = null;
    this.OperatingIncomeActual = null;
    this.OperationalProcessPartyFk = null;
    this.ParentServiceFk = "";
    this.PaymentTermCode = "";
    this.PriceCurrencyCode = "";
    this.PriceExchangeRate = null;
    this.PriceTaxAmount = null;
    this.PriceTaxCode = "";
    this.PriceTaxPercent = "";
    this.Quantity = "";
    this.QuotationNumber = "";
    this.SupplierCode = "";
    this.SupplierName = "";
    this.TotalEstimatedCost = null;
    this.TotalEyeshareAmount = null;
    this.TotalPriceAmount = null;
    this.UnitCost = null;
    this.UnitPrice = null;
    this.UomCode = "";
    this.UomName = "";
    this.rowId = 0;
    this.RowOrder = null;
    this.isDeleted = false;
    this.svsCategoryCodes = [];
    this.IsBillable = true;
    this.VoucherFk = null;
    this.VoucherLink = null;
    this.VoucherNumber = null;
    this.VoucherStatus = null;
    this.VoucherType = null;
  }
}