import { useSelector } from "react-redux";
import { Section, Left, Right, Label, VisibilitySection } from '../../template.styles';
import Dropdown from 'Controls/Dropdown';
import PegRadioButton from 'Controls/Radio/PegRadioButton';
import PegInput from 'Controls/Input/PegInputBox';
import { LoaderOverview } from 'Containers/JobGrid/Jobs.styles';
import Loader from 'Components/Loader';
import { useState } from "react";

type Prop = {
	serviceTemplate: any;
	onChangeInput: (e: any, id: string) => void;
	OnChange: any;
	businessProductList?: any[];
	businessProducts: any[];
	currencyList?: any[];
	selectedCompany?: string;
	onSaveOfServiceTemplate: () => void;
	onValueChange?: any;
	isFeildsMissing?: boolean;
}

export default function ServiceTemplateDetails(props: Prop) {
	let isLoading = useSelector((state: any) => state.ServiceTemplates.isLoading);

	const {
		serviceTemplate,
		onChangeInput,
		OnChange,
		businessProductList,
		currencyList,
		onValueChange,
		businessProducts,
	} = props;

	const {
		ServiceTemplateDetails
	} = serviceTemplate;

	const isVesselSelectionDisabled = !serviceTemplate.ServiceTemplateDetails?.PortName

	if (isLoading) {
		return (
			<LoaderOverview>
				<Loader gridLoader={true} color={'#aacbe9'} />
			</LoaderOverview>
		);
	}

	return (
		<div id="serviceTemplateDetailView">
			<Section>
				<Left>
					<Label>Template Name</Label>
					<PegInput
						type={'text'}
						id={'txtTemplateName'}
						name={'templateName'}
						textAlign={'left'}
						style={{ width: 190, marginLeft: '30px', fontSize: '12px' }}
						value={ServiceTemplateDetails?.Name ? ServiceTemplateDetails?.Name : ''}
						isMandatory={true}
						onChange={(e: any, id: string) => onChangeInput(e, "templateName")} />

				</Left>
				<Right>
					<Label>Customer</Label>
					<Dropdown
						defaultValue={serviceTemplate.ServiceTemplateDetails?.CustomerName}
						id={'dropdownCustomer'}
						name={'customer'}
						isLookup={true}
						dropdownLabel={'name'}
						dropdownValue={'code'}
						appendParams={false}
						useDebounce={true}
						style={{ width: 190, borderRadius: '2px' }}
						isMenuPortalTarget={'true'}
						domId={"serviceTemplateDetailView"}
						placeHolder="Select customer"
						url={`mdm-search-customer?searchText=`}
						onChangeDropdown={(e: any, id: string) => onChangeInput(e, "customer")}
					/>

				</Right>
			</Section>
			<Section>
				<Left>
					<Label>Base Currency</Label>
					<Dropdown
						defaultValue={serviceTemplate.ServiceTemplateDetails?.BaseCurrencyCode}
						label={'code'}
						isLookup={false}
						dropdownOptions={currencyList}
						dropdownLabel={'code'}
						dropdownValue={'code'}
						placeHolder="Select currency"
						id="baseCurrency"
						name={'baseCurrency'}
						type={'serviceTemplate'}
						appendParams={false}
						isMandatory={true}
						style={{ width: 190, marginLeft: '15px', borderRadius: '2px' }}
						isMenuPortalTarget={'true'}
						onChangeDropdown={(e: any, id: string) => onChangeInput(e, "baseCurrency")}
						domId={"serviceTemplateDetailView"}
						removeIsClear={true}
					/>
				</Left>
				<Right>
					<Label>Description</Label>
					<PegInput
						type={'text'}
						id={'txtDescription'}
						name={'description'}
						textAlign={'left'}
						style={{ width: 190, fontSize: '12px' }}
						value={serviceTemplate.ServiceTemplateDetails?.Description}
						onChange={(e: any, id: string) => onChangeInput(e, "description")} />

				</Right>
			</Section>
			<Section>
				<Left>
					<Label id="lblTemplateName">
						Business Products
					</Label>
					<Dropdown
						defaultValue={serviceTemplate?.BusinessProducts}
						value={businessProducts}
						label={'Name'}
						isLookup={false}
						domId={"serviceTemplateDetailView"}
						dropdownOptions={businessProductList}
						dropdownLabel={'Name'}
						dropdownValue={'Code'}
						placeHolder="Select Business Product"
						id="businessProduct"
						name={"businessProduct"}
						type={'businessProduct'}
						appendParams={false}
						style={{ width: 450, marginLeft: '15px', fontSize: '12px', borderRadius: '2px' }}
						isMenuPortalTarget={'true'}
						onChangeDropdown={(e: any, id: string) => onValueChange(e, "businessProducts")}
						isMulti={true}
						removeIsClear
						isMandatory={true}
						disabled={!serviceTemplate.ServiceTemplateDetails?.BaseCurrencyCode || !serviceTemplate.ServiceTemplateDetails?.Name}
					/>
				</Left>
				<Right>
					{/* {isFeildsMissing && <span>Fill the required fields</span>}
					<PegButton
						id="btnNext"
						type="button"
						variant="Success"
						styleObject={{ display: 'flex', float: 'right' }}
						onClick={() => { onSaveOfServiceTemplate() }}>
						Save
					</PegButton> */}
				</Right>
			</Section>
			<VisibilitySection>
				<Label>Visibility</Label>
				<div style={{ display: 'flex', marginLeft: '15px', marginRight: 'auto' }}>
					<PegRadioButton
						id="radiobtnCompany"
						onChange={OnChange}
						name="serviceTemplateVisibility"
						option={'Company'}
						value={'Company'}
						style={{ position: 'relative', fontSize: '12px', fontWeight: 'normal' }}
						checked={serviceTemplate.ServiceTemplateDetails?.VisibilityType === "Company"}
					/>
					{serviceTemplate?.ServiceTemplateDetails?.CompanyCommonName &&
						<span style={{ fontSize: '12px', fontWeight: 'normal', marginTop: '2px', marginRight: '10px' }}>{`(${serviceTemplate?.ServiceTemplateDetails?.CompanyCommonName})`}</span>
					}
					<PegRadioButton
						id="radiobtnDepartment"
						onChange={OnChange}
						name="serviceTemplateVisibility"
						option={'Department'}
						value={'Department'}
						style={{ position: 'relative', marginRight: '10px', fontSize: '12px', fontWeight: 'normal' }}
						checked={serviceTemplate.ServiceTemplateDetails?.VisibilityType === "Department"}
					/>
					<PegRadioButton
						id="radiobtnOffice"
						onChange={OnChange}
						option={'Office'}
						value={'Office'}
						name="serviceTemplateVisibility"
						style={{ position: 'relative', marginRight: '10px', fontSize: '12px', fontWeight: 'normal' }}
						checked={serviceTemplate.ServiceTemplateDetails?.VisibilityType === "Office"}
					/>
				</div>
				<div style={{ display: 'flex' }}>
					<label
						htmlFor="chkIsActive"
						style={{ fontSize: '12px', marginRight: '5px' }}>Is Active</label>
					<input
						id={'chkIsActive'}
						name={'isActive'}
						type={'checkbox'}
						onChange={(e: any) => onChangeInput(e, 'isActive')}
						style={{ marginTop: '2px' }}
						defaultChecked={serviceTemplate.ServiceTemplateDetails?.IsActive ? serviceTemplate.ServiceTemplateDetails?.IsActive : true}
					/>
				</div>
			</VisibilitySection>
			<Section>
				<Left>
					<Label>
						Ports
					</Label>
					<Dropdown
						defaultValue={serviceTemplate.ServiceTemplateDetails?.PortName}
						id={'dropdownPort'}
						name={'port'}
						isLookup={true}
						dropdownLabel={'name'}
						dropdownValue={'code'}
						appendParams={false}
						useDebounce={true}
						style={{ width: 190, marginLeft: '15px', borderRadius: '2px' }}
						isMenuPortalTarget={'true'}
						placeHolder="Select Port"
						url={`/mdm-port?searchText=`}
						onChangeDropdown={(e: any) => onChangeInput(e, "port")}
						isMulti={false}
						removeIsClear={false}
					/>
				</Left>
				<Right>
					<Label>
						Vessel Type
					</Label>
					<Dropdown
						defaultValue={serviceTemplate.ServiceTemplateDetails?.VesselTypeName}
						id={'dropdownVesselType'}
						name={'vesselType'}
						isLookup={true}
						dropdownLabel={'name'}
						dropdownValue={'code'}
						appendParams={false}
						useDebounce={true}
						style={{ width: 190, borderRadius: '2px' }}
						isMenuPortalTarget={'true'}
						placeHolder="Select Vessel"
						url={`/mdm-vessel-type?searchText=`}
						onChangeDropdown={(e: any) => onChangeInput(e, "vesselType")}
						isMulti={false}
						disabled={isVesselSelectionDisabled}
						removeIsClear={false}
					/>
				</Right>
			</Section>
		</div>
	)
}
