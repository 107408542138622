import PegButton from 'Controls/Button/PegButton';
import { RiCheckboxBlankLine, RiCheckboxLine } from 'react-icons/ri';
import {
  GridOptions,
  GroupOptions,
  MarginLeft
} from '../Jobs.styles';
import { BsCheck, BsCircle } from 'react-icons/bs';
import { Color } from 'Utils/Color';

type Props = {
  disableCost: boolean;
  disableServiceRequest: boolean;
  disableOperatingIncome: boolean;
  disableRevenue: boolean;
  disableBilling: boolean;
  templateImportEnabled: boolean;
  selectedList: any[];
  services: any;
  jobDetails: any;
  isModified: boolean;
  onClickImportTemplate: () => void;
  onClickOptions: (type: string) => void;
  onClickPriceSummary: () => void;
  onClickFilterColumn: () => void;
  showOperatingIncome: any
  showServReq: any
  showBilling: any
};
const HeaderOptions = (props: Props) => {
  const {
    disableCost,
    disableServiceRequest,
    disableOperatingIncome,
    onClickFilterColumn,
    disableRevenue,
    onClickImportTemplate,
    jobDetails,
    disableBilling,
    showOperatingIncome,
    showServReq,
    showBilling,
    templateImportEnabled
  } = props

  const ActiveIcon = (prop: { active: boolean }) => {
    return (
      prop.active ? <></> : <BsCheck style={{ color: '#383a3e', height: 20, width: 20 }} />
    )
  }

  return (
    <GridOptions>
      {!jobDetails.IsMaster && showOperatingIncome &&
        <>
          <GroupOptions
            data-tip
            data-for='OperatingIncome'
            colour={Color.operatingIncomeHighlight}
            onClick={() => props.onClickOptions('OpIncome')}>
            <ActiveIcon active={disableOperatingIncome} />
            <span>Op Income</span>
          </GroupOptions>
        </>}
      {!jobDetails.IsMaster &&
        <>
          <GroupOptions
            data-tip
            data-for='actuals'
            colour={Color.revenueHighlight}
            onClick={() => props.onClickOptions('actuals')}>
            <ActiveIcon active={disableRevenue} />
            <span>Revenue</span>
          </GroupOptions>
        </>}
      <GroupOptions
        data-tip
        data-for='estimates'
        colour={Color.costHighlight}
        onClick={() => props.onClickOptions('estimates')}>
        <ActiveIcon active={disableCost} />
        <span>Cost</span>
      </GroupOptions>
      {showServReq &&
        <GroupOptions
          data-tip
          data-for='ServiceRequest'
          colour={Color.serviceRequestHighlight}
          onClick={() => props.onClickOptions('SrRequest')}>
          <ActiveIcon active={disableServiceRequest} />
          <span>Service Req</span>
        </GroupOptions>}
      {!jobDetails.IsMaster && showBilling &&
        <>
          <GroupOptions
            data-tip
            data-for='billing'
            colour={Color.billingHighlight}
            onClick={() => props.onClickOptions('billing')}>
            <ActiveIcon active={disableBilling} />
            <span>Billing</span>
          </GroupOptions>
        </>}
      <MarginLeft>
        {templateImportEnabled ? <PegButton
          id="jobGrid_import"
          type="button"
          variant="Primary"
          styleObject={{ marginLeft: 12 }}
          onClick={onClickImportTemplate}>
          Import Template
        </PegButton> : null}
        <PegButton
          id="filter_column"
          type="button"
          variant="Primary"
          styleObject={{ marginLeft: 12 }}
          onClick={onClickFilterColumn}>
          Preferences
        </PegButton>
      </MarginLeft>
    </GridOptions>
  )
};

export default HeaderOptions;
