import axios from "axios";
import _ from 'lodash'
import store from "Redux/Store";
import { ServiceTemplateDefault } from "Model/ServiceTemplate/ServiceTemplatedefault";
import { toast } from "react-toastify";
import {
  SET_SERVICE_TEMPLATE,
  SET_ERROR,
  SET_SERVICE_LOADING,
  SET_VISIBILITY_GRID_LOADING,
  ADD_SERVICE_TEMPLATE,
  UPDATE_SERVICE_TEMPLATE,
  IS_SERVICE_TEMPLATE_SERVICE_SAVING,
  SET_BUSINESS_PRODUCT,
  UPDATE_BUSINESS_PRODUCT,
  ON_ADD_TEMPLATE_SERVICE,
  ON_DELETE_TEMPLATE_SERVICE,
  ON_ADD_TEMPLATE_CHARGE,
  ON_DELETE_TEMPLATE_CHARGE,
  ON_UPDATE_TEMPLATE_CHARGE,
  ON_UPDATE_TEMPLATE_SERVICE,
  ON_BULK_UPDATE_SERVICE_CHARGES,
  ON_UPDATE_BUSINESS_PRODUCT_SERVICES,
  ON_UPDATE_BUSINESS_PRODUCT_DETAILS,
  ON_UPDATE_OP_TAGS,
  SET_VISIBILITY_GRID_DATA,
  DELETE_SERVICE_VISIBILITY,
  REFRESH_SERVICE_VISIBILITY,
  ON_BULK_DELETE_SERVICE_CHARGES,
  SET_IS_DUPLICATE
} from "../ActionTypes/ActionTypes";

export function getServiceTemplate(params: any) {
  return (dispatch: (arg: any) => void) => {
    dispatch(setServiceTemplateLoading(true));
    return axios.get(`oum-service-template-main-detail?id=${params.id}`).then((response: any) => {
      if (response.data) {
        dispatch(setServiceData(response.data));
        dispatch(setServiceTemplateLoading(false));
      } else {
        dispatch(setError(response.data));
      }
    })
  }
}


export function getVisibilityGridData(type: string, selectedCompany: string) {
  const state: any = store.getState();
  const ServiceTemplateVisibility: any = state?.ServiceTemplates?.serviceTemplate?.ServiceTemplateVisibility
  return (dispatch: (arg: any) => void) => {
    const companyCode = new URLSearchParams(window.location.search).get("companycode");
    let keyId = "";
    let url = "";
    if (type === "Office") {
      keyId = "OfficeFk";
      url = "/oum-office?company-code=" + companyCode;
    }
    else if (type === "Department") {
      keyId = "DepartmentFk";
      url = "/oum-department?company-code=" + companyCode;
    }
    else {
      dispatch(setVisibilityGridData([]))
      return;
    }
    dispatch(setVisibilityGridLoading(true));
    axios.get(url).then((response) => {
      var result;
      const selectedRows: any[] = response.data.map((x: any) => {
        if (type === 'Department') {
          result = ServiceTemplateVisibility.filter((a1: any) => a1.DepartmentFk === x.DepartmentId);
          if (result.length > 0) { x.isChecked = true; } else { x.isChecked = false }
          return x
        } else if (type === 'Office') {
          result = ServiceTemplateVisibility.filter((a1: any) => a1.OfficeFk === x.OfficeId);
          if (result.length > 0) { x.isChecked = true; } else { x.isChecked = false }
          return x
        }
      })
      dispatch(setVisibilityGridData(selectedRows))
      dispatch(setVisibilityGridLoading(false));
    }).catch((response) => {
      dispatch(setError(response.data));
    })
  }
}

export const setVisibilityGridLoading = (value: boolean) => {
  return {
    type: SET_VISIBILITY_GRID_LOADING,
    value
  }
};

export const deleteServiceVisibility = (id: number, visibiltyType: string) => {
  return {
    type: DELETE_SERVICE_VISIBILITY,
    id,
    visibiltyType
  }
};

export const setServiceTemplateLoading = (value: boolean) => {
  return {
    type: SET_SERVICE_LOADING,
    value
  }
};

export function saveServiceTemplate(serviceTemplate: ServiceTemplateDefault) {
  return (dispatch: any, getState: any) => {
    dispatch(isServiceTemplateSaving(true))
    return axios.post(`oum-add-update-service-template-main-details`, serviceTemplate).then((response: any) => {
      dispatch(setServiceData(response.data));
      return response.data
    }).catch((err) => {
      let message = "Error occured";
      if (err.response) {
        if (Array.isArray(err.response.data)) {
          if (err.response.data.length > 0)
            message = err.response.data.join(", ");
        }
        else {
          message = err.response.data;
        }
      }
      else {
        message = "Network error.Please try again.";
      }
      toast.error(message, {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        style: { width: '400px' },
      })
      dispatch(isServiceTemplateSaving(false))
    })
  }
}

export function saveServiceCharges(productDetail: any, product: any) {
  return (dispatch: (arg: any) => void) => {
    let payload = {
      ...product,
      servicesAndCharges: productDetail?.servicesAndCharges ? productDetail?.servicesAndCharges : [],
      OperationalProcessTags: productDetail?.opCode ?
        _.uniqBy(productDetail?.opCode, function (data: any) {
          return data.Code;
        }).map((item: any) => {
          return {
            ...item,
            BusinessProductId: product.BusinessProductId
          }
        }) : []
    }
    return axios.post(`oum-add-update-service-template-other-details`, {
      BusinessProducts: { ...payload }
    }).then((response: any) => {
      if (response.data) {
        dispatch(onUpdateBPserviceCharges(
          product,
          response.data?.BusinessProducts?.servicesAndCharges))
      }
    })
  }
}

function setServiceData(response: any) {
  return {
    type: SET_SERVICE_TEMPLATE,
    response
  }
}

export function onUpdateOpTags(product: any, opTags: any) {
  return {
    type: ON_UPDATE_OP_TAGS,
    product,
    opTags
  }
}


function setError(error: any) {
  return {
    type: SET_ERROR,
    error
  }
}

export function refreshServiceVisibility(visibilityType: string) {
  return {
    type: REFRESH_SERVICE_VISIBILITY,
    visibilityType
  }
}

export function onBulkUpdate(services: any) {
  return {
    type: ON_BULK_UPDATE_SERVICE_CHARGES,
    services
  }
}

export function onMultiDeleteServices(list: any[]) {
  return {
    type: ON_BULK_DELETE_SERVICE_CHARGES,
    list
  }
}

export function addServiceTemplate(serviceTemplate: any) {
  return {
    type: ADD_SERVICE_TEMPLATE,
    serviceTemplate
  }
}

export function setVisibilityGridData(data: any) {
  return {
    type: SET_VISIBILITY_GRID_DATA,
    data
  }
}

export function isServiceTemplateSaving(isSaving: boolean) {
  return {
    type: IS_SERVICE_TEMPLATE_SERVICE_SAVING,
    isSaving
  }
}

export function onUpdateServiceTemplate(serviceTemplate: any) {
  return {
    type: UPDATE_SERVICE_TEMPLATE,
    serviceTemplate
  }
}

export function onAddTemplateService() {
  return {
    type: ON_ADD_TEMPLATE_SERVICE,
  }
}

export function onDeleteTemplateService(serviceIndex: number, opCode: number) {
  return {
    type: ON_DELETE_TEMPLATE_SERVICE,
    serviceIndex,
    opCode
  }
}

export function onUpdateTemplateService(serviceIndex: number, service: any) {
  return {
    type: ON_UPDATE_TEMPLATE_SERVICE,
    serviceIndex,
    service
  }
}

export function onAddTemplateCharge(serviceIndex: number) {
  return {
    type: ON_ADD_TEMPLATE_CHARGE,
    serviceIndex
  }
}

export function onDeleteTemplateCharge(serviceIndex: number, chargeIndex: number) {
  return {
    type: ON_DELETE_TEMPLATE_CHARGE,
    serviceIndex,
    chargeIndex
  }
}

export function onUpdateTemplateCharge(serviceIndex: number, chargeIndex: number, charge: any) {
  return {
    type: ON_UPDATE_TEMPLATE_CHARGE,
    serviceIndex,
    chargeIndex,
    charge
  }
}

export function onBulkDeleteService() {
  return {
    type: ON_UPDATE_TEMPLATE_SERVICE,
  }
}

export function onUpdateBusinessProduct(products: any) {
  return {
    type: UPDATE_BUSINESS_PRODUCT,
    products
  }
}

export function onSetDefaultProduct(product: any) {
  return {
    type: SET_BUSINESS_PRODUCT,
    product
  }
}

export function onUpdateBPdetails(product: any, newProduct: any, details: any) {
  return {
    type: ON_UPDATE_BUSINESS_PRODUCT_DETAILS,
    product,
    newProduct,
    details
  }
}

export function onUpdateBPserviceCharges(product: any, servicesAndCharges: any) {
  return {
    type: ON_UPDATE_BUSINESS_PRODUCT_SERVICES,
    product,
    servicesAndCharges
  }
}

export function setIsDuplicate(value: any) {
  return {
    type: SET_IS_DUPLICATE,
    value
  }
}