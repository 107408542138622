
import { sheetHeader } from '../../../Constants/Constant';
import {
  OverviewHeader
} from '../services.styles'
import { ReactComponent as Expanded } from 'Assets/arrow-down.svg'
import { ReactComponent as Collapsed } from 'Assets/arrow-up.svg'

export default function Header(props: any) {
  return (
    <OverviewHeader>
      {
        sheetHeader.map((item: any) => {
          let Tag = item.component;
          return (
            <Tag
              className="px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50"
              key={item.id}>
              {
                item.key === 'id' &&
                <input
                  type="checkbox"
                  checked={props.isAllSelected}
                  onChange={() => props.onSelectAll()}
                  style={{ cursor: 'pointer', marginRight: '8px' }}
                />
              }
              {
                item.key === 'expand' &&
                <div onClick={props.onExpand}>
                  <Collapsed />
                  <Expanded />
                </div>
              }
              {item.label}
            </Tag>
          )
        })
      }
    </OverviewHeader>
  )
}
