
import {
  useCallback,
  useState
} from 'react';
import _ from 'lodash';
import { validationMessageJob } from 'Constants/Constant'
import { GrFormClose } from "react-icons/gr";
import ServiceLine from './ServiceLine';
import ChargeLine from './ChargeLine';
import PegButton from 'Controls/Button/PegButton';
import {
  setServiceValue,
  setEditServiceRequestDetails,
  setChargeValue
} from 'Utils/JobGrid'
import {
  Overview,
  Header,
  Footer,
  Content
} from './details.styles';

type Props = {
  type?: string;
  onClose: () => void;
  onEditConfirm?: any;
  service: any;
  charge?: any;
  billingParties: any[];
  serviceRequestDetails?: any;
  jobDetails: any;
  serviceList: any[];
  uomList: any[];
  voucherList: any;
  currencyList: any[];
  expandAll?: boolean;
  id: any;
  index?: any;
  charges: any;
  services: any;
  exchangeRates: any[];
  taxList: any[];
  disableCost: boolean;
  disableServiceRequest: boolean;
  disableOperatingIncome: boolean;
  disableRevenue: boolean;
  disableBilling: boolean;
  isSupplierInvoiceCNGenerated: boolean;
  selectedList: any[];
  onDeleteService: (servId: number) => void;
  onAddCharge: (index: number) => void;
  onSelectServices: (index: number) => void;
  onUpdateService: (service: any, serviceIndex: any) => void;
  onUpdateServiceDetails: (service: any, serviceIndex: any) => void;
  onUpdateCharge: (charge: any, serviceIndex: number, chargeIndex: number) => void;
  setPartyValidModal: any;
  onSelectAll?: any;
  onSelectVoucher?: any;
  userDetails: any;
  isValidParty: boolean;
  setValidParty: any;
  columnPreference: any;
  paymentTerms: any;
  serviceIndex: any;
  serviceObject?: any;
  salesInvoice?: any;
  srNoDropdown?: any;
  supplierDetails?: any;
  hasMissingFields?: any;
  missingValidation?: any;
  editService?: any;
  onChangeInput: (event: any, id: any, serviceIndex: any, chargeIndex: any) => void;
  onChangeDate: (event: any, id: any, serviceIndex: any, chargeIndex: any) => void;
  onChangeDropdown: (event: any, id: any, obj: any) => void;
};

const Detail = (props: Props) => {
  const {
    onClose,
    onEditConfirm,
    jobDetails,
    serviceList,
    currencyList,
    uomList,
    voucherList,
    billingParties,
    taxList,
    setPartyValidModal,
    exchangeRates,
    isValidParty,
    setValidParty,
    userDetails,
    paymentTerms,
    serviceObject,
    srNoDropdown,
    serviceIndex,
    id,
    index,
    disableCost,
    disableServiceRequest,
    disableOperatingIncome,
    disableRevenue,
    disableBilling,
    isSupplierInvoiceCNGenerated,
    services,
    onUpdateCharge,
    onUpdateService,
    onUpdateServiceDetails,
    columnPreference,
    hasMissingFields,
    missingValidation
  } = props;
  let messages: any = []
  if (missingValidation?.length) {
    validationMessageJob.forEach((item: any) => {
      if (missingValidation.includes(item.key)) {
        messages.push(item)
      }
    })
  }

  const [showCharges, setShowCharges] = useState(true);
  const clonedServiceObject = _.cloneDeep(serviceObject)
  const [serviceObj, setServiceObj] = useState(clonedServiceObject)
  const {
    service,
    charges,
    salesInvoice,
    supplierDetails,
    serviceActivity,
    serviceRequestDetails
  } = serviceObj

  const onUpdateDropdown = useCallback((type: string, id: string, value: any, serviceIndex: any, chargeIndex: any) => {
    if (type === 'service') {
      if (id === 'serviceStatus' || id === 'serviceSRnumber') {
        let serviceDetailsObj = setEditServiceRequestDetails(serviceRequestDetails, id, value)
        let serviceRequestDetailsValue = {
          ...serviceObj,
          serviceRequestDetails: serviceDetailsObj
        }
        setServiceObj(serviceRequestDetailsValue)
      } else {
        let obj = setServiceValue(service, jobDetails, id, value)
        let serviceValue = {
          ...serviceObj,
          service: obj
        }
        setServiceObj(serviceValue)
      }

    } else {
      let chargeObj = setChargeValue(charges[chargeIndex], jobDetails, id, value)
      charges[chargeIndex] = chargeObj;
      let serviceValue = {
        ...serviceObj,
        charges: [...charges]
      }
      setServiceObj(serviceValue)
    }
  }, [service, serviceRequestDetails, charges])


  const onUpdateInput = useCallback((type: string, id: string, value: any, serviceIndex: number, chargeIndex: number) => {
    if (type === 'service') {
      let obj = setServiceValue(service, jobDetails, id, value)
      let serviceValue = {
        ...serviceObj,
        service: obj
      }
      setServiceObj(serviceValue)
    } else {
      let chargeObj = setChargeValue(charges[chargeIndex], jobDetails, id, value)
      charges[chargeIndex] = chargeObj;
      let serviceValue = {
        ...serviceObj,
        charges: [...charges]
      }
      setServiceObj(serviceValue)
    }
  }, [service])
  const onUpdateCheckBox = useCallback((type: string, event: any, value: any) => {
    if (type === 'service') {

      if (event?.target?.id === 'BackToBack') {
        let serv = setServiceValue(service, jobDetails, 'BackToBack', event.target.checked);

        if (serv?.BackToBack) {
          serv.PriceCurrencyCode = serv.CostCurrencyCode
          serv.PriceCurrencyName = serv.CostCurrencyName
          serv.PriceExchangeRate = serv.CostExchangeRate
        }

        charges?.forEach((charge: any) => {

          charge.BackToBack = serv.BackToBack

          if (serv.BackToBack) {

            const supplierAmount = charge?.TotalEyeshareAmount ?? 0
            const isSupplierAmountValid = supplierDetails?.supplierInvoice?.[0] || supplierAmount > 0

            charge.Quantity = 1
            charge.PriceCurrencyCode = charge.CostCurrencyCode
            charge.PriceCurrencyName = charge.CostCurrencyName
            charge.PriceExchangeRate = charge.CostExchangeRate

            if (isSupplierAmountValid) {
              charge.UnitPrice = supplierAmount
            } else {
              charge.UnitPrice = charge?.UnitCost
            }

          }
        })

        let serviceValue = {
          ...serviceObj,
          service: serv,
          charges: charges
        }
        setServiceObj(serviceValue)

      } else if (event.target.id === 'voucherStatus') {
        let serv = setServiceValue(service, jobDetails, 'IsCashVoucherNeeded', event.target.checked)
        let serviceValue = {
          ...serviceObj,
          service: serv
        }
        setServiceObj((prevState: any) => prevState.serviceObj = serviceValue)
      } else if (event.target.id === 'IsBillable') {
        let serv = setServiceValue(service, jobDetails, 'IsBillable', event.target.checked)
        let serviceValue = {
          ...serviceObj,
          service: serv
        }
        setServiceObj((prevState: any) => prevState.serviceObj = serviceValue)

        const min = serv.IsBillable ? 1 : 0;
        charges?.forEach((charge: any) => {
          if (!serv.IsBillable) {
            charge.UnitPrice = 0;
          }
        })
      }
    }
  }, [])
  const onUpdateDate = useCallback((type: string, id: string, value: any, serviceIndex: number, chargeIndex: number) => {
    if (type === 'service') {
      let serviceDetailsObj = setEditServiceRequestDetails(serviceRequestDetails, id, value)
      let serviceRequestDetailsValue = {
        ...serviceObj,
        serviceRequestDetails: serviceDetailsObj
      }
      setServiceObj(serviceRequestDetailsValue)
    } else {

    }
  }, [serviceRequestDetails])

  const onSubmit = () => {
    onEditConfirm(serviceObj, serviceIndex)
  }

  return (
    <Overview>
      <Header>
        <GrFormClose
          onClick={() => onClose()}
          className="icon-style" />
      </Header>
      <Content>
        <ServiceLine
          id={id}
          service={service}
          serviceRequestDetails={serviceRequestDetails}
          jobDetails={jobDetails}
          serviceList={serviceList}
          voucherList={voucherList}
          currencyList={currencyList}
          uomList={uomList}
          userDetails={userDetails}
          columnPreference={columnPreference}
          serviceIndex={index}
          serviceObject={serviceObject}
          salesInvoice={salesInvoice}
          charge={charges}
          srNoDropdown={srNoDropdown}
          supplierDetails={supplierDetails}
          services={services}
          billingParties={billingParties}
          taxList={taxList}
          exchangeRates={exchangeRates}
          paymentTerms={paymentTerms}
          showCharges={showCharges}
          onUpdateServiceDetails={onUpdateServiceDetails}
          onChangeDate={onUpdateDate}
          onChangeInput={onUpdateInput}
          onChangeDropdown={(type: string, elemId: any, event: any, serviceIndex: number, chargeIndex: number) => onUpdateDropdown(type, elemId, event, serviceIndex, chargeIndex)}
          onUpdateService={onUpdateService}
          disableCost={disableCost}
          disableServiceRequest={disableServiceRequest}
          disableOperatingIncome={disableOperatingIncome}
          disableRevenue={disableRevenue}
          disableBilling={disableBilling}
          isSupplierInvoiceAvailable={service?.supplierDetails?.supplierInvoice?.[0]}
          isSupplierCreditNoteAvailable={service?.supplierDetails?.creditNote?.[0]}
          setServiceObj={setServiceObj}
          serviceObj={serviceObj}
          onUpdateCheckbox={(type: string, event: any, value: any) => onUpdateCheckBox(type, event, value)}
          isValidParty={isValidParty}
          setValidParty={setValidParty}
          setPartyValidModal={setPartyValidModal}
        />
        {
          charges?.map((item: any, indexChild: number) => {
            if (item.IsActive) {
              return (
                <ChargeLine
                  key={'detail-charges' + index}
                  service={service}
                  services={services}
                  charge={item}
                  charges={charges}
                  columnPreference={columnPreference}
                  userDetails={userDetails}
                  serviceCode={service?.Code}
                  salesInvoice={salesInvoice}
                  jobDetails={jobDetails}
                  serviceIndex={index}
                  chargeIndex={indexChild}
                  uomList={uomList}
                  currencyList={currencyList}
                  billingParties={billingParties}
                  taxList={taxList}
                  onChangeInput={onUpdateInput}
                  onUpdateCharge={onUpdateCharge}
                  onUpdateService={onUpdateService}
                  disableCost={disableCost}
                  disableServiceRequest={disableServiceRequest}
                  disableOperatingIncome={disableOperatingIncome}
                  disableRevenue={disableRevenue}
                  disableBilling={disableBilling}
                  isSupplierInvoiceCNGenerated={isSupplierInvoiceCNGenerated}
                  isValidParty={isValidParty}
                  setValidParty={setValidParty}
                  serviceObj={serviceObj}
                  setServiceObj={setServiceObj}
                  onChangeDropdown={(type: string, elemId: any, event: any, serviceIndex: any, chargeIndex: any) => onUpdateDropdown(type, elemId, event, serviceIndex, chargeIndex)}
                />
              )
            } else {
              return null
            }

          })
        }
      </Content>
      <Footer>
        {hasMissingFields &&
          <span data-testid="jobGridValidation">
            {messages[0]?.message}
          </span>
        }
        <PegButton
          id="import_template"
          type="button"
          variant="Primary"
          styleObject={{ marginRight: '10px' }}
          onClick={onSubmit}>
          Update
        </PegButton>
        <PegButton
          id="import_template"
          type="button"
          variant="Primary"
          onClick={onClose}>
          Close
        </PegButton>

      </Footer>
    </Overview>
  )
};

export default Detail;
