
import {
  useState
} from 'react';
import {
  ChargeOverview,
  ChargeHeader,
  ChargeIcon,
  Row,
  Columns,
  Title,
  Value,
  DetailsView
} from './details.styles'
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import Tariff from './TariffBreakDown'
import { JOB_COLUMN } from 'Constants/ColumnFilterConstants';

type Props = {
  charge?: any;
  service?: any;
  type?: string;
};

const Charge = (props: Props) => {
  const {
    charge,
    service,
    type
  } = props;
  const [showDetails, setShowDetails] = useState(false);
  let opEstimated = 0;
  let estimatedAmount = 0;
  let estimatedTaxAmount = 0;
  let billAmount = 0;
  let billTax = 0;
  let offsetAmount = 0;
  let offsetTax = 0;
  let adjBillAmount = 0;
  let opActual = 0;
  let purchaseTaxAmt = 0;
  let costVariance = 0;
  const amountValue = charge?.UnitPrice * charge?.Quantity

  if (charge?.Quantity && charge?.UnitCost) {
    estimatedAmount = charge?.Quantity * charge?.UnitCost
  }

  estimatedTaxAmount = (estimatedAmount * charge.CostTaxPercent) / 100 + estimatedAmount;
  purchaseTaxAmt = estimatedAmount * charge.CostTaxPercent / 100

  if (amountValue && service?.BillingExchangeRate) {
    if (charge.PriceTaxPercent) {
      billTax = (amountValue * charge.PriceTaxPercent) / 100
      offsetTax = ((charge?.OffsetAmount || 0) * charge.PriceTaxPercent) / 100
    }

    const amountWithTax = amountValue + (billTax || 0);
    const offsetAmountWithTax = (charge?.OffsetAmount || 0) + offsetTax;

    const convertedBillTaxAmount = amountWithTax / (service?.BillingExchangeRate || 1)
    const convertedOffsetTaxAmount = offsetAmountWithTax / (service?.BillingExchangeRate || 1)
    billAmount = convertedBillTaxAmount;
    offsetAmount = convertedOffsetTaxAmount;
    adjBillAmount = billAmount - offsetAmount;
  }

  opEstimated = ((amountValue - (charge?.OffsetAmount || 0)) * charge.PriceExchangeRate) - (estimatedAmount * service.CostExchangeRate)
  opActual = ((amountValue - (charge?.OffsetAmount || 0)) * charge.PriceExchangeRate) - (charge.TotalEyeshareAmount * service.CostExchangeRate)
  costVariance = estimatedAmount - charge.TotalEyeshareAmount;

  return (
    <ChargeOverview>
      <ChargeHeader onClick={() => setShowDetails(!showDetails)}>
        {charge?.Name}
        <ChargeIcon>
          {
            showDetails ?
              <AiOutlineUp
                style={{ cursor: 'pointer', color: '#000' }} />
              :
              <AiOutlineDown
                style={{ cursor: 'pointer', color: '#000' }} />
          }
        </ChargeIcon>
      </ChargeHeader>
      {
        showDetails &&
        <DetailsView>
          <Row>
            <Columns>
              <Title>
                {JOB_COLUMN.BILLING_PARTY.label}
              </Title>
              <Value>
                {type === 'job' ? charge?.OperationalProcessPartyFk : charge?.BillingPartyName}
              </Value>
            </Columns>
            <Columns>
              <Title>
                {JOB_COLUMN.QUANTITY.label}
              </Title>
              <Value>
                {charge?.Quantity}
              </Value>
            </Columns>
          </Row>
          <Row>
            <Columns>
              <Title>
                {JOB_COLUMN.UOM.label}
              </Title>
              <Value>
                {charge?.UomName}
              </Value>
            </Columns>
            <Columns>
              <Title>
                {JOB_COLUMN.OPERATING_INCOME.label} (Est.)
              </Title>
              <Value>
                {opEstimated}
              </Value>
            </Columns>
          </Row>
          <Row>
            <Columns>
              <Title>
                {JOB_COLUMN.ACTUAL_COST.label}
              </Title>
              <Value>
                {isNaN(opActual) ? 0 : opActual.toFixed(2)}
              </Value>
            </Columns>
            <Columns>
              <Title>
                {JOB_COLUMN.UNIT_PRICE.label}
              </Title>
              <Value>
                {charge?.UnitPrice}
              </Value>
            </Columns>
          </Row>

          <Row>
            <Columns>
              <Title>
                {JOB_COLUMN.AMOUNT.label}
              </Title>
              <Value>
                {amountValue}
              </Value>
            </Columns>
            <Columns>
              <Title>
                {JOB_COLUMN.TAX_RATE.label}
              </Title>
              <Value>
                {charge?.PriceTaxPercent} %
              </Value>
            </Columns>
          </Row>
          <Row>
            <Columns>
              <Title>
                {JOB_COLUMN.SALES_TAX_AMT.label}
              </Title>
              <Value>
                {billTax}
              </Value>
            </Columns>
            <Columns>
              <Title>
                {JOB_COLUMN.UNIT_COST.label}
              </Title>
              <Value>
                {charge?.UnitCost}
              </Value>
            </Columns>
          </Row>
          {type === 'job' &&
            <Row>
              <Columns>
                <Title>
                  {JOB_COLUMN.BILL_AMT.label}
                </Title>
                <Value>
                  {charge?.BillAmount?.toFixed(2)}
                </Value>
              </Columns>
              <Columns>
                <Title>
                  {JOB_COLUMN.COST_TAX_RATE.label}
                </Title>
                <Value>
                  {charge?.CostTaxPercent}
                </Value>
              </Columns>
            </Row>}
          {type === 'job' &&
            <Row>
              <Columns>
                <Title>
                  {JOB_COLUMN.PURCHASE_TAX.label}
                </Title>
                <Value>
                  {purchaseTaxAmt}
                </Value>
              </Columns>
              <Columns>
                <Title>
                  {JOB_COLUMN.AMT.label}
                </Title>
                <Value>
                  {charge?.EstimatedCost}
                </Value>
              </Columns>
            </Row>}
          {type === 'job' &&
            <Row>
              <Columns>
                <Title>
                  {JOB_COLUMN.AMT_WITH_TAX.label}
                </Title>
                <Value>
                  {estimatedTaxAmount}
                </Value>
              </Columns>
              <Columns>
                <Title>
                  {JOB_COLUMN.ACTUAL_COST.label}
                </Title>
                <Value>
                  {charge?.TotalEyeshareAmount}
                </Value>
              </Columns>
            </Row>}
          {type === 'job' &&
            <Row>
              <Columns>
                <Title>
                  Cost Variance
                </Title>
                <Value>
                  {costVariance}
                </Value>
              </Columns>
            </Row>}
          {
            charge?.TariffDetails?.TariffBreakdown &&
            <Tariff
              tariff={charge?.TariffDetails?.TariffBreakdown} />
          }
        </DetailsView>
      }
    </ChargeOverview>
  )
};

export default Charge;
