import {
  OverviewHeader,
  // list
  ID,
  Name,
  //revenue
  Quantity,
  UOM,
  UnitPrice,
  Currency,
  Amount,
  BillingCurrency,
  ExRate,
  Tax,
  SalesTaxAmt,
  BillAmount,
  AdjBillAmount,
  //cost
  Supplier,
  EstUnitCost,
  EstCurrency,
  EstExRate,
  EstTaxType,
  EstTax,
  EstAmt,
  EstAmtTax,
  // operating income 
  OperatingIncome,
  // eye_share
  EsActualCost,
  CostQty,
  CostUOM,
} from 'Containers/JobGrid/Jobs.styles';
import { Color } from 'Utils/Color';

type Props = {
  service?: any;
  charge: any;
  index: number;
  servIndex: number;
};

const ChargeViewMode = (props: Props) => {
  const {
    service,
    charge,
    index,
    servIndex } = props;
  const styleClass = "text-left text-xs font-medium text-gray-500 tracking-wider bg-gray-50";
  return (
    <OverviewHeader className={styleClass}>
      <ID >
        {servIndex + 1}.{index + 1}
      </ID>
      <Name>
        <div>{charge?.Name}</div>
      </Name>
      <OperatingIncome groupColor={Color.operatingIncome}>{charge?.OperatingIncomeActual}</OperatingIncome>
      <UOM>{charge?.UomName}</UOM>
      <Quantity>{charge?.Quantity}</Quantity>
      <UnitPrice groupColor={Color.revenue}>{charge?.UnitPrice}</UnitPrice>
      <Currency groupColor={Color.revenue}>
        {service?.PriceCurrencyCode}
      </Currency>
      <Amount groupColor={Color.revenue}>{charge?.TotalPriceAmount}</Amount>
      <BillingCurrency groupColor={Color.revenue}>
        {service?.BillingCurrencyCode}
      </BillingCurrency>
      <ExRate groupColor={Color.revenue}>
        {service?.BillingExchangeRate}
      </ExRate>
      <Tax groupColor={Color.revenue}>{charge?.PriceTaxCode}</Tax>
      <SalesTaxAmt groupColor={Color.revenue}>{charge?.PriceTaxAmount}</SalesTaxAmt>
      <BillAmount groupColor={Color.revenue}>{charge?.BillAmount}</BillAmount>
      <AdjBillAmount isService groupColor={Color.revenue} />
      <Supplier groupColor={Color.cost} />
      <EstUnitCost groupColor={Color.cost}>{charge?.UnitCost}</EstUnitCost>
      <EstCurrency groupColor={Color.cost}>{service?.CostCurrencyCode}</EstCurrency>
      <EstExRate groupColor={Color.cost}>{service?.CostExchangeRate}</EstExRate>
      <EstTaxType groupColor={Color.cost}>{charge?.CostTaxCode}</EstTaxType>
      <EstTax groupColor={Color.cost}>{charge?.CostTaxAmount}</EstTax>
      <EstAmt groupColor={Color.cost}>{charge?.EstimatedCost}</EstAmt>
      <EstAmtTax groupColor={Color.cost}>{charge?.TotalEstimatedCost}</EstAmtTax>
      <EsActualCost groupColor={Color.cost}>{charge?.TotalEyeshareAmount}</EsActualCost>
    </OverviewHeader>
  )
};

export default ChargeViewMode;