import React, { useState, useEffect, useRef } from 'react'

type MyProps = {
    steps: any;
    currentStepNumber: any;
}


const Stepper: React.FC<MyProps> = ({ steps, currentStepNumber }) => {

    const [stepperSteps, setStep] = useState([]);
    const stepsStateRef = useRef();
    /* istanbul ignore next */
    useEffect(() => {
        const stepsState = steps.map((step: any, index: number) => {
            const stepObj: any = {};
            stepObj.description = step;
            stepObj.completed = false;
            stepObj.highlighted = index === 0 ? true : false;
            stepObj.selected = index === 0 ? true : false;
            return stepObj;
        });

        stepsStateRef.current = stepsState;

        const currentSteps: any = updateStep(currentStepNumber - 1, stepsState)
        setStep(currentSteps)
    }, []);

    /* istanbul ignore next */
    useEffect(() => {
        const currentSteps: any = updateStep(currentStepNumber - 1, stepsStateRef.current)
        setStep(currentSteps)
    }, [currentStepNumber]);

    /* istanbul ignore next */
    function updateStep(stepNumber: any, step: any) {
        const newSteps = [...step];

        let stepCounter: number = 0;
        while (stepCounter < newSteps.length) {
            //current step 
            if (stepCounter === stepNumber) {
                newSteps[stepCounter] = {
                    ...newSteps[stepCounter],
                    highlighted: true,
                    selected: true,
                    completed: false
                };
                stepCounter = stepCounter + 1;
            }
            // Past step
            else if (stepCounter < stepNumber) {
                newSteps[stepCounter] = {
                    ...newSteps[stepCounter],
                    highlighted: false,
                    selected: true,
                    completed: true
                };
                stepCounter = stepCounter + 1;
            }
            // Future steps 
            else {
                newSteps[stepCounter] = {
                    ...newSteps[stepCounter],
                    highlighted: false,
                    selected: false,
                    completed: false
                }
                stepCounter = stepCounter + 1;
            }
        }
        return newSteps
    }


    const stepsDisplay = stepperSteps.map((step: any, index: number) => {

        return (
            <div key={index}
                className={index !== stepperSteps.length - 1 ? "w-full flex items-center" : "flex items-center"} >
                <div className="relative flex flex-col items-center text-teal-600">
                    <div className={`rounded-full transition duration-500 ease-in-out border-2 border-gray-300 h-12 w-12 flex items-center justify-center py-3  ${step.selected ? "bg-white text-primary font-bold" : ""}`}>
                        {step.completed ? <span className="text-primary font-bold text-xl">&#10003;</span> : index + 1}
                    </div>
                    <div className={`absolute top-0  text-center mt-16 w-32 text-xs font-medium uppercase ${step.highlighted ? "text-primary" : "text-primary"}`}> {step.description}   </div>
                </div>
                <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-gray-300 "> </div>
            </div>
        )
    })

    return (
        <div className="mx-4 p-4 flex justify-between items-center">
            {stepsDisplay}
        </div>
    )
}

export default Stepper;
