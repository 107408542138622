
import { useCallback } from 'react';
import { Overview } from '../../services.styles';
import InputBox from 'Controls/Input/PegInputBox';
import Dropdown from 'Controls/Dropdown';
import DatePicker from 'Controls/DateRange';
import Validator from 'Controls/Validator';

type Props = {
  data: any;
  charge: any;
  type?: string;
  onClickExpand?: () => void;
  onChangeInput?: (event: any, id: any, type: any, service: any, charge: any) => void;
  onChangeDate?: (event: any, id: any, type: any, service: any, charge: any) => void;
  onChangeDropdown?: (event: any, id: any, type: any, service: any, charge: any) => void;
  url?: string;
  service?: any;
  deleteCharge: Function;
  isAllSelected?: any;
  selectedList?: any;
  isServiceSelected?: any;
  isChargeSelected?: any;
  onSelectOptions?: (service: any, charge: any) => void;
};

const Charge = (prop: Props) => {
  const {
    charge,
    service,
    data,
    onChangeDropdown,
    onChangeInput,
    onChangeDate,
    deleteCharge,
    type,
    isAllSelected,
    onSelectOptions,
    isServiceSelected,
    isChargeSelected
  } = prop;

  const onChangeInputValue = useCallback(
    (e: any, id: any) => {
      if (onChangeInput) {
        onChangeInput(e, id, type, service, charge);
      }
    },
    [onChangeInput, type, service, charge]
  );

  const onChangeDropdownValue = useCallback(
    (e: any, id: any) => {
      if (onChangeDropdown) {
        onChangeDropdown(e, id, type, service, charge);
      }
    },
    [onChangeDropdown, type, service, charge]
  );

  const onChangeDateValue = useCallback(
    (e: any, id: any) => {
      if (onChangeDate) {
        onChangeDate(e, id, type, service, charge);
      }
    },
    [onChangeDate, type, service, charge]
  );

  const inputStyle = {
    border: 'none',
    height: '36px',
    borderRadius: '0px',
    fontSize: '12px'
  }

  const renderData = () => {
    switch (data.key) {
      case 'costCurrency':
        return (
          <Dropdown
            id={data.key}
            type={'charge'}
            isLookup={false}
            defaultValue={charge.CostCurrencyName}
            dropdownLabel={'name'}
            dropdownValue={'name'}
            appendParams={false}
            style={{ width: 199 }}
            removeBorder={true}
            placeHolder="Select currency"
            url={`/mdm-currency?searchText=&code=`}
            onChangeDropdown={onChangeDropdownValue}
          />
        );

      case 'supplier':
        return <div />;

      case 'currency':
        return <div>AED</div>;

      case 'exRate':
        return <div>RO</div>;

      case 'localAmount':
        return <div>RO</div>;

      case 'estAmt':
        return <div>RO</div>;

      case 'estAmtTax':
        return <div>RO</div>;

      case 'name':
        return (
          <Dropdown
            id={data.key}
            type={'charge'}
            isLookup={false}
            defaultValue={charge.Name}
            dropdownLabel={'name'}
            dropdownValue={'name'}
            url={`getCharges?company-code=GDUB&service-code=`}
            style={{ width: 199 }}
            removeBorder={true}
            appendParams={true}
            placeHolder="Select charge"
            params={service && service.Code}
            onChangeDropdown={onChangeDropdownValue}
          />
        );

      case 'billingParty':
        return (
          <Dropdown
            id={data.key}
            type={'charge'}
            isLookup={true}
            defaultValue={charge.BillingPartyName}
            dropdownLabel={'name'}
            dropdownValue={'name'}
            appendParams={false}
            useDebounce={true}
            style={{ width: 199 }}
            removeBorder={true}
            placeHolder="Select Party"
            url={`/mdm-search-party?searchText=`}
            onChangeDropdown={onChangeDropdownValue}
          />
        );
      case 'UOM':
        return (
          <Dropdown
            id={data.key}
            type={'charge'}
            label={'name'}
            defaultValue={charge.UoMName}
            isLookup={false}
            dropdownLabel={'code'}
            dropdownValue={'name'}
            appendParams={false}
            style={{ width: 149 }}
            placeHolder="Select UOM"
            removeBorder={true}
            url={`/mdm-unit-of-measure?uomShortNames=&uomCodes=&searchText=`}
            onChangeDropdown={onChangeDropdownValue}
          />
        );

      case 'estUOM':
        return (
          <Dropdown
            id={data.key}
            type={'charge'}
            label={'name'}
            defaultValue={charge.UoMName}
            isLookup={false}
            dropdownLabel={'code'}
            dropdownValue={'name'}
            appendParams={false}
            style={{ width: 149 }}
            placeHolder="Select UOM"
            removeBorder={true}
            url={`/mdm-unit-of-measure?uomShortNames=&uomCodes=&searchText=`}
            onChangeDropdown={onChangeDropdownValue}
          />
        );

      case 'taxType':
        return (
          <Dropdown
            id={data.key}
            type={'charge'}
            label={'Name'}
            defaultValue={charge.TaxTypeName}
            isLookup={false}
            dropdownLabel={'Name'}
            dropdownValue={'Name'}
            appendParams={false}
            style={{ width: 149 }}
            placeHolder="Select tax type"
            removeBorder={true}
            url={`/oum-get-tax?company-code=GDUB`}
            onChangeDropdown={onChangeDropdownValue}
          />
        );

      case 'quantity':
        return (
          <InputBox
            type={'charge'}
            placeholder={'Enter quantity'}
            onChange={onChangeInputValue}
            style={inputStyle}
            id={data.key} />
        );

      case 'estUnitCost':
        return (
          <InputBox
            type={'charge'}
            placeholder={'Enter cost'}
            onChange={onChangeInputValue}
            style={inputStyle}
            id={data.key} />
        );
      case 'tax':
        return (
          <InputBox
            type={'charge'}
            placeholder={'Enter tax'}
            onChange={onChangeInputValue}
            style={inputStyle}
            id={data.key} />
        );
      case 'srActualCost':
        return (
          <InputBox
            type={'charge'}
            placeholder={'Enter cost'}
            onChange={onChangeInputValue}
            style={inputStyle}
            id={data.key} />
        );
      case 'amount':
        return (
          <InputBox
            type={'charge'}
            placeholder={'Enter amount'}
            onChange={onChangeInputValue}
            style={inputStyle}
            id={data.key} />
        );

      case 'unitPrice':
        return (
          <InputBox
            type={'charge'}
            placeholder={'Enter Price'}
            onChange={onChangeInputValue}
            style={inputStyle}
            id={data.key} />
        );

      case 'unitprice':
        return (
          <InputBox
            type={'charge'}
            placeholder={'Enter price'}
            onChange={onChangeInputValue}
            style={inputStyle}
            id={data.key} />
        );

      case 'discount':
        return (
          <InputBox
            type={'charge'}
            onChange={onChangeInputValue}
            placeholder={'Enter discount'}
            style={inputStyle}
            id={data.key} />
        );

      case 'margin':
        return (
          <InputBox
            type={'charge'}
            onChange={onChangeInputValue}
            placeholder={'Enter Margin'}
            style={inputStyle}
            id={data.key} />
        );

      case 'taxRate':
        return (
          <InputBox
            type={'charge'}
            onChange={onChangeInputValue}
            placeholder={'Enter Tax'}
            style={inputStyle}
            id={data.key} />
        );

      case 'remarks':
        return (
          <InputBox
            type={'charge'}
            onChange={onChangeInputValue}
            style={{
              border: 'none',
              height: '36px',
              borderRadius: '0px',
              fontSize: '12px'
            }}
            id={data.key} />
        );

      case 'startDate':
        return (
          <Validator>
            <DatePicker
              id={data.key}
              type={'charge'}
              style={{ height: '36px' }}
              onChange={onChangeDateValue} />
          </Validator>
        );

      case 'endDate':
        return (
          <DatePicker
            id={data.key}
            type={'charge'}
            style={{ height: '36px' }}
            onChange={onChangeDateValue} />
        );

      case 'id':
        return (
          <div>
            <input
              type="checkbox"
              disabled={true}
              checked={isAllSelected || isServiceSelected || isChargeSelected}
              onChange={() => onSelectOptions && onSelectOptions(service, charge)}
              style={{ cursor: 'pointer', marginRight: '8px' }}
            />
            {service.ServiceDetailId}.{charge.ServiceDetailId}
          </div>
        )
      case 'delete':
        return (
          <div
            onClick={() => deleteCharge(service, charge)}
            style={{ cursor: 'pointer' }}>
            X
          </div>
        );

      case 'expand':
        return <div />;

      case 'operatingIncome':
        return <div>2409</div>;

      case 'netprice':
        return <div>2418</div>;

      case 'netPriceLocal':
        return <div>2134</div>;

      case 'netPriceSelling':
        return <div>2134</div>;

      case 'unitCost':
        return <div />;

      case 'priceCurrency':
        return <div>AED</div>;

      case 'totalCost':
        return <div>2312</div>;

      default:
        return <div />;
    }
  };

  return <Overview>{renderData()}</Overview>;
};

export default Charge;
