import styled from "styled-components";

interface IStylesProps {
  isHeader?: boolean;
  isService?: boolean;
  isSticky?: boolean;
  groupColor?: any;
  colour?: any;
  showBorder?: boolean;
  value?: any;
}
export const Row = styled.div<IStylesProps>`
  display: flex;
  font-size: 12px;
  text-align: center;
  padding 4px;
  justify-content: center;
  align-items: center;
  height: 36px;
  background-color: ${(props) => (props.isHeader ? '#C1C1C1' : '#FFF')};
  border-bottom: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
`;

export const ColumnModalOverview = styled.div<IStylesProps>`
width: 80vw;
height: 80vh;
`;
export const ColumnModalFooter = styled.div`
width: 100%;
height: 60px;
position: absolute;
border-top: 2px solid #D8D8D8;
bottom: 0px;
display: flex;
justify-content: flex-end;
padding: 12px;
padding-right: 24px;
`;

export const Content = styled.div<IStylesProps>`
height: calc(70vh - 100px);
overflow: auto;
padding 24px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding-right: 16px;
  border-bottom: 0px solid #C1C1C1;
`;
export const ListOverview = styled.div`
column-count: 3;
border: 1px solid #C1C1C1;
margin-top: 12px;
padding: 24px;
`;


export const ExpandCollapse = styled(Row)`
  min-width: 100px;
`;