export interface IVesselDetail {
  VesselId: number;
  Version: number;
  VesselImoCode: string;
  VesselName: string;
  VesselTypeCode: string;
  VesselTypeName: string;
  GrossTonnage: number | null;
  NetTonnage: number | null;
  SummerDWT: number | null;
  Loa: number | null;
  CargoTypeCode: string;
  CargoTypeName: string;
  ETA: Date | null;
  ETD: Date | null;
  ETB: Date | null;
  ETC: Date | null;
  CallTypeCode: string;
  CallTypeName: string;
  CallSubTypeCode: string;
  CallSubTypeName: string;
  CallPurposeCode: string;
  CallPurposeName: string;
  IndustryVerticalCode: string;
  IndustryVerticalName: string;
  IsCargoRelated: string;
  PreviousPortCode: string;
  PreviousPortName: string;
  NextPortCode: string;
  NextPortName: string;
  IsActive: boolean;
}

export class VesselDetail implements IVesselDetail {
  VesselId: number;

  Version: number;

  VesselImoCode: string;

  VesselName: string;

  VesselTypeCode: string;

  VesselTypeName: string;

  GrossTonnage: number | null;

  NetTonnage: number | null;

  SummerDWT: number | null;

  Loa: number | null;

  CargoTypeCode: string;

  CargoTypeName: string;

  CallSubTypeCode: string;

  CallSubTypeName: string;

  IsCargoRelated: string;

  ETA: Date | null;

  ETB: Date | null;

  ETC: Date | null;

  ETD: Date | null;

  CallTypeCode: string;

  CallTypeName: string;

  CallPurposeCode: string;

  CallPurposeName: string;

  PreviousPortCode: string;

  PreviousPortName: string;

  IndustryVerticalCode: string;

  IndustryVerticalName: string;

  NextPortCode: string;

  NextPortName: string;

  IsActive: boolean;

  constructor() {
    this.VesselId = 0;
    this.Version = 0;
    this.VesselImoCode = '';
    this.VesselName = '';
    this.VesselTypeCode = '';
    this.VesselTypeName = '';
    this.GrossTonnage = null;
    this.NetTonnage = null;
    this.SummerDWT = null;
    this.Loa = 0;
    this.CargoTypeCode = '';
    this.CargoTypeName = '';
    this.ETA = new Date();
    this.ETB = new Date();
    this.ETC = new Date();
    this.ETD = new Date();
    this.CallTypeCode = '';
    this.CallTypeName = '';
    this.CallSubTypeCode = '';
    this.CallSubTypeName = '';
    this.CallPurposeCode = '';
    this.CallPurposeName = '';
    this.IsCargoRelated = '';
    this.PreviousPortCode = '';
    this.PreviousPortName = '';
    this.NextPortCode = '';
    this.NextPortName = '';
    this.IndustryVerticalCode = '';
    this.IndustryVerticalName = '';
    this.IsActive = true;
  }
}
