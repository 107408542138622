import styled from 'styled-components';

export const Overview = styled.div`
  display: flex;
`;

export const OverviewHeader = styled.div`
    display: flex;
`;

export const HeaderView = styled.div`
  border: 1px solid #dadfe3;
  border-left: 0px;
  border-top: 0px;
  font-size: 12px;
  line-height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  height: 38px;
  background-color: #FFF;
`;

export const ID = styled(HeaderView)`
  min-width: 60px !important;
  overflow: hidden;
  justify-content: left;
  padding-left: 12px;
`;

export const Expand = styled(HeaderView)`
  min-width: 35px;
`;
export const Delete = styled(HeaderView)`
  min-width: 35px;
  cursor: pointer;
`;

export const Billing = styled(HeaderView)`
  min-width: 200px;
`;
export const ServiceName = styled(HeaderView)`
  min-width: 200px !important;
`;

export const Supplier = styled(HeaderView)`
  min-width: 200px;
`;
export const Quantity = styled(HeaderView)`
  min-width: 150px;
`;
export const UOM = styled(HeaderView)`
  min-width: 150px;
`;
export const InternalRemarks = styled(HeaderView)`
  min-width: 150px;
`;
export const Enddate = styled(HeaderView)`
  min-width: 150px;
`;
export const Startdate = styled(HeaderView)`
  min-width: 150px;
`;
export const Totalcost = styled(HeaderView)`
  min-width: 150px;
`;
export const Costcurrency = styled(HeaderView)`
  min-width: 200px;
`;
export const Unitcost = styled(HeaderView)`
  min-width: 150px;
`;
export const NetSelling = styled(HeaderView)`
  min-width: 150px;
`;
export const NetLocal = styled(HeaderView)`
  min-width: 150px;
`;
export const Taxrate = styled(HeaderView)`
  min-width: 150px;
`;
export const Taxtype = styled(HeaderView)`
  min-width: 150px;
`;
export const Netprice = styled(HeaderView)`
  min-width: 200px;
`;
export const Margin = styled(HeaderView)`
  min-width: 150px;
`;
export const Discount = styled(HeaderView)`
  min-width: 150px;
`;
export const Unitprice = styled(HeaderView)`
  min-width: 200px;
`;
export const OpertionIncome = styled(HeaderView)`
  min-width: 150px;
`;


export const ServiceGridOverview = styled.div`
    width: 100%;
    background-color: #ffffff;
    padding: 12px 0px;
`;

export const ServiceGridOptions = styled.div`
    display: flex;
    margin: 12px 0px 24px 0px;
`;

export const TableOverview = styled.div`
    overflow: auto;
    padding-bottom: 12px;
    border: 1px solid #dadfe3;
    border-radius: 4px;
`;

export const GridOverview = styled.div`
   max-width: 100% !important;
`;

export const MandatoryFields = styled.div`
    color: red;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
`;

export const OptionsView = styled.div`
  margin-left: auto; 
  display: flex; 
`;

export const CheckboxView = styled.div`
  margin-left: auto; 
  display: flex; 
`;

export const Servives = styled.div`
 padding: 24px;
`
export const OptionName = styled.div`
  font-weight: bold;
  font-size: 16px;
`




