import styled from "styled-components";

interface IStylesProps {
  isHeader?: boolean;
  isService?: boolean;
  isSticky?: boolean;
  groupColor?: any;
  colour?: any;
  showBorder?: boolean;
  value?: any;
}

const getStyledRowData = (minWidth: any, maxWidth: any) => styled(RowData)`
  min-width: ${minWidth};
  max-width: ${maxWidth};
`;

export const ProductList = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`;

export const Title = styled.div`
   font-size: 18px;
   margin-bottom 12px;
`;

export const Layout = styled.div`
background-color: #FFF;
   padding: 16px;
`;

export const ListOverview = styled.div`
  width: 100%;
  overflow: auto;
  max-height: calc(100vh - 250px);
  margin-bottom: 20px;
  padding-bottom: 12px;
`;

export const Row = styled.div<IStylesProps>`
display: flex;
font-size: 12px;
font-weight: ${(props) => (props.isHeader ? '500' : '400')};
justify-content: center;
align-items: center;
min-height: 36px;
background-color: ${(props) => (props.isHeader ? '#f4f5f8' : props.isService ? "#FFF" : '#FFF')};
border-bottom: 1px solid #d8d8d8;
border-right: 1px solid #d8d8d8;
`;

export const RowData = styled(Row)`
background-color: ${(props) => (props.groupColor)};
height: ${(props) => (props.isHeader ? '36px' : 'auto')};
`;

export const OverviewHeader = styled.div`
    display: flex;
`;

export const HeaderView = styled.div`
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index:100;
`;

export const Grid = styled.div`
    border: 1px solid #D8D8D8;
    border-radius: 4px;
    padding-bottom: 16px;
    margin-bottom: 12px;
    overflow: auto;
    height: calc(100vh - 240px);
`;

export const JobServices = styled.div`
  background-color: #FFF;
  padding: 16px 12px; 
`;

export const Overview = styled.div`
  display: flex;
`;

// common styles 

export const minMaxWidth100px = getStyledRowData(`100px`, `100px`)

export const minMaxWidth200px = getStyledRowData(`200px`, `200px`)

export const minMaxWidth150px = getStyledRowData(`150px`, `150px`)

export const minWidth35px = styled(RowData)`
min-width: 35px;
`;

export const NameHeader = styled(RowData)`
  min-width: 200px;
  position: sticky;
  left: 60px;
  z-index: 1;
`;

export const ID = styled(RowData)`
  min-width: 60px;
  position: sticky;
  left: 0px;
  z-index: 1;
`;

export const Delete = styled(minWidth35px)``;

export const Add = styled(minWidth35px)``;

export const Expand = styled(minWidth35px)``;

export const Detail = styled(minWidth35px)``;

export const Name = styled(RowData)`
  min-width: 200px;
  max-width: 200px;
  z-index: 1;
  position: sticky;
  left: 0px;
`;

export const CustomerService = styled(minMaxWidth200px)``;

export const BillingParty = styled(minMaxWidth200px)``;

export const BillingPartyName = styled(RowData)`  
  min-width: 200px;
  max-width: 200px;
  padding-left: 5px;  
  display: flex;
  justify-content: flex-start;
  white-space: nowrap;
`;
export const BillingPartyAddress = styled(RowData)`
  min-width: 220px;
  max-width: 220px;
  padding-left: 5px;  
  display: flex;
  justify-content: ${(props) => (props.isHeader ? 'center' : 'flex-start')};
  white-space: nowrap;
`;

export const StartDate = styled(RowData)`
  min-width: 220px;
  max-width: 220px;
`;
export const EndDate = styled(RowData)`
  min-width: 220px;
  max-width: 220px;
`;
export const OperatingIncome = styled(minMaxWidth150px)``;

export const UOM = styled(minMaxWidth150px)``;

export const Quantity = styled(minMaxWidth100px)``;

export const UnitPrice = styled(minMaxWidth100px)``;

export const InternalRemarks = styled(minMaxWidth150px)``;

export const ExternalRemarks = styled(minMaxWidth150px)``;

export const PriceCurrency = styled(RowData)`
  min-width: 150px;
  max-width: 150px;
  justify-content: ${(props) => (props.isHeader ? 'center' : 'flex-start')};
`;

export const CostCurrency = styled(RowData)`
  min-width: 150px;
  max-width: 150px;
  justify-content: ${(props) => (props.isHeader ? 'center' : 'flex-start')};
`;

export const TextFieldContainer = styled(RowData)`
padding-left: 8px;
border: 0;
background-color: ${(props) => (props.groupColor)};
`;

export const EstTaxType = styled(minMaxWidth150px)``;
export const EstTax = styled(minMaxWidth100px)``;
export const EstAmt = styled(RowData)`
  min-width: 100px;
  max-width: 150px;
`;
export const EstAmtTax = styled(minMaxWidth150px)``;

export const UnitCost = styled(minMaxWidth100px)``;

export const TotalCost = styled(minMaxWidth150px)``;

export const TotalCostLocal = styled(minMaxWidth200px)``;

export const BackToBack = styled(RowData)`
  min-width: 100px;
`;

export const Supplier = styled(RowData)`
  min-width: 200px;
`;

export const Discount = styled(RowData)`
  min-width: 100px;
`;

export const Margin = styled(minMaxWidth100px)``;

export const TaxType = styled(minMaxWidth150px)``;

export const TaxRate = styled(minMaxWidth100px)``;

export const NetPrice = styled(minMaxWidth150px)``;

export const NetPriceLocal = styled(minMaxWidth150px)``;

export const NetPriceSelling = styled(minMaxWidth150px)``;

export const Group = styled.div<IStylesProps>`
  z-index: ${(props) => (props.isSticky ? '1' : '')};
  position: ${(props) => (props.isSticky ? 'sticky' : '')};
  left: ${(props) => (props.isSticky ? '0px' : '')};
  background-color: #f4f5f8;
`;

export const SecondaryGroup = styled(Group)`
  left: ${(props) => (props.isSticky ? '60px' : '')};
`;

export const GroupName = styled.div<IStylesProps>`
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: ${(props) => (props.groupColor ? `3px solid ${props.groupColor}` : `3px solid '#f4f5f8'`)};
`;

export const Flex = styled.div<IStylesProps>`
  display: flex;
`;

export const GridOptions = styled.div`
    display: flex;
    align-items: center;
    height: 50px;
`;

export const GroupOptions = styled.div<IStylesProps>`
   padding: 2px 4px;
   height: 28px;
   width: 140px;
   margin-right : 12px;
   background-color: ${(props) => (props.colour ? props.colour : '')};
   cursor: pointer;
   border-radius: 16px;
   display: flex;
   justify-content: center;
   align-items: center;
   span{
     padding: 0px 4px;
     font-size: 11px;
     font-weight: 500;
     text-transform: uppercase;
   }
`;

export const MarginLeft = styled.div<IStylesProps>`
  display: flex;
  margin-left: auto;
  align-items: center;
  span{
    color: red;
    margin-right: 12px;
    font-size: 14px;
  }
`;

export const LoaderOverview = styled.div<IStylesProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 75px);
`;

export const TemplateOverview = styled.div<IStylesProps>`
  display: flex;
  width: 79vw;
  height: 65vh;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
`;

export const Options = styled.div<IStylesProps>`
  min-width: 25%;
  border-right: 1px solid #d8d8d8;
  overflow: scroll;
  padding: 12px 0px;
`;

export const OptionsLoading = styled.div<IStylesProps>`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TemplateSection = styled.div<IStylesProps>`
  width: 75%; 
`;

export const GridView = styled.div<IStylesProps>`
  height: calc(65vh - 70px);
  margin: 12px;
  width: 96%;
`;

export const GridViewLoading = styled(GridView) <IStylesProps>`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Footer = styled.div<IStylesProps>`
  display: flex;
`;

export const ListName = styled.div<IStylesProps>`
  font-size: 12px;
  border-bottom: 1px solid #d8d8d8;
  margin: 8px;
  padding-bottom: 4px;
  cursor: pointer;
`;

export const Green = styled.div<IStylesProps>`
  color: green;
`;

export const Red = styled.div<IStylesProps>`
  color: red;
`;

export const TemplateModalOverview = styled.div<IStylesProps>`
  overflow: scroll;
  padding-bottom: 12px;
`;
export const QuotationAlert = styled.div`
  text-align: center;
  padding: 12px;
  font-size: 18px;
  font-weight: 400;
`
export const ErrorMessage = styled.div`
  color: red;
  font-size: 14px;
  margin-right: 8px;
  font-weight: 400;
`
export const NumberToColor = styled.div<IStylesProps>`
color: ${(props) => (props.value < 0 ? '#d40606' : '#04922a')}`;

export const LoaderErrorMessage = styled.div`
  position: absolute;
  margin-top: -100px;
  color: red;
  font-size: 1.2em;
  font-weight: 400;
`
export const SummaryDetails = styled.div`
display: flex;`