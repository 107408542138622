import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { quoteProps } from './modal';
import { QUOTATION_COLUMN } from 'Constants/ColumnFilterConstants';
import { QuotationService } from 'Model/QuotationGrid/QuotationService';
import { QuotationCharge } from 'Model/QuotationGrid/QuotationCharge';
import { useDispatch, useSelector } from 'react-redux';
import {
    getQuotationServices,
    onUpdateService,
    onUpdateCharge,
    onAddService,
    onDeleteService,
    onAddCharge,
    onDeleteCharge,
    saveQuotationServices,
    onBulkUpdate,
    onImportTemplate,
    onUpdateTariff,
    onMultiDeleteServices,
    updateChargeValidation,
} from 'Redux/Actions/QuoteGrid';
import { getUserDetail } from 'Redux/Actions/User';
import { getUserAccount } from 'Utils/AuthConfig';
import { useParams } from 'react-router';
import axios from 'axios';
import { toast } from 'react-toastify';
import { IProduct, IServiceAndCharges } from 'Model/QuotationGrid/types';

import Loader from 'Components/Loader';
import PegModal from 'Controls/PegModal';
import ServiceOverview from './ServiceOverview';
import EditForm from './components/EditForm/EditForm';
import { LoaderOverview, Layout, QuotationAlert, LoaderErrorMessage } from './quote.styles';
import QuotationSummary from 'Components/QuotationSummary';
import TariffModal from 'Components/TariffModal';
import PegButton from 'Controls/Button/PegButton';
import GridErrors from 'Components/GridErrors';
import Preferences from 'Components/ColumnPreference';
import { ColumnPreferences } from 'Model/Common/ColumnPreferences';
import { IExchangeRate, IGridErrorData, IValidationConfig } from 'Model/Common/types';
import { getGridErrorData } from 'Components/GridErrors/utils';
import { validateTemplate } from 'Utils/Generic';
import { ITemplate } from 'Model/Job/types';
import { applyTariff, onBulkEdit, onBulkEditAll, transformQuotationGrid } from 'Utils/QuotationGrid';
import { BsCheckCircle } from 'react-icons/bs';
import moment from 'moment';
import { getExchangeRates as getExchangeRatesUtil } from 'Utils/Generic';
import ServiceOverviewRecode from './ServiceOverviewRecode';

const QuotationGridRecode: React.FC<quoteProps> = (props) => {
    const dispatch = useDispatch();
    const params: any = useParams();

    /** Redux state */
    const quotationDetails = useSelector((state: any) => state.QuoteGrid.quotationDetails);
    const services = useSelector((state: any) => state.QuoteGrid.services);
    const isLoading = useSelector((state: any) => state.QuoteGrid.isLoading);
    const isRefreshLoader = useSelector((state: any) => state.QuoteGrid.isRefreshLoader);
    const message = useSelector((state: any) => state.QuoteGrid.message);
    const isError = useSelector((state: any) => state.QuoteGrid.isError);
    const serviceValidation = useSelector((state: any) => state.QuoteGrid.serviceValidation);
    const chargeValidation = useSelector((state: any) => state.QuoteGrid.chargeValidation);
    const tariffServiceValidation = useSelector((state: any) => state.QuoteGrid.tariffServiceValidation);
    const tariffChargeValidation = useSelector((state: any) => state.QuoteGrid.tariffChargeValidation);
    const isQuotationSaving = useSelector((state: any) => state.QuoteGrid.isQuotationSaving);
    const isQuotationSaved = useSelector((state: any) => state.QuoteGrid.isQuotationSaved);
    const isViewer = useSelector((state: any) => state.QuoteGrid.isViewer);
    const userDetails = useSelector((state: any) => state.user.userDetail);

    /** Local component state */
    const [innerSelectedProduct, setSelectedProduct] = useState<IProduct>(services[0]);
    const [hasMissingFields, setHasMissingFields] = useState(false);
    const [selectedList, setSelectedList] = useState<number[]>([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [billingParties, setBillingParties] = useState([]);
    const [taxList, setTaxList] = useState([]);
    const [exchangeRates, setExchangeRates] = useState([]);
    const [exchangeRatesLoading, setExchangeRatesLoading] = useState(true);
    const [loading, setLoading] = useState(true);
    const [showTarrifModal, setShowTarrifModal] = useState(false);
    const [tariffData, setTariffData] = useState<QuotationCharge | null>(null);
    const [applyingTarriff, setApplyingTarriff] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [bulkEditService, setBulkEditService] = useState<any>({} as QuotationService);
    const [bulkEditCharge, setBulkEditCharge] = useState({} as QuotationCharge);
    const [disableRevenue, setDisableRevenue] = useState(false);
    const [disableCost, setDisableCost] = useState(false);
    const [disableOperatingIncome, setDisableOperatingIncome] = useState(false);
    const [showTemplateModal, setShowTemplateModal] = useState(false);
    const [gridErrors, setGridErrors] = useState<IGridErrorData[]>();
    const [exchangeError, setExchangeError] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [columnPreference, setColumnPreference] = useState(QUOTATION_COLUMN);
    const [showVesselMeasureModal, setShowVesselMeasureModal] = useState(false);

    const showVesselDetails = useMemo(
        () => quotationDetails?.VesselImoNumber && quotationDetails?.VesselImoNumber?.toString().trim().length > 0 && quotationDetails?.VesselImoNumber !== 'N/A',
        [quotationDetails]
    );

    /** Functionality */
    const getTaxes = useCallback(async () => {
        try {
            const { data } = await axios.get(`/oum-get-tax?company-code=${quotationDetails.CompanyCode}`);
            setTaxList(data);
        } catch (e: any) {
            toast.error(e.data.message ?? 'Failed to get taxes', {
                position: 'top-center',
                autoClose: 7000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
                style: { width: '400px' },
            });
        }
    }, [quotationDetails.CompanyCode, setTaxList]);

    const getBillingParties = useCallback(async () => {
        try {
            const { data } = await axios.get(`/qtn-getBillingParties?QuotationId=${params.id}`);

            const filteredParties = data.filter((el: any) => el?.QuotationProductId === innerSelectedProduct?.ProductDetails?.QuotationProductId);

            setBillingParties(filteredParties[0]?.Parties);
        } catch (e: any) {
            toast.error(e.data.message ?? 'Failed to get taxes', {
                position: 'top-center',
                autoClose: 7000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
                style: { width: '400px' },
            });
        }
    }, [params.id, innerSelectedProduct?.ProductDetails?.QuotationProductId, setBillingParties]);

    const getExchangeRates = useCallback(async () => {
        const exchangeErrorTimeout = setTimeout(() => {
            setExchangeError(true);
        }, 5000);

        try {
            const { data } = await axios.get(`/jobService-getAllCurrencyExchangeRates?company-code=${quotationDetails.CompanyCode}`);
            clearTimeout(exchangeErrorTimeout);
            setExchangeRates(data);
            setExchangeRatesLoading(false);
        } catch (e) {
            setExchangeError(true);
            setExchangeRatesLoading(false);
        }
    }, [quotationDetails.CompanyCode, setExchangeError, setExchangeRates, setExchangeRatesLoading]);

    const filterServices = useCallback(async () => {
        await getTaxes();
        await getBillingParties();
        await getExchangeRates();

        setLoading(false);
    }, [getTaxes, getBillingParties, getExchangeRates, setLoading]);

    const checkValidation = useCallback(
        (list: IServiceAndCharges[], type: string) => {
            let isValidated = true;
            let serValidation = serviceValidation;
            let charValidation = chargeValidation;

            let errors: IGridErrorData[] = [];

            if (type === 'tariff') {
                serValidation = tariffServiceValidation;
                charValidation = tariffChargeValidation;
                list?.forEach((item: IServiceAndCharges) => {
                    if (item?.service?.IsActive) {
                        Object.keys(serValidation).forEach(function (key) {
                            const service = item?.service[key as keyof typeof item.service];
                            if (!service || !Object.keys(service).length) {
                                isValidated = false;
                                errors.push(getGridErrorData({ service: item?.service, key, type: 'required' }));
                            }
                        });
                    }
                    item.charges.forEach((charge: QuotationCharge) => {
                        if (charge.IsActive) {
                            Object.keys(charValidation).forEach(function (key: string) {
                                const chargeProperty = charge[key as keyof QuotationCharge];

                                if (key === 'BillingPartyCode') {
                                    return;
                                }

                                if (key === 'UnitCost') {
                                    if (isNaN(chargeProperty) || chargeProperty === null || chargeProperty === undefined || chargeProperty === '') {
                                        isValidated = false;
                                        errors.push(getGridErrorData({ service: item?.service, charge, key, type: 'required' }));
                                    }
                                } else if (key === 'CustomerService') {
                                    const customerServiceValidation = charValidation[key] as IValidationConfig;
                                    const shouldValidateCharge = customerServiceValidation[charge.Code as string];

                                    if (!charge.Id && shouldValidateCharge && charge.GSServiceCode === '') {
                                        isValidated = false;
                                        errors.push(getGridErrorData({ service: item?.service, charge, key, type: 'required' }));
                                    }
                                } else if (key === 'dolphinCode') {
                                    const customerServiceValidation = charValidation[key] as IValidationConfig;
                                    const shouldValidateCharge = customerServiceValidation[charge.Code as string];

                                    if (charge.Id === 0 && shouldValidateCharge) {
                                        isValidated = false;
                                        errors.push(
                                            getGridErrorData({
                                                service: item?.service,
                                                charge,
                                                key: 'DolphinCode',
                                                type: 'dolphinCode',
                                                message: ' - (Note that the charge selected has missing dolphin codes. Please contact finance/accounts to check and resolve)',
                                            })
                                        );
                                    }
                                } else {
                                    if (!chargeProperty) {
                                        isValidated = false;
                                        errors.push(getGridErrorData({ service: item?.service, charge, key, type: 'required' }));
                                    }
                                }
                            });
                        }
                    });
                });
            } else {
                services.forEach((product: IProduct) => {
                    product.ServiceAndCharges?.forEach((item: IServiceAndCharges) => {
                        if (item?.service?.IsActive) {
                            Object.keys(serValidation).forEach(function (key) {
                                const serviceProperty = item?.service[key as keyof QuotationService];

                                if (!serviceProperty || !Object.keys(serviceProperty).length) {
                                    isValidated = false;
                                    errors.push(getGridErrorData({ product, service: item?.service, key, type: 'required' }));
                                }
                            });
                        }
                        item.charges.forEach((charge: QuotationCharge) => {
                            if (charge.IsActive) {
                                Object.keys(charValidation).forEach(function (key) {
                                    const chargeProperty = charge[key as keyof QuotationCharge];
                                    if (key === 'UnitCost') {
                                        if (isNaN(chargeProperty) || chargeProperty === null || chargeProperty === undefined || chargeProperty === '') {
                                            isValidated = false;
                                            errors.push(getGridErrorData({ product, service: item?.service, charge, key, type: 'required' }));
                                        }
                                    } else if (key === 'CustomerService') {
                                        const customerServiceValidation = charValidation[key] as IValidationConfig;
                                        const shouldValidateCharge = customerServiceValidation[charge.Code as string];

                                        if (!charge.Id && shouldValidateCharge && !charge?.GSServiceCode) {
                                            isValidated = false;
                                            errors.push(getGridErrorData({ product, service: item?.service, charge, key, type: 'required' }));
                                        }
                                    } else if (key === 'dolphinCode') {
                                        const customerServiceValidation = charValidation[key] as IValidationConfig;
                                        const shouldValidateCharge = customerServiceValidation[charge.Code as string];

                                        if (!charge.Id && shouldValidateCharge) {
                                            isValidated = false;
                                            errors.push(
                                                getGridErrorData({
                                                    product,
                                                    service: item?.service,
                                                    charge,
                                                    key: 'DolphinCode',
                                                    type: 'dolphinCode',
                                                    message: ' - (Note that the charge selected has missing dolphin codes. Please contact finance/accounts to check and resolve)',
                                                })
                                            );
                                        }
                                    } else {
                                        if (!chargeProperty) {
                                            isValidated = false;
                                            errors.push(getGridErrorData({ product, service: item?.service, charge, key, type: 'required' }));
                                        }
                                    }
                                });
                            }
                        });
                    });
                });
            }

            setGridErrors(errors);
            return isValidated;
        },
        [chargeValidation, serviceValidation, tariffChargeValidation, tariffServiceValidation, services, setGridErrors]
    );

    const onSaveServices = useCallback(() => {
        const isValidation = checkValidation([], 'save');
        if (isValidation) {
            let quotationObject = {
                QuotationDetails: { ...quotationDetails },
                Products: [...services] as IProduct[],
            };

            dispatch(saveQuotationServices(quotationObject));
        }

        setHasMissingFields(!isValidation);
    }, [checkValidation, dispatch, quotationDetails, services, setHasMissingFields]);

    const onClickOptions = useCallback(
        (type: string) => {
            switch (type) {
                case 'revenue':
                    setDisableRevenue(!disableRevenue);
                    break;
                case 'cost':
                    setDisableCost(!disableCost);
                    break;
                case 'OpIncome':
                    setDisableOperatingIncome(!disableOperatingIncome);
                    break;
                default:
                    break;
            }
        },
        [disableRevenue, disableCost, disableOperatingIncome, setDisableRevenue, setDisableCost, setDisableOperatingIncome]
    );

    const setSelectedArray = useCallback(
        (isAllSelected: boolean) => {
            const services = innerSelectedProduct?.ServiceAndCharges;
            if (isAllSelected) {
                let list: number[] = [];
                services?.forEach((item: IServiceAndCharges, index: number) => {
                    list.push(index);
                });
                setSelectedList(list);
            }
        },
        [innerSelectedProduct, setSelectedList]
    );

    const onSelectAll = useCallback(() => {
        setIsAllSelected(!isAllSelected);
        setSelectedArray(isAllSelected);
    }, [isAllSelected, setSelectedArray]);

    const onSelectServices = useCallback(
        (index: number) => {
            setSelectedList((prevSelectedList) => {
                if (!prevSelectedList.includes(index)) {
                    return [...prevSelectedList, index];
                } else {
                    return prevSelectedList.filter((item) => item !== index);
                }
            });

            setIsAllSelected(false);
        },
        [selectedList, setSelectedList, setIsAllSelected]
    );

    const onSubmitImportTemplate = useCallback(
        async (template: ITemplate[]) => {
            const { CustomerCode, CompanyCode } = quotationDetails;

            const { errors, services } = await validateTemplate(template, CompanyCode, CustomerCode);

            errors.forEach((error: any) => {
                toast.error(error.message, {
                    position: 'top-center',
                    autoClose: 7000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'dark',
                    style: { width: '400px' },
                });
            });

            let transformedData = transformQuotationGrid(services);

            dispatch(onImportTemplate(transformedData, innerSelectedProduct!));

            setShowTemplateModal(false);
        },
        [quotationDetails, innerSelectedProduct, dispatch]
    );

    const onApplyTariff = useCallback(async () => {
        let list: IServiceAndCharges[] = [];

        if (!isAllSelected) {
            list = selectedList.map((item: number) => innerSelectedProduct?.ServiceAndCharges[item]) as IServiceAndCharges[];
        } else {
            list = innerSelectedProduct?.ServiceAndCharges.filter((item: IServiceAndCharges) => item.service.IsActive) as IServiceAndCharges[];
        }

        let isValidated = checkValidation(list, 'tariff');

        setHasMissingFields(!isValidated);

        if (isValidated) {
            setApplyingTarriff(true);

            const serviceDetails = {
                productDetails: innerSelectedProduct?.ProductDetails,
                serviceAndCharges: [...list],
                quotationDetails: quotationDetails,
            };

            try {
                const { data } = await axios.post(`/quotation-getTariffDetails`, {
                    serviceDetails,
                });
                const updatedServices = applyTariff(selectedList, innerSelectedProduct?.ServiceAndCharges!, data, isAllSelected);

                dispatch(onUpdateTariff(innerSelectedProduct!, updatedServices));
                setApplyingTarriff(false);
                setSelectedList([]);
                setIsAllSelected(false);
            } catch (e: any) {
                setApplyingTarriff(false);
                setSelectedList([]);
                setIsAllSelected(false);
            }
        }
    }, [checkValidation, innerSelectedProduct, selectedList, quotationDetails, dispatch, setApplyingTarriff, setHasMissingFields, setIsAllSelected, isAllSelected]);

    const onEditOptionConfirm = useCallback(() => {
        const updatedList = isAllSelected
            ? onBulkEditAll(services, bulkEditService, bulkEditCharge, innerSelectedProduct as IProduct)
            : onBulkEdit(services, selectedList, bulkEditService, bulkEditCharge, innerSelectedProduct as IProduct);

        dispatch(onBulkUpdate(updatedList));
        setShowEditModal(false);
    }, [isAllSelected, services, bulkEditService, bulkEditCharge, innerSelectedProduct, dispatch, setShowEditModal, selectedList]);

    const getConvertionRate = useCallback(
        (fromCurrency: string): IExchangeRate | null => {
            const exchangeRate: IExchangeRate | null | undefined = getExchangeRatesUtil(exchangeRates, fromCurrency, quotationDetails?.BaseCurrencyCode);
            return exchangeRate ? exchangeRate : null;
        },
        [exchangeRates, quotationDetails?.BaseCurrencyCode]
    );

    const onEditOption = useCallback(
        (event: any, key: string) => {
            switch (key) {
                case 'taxType':
                    bulkEditCharge.PriceTaxCode = event ? event.Code : '';
                    bulkEditCharge.PriceTaxName = event ? event.Name : '';
                    bulkEditCharge.PriceTaxPercent = event ? event.Percentage : '';
                    break;

                case 'billingParty':
                    bulkEditCharge.BillingPartyCode = event ? event.Code : '';
                    bulkEditCharge.BillingPartyName = event ? event.Node : '';
                    bulkEditCharge.AddressFk = event ? event.AddressId[0] : '';
                    break;

                case 'startDate':
                    bulkEditService.StartDateTime = event ? moment(event?.value).utc().format() : '';
                    break;

                case 'endDate':
                    bulkEditService.EndDateTime = event ? moment(event?.value).utc().format() : '';
                    break;

                case 'priceCurrency':
                    bulkEditService.PriceCurrencyCode = event?.code;
                    bulkEditService.PriceCurrencyName = event?.name;
                    let PriceExchangeRate = quotationDetails?.QuoteCurrencyCode === event?.code ? quotationDetails?.QuoteCurrencyExRate : getConvertionRate(event.code);
                    bulkEditService.PriceExchangeRate = PriceExchangeRate ? PriceExchangeRate.ExchangeRate : 0;
                    break;

                case 'costCurrency':
                    bulkEditService.CostCurrencyCode = event?.code;
                    bulkEditService.CostCurrencyName = event?.name;
                    let costExRate = quotationDetails?.QuoteCurrencyCode === event?.code ? quotationDetails?.QuoteCurrencyExRate : getConvertionRate(event.code);
                    bulkEditService.CostExchangeRate = costExRate ? costExRate.ExchangeRate : 0;
                    break;

                default:
                    break;
            }
        },
        [bulkEditCharge, bulkEditService, quotationDetails?.QuoteCurrencyCode, quotationDetails?.QuoteCurrencyExRate, getConvertionRate]
    );

    const onDeleteModalConfirm = useCallback(() => {
        dispatch(onMultiDeleteServices(selectedList, isAllSelected, innerSelectedProduct!));

        setShowDeleteModal(false);
        setSelectedList([]);
        setIsAllSelected(false);
    }, [dispatch, selectedList, isAllSelected, innerSelectedProduct, setShowDeleteModal, setSelectedList, setIsAllSelected]);

    const onSavePreferences = useCallback(
        (list: ColumnPreferences) => {
            const columnObj: any = {
                [userDetails?.Username as string]: list,
            };
            localStorage.setItem('quotationColumnPreference', JSON.stringify(columnObj));

            setShowFilterModal(false);
            setColumnPreference(list);

            toast.success('Column Preferences Updated', {
                position: 'top-center',
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
                style: { width: '400px' },
            });
        },
        [userDetails?.Username, setColumnPreference, setShowFilterModal]
    );

    /** Effects */
    useEffect(() => {
        const username = getUserAccount('userName');
        dispatch(getUserDetail(username));
    }, [dispatch]);

    useEffect(() => {
        const quotationColumnPreference = localStorage.getItem('quotationColumnPreference') ?? null;

        if (userDetails && Object.keys(userDetails).length && !Object.keys(quotationDetails).length) {
            dispatch(getQuotationServices(params));

            if (quotationColumnPreference) {
                const jobColumnsKeys = Object.keys(QUOTATION_COLUMN);

                let columnObj = JSON.parse(localStorage.quotationColumnPreference);
                if (columnObj[userDetails?.Username]) {
                    const columnPreferencesKeys = Object.keys(columnObj[userDetails?.Username]);

                    const jobsColumnsKeysString = jobColumnsKeys.sort().join();
                    const columnPreferencesKeysString = columnPreferencesKeys.sort().join();

                    if (jobsColumnsKeysString === columnPreferencesKeysString) {
                        setColumnPreference(columnObj[userDetails?.Username]);
                    } else {
                        localStorage.removeItem('quotationColumnPreference');
                    }
                }
            }
        }
    }, [userDetails]);

    useEffect(() => {
        setSelectedProduct(services[0]);
    }, [quotationDetails]);

    useEffect(() => {
        filterServices();
    }, [innerSelectedProduct]);

    /** Core components and renderers */
    const _ModalContainer = () => (
        <>
            <div id="vesselMeasureModal">
                <QuotationSummary onClose={() => setShowVesselMeasureModal(false)} showVesselMeasureModal={showVesselMeasureModal} />
            </div>
            <div id="quotationSaving">
                <PegModal isOpen={isQuotationSaving} alertModal={true} showTemplate={true} isCenter={true}>
                    <div style={{ overflow: 'hidden', padding: 12 }} data-testid="saveQuotationGridModal">
                        <QuotationAlert>{isQuotationSaved ? 'Records have been saved successfully' : 'Please wait while saving'}</QuotationAlert>
                        {isQuotationSaved ? (
                            <BsCheckCircle
                                style={{
                                    height: '50px',
                                    width: '100%',
                                    color: '#2cd334',
                                }}
                            />
                        ) : (
                            <Loader size={50} color={'#adccf4'} />
                        )}
                    </div>
                </PegModal>
            </div>
            <div id="serviceGridEdit">
                <PegModal
                    isOpen={showEditModal}
                    closeModal={() => setShowEditModal(false)}
                    tertiaryAction={onEditOptionConfirm}
                    modalTitle={'Edit Items'}
                    buttonText={'Save'}
                    isCenter={true}
                    showTemplate={true}
                >
                    <EditForm onEditServices={(event: any, key: string) => onEditOption(event, key)} billingParties={billingParties} taxList={taxList} />
                </PegModal>
            </div>
            <div id="tariffModal">
                <PegModal
                    isOpen={showTarrifModal}
                    closeModal={() => {
                        setShowTarrifModal(false);
                        setTariffData(null);
                    }}
                    alertModal={true}
                    isCenter={true}
                    showTemplate={true}
                >
                    <TariffModal
                        charge={tariffData}
                        type="quotation"
                        closeModal={() => {
                            setShowTarrifModal(false);
                            setTariffData(null);
                        }}
                    />
                </PegModal>
            </div>
            <div id="deleteModal">
                <PegModal
                    isOpen={showDeleteModal}
                    closeModal={() => {
                        setShowDeleteModal(false);
                        setSelectedList([]);
                        setIsAllSelected(false);
                    }}
                    tertiaryAction={onDeleteModalConfirm}
                    modalTitle={'Delete Items'}
                    buttonText={'Delete'}
                    modalContent={'Are you sure to delete the selected items'}
                />
            </div>
            <div id="applyingTariffModal">
                <PegModal isOpen={applyingTarriff} alertModal={true} showTemplate={true} isCenter={true}>
                    <div style={{ overflow: 'hidden', padding: 12 }}>
                        <QuotationAlert>Applying Tarriff ...</QuotationAlert>
                        <Loader size={100} color={'blue'} />
                    </div>
                </PegModal>
            </div>
            <div id="filterColumnModal">
                <PegModal isOpen={showFilterModal} closeModal={() => setShowFilterModal(false)} alertModal={true} isCenter={true} showTemplate={true}>
                    <Preferences
                        details={quotationDetails}
                        type={'job'}
                        column={columnPreference}
                        onCloseFilterModal={() => setShowFilterModal(false)}
                        onSavePreferences={(list: ColumnPreferences) => onSavePreferences(list)}
                    />
                </PegModal>
            </div>

            {isRefreshLoader && (
                <div id="refreshModal">
                    <PegModal isOpen={isRefreshLoader} alertModal={true} showTemplate={true} isCenter={true}>
                        <div style={{ overflow: 'hidden', padding: 30 }}>
                            <QuotationAlert>{message}</QuotationAlert>
                            <Loader gridLoader={true} color={'#001F59'} />
                            <PegButton
                                id="refreshModalButton"
                                type="button"
                                variant="Primary"
                                data-testid="refreshModalButton"
                                styleObject={{ position: 'relative', top: '15px', display: 'flex', margin: 'auto' }}
                                onClick={() => window.location.reload()}
                            >
                                Refresh Page
                            </PegButton>
                        </div>
                    </PegModal>
                </div>
            )}
        </>
    );

    const _ProductView = () =>
        loading ? (
            <LoaderOverview>
                <Loader gridLoader={true} color={'#aacbe9'} />
            </LoaderOverview>
        ) : (
            <ServiceOverviewRecode
                data-testid="quotationGridServices"
                quoteId={params.id}
                selectedProduct={innerSelectedProduct}
                onAddService={() => dispatch(onAddService(innerSelectedProduct))}
                onSaveServices={onSaveServices}
                onDeleteOption={() => setShowDeleteModal(true)}
                onEditService={() => setShowEditModal(true)}
                quotationDetails={quotationDetails}
                hasMissingFields={hasMissingFields}
                disableRevenue={disableRevenue}
                disableCost={disableCost}
                exchangeRates={exchangeRates}
                billingParties={billingParties}
                disableOperatingIncome={disableOperatingIncome}
                onClickOptions={(type: string) => onClickOptions(type)}
                onDeleteService={(serviceIndex: number) => dispatch(onDeleteService(innerSelectedProduct, serviceIndex))}
                onAddCharge={(serviceIndex: number) => dispatch(onAddCharge(innerSelectedProduct, serviceIndex))}
                onDeleteCharge={(serviceIndex: number, chargeIndex: number) => dispatch(onDeleteCharge(innerSelectedProduct, serviceIndex, chargeIndex))}
                onUpdateService={(service: QuotationService, serviceIndex: number, productId: IProduct) => dispatch(onUpdateService(service, serviceIndex, productId))}
                onUpdateCharge={(charge: QuotationCharge, chargeIndex: number, serviceIndex: number, selectedProduct: IProduct) =>
                    dispatch(onUpdateCharge(charge, chargeIndex, serviceIndex, selectedProduct))
                }
                serviceList={services.find((item: IProduct) => item?.ProductDetails?.QuotationProductId === innerSelectedProduct?.ProductDetails?.QuotationProductId)}
                isAllSelected={isAllSelected}
                onSelectAll={() => onSelectAll()}
                onSelectServices={(serviceIndex: number) => onSelectServices(serviceIndex)}
                selectedList={selectedList}
                onClickImportTemplate={() => setShowTemplateModal(true)}
                showTemplateModal={showTemplateModal}
                onSubmitImportTemplate={onSubmitImportTemplate}
                onCloseTemplateModal={() => setShowTemplateModal(false)}
                userDetails={userDetails}
                onShowTariff={(tariff: QuotationCharge) => {
                    setShowTarrifModal(true);
                    setTariffData(tariff);
                }}
                onApplyTariff={onApplyTariff}
                isGridDisabled={isViewer}
                onClickFilterColumn={() => setShowFilterModal(!showFilterModal)}
                columnPreference={columnPreference}
                updateChargeValidation={(validationKey: string, chargeId: string, value: boolean) => dispatch(updateChargeValidation(validationKey, chargeId, value))}
            />
        );

    const _MainView = () => (
        <Layout data-testid="quotationGridProductList">
            {gridErrors && gridErrors?.length > 0 && <GridErrors errors={gridErrors} />}

            {innerSelectedProduct && _ProductView()}

            {showVesselDetails && (
                <div className="absolute right-0 top-96 transform -rotate-90 w-8 shadow-lg" style={{ zIndex: 100 }}>
                    <PegButton id="vesssel_measure" type="button" variant="Primary" styleObject={{ minWidth: '10rem' }} onClick={() => setShowVesselMeasureModal(true)}>
                        Vessel Measure
                    </PegButton>
                </div>
            )}

            {_ModalContainer()}
        </Layout>
    );

    const _Renderer = () => {
        if (isLoading || exchangeRatesLoading) {
            return <InitialLoader exchangeError={exchangeError} />;
        }

        if (isError) {
            return <div data-testid="quotationGridError">Something went wrong</div>;
        }

        return _MainView();
    };

    return _Renderer();
};

const InitialLoader = ({ exchangeError }: { exchangeError: boolean }) => (
    <LoaderOverview data-testid="quotationgridLoader" role="quotationgridLoader">
        {exchangeError && <LoaderErrorMessage>Could not load currency exchange data</LoaderErrorMessage>}
        <Loader size={50} color={'#adccf4'} />
    </LoaderOverview>
);

export default QuotationGridRecode;
