export interface IFreightDetail {
  FreightId: number;
  Version: number;
  MovementType: string;
  ShipmentTypeCode: string;
  ShipmentTypeName: string;
  ConsigneeAddressTypeName: string;
  ConsigneeAddressTypeCode: string;
  OriginCode: string;
  OriginName: string;
  OriginLatLong: string;
  DestCode: string;
  DestName: string;
  DestLatLong: string;
  ShipperCode: string;
  ShipperName: string;
  ShipperAddress: string;
  ShipperRemarks: string;
  ConsigneeCode: string;
  ConsigneeName: string;
  ConsigneeAddress: string;
  ConsigneeRemarks: string;
  CreatedAt: Date;
  CreatedBy: string;
  ModifiedAt: Date | null;
  ModifiedBy: string;
  IsActive: boolean;
  IncoTermName: string;
  IncoTermCode: string;
  AdditionalServices: string[];
  RequiredServices: string[];
}

export class FreightDetail implements IFreightDetail {
  FreightId: number;
  Version: number;
  MovementType: string;
  ShipmentTypeCode: string;
  ShipmentTypeName: string;
  OriginCode: string;
  OriginName: string;
  OriginLatLong: string;
  DestCode: string;
  DestName: string;
  DestLatLong: string;
  ShipperCode: string;
  ShipperName: string;
  ShipperAddress: string;
  ShipperRemarks: string;
  ConsigneeCode: string;
  ConsigneeName: string;
  ConsigneeAddress: string;
  ConsigneeRemarks: string;
  CreatedAt: Date;
  CreatedBy: string;
  ModifiedAt: Date | null;
  ModifiedBy: string;
  IsActive: boolean;
  ConsigneeAddressTypeName: string;
  ConsigneeAddressTypeCode: string;
  IncoTermName: string;
  IncoTermCode: string;
  AdditionalServices: string[];
  RequiredServices: string[];

  constructor() {
    this.FreightId = 0;
    this.Version = 0;
    this.MovementType = '';
    this.ShipmentTypeCode = '';
    this.ShipmentTypeName = '';
    this.OriginCode = '';
    this.OriginName = '';
    this.OriginLatLong = '';
    this.DestCode = '';
    this.DestName = '';
    this.DestLatLong = '';
    this.ShipperCode = '';
    this.ShipperName = '';
    this.ShipperAddress = '';
    this.ShipperRemarks = '';
    this.ConsigneeCode = '';
    this.ConsigneeName = '';
    this.ConsigneeAddress = '';
    this.ConsigneeRemarks = '';
    this.CreatedAt = new Date();
    this.CreatedBy = '';
    this.ModifiedAt = new Date();
    this.ModifiedBy = '';
    this.IsActive = true;
    this.ConsigneeAddressTypeName = '';
    this.ConsigneeAddressTypeCode = '';
    this.IncoTermName = '';
    this.IncoTermCode = '';
    this.RequiredServices = [];
    this.AdditionalServices = [];
  }
}
