import {
  useCallback,
  useState
} from 'react';
import PegLabel from 'Controls/Label/PegLabel';
import InputBox from 'Controls/Input/PegInputBox';
import {
  RiDeleteBin2Line,
  RiTruckFill,
  RiHome4Fill,
  RiProfileFill,
  RiMedicineBottleFill
} from 'react-icons/ri';
import PegTextArea from 'Controls/TextArea/PegTextArea';
import { CrewDetails } from 'Model/Order/CrewDetail';
import PegRadioButton from 'Controls/Radio/PegRadioButton';
import Dropdown from 'Controls/Dropdown';
import {
  crewMovement,
  accomodationType,
  documentType
} from 'Constants/DropdownOptions';
import { ICrewItems } from 'Constants/Constant';

interface Props {
  index: number;
  crewDetail: CrewDetails,
  handleOnDeleteRow: Function,
  onUpdateValue: Function,
  onClickActivity: (index: number, crewActivities: any) => void;
  enabledRequests?: any;
}

const CrewLine = (props: Props) => {
  const {
    index,
    handleOnDeleteRow,
    crewDetail,
    onUpdateValue,
    onClickActivity,
    enabledRequests
  } = props;

  let crewActivities: ICrewItems = {
    crew: false,
    accomodation: false,
    documentation: false,
    medical: false,
  }
  if (enabledRequests[index]) {
    crewActivities = enabledRequests[index]
  }

  const [crewFlag, setCrewFlag] = useState(false);
  const [accomodationFlag, setAccomodationFlag] = useState(false);
  const [documentationFlag, setDocumentationFlag] = useState(false);
  const [medicalFlag, setMedicalFlag] = useState(false);
  const documentationServiceYes = `radioDocumentationServicesYes${index}`;
  const documentationServiceNo = `radioDocumentationServicesNo${index}`;

  const onClickCrew = useCallback(() => {
    setCrewFlag(!crewFlag)
    crewActivities.crew = !crewFlag
    onClickActivity(index, crewActivities)
  }, [crewFlag])

  const onClickAccomodation = useCallback(() => {
    setAccomodationFlag(!accomodationFlag)
    crewActivities.accomodation = !accomodationFlag
    onClickActivity(index, crewActivities)
  }, [accomodationFlag])

  const onClickDocumentation = useCallback(() => {
    setDocumentationFlag(!documentationFlag)
    crewActivities.documentation = !documentationFlag
    onClickActivity(index, crewActivities)
  }, [documentationFlag])

  const onClickMedical = useCallback(() => {
    setMedicalFlag(!medicalFlag)
    crewActivities.medical = !medicalFlag
    onClickActivity(index, crewActivities)
  }, [medicalFlag])

  return (
    <div className="grid grid-cols-12 mb-4 mt-4 gap-4 p-4 border-b border-light items-center">
      <div className={'col-span-1 flex flex-col space-y-2'}>
        <h4 className="text-sm font-semibold text-dark border border-medium rounded-full w-8 h-8 flex items-center justify-center">{index + 1}</h4>
      </div>

      <div className={'col-span-1 flex flex-col space-y-2 relative top-2'}>
        <div className={'self-left'}>
          <button>
            <RiTruckFill
              size="2.2em"
              className={crewFlag === false ? 'fill-current text-medium group-hover:text-gray-400-shade' : 'fill-current text-success group-hover:text-success-shade'}
              onClick={() => onClickCrew()} />
          </button>
        </div>
      </div>

      <div className={'col-span-2 flex flex-col space-y-2 relative'}>
        <PegLabel id="lblCrewMovement" name="crewMovement" className="w-8/12 text-sm font-semibold text-dark">
          Crew Movement
        </PegLabel>
        <div className={''}>
          <Dropdown
            id={'drpCrewMovement'}
            isLookup={false}
            dropdownLabel={'name'}
            dropdownValue={'name'}
            appendParams={false}
            isMandatory={crewFlag}
            dropdownOptions={crewMovement}
            placeHolder="Select Movement"
            disabled={!crewFlag}
            onChangeDropdown={(event: any, key: any) => onUpdateValue(event, 'crewMovement')}
          />
        </div>
      </div>

      <div className={'col-span-2 flex flex-col space-y-2 relative'}>
        <PegLabel id="lblNoOfCrew" name="noOfCrew" className="w-8/12 text-sm font-semibold text-dark">
          No of Crew
        </PegLabel>
        <div className="">
          <InputBox
            type={'text'}
            id={'txtNoOfCrew'}
            name={'noOfCrew'}
            defaultValue={crewDetail?.NoOfCrew?.toString()}
            value={crewDetail.NoOfCrew?.toString()}
            disabled={!crewFlag}
            key={'noOfCrew'}
            isMandatory={true}
            onChange={(event: any) => onUpdateValue(event, 'noOfCrew')} />
        </div>
      </div>

      <div className={'col-span-1 flex flex-col space-y-2 relative top-2 left-12'}>
        <div className={'self-left'}>
          <button>
            <RiHome4Fill
              size="2.2em"
              className={accomodationFlag === false ? 'fill-current text-medium group-hover:text-gray-400-shade' : 'fill-current text-success group-hover:text-success-shade'}
              onClick={() => onClickAccomodation()}
            />
          </button>
        </div>
      </div>
      <div className={'col-span-2 flex flex-col space-y-2'}>
        <PegLabel id="lblAccomodationType" name="accomodationType" className="w-10/12 text-sm font-semibold text-dark">
          Accomodation Type
        </PegLabel>
        <div className={''}>
          <Dropdown
            id={'drpAccomodationType'}
            name={'accomodationType'}
            isLookup={false}
            dropdownLabel={'name'}
            dropdownValue={'name'}
            appendParams={false}
            dropdownOptions={accomodationType}
            isMandatory={accomodationFlag}
            placeHolder="Select accomodation type"
            disabled={!accomodationFlag}
            onChangeDropdown={(event: any, key: any) => onUpdateValue(event, 'accomodationType')}
          />
        </div>
      </div>

      <div className={'col-span-2 flex flex-col space-y-2'}>
        <PegLabel id="lblNoOfNights" name="noOfNights" className="w-8/12 text-sm font-semibold text-dark">
          No of Nights
        </PegLabel>
        <div className="">
          <InputBox
            type={'text'}
            id={'txtNoOfNights'}
            placeholder={'Enter no. of nights'}
            disabled={!accomodationFlag}
            isMandatory={accomodationFlag}
            onChange={(e: any, key: any) => onUpdateValue(e, 'noOfNights')}
            value={crewDetail.NoOfNights?.toString()}
            defaultValue={crewDetail.NoOfNights?.toString()} />
        </div>
      </div>
      <div className={'col-span-1 flex flex-col space-y-2'}>
        <div className={'self-center'}>
          <button>
            <RiDeleteBin2Line size="1.2em" className="fill-current text-danger group-hover:text-danger-shade" onClick={() => handleOnDeleteRow()} />
          </button>
        </div>
      </div>
      <div className={'col-span-1 flex flex-col space-y-2'}>
      </div>

      <div className={'col-span-1 flex flex-col space-y-2 relative top-2'}>
        <div className={'self-left'}>
          <button>
            <RiProfileFill
              size="2.2em"
              className={documentationFlag === false ? 'fill-current text-medium group-hover:text-gray-400-shade' : 'fill-current text-success group-hover:text-success-shade'}
              onClick={() => onClickDocumentation()}
            />
          </button>
        </div>
      </div>

      <div className={'col-span-2 flex flex-col space-y-2 relative'}>
        <PegLabel id="lblDangerousCargo" name="dangerousCargo" className='w-12/12 text-sm font-semibold text-dark'>
          Documentation Services
        </PegLabel>
        <div className="w-10/12">
          <div className="inline-flex items-center mr-8">
            <PegRadioButton
              id={documentationServiceYes}
              onChange={(e: any) => onUpdateValue(e, 'documentationServices')}
              name={documentationServiceYes}
              option={'Yes'}
              value={'yes'}
              disabled={!documentationFlag} />
          </div>
          <div className="inline-flex items-center">
            <PegRadioButton
              id={documentationServiceNo}
              onChange={(e: any) => onUpdateValue(e, 'documentationServices')}
              name={documentationServiceYes}
              option={'No'}
              value={'no'}
              disabled={!documentationFlag} />
          </div>
          <div className="flex items-center"></div>
        </div>
      </div>

      <div className={'col-span-2 flex flex-col space-y-2 relative'}>
        <PegLabel id="lblDocumentType" name="documentType" className="w-8/12 text-sm font-semibold text-dark">
          Document Type
        </PegLabel>
        <div>
          <Dropdown
            id={'drpDocumentType'}
            isLookup={false}
            dropdownLabel={'name'}
            dropdownValue={'name'}
            appendParams={false}
            dropdownOptions={documentType}
            placeHolder="Select Type"
            disabled={!documentationFlag}
            onChangeDropdown={(event: any, key: any) => onUpdateValue(event, 'documentType')}
          />
        </div>
      </div>

      <div className={'col-span-1 flex flex-col space-y-2 relative top-2 left-2'}>
        <div className={'self-center'}>
          <button>
            <RiMedicineBottleFill
              size="2.2em"
              className={medicalFlag === false ? 'fill-current text-medium group-hover:text-gray-400-shade' : 'fill-current text-success group-hover:text-success-shade'}
              onClick={() => onClickMedical()}
            />
          </button>
        </div>
      </div>
      <div className={'col-span-4 flex flex-col space-y-2'}>
        <PegLabel id="lblRemarks" name="remarks" className="w-8/12 text-sm font-semibold text-dark">
          Remarks
        </PegLabel>
        <div className="">
          <PegTextArea
            id={'txtRemarks'}
            name={'remarks'}
            placeholder={'Enter Remarks'}
            disabled={!medicalFlag}
            isMandatory={medicalFlag}
            onChange={(event: any, key: any) => onUpdateValue(event, 'medicalCondition')}
            defaultValue={crewDetail?.MedicalCondition} />
        </div>
      </div>
    </div>
  );
};

export default CrewLine;
