import {
  UPDATE_CREW_DETAILS,
  UPDATE_CREW_VALIDATIONS,
  CREATE_CREW_ROW,
  DELETE_CREW_ROW,
  UPDATE_CREW_ACTIVITIES
} from "../ActionTypes/Crew";
import { RESET_CREW_STATE } from "Redux/ActionTypes/ActionTypes";

export function updateCrewDetails(event: any, key: string, index: number) {
  return {
    type: UPDATE_CREW_DETAILS,
    event,
    key,
    index
  };
}

export function updateCrewValidation(validation: {}) {
  return {
    type: UPDATE_CREW_VALIDATIONS,
    validation,
  };
}

export function createCrewRow(payload: Object) {
  return {
    type: CREATE_CREW_ROW,
    payload
  };
}

export function deleteCrewRow(index: number) {
  return {
    type: DELETE_CREW_ROW,
    index
  };
}

export function resetCrewState() {
  return {
    type: RESET_CREW_STATE
  };
}

export function updateCrewActivities(activity: any) {
  return {
    type: UPDATE_CREW_ACTIVITIES,
    activity
  };
}


