

interface Prop {
  title: string;
  isCollapased?: string;
  style?: any;
}

const SectionHeader = (props: Prop) => {

  const { title, style, isCollapased } = props;

  return (
    <div className={"flex flex-col border-b-2 border-light pb-2"}>
      <h3 className={'text-primary font-semibold'} style={style}>{title}</h3>
    </div>
  )
};

export default SectionHeader