import React from 'react';
import styled from "styled-components";
// interface to declare all our prop types
interface Props {
  id: string;
  type?: 'submit' | 'reset' | 'button';
  children: React.ReactNode;
  onClick?: any;
  variant: string; // default, primary, info, success, warning, danger, dark
  size?: string; // sm, md, lg
  disabled?: boolean;
  hidden?: boolean;
  styleObject?: object;
  dataTestId?: string;
}

//button preset
export const buttonPreset: Record<string, string> = {
  Primary: "btn-primary",
  Secondary: "btn-secondary",
  Tertiary: "btn-secondary",
  Success: "btn-success",
  Warning: "btn-warning",
  Danger: "btn-danger",
  LinkPrimary: "btn-primary-link",
  OutlinePrimary: "btn-primary-outline",
}

const PegButton: React.FC<Props> = ({
  styleObject,
  children,
  onClick,
  variant = buttonPreset.primary, size, disabled, id, type, hidden = false, dataTestId, ...rest }) => {
  const style = `${variant ? buttonPreset[variant] : 'btn-primary'}`
  return (
    <StyledButton
      id={id}
      style={styleObject ? styleObject : {}}
      className={hidden ? 'hidden' : style}
      type={type}
      onClick={onClick}
      disabled={disabled}
      data-testid={dataTestId}
      {...rest}>
      <div className="flex flex-row items-center justify-center gap-x-2" style={disabled ? { cursor: 'not-allowed', opacity: 0.5 } : {}}>
        {children}
      </div>
    </StyledButton>
  );
};

export default PegButton;

export const StyledButton = styled.button`
  padding: 4px 12px;
  border-radius: 4px;
  min-width: 120px;
`;