import Package from './PackageLine';
import PegButton from 'Controls/Button/PegButton';
import Header from './Header';
import {
  Section,
  ButtonActions,
  CargoList
} from 'Containers/CargoDetails/cargo.styles';
import { RiAddCircleLine, RiFileDownloadLine } from 'react-icons/ri';
import SectionHeader from 'Components/SectionHeader/SectionHeader';

type IProps = {
  cargo?: any;
  cargoDetails?: any;
  packageDetails?: any[];
  onAdd?: () => void;
  onUpdate?: (key: string, event: any, id: number) => void;
  onDelete?: (id: number) => void;
};
const PackageTableView = (props: IProps) => {
  const {
    onAdd,
    onDelete,
    packageDetails,
    onUpdate
  } = props

  return (
    <Section className={"mt-4 mb-4"}>
      <SectionHeader title={"Package Details"} />
      <ButtonActions className={"flex flex-row-reverse space-x-2 mb-4"}>
        <PegButton
          id="cargo_import"
          type="button"
          variant="OutlinePrimary"
          disabled={true}>
          <RiFileDownloadLine size="1.2em" className="fill-current text-primar" />
          Import cargo
        </PegButton>
        <PegButton
          id="cargo_add"
          type="button"
          variant="OutlinePrimary"
          styleObject={{ marginRight: 12 }}
          onClick={onAdd}>
          <RiAddCircleLine size="1.2em" className="fill-current text-primary" />
          Add cargo
        </PegButton>
      </ButtonActions>
      <CargoList>
        {packageDetails && packageDetails.length > 0 && <Header />}
        {packageDetails && packageDetails.map((item, index) => {
          return (
            <Package
              key={index}
              index={index}
              packages={item}
              onDelete={onDelete}
              onUpdate={onUpdate}
            />
          )
        })}
      </CargoList>
    </Section>
  )
}

export default PackageTableView;