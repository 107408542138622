/* eslint-disable react-hooks/exhaustive-deps */

import styled from "styled-components";

interface StyledProps {
  danger?: any;
  primary?: any;
  secondary?: any;
  success?: any;
  warning?: any;
  rightAlign?: any;
  autoWidth?: any;
  large?: any;
  required?: any;
  isOptional?: any;
  showTemplate?: boolean;
  isScroll?: boolean;
}

export const customModalStyles = {
  overlay: {
    backgroundColor: "rgba(18,52,77,0.58)",
    zIndex: 100,
    transition: "opacity 250ms"
  },
  content: {
    top: "0",
    left: "50%",
    right: "auto",
    bottom: "auto",
    overflow: "visible",
    borderRadius: "0 0 6px 6px",
    boxShadow: "0 0 0 1px rgba(24,50,71,0.1), 0 1px 4px 0 rgba(24,50,71,0.15), 0 6px 14px 0 rgba(24,50,71,0.05)",
    marginRight: "-50%",
    padding: "0",
    transform: "translate(-50%, 0)",
    border: 0
  }
}

export const customModalCenterStyles = {
  overlay: {
    backgroundColor: "rgba(18,52,77,0.58)",
    zIndex: 100,
    transition: "opacity 250ms",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    padding: "20px",
    borderRadius: "10px 10px 10px 10px",
    inset: "auto"
  }
}

export const customModalSideStyles = {
  overlay: {
    backgroundColor: "rgba(18,52,77,0.58)",
    zIndex: 100,
    transition: "opacity 250ms",
  },
  content: {
    inset: "auto 0 auto auto",
    height: "100vh",
    padding: 0
  }
}

export const StyledError = styled.div`
  color: red;
  width: 100%;
  display: block;
  padding-top: 20px;
  text-align: center;
`

export const ModalSection = styled.div`
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  b {
    word-break: break-word;
  }
`
export const StyledSaveEvents = styled.div<StyledProps>`
  cursor: pointer;
  height: 30px;
  align-items: center;
  padding: 10px;
  color: #2B5CC5;
  ${props => props.danger && { "color": '#c82124' }};
  ${props => props.primary && { "color": '#007bff' }};
  ${props => props.secondary && { "color": '#6c757d' }};
  ${props => props.success && { "color": '#28a745' }};
  ${props => props.warning && { "color": '#ffc107' }};
  font-weight: 600;
  border-radius: 4px;
  margin-left: 0px;

  :hover{
    background: #E6E8EB;
  }
`

export const StyledAdditionalTemplate = styled.div`
  line-height: 30px;
  left: 10px;
  position: absolute;
  padding: 0 10px;
`;

export const ModalHeader = styled.div`
    background-color: #F9F9F9;
    padding: 12px 24px;
    text-align: center;
    font-size: 18px;
    font-weight: 300;
`;

export const ModalBody = styled.div<StyledProps>`
    background: white;
    color: "#111315";
`
export const Content = styled.div`
    padding: 20px 8px;
`

export const ModalFooter = styled.div<StyledProps>`
    text-align: right;
    padding: 12px 16px;
    border-radius: 0 0 6px 6px;
    background: #f5f7f9;
    
    ${({ rightAlign }) => rightAlign && `
      width: 100% !important;
      ${"justify-content: flex-end !important;"}
    `}

    button {
        text-align: center;
    }

    button:nth-child(2) {
        margin-left: 12px;
    }

    button:nth-child(3) {
      margin-left: 12px;
    }
`;

export const ModalTitle = styled.h2`
	font-size: 16px;
	font-weight: 600;
  text-align: left;
  padding-bottom: 10px;
  line-height: 24px;
`;

export const StyledForm = styled.form<StyledProps>`
  min-width: 412px;
  background-color: #F9F9F9;
  border-radius: 0 0 6px 6px;
  overflow: auto;
`;

export const FieldLabel = styled.div<StyledProps>`
  width: ${({ isOptional }) => !isOptional ? "auto" : ""};
  vertical-align: top;
  font-size: 12px;
  padding: 3px 1px;

  h4 {
    font-size: 12px !important;
    font-weight: 600 !important;
  }

  ${props => props.required && `
    &:after {
      content: "*";
      margin-left: 4px;
      color: #f65050;
    }
  `}
`;

export const FieldLabelOptional = styled.div`
  color: #ACB8C4;
  vertical-align: top;
  font-size: 12px;
  padding: 3px 0px 3px 6px;
`;

export const FieldContent = styled.div`
  input {
    width: 100%;
  }
`;

export const FieldError = styled.div`
  color: #f65050;
  font-size: 12px;
  font-weight: 400;
`;


export const Field = styled.div`
  padding: 10px 0;
  color: "#111315";
  font-weight: 600;
  line-height: 20px;

  h4 {
    font-size: 13px;
    text-align: left;
  }
`;
