import { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { RiCheckLine } from 'react-icons/ri';
import {
  GridOptions,
  GroupOptions
} from '../../template.styles'
interface Prop {
  disableRevenue: boolean;
  disableCost: boolean;
  disableOperatingIncome: boolean;
  onClickGroupingOptions: (type: string) => void;
}

const HeaderOptions = (props: Prop) => {
  const {
    disableCost,
    disableOperatingIncome,
    disableRevenue
  } = props
  return (
    <GridOptions>
      <GroupOptions
        data-tip
        data-for='OperatingIncome'
        colour={'#FFC300'}
        onClick={() => props.onClickGroupingOptions('OpIncome')}>
        <span>Op Income</span>
        {!disableOperatingIncome && <RiCheckLine style={{ height: 20, width: 20 }} />}
      </GroupOptions>

      <GroupOptions
        data-tip
        data-for='actuals'
        colour={'#ADD8E6'}
        onClick={() => props.onClickGroupingOptions('actuals')}>
        <span>Revenue</span>
        {!disableRevenue && <RiCheckLine style={{ height: 20, width: 20 }} />}
      </GroupOptions>

      <GroupOptions
        data-tip
        data-for='estimates'
        colour={'#00FF00'}
        onClick={() => props.onClickGroupingOptions('estimates')}>
        <span>Cost</span>
        {!disableCost && <RiCheckLine style={{ height: 20, width: 20 }} />}
      </GroupOptions>
    </GridOptions>
  );
};

export default HeaderOptions;