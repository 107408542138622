import React from 'react';
import { Wrapper, Table } from './index.styles';
import { MdClear } from 'react-icons/md';
import PegInputBox from 'Controls/Input/PegInputBox';
import PegButton from 'Controls/Button/PegButton';


type MyState = {
}
type MyProps = {
  data?: any;
  columns?: any[];
  searchInput?: any;
  checked: any[];
  handleChange: Function;
  clearSearch: Function;
  getTrProps?: any;
  index?: number;
};

class VesselCallTableView extends React.Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
    };
  }


  render() {
    let { data, columns, searchInput, handleChange, clearSearch, getTrProps } = this.props;
    return (
      <div>
        <br />
        <div className="flex flex-row items-center w-8/12 ml-4 mb-0 mr-4 space-x-4">
          <PegInputBox
            type="search"
            id="txtLinkVesselCallSearch"
            name="searchInput"
            placeholder="Search"
            value={searchInput || ""}
            onChange={handleChange}
          />
          <PegButton variant={'Primary'} id="btnClear" onClick={() => clearSearch()}>
            <MdClear size="1em" className="fill-current text-white group-hover:text-white-shade" />
            Clear
          </PegButton>
        </div>
        <br />
        <Wrapper>
        </Wrapper>
      </div>
    );
  }

};

export default VesselCallTableView;
