import ServiceTemplate from './ServiceTemplate'
import { connect } from 'react-redux';
import {
    getServiceTemplate,
    onUpdateServiceTemplate,
    saveServiceTemplate,
    onBulkDeleteService,
    onUpdateBusinessProduct,
    saveServiceCharges,
    onUpdateBPserviceCharges,
    getVisibilityGridData,
    deleteServiceVisibility,
    refreshServiceVisibility,
    isServiceTemplateSaving,
    setVisibilityGridLoading,
    setIsDuplicate
} from 'Redux/Actions/serviceTemplate';
import {
    ServiceTemplateDefault
} from 'Model/ServiceTemplate/ServiceTemplatedefault';
import { getUserDetail } from 'Redux/Actions/User';

const mapDispatchToProps = (dispatch: any) => ({
    getServiceTemplate: (params: any) => dispatch(getServiceTemplate(params)),
    onUpdateServiceTemplate: (serviceTemplate: any) => dispatch(onUpdateServiceTemplate(serviceTemplate)),
    saveServiceTemplate: (data: ServiceTemplateDefault) => dispatch(saveServiceTemplate(data)),
    onBulkDeleteService: () => dispatch(onBulkDeleteService()),
    deleteServiceVisibility: (id: number, type: string) => dispatch(deleteServiceVisibility(id, type)),
    getVisibilityGridData: (type: string, selectedCompany: string) => dispatch(getVisibilityGridData(type, selectedCompany)),
    onUpdateBusinessProduct: (products: any) => dispatch(onUpdateBusinessProduct(products)),
    saveServiceCharges: (list: any, products: any) => dispatch(saveServiceCharges(list, products)),
    onUpdateBPserviceCharges: (product: any, servAndChar: any) =>
        dispatch(onUpdateBPserviceCharges(product, servAndChar)),
    refreshServiceVisibility: (visibilityType: string) => dispatch(refreshServiceVisibility(visibilityType)),
    setServiceTemplateSaving: (val: boolean) => dispatch(isServiceTemplateSaving(val)),
    setVisibilityGridLoading: (value: boolean) => dispatch(setVisibilityGridLoading(value)),
    setIsDuplicate: (value: boolean) => dispatch(setIsDuplicate(value)),
    getUserDetail: (userName: any) => dispatch(getUserDetail(userName)),
});

const mapStateToProps = (state: any) => ({
    serviceTemplate: state.ServiceTemplates.serviceTemplate,
    visibilityType: state.ServiceTemplates.visibilityType,
    isLoading: state.ServiceTemplates.isLoading,
    isServiceTemplateSaving: state.ServiceTemplates.isServiceTemplateSaving,
    userDetails: state.user.userDetail,
    visibilityGridData: state.ServiceTemplates.visibilityGridData,
    serviceAndCharges: state.ServiceTemplates.serviceAndCharges,
    selectedProduct: state.ServiceTemplates.selectedProduct,
    templateDetails: state.ServiceTemplates.templateDetails,
    isTemplateLoading: state.ServiceTemplates.isTemplateLoading,
    businessProducts: state.ServiceTemplates.businessProducts,
    isError: state.ServiceTemplates.isError,
    isDuplicate: state.ServiceTemplates.isDuplicate
});
export default connect(mapStateToProps, mapDispatchToProps)(ServiceTemplate);