
import { RESET_QUOTATION_STATE } from "Redux/ActionTypes/ActionTypes";
import store from "Redux/Store";
import QuotationAgent from 'Api/QuotationAgent';
import { IS_SAVE_ONPROGESS, UPDATE_QUOTATION_DETAIL, SET_BUSINESS_TYPE, SET_NAVIGATION_TAB } from "Redux/ActionTypes/Quotation";
import { Quotation } from "Model/Order/Quotation";
import { UserDefault } from "Model/User/UserDefault";

export function updateQuotationDetails(event: any, key: string) {
  return {
    type: UPDATE_QUOTATION_DETAIL,
    event,
    key
  };
}

export function setQuotationLoading(loading: boolean) {
  return {
    type: IS_SAVE_ONPROGESS,
    loading
  };
}

export function resetQuotationState() {
  return {
    type: RESET_QUOTATION_STATE
  };
}

export function setQuotationTab(tab: any) {
  return {
    type: SET_NAVIGATION_TAB,
    tab
  };
}

export function setBusinessType(types: any[]) {
  return {
    type: SET_BUSINESS_TYPE,
    types
  };
}

export function createQuotation(history: any) {
  return (dispatch: (arg: any) => void) => {
    const state = store.getState();
    const vesselObject: any = state.vessel
    const customerObject: any = state.customer
    const selectedBusiness = state.quotation.selectedBusiness
    const quotationObject: Quotation = state.quotation.quotation;
    const userObject: UserDefault = state.user.userDetail;
    let QuotationObject = {
      "QuotationId": quotationObject.QuotationId,
      "Version": quotationObject.Version,
      "QuotationStatusFk": quotationObject.QuotationStatusFk,
      "QuotationNumber": quotationObject.QuotationNumber,
      "QuotationDate": quotationObject.QuotationDate,
      "PaymentTerm": quotationObject.PaymentTerm,
      "CreditBalance": quotationObject.CreditBalance,
      "ValidityDate": "2021-12-01T05:03:35.570Z",
      "DueDate": quotationObject.DueDate,
      "PIC": undefined,
      "Remarks": quotationObject.Remarks,
      "QuoteCurrencyCode": "",
      "QuoteCurrencyName": "",
      "BaseCurrencyCode": "AED",
      "BaseCurrencyName": "Dirham",
      "OrderNumber": quotationObject.OrderNumber,
      "RFQNumber": "",
      "QuotationType": "Quotation",
      "CompanyCode": "GDUB",
      "CompanyName": "GAC Dubai",
      "OfficeCode": "AEDXB37",
      "OfficeName": "Dubai, Coldstore Building, Jebel Ali",
      "DepartmentCode": "1301",
      "DepartmentName": "IT Department",
      "CreatedAt": "2021-11-01T04:58:02.160Z",
      "CreatedBy": "NIS#01",
      "ModifiedAt": "2021-11-01T04:58:02.160Z",
      "ModifiedBy": "NIS#01",
      "IsActive": true,
      "AppUserDetail": userObject,
      QuoteService: state.quoteServices.QuoteService,
      QuotationArea: selectedBusiness,
      CustomerDetail: customerObject?.customerDetails,
      CargoDetail: state.cargo?.cargoDetails,
      VesselDetail: vesselObject?.vesselDetails,
      OptionDetail: vesselObject?.optionDetails,
      CrewDetail: state.crew?.crewDetails,
    }
    return QuotationAgent.save({ "Quotation": QuotationObject }).then(() => {
      dispatch(setQuotationLoading(false))
      history.push('/quotation-success')
    })
  }
}
