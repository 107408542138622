import {
  GridViewLoading,
  TemplateModalOverview
} from 'Containers/JobGrid/Jobs.styles';
import Loader from 'Components/Loader';
import Service from './Service';
import Header from 'Containers/JobGrid/components/Header';
import { JOB_COLUMN } from 'Constants/ColumnFilterConstants';

type Props = {
  isTemplateLoading: boolean;
  isTemplateImporting: boolean;
  isSelectAllChecked: boolean;
  template: any[] | null
};

const Template = (props: Props) => {
  const {
    isTemplateLoading,
    isTemplateImporting,
    isSelectAllChecked,
    template
  } = props;

  if (isTemplateLoading) {
    return (
      <GridViewLoading>
        <Loader gridLoader={true} color={'#aacbe9'} />
      </GridViewLoading>
    )
  }
  if (isTemplateImporting) {
    return (
      <GridViewLoading>
        <div style={{
          position: "relative",
          top: "40%"
        }}>Template importing</div>
        <Loader gridLoader={true} color={'#aacbe9'} />
      </GridViewLoading>
    )
  }
  if (!template) {

    if (isSelectAllChecked) {
      return (
        <GridViewLoading>
          All templates are selected
        </GridViewLoading>
      )
    }

    return (
      <GridViewLoading>
        Select a template
      </GridViewLoading>
    )
  }
  if (!template.length) {
    return (
      <GridViewLoading>
        Services in this template are not relevant for this Job
      </GridViewLoading>
    )
  }
  return (
    <TemplateModalOverview>
      <Header
        jobDetails={false}
        disableCost={false}
        isViewMode={true}
        disableServiceRequest={false}
        disableOperatingIncome={false}
        disableRevenue={false}
        disableBilling={false}
        showOperatingIncome={true}
        columnPreference={JOB_COLUMN}
        showCostQuantity={false}
      />
      {template.map((service: any, index: number) => {
        return (
          <Service
            key={service.Id + 'service-template'}
            index={index}
            service={service.services}
            charges={service.charges}
          />
        )
      })}
    </TemplateModalOverview>
  )
};

export default Template;
