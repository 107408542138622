import {
  Flex,
  Id,
  Quantity,
  GrossWeight,
  Weight,
  Length,
  Width,
  Height,
  Dimensions,
  Volume,
  GrossVolume,
  DeleteIcon
} from '../../cargo.styles'

const CargoHeader = (props: any) => {
  return (
    <Flex>
      <DeleteIcon />
      <Id>
        {/* <span className="text-sm font-semibold text-dark">LOT</span> */}
      </Id>
      <Quantity>
        <span className="text-sm font-semibold text-dark">Quantity</span>
      </Quantity>
      <GrossWeight>
        <span className="text-sm font-semibold text-dark">Gross Weight</span>
      </GrossWeight>
      <Weight>
        <span className="text-sm font-semibold text-dark">Weight UOM</span>
      </Weight>
      <Length>
        <label className="text-sm font-semibold text-dark">Length</label>
      </Length>
      <Width>
        <label className="text-sm font-semibold text-dark">Width</label>
      </Width>
      <Height>
        <label className="text-sm font-semibold text-dark">Height</label>
      </Height>
      <Dimensions>
        <span className="text-sm font-semibold text-dark">Dimensions UOM</span>
      </Dimensions>
      <Volume>
        <span className="text-sm font-semibold text-dark">Volume UOM</span>
      </Volume>
      <GrossVolume>
        <span className="text-sm font-semibold text-dark">Gross Volume</span>
      </GrossVolume>
    </Flex>
  );
};

export default CargoHeader;
