export interface ICustomerDetail {
  CustomerId: number;
  Version: number;
  CustomerCode: string;
  CustomerName: string;
  CustomerReference: string;
  ApprovalByCode: string;
  ApprovalByName: string;
  ApprovalByReference: string;
  AccountManager: string;
  CustomerContact: string;
  Email: string;
  CountryCode: string;
  CountryName: string;
  Address: string;
  AddressType: string;
  IsCustomerRedFlagged: boolean;
  SalesPersonCode: string;
  SellingCurrency: string;
  ExchangeRate: number;
  AdvanceFundingRequired: boolean;
  BankInfo: string;
  Percentage: number;
  IsActive: boolean;
}

export class CustomerDetail implements ICustomerDetail {

  CustomerId: number;

  Version: number;

  CustomerCode: string;

  CustomerName: string;

  CustomerReference: string;

  CustomerContact: string;

  Email: string;

  ApprovalByCode: string;

  ApprovalByName: string;

  ApprovalByReference: string;

  CountryCode: string;

  CountryName: string;

  AddressType: string;

  Address: string;

  IsCustomerRedFlagged: boolean;

  SalesPersonCode: string;

  AccountManager: string;

  SellingCurrency: string;

  ExchangeRate: number;

  AdvanceFundingRequired: boolean;

  BankInfo: string;

  Percentage: number;

  IsActive: boolean;

  constructor() {
    this.CustomerId = 0;
    this.Version = 0;
    this.CustomerCode = '';
    this.CustomerName = '';
    this.CustomerReference = '';
    this.CustomerContact = '';
    this.Email = '';
    this.ApprovalByCode = '';
    this.ApprovalByName = '';
    this.ApprovalByReference = '';
    this.CountryCode = '';
    this.CountryName = '';
    this.AddressType = '';
    this.Address = '';
    this.IsCustomerRedFlagged = true;
    this.SalesPersonCode = '';
    this.AccountManager = '';
    this.IsActive = true;
    this.SellingCurrency = '';
    this.AdvanceFundingRequired = false;
    this.BankInfo = '';
    this.Percentage = 0;
    this.ExchangeRate = 1;
  }
}
