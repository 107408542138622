import {
  UPDATE_ACCOUNT_MANAGER,
  UPDATE_CUSTOMER_DETAILS,
  UPDATE_CUSTOMER_VALIDATIONS,
  UPDATE_QUOTATION_CUSTOMER_DETAILS,
} from 'Redux/ActionTypes/Customer';
import { RESET_CUSTOMER_STATE, RESET_NEW_CUSTOMER_STATE } from "Redux/ActionTypes/ActionTypes";
import { setCustomerSectionValue, setNewCustomer } from 'Utils/Quotation';
import {
  customerRequired,
} from 'Constants/Validations';
import { CustomerDetail } from 'Model/Order/CustomerDetail';
import { CustomerAdditionalDetail } from 'Model/Order/CustomerAdditionalDetail';
import { UPDATE_CUSTOMER_ADDITIONAL_DETAIL } from './../ActionTypes/Customer';
import { CreateCustomer } from 'Model/Order/CreateCustomer';
import { CREATE_NEW_CUSTOMER } from '../ActionTypes/Customer';


var initialState = {
  customerDetails: new CustomerDetail(),
  newCustomer: new CreateCustomer(),
  customerAdditionalDetails: new CustomerAdditionalDetail(),
  errorCount: 0,
  validation: {
    CustomerName: { error: false, message: customerRequired }
  }
}

export const customer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_CUSTOMER_DETAILS:
      const obj = setCustomerSectionValue(state.customerDetails, action.key, action.event)
      return {
        ...state,
        customerDetails: {
          ...state.customerDetails,
          ...obj
        }
      };

    case CREATE_NEW_CUSTOMER:
      const newObj = setNewCustomer(state.newCustomer, action.key, action.event)
      return {
        ...state,
        newCustomer: {
          ...state.newCustomer,
          ...newObj
        }
      };

    case UPDATE_CUSTOMER_ADDITIONAL_DETAIL:
      return {
        ...state,
        customerAdditionalDetails: {
          ...action.data
        }
      }

    case UPDATE_ACCOUNT_MANAGER:
      return Object.assign({}, state, {
        customerDetails: Object.assign({}, state.customerDetails, {
          AccountManager: action.payload
        })
      });

    case UPDATE_CUSTOMER_VALIDATIONS:
      return {
        ...state,
        validation: { ...action.validation }
      }

    case UPDATE_QUOTATION_CUSTOMER_DETAILS:
      return {
        ...state,
        customerDetails: {
          ...state.customerDetails,
          ...action.data
        }
      };

    case RESET_CUSTOMER_STATE:
      return {
        customerDetails: new CustomerDetail(),
        errorCount: 0,
        validation: {
          CustomerName: { error: false, message: customerRequired }
        }
      };

    case RESET_NEW_CUSTOMER_STATE:
      return {
        newCustomer: new CreateCustomer(),
      };

    default:
      return state;

  }
}