// CustomAxiosCacheWrapper.ts

import axios, { AxiosResponse } from 'axios';

// Define a cache object to store responses
const cache: Record<string, { data: any; timestamp: number }> = {};

const checkForData = (cacheKey: string, callback: (response: any, fromCache: boolean) => void) => {
    if (cache[cacheKey].data) {
        callback(cache[cacheKey].data, true); // Indicate that the response is from the cache
        return;
    } else {
        setTimeout(() => {
            checkForData(cacheKey, callback);
        }, 100);
    }
}

export default function useAxiosCached() {

    const axiosCashed = async (
        url: string,
        method: 'get' | 'post' | 'put' | 'delete',
        callback: (response: any, fromCache: boolean) => void,
        timeout?: number
    ): Promise<void> => {
        const cacheKey = `${method}:${url}`;

        // Check if the response is already cached and not expired
        if (cache[cacheKey] && (!timeout || Date.now() - cache[cacheKey].timestamp < timeout)) {
            checkForData(cacheKey, callback);
            return;
        } else {
            cache[cacheKey] = {
                data: null,
                timestamp: 0
            };
        }

        try {
            // Make the request with the specified method
            const response: AxiosResponse = await axios.request({
                url,
                method,
            });

            // Cache the response
            cache[cacheKey] = {
                data: response.data,
                timestamp: Date.now(),
            };

            callback(response.data, false); // Indicate that the response is fresh
        } catch (error) {
            throw error;
        }
    };

    return axiosCashed;
}

