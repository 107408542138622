
import Port from './Port'
import { connect } from 'react-redux';
import {
  updatePortDetail,
  setLoading,
  savePortDetail,
  getPortDetails,
  setPortSaving,
  updateCenter
} from 'Redux/Actions/port';

const mapDispatchToProps = (dispatch: any) => ({
  updatePortDetail: (key: string, value: any) => dispatch(updatePortDetail(key, value)),
  setLoading: (value: boolean) => dispatch(setLoading(value)),
  savePortDetail: (params: any) => dispatch(savePortDetail(params)),
  getPortDetails: (id: number) => dispatch(getPortDetails(id)),
  updateCenter: (value: any) => dispatch(updateCenter(value)),
  setPortSaving: (value: boolean) => dispatch(setPortSaving(value))
});

const mapStateToProps = (state: any) => ({
  userDetails: state.user.userDetail,
  portDetails: state.port.portDetails,
  validation: state.port.validation,
  center: state.port.center,
  isSaving: state.port.isSaving,
  isLoading: state.port.isLoading
});

export default connect(mapStateToProps, mapDispatchToProps)(Port);