import { Configuration, PopupRequest } from "@azure/msal-browser";

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const AUTHORITY = process.env.REACT_APP_AUTHORITY;
const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: `${CLIENT_ID}`,
        authority: `${AUTHORITY}`,
        redirectUri: `${REDIRECT_URI}`,
        postLogoutRedirectUri: "/"
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        allowRedirectInIframe: true,
        iframeHashTimeout: 10000,
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ["api://54b438d5-7fa9-418e-be83-2f00c59edb83/Api"]
};

// Add here the endpoints for MS Graph API services you would like to use.

export const setToken = (key: string, userToken: string) => {
    return localStorage.setItem(`${key}`, userToken)
}
export const setUserAccount = (key: string, userAccount: string) => {
    return localStorage.setItem(`${key}`, userAccount)
}
export const getToken = (key: string) => {
    return localStorage.getItem(`${key}`)
}
export const getUserAccount = (key: string) => {
    return localStorage.getItem(`${key}`)
}
export const deleteToken = (key: string) => {
    return localStorage.removeItem(`${key}`);
}

export const clearStorage = () => {
    localStorage.clear();
}

