
import { JOB_COLUMN, QUOTATION_COLUMN } from 'Constants/ColumnFilterConstants';
import {
  Row,
  Columns,
  Title,
  Value
} from './details.styles';

type Props = {
  service?: any;
  serviceRequestDetails?: any;
  type?: string;
};

const Service = (props: Props) => {
  const {
    service,
    serviceRequestDetails,
    type } = props;

  return (
    <div>
      <Row>
        <Columns>
          <Title>
            {JOB_COLUMN.NAME.label}
          </Title>
          <Value>
            {service?.Name}
          </Value>
        </Columns>
      </Row>
      <Row>
        <Columns>
          <Title>
            {JOB_COLUMN.SUPPLIER.label}
          </Title>
          <Value>
            {service?.SupplierName}
          </Value>
        </Columns>
        <Columns>
          <Title>
            {JOB_COLUMN.CURRENCY.label}
          </Title>
          <Value>
            {service?.PriceCurrencyCode}
          </Value>
        </Columns>
      </Row>
      {type === 'job' &&
        <Row>
          <Columns>
            <Title>
              {JOB_COLUMN.BILLING_CURRENCY.label}
            </Title>
            <Value>
              {service?.BillingCurrencyCode}
            </Value>
          </Columns>
          <Columns>
            <Title>
              {JOB_COLUMN.EX_RATE.label}
            </Title>
            <Value>
              {service?.BillingExchangeRate}
            </Value>
          </Columns>
        </Row>}
      <Row>
        <Columns>
          <Title>
            {JOB_COLUMN.COST_CURRENCY.label}
          </Title>
          <Value>
            {service?.CostCurrencyCode}
          </Value>
        </Columns>
        <Columns>
          <Title>
            {JOB_COLUMN.COST_EX_RATE.label}
          </Title>
          <Value>
            {service?.CostExchangeRate}
          </Value>
        </Columns>
      </Row>
      {
        type === 'job' &&
        <>
          <Row>
            <Columns>
              <Title>
                {JOB_COLUMN.SR_NO.label}
              </Title>
              <Value>
                {serviceRequestDetails?.SRNumber}
              </Value>
            </Columns>
            <Columns>
              <Title>
                {JOB_COLUMN.SR_STATUS.label}
              </Title>
              <Value>
                {serviceRequestDetails?.SRStatus}
              </Value>
            </Columns>
          </Row>
          <Row>
            <Columns>
              <Title>
                {JOB_COLUMN.SERVICE_STATUS.label}
              </Title>
              <Value>
                {serviceRequestDetails?.ServiceStatus}
              </Value>
            </Columns>
            <Columns>
              <Title>
                {JOB_COLUMN.BILLING_STATUS.label}
              </Title>
              <Value>
                {service?.BillingStatus}
              </Value>
            </Columns>
          </Row>
        </>
      }

    </div>
  )
};

export default Service;
