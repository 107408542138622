
import {
  useEffect,
  useState
} from 'react';
import axios from 'axios';
import Loader from 'Components/Loader';
import Template from './Template'
import {
  TemplateOverview,
  TemplateOptions,
  TemplateSection,
  SelectAllTemplates,
  GridView,
  Footer,
  MarginLeft,
  ListName,
  ListCheckbox,
  OptionsLoading
} from 'Containers/JobGrid/Jobs.styles';
import PegButton from 'Controls/Button/PegButton';
type Props = {
  details: any;
  type?: string;
  onCloseTemplateModal: () => void,
  onSubmitImportTemplate: (templates: any) => void
};

const TemplateView = (props: Props) => {
  const {
    onCloseTemplateModal,
    onSubmitImportTemplate
  } = props;

  const [templateList, setTemplateList] = useState([]);
  const [isTemplateLoading, setIsTemplateLoading] = useState(false);
  const [templatesCache, setTemplatesCache] = useState<{ [id: number]: any }>({});
  const [isTemplateImporting, setIsTemplateImporting] = useState(false);

  const [activeTemplateId, setActiveTemplateId] = useState<number | null>(null);
  const [activeTemplate, setActiveTemplate] = useState([])

  const [selectedTemplateIds, setSelectedTemplateIds] = useState<number[]>([]);

  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);

  useEffect(() => {
    // const asyncFn = async () => {
    getLists()
    // }
    // asyncFn()
  }, []);

  const getLists = () => {
    const {
      details,
      type
    } = props
    let productCode = type === 'job' ? details?.ProductCode : details?.ProductDetails?.ProductCode
    if (productCode) {
      axios.get(`/oum-getServiceTemplates?businessProductCode=${productCode}`).then((response: any) => {
        if (response.data) {
          setTemplateList(response.data)
        }
      })
    }
  }

  const onClickTemplate = (id: number) => {
    if (!isTemplateLoading && id !== activeTemplateId) {
      setIsTemplateLoading(true)
      setActiveTemplateId(id)

      if (!templatesCache[id]) {
        getTemplate(id).then(template => {
          if (!template) return;

          setActiveTemplate(template);
          setTemplatesCache({ ...templatesCache, [id]: template });
          setIsTemplateLoading(false);
        })
      } else {
        setActiveTemplate(templatesCache[id]);
        setIsTemplateLoading(false);
      }
    }
  }

  const getTemplate = (id: number) => {
    const {
      details,
      type
    } = props

    let productCode = type === 'job' ? details?.ProductCode : details?.ProductDetails?.ProductCode
    let url = type === 'job' ?
      `/oum-service-template-detail?id=${id}&jobId=${details.LocalJobId}&businessProductCode=${productCode}`
      :
      `/oum-service-template-detail?id=${id}&businessProductCode=${productCode}`
    return axios.get(url).then(async (response: any) => {
      return response.data.BusinessProducts[0]?.servicesAndCharges;
    })
  }

  const cacheTemplate = (id: number, selectedTemplate: any) => {
    setTemplatesCache({
      ...templatesCache,
      [id]: selectedTemplate
    });

  }

  const toggleSelectTemplate = (templateId: number) => {
    let newTemplatesCache = { ...templatesCache };
    let newSelectedTemplateIds = [...selectedTemplateIds]

    if (newSelectedTemplateIds.includes(templateId)) {
      delete newTemplatesCache[templateId];
      newSelectedTemplateIds = newSelectedTemplateIds.filter(id => id !== templateId);

      setTemplatesCache(newTemplatesCache);
      setSelectedTemplateIds(newSelectedTemplateIds);

    } else {
      if (templateId !== activeTemplateId) {
        getTemplate(templateId).then(template => {
          cacheTemplate(templateId, template);
          setIsTemplateLoading(false)
        })
      }

      setSelectedTemplateIds([...newSelectedTemplateIds, templateId]);
    }
  }

  const toggleSelectAll = () => {
    if (isSelectAllChecked) {
      setSelectedTemplateIds([])
      setIsSelectAllChecked(false);
      return;
    }

    setIsTemplateLoading(true);

    let newSelectedTemplateIds = [...selectedTemplateIds]
    let newTemplatesCache = { ...templatesCache };

    let getTemplatePromises: Promise<any>[] = [];

    templateList?.forEach((item: any, index: number) => {
      if (newSelectedTemplateIds.indexOf(item.ServiceTemplateId) < 0) {
        newSelectedTemplateIds.push(item.ServiceTemplateId);

        getTemplatePromises.push(getTemplate(item.ServiceTemplateId))
      }
    });

    Promise.all(getTemplatePromises).then(values => {
      values.forEach((template: any, index: number) => {
        const templateId = newSelectedTemplateIds[index];

        newTemplatesCache[templateId] = template;
      });

      setSelectedTemplateIds(newSelectedTemplateIds);
      setActiveTemplateId(null);
      setTemplatesCache(newTemplatesCache);
      setIsSelectAllChecked(true);
      setIsTemplateLoading(false);

    }).catch(e => {
      setIsSelectAllChecked(false);
    });

  }

  const importTemplates = () => {
    let templatesForImport: any[] = [];
    selectedTemplateIds.forEach((id: any) =>
      templatesForImport = templatesForImport.concat(templatesCache[id])
    );

    setIsTemplateImporting(true);
    onSubmitImportTemplate(templatesForImport);
  }

  const isImportDisabled = selectedTemplateIds.length === 0;

  return (
    <TemplateOverview>
      <TemplateOptions>
        <SelectAllTemplates>
          <ListCheckbox
            type="checkbox"
            onClick={() => toggleSelectAll()}
            checked={isSelectAllChecked}
            style={{ cursor: 'pointer' }} />
          Select all
        </SelectAllTemplates>
        {templateList?.length ?
          <div>
            {templateList?.map((item: any, index: number) => {
              const checked = !!selectedTemplateIds.includes(item.ServiceTemplateId);
              return (
                <ListName
                  selected={item.ServiceTemplateId === activeTemplateId}
                  key={item.ServiceTemplateId}
                  onClick={() => onClickTemplate(item.ServiceTemplateId)}>
                  <ListCheckbox
                    type="checkbox"
                    onClick={() => toggleSelectTemplate(item.ServiceTemplateId)}
                    checked={checked}
                    style={{ cursor: 'pointer' }} />
                  {item.Name}
                </ListName>
              )
            })}
          </div>
          :
          <OptionsLoading>
            <Loader
              size={24}
              color={'blue'}
            />
          </OptionsLoading>

        }
      </TemplateOptions>
      <TemplateSection>
        <GridView>
          <Template
            template={activeTemplateId ? activeTemplate : null}
            isTemplateLoading={isTemplateLoading}
            isTemplateImporting={isTemplateImporting}
            isSelectAllChecked={isSelectAllChecked}
          />
        </GridView>
        <Footer>
          <MarginLeft>
            <PegButton
              id="import_template"
              type="button"
              variant="Primary"
              onClick={onCloseTemplateModal}>
              Close
            </PegButton>
            <PegButton
              id="import_template"
              type="button"
              variant="Primary"
              disabled={isImportDisabled}
              styleObject={{ margin: '0px 12px' }}
              onClick={() => importTemplates()}>
              Import Template
            </PegButton>
          </MarginLeft>
        </Footer>
      </TemplateSection>
    </TemplateOverview>
  )
};

export default TemplateView;
