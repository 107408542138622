import {
  UPDATE_VESSEL_DETAILS,
  UPDATE_VESSEL_ADDITIONAL_DETAILS,
  UPDATE_VESSEL_VALIDATIONS,
  UPDATE_OPTION_DETAILS,
  CREATE_VOYAGE_ROW,
  DELETE_VOYAGE_ROW,
  UPDATE_SERVICES,
  UPDATE_QUOTATION_VESSEL_DETAILS,
  UPDATE_QUOTATION_OPTION_DETAILS,
  UPDATE_OPTION_VALIDATIONS,
  LINK_VESSEL_CALL,
  LINK_OPTION_DETAILS,
  ENABLE_SHIP_SPARES,
  ENABLE_CREW_TAB,
  ENABLE_CARGO_TAB,
} from "../ActionTypes/Vessel";
import { RESET_VESSEL_STATE } from "Redux/ActionTypes/ActionTypes";

export function updateVesselDetails(event: any, key: string) {
  return {
    type: UPDATE_VESSEL_DETAILS,
    event,
    key,
  };
}

export function updateVesselAdditionalDetails(data: any) {
  return {
    type: UPDATE_VESSEL_ADDITIONAL_DETAILS,
    data
  };
}

export function linkOptionDetails(data: any) {
  return {
    type: LINK_OPTION_DETAILS,
    data
  };
}


export function linkVesselCall(data: any) {
  return {
    type: LINK_VESSEL_CALL,
    data
  };
}

export function updateVesselValidation(validation: {}) {
  return {
    type: UPDATE_VESSEL_VALIDATIONS,
    validation,
  };
}

export function updateOptionDetails(event: any, key: string, index: number) {
  return {
    type: UPDATE_OPTION_DETAILS,
    event,
    key,
    index
  };
}

export function createVoyageRow() {
  return {
    type: CREATE_VOYAGE_ROW,
  };
}

export function deleteVoyageRow(index: number) {
  return {
    type: DELETE_VOYAGE_ROW,
    index
  };
}

export function updateVesselServices(index: number, services: any) {
  return {
    type: UPDATE_SERVICES,
    index,
    services
  };
}

export function updateOptionValidations(errorCount: number) {
  return {
    type: UPDATE_OPTION_VALIDATIONS,
    errorCount,
  };
}


export function resetVesselState() {
  return {
    type: RESET_VESSEL_STATE
  };
}
export function updateQuotationVesselDetails(data: object) {
  return {
    type: UPDATE_QUOTATION_VESSEL_DETAILS,
    data
  };
}

export function updateQuotationOptionDetails(data: []) {
  return {
    type: UPDATE_QUOTATION_OPTION_DETAILS,
    data
  };
}

export function enableCrewTab(value: boolean) {
  return {
    type: ENABLE_CREW_TAB,
    value
  };
}

export function enableCargoTab(value: boolean) {
  return {
    type: ENABLE_CARGO_TAB,
    value
  };
}

export function enableShipSpares(value: boolean) {
  return {
    type: ENABLE_SHIP_SPARES,
    value
  };
}

