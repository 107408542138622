import Header from './Header'
import List from './List'
import {
  Grid
} from '../quote.styles';
import PegModal from 'Controls/PegModal';
import TemplateView from 'Components/Template';
import { isDraftQuotation } from 'Utils/QuotationGrid';
import { ColumnPreferences } from 'Model/Common/ColumnPreferences';
import { UserDefault } from 'Model/User/UserDefault';
import { IProduct, IQBillingParties, IQuotationDetails, IServiceAndCharges, onShowTariffFunc, onSubmitImportTemplateFunc, onUpdateChargeFunc, onUpdateServiceFunc } from 'Model/QuotationGrid/types';
import { ExchangeRateLookupEntity } from 'Model/Master/ExchangeRateLookupEntity';
import { IServiceItem, onDeleteChargeFunc } from 'Model/Common/types';
import { TaxLookupEntity } from 'Model/Master/TaxLookupEntity';
import { CurrencyLookupEntity } from 'Model/Master/CurrencyLookupEntity';
import { ITemplate, IUOM } from 'Model/Job/types';
import isNull from 'lodash/isNull';

interface Prop {
  userDetails: UserDefault;
  services: IServiceAndCharges[];
  serviceOptions: IServiceItem[];
  taxList: TaxLookupEntity[];
  currencyList: CurrencyLookupEntity[];
  uomList: IUOM[];
  billingParties: IQBillingParties[];
  selectedProduct: IProduct;
  quotationDetails: IQuotationDetails;
  disableRevenue: boolean;
  disableCost: boolean;
  isAllSelected?: boolean;
  onSelectAll?: () => void;
  selectedList?: number[];
  onSelectServices?: (index: number) => void;
  disableOperatingIncome: boolean;
  exchangeRates: ExchangeRateLookupEntity[];
  isGridDisabled: boolean;

  onDeleteService: (serviceIndex: number) => void;
  onAddCharge: (serviceIndex: number) => void;
  onDeleteCharge: onDeleteChargeFunc;
  onUpdateCharge: onUpdateChargeFunc;
  onUpdateService: onUpdateServiceFunc;
  showTemplateModal: boolean;
  onSubmitImportTemplate: onSubmitImportTemplateFunc;
  onCloseTemplateModal: () => void;
  onShowTariff: onShowTariffFunc;
  columnPreference: ColumnPreferences,
  updateChargeValidation: (validationKey: string, chargeId: string, value: boolean) => void;
}
const ServiceList = (props: Prop) => {
  const {
    services,
    serviceOptions,
    taxList,
    currencyList,
    uomList,
    billingParties,
    selectedProduct,
    onUpdateCharge,
    onUpdateService,
    onAddCharge,
    onDeleteService,
    onDeleteCharge,
    quotationDetails,
    disableRevenue,
    disableCost,
    disableOperatingIncome,
    isAllSelected,
    onSelectAll,
    selectedList,
    onSelectServices,
    exchangeRates,
    showTemplateModal,
    userDetails,
    onShowTariff,
    isGridDisabled,
    columnPreference,
    updateChargeValidation
  } = props

  const onSubmitTemplate = (template: ITemplate[]) => {
    props.onSubmitImportTemplate(template)
  }

  const isReadOnly = isGridDisabled || !isDraftQuotation(quotationDetails);

  return (
    <Grid id="stickyPosition" data-testid="serviceList">
      <Header
        disableRevenue={disableRevenue}
        disableCost={disableCost}
        isAllSelected={isAllSelected}
        onSelectAll={onSelectAll}
        isReadOnly={isReadOnly}
        disableOperatingIncome={disableOperatingIncome}
        columnPreference={columnPreference} />
      {
        services?.map((service: IServiceAndCharges, index: number) => {
          return (
            <div key={isNull(service.service.Id) ? index : service.service.Id} data-testid="servicesCharges">
              <List
                data-testid="quotationGridTable"
                userDetails={userDetails}
                selectedProduct={selectedProduct}
                service={service}
                serviceIndex={index}
                serviceOptions={serviceOptions}
                selectedList={selectedList}
                onSelectServices={onSelectServices}
                taxList={taxList}
                exchangeRates={exchangeRates}
                quotationDetails={quotationDetails}
                currencyList={currencyList}
                uomList={uomList}
                billingParties={billingParties}
                onUpdateCharge={onUpdateCharge}
                onUpdateService={onUpdateService}
                onAddCharge={onAddCharge}
                onDeleteService={onDeleteService}
                onDeleteCharge={onDeleteCharge}
                disableRevenue={disableRevenue}
                disableCost={disableCost}
                disableOperatingIncome={disableOperatingIncome}
                onShowTariff={onShowTariff}
                isGridDisabled={isGridDisabled}
                columnPreference={columnPreference}
                updateChargeValidation={updateChargeValidation}

              />
            </div>
          )
        })
      }
      <div id="importTemplateModal">
        <PegModal
          isOpen={showTemplateModal}
          alertModal={true}
          isCenter={true}
          showTemplate={true}>
          <TemplateView
            details={selectedProduct}
            type={'quotation'}
            onSubmitImportTemplate={onSubmitTemplate}
            onCloseTemplateModal={props.onCloseTemplateModal}
          />
        </PegModal>
      </div>
    </Grid>
  );
};

export default ServiceList;