import PegLabel from 'Controls/Label/PegLabel';
import React from 'react'
import InputBox from 'Controls/Input/PegInputBox';
import Dropdown from 'Controls/Dropdown';
import { businessArea } from 'Constants/DropdownOptions';
import { businessProduct } from '../../../Constants/DropdownOptions';
import PegTextArea from '../../../Controls/TextArea/PegTextArea';

type MyState = {
    newCustomer: | {};
};

type MyProps = {
    newCustomer?: any;
    createNewCustomer: (event: any, key: string) => void;
};

class CreateCustomer extends React.Component<MyProps, MyState> {
    constructor(props: any) {
        super(props);
        this.state = {
            newCustomer: {},
        };
    }

    onUpdateValue = (event: any, key: any) => {
        const {
            createNewCustomer,
        } = this.props
        createNewCustomer(event, key);
    }

    render() {
        const { newCustomer } = this.props;
        return (
            <div className="p-5">
                <div className="grid grid-cols-1 gap-12">
                    <div className="flex flex-row items-center space-y-4 space-x-2">
                        <PegLabel id="lblAccountName" name="accountName" className="w-6/12 text-sm font-normal text-dark">
                            Account Name
                        </PegLabel>
                        <div className="w-full">
                            <InputBox
                                defaultValue={newCustomer?.LegalName}
                                id={'txtAccountName'}
                                key={'accountName'}
                                onChange={(event: any) => this.onUpdateValue(event, 'accountName')}
                            />
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-1 gap-12">
                    <div className="flex flex-row items-center space-y-4 space-x-2">
                        <PegLabel id="lblCommonName" name="commonName" className="w-6/12 text-sm font-normal text-dark">
                            Common Name
                        </PegLabel>
                        <div className="w-full">
                            <InputBox
                                id={'txtCommonName'}
                                key={'commonName'}
                                defaultValue={newCustomer?.Name}
                                onChange={(event: any) => this.onUpdateValue(event, 'commonName')}
                            />
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-1 gap-12">
                    <div className="col-span-1">
                        <div className="flex flex-row items-center space-y-4 space-x-2">
                            <PegLabel id="lblIndustryVertical" name="industryVertical" className="w-6/12 text-sm font-normal text-dark">
                                Industry Vertical
                            </PegLabel>
                            <div className="w-full">
                                <Dropdown
                                    id={'txtIndustryVertical'}
                                    defaultValue={newCustomer?.IndustryVerticals[0]?.Name}
                                    placeHolder={'Select Industry Vertical'}
                                    isLookup={true}
                                    dropdownLabel={'name'}
                                    dropdownValue={'name'}
                                    appendParams={false}
                                    isMandatory={true}
                                    getFormattedLabel={true}
                                    useDebounce={true}
                                    showErrorMessage={'mandatory field'}
                                    url={`/mdm-industry-vertical?searchText=`}
                                    onChangeDropdown={(event: any, key: any) => this.onUpdateValue(event, 'industryVertical')}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-1 gap-12">
                    <div className="flex flex-row items-center space-y-4 space-x-2">
                        <PegLabel id="lblBusinessArea" name="businessArea" className="w-6/12 text-sm font-normal text-dark">
                            Business Area
                        </PegLabel>
                        <div className="w-full">
                            <Dropdown
                                id={'businessArea'}
                                isLookup={false}
                                dropdownLabel={'name'}
                                dropdownValue={'name'}
                                appendParams={false}
                                dropdownOptions={businessArea}
                                placeHolder="Select Area"
                                onChangeDropdown={(event: any, key: any) => this.onUpdateValue(event, 'businessArea')}
                            />
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-1 gap-12">
                    <div className="flex flex-row items-center space-y-4 space-x-2">
                        <PegLabel id="lblBusinessArea" name="businessArea" className="w-6/12 text-sm font-normal text-dark">
                            Business Product
                        </PegLabel>
                        <div className="w-full">
                            <Dropdown
                                id={'businessProduct'}
                                isLookup={false}
                                dropdownLabel={'name'}
                                dropdownValue={'name'}
                                appendParams={false}
                                dropdownOptions={businessProduct}
                                placeHolder="Select Product"
                                onChangeDropdown={(event: any, key: any) => this.onUpdateValue(event, 'businessProduct')}
                            />
                        </div>
                    </div>

                </div>

                <br />
                <h1 className="text-primary">Address Details</h1>
                <div className="divide-solid divide-y border border-indigo-200" />

                <div className="grid grid-cols-2 gap-12">
                    <div className="flex flex-row items-center space-y-4 space-x-2">
                        <PegLabel id="lblAddress" name="address" className="w-6/12 text-sm font-normal text-dark">
                            Address
                        </PegLabel>
                        <div className="w-full">
                            <PegTextArea
                                id={'txtAddress'}
                                key={'address'}
                                onChange={(event: any) => this.onUpdateValue(event, 'address')}
                            />
                        </div>
                    </div>

                    <div className="flex flex-row items-center space-y-4 space-x-2">
                        <PegLabel id="lblPhone" name="phone" className="w-6/12 text-sm font-normal text-dark">
                            Phone
                        </PegLabel>
                        <div className="w-full">
                            <InputBox
                                defaultValue={newCustomer?.Phone}
                                id={'txtPhone'}
                                key={'phone'}
                                onChange={(event: any) => this.onUpdateValue(event, 'phone')}
                            />
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-2 gap-12">
                    <div className="flex flex-row items-center space-y-4 space-x-2">
                        <PegLabel id="lblCity" name="city" className="w-6/12 text-sm font-normal text-dark">
                            City
                        </PegLabel>
                        <div className="w-full">
                            <InputBox
                                defaultValue={newCustomer?.City}
                                id={'txtCity'}
                                key={'city'}
                                onChange={(event: any) => this.onUpdateValue(event, 'city')}
                            />
                        </div>
                    </div>

                    <div className="flex flex-row items-center space-y-4 space-x-2">
                        <PegLabel id="lblEmail" name="Email" className="w-6/12 text-sm font-normal text-dark">
                            Email
                        </PegLabel>
                        <div className="w-full">
                            <InputBox
                                defaultValue={newCustomer?.Email}
                                id={'txtEmail'}
                                key={'email'}
                                onChange={(event: any) => this.onUpdateValue(event, 'email')}
                            />
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-2 gap-12">
                    <div className="flex flex-row items-center space-y-4 space-x-2">
                        <PegLabel id="lblCountry" name="country" className="w-6/12 text-sm font-normal text-dark">
                            Country
                        </PegLabel>
                        <div className="w-full">
                            <Dropdown
                                id={'txtCountry'}
                                defaultValue={newCustomer?.CountryISO2Code}
                                placeHolder={'Select Country'}
                                isLookup={true}
                                dropdownLabel={'name'}
                                dropdownValue={'name'}
                                appendParams={false}
                                isMandatory={true}
                                getFormattedLabel={true}
                                useDebounce={true}
                                showErrorMessage={'mandatory field'}
                                url={`/mdm-country?searchText=`}
                                onChangeDropdown={(event: any, key: any) => this.onUpdateValue(event, 'country')}
                            />
                        </div>
                    </div>


                    <div className="flex flex-row items-center space-y-4 space-x-2">
                        <PegLabel id="lblWebsite" name="website" className="w-6/12 text-sm font-normal text-dark">
                            Website
                        </PegLabel>
                        <div className="w-full">
                            <InputBox
                                id={'txtWebsite'}
                                key={'website'}
                                defaultValue={newCustomer?.Website}
                                onChange={(event: any) => this.onUpdateValue(event, 'website')}
                            />
                        </div>
                    </div>
                </div>

                <div className="grid grid-cols-2 gap-12">
                    <div className="flex flex-row items-center space-y-4 space-x-2">
                        <PegLabel id="lblPostalCode" name="postalCode" className="w-6/12 text-sm font-normal text-dark">
                            Postal Code
                        </PegLabel>
                        <div className="w-full">
                            <InputBox
                                id={'txtPostalCode'}
                                key={'postalCode'}
                                defaultValue={newCustomer?.PostalCode}
                                onChange={(event: any) => this.onUpdateValue(event, 'postalCode')}
                            />
                        </div>
                    </div>

                    <div className="flex flex-row items-center space-y-4 space-x-2">
                        <PegLabel id="lblTelex" name="telex" className="w-6/12 text-sm font-normal text-dark">
                            Telex
                        </PegLabel>
                        <div className="w-full">
                            <InputBox
                                defaultValue={newCustomer?.Telex}
                                id={'txtTelex'}
                                key={'telex'}
                                onChange={(event: any) => this.onUpdateValue(event, 'telex')}
                            />
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
export default CreateCustomer;
