import PegCheckbox from 'Controls/Checkbox/PegCheckbox';
import { ListOverview } from './Preference.styles';

type Props = {
  onSelectColumn?: any;
  column?: any
}

function ListColumn(props: Props) {
  const { column, onSelectColumn } = props;

  return (
    <ListOverview>
      <ol>
        {Object.keys(column).map((key: string, index: number) => {
          return (
            <li key={index}>
              <PegCheckbox
                id={key}
                label={column[key].label?.toUpperCase()}
                checked={column[key].isActive}
                disabled={column[key].isDefault}
                onChange={(e: any) => onSelectColumn(key, e.checked)}
                style={{ cursor: 'pointer', marginRight: '8px' }} />

            </li>
          )
        })}
      </ol>
    </ListOverview>
  )
}

export default ListColumn;
