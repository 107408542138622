import Header from './Header';
import Container from './ContainerLine';
import PegButton from 'Controls/Button/PegButton';
import { Section, ButtonActions } from 'Containers/CargoDetails/cargo.styles';
import { RiAddCircleLine } from 'react-icons/ri';
import SectionHeader from 'Components/SectionHeader/SectionHeader';

type IProps = {
  cargo?: any;
  containerDetails: any[];
  onUpdate: (key: string, event: any, index: number) => void;
  onAdd: () => void;
  onDelete: (id: number) => void;
};
const ContainerTableView = (props: IProps) => {
  const {
    onAdd,
    containerDetails,
    onDelete,
    onUpdate
  } = props
  return (
    <Section className={"mt-4 mb-4"}>
      <SectionHeader title={"Container Details"} />
      <ButtonActions className={"flex flex-row-reverse space-x-2"}>
        <PegButton
          id="cargo_add"
          type="button"
          variant="OutlinePrimary"
          onClick={onAdd}>
          <RiAddCircleLine size="1.2em" className="fill-current text-primary" />
          Add container
        </PegButton>
      </ButtonActions>
      <div>
        {containerDetails && containerDetails.length > 0 && <Header />}
        {
          containerDetails && containerDetails.map((item: any, index: number) => {
            return (
              <Container
                key={index}
                index={index}
                container={item}
                onUpdate={onUpdate}
                onDelete={onDelete} />
            )
          })
        }
      </div>
    </Section>
  )
}
export default ContainerTableView;