import styled from "styled-components";

interface IStylesProps {
  isHeader?: boolean;
  isDetails?: boolean;
  isSticky?: boolean;
  groupColor?: any;
  colour?: any;
  showBorder?: boolean;
  index?: any;
}

export const Row = styled.div<IStylesProps>`
  display: flex;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  height: 36px;
  background-color: ${(props) => (props.isHeader ? '#C1C1C1' : (props.index % 2) === 0 ? "#F5F5F5" : '#FFF')};
  border-bottom: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
`;
export const OverviewHeader = styled.div`
    display: flex;
`;

export const Overview = styled.div`
  display: flex;
`;

export const ID = styled(Row)`
  min-width: 50px;
  max-width: 50px;
`;

export const OfficeID = styled(Row)`
  min-width: 50px;
  max-width: 50px;
`;
export const DepartmentID = styled(Row)`
  min-width: 50px;
  max-width: 50px;
`;
export const OfficeName = styled(Row)`
  min-width: 200px;
  max-width: 200px;
`;

export const DepartmentName = styled(Row)`
  min-width: 200px;
  max-width: 200px;
`;
export const OfficeTypeName = styled(Row)`
  min-width: 150px;
`;

export const OfficeIsActive = styled(Row)`
  min-width: 100px;
`;
export const DepartmentIsActive = styled(Row)`
  min-width: 100px;
`;

export const IsMainOffice = styled(Row)`
  min-width: 150px;
`;

export const Code = styled(Row)`
  min-width: 100px;
`;

export const Description = styled(Row)`
  min-width: 200px;
  max-width: 200px;
`;

export const LoaderOverview = styled.div<IStylesProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  marginTop: 100px
`;

export const LoaderOverviewGrid = styled.div<IStylesProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 50vh);
`;

export const TemplateOverview = styled.div<IStylesProps>`
  display: flex;
  width: 79vw;
  height: 65vh;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
`;
