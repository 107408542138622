
import {
  TariffOverview,
  TariffHeader,
  TariffIcon,
} from './details.styles';
import styled from 'styled-components';
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { useState } from 'react';

type Props = {
  tariff?: any;
};

interface IStylesProps {
  isHeader?: boolean;
  colour?: any;
  showBorder?: boolean;
  isLast?: boolean;
  isBreakdown?: boolean;
  count?: number
}

const Tariff = (props: Props) => {
  const {
    tariff
  } = props;
  const [showDetails, setShowDetails] = useState(false);
  let details = JSON.parse(tariff.replace(/\s(?=\w+":)/g, ""));

  return (
    <TariffOverview>
      <TariffHeader onClick={() => setShowDetails(!showDetails)}>
        Tariff Breakdown
        <TariffIcon>
          {
            showDetails ?
              <AiOutlineUp
                style={{ cursor: 'pointer', color: '#000' }} />
              :
              <AiOutlineDown
                style={{ cursor: 'pointer', color: '#000' }} />
          }
        </TariffIcon>
      </TariffHeader>
      {showDetails &&
        <Grid>
          <Breakdown isHeader>
            Tariff Details
          </Breakdown>
          <List>
            <Breakdown isBreakdown>
              {Object.keys(details).map((key, i) => {
                return (
                  key != "v_BasicFee" && details[key] != null && <ul key={key}>
                    <UnorderedList>
                      {key.replace(/([A-Z])/g, ' $1').trim()} - {details[key]}
                    </UnorderedList>
                    <Divider count={Object.keys(details).length} />
                  </ul>
                );
              })}

            </Breakdown>
          </List>
        </Grid>
      }
    </TariffOverview>
  )
};

export default Tariff;

const Grid = styled.div<IStylesProps>`
  margin-top: 20px;
`
const Divider = styled.hr<IStylesProps>`
border: 0.5px solid #DFDFDF;
width: 100% !important
`
const Row = styled.div<IStylesProps>`
  display: flex;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 36px;
  background-color: ${(props) => (props.isHeader ? '#C1C1C1' : '#FFF')};
  border-right: 1px solid #D8D8D8;
  border-left: 1px solid #D8D8D8;
`;

const List = styled.div<IStylesProps>`
  display: flex;
  width: fit-content;
`
const Breakdown = styled(Row)`
  display: ${(props) => (props?.isBreakdown ? 'block' : 'flex')};
  font-size: 14px;
  font-weight: bold;
  text-align: left;
`;
const UnorderedList = styled.li<IStylesProps>`
padding: 5px;
`;

const Button = styled.button<IStylesProps>`
color: blue;
`
