
import {
  useEffect,
  useState
} from 'react';
import {
  Overview,
  Header,
  Footer,
  Content
} from './price.syles';
import PegButton from 'Controls/Button/PegButton';
import PriceGrid from './components/PriceGrid'
import { GrFormClose } from "react-icons/gr";
import axios from 'axios';
import Loader from 'Components/Loader';

type Props = {
  onClose: () => void;
  selectedList?: any;
  jobDetail: any;
  isPriceSummaryPosting: boolean;
  sendPricingSummary: (pricingSummary: any) => void;
};

const PriceSummary = (props: Props) => {
  const {
    onClose,
    jobDetail,
    sendPricingSummary,
    isPriceSummaryPosting
  } = props;
  const [priceSummary, setPriceSummary] = useState([]);
  const [isError, setError] = useState(false);
  const [selectedSummary, SetSelectedSummary] = useState([]);

  useEffect(() => {
    //jobDetail.LocalJobId
    axios.get(`getPriceSummaryData?jobId=9112`).then((response) => {
      if (response) {
        setPriceSummary(response.data)
      } else {
        setError(true)
      }
    })
  }, [])

  const updatePricingSummary = (id: string, key: number, value: any) => {
    let tempSummary: any = priceSummary;
    let priceSummaryData = tempSummary.priceSummaryData
    if (id === 'bankAccount') {
      if (value) {
        priceSummaryData[key][0] = {
          ...priceSummaryData[key][0],
          'BankCode': value.BankAccountId,
        }
      } else {
        priceSummaryData[key][0] = {
          ...priceSummaryData[key][0],
          'BankCode': '',
          'BankAccountNumber': ''
        }
      }
    }
    if (id === 'requestedAmount') {
      priceSummaryData[key][0] = {
        ...priceSummaryData[key][0],
        'RequestedAmount': value,
        'PercentageFunds': (parseInt(value) / priceSummaryData[key][0].TotalNetPrice) * 100
      }
    }
    tempSummary.priceSummaryData = priceSummaryData;
    setPriceSummary(tempSummary)
  }

  const onSelectPriceSummary = (id: number) => {
    let tempList: any = selectedSummary
    if (!tempList.includes(id)) {
      tempList.push(id)
    } else {
      tempList.splice(tempList.indexOf(id), 1);
    }
    SetSelectedSummary(tempList)
  }

  return (
    <Overview>
      <Header>
        <GrFormClose
          onClick={onClose}
          className="icon-style" />
      </Header>
      <Content>
        <PriceGrid
          updatePricingSummary={(id: string, key: number, value: any) => updatePricingSummary(id, key, value)}
          onSelectPriceSummary={onSelectPriceSummary}
          priceSummary={priceSummary} />
      </Content>
      <Footer>
        <PegButton
          id="import_template"
          type="button"
          variant="Primary"
          styleObject={{ width: 80 }}
          onClick={onClose}>
          Close
        </PegButton>
        <PegButton
          id="import_template"
          type="button"
          variant="Primary"
          styleObject={{ marginLeft: 12, width: 180 }}
          onClick={() => sendPricingSummary(priceSummary)}>
          {isPriceSummaryPosting ?
            <Loader
              color={'#FFF'}
              size={24} />
            :
            'Send Price Summary'}
        </PegButton>
      </Footer>
    </Overview>
  )
};

export default PriceSummary;
