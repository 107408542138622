import { ErrorType, GridNamesType } from "./types";

const GRID_ERROR_NAMES: GridNamesType = {
    Name: "Name",
    PriceCurrencyCode: "Price Currency",
    CostCurrencyCode: "Cost Currency",
    CostExchangeRate: "Cost Currency Rate",
    SupplierName: "Supplier",
    Quantity: "Quantity",
    UomName: "UOM",
    UnitPrice: "Unit Price",
    UnitCost: "Unit Cost",
    BillingPartyCode: "Billing Party",
    BillingCurrencyCode: "Billing Currency",
    BillingExchangeRate: "Billing Currency Rate",
    OperationalProcessPartyFk: "Billing Party",
    UomCode: "UoM",
    VoucherType: "Voucher Type",
    CustomerService: "Customer Service",
    DolphinCode: "Dolphin Code"
};

export const formatErrors = (errors: any[]) => {
    let errorsByProduct: ErrorType = {};

    errors.forEach((error) => {
        const { productName, serviceName, chargeName, key, type, message } = error;

        if (!errorsByProduct[productName]) {
            errorsByProduct[productName] = { errors: [] };
        }

        if (!errorsByProduct[productName][serviceName]) {
            errorsByProduct[productName][serviceName] = { errors: [], charges: {} };
        }

        if (type === "missingJobRevenue") {
            errorsByProduct[productName].errors.push({ key, type, message });
        }

        if (type === "exchangeRate") {
            errorsByProduct[productName][serviceName].errors.push({ key: GRID_ERROR_NAMES[key], type: "exchangeRate", message: message });
        }

        if (type === "dolphinCode") {
            if (!chargeName) {
                errorsByProduct[productName][serviceName].errors.push({ key: GRID_ERROR_NAMES[key], type: type, message: message });
            } else {
                if (!errorsByProduct[productName][serviceName].charges[chargeName]) {
                    errorsByProduct[productName][serviceName].charges[chargeName] = { errors: [] };
                }

                errorsByProduct[productName][serviceName].charges[chargeName]?.errors.push({ key: GRID_ERROR_NAMES[key], type: type, message: message });
            }
        }

        if (type === "required") {
            if (!chargeName) {
                errorsByProduct[productName][serviceName].errors.push({ key: GRID_ERROR_NAMES[key], type: "required" });
            } else {
                if (!errorsByProduct[productName][serviceName].charges[chargeName]) {
                    errorsByProduct[productName][serviceName].charges[chargeName] = { errors: [] };
                }
                errorsByProduct[productName][serviceName].charges[chargeName].errors.push({ key: GRID_ERROR_NAMES[key], type: "required" });
            }
        }
    });

    return errorsByProduct;
};

export const getGridErrorData = (error: {
    product?: any,
    service: any,
    charge?: any,
    key: string,
    type?: string,
    message?: string
}) => {
    const { product, service, charge, key, type, message } = error;
    const productName = product?.ProductDetails?.ProductName;
    const serviceName = service?.Name;
    const chargeName = charge?.Name;

    return {
        productName,
        serviceName,
        chargeName,
        key: key,
        type: type,
        message: message
    }
}

export const listMissingColumnsForItem = (columns: any[], type: string) => {

    if (!columns || !type) {
        return null
    }

    const items = columns.filter(d => d.type == type);

    if (items.length === 0) {
        return null;
    }

    const maxKeyIndex = items.length - 1;
    const itemErrors = items.map((item: any, index: number) => {
        const { key, message } = item
        return (<span key={`${index}`}>{key}{message}{index < maxKeyIndex ? "," : ""} </span>);
    });

    return itemErrors
}

