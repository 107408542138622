export const setServiceValue = (obj: any, key: any, event: any) => {
  if (key === 'startDate') {
    obj.StartDateTime = event
  }
  if (key === 'endDate') {
    obj.StartDateTime = event
  }
  if (key === 'billingParty') {
    if (event) {
      obj.Name = event.name
      obj.Code = event.code
    } else {
      obj.Name = ""
      obj.Code = ""
    }
  }
  if (key === 'taxType') {
    if (event) {
      obj.Name = event.name
      obj.Code = event.code
    } else {
      obj.Name = ""
      obj.Code = ""
    }
  }
  if (key === 'name') {
    if (event) {
      obj.Name = event.name
      obj.Code = event.code
    } else {
      obj.Name = ""
      obj.Code = ""
    }
  }
  if (key === 'UOM') {
    if (event) {
      obj.UoMName = event.name
      obj.UoMCode = event.code
    } else {
      obj.UoMName = ""
      obj.UoMCode = ""
    }
  }
  if (key === 'priceCurrency') {
    if (event) {
      obj.PriceCurrencyCode = event.name
      obj.PriceCurrencyName = event.code
    } else {
      obj.PriceCurrencyCode = ""
      obj.PriceCurrencyName = ""
    }
  }
  if (key === 'supplier') {
    if (event) {
      obj.SupplierCode = event.name
      obj.SupplierName = event.code
    } else {
      obj.SupplierCode = ""
      obj.SupplierName = ""
    }

  }
  if (key === 'costCurrency') {
    if (event) {
      obj.CostCurrencyCode = event.name
      obj.CostCurrencyName = event.code
    } else {
      obj.CostCurrencyCode = ""
      obj.CostCurrencyName = ""
    }
  }
  return obj
}

export const editServiceObject = (obj: any, key: any, event: any) => {
  switch (key) {
    case 'supplier':
      obj.SupplierCode = event.code
      obj.SupplierName = event.name
      break;
    case 'priceCurrency':
      obj.PriceCurrencyName = event.name
      obj.PriceCurrencyCode = event.code
      break;
    case 'taxType':
      obj.TaxTypeCode = event.Code
      obj.TaxTypeName = event.Name
      break;
    case 'costCurrency':
      obj.CostCurrencyCode = event.code
      obj.CostCurrencyName = event.name
      break;
    case 'startDate':
      obj.StartDateTime = event
      break;
    case 'endDate':
      obj.EndDateTime = event
      break;
  }
  return obj
}
