import {
  CREATE_VOYAGE_ROW,
  DELETE_VOYAGE_ROW,
  UPDATE_VESSEL_DETAILS,
  UPDATE_VESSEL_ADDITIONAL_DETAILS,
  UPDATE_VESSEL_VALIDATIONS,
  UPDATE_OPTION_DETAILS,
  UPDATE_SERVICES,
  UPDATE_QUOTATION_VESSEL_DETAILS,
  UPDATE_OPTION_VALIDATIONS,
  ENABLE_CARGO_TAB,
  ENABLE_CREW_TAB,
  ENABLE_SHIP_SPARES,
} from "Redux/ActionTypes/Vessel";
import { RESET_VESSEL_STATE } from "Redux/ActionTypes/ActionTypes";
import {
  setLinkOptionValue,
  setVesselDetails,
  setVesselSectionValue,
  setVoyageTableValue
} from "Utils/Quotation";
import {
  vesselNameRequired,
  cargoTypeRequired,
  terminalNameRequired,
  portNameRequired
} from "Constants/Validations";
import { VesselDetail } from "Model/Order/VesselDetail";
import { OptionDetail } from "Model/Order/OptionDetail";
import { LINK_VESSEL_CALL, LINK_OPTION_DETAILS } from '../ActionTypes/Vessel';
import {
  chargeObject,
  serviceObject
} from "Constants/Constant";



let initialState = {
  vesselDetails: new VesselDetail(),
  isCrewEnabled: false,
  isCargoEnabled: false,
  isShipSparesEnabled: false,
  optionDetails: [],
  errorCount: 0,
  validation: {
    VesselName: { error: false, message: vesselNameRequired },
    CargoTypeName: { error: false, message: cargoTypeRequired },
  },
  optionsValidation: {
    PortName: { error: false, message: portNameRequired },
    TerminalName: { error: false, message: terminalNameRequired },
  }
}

export const vessel = (state = initialState, action: any) => {

  switch (action.type) {
    case UPDATE_VESSEL_DETAILS:
      const obj = setVesselSectionValue(state.vesselDetails, action.key, action.event);
      return {
        ...state,
        vesselDetails: {
          ...state.vesselDetails,
          ...obj
        }
      };
    case LINK_VESSEL_CALL:
      return {
        ...state,
        vesselDetails: { ...action.data },
      }

    case UPDATE_VESSEL_ADDITIONAL_DETAILS:
      const objVessel = setVesselDetails(state.vesselDetails, action.data);
      return {
        ...state,
        vesselDetails: { ...objVessel }
      }

    case UPDATE_VESSEL_VALIDATIONS:
      return {
        ...state,
        validation: { ...action.validation }
      }

    case CREATE_VOYAGE_ROW:
      let option = new OptionDetail();
      let services = [{
        ...serviceObject,
        ServiceDetailId: 1,
        SortOrder: 1,
        charges: [
          {
            ...chargeObject,
            ServiceDetailId: 1,
            SortOrder: 1,
          },
        ]
      }]
      option = {
        ...option,
        ServiceDetail: services
      }
      let newOptions: any[] = state.optionDetails.slice();
      newOptions.push(option)
      return {
        ...state,
        optionDetails: newOptions
      }

    case DELETE_VOYAGE_ROW:
      let newState = [...state.optionDetails];
      newState.splice(action.index, 1);
      return {
        ...state,
        optionDetails: newState
      }

    case UPDATE_OPTION_DETAILS:
      const row = setVoyageTableValue(state.optionDetails[action.index], action.key, action.event, action.index)

      let newStatew = [...state.optionDetails];
      newStatew.splice(action.index, 1);

      return {
        ...state,
        optionDetails: [
          ...newStatew,
          row
        ]
      };

    case LINK_OPTION_DETAILS:
      const rowOption = setLinkOptionValue(state.optionDetails[0], action.data);
      return {
        ...state,
        optionDetails: [
          rowOption
        ]
      };

    case UPDATE_SERVICES:
      let optionDetails: any[] = state.optionDetails
      optionDetails[action.id] = action.serviceList
      return {
        ...state,
        optionDetails: [...optionDetails]
      };

    case ENABLE_CREW_TAB:
      return {
        ...state,
        isCrewEnabled: action.value
      };

    case ENABLE_CARGO_TAB:
      return {
        ...state,
        isCargoEnabled: action.value
      };

    case ENABLE_SHIP_SPARES:
      return {
        ...state,
        isShipSparesEnabled: action.value
      };

    case RESET_VESSEL_STATE:
      return {
        vesselDetails: new VesselDetail(),
        optionDetails: [],
        errorCount: 0,
        validation: {
          VesselName: { error: false, message: vesselNameRequired },
          CargoTypeName: { error: false, message: cargoTypeRequired },
        }
      };

    case UPDATE_OPTION_VALIDATIONS:
      return {
        ...state,
        errorCount: action.errorCount
      };

    case UPDATE_QUOTATION_VESSEL_DETAILS:
      return {
        ...state,
        vesselDetails: {
          ...state.vesselDetails,
          ...action.data
        }
      };

    default:
      return state;
  }
};

