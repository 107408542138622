import { IFreightDetail } from "Model/Order/FreightDetails";
import {
    FREIGHT_DETAILS
} from "../ActionTypes/ActionTypes";
import { RESET_FREIGHT_STATE } from "Redux/ActionTypes/ActionTypes";

export function updateFreightDetails(data: IFreightDetail) {
    return {
        type: FREIGHT_DETAILS,
        data
    };
}

export function resetFreightState() {
    return {
        type: RESET_FREIGHT_STATE
    };
}