
import { QuoteService } from "Model/Order/QuoteService";
import { QuotationAreaType } from "Constants/QuotationAreaType";
import { UPDATE_QUOTE_SERVICES } from "Redux/ActionTypes/QuoteServices";


let initialState = {
  QuoteService: [] as QuoteService[]
}

export const quoteServices = (state = initialState, action: any) => {

  switch (action.type) {

    case UPDATE_QUOTE_SERVICES:

      let tempAdditionalServices = [...state.QuoteService];
      let index = tempAdditionalServices?.findIndex(x => x.Name === action.key);

      if (index === -1) {

        let vesselService = new QuoteService();
        vesselService.Name = action.key
        vesselService.Code = action.key
        vesselService.QuotationAreaType = QuotationAreaType.Shipping

        return {
          ...state,
          QuoteService: [...tempAdditionalServices, vesselService]
        };

      } else {

        tempAdditionalServices.splice(index, 1);

        return {
          ...state,
          QuoteService: [...tempAdditionalServices]
        };

      }

    default:
      return state;
  }
};

