import styled from "styled-components";

export const Header = styled.div`
  position: relative;
  top: 5px;
  text-align: left;
  width: 50px;
  height: 30px;
`;

export const Wrapper = styled.div`
  padding: 20px;
`;

export const Table = styled.div`
display: flex;
justify-content: center;
margin: auto;
table-layout: fixed;
width: 1000px;
`;




