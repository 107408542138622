
import React from "react";
import { AccordionComponent } from "Controls/Accordion/index";
import CustomerDetail from "Containers/CustomerDetail";
import VesselDetail from "Containers/VesselDetail";
import QuotationAgent from './../../Api/QuotationAgent';
import Cargo from "Containers/CargoDetails/index";
import ApprovalProcess from 'Containers/ApprovalProcess/index';
import SectionHeader from './../../Components/SectionHeader/SectionHeader';
import ServiceGrid from "Containers/ServiceGrid";
import PegStepper from "Controls/Stepper/PegStepper";
import { STEPS } from 'Constants/Constant';


type MyState = {
    selectedIndex: number,
    details: [],
    customer: any,
    vessel: any,
    cargo: any,
    option: [],
    currentStep: number,
}
type MyProps = {
    selectedIndex?: number,
    customerDetails?: any,
    quotationList?: any,
    id?: any,
    updateQuotationCustomerDetails: (data: object) => void,
    updateQuotationVesselDetails: (data: object) => void,
    updateQuotationCargoDetails: (data: object) => void,
    updateQuotationOptionDetails: (data: object) => void,
}

class OverviewPage extends React.Component<MyProps, MyState> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedIndex: 0,
            customer: {},
            vessel: {},
            cargo: {},
            option: [],
            details: [],
            currentStep: 1
        };
    }

    componentDidMount = () => {
        QuotationAgent.get(Number(this.props.id), 'THN%2301')
            .then((data: any) => {
                this.setState({ customer: data.CustomerDetail, vessel: data.VesselDetail, cargo: data.CargoDetail, option: data.OptionDetail });
            })
            .finally(() => {
                this.props.updateQuotationCustomerDetails(this.state.customer);
                this.props.updateQuotationVesselDetails(this.state.vessel);
                this.props.updateQuotationCargoDetails(this.state.cargo);
                this.props.updateQuotationOptionDetails(this.state.option);
            })
        const filteredData = this.props.quotationList?.filter((el: any) => {
            return el.QuotationId === Number(this.props.id);
        })

        const status = filteredData[0]?.QuotationStatus;
        if (status === "Draft") {
            this.handleStepper(1)
        } else if (status === "Customer Approval") {
            this.handleStepper(2)
        } else if (status === "Approved") {
            this.handleStepper(3)
        } else if (status === "Job Created") {
            this.handleStepper(4)
        }
    };

    handleStepper = (step: any) => {
        const { currentStep } = this.state;
        this.setState({ currentStep: currentStep + step })
    }

    render() {
        const { currentStep } = this.state;
        return (
            <div className="px-8 py-8">
                <div className={"flex flex-row justify-between"}>
                    <SectionHeader title={"Summary"} style={{ margin: '10px 0 0 2px', fontSize: '18px' }} />
                    <ApprovalProcess />
                </div>
                <div className="container horizontal mb-12 m-auto">
                    <PegStepper
                        steps={STEPS}
                        currentStepNumber={currentStep}
                    />
                </div>

                <AccordionComponent title="Customer Details">
                    <CustomerDetail />
                </AccordionComponent>
                <AccordionComponent title="Vessel Details">
                    <VesselDetail />
                </AccordionComponent>
                <AccordionComponent title="Cargo Details">
                    <Cargo />
                </AccordionComponent>
                <AccordionComponent title="Services/Charges">
                    <ServiceGrid />
                </AccordionComponent>

            </div>
        );
    }
}
export default OverviewPage;



