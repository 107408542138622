import { IJobTariffDetails } from "./types";



/* eslint-disable @typescript-eslint/naming-convention */
export interface ICharge {
  BackToBack?: null | number;
  BillAmount?: null | number;
  BillingCurrencyCode?: string;
  BillingExchangeRate?: null | number;
  BillingMethodCode?: string;
  BillingOptions?: string;
  BillingPriorityCode?: string;
  BillingSequence?: null | number;
  BillingStatus?: string;
  Code?: string;
  CostUomCode?: string;
  CostUomName?: string;
  CostQuantity?: null | number;
  CostCurrencyCode?: string;
  CostExchangeRate?: null | number;
  CostSummaryFk: null | number;
  CostSummaryStatus: string;
  CostSummaryVersion: string;
  CostTaxAmount?: null | number;
  CostTaxCode?: string;
  CostTaxPercent?: null | number;
  CostVariance?: null | number;
  CreatedAt?: string;
  CreatedBy?: string;
  CreditAmount: null | number;
  CustomerService: string;
  EstimatedCost?: null | number;
  ExternalRemarks?: string;
  EyeshareCost?: null | number;
  EyeshareTax?: null | number;
  GSServiceCode: string;
  GSServiceName: string;
  Id?: null | number;
  InternalRemarks: string;
  IsActive?: boolean;
  IsBillable: null | boolean;
  IsCashVoucherNeeded: null | boolean;
  IsCharge?: boolean;
  IsCostDitributed?: null | boolean;
  IsExternallyEdited: null | boolean;
  IsFromMaster: null | boolean;
  LocalJobId?: null | number;
  LineNumber: string | null;
  MasterCode?: any;
  ModifiedAt?: string;
  ModifiedBy?: string;
  Name?: string;
  OffsetAmount: null | number;
  OperatingIncomeActual?: null | number;
  OperationalProcessFk?: null | number;
  OperationalProcessPartyFk?: null | number;
  ParentServiceFk?: null | number;
  PaymentTermCode?: string;
  PriceCurrencyCode?: string;
  PriceExchangeRate?: null | number;
  PriceTaxAmount?: null | number;
  PriceTaxCode?: string;
  PriceTaxPercent?: null | number;
  Quantity?: null | number;
  QuotationNumber?: string;
  rowId?: null | number; // not getting from server
  ReceivedQuantity: null | number;
  SupplierCode?: string;
  SupplierName?: string;
  TariffDetails: IJobTariffDetails;
  TotalEstimatedCost?: null | number;
  TotalEyeshareAmount?: null | number;
  TotalPriceAmount?: null | number;
  UnitCost?: null | number;
  UnitPrice?: null | number;
  UomCode?: string;
  UomName?: string;
  VoucherFk: null | number;
  VoucherNumber: null | string;
  VoucherStatus: null | string;
}
export class Charge implements ICharge {
  BackToBack?: null | number;
  BillAmount?: null | number;
  BillingCurrencyCode?: string;
  BillingExchangeRate?: null | number;
  BillingMethodCode?: string;
  BillingOptions?: string;
  BillingPriorityCode?: string;
  BillingSequence?: null | number;
  BillingStatus?: string;
  Code?: string;
  CostUomCode?: string;
  CostUomName?: string;
  CostQuantity?: null | number;
  CostCurrencyCode?: string;
  CostExchangeRate?: null | number;
  CostSummaryFk: null | number;
  CostSummaryStatus: string;
  CostSummaryVersion: string;
  CostTaxAmount?: null | number;
  CostTaxCode?: string;
  CostTaxPercent?: null | number;
  CostVariance?: null | number;
  CreatedAt?: string;
  CreatedBy?: string;
  CreditAmount: null | number;
  CustomerService: string;
  EstimatedCost?: null | number;
  ExternalRemarks?: string;
  EyeshareCost?: null | number;
  EyeshareTax?: null | number;
  GSServiceCode: string;
  GSServiceName: string;
  Id?: null | number;
  InternalRemarks: string;
  IsActive?: boolean;
  IsBillable: null | boolean;
  IsCashVoucherNeeded: null | boolean;
  IsCharge?: boolean;
  IsCostDitributed?: null | boolean;
  IsExternallyEdited: null | boolean;
  IsFromMaster: null | boolean;
  LocalJobId?: null | number;
  LineNumber: string | null;
  MasterCode?: any; // not getting from server
  ModifiedAt?: string;
  ModifiedBy?: string;
  Name?: string;
  OffsetAmount: null | number;
  OperatingIncomeActual?: null | number;
  OperationalProcessFk?: null | number;
  OperationalProcessPartyFk?: null | number;
  ParentServiceFk?: null | number;
  PaymentTermCode?: string;
  PriceCurrencyCode?: string;
  PriceExchangeRate?: null | number;
  PriceTaxAmount?: null | number;
  PriceTaxCode?: string;
  PriceTaxPercent?: null | number;
  Quantity?: null | number;
  QuotationNumber?: string;
  rowId?: null | number; // not getting from server
  ReceivedQuantity: null | number;
  SupplierCode?: string;
  SupplierName?: string;
  TariffDetails: IJobTariffDetails;
  TotalEstimatedCost?: null | number;
  TotalEyeshareAmount?: null | number;
  TotalPriceAmount?: null | number;
  UnitCost?: null | number;
  UnitPrice?: null | number;
  UomCode?: string;
  UomName?: string;
  VoucherFk: null | number;
  VoucherNumber: null | string;
  VoucherStatus: null | string;

  constructor() {
    this.BillAmount = null;
    this.BillingCurrencyCode = "";
    this.BillingExchangeRate = null;
    this.BillingMethodCode = "";
    this.BillingOptions = "";
    this.BillingPriorityCode = "";
    this.BillingSequence = null;
    this.BillingStatus = "";
    this.Code = "";
    this.CostUomCode = "";
    this.CostUomName = "";
    this.CostQuantity = 1;
    this.CostCurrencyCode = "";
    this.CostExchangeRate = null;
    this.CostSummaryFk = null;
    this.CostSummaryStatus = "";
    this.CostSummaryVersion = "";
    this.CostTaxAmount = null;
    this.CostTaxCode = "";
    this.CostTaxPercent = null;
    this.CostVariance = null;
    this.CreatedAt = "";
    this.CreatedBy = "";
    this.CreditAmount = null;
    this.CustomerService = "";
    this.EstimatedCost = null;
    this.ExternalRemarks = "";
    this.EyeshareCost = null;
    this.EyeshareTax = null;
    this.GSServiceCode = "";
    this.GSServiceName = "";
    this.Id = 0;
    this.InternalRemarks = "";
    this.IsActive = true;
    this.IsBillable = null;
    this.IsCharge = true;
    this.IsCostDitributed = null;
    this.IsExternallyEdited = null;
    this.IsFromMaster = null;
    this.LocalJobId = null;
    this.LineNumber = null;
    this.ModifiedAt = "";
    this.ModifiedBy = "";
    this.Name = "";
    this.OffsetAmount = null;
    this.OperatingIncomeActual = null;
    this.OperationalProcessPartyFk = null;
    this.ParentServiceFk = null;
    this.PaymentTermCode = "";
    this.PriceCurrencyCode = "";
    this.PriceExchangeRate = null;
    this.PriceTaxAmount = null;
    this.PriceTaxCode = "";
    this.PriceTaxPercent = null;
    this.Quantity = 1;
    this.QuotationNumber = "";
    this.rowId = null;
    this.ReceivedQuantity = null;
    this.SupplierCode = "";
    this.SupplierName = "";
    this.TariffDetails = {
      TariffAppliedBy: "",
      TariffEditedBy: "",
      TariffBreakdown: "",
      CreatedBy: "",
      ModifiedBy: "",
      ErrorMessage: ""
    };
    this.TotalEstimatedCost = null;
    this.TotalEyeshareAmount = null;
    this.TotalPriceAmount = null;
    this.UnitCost = null;
    this.UnitPrice = null;
    this.UomCode = "";
    this.UomName = "";
    this.IsCashVoucherNeeded = false;
    this.VoucherNumber = "";
    this.VoucherFk = null;
    this.VoucherStatus = "";
    this.rowId = 0;
    this.MasterCode = null;
  }
}




