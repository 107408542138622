import {
  GET_PORT_DETAILS,
  UPDATE_PORT_DETAILS,
  SAVE_PORT_DETAILS,
  PORT_LOADING,
  PORT_SAVING,
  UPDATE_CENTER,
  GET_PORT_EDITOR_DETAILS,
  SET_PORT_EDITOR_LOADING,
  SAVE_PORT_EDITOR_DETAILS,
  UPDATE_TERMINAL_DETAILS,
  PORT_EDITOR_SAVING,
  UPDATE_PORT_EDITOR_DETAILS
} from '../ActionTypes/Port'

var initialState: any = {
  validation: {
    AssignedToUserCode: true,
    Country: true,
    LatLng: true,
    Name: true
  },
  portDetails: {},
  center: {
    lat: 24.9848,
    lng: 55.0716
  },
  isLoading: true,
  isSaving: false,
  portEditorDetails: {},
  terminals: [],
  editorLoading: true,
  isPortEdited: false,
  isPortEditorSaving: false
}

export const port = (state = initialState, action: any) => {

  switch (action.type) {

    case GET_PORT_DETAILS:
      const centerPoint = action.response?.LatLng[0]
      return {
        ...state,
        portDetails: action.response,
        center: centerPoint ? centerPoint : state.center
      };

    case GET_PORT_EDITOR_DETAILS:
      let center;
      if (action?.response?.Port) {
        center = {
          lat: action?.response?.Port?.Latitude,
          lng: action?.response?.Port?.Longitude
        }
      }
      return {
        ...state,
        portEditorDetails: action.response?.Port,
        terminals: action.response?.Terminal,
        center: (center?.lat && center?.lng) ? center : state.center
      };

    case SET_PORT_EDITOR_LOADING:
      return {
        ...state,
        editorLoading: action.value
      }

    case UPDATE_PORT_DETAILS:
      return {
        ...state,
        portDetails: {
          ...state.portDetails,
          [action.key]: action.value
        },
        [action.key]: action.value
      };

    case SAVE_PORT_DETAILS:
      return {
        ...state,
        portDetails: action.response
      };

    case PORT_LOADING:
      return {
        ...state,
        isLoading: action.value
      };

    case UPDATE_CENTER:
      return {
        ...state,
        center: action.value
      };

    case PORT_SAVING:
      return {
        ...state,
        isSaving: action.value
      };

    case SAVE_PORT_EDITOR_DETAILS:
      return {
        ...state,
        isPortEditorSaving: false
      };

    case UPDATE_TERMINAL_DETAILS:
      return {
        ...state,
        terminals: [...action.terminals],
        isPortEdited: true
      };

    case PORT_EDITOR_SAVING:
      return {
        ...state,
        isPortEditorSaving: action.value
      };

    case UPDATE_PORT_EDITOR_DETAILS:
      return {
        ...state,
        portEditorDetails: { ...action.port }
      };

    default:
      return state
  }

}