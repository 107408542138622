import React from 'react';
import {
  Overview,
  LoaderView,
  LoaderOverview
} from './port.styles'
import Map from './Components/map'
import PortAssignment from './Components/PortAssignment'
import {
  LoadScript
} from "@react-google-maps/api";
import PegModal from 'Controls/PegModal';
import Loader from 'Components/Loader';
import axios from 'axios';
const mapKey = process.env.REACT_APP_MAPS_KEY;

const libraries: any = ["drawing", "places"];

type MyState = {
  isValidated: boolean;
  points: any[];
  getPointsLoading: boolean;
  markerPoints: any[]
};

type MyProps = {
  match?: any;
  validation: any;
  center: any;
  isSaving: boolean;
  portDetails: any;
  isLoading: boolean;
  updatePortDetail: (key: string, value: any) => void;
  updateCenter: (value: any) => void;
  setLoading: (value: boolean) => void;
  savePortDetail: (params: any) => void;
  setPortSaving: (value: boolean) => void;
  getPortDetails: (id: number) => void;
};

class Port extends React.Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isValidated: true,
      points: [],
      markerPoints: [],
      getPointsLoading: false
    };
  }

  componentDidMount() {
    const {
      match,
      getPortDetails,
      setLoading
    } = this.props
    if (match?.params?.id) {
      getPortDetails(match?.params?.id)
    } else {
      setLoading(false)
    }
    document.title = "Port Assignment"
  }

  onDeletePoint = (index: number) => {
    const {
      portDetails,
    } = this.props
    let updatePath = portDetails?.LatLng?.filter((_: any, i: number) => i !== index)
    this.props.updatePortDetail('LatLng', updatePath)
  }

  onUpdateCountry = (center: any, details: any) => {
    const {
      updatePortDetail,
      updateCenter
    } = this.props
    updatePortDetail('Country', details);
    updateCenter(center)
  }

  onUpdateUser = (users: any[]) => {
    const {
      updatePortDetail,
    } = this.props
    updatePortDetail('AssignedToUserCode', users)
  }

  onUpdateTitle = (event: any) => {
    this.props.updatePortDetail('Name', event.target.value)
  }

  onSubmitPort = () => {
    const {
      portDetails
    } = this.props
    this.checkValidation(portDetails)
  }

  checkValidation = (portObject: any) => {
    const {
      validation,
      setPortSaving,
      savePortDetail
    } = this.props
    let isValidation = true;
    Object.keys(validation).forEach((key) => {
      if (!portObject[key] || !Object.keys(portObject[key]).length) {
        isValidation = false;
      }
    })
    this.setState({ isValidated: isValidation })
    if (isValidation) {
      setPortSaving(true)
      savePortDetail(portObject)
    }
  }

  getPoints = () => {
    this.setState({ getPointsLoading: true })
    const portArea = JSON.stringify(this.props.portDetails?.LatLng)
    axios.get(`/oum-getPortsInsidePortArea`,
      {
        params: {
          latLng: portArea,
          country: this.props.portDetails?.Country
        }
      }
    ).then((response: any) => {
      let markerPoints: any = response.data.map((point: any) => {
        return {
          lat: point.Latitude,
          lng: point.Longitude
        }
      })
      this.setState({
        points: response.data,
        getPointsLoading: false,
        markerPoints
      })
    })
  }

  render() {
    const {
      center,
      updatePortDetail,
      isSaving,
      portDetails,
      isLoading
    } = this.props

    const {
      isValidated,
      points,
      getPointsLoading,
      markerPoints
    } = this.state

    const {
      Name,
      Country,
      AssignedToUserCode,
      LatLng
    } = portDetails

    if (isLoading) {
      return (
        <LoaderOverview>
          <Loader gridLoader={true} color={'#aacbe9'} />
        </LoaderOverview>
      )
    }

    return (
      <Overview>
        <LoadScriptComponent
          id="script-loader"
          googleMapsApiKey={mapKey}
          libraries={libraries}
          language="en"
          version="weekly"
          region="us">
          <Map
            center={center}
            paths={LatLng}
            markerPoints={markerPoints}
            point={(paths: any) => updatePortDetail('LatLng', paths)}
            onUpdatePath={(path: any) => updatePortDetail('LatLng', path)} />
          <PortAssignment
            title={Name ? Name : ''}
            points={points}
            paths={LatLng}
            getPointsLoading={getPointsLoading}
            users={AssignedToUserCode ? AssignedToUserCode : []}
            isValidated={isValidated}
            countryDetails={Country ? Country : ''}
            onSubmitPort={() => this.onSubmitPort()}
            onUpdateTitle={(event: any) => this.onUpdateTitle(event)}
            onUpdateUser={(users: any[]) => this.onUpdateUser(users)}
            getPoints={() => this.getPoints()}
            onDeletePoint={(index: number) => this.onDeletePoint(index)}
            onUpdateCountry={(center: any, details: any) => this.onUpdateCountry(center, details)} />
        </LoadScriptComponent>
        <PegModal
          isOpen={isSaving}
          alertModal={true}
          showTemplate={true}
          isCenter={true}>
          <LoaderView>
            <div>Creating Port Area</div>
            <Loader
              size={100}
              color={'blue'}
            />
          </LoaderView>
        </PegModal>
      </Overview>
    )
  }
}

class LoadScriptOnlyIfNeeded extends LoadScript {
  componentDidMount() {
    const cleaningUp = true;
    const isBrowser = typeof document !== "undefined"; // require('@react-google-maps/api/src/utils/isbrowser')
    const isAlreadyLoaded =
      window.google &&
      window.google.maps &&
      document.querySelector("body.first-hit-completed"); // AJAX page loading system is adding this class the first time the app is loaded
    if (!isAlreadyLoaded && isBrowser) {
      // @ts-ignore
      if (window.google && !cleaningUp) {
        return;
      }

      this.isCleaningUp().then(this.injectScript);
    }

    if (isAlreadyLoaded) {
      this.setState({ loaded: true });
    }
  }
}

const LoadScriptComponent: any = LoadScriptOnlyIfNeeded;

export default Port
function params(arg0: string, params: any, arg2: { foo: string; }) {
  throw new Error('Function not implemented.');
}

