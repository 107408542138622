
import {
  Overview,
  Section,
  Label,
  Left,
  Right
} from './edit.styles';
import Dropdown from 'Controls/Dropdown';
import DatePicker from 'Controls/DateRange';

type IProps = {
  onEditServices: (key: any, value: any) => void;
  currencyList?: any[];
  selectedProduct?: any
};

const EditForm = (props: IProps) => {
  const {
    onEditServices,
    currencyList,
    selectedProduct,
  } = props
  return (
    <Overview>
      {/* {selectedProduct?.Name === 'Ship Agency' &&
        <Section>
          <Left>
            <Label>Start date</Label>
            <DatePicker
              id="startDate"
              type={"dateTime"}
              width={200}
              timeFormat={'HH:mm'}
              format={'dd/MM/yyyy HH:mm'}
              onChange={onEditServices} />
          </Left>
          <Right>
            <Label>End date</Label>
            <DatePicker
              id="editDate"
              type={"dateTime"}
              width={200}
              timeFormat={'HH:mm'}
              format={'dd/MM/yyyy HH:mm'}
              onChange={onEditServices} />
          </Right>
        </Section>
      } */}

      <Section>
        <Left>
          <Label>Price currency</Label>
          <Dropdown
            id={"priceCurrency"}
            isLookup={false}
            dropdownLabel={'code'}
            dropdownValue={'code'}
            appendParams={false}
            style={{ width: 199 }}
            placeHolder="Select currency"
            dropdownOptions={currencyList}
            onChangeDropdown={onEditServices}
            isMenuPortalTarget={'true'}
            domId={'stickyPosition'}
          />
        </Left>
        <Right>
          <Label>Cost currency</Label>
          <Dropdown
            id={"costCurrency"}
            isLookup={false}
            dropdownLabel={'code'}
            dropdownValue={'code'}
            appendParams={false}
            style={{ width: 199 }}
            placeHolder="Select currency"
            dropdownOptions={currencyList}
            onChangeDropdown={onEditServices}
            isMenuPortalTarget={'true'}
            domId={'stickyPosition'}
          />
        </Right>
      </Section>
      <Section>
        <Left>
          <Label>Billing currency</Label>
          <Dropdown
            id={"billingCurrency"}
            isLookup={false}
            dropdownLabel={'code'}
            dropdownValue={'code'}
            appendParams={false}
            style={{ width: 199 }}
            placeHolder="Select currency"
            dropdownOptions={currencyList}
            onChangeDropdown={onEditServices}
            isMenuPortalTarget={'true'}
            domId={'stickyPosition'}
          />
        </Left>
        <Right />
      </Section>
    </Overview>
  );
};

export default EditForm;
