export interface IService {
  BillAmount?: any;
  BillingCurrencyCode?: any;
  BillingExchangeRate?: any;
  Code?: any;
  CostCurrencyCode?: any;
  CostExchangeRate?: any;
  CostTaxAmount?: any;
  CostTaxCode?: any;
  CostTaxPercent?: any;
  CostVariance?: any;
  CreatedAt?: any;
  CreatedBy?: any;
  EstimatedCost?: any;
  ExternalRemarks?: any;
  EyeshareCost?: any;
  EyeshareTax?: any;
  Id?: null | number;
  InternalRemarks: any;
  IsCharge?: any;
  IsReadyForBilling?: boolean;
  IsCostDitributed?: any;
  LocalJobId?: any;
  ModifiedAt?: any;
  ModifiedBy?: any;
  Name?: any;
  OperatingIncomeActual?: any;
  ParentServiceFk?: any;
  PaymentTermCode?: any;
  PriceCurrencyCode?: any;
  PriceExchangeRate?: any;
  PriceTaxAmount?: any;
  PriceTaxCode?: any;
  PriceTaxPercent?: any;
  Quantity?: any;
  QuotationNumber?: any;
  SupplierCode?: any;
  SupplierName?: any;
  TotalEstimatedCost?: any;
  TotalEyeshareAmount?: any;
  TotalPriceAmount?: any;
  UnitCost?: any;
  UnitPrice?: any;
  UomCode?: any;
  UomName?: any;
  rowId?: number;
  IsBillable?: boolean;
}
export class Service implements IService {
  BillAmount?: any;

  BillingCurrencyCode?: any;

  BillingExchangeRate?: any;

  Code?: any;

  CostCurrencyCode?: any;

  CostExchangeRate?: any;

  CostTaxAmount?: any;

  CostTaxCode?: any;

  CostTaxPercent?: any;

  CostVariance?: any;

  CreatedAt?: any;

  CreatedBy?: any;

  EstimatedCost?: any;

  ExternalRemarks?: any;

  EyeshareCost?: any;

  EyeshareTax?: any;

  Id?: null | number;

  InternalRemarks: any;

  IsCharge?: any;

  IsCostDitributed?: any;

  LocalJobId?: any;

  ModifiedAt?: any;

  ModifiedBy?: any;

  Name?: any;

  IsReadyForBilling?: boolean;

  OperatingIncomeActual?: any;

  ParentServiceFk?: any;

  PaymentTermCode?: any;

  PriceCurrencyCode?: any;

  PriceExchangeRate?: any;

  PriceTaxAmount?: any;

  PriceTaxCode?: any;

  PriceTaxPercent?: any;

  Quantity?: any;

  QuotationNumber?: any;

  SupplierCode?: any;

  SupplierName?: any;

  TotalEstimatedCost?: any;

  TotalEyeshareAmount?: any;

  TotalPriceAmount?: any;

  UnitCost?: any;

  UnitPrice?: any;

  UomCode?: any;

  UomName?: any;

  rowId?: number;

  IsBillable?: any;

  constructor() {
    this.BillAmount = "";
    this.BillingCurrencyCode = "";
    this.BillingExchangeRate = "";
    this.IsReadyForBilling = false;
    this.Code = "";
    this.CostCurrencyCode = "";
    this.CostExchangeRate = null;
    this.CostTaxAmount = "";
    this.CostTaxCode = "";
    this.CostTaxPercent = "";
    this.CostVariance = "";
    this.CreatedAt = "";
    this.CreatedBy = "";
    this.EstimatedCost = "";
    this.ExternalRemarks = "";
    this.EyeshareCost = "";
    this.EyeshareTax = "";
    this.Id = 0;
    this.InternalRemarks = "";
    this.IsCharge = false;
    this.IsCostDitributed = "";
    this.LocalJobId = null;
    this.ModifiedAt = "";
    this.ModifiedBy = "";
    this.Name = "";
    this.OperatingIncomeActual = "";
    this.ParentServiceFk = "";
    this.PaymentTermCode = "";
    this.PriceCurrencyCode = "";
    this.PriceExchangeRate = null;
    this.PriceTaxAmount = "";
    this.PriceTaxCode = "";
    this.PriceTaxPercent = "";
    this.Quantity = "";
    this.QuotationNumber = "";
    this.SupplierCode = "";
    this.SupplierName = "";
    this.TotalEstimatedCost = "";
    this.TotalEyeshareAmount = "";
    this.TotalPriceAmount = "";
    this.UnitCost = 0;
    this.UnitPrice = "";
    this.UomCode = "";
    this.UomName = "";
    this.rowId = 0;
    this.IsBillable = true;
  }
}