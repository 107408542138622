import React, { useState } from 'react';
import { useSelector } from "react-redux";
import PegButton from 'Controls/Button/PegButton';
import { Link } from 'react-router-dom';
import PegInputBox from 'Controls/Input/PegInputBox';
import { IQuotationList } from 'Model/Order/QuotationList';
import Loader from 'Components/Loader';
import { LoaderOverview } from 'Containers/JobGrid/Jobs.styles';


const QuotationList = () => {
    const pages = [];
    const quotationListData = useSelector((state: any) => state.quotationList);
    const { quotationList, isLoading } = quotationListData;
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(9);
    const [pageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);

    const handleClick = (event: any) => {
        setCurrentPage(Number(event.target.innerText))
    }

    for (let i = 1; i <= Math.ceil(quotationList?.length / itemsPerPage); i++) {
        pages.push(i)
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = quotationList?.slice(indexOfFirstItem, indexOfLastItem);
    const renderPageNumbers = pages.map((number: any, i: any) => {
        if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
            return (
                <li key={i} id={number} onClick={handleClick}>
                    <button className={currentPage === number ?
                        `h-10 px-5 text-white transition-colors duration-150 bg-primary border border-r-0 border-primary focus:shadow-outline` :
                        `h-10 px-5 text-primary transition-colors duration-150 bg-white border border-r-0 border-primary focus:shadow-outline`}>
                        {number}
                    </button>
                </li>
            )
        } else {
            return null;
        }

    })

    const handleNext = () => {
        setCurrentPage(currentPage + 1);
        if (currentPage + 1 > maxPageNumberLimit) {
            setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    }

    const handlePrev = () => {
        setCurrentPage(currentPage - 1);
        if ((currentPage - 1) % pageNumberLimit === 0) {
            setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
    }

    let pageIncrementBtn = null;
    if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn =
            <li onClick={handleNext}
                className="h-10 px-5 text-primary transition-colors duration-150 bg-white border border-r-0 border-primary focus:shadow-outline">
                &hellip;
            </li>
    }

    let pageDecrementBtn = null;
    if (minPageNumberLimit >= 1) {
        pageDecrementBtn =
            <li onClick={handlePrev}
                className="h-10 px-5 text-primary transition-colors duration-150 bg-white border border-r-0 border-primary focus:shadow-outline">
                &hellip;
            </li>
    }

    if (isLoading) {
        return (
            <LoaderOverview>
                <Loader gridLoader={true} color={'#001F58'} />
            </LoaderOverview>
        );
    }
    return (
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">

            <div className={"flex flex-row mb-4 justify-between"}>
                <div className={"flex flex-row gap-4"}>
                    <Link to={`/quotation/create`}>
                        <PegButton id="btnQtn" key="qte" type="submit" variant="Primary" size="sm">
                            New Quote
                        </PegButton>
                    </Link>
                    <Link to={`/quotation/create`}>
                        <PegButton id="btnQtn" key="qte" type="submit" variant="OutlinePrimary" styleObject={{ border: '1px solid #001F58' }} size="sm">
                            New Appointment
                        </PegButton>
                    </Link>
                </div>
                <div className="w-2/12">
                    <PegInputBox id="txtSearch" placeholder="Search Quotation" onChange={undefined} />
                </div>
            </div>

            <div className="flex flex-col">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-light sm:rounded-lg">
                            <table className="min-w-full divide-y divide-light">
                                <thead className="bg-white border-b-2">
                                    <tr>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-dark uppercase tracking-wider">
                                            Quotation No
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-dark uppercase tracking-wider">
                                            Customer Name
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-dark uppercase tracking-wider">
                                            Status
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-dark uppercase tracking-wider">
                                            Vessel Name
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-dark uppercase tracking-wider">
                                            Port
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-dark uppercase tracking-wider">
                                            ETA
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-dark uppercase tracking-wider">
                                            Quoted Date
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-light">
                                    {currentItems?.map((quote: IQuotationList) => (
                                        <tr key={quote.QuotationId} className={'group'}>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <Link
                                                    to={quote.QuotationStatus === "Draft" ? `/quotation/create` : `overview/${quote.QuotationId}`}
                                                >
                                                    <div className="text-sm text-left hover:font-semibold transition ease-in-out duration-300; text-primary">
                                                        {
                                                            quote.QuotationNumber
                                                        }
                                                    </div>
                                                </Link>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-xs text-gray-900">{quote.CustomerName}</div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-xs text-gray-900">{quote.QuotationStatus}</div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-xs text-gray-900">{quote.VesselName}</div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-xs text-gray-900">{quote.PortName}</div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-xs text-gray-900">{quote.ETA.toString()}</div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-xs text-gray-900">{quote.QuotationDate.toString()}</div>
                                            </td>
                                        </tr>
                                    ))

                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {
                quotationList &&
                <nav aria-label="Page navigation" className="flex flex-row justify-between shadow overflow-hidden bg-white border-b border-light sm:rounded-lg -my-1">
                    <ul className="flex flex-row justify-start">
                        <li>
                            <button
                                className="h-10 px-5 text-primary transition-colors duration-150 bg-white border border-r-0 border-primary rounded-l-lg focus:shadow-outline hover:text-primary"
                                onClick={handlePrev}
                                disabled={currentPage === pages[0] ? true : false}>
                                Prev
                            </button>
                        </li>
                        {pageDecrementBtn}
                        {renderPageNumbers}
                        {pageIncrementBtn}
                        <li>
                            <button
                                className="h-10 px-5 text-primary transition-colors duration-150 bg-white border border-primary rounded-r-lg focus:shadow-outline hover:text-primary"
                                onClick={handleNext}
                                disabled={currentPage === pages[pages.length - 1] ? true : false}>
                                Next
                            </button>
                        </li>
                    </ul>
                    <ul className="flex flex-row justify-end mr-8 mt-2">
                        <span>
                            Page {currentPage} of {quotationList ? Math.ceil(quotationList?.length / itemsPerPage) : currentPage}
                        </span>
                    </ul>
                </nav>
            }

        </div >
    );
};

export default QuotationList;


