import CargoDetails from './CargoDetails';
import { connect } from 'react-redux';
import {
  updateCargoDetails,
  addPackageSection,
  deletePackageSection,
  addContainerSection,
  deleteContainerSection,
  addBulkSection,
  deleteBulkSection
} from 'Redux/Actions/cargo'

const mapDispatchToProps = (dispatch: any) => ({
  updateCargoDetails: (cargoDetails: any) => dispatch(updateCargoDetails(cargoDetails)),
  addPackageSection: (cargoArray: any) => dispatch(addPackageSection(cargoArray)),
  deletePackageSection: (packageArray: any) => dispatch(deletePackageSection(packageArray)),
  addBulkSection: (bulkArray: any) => dispatch(addBulkSection(bulkArray)),
  deleteBulkSection: (bulkArray: any) => dispatch(deleteBulkSection(bulkArray)),
  addContainerSection: (containerArray: any) => dispatch(addContainerSection(containerArray)),
  deleteContainerSection: (containerArray: any) => dispatch(deleteContainerSection(containerArray))
});

const mapStateToProps = (state: any) => ({
  cargoDetails: state.cargo.cargoDetails,
  cargoInfoValidation: state.cargo.cargoInfoValidation,
  bulkDetailsValidation: state.cargo.bulkDetailsValidation,
  containerValidation: state.cargo.containerValidation,
  packageDetailsValidation: state.cargo.packageDetailsValidation
});

export default connect(mapStateToProps, mapDispatchToProps)(CargoDetails);