import { RiCloseFill } from 'react-icons/ri';
import PegInput from 'Controls/Input/PegInputBox';
import Dropdown from 'Controls/Dropdown';
import {
  DimensionUOM,
  WeightUOM,
  VolumeUOM
} from 'Constants/DropdownOptions';
import { useCallback } from 'react';
import { Package } from 'Model/Order/Package';
import {
  Flex,
  Id,
  Quantity,
  GrossWeight,
  Weight,
  Length,
  Width,
  Height,
  Dimensions,
  Volume,
  GrossVolume,
  DeleteIcon
} from '../../cargo.styles'

interface Prop {
  index: number;
  key: number;
  packages?: any;
  packageDetail: Package,
  onDelete: (id: number) => void;
  onUpdate: (key: string, event: any, index: number) => void;
}

const CargoLine = (props: any) => {

  const {
    index,
    onUpdate,
    packages,
    onDelete,
  } = props;

  const onChange = useCallback((event: any, key: string) => {
    onUpdate(key, event, index)
  }, [packages]);

  return (
    <Flex id="packageList">
      <DeleteIcon>
        <button>
          <RiCloseFill
            size="1.2em"
            className="fill-current text-danger group-hover:text-danger-shade"
            onClick={() => onDelete(index)} />
        </button>
      </DeleteIcon>
      <Id className={'col-span-1'}>
        <h4 className="text-sm font-semibold text-dark w-1.5">{index + 1}</h4>
      </Id>
      <Quantity>
        <PegInput
          id={'txtCargoQuantity'}
          type={'number'}
          defaultValue={packages?.Quantity}
          name={'cargoQuantity'}
          isMandatory={true}
          onChange={(event: any) => onChange(event, 'quantity')} />
      </Quantity>
      <GrossWeight>
        <PegInput
          id={'txtGrossWeight'}
          type={'number'}
          defaultValue={packages?.GrossWeight}
          name={'GrossWeight'}
          isMandatory={true}
          onChange={(event: any) => onChange(event, 'grossWeight')}
        />
      </GrossWeight>
      <Weight>
        <Dropdown
          isMandatory={true}
          id={'drpVolumnUom'}
          isLookup={false}
          defaultValue={packages?.WeightUomName}
          dropdownLabel={'name'}
          dropdownValue={'name'}
          appendParams={false}
          domId={"packageList"}
          isMenuPortalTarget={'true'}
          dropdownOptions={WeightUOM}
          placeholder={'Select weight'}
          onChangeDropdown={(event: any) => onChange(event, 'wUOM')}
        />
      </Weight>
      <Length>
        <PegInput
          id={'txtLength'}
          type={'number'}
          name={'length'}
          defaultValue={packages?.Length}
          onChange={(event: any) => onChange(event, 'length')} />
      </Length>
      <Width>
        <PegInput
          id={'txtWidth'}
          type={'number'}
          name={'width'}
          defaultValue={packages?.Width}
          onChange={(event: any) => onChange(event, 'width')} />
      </Width>
      <Height>
        <PegInput
          id={'txtHeight'}
          type={'number'}
          name={'height'}
          defaultValue={packages?.Height}
          onChange={(event: any) => onChange(event, 'height')} />
      </Height>
      <Dimensions>
        <Dropdown
          id={'uom'}
          isLookup={false}
          dropdownLabel={'name'}
          dropdownValue={'name'}
          appendParams={false}
          domId={"packageList"}
          defaultValue={packages?.DimensionUomName}
          isMenuPortalTarget={'true'}
          dropdownOptions={DimensionUOM}
          placeholder={'Select Unit of Measures'}
          onChangeDropdown={(event: any) => onChange(event, 'dUOM')}
        />
      </Dimensions>
      <Volume>
        <Dropdown
          id={'drpVolumnUom'}
          isLookup={false}
          dropdownLabel={'name'}
          dropdownValue={'name'}
          appendParams={false}
          defaultValue={packages?.VolumeUomName}
          domId={"packageList"}
          isMenuPortalTarget={'true'}
          dropdownOptions={VolumeUOM}
          placeholder={'Select volume'}
          onChangeDropdown={(event: any) => onChange(event, 'vUOM')}
        />
      </Volume>
      <GrossVolume>
        <PegInput
          id={'txtGrossVolumne'}
          type={'number'}
          defaultValue={packages?.GrossVolume}
          name={'GrossVolumne'}
          onChange={(event: any) => onChange(event, 'gVolume')}
        />
      </GrossVolume>
    </Flex>
  );
};

export default CargoLine;
