import { request } from 'http';
import { Quotation } from '../Model/Order/Quotation';
import { IQuotationList } from '../Model/Order/QuotationList';
import { requests, pagination } from './Agent';
import { bankInfo } from '../Constants/DropdownOptions';

const ReferenceAgent = {
  customer: (filter: string) => requests.get(`/get-customer?search-text=${filter}`),
  customerDetail: (code: string) => requests.get(`/mdm-get-customer?code=${code}`),
  bankInfo: (code: string) => requests.get(`/oum-get-bank-account?office-code=${code}`),
  country: (filter: string) => requests.get(`/mdm-country?searchText=${filter}`),
  vessel: (filter: string) => requests.get(`/mdm-country?searchText=${filter}`),
  vesselDetail: (code: string) => requests.get(`/mdm-get-vessel?code=${code}`),
  vesselList: () => requests.get(`/getVesselCallList`),
  vesselDetailsByChild: (code: string) => requests.get(`/getVesselDetailsByVesselCallId?vesselCallId=${code}`),
  vesselCallById: (code: string) => requests.get(`/vesselCallDetailsById?vesselCallId=${code}`),
  industryVertical: (code: string) => requests.get(`/mdm-call-purpose-selection?industryVerticalCode=${code}`),
  uom: (filter: string) => requests.get(`/mdm-country?searchText=${filter}`),
  location: (filter: string) => requests.get(`/mdm-country?searchText=${filter}`),
  port: (filter: string) => requests.get(`/mdm-country?searchText=${filter}`),
  terminal: (filter: string) => requests.get(`/mdm-country?searchText=${filter}`),
  agent: (filter: string) => requests.get(`/mdm-country?searchText=${filter}`),
};

export default ReferenceAgent;
