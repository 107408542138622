import { useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import QuotationType from './Components/QuotationsType';
import {
  Overview,
  Content,
  AlignSelf
} from './styles';
import WizardHeader from 'Components/WizardHeader/WizardHeader';
import { QuotationArea } from 'Model/Order/QuotationArea';
import { BUSINESS_TYPES } from 'Constants/Constant';
import { setBusinessType } from 'Redux/Actions/Quotation';


type MyState = {
  QuotationArea: QuotationArea[];
};

const BusinessSelection = () => {

  let selectedBusiness = useSelector((state: any) => state.quotation.selectedBusiness);
  let dispatch = useDispatch();

  const selectType = useCallback((item: any) => {
    const index = selectedBusiness.findIndex((x: any) => x.AreaType === item.type);
    if (index === -1) {
      selectedBusiness.push({
        AreaType: item.type,
        AreaName: item.type,
        AreaCode: item.type,
      })
    } else {
      selectedBusiness.splice(index, 1);
    }
    dispatch(setBusinessType([...selectedBusiness]))
  }, [])

  return (
    <Overview className="">
      <AlignSelf className={"flex flex-col"}>
        <WizardHeader
          title={"New Quotation"}
          description={"Please select the service you wish to continue"}
          centerAlign={true}
        />
        <Content>
          {
            BUSINESS_TYPES.map((item, index) => {
              return (
                <QuotationType
                  key={item.type + index}
                  onSelect={() => selectType(item)}
                  icon={item.icon}
                  title={item.type}
                  type={item.type}
                  isSelected={selectedBusiness?.map((i: any) => i.AreaType).includes(item.type)}
                  description={item.description} />
              )
            })
          }
        </Content>
      </AlignSelf>
    </Overview>
  );
};

export default BusinessSelection
