
import {
  HeaderOverview,
  Version,
  PriceSummary,
  Party,
  BillingAddress,
  TotalNetPrice,
  TotalRequestedPrice,
  FundsReceived,
  RequestedAmount,
  RequestedPerc,
  BankAccount,
  Id
} from '../price.syles';

type Props = {

};

const Header = (props: Props) => {
  return (
    <HeaderOverview>
      <Id isHeader />
      <Version isHeader>Version</Version>
      <PriceSummary isHeader>Price summary</PriceSummary>
      <Party isHeader>Party</Party>
      <BillingAddress isHeader>Billing address</BillingAddress>
      <TotalNetPrice isHeader>Total net price</TotalNetPrice>
      <TotalRequestedPrice isHeader>Total requested amount</TotalRequestedPrice>
      <FundsReceived isHeader>Funds received</FundsReceived>
      <RequestedAmount isHeader>Requested amount</RequestedAmount>
      <RequestedPerc isHeader>% requested amount</RequestedPerc>
      <BankAccount isHeader>Bank account</BankAccount>
    </HeaderOverview>
  )
};

export default Header;
