import React from 'react';
// import customed components here
import WizardHeader from 'Components/WizardHeader/WizardHeader';
import Dropdown from 'Controls/Dropdown';
import PegCheckbox from 'Controls/Checkbox/PegCheckbox';
// import style files here
import './RouteDetails.scss';
import addRowIcon from '../../Assets/add-new.svg';
import deleteRowIcon from '../../Assets/delete_icon.svg';
import downArrowIcon from '../../Assets/arrow-down.svg';

// import model and constant files here
import { IFreightDetail } from 'Model/Order/FreightDetails';
import { IRoute } from 'Model/Order/Route';
import { MODEOF_TRANSFER_LIST } from 'Constants/Constant';

type IState = {
  routeDetails: any
};

type IProps = {
  freightDetails: IFreightDetail,
  routeDetails: any[],
  updateRouteDetails: (routeDetails: any[]) => void;
};

class RouteDetails extends React.Component<IProps, IState> {
  private routeDetailsList: any;

  constructor(props: any) {
    super(props);
    this.state = { routeDetails: this.props.routeDetails };
  }

  /**
    * @method componentDidMount
    * @param none
    * @desc used to load the default data from the server for service grid
    * @returns none
    */
  componentDidMount(): void {
    this.addRouteOptionInitialDetails();
  }

  /**
   * @method addRouteOptionInitialDetails
   * @param selectedItem<string>, parentIndex<number>, childIndex<number>
   * @desc update the store with mode of transfer details
   * @returns none 
   */
  addRouteOptionInitialDetails(): void {
    this.routeDetailsList = [];
    this.routeDetailsList.push([{ DestinationName: this.props.freightDetails.DestName, DestinationCode: this.props.freightDetails.DestCode } as IRoute] as IRoute[]);
    this.setState({ routeDetails: this.routeDetailsList }, () => {
      this.props.updateRouteDetails(this.state.routeDetails);
    });
  }

  /**
   * @method handleMOTSelection
   * @param selectedItem<string>, parentIndex<number>, childIndex<number>
   * @desc update the store with mode of transfer details
   * @returns none 
   */
  handleMOTSelection(selectedItem: string, parentIndex: number, childIndex: number): void {
    this.routeDetailsList[parentIndex][childIndex].MoTCode = selectedItem;
    this.routeDetailsList[parentIndex][childIndex].MoTName = selectedItem;
    this.setState({ routeDetails: this.routeDetailsList }, () => {
      this.props.updateRouteDetails(this.state.routeDetails);
    });
  }

  /**
   * @method handleInputChange
   * @param $event<HtmlInputEvent>, elementName<string>, elementCode<string>, parentIndex<number>, childIndex<number>
   * @desc used for updating input value changes with store
   * @returns none 
   */
  handleInputChange($event: any, elementName: string, elementCode: string, parentIndex: number, childIndex: number): void {
    this.routeDetailsList[parentIndex][childIndex][elementName] = $event.name;
    this.routeDetailsList[parentIndex][childIndex][elementCode] = $event.code;
    this.setState({ routeDetails: this.routeDetailsList }, () => {
      this.props.updateRouteDetails(this.state.routeDetails);
    });
  }

  /**
   * @method handleCheckboxChanges
   * @param $event<any>, parentIndex<number>, childIndex<number>
   * @desc update store with isStoreRequired property
   * @returns none 
   */
  handleCheckboxChanges($event: any, parentIndex: number, childIndex: number): void {
    this.routeDetailsList[parentIndex][childIndex].IsStorageRequired = $event.checked;
    this.setState({ routeDetails: this.routeDetailsList }, () => {
      this.props.updateRouteDetails(this.state.routeDetails);
    });
  }

  /**
   * @method addRouteOption
   * @param none
   * @desc It will add the new route option to routes list
   * @returns none 
   */
  addRouteOption(): void {
    this.routeDetailsList.push([{} as IRoute] as IRoute[]);
    this.setState({ routeDetails: this.routeDetailsList }, () => {
      this.props.updateRouteDetails(this.state.routeDetails);
    });
  }

  /**
   * @method addRoute
   * @param parentIndex<number>
   * @desc It will add the new row to routes list
   * @returns none 
   */
  addRoute(parentIndex: number): void {
    this.routeDetailsList[parentIndex].push([{} as IRoute] as IRoute[]);
    this.setState({ routeDetails: this.routeDetailsList }, () => {
      this.props.updateRouteDetails(this.state.routeDetails);
    });
  }

  /**
   * @method deleteRouteOption
   * @param isParentRoute<boolean>, parentIndex<number>, childIndex<number>
   * @desc It's used to delete the option from routes list
   * @returns none 
   */
  deleteRouteOption(isParentRoute: boolean, parentIndex: number, childIndex?: number): void {
    if (isParentRoute) {
      this.routeDetailsList.splice(parentIndex, 1);
    } else {
      this.routeDetailsList[parentIndex].splice(childIndex, 1);
    }
    this.setState({ routeDetails: this.routeDetailsList }, () => {
      this.props.updateRouteDetails(this.state.routeDetails);
    });
  }

  /**
   * @method toggleContent
   * @param parentRowClass<string>, toggleBtnClass<string>
   * @desc It's used to toggle the routes list content
   * @returns none 
   */
  toggleContent(parentRowClass: string, toggleBtnClass: string): void {
    const SELECTED_ROUTE_ELEMENT: HTMLElement | null = document.querySelector(`.${parentRowClass}`);
    const ARROW_ELEMENT: HTMLElement | null = document.querySelector(`.${toggleBtnClass}`);
    // toggle the routes list content
    if (SELECTED_ROUTE_ELEMENT && SELECTED_ROUTE_ELEMENT.classList && SELECTED_ROUTE_ELEMENT.classList.contains('d-none')) {
      SELECTED_ROUTE_ELEMENT.classList.remove('d-none');
    } else if (SELECTED_ROUTE_ELEMENT && SELECTED_ROUTE_ELEMENT.classList && !SELECTED_ROUTE_ELEMENT.classList.contains('d-none')) {
      SELECTED_ROUTE_ELEMENT.classList.add('d-none');
    }
    // used to toggle the arrow class names
    if (ARROW_ELEMENT && ARROW_ELEMENT.classList && ARROW_ELEMENT.classList.contains('up-arrow')) {
      ARROW_ELEMENT.classList.remove('up-arrow');
    } else if (ARROW_ELEMENT && ARROW_ELEMENT.classList && ARROW_ELEMENT.classList) {
      ARROW_ELEMENT.classList.add('up-arrow');
    }
  }

  render() {
    return (
      <div className="grid grid-cols-1 p-12" >
        <div className={'col-span-1 flex-col mb-6'}>
          <WizardHeader title={"Route Information"} description={"Enter possible directions and destination"} />
        </div>
        <div className="col-span-12 flex justify-end">
          <button className="flex items-center" onClick={() => this.addRouteOption()}> <img className="mr-2" src={addRowIcon} alt="add_icon" /> Add another option</button>
        </div>
        {this.state.routeDetails.map((parentItem: any[], parentIndex: number) =>
          <div className="col-span-12 row-wrapper p-4 mt-4" key={parentIndex}>
            <div className="grid grid-cols-12 mb-4">
              <div className={`toggle-btn items-center font-bold col-span-6 flex toggle-btn${parentIndex}`} onClick={() => this.toggleContent(`route-wrapper${parentIndex}`, `toggle-btn${parentIndex}`)}>
                <img className="mr-2" src={downArrowIcon} alt="toggle_icon" />
                ROOT OPTION {parentIndex + 1}
              </div>
              {/* delete complete routes list button*/}
              {this.state.routeDetails && this.state.routeDetails.length > 1 && <div className="col-span-6 flex justify-end">
                <button className="flex items-center delete-btn" onClick={() => this.deleteRouteOption(true, parentIndex)}><img className="mr-2" src={deleteRowIcon} alt="delete_icon" />Remove</button>
              </div>}
            </div>
            <div className={`route-wrapper${parentIndex} route-wrapper grid grid-cols-12`}>
              {parentItem && parentItem.map((childItem: IRoute, childIndex: number) =>
                <div className={`col-span-9 option-wrapper flex-col ${childIndex > 0 ? 'child-row-border-top pt-4 mt-4 mr-4' : ''}`} key={childIndex}>
                  {/* mode of transfer buttons list*/}
                  <div className={'grid grid-cols-12 flex mb-6 items-center'}>
                    <label className="text-sm font-semibold text-dark col-span-2 mr-10">Mode of Transport</label>
                    <div className={'col-span-10 flex btn-wrapper space-x-6'}>
                      {MODEOF_TRANSFER_LIST.map((item: string, btnIndex: number) =>
                        <button onClick={() => this.handleMOTSelection(item, parentIndex, childIndex)} className={`btn btn-md btn-primary ${childItem.MoTCode === item ? '' : 'btn-outline'}`} key={btnIndex}>{item}</button>)}
                    </div>
                  </div>
                  <div className="grid grid-cols-12 mb-3 gap-4 justify-center">
                    <div className={'col-span-4 flex flex-col space-y-2'}>
                      <label className="text-sm font-semibold text-dark">Origin</label>
                    </div>
                    <div className={'col-span-4 flex flex-col space-y-2'}>
                      <label className="text-sm font-semibold text-dark">Destination</label>
                    </div>

                    <div className={'col-span-2 flex flex-col space-y-2'}>
                      <label className="text-sm font-semibold text-dark self-center">Storage Required</label>
                    </div>
                  </div>
                  <div className="grid grid-cols-12 mb-2 gap-4">
                    <div className={'col-span-4 flex flex-col space-y-2'}>
                      <Dropdown
                        id={'origin'}
                        type={'text'}
                        defaultValue={childItem?.DestinationName}
                        placeHolder={'Select Origin'}
                        isLookup={true}
                        dropdownLabel={'name'}
                        dropdownValue={'name'}
                        appendParams={false}
                        getFormattedLabel={true}
                        url={`/mdm-search-customer?searchText=`}
                        onChangeDropdown={(event: any) => this.handleInputChange(event, 'OriginName', 'OriginCode', parentIndex, childIndex)} />
                    </div>
                    <div className={'col-span-4 flex flex-col space-y-2'}>
                      <Dropdown
                        id={'origin'}
                        type={'text'}
                        defaultValue={childItem?.DestinationName}
                        placeHolder={'Select Destination'}
                        isLookup={true}
                        dropdownLabel={'name'}
                        dropdownValue={'name'}
                        appendParams={false}
                        getFormattedLabel={true}
                        url={`/mdm-search-customer?searchText=`}
                        onChangeDropdown={(event: any) => this.handleInputChange(event, 'DestinationName', 'DestinationCode', parentIndex, childIndex)} />
                    </div>
                    <div className={'col-span-2 flex flex-col space-y-2'}>
                      <div className={'self-center'}>
                        <PegCheckbox id={`chkStorageRequired${parentIndex}${childIndex}`} name={'storageRequired'} label={''} onChange={(event: any) => this.handleCheckboxChanges(event, parentIndex, childIndex)} />
                      </div>
                    </div>
                    {/* delete routes option button */}
                    {parentItem && parentItem.length > 1 && <div className={'col-span-2 flex flex-col space-y-2'}>
                      <button className="flex items-center delete-btn" onClick={() => this.deleteRouteOption(false, parentIndex, childIndex)}> <img className="mr-2" src={deleteRowIcon} alt="delete_icon" /></button>
                    </div>}
                  </div>
                </div>)}
              <div className="col-span-3 google-map-wrapper" />

              {/* add option to routes list button */}
              <div className="col-span-12 mt-3 justify-end">
                <button className="flex items-center" onClick={() => this.addRoute(parentIndex)}> <img className="mr-2" src={addRowIcon} alt="add_icon" /> Add new route</button>
              </div>
            </div>
          </div>)}
      </div>
    );
  }
}

export default RouteDetails
