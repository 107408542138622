import { combineReducers } from 'redux'
import { services } from './services'
import { quotation } from './Quotation'
import { quoteServices } from './QuoteServices'
import { customer } from './customer';
import { vessel } from './Vessel';
import { cargo } from './cargo';
import { freight } from './FreightReducer';
import { route } from './RouteReducer';
import { crew } from './Crew';
import { JobServices } from './JobGrid';
import { ServiceTemplates } from './ServiceTemplates';
import { QuoteGrid } from './QuoteGrid';
import { user } from './user';
import { quotationList } from './quotationList';
import { port } from './port';

export default combineReducers({
  services,
  user,
  customer,
  vessel,
  quotation,
  quoteServices,
  cargo,
  freight,
  route,
  crew,
  JobServices,
  ServiceTemplates,
  quotationList,
  QuoteGrid,
  port
})