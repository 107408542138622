
import { Overview } from '../services.styles';
import Service from './Services/Service';
import Charge from './Charges/Charge';
import { sheetHeader } from '../../../Constants/Constant';

export default function ServiceTable(props: any) {
  const {
    service,
    onExpandService,
    deleteCharge,
    deleteService,
    onAddCharge,
    onChangeInput,
    onChangeDropdown,
    onChangeDate,
    selectedList,
    isAllSelected,
    onSelectOptions
  } = props;
  return (
    <div>
      <Overview>
        {sheetHeader.map((item: any, index: number) => {
          let Tag = item.component;
          return (
            <Tag key={`${item.id}services`} className="text-sm font-medium">
              <Service
                onExpandService={onExpandService}
                onAddCharge={onAddCharge}
                deleteService={deleteService}
                data={item}
                onChangeInput={onChangeInput}
                onChangeDate={onChangeDate}
                onChangeDropdown={onChangeDropdown}
                service={service}
                type={'service'}
                isAllSelected={isAllSelected}
                isSelected={selectedList.some((child: any) => child.ServiceDetailId === service.ServiceDetailId && child.isSelected)}
                selectedList={selectedList}
                onSelectOptions={onSelectOptions}
              />
            </Tag>
          );
        })}
      </Overview>
      {service.charges && service.charges.length && service.showCharges ? (
        <div>
          {service.charges.map((charge: any, index: number) => {
            let selectedService = selectedList.find((child: any) => child.ServiceDetailId === service.ServiceDetailId)
            let isChargeSelected = selectedService && selectedService.charges && selectedService.charges.length &&
              selectedService.charges.some((item: any) => item.ServiceDetailId === charge.ServiceDetailId)
            return (
              <Overview key={index}>
                {sheetHeader.map((header: any) => {
                  let Tag = header.component;
                  return (
                    <Tag key={`${header.id}charges`}>
                      <Charge
                        data={header}
                        service={service}
                        charge={charge}
                        type={'charge'}
                        deleteCharge={deleteCharge}
                        onChangeInput={onChangeInput}
                        onChangeDate={onChangeDate}
                        onChangeDropdown={onChangeDropdown}
                        isAllSelected={isAllSelected}
                        isServiceSelected={selectedList.some((child: any) => (child.ServiceDetailId === service.ServiceDetailId && child.isSelected))}
                        isChargeSelected={isChargeSelected}
                        selectedList={selectedList}
                        onSelectOptions={onSelectOptions}
                      />
                    </Tag>
                  );
                })}
              </Overview>
            );
          })}
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}
