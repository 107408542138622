import ServiceVisibilityGrid from './ServiceVisibilityGrid'
import { connect } from 'react-redux';

const mapDispatchToProps = (dispatch: any) => ({

});

const mapStateToProps = (state: any) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceVisibilityGrid);