import VesselDetail from "./VesselDetail";
import { connect } from 'react-redux';
import { updateVesselAdditionalDetails, updateVesselDetails, linkVesselCall, resetVesselState, createVoyageRow, linkOptionDetails, enableCargoTab, enableCrewTab, enableShipSpares } from "Redux/Actions/Vessel";
import { updateQuoteServices } from "Redux/Actions/QuoteServices";


const mapDispatchToProps = (dispatch: any) => ({
  updateVesselDetails: (event: any, key: string) => dispatch(updateVesselDetails(event, key)),
  updateQuoteServices: (event: any, key: string) => dispatch(updateQuoteServices(event, key)),
  enableCargoTab: (value: boolean) => dispatch(enableCargoTab(value)),
  enableCrewTab: (value: boolean) => dispatch(enableCrewTab(value)),
  enableShipSpares: (value: boolean) => dispatch(enableShipSpares(value)),
  updateVesselAdditionalDetails: (data: any) => dispatch(updateVesselAdditionalDetails(data)),
  linkVesselCall: (data: any) => dispatch(linkVesselCall(data)),
  linkOptionDetails: (data: any) => dispatch(linkOptionDetails(data)),
  resetVesselState: () => dispatch(resetVesselState()),
  createVoyageRow: () => dispatch(createVoyageRow()),
});

const mapStateToProps = (state: any) => ({
  optionsValidation: state.vessel.optionsValidation,
  vesselDetails: state.vessel.vesselDetails,
  vesselAdditionalDetails: state.vessel.vesselAdditionalDetails,
});

export default connect(mapStateToProps, mapDispatchToProps)(VesselDetail);