import axios from 'axios';
import { useState, useEffect } from 'react';
import Charge from './Charge';
import Service from './Service';
interface Prop {
  userDetails: any;
  selectedList: any[];
  uomList: any[];
  currencyList: any[];
  taxList: any[];
  serviceList: any[];
  exchangeRates: any[];
  opTags: any[];
  onAddCharge: (index: number) => void;
  onDeleteService: (index: number) => void;
  onUpdateOpTags: (product: any, opTags: any) => void;
  onDeleteCharge: (servIndex: number, chargeIndex: number) => void;
  onUpdateService: (servIndex: number, servObj: any, key: string, value: any) => void;
  onUpdateCharge: (servIndex: number, chargeIndex: number, servObj: any, key: string, value: any) => void;
  onUpdateTemplateCharge: (servIndex: number, charIndex: number, chargeObj: any) => void;
  onUpdateTemplateService: (servIndex: number, serviceObj: any) => void;
  service: any;
  charges: any;
  serviceIndex: number;
  disableCost: boolean;
  disableOperatingIncome: boolean;
  disableRevenue: boolean;
  isAllSelected: boolean;
  selectedProduct: any;
  onSelectServices: (index: number) => void;
  isShowCostQty: boolean
}

const List = (props: Prop) => {
  const {
    selectedList,
    onAddCharge,
    onDeleteService,
    onDeleteCharge,
    disableRevenue,
    disableOperatingIncome,
    isAllSelected,
    disableCost,
    serviceList,
    service,
    currencyList,
    onSelectServices,
    taxList,
    exchangeRates,
    uomList,
    selectedProduct,
    serviceIndex,
    charges,
    onUpdateService,
    onUpdateCharge,
    opTags,
    onUpdateOpTags,
    userDetails,
    isShowCostQty
  } = props
  const [chargeList, setChargeList] = useState([]);
  const [showCharges, setShowCharge] = useState(true);
  const companyCode = new URLSearchParams(window.location.search).get("companycode");

  useEffect(() => {
    // const asyncFn = async () => {
    if (service?.Code) {
      let tags = opTags.filter((item: any) => item.BpCode === service?.Code)
      onUpdateOpTags(selectedProduct, tags)
      axios.get(`/getCharges?company-code=${companyCode}&service-code=${service?.Code}&includeDolphinMapping=true`).then((response) => {
        setChargeList(response.data)
      })
    }
    // }
    // asyncFn()
  }, [service?.Code])

  const onExpandService = () => {
    setShowCharge(!showCharges)
  }
  return (
    <>
      <Service
        userDetails={userDetails}
        disableRevenue={disableRevenue}
        disableCost={disableCost}
        disableOperatingIncome={disableOperatingIncome}
        isAllSelected={isAllSelected}
        onExpandService={() => onExpandService()}
        onAddCharge={(index: number) => onAddCharge(index)}
        onDeleteService={(index: number) => onDeleteService(index)}
        onUpdateService={(servIndex: number, servObj: any, key: string, value: any) =>
          onUpdateService(servIndex, servObj, key, value)}
        onUpdateTemplateService={(servIndex: number, serviceObj: any) =>
          props.onUpdateTemplateService(servIndex, serviceObj)}
        showCharges={showCharges}
        service={service}
        serviceIndex={serviceIndex}
        selectedProduct={selectedProduct}
        serviceList={serviceList}
        currencyList={currencyList}
        onSelectServices={onSelectServices}
        selectedList={selectedList}
        exchangeRates={exchangeRates}
        isShowCostQty={isShowCostQty}
      />
      {
        showCharges && charges?.map((charge: any, index: number) => {
          return (
            <Charge
              key={'charge-' + index}
              userDetails={userDetails}
              charge={charge}
              service={service}
              chargeIndex={index}
              serviceIndex={serviceIndex}
              disableRevenue={disableRevenue}
              disableCost={disableCost}
              disableOperatingIncome={disableOperatingIncome}
              onDeleteCharge={(servIndex: number, chargeIndex: number) =>
                onDeleteCharge(servIndex, chargeIndex)}
              onUpdateCharge={(servIndex: number, chargeIndex: number, chargeObj: any, key: string, value: any) =>
                onUpdateCharge(servIndex, chargeIndex, chargeObj, key, value)}
              onUpdateTemplateCharge={(servIndex: number, charIndex: number, chargeObj: any) =>
                props.onUpdateTemplateCharge(servIndex, charIndex, chargeObj)}
              selectedProduct={selectedProduct}
              chargeList={chargeList}
              uomList={uomList}
              taxList={taxList}
              isShowCostQty={isShowCostQty}
            />
          )
        }
        )
      }
    </>
  );
};

export default List;