import axios from "axios";
import { BILLING_STATUS_OPTIONS_LIST, VoucherType } from "Constants/Constant";
import { VOUCHER_TYPE_OPTIONS } from "Constants/DropdownOptions";
import _ from "lodash";
import { Charge, ICharge } from "Model/Job/JobCharge";
import { IService, Service } from "Model/Job/JobService";
import { IJobDetails, IServicesAndCharges, IBulkEditList, IBulkEditSR } from "Model/Job/types";
import { ExchangeRateLookupEntity } from "Model/Master/ExchangeRateLookupEntity";

import moment from "moment";
import {
  formatAddress,
  getExchangeRates
} from 'Utils/Generic';

export const onBulkEdit = (serviceList: IServicesAndCharges[], selectedList: number[], bulkList: IBulkEditList, bulkSR: IBulkEditSR, jobDetails: IJobDetails, exchangeRates: ExchangeRateLookupEntity[]) => {
  serviceList = serviceList.map((list: IServicesAndCharges, index: number) => {
    if (selectedList.includes(index)) {
      let service = serviceList[index].service;
      return editService(service, bulkList, bulkSR, exchangeRates, list, jobDetails.CurrencyCode,);
    } else {
      return list
    }
  })
  return serviceList;
}

export const onBulkEditAll = (serviceList: IServicesAndCharges[], selectedList: number[], bulkList: IBulkEditList, bulkSR: IBulkEditSR, jobDetails: IJobDetails, exchangeRates: ExchangeRateLookupEntity[]) => {
  serviceList = serviceList.map((list: IServicesAndCharges, index: number) => {
    let service = serviceList[index].service;

    const isChargeExist = list
      .charges
      .some((charge: ICharge) => !charge.BillingStatus || !BILLING_STATUS_OPTIONS_LIST.includes(charge.BillingStatus))

    if (isChargeExist) {
      return editService(service, bulkList, bulkSR, exchangeRates, list, jobDetails.CurrencyCode,);
    } else {
      return list
    }
  });
  return serviceList;
}

export const setServiceValue = (obj: any, jobDetails: any, key: any, event: any) => {

  obj.LocalJobId = jobDetails?.LocalJobId;
  obj.UomCode = null;
  obj.UomName = null;
  obj.IsActive = true;
  obj.modifedAt = moment().format();
  if (!obj.Id) {
    obj.IsActive = moment().format();
  }
  if (key === 'customerService') {
    if (event.ServiceCode && event.ServiceName) {
      obj.Code = event.ServiceCode
      obj.Name = event.ServiceName
    }
  }
  if (key === 'actualCurrency') {
    obj.PriceCurrencyCode = event ? event.code : ""
  }
  if (key === 'estimatedCurrency') {
    obj.CostCurrencyCode = event ? event.code : ""
  }
  if (key === 'defaultCostCurrency') {
    obj.CostCurrencyCode = event
  }
  if (key === 'defaultBillingCurrency') {
    obj.BillingCurrencyCode = event
  }
  if (key === 'defaultPriceCurrency') {
    obj.PriceCurrencyCode = event
  }
  if (key === 'billingCurrency') {
    obj.BillingCurrencyCode = event ? event.code : ""
  }
  if (key === 'billingExchangeRate') {
    if (event) {
      obj.BillingExchangeRate = event.target.type === "number" ? parseFloat(event.target.value) : event.target.value
    }
  }
  if (key === 'costExchangeRate') {
    if (event) {
      obj.CostExchangeRate = event.target.type === "number" ? parseFloat(event.target.value) : event.target.value
    }
  }
  if (key === 'supplier') {
    obj.SupplierName = event ? event.name : ""
    obj.SupplierCode = event ? event.code : ""
  }
  if (key === 'serviceName') {
    obj.Name = event ? event.name : ""
    obj.Code = event ? event.code : ""
    obj.OperationalProcessFk = event ? event.opTags?.operationalProcessId : ""
    obj.svsCategoryCodes = event ? event.svsCategories : []
  }
  if (key === 'qty') {
    obj.Quantity = event.target.type === "number" ? parseFloat(event.target.value) : event.target.value
  }
  if (key === 'unitPrice') {
    obj.UnitPrice = event.target.type === "number" ? parseFloat(event.target.value) : event.target.value
  }
  if (key === 'remarks') {
    obj.InternalRemarks = event.target.value
  }
  if (key === 'BillingExchangeRate') {
    obj.BillingExchangeRate = event
  }
  if (key === 'CostExchangeRate') {
    obj.CostExchangeRate = event
  }
  if (key === 'PriceExchangeRate') {
    obj.PriceExchangeRate = event
  }
  if (key === 'billingStatus') {
    obj.BillingStatus = event ? event.name : ""
  }
  if (key === 'paymentMethod') {
    obj.PaymentTermCode = event ? event : ""
  }
  if (key === 'billingMethod') {
    obj.BillingMethodCode = event ? event : ""
  }
  if (key === 'isShowVoucherLink') {
    obj.isShowVoucherLink = false;
  }
  if (key === 'IsCashVoucherNeeded') {
    obj.IsCashVoucherNeeded = event;
    obj.isShowVoucherLink = false;
    obj.VoucherLink = '';
    obj.VoucherFk = '';
    obj.VoucherNumber = '';
    obj.VoucherStatus = '';
    obj.VoucherType = '';
  }
  if (key === 'VoucherNumber') {
    obj.VoucherFk = event ? event.VoucherId : ""
    obj.VoucherNumber = event ? event.VoucherNumber : ""
    obj.VoucherStatus = event ? event.Status : ""
    obj.VoucherLink = event?.VoucherLink ? event.VoucherLink : ""
    obj.isShowVoucherLink = !!event?.isShowVoucherLink
  }
  if (key === 'VoucherType') {
    obj.VoucherType = event ? event.code : "";
    obj.VoucherFk = '';
    obj.VoucherNumber = '';
    obj.VoucherStatus = '';
  }
  if (key === 'BackToBack') {
    obj.BackToBack = event
  }
  if (key === 'IsBillable') {
    obj.IsBillable = event;
    if (!obj.IsBillable) {
      obj.BillingStatus = ''
    }
  }

  if (key === 'CostTaxPercent') {
    obj.CostTaxPercent = event
  }

  return obj
}

export const setChargeValue = (obj: any, jobDetails: any, key: any, event: any) => {

  obj.LocalJobId = jobDetails?.LocalJobId;
  if (key === 'actualTax') {
    obj.PriceTaxCode = event ? event.Code : ""
    obj.PriceTaxPercent = event ? event.Percentage : ""
  }
  if (key === 'vatType') {
    obj.PriceTaxCode = event ? event.code : ""
    obj.PriceTaxPercent = event ? event.percentage : ""

  }
  if (key === 'estimateTax') {
    //check this condition
    obj.CostTaxAmount = ""
    obj.CostTaxCode = event ? event.Code : ""
    obj.CostTaxPercent = event ? event.Percentage : ""
  }
  if (key === 'UOM') {
    obj.UomName = event ? event.fullName : ""
    obj.UomCode = event ? event.code : ""
  }
  if (key === 'CostUOM') {
    obj.CostUomName = event ? event.fullName : ""
    obj.CostUomCode = event ? event.code : ""
  }
  if (key === 'billingParty') {
    obj.OperationalProcessPartyFk = event ? event.operationalProcessPartyId : ""
  }
  if (key === 'chargeName') {
    obj.Name = event ? event.name : ""
    obj.Code = event ? event.code : ""
    obj.MasterCode = event ? event.gacMasterCode : ""
  }
  if (key === 'unit') {
    obj.UnitPrice = event.target.type === "number" ? parseFloat(event.target.value) : event.target.value
  }
  if (key === 'quantity') {
    obj.Quantity = event.target.type === "number" ? parseFloat(event.target.value) : event.target.value
  }
  if (key === 'costQuantity') {
    obj.CostQuantity = event.target.type === "number" ? parseFloat(event.target.value) : event.target.value
  }
  if (key === 'unitPrice') {
    obj.UnitPrice = event
  }
  if (key === 'remarks') {
    let limit = 1000;
    obj.InternalRemarks = event.target.value.slice(0, limit)
  }
  if (key === 'externalRemarks') {
    let limit = 1000;
    obj.ExternalRemarks = event.target.value.slice(0, limit)
  }
  if (key === 'estUnitCost') {
    if (event) {
      obj.UnitCost = event.target.type === "number" ? parseFloat(event.target.value) : ''
    }
  }
  if (key === 'BillingExchangeRate') {
    obj.BillingExchangeRate = event
  }
  if (key === 'BillingCurrencyCode') {
    obj.BillingCurrencyCode = event
  }
  if (key === 'CostCurrencyCode') {
    obj.CostCurrencyCode = event
  }
  if (key === 'CostExchangeRate') {
    obj.CostExchangeRate = event
  }
  if (key === 'CostTaxAmount') {
    obj.CostTaxAmount = event
  }
  if (key === 'EstimatedCost') {
    obj.EstimatedCost = event
  }
  if (key === 'OperatingIncomeActual') {
    obj.OperatingIncomeActual = event
  }
  if (key === 'PriceTaxAmount') {
    obj.PriceTaxAmount = event
  }
  if (key === 'TotalEstimatedCost') {
    obj.TotalEstimatedCost = event
  }
  if (key === 'TotalPriceAmount') {
    obj.TotalPriceAmount = event
  }
  if (key === 'PriceExchangeRate') {
    obj.PriceExchangeRate = event
  }
  if (key === 'PriceCurrencyCode') {
    obj.PriceCurrencyCode = event
  }
  if (key === 'eyeShareCost') {

    const isNumberInput = event.target.type === "number";
    const hasNonEmptyValue = event.target.value.trim() !== '';

    if (isNumberInput && hasNonEmptyValue) {
      obj.TotalEyeshareAmount = parseFloat(event.target.value);
    } else {
      obj.TotalEyeshareAmount = null;
    }

  }
  if (key === 'BillAmount') {
    obj.BillAmount = event
  }
  if (key === 'CostVariance') {
    obj.CostVariance = event
  }
  if (key === 'PriceVariance') {
    obj.PriceTaxCode = event ? event.code : ""
    obj.PriceTaxPercent = event ? event.percentage : ""
  }
  if (key === 'customerService') {
    obj.GSServiceName = event?.GSServiceName ? event?.GSServiceName : event?.name
    obj.GSServiceCode = event?.GSServiceCode ? event?.GSServiceCode : event?.code
    if (event?.ChargeName && event?.ChargeCode) {
      obj.Code = event.ChargeCode
      obj.Name = event.ChargeName
    }
  }
  return obj
}

export const setServiceRequestDetails = (obj: any, key: any, event: any) => {
  if (key === 'startDate') {
    obj.serviceRequestDetails.StartDateTime = moment(event.value).utc().format()
  }
  if (key === 'endDate') {
    obj.serviceRequestDetails.EndDateTime = moment(event.value).utc().format()
  }
  if (key === 'defaultEndDate') {
    obj.serviceRequestDetails.EndDateTime = moment(event).utc().format()
  }
  if (key === 'defaultStartDate') {
    obj.serviceRequestDetails.StartDateTime = moment(event).utc().format()
  }
  if (key === 'serviceStatus') {
    obj.serviceRequestDetails.ServiceStatus = event ? event.label : ""
  }
  if (key === 'serviceSRnumber') {
    obj.serviceRequestDetails.SRNumber = event ? event.name : ""
    obj.serviceRequestDetails.SRStatus = event ? event.status : ""
  }
  if (key === 'isShowSRlink') {
    obj.serviceRequestDetails.isShowSRLink = event;
    obj.serviceRequestDetails.SRNumber = null;
  }
  return obj
}

export const setEditServiceRequestDetails = (obj: any, key: any, event: any) => {
  if (key === 'startDate') {
    obj.StartDateTime = moment(event.value).utc().format()
  }
  if (key === 'endDate') {
    obj.EndDateTime = moment(event.value).utc().format()
  }
  if (key === 'defaultEndDate') {
    obj.EndDateTime = moment(event).utc().format()
  }
  if (key === 'defaultStartDate') {
    obj.StartDateTime = moment(event).utc().format()
  }
  if (key === 'serviceStatus') {
    obj.ServiceStatus = event ? event.label : ""
  }
  if (key === 'serviceSRnumber') {
    obj.SRNumber = event ? event.name : ""
    obj.SRStatus = event ? event.status : ""
  }
  if (key === 'isShowSRlink') {
    obj.isShowSRLink = event;
    obj.SRNumber = null;
  }
  return obj
}

export const setEditServiceValue = (obj: any, jobDetails: any, key: any, event: any) => {
  obj.LocalJobId = jobDetails?.LocalJobId;
  obj.UomCode = null;
  obj.UomName = null;
  obj.IsActive = true;
  obj.modifedAt = moment().format();
  if (!obj.Id) {
    obj.IsActive = moment().format();
  }
  if (key === 'customerService') {
    if (event.ServiceCode && event.ServiceName) {
      obj.Code = event.ServiceCode
      obj.Name = event.ServiceName
    }
  }
  if (key === 'actualCurrency') {
    obj.PriceCurrencyCode = event ? event.code : ""
  }
  if (key === 'estimatedCurrency') {
    obj.CostCurrencyCode = event ? event.code : ""
  }
  if (key === 'defaultCostCurrency') {
    obj.CostCurrencyCode = event
  }
  if (key === 'defaultBillingCurrency') {
    obj.BillingCurrencyCode = event
  }
  if (key === 'defaultPriceCurrency') {
    obj.PriceCurrencyCode = event
  }
  if (key === 'billingCurrency') {
    obj.BillingCurrencyCode = event ? event.code : ""
  }
  if (key === 'billingExchangeRate') {
    if (event) {
      obj.BillingExchangeRate = event.target.type === "number" ? parseFloat(event.target.value) : event.target.value
    }
  }
  if (key === 'costExchangeRate') {
    if (event) {
      obj.CostExchangeRate = event.target.type === "number" ? parseFloat(event.target.value) : event.target.value
    }
  }
  if (key === 'supplier') {
    obj.SupplierName = event ? event.name : ""
    obj.SupplierCode = event ? event.code : ""
  }
  if (key === 'serviceName') {
    obj.Name = event ? event.name : ""
    obj.Code = event ? event.code : ""
    obj.OperationalProcessFk = event ? event.opTags?.operationalProcessId : ""
    obj.svsCategoryCodes = event ? event.svsCategories : []
  }
  if (key === 'qty') {
    obj.Quantity = event.target.type === "number" ? parseFloat(event.target.value) : event.target.value
  }
  if (key === 'unitPrice') {
    obj.UnitPrice = event.target.type === "number" ? parseFloat(event.target.value) : event.target.value
  }
  if (key === 'remarks') {
    obj.InternalRemarks = event.target.value
  }
  if (key === 'BillingExchangeRate') {
    obj.BillingExchangeRate = event
  }
  if (key === 'CostExchangeRate') {
    obj.CostExchangeRate = event
  }
  if (key === 'PriceExchangeRate') {
    obj.PriceExchangeRate = event
  }
  if (key === 'billingStatus') {
    obj.BillingStatus = event ? event.name : ""
  }
  if (key === 'paymentMethod') {
    obj.PaymentTermCode = event ? event : ""
  }
  if (key === 'billingMethod') {
    obj.BillingMethodCode = event ? event : ""
  }
  if (key === 'IsCashVoucherNeeded') {
    obj.IsCashVoucherNeeded = event;
    obj.isShowVoucherLink = false;
    obj.VoucherFk = '';
    obj.VoucherNumber = '';
    obj.VoucherStatus = '';
  }
  if (key === 'isShowVoucherLink') {
    obj.isShowVoucherLink = false;
  }
  if (key === 'VoucherNumber') {
    obj.VoucherFk = event ? event.VoucherId : ""
    obj.VoucherNumber = event ? event.VoucherNumber : ""
    obj.VoucherStatus = event ? event.Status : ""
  }
  if (key === 'BackToBack') {
    obj.BackToBack = event
  }

  return obj
}

export const createServiceObj = (list: any[], jobDetails: any) => {
  let services = list.map(service => {
    let charges = service.charges.map((charge: any) => {
      return {
        ...charge,
        LocalJobId: jobDetails?.LocalJobId,
        Id: 0
      }
    })
    return {
      ...service,
      service: {
        ...service.services,
        IsBillable: true,
        IsActive: true,
        LocalJobId: jobDetails?.LocalJobId,
        Id: 0
      },
      charges: [...charges],
      serviceActivity: [],
      serviceRequestDetails: {
        CompletedDateTime: null,
        EndDateTime: null,
        Id: null,
        Rating: null,
        SRNumber: null,
        SRStatus: null,
        ServiceFk: null,
        ServiceStatus: null,
        StartDateTime: null
      },
      supplierDetails: {}
    }
  })
  return services
}

export const updateDeleteService = (list: any, index: number, updateValue: any) => {
  return [
    ...list.slice(0, index),
    list[index] = updateValue,
    ...list.slice(index + 1)
  ];
};

export const updateAddCharge = (list: any, index: number, updateValue: any) => {
  return [
    ...list.slice(0, index),
    list[index] = updateValue,
    ...list.slice(index + 1)
  ];
};

export const updateCharges = (list: any, serviceIndex: number, chargeIndex: number, updateValue: any) => {
  return [
    ...list.slice(0, serviceIndex),
    list[serviceIndex] = {
      ...list[serviceIndex],
      charges: [
        ...list[serviceIndex].charges.slice(0, chargeIndex),
        list[serviceIndex].charges[chargeIndex] = updateValue,
        ...list[serviceIndex].charges.slice(chargeIndex + 1)
      ]
    },
    ...list.slice(serviceIndex + 1)
  ];
};

export const updateService = (list: any, serviceIndex: number, updateValue: any) => {
  return [
    ...list.slice(0, serviceIndex),
    list[serviceIndex] = {
      ...list[serviceIndex],
      service: updateValue
    },
    ...list.slice(serviceIndex + 1)
  ]
};

export const applyTariff = (list: any, serviceAndCharges: any, tariffDetails: any, isAllSelected: boolean) => {
  let services = serviceAndCharges.filter((el: any) => el.service.IsActive);
  let tarrifArray = tariffDetails

  if (!isAllSelected || list.length !== serviceAndCharges.length) {
    list?.forEach((item: number, index: number) => {
      if (item < services?.length && index < tariffDetails?.length) {
        services[item] = tariffDetails[index];
      }
    });
  } else {
    services = services.map((item: any, index: number) => {
      return tariffDetails[index]
    })
  }
  return services
};

export const setTariffDetails = (userDetails: any, charge: any) => {
  charge.TariffDetails = {
    ...charge.TariffDetails,
    ModifiedBy: userDetails?.Code,
    ModifiedAt: moment(new Date()).utc().format(),
    TariffEditedBy: userDetails?.Code,
    TariffEditedOn: moment(new Date()).utc().format()
  }
  return charge
}

export const getStatus = (jobDetail: any, services: any, serviceRow: any, billingParties: any, type: string, selectedList: any, isAllSelected: boolean): any => {
  let customer: any[] = [jobDetail?.CustomerCode];
  let supplier: any[] = [];
  if (type === 'bulkEdit') {
    if (isAllSelected) {
      services.forEach((el: any, index: number) => {
        let charges = services[index].charges;
        let filteredParty = billingParties.filter((el: any) => charges.some((item: any) => el.operationalProcessPartyId === item.OperationalProcessPartyFk));
        if (!supplier.includes(el.service.SupplierCode)) {
          if (!BILLING_STATUS_OPTIONS_LIST.includes(el.service.BillingStatus)) {
            supplier.push(el.service.SupplierCode)
          }
        }
        filteredParty.forEach((x: any) => {
          if (!customer.includes(x.code)) {
            customer.push(x.code);
          }
        })
      })
    } else {
      services.forEach((el: any, index: number) => {
        if (selectedList.includes(index)) {
          let charges = services[index].charges;
          let filteredParty = billingParties.filter((el: any) => charges.some((item: any) => el.operationalProcessPartyId === item.OperationalProcessPartyFk));
          if (!supplier.includes(el.service.SupplierCode)) {
            supplier.push(el.service.SupplierCode)
          }
          filteredParty.forEach((x: any) => {
            if (!customer.includes(x.code)) {
              customer.push(x.code);
            }
          })

        }
      })
    }
  } else {
    let { service, charges } = serviceRow;
    supplier = [service.SupplierCode];
    let filteredParty = billingParties.filter((el: any) => charges.some((item: any) => el.operationalProcessPartyId === item.OperationalProcessPartyFk));
    filteredParty.forEach((x: any) => {
      if (!customer.includes(x.code)) {
        return customer.push(x.code);
      }
    })
  }

  return axios.post(`/validatePartyStatus`, {
    "suppliers": supplier || [],
    "customers": customer || [],
    "companyCode": jobDetail?.CompanyCode
  }).then((response) => {
    if (response?.data) {
      return response.data;
    }
  })
}

export const calculateCost = (charge: any): number => {
  if (_.isNil(charge?.TotalEyeshareAmount)) {
    return ((charge?.CostQuantity ?? 0) * charge.UnitCost * charge.CostExchangeRate)
  } else {
    return (charge.TotalEyeshareAmount ?? 0) * charge.CostExchangeRate
  }
};

export const convertPricesAndTaxes = (jobCurrencyCode: string, services: any, chargeIndex: number) => {
  const service = services?.service;
  const charge = services?.charges[chargeIndex];

  if (service && charge) {
    const {
      amountValue,
      estimatedAmount,
      purchaseTaxAmount,
      estimatedTaxAmount
    } = calculateChargePricesAndTaxes(charge);

    const billingExchangeRate = getBillingExchangeRate(service, jobCurrencyCode);

    const {
      billTax,
      billAmount,
      adjBillAmount
    } = calculateBillAmountAndTax(amountValue, service, charge, billingExchangeRate);

    if (!charge.OffsetAmount) {
      charge.OffsetAmount = null;
    }

    const netCostLocalCurrency = calculateCost(charge);

    const netPriceLocalCurrency = (amountValue * charge.PriceExchangeRate) - charge.OffsetAmount;
    const operatingIncome = netPriceLocalCurrency - netCostLocalCurrency;

    return {
      operatingIncome,
      estimatedTaxAmount,
      estimatedAmount,
      purchaseTaxAmount,
      billAmount,
      adjBillAmount,
      billTax,
      amountValue,
    }
  }

  return {
    operatingIncome: 0,
    estimatedTaxAmount: 0,
    estimatedAmount: 0,
    purchaseTaxAmount: 0,
    billAmount: 0,
    adjBillAmount: 0,
    billTax: 0,
    amountValue: 0,
  }
}

export const calculateBillAmountAndTax = (amountValue: number, service: any, charge: any, billingExchangeRate: number) => {

  let billAmount = 0;
  let billTax = 0;
  let adjBillAmount = 0;

  let offsetAmount = charge?.OffsetAmount || 0;
  let offsetAmountBillingCurrency = (offsetAmount * billingExchangeRate)

  let offsetWithPercentage = (offsetAmountBillingCurrency * (charge?.PriceTaxPercent / 100)) + offsetAmountBillingCurrency

  if (amountValue && service?.BillingExchangeRate) {
    if (charge.PriceTaxPercent) {
      billTax = (amountValue * charge.PriceTaxPercent) / 100;
    }

    const amountWithTax = amountValue + (billTax || 0);

    let convertedBillTaxAmount: any = 0;
    convertedBillTaxAmount = amountWithTax * billingExchangeRate;

    billAmount = convertedBillTaxAmount;
    //TODO: Refactor this
    adjBillAmount = Number(billAmount.toFixed(2)) - Number(offsetWithPercentage.toFixed(2));

  }

  return { billTax, billAmount, adjBillAmount };

}

export const calculateChargePricesAndTaxes = (charge: any) => {
  let estimatedAmount = 0;
  let estimatedTaxAmount = 0;
  let purchaseTaxAmount = 0;

  const amountValue = charge?.UnitPrice * charge?.Quantity;
  const qty = charge?.CostQuantity || charge.Quantity;
  if (qty && charge?.UnitCost) {
    estimatedAmount = qty * charge?.UnitCost;
  }

  purchaseTaxAmount = estimatedAmount * charge.CostTaxPercent / 100;
  estimatedTaxAmount = purchaseTaxAmount + estimatedAmount;

  return { amountValue, estimatedAmount, purchaseTaxAmount, estimatedTaxAmount };
}

export const getBillingExchangeRate = (service: any, jobCurrencyCode: string) => {
  let billingExchangeRate = 1;

  if (service.BillingExchangeRate) {
    if (jobCurrencyCode === service.PriceCurrencyCode) {
      billingExchangeRate = 1 / service.BillingExchangeRate;
    } else {
      billingExchangeRate = service.BillingExchangeRate;
    }
  }
  return billingExchangeRate;
}

function editService(service: IService, bulkList: IBulkEditList, bulkSR: IBulkEditSR, exchangeRates: ExchangeRateLookupEntity[], list: any, jobCurrencyCode: string) {
  if (bulkList.BillingCurrencyCode || bulkList.PriceCurrencyCode) {
    let fromCurrency = bulkList.BillingCurrencyCode ? bulkList.BillingCurrencyCode : service?.BillingCurrencyCode;
    let targetCurrency = bulkList.PriceCurrencyCode ? bulkList.PriceCurrencyCode : service?.PriceCurrencyCode;

    if (jobCurrencyCode !== targetCurrency) {
      fromCurrency = bulkList.PriceCurrencyCode ? bulkList.PriceCurrencyCode : service?.PriceCurrencyCode;
      targetCurrency = bulkList.BillingCurrencyCode ? bulkList.BillingCurrencyCode : service?.BillingCurrencyCode;
    }

    if (fromCurrency !== targetCurrency) {
      let exChangeRate: any = getExchangeRates(exchangeRates, fromCurrency!, targetCurrency!);
      bulkList.BillingExchangeRate = exChangeRate ? exChangeRate.ExchangeRate : 0;
    } else {
      bulkList.BillingExchangeRate = 1;
    }
  }
  if (bulkList.IsBillable === false) {
    list.charges?.forEach((c: any) => c.UnitPrice = 0);
  }

  let { ...bulkListToUpdate } = bulkList;

  return {
    ...list,
    service: {
      ...list.service,
      ...bulkListToUpdate,
    },
    charges: [...list.charges],
    serviceRequestDetails: {
      ...list.serviceRequestDetails,
      ...bulkSR
    }
  };
}


export function formatJobBillingPartyAddress(bp: any) {
  return formatAddress(bp.postalAddress, bp.city, bp.country);
}

