import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Dropdown from 'Controls/Dropdown';
import PegLabel from 'Controls/Label/PegLabel';
import PegCheckbox from 'Controls/Checkbox/PegCheckbox';
import PegDatePicker from 'Controls/DateRange/PegDatePicker';
import { IVesselDetail } from 'Model/Order/VesselDetail';
import VoyageTableView from './VoyageTableView/VoyageTableView';
import InputBox from 'Controls/Input/PegInputBox';
import ReferenceAgent from 'Api/ReferenceAgent';
import PegRadioButton from 'Controls/Radio/PegRadioButton';
import { AiOutlineLink } from 'react-icons/ai';
import PegButton from 'Controls/Button/PegButton';
import VesselCallTableView from './VesselCallTableView';
import PegModal from 'Controls/PegModal';
import { toast } from 'react-toastify';
import { Header } from './VesselCallTableView/index.styles';
import { MdClear } from 'react-icons/md';
import moment from "moment";


type MyState = {
  vessel: IVesselDetail | {};
  callType: any[];
  callSubType: any[];
  callPurpose: any[];
  openModal: boolean;
  data?: any;
  filteredData?: any;
  columns?: any[];
  searchInput?: any;
  checked: any[];
  cargoRelated: boolean;
  selectionChanged: any,
  rowEdit: any,
  selectedRowIndex: any[],
  index: any,
  linkVesselCallFlag: boolean;
}

type MyProps = {
  vesselDetails?: any;
  updateVesselDetails: (event: any, key: string) => void;
  updateVesselAdditionalDetails: (data: any) => void;
  linkVesselCall: (data: any) => void;
  linkOptionDetails: (data: any) => void;
  resetVesselState: () => void,
  updateQuoteServices: (event: any, key: string) => void;
  optionsValidation?: any;
  enableCrewTab: (value: boolean) => void;
  enableCargoTab: (value: boolean) => void;
  enableShipSpares: (value: boolean) => void;
  createVoyageRow: () => void;
};

class VesselDetailComponent extends React.Component<MyProps & RouteComponentProps, MyState> {

  constructor(props: any) {
    super(props);
    this.state = {
      vessel: {},
      callType: [],
      callSubType: [],
      callPurpose: [],
      cargoRelated: false,
      openModal: false,
      data: [],
      filteredData: [],
      columns: [],
      searchInput: '',
      selectionChanged: false,
      rowEdit: null,
      selectedRowIndex: [],
      index: 0,
      checked: [],
      linkVesselCallFlag: false,
    };
  }

  onUniqueArr = (newArr: any[]) => {
    const uniqueArray = newArr.filter((value, index) => {
      const filteredValue = JSON.stringify(value);
      return index === newArr.findIndex(obj => {
        return JSON.stringify(obj) === filteredValue;
      });
    })
    return uniqueArray;
  }

  getIndustryVerticalDetails = (code: any) => {
    ReferenceAgent.industryVertical(code).then((data: any) => {
      const newCallTypeArr: any[] = [];
      const newCallSubTypeArr: any[] = [];
      const newCallPurposeArr: any[] = [];
      if (data) {
        data.map((x: any) => {
          newCallTypeArr.push(x.CallType)
          newCallSubTypeArr.push(x.CallSubType)
          newCallPurposeArr.push(x.CallPurpose)
          this.setState({
            callType: this.onUniqueArr(newCallTypeArr),
            callSubType: this.onUniqueArr(newCallSubTypeArr),
            callPurpose: this.onUniqueArr(newCallPurposeArr)
          })
        })
      } else {
        this.clearVesselLink();
      }
    })
  }

  getAdditionalVesselDetails = (code: any) => {
    ReferenceAgent.vesselDetail(code).then((response: any) => {
      const industryVerticleCode = response[0]?.vesselType?.industryVertical?.code;
      this.props.updateVesselAdditionalDetails(response[0]);
      if (industryVerticleCode) {
        this.getIndustryVerticalDetails(industryVerticleCode)
      }
    })
  }

  onUpdateValue = (event: any, key: any) => {
    const {
      updateVesselDetails,
    } = this.props
    updateVesselDetails(event, key);
    if (key === 'vesselName') {
      if (event?.code) {
        this.getAdditionalVesselDetails(event?.code)
      } else {
        this.clearVesselLink();
        this.props.resetVesselState()
      }
    }
  }

  onAddtionalServiceChange = (event: any, key: any) => {
    const {
      updateQuoteServices,
      enableCargoTab,
      enableCrewTab,
      enableShipSpares
    } = this.props
    updateQuoteServices(event, key)
    if (key === 'cargoOperation') {
      enableCargoTab(event.checked)
    }
    if (key === 'shipSpares') {
      enableShipSpares(event.checked)
    }
    if (key === 'crewChange') {
      enableCrewTab(event.checked)
    }
  }

  handleModal = () => {
    this.setState({ openModal: true })
    this.props.resetVesselState();
  }

  handleClose = () => {
    const { openModal } = this.state;
    if (openModal) {
      this.setState({ openModal: false })
    }
  }

  componentDidMount() {
    this.getVesselList();
    let columns = [
      {
        Header: () => <Header>Vessel Call Number</Header>,
        accessor: "PlannerNumber",
        width: '300',
        sortable: true,
        show: true,
        displayValue: "Vessel Call Id",
        headerStyle: {
          backgroundColor: '#E5E5E5',
          color: 'black'
        }
      },
      {
        Header: () => <Header>Vessel Name</Header>,
        accessor: "VesselName",
        width: '400',
        sortable: true,
        show: true,
        displayValue: "Vessel Name",
        headerStyle: {
          backgroundColor: '#E5E5E5',
          color: 'black'
        }
      },
      {
        Header: () => <Header>Port</Header>,
        accessor: "PortName",
        width: '300',
        sortable: true,
        show: true,
        displayValue: "Port Name",
        headerStyle: {
          backgroundColor: '#E5E5E5',
          color: 'black'
        }
      }
    ];
    this.setState({ columns });
  }

  getVesselList = () => {
    ReferenceAgent.vesselList().then((response: any) => {
      this.setState({ searchInput: '', data: response, filteredData: response })
    })
  }

  handleChange = (event: any) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  globalSearch = () => {
    let { searchInput, data } = this.state;
    let filteredData = data.filter((value: any) => {
      return (
        value?.VesselCallId.toString().includes(searchInput.toLowerCase()) ||
        value?.VesselName.toLowerCase().includes(searchInput.toLowerCase()) ||
        value?.PortName
          .toString()
          .toLowerCase()
          .includes(searchInput.toLowerCase())
      );
    });
    this.setState({ filteredData });
  };


  linkVesselCall = () => {
    this.props.resetVesselState();
    this.clearVesselLink();
    const { index } = this.state;
    const filteredData = this.state.data[index];
    ReferenceAgent.vesselCallById(filteredData?.VesselCallId).then((res: any) => {
      let linkVesselData = res[0]?.vesselDetails;
      this.props.linkVesselCall(linkVesselData);
      this.setState({ linkVesselCallFlag: true })
      this.getIndustryVerticalDetails(linkVesselData?.IndustryVerticalCode);
      if (linkVesselData?.PortCode != '') {
        this.props.createVoyageRow();
        this.props.linkOptionDetails(linkVesselData);
      }
      this.setState({ openModal: false })
    }).catch((err: any) => {
      if (err) {
        toast.error(`Select some other id, data not found!`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
          style: { width: '400px' },
        })
      }
      this.setState({ openModal: false })
    })
  }

  clearVesselLink = () => {
    this.props.resetVesselState();
    this.setState({
      linkVesselCallFlag: false,
      callType: [],
      callSubType: [],
      cargoRelated: false,
      callPurpose: []
    });
  }

  render() {
    const { openModal, data, columns, searchInput, checked, filteredData, linkVesselCallFlag } = this.state;
    const { vesselDetails } = this.props;
    return (
      <div>
        <div className="flex flex-row justify-end h-8 relative right-10 top-3">
          <PegButton variant={'LinkPrimary'} id="btnLinkVesselCall" onClick={() => this.handleModal()}>
            <AiOutlineLink size="1.2em" className="fill-current text-primary group-hover:text-primary-shade" />
            Link Vessel Call
          </PegButton>
        </div>
        <PegModal
          isOpen={openModal}
          tertiaryMsg={'Cancel'}
          showTemplate={true}
          buttonText={'Link'}
          modalTitle={'Vessel Call List'}
          tertiaryAction={this.linkVesselCall}
          closeModal={this.handleClose}
          isCenter={true}>
          <div>
            <VesselCallTableView
              data={filteredData && filteredData.length ? filteredData : data}
              columns={columns}
              searchInput={searchInput}
              checked={checked}
              handleChange={this.handleChange}
              clearSearch={this.getVesselList}
              getTrProps={(state: any, rowInfo: any) => {
                if (rowInfo && rowInfo.row) {
                  return {
                    onClick: (e: any) => {
                      if (rowInfo.index !== this.state.rowEdit) {
                        this.setState({
                          rowEdit: rowInfo.index,
                          selectedRowIndex: rowInfo.original,
                          selectionChanged: this.state.selectionChanged
                            ? false
                            : true
                        });
                      } else {
                        this.setState({
                          rowEdit: null
                        });
                      }
                      this.setState({ index: rowInfo.index })
                    },
                    style: {
                      background:
                        rowInfo.index === this.state.rowEdit ? "#001F58" : "white",
                      color:
                        rowInfo.index === this.state.rowEdit ? "white" : "black"
                    }
                  };
                } else {
                  return {
                  };
                }
              }}
            />
          </div>
        </PegModal>

        <div className="grid grid-cols-2 gap-12 p-12 pt-3">
          <div className="col-span-1">
            {vesselDetails?.VesselCallNumber ? <div className="flex flex-row items-center space-y-4">
              <PegLabel id="lblVesselType" name="vesselType">
                Vessel Call ID
              </PegLabel>
              <div className="w-4/12">
                <InputBox
                  id={'txtVesselCallId'}
                  key={'vesselCallId'}
                  value={vesselDetails?.VesselCallNumber ? vesselDetails?.VesselCallNumber : ''}
                  onChange={(event: any) => this.onUpdateValue(event, 'vesselCallId')}
                  disabled
                />
              </div>
              <div className="ml-14">
                <PegButton variant={'LinkPrimary'} id="btnClear" onClick={this.clearVesselLink}>
                  Clear Link
                  <MdClear size="1em" className="fill-current text-primary group-hover:text-primary-shade" />
                </PegButton>
              </div>
            </div> : ''}

            <div className="flex flex-row items-center space-y-4">
              <PegLabel id="lblVessel" name="vessel">
                Vessel
              </PegLabel>
              <div className="w-8/12">
                <Dropdown
                  id={'drpVesselName'}
                  defaultValue={vesselDetails?.VesselName}
                  value={vesselDetails?.VesselName}
                  placeHolder={'Select Vessel'}
                  isLookup={true}
                  dropdownLabel={'name'}
                  dropdownValue={'name'}
                  appendParams={false}
                  isMandatory={true}
                  getFormattedLabel={true}
                  useDebounce={true}
                  isClearable={true}
                  showErrorMessage={'mandatory field'}
                  url={`/mdm-vessel?searchText=`}
                  onChangeDropdown={(event: any, key: any) => this.onUpdateValue(event, 'vesselName')}
                />
              </div>
            </div>

            <div className="flex flex-row items-center space-y-4">
              <PegLabel id="lblVesselType" name="vesselType">
                Vessel Type
              </PegLabel>
              <div className="w-8/12">
                <InputBox
                  id={'txtVesselType'}
                  key={'vesselType'}
                  value={vesselDetails?.VesselName ? vesselDetails?.VesselTypeName : ''}
                  onChange={(event: any) => this.onUpdateValue(event, 'vesselType')}
                />
              </div>
            </div>

            <div className="grid grid-flow-col gap-4">
              <div className="col-span-1">
                <div className="flex flex-row items-center space-y-4 space-x-20">
                  <PegLabel id="lblGrossTonnage" name="grossTonnage" className='w-5/12 text-sm font-normal text-dark'>
                    Gross Tonnage
                  </PegLabel>
                  <div className="w-6/12">
                    <InputBox
                      id={'txtGrossTonnage'}
                      key={'grossTonnage'}
                      value={vesselDetails?.VesselName ? vesselDetails?.GrossTonnage : ''}
                      onChange={(event: any) => this.onUpdateValue(event, 'grossTonnage')}
                    />
                  </div>
                </div>

              </div>
              <div className="col-span-1">
                <div className="flex flex-row items-center space-y-4">
                  <PegLabel id="lblNetTonnage" name="netTonnage" className='w-6/12 text-sm font-normal text-dark'>
                    Net Tonnage
                  </PegLabel>
                  <div className="w-6/12">
                    <InputBox
                      id={'txtNetTonnage'}
                      key={'netTonnage'}
                      value={vesselDetails?.VesselName ? vesselDetails?.NetTonnage : ''}
                      onChange={(event: any) => this.onUpdateValue(event, 'netTonnage')}
                    />
                  </div>
                </div>
              </div>
            </div>


            <div className="grid grid-flow-col gap-4">
              <div className="col-span-1">
                <div className="flex flex-row items-center space-y-4 space-x-20">
                  <PegLabel id="lblSummaryDWT" name="summaryDWT" className='w-5/12 text-sm font-normal text-dark'>
                    Summer DWT
                  </PegLabel>
                  <div className="w-6/12">
                    <InputBox
                      id={'txtSummerDWT'}
                      key={'summerDWT'}
                      value={vesselDetails?.VesselName ? vesselDetails?.SummerDWT : ''}
                      onChange={(event: any) => this.onUpdateValue(event, 'summerDWT')}
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-1">
                <div className="flex flex-row items-center space-y-4">
                  <PegLabel id="lblIndustryVertical" name="industryVertical" className='w-6/12 text-sm font-normal text-dark'>
                    Industry Vertical
                  </PegLabel>
                  <div className="w-6/12">
                    <InputBox
                      id={'txtIndustryVertical'}
                      key={'industryVertical'}
                      value={vesselDetails?.VesselName ? vesselDetails?.IndustryVerticalName : ''}
                      onChange={(event: any) => this.onUpdateValue(event, 'industryVertical')}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-row items-center space-y-4">
              <PegLabel id="lblCallType" name="callType">
                Call Type
              </PegLabel>
              {linkVesselCallFlag ?
                (<div className="w-8/12">
                  <InputBox
                    id={'txtCallType'}
                    key={'callType'}
                    value={vesselDetails?.VesselName ? vesselDetails?.CallTypeName : ''}
                    onChange={(event: any) => this.onUpdateValue(event, 'callType')}
                    disabled={linkVesselCallFlag ? true : false}
                  />
                </div>
                ) :
                (<div className="w-8/12">
                  <Dropdown
                    id={'drpCallType'}
                    value={vesselDetails?.VesselName ? vesselDetails?.CallTypeName : ''}
                    defaultValue={vesselDetails?.VesselName ? vesselDetails?.CallTypeName : ''}
                    defaultInputValue={vesselDetails?.VesselName ? vesselDetails?.CallTypeName : ''}
                    placeHolder={'Select Call Type'}
                    isLookup={false}
                    dropdownLabel={'Name'}
                    dropdownValue={'Name'}
                    appendParams={false}
                    getFormattedLabel={true}
                    dropdownOptions={this.state.callType ? this.state.callType : []}
                    onChangeDropdown={(event: any, key: any) => this.onUpdateValue(event, 'callType')}
                    disabled={vesselDetails?.VesselName ? false : true}
                  />
                </div>
                )
              }
            </div>

            <div className="flex flex-row items-center space-y-4">
              <PegLabel id="lblVesselType" name="callSubType">
                Call SubType
              </PegLabel>
              {linkVesselCallFlag ?
                (
                  <div className="w-8/12">
                    <InputBox
                      id={'txtCallSubType'}
                      key={'callSubType'}
                      value={vesselDetails?.VesselName ? vesselDetails?.CallSubTypeName : ''}
                      onChange={(event: any) => this.onUpdateValue(event, 'callSubType')}
                      disabled={linkVesselCallFlag ? true : false}
                    />
                  </div>
                ) :
                (
                  <div className="w-8/12">
                    <Dropdown
                      id={'drpCallSubType'}
                      value={vesselDetails?.VesselName ? vesselDetails?.CallSubTypeName : ''}
                      defaultInputValue={vesselDetails?.VesselName ? vesselDetails?.CallSubTypeName : ''}
                      defaultValue={vesselDetails?.VesselName ? vesselDetails?.CallSubTypeName : ''}
                      placeHolder={'Select Sub Type'}
                      isLookup={false}
                      dropdownLabel={'Name'}
                      dropdownValue={'Name'}
                      appendParams={false}
                      dropdownOptions={this.state.callSubType ? this.state.callSubType : []}
                      onChangeDropdown={(event: any, key: any) => this.onUpdateValue(event, 'callSubType')}
                      disabled={vesselDetails?.CallTypeName ? false : true}
                    />
                  </div>
                )}

            </div>

            <div className="flex flex-row items-center space-y-4">
              <PegLabel id="lblCargoRelated" name="cargoRelated">
                Cargo Related
              </PegLabel>
              {linkVesselCallFlag ?
                (<div className="w-8/12">
                  <div className="inline-flex items-center mr-8">
                    <PegRadioButton
                      id={'radioCargoRelatedYes'}
                      onChange={(event: any) => this.onUpdateValue(event, 'callCargoRelatedYes')}
                      name={'cargoRelated'}
                      key={'callCargoRelatedYes'}
                      option={'Yes'}
                      value={'true'}
                      checked={vesselDetails?.IsCargoRelated ? vesselDetails?.IsCargoRelated.substr(0, vesselDetails?.IsCargoRelated.length) : false}
                      disabled={linkVesselCallFlag ? true : false} />
                  </div>
                  <div className="inline-flex items-center">
                    <PegRadioButton
                      id={'radioCargoRelatedNo'}
                      onChange={(event: any) => this.onUpdateValue(event, 'callCargoRelatedNo')}
                      name={'cargoRelated'}
                      key={'callCargoRelatedNo'}
                      checked={vesselDetails?.IsCargoRelated ? !(vesselDetails?.IsCargoRelated.substr(0, vesselDetails?.IsCargoRelated.length)) : false}
                      option={'No'}
                      value={'false'}
                      disabled={linkVesselCallFlag ? true : false}
                    />
                  </div>
                  <div className="flex items-center"></div>
                </div>) :
                (
                  <div className="w-8/12">
                    <div className="inline-flex items-center mr-8">
                      <PegRadioButton
                        id={'radioCargoRelatedYes'}
                        onChange={(event: any) => this.onUpdateValue(event, 'callCargoRelatedYes')}
                        name={'cargoRelated'}
                        key={'callCargoRelatedYes'}
                        option={'Yes'}
                        value={'true'}
                        checked={false}
                        disabled={vesselDetails?.CallSubTypeName ? false : true} />
                    </div>
                    <div className="inline-flex items-center">
                      <PegRadioButton
                        id={'radioCargoRelatedNo'}
                        onChange={(event: any) => this.onUpdateValue(event, 'callCargoRelatedNo')}
                        name={'cargoRelated'}
                        key={'callCargoRelatedNo'}
                        checked={false}
                        option={'No'}
                        value={'false'}
                        disabled={vesselDetails?.CallSubTypeName ? false : true} />
                    </div>
                    <div className="flex items-center"></div>
                  </div>
                )
              }

            </div>

            <div className="flex flex-row items-center space-y-4">
              <PegLabel id="lblCallPurpose" name="callPurpose">
                Call Purpose
              </PegLabel>
              {linkVesselCallFlag ?
                (
                  <div className="w-8/12">
                    <InputBox
                      id={'txtCallPurpose'}
                      key={'callPurpose'}
                      value={vesselDetails?.VesselName ? vesselDetails?.CallPurposeName : ''}
                      onChange={(event: any) => this.onUpdateValue(event, 'callPurpose')}
                      disabled={linkVesselCallFlag ? true : false}
                    />
                  </div>
                )
                : (
                  <div className="w-8/12">
                    <Dropdown
                      id={'drpCallPurpose'}
                      value={vesselDetails?.VesselName ? vesselDetails?.CallPurposeName : ''}
                      defaultInputValue={vesselDetails?.VesselName ? vesselDetails?.CallPurposeName : ''}
                      placeHolder={'Select Call Purpose'}
                      isLookup={false}
                      dropdownLabel={'Name'}
                      dropdownValue={'Name'}
                      appendParams={false}
                      dropdownOptions={this.state.callPurpose ? this.state.callPurpose : []}
                      onChangeDropdown={(event: any, key: any) => this.onUpdateValue(event, 'callPurpose')}
                      disabled={vesselDetails?.IsCargoRelated ? false : true}
                    />
                  </div>
                )}
            </div>
          </div>


          <div className="col-span-1">
            <div className="flex flex-row items-center space-y-4">
              <PegLabel id="lblCargoType" name="cargoType">
                Cargo Type
              </PegLabel>
              <div className="w-8/12">
                <Dropdown
                  id={'drpCargoType'}
                  type={'text'}
                  defaultValue={vesselDetails?.CargoTypeName}
                  placeHolder={'Select Cargo Type'}
                  dropdownLabel={'name'}
                  dropdownValue={'name'}
                  appendParams={false}
                  isMandatory={true}
                  getFormattedLabel={true}
                  showErrorMessage={'mandatory field'}
                  url={`mdm-cargo-type?searchText=`}
                  onChangeDropdown={(event: any, key: any) => this.onUpdateValue(event, 'cargoType')}
                />
              </div>
            </div>

            <div className="flex flex-row items-center space-y-4">
              <PegLabel id="lblEtaDate" name="etaDate">
                ETA Date
              </PegLabel>
              <span className="w-4/12">
                <PegDatePicker
                  id={'txtEtaDate'}
                  value={moment(vesselDetails?.ETA, 'DD-MM-YYYY').format()}
                  onChange={(event: any, key: any) => this.onUpdateValue(event, 'etaDate')}
                  name={'etaDate'}
                  type={'dateTime'}
                />
              </span>
            </div>

            <div className="flex flex-row items-center space-y-4">
              <PegLabel id="lblEtbDate" name="etbDate">
                ETB Date
              </PegLabel>
              <span className="w-4/12">
                <PegDatePicker
                  id={'txtEtbDate'}
                  value={moment(vesselDetails?.ETB, 'DD-MM-YYYY').format()}
                  onChange={(event: any, key: any) => this.onUpdateValue(event, 'etbDate')}
                  name={'etbDate'}
                  type={'dateTime'}
                />
              </span>
            </div>
            <div className="flex flex-row items-center space-y-4">
              <PegLabel id="lblEtcDate" name="etcDate">
                ETC Date
              </PegLabel>
              <span className="w-4/12">
                <PegDatePicker
                  id={'txtEtcDate'}
                  value={moment(vesselDetails?.ETC, 'DD-MM-YYYY').format()}
                  onChange={(event: any, key: any) => this.onUpdateValue(event, 'etcDate')}
                  name={'etcDate'}
                  type={'dateTime'}
                />
              </span>
            </div>
            <div className="flex flex-row items-center space-y-4">
              <PegLabel id="lblEtaDate" name="etaDate">
                ETD Date
              </PegLabel>
              <span className="w-4/12">
                <PegDatePicker
                  id={'txtEtdDate'}
                  value={moment(vesselDetails?.ETB, 'DD-MM-YYYY').format()}
                  onChange={(event: any, key: any) => this.onUpdateValue(event, 'etdDate')}
                  name={'etdDate'}
                  type={'dateTime'}
                />
              </span>
            </div>


            <div className="flex flex-row items-center space-y-4 ">
              <PegLabel id="lblVesselAdditionalServices" name="vesselAdditionalServices">
                Additional Services
              </PegLabel>
              <span className="w-8/12 flex flex-col justify-start space-y-2 border-medium pt-4">

                <PegCheckbox
                  id={'chkCargoOperation'}
                  name={'cargoOperation'}
                  label={'Cargo'}
                  onChange={(event: any, key: any) => this.onAddtionalServiceChange(event, 'cargoOperation')}
                  checked={false}
                  value={"true"} />

                <PegCheckbox
                  id={'chkCrewChange'}
                  name={'crewChange'}
                  label={'Crew Change'}
                  onChange={(event: any, key: any) => this.onAddtionalServiceChange(event, 'crewChange')}
                  checked={false}
                  value={"true"} />

                <PegCheckbox
                  id={'chkshipSpares'}
                  name={'shipSpares'}
                  label={'Ship Spares'}
                  onChange={(event: any, key: any) => this.onAddtionalServiceChange(event, 'shipSpares')}
                  checked={false}
                  value={"true"} />
              </span>
            </div>

            <div className="flex flex-row items-center space-y-4">
              <PegLabel id="lblPreviousPort" name="previousPort">
                Previous Port
              </PegLabel>
              <div className="w-4/12">
                <Dropdown
                  id={'drpPreviousPort'}
                  type={'text'}
                  defaultValue={vesselDetails?.PrevPortName}
                  placeHolder={'Select Previous Port'}
                  isLookup={true}
                  dropdownLabel={'name'}
                  dropdownValue={'name'}
                  appendParams={false}
                  isMandatory={false}
                  getFormattedLabel={true}
                  scrollToTop={true}
                  showErrorMessage={'mandatory field'}
                  url={`/mdm-port?searchText=`}
                  onChangeDropdown={(event: any, key: any) => this.onUpdateValue(event, 'previousPort')}
                />
              </div>
            </div>


            <div className="flex flex-row items-center space-y-4">
              <PegLabel id="lblNextPort" name="nextPort">
                Next Port
              </PegLabel>
              <div className="w-4/12">
                <Dropdown
                  id={'drpNextPort'}
                  type={'text'}
                  defaultValue={vesselDetails?.NextPortName}
                  placeHolder={'Select Next Port'}
                  isLookup={true}
                  dropdownLabel={'name'}
                  dropdownValue={'name'}
                  appendParams={false}
                  isMandatory={false}
                  getFormattedLabel={true}
                  scrollToTop={true}
                  showErrorMessage={'mandatory field'}
                  url={`/mdm-port?searchText=`}
                  onChangeDropdown={(event: any, key: any) => this.onUpdateValue(event, 'nextPort')}
                />
              </div>
            </div>

          </div>

          <div className={'col-span-2 flex-col border-b-2 pb-2 border-light'}>
            <h3 className={'text-primary font-semibold'}>Voyage Details</h3>
          </div>
          <VoyageTableView />
        </div>
      </div >
    );
  }

};

export default withRouter(VesselDetailComponent);
