
import { useEffect, useState } from 'react';
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import InputBox from 'Controls/Input/PegInputBox';
import Dropdown from 'Controls/Dropdown';
import {
  ListOverview,
  Version,
  PriceSummary,
  Party,
  BillingAddress,
  TotalNetPrice,
  TotalRequestedPrice,
  FundsReceived,
  RequestedAmount,
  RequestedPerc,
  BankAccount,
  Id
} from '../price.syles';
import { TiTick, TiTimes } from "react-icons/ti";
import axios from "axios";
import Loader from 'Components/Loader';

type Props = {
  priceSummary: any;
  priceIndex: number;
  bankOptions: any;
  updatePricingSummary: (id: string, index: number, value: any) => void;
  onSelectPriceSummary: (id: number) => void;
};

const List = (props: Props) => {
  const {
    priceSummary,
    updatePricingSummary,
    priceIndex,
    bankOptions
  } = props;
  const [showSummary, setShowSummary] = useState(false)
  const [requestedAmount, setRequestedAmount] = useState(0)
  const [isPosting, setIsPosting] = useState(false)
  const summary = priceSummary[0]
  const inputStyle = {
    border: 'none',
    height: '34px',
    borderRadius: '0px',
    fontSize: '12px'
  }

  const onChangeInputValue = (event: any) => {
    updatePricingSummary(event.target?.id, priceIndex, event.target?.value)
    setRequestedAmount(event.target?.value)
  }

  const onChangeDropdownValue = (event: any, id: any) => {
    updatePricingSummary(id, priceIndex, event)
  }

  const onAccept = (item: any) => {
    setIsPosting(true)
    axios.post(`/AcceptRejectPriceSummary`, {
      "CostSummaryId": item.CostSummaryId,
      "Status": "Accepted"
    }).then(() => {
      setIsPosting(false)
    })
  }

  const onReject = (item: any) => {
    setIsPosting(true)
    axios.post(`/AcceptRejectPriceSummary`, {
      "CostSummaryId": item.CostSummaryId,
      "Status": "Accepted"
    }).then(() => {
      setIsPosting(false)
    })
  }

  const onSelectSummary = () => {
    props.onSelectPriceSummary(priceIndex)
  }

  return (
    <div>
      <ListOverview>
        <Id>
          <input
            type="checkbox"
            onClick={() => onSelectSummary()}
            style={{ cursor: 'pointer', marginRight: '8px' }} />
        </Id>
        <Version >{summary?.Version}</Version>
        <PriceSummary>
          {
            !showSummary ?
              <AiOutlineDown
                onClick={() => setShowSummary(!showSummary)}
                className="icon-style-xs"
              />
              :
              <AiOutlineUp
                onClick={() => setShowSummary(!showSummary)}
                className="icon-style-xs"
              />
          }
        </PriceSummary>
        <Party >{summary?.BillingPartyName}</Party>
        <BillingAddress >{summary?.BillingAddress ? summary?.BillingAddress : '-'}</BillingAddress>
        <TotalNetPrice >{summary?.TotalNetPrice ? summary?.TotalNetPrice : '-'}</TotalNetPrice>
        <TotalRequestedPrice >{summary?.TotalRequestedAmount ? summary?.TotalRequestedAmount : '-'}</TotalRequestedPrice>
        <FundsReceived >{summary?.FundsReceived ? summary?.FundsReceived : '-'}</FundsReceived>
        <RequestedAmount >
          <InputBox
            defaultValue={summary.RequestedAmount ? summary.RequestedAmount : 0}
            id="requestedAmount"
            type={'number'}
            placeholder={'Requested Amount'}
            onChange={onChangeInputValue}
            style={inputStyle}
          /></RequestedAmount>
        <RequestedPerc >
          {((requestedAmount / summary?.TotalNetPrice) * 100).toFixed(2)}
        </RequestedPerc>
        <BankAccount>
          <Dropdown
            id="bankAccount"
            onChangeDropdown={onChangeDropdownValue}
            isLookup={false}
            dropdownOptions={bankOptions}
            dropdownLabel={'BankName'}
            dropdownValue={'BankCode'}
            placeHolder="Select Option"
            style={{ width: 199 }}
            isMenuPortalTarget={'true'}
            domId={'stickyPosition'}
            removeBorder={true}
          />
        </BankAccount>
      </ListOverview>
      {
        (showSummary && summary.History) &&
        <div>
          {
            summary.History.map((child: any, index: number) => {
              return (
                <ListOverview key={index}>
                  <Id>
                    <input
                      type="checkbox"
                      disabled={true}
                      style={{ cursor: 'pointer', marginRight: '8px' }} />
                  </Id>
                  <Version >{child?.Version}</Version>
                  <PriceSummary>
                    {
                      isPosting ?
                        <Loader
                          size={20} />
                        :
                        <>
                          <TiTick
                            onClick={() => onAccept(child)}
                            style={{
                              height: "24px",
                              width: "24px",
                              cursor: "pointer",
                              marginRight: "8px",
                              color: 'green'
                            }} />
                          <TiTimes
                            onClick={() => onReject(child)}
                            style={{
                              height: "24px",
                              width: "24px",
                              cursor: "pointer",
                              color: 'red'
                            }} />
                        </>
                    }

                  </PriceSummary>
                  <Party />
                  <BillingAddress >{child?.BillingAddress ? child?.BillingAddress : '-'}</BillingAddress>
                  <TotalNetPrice >{child?.TotalNetPrice ? child?.TotalNetPrice : '-'}</TotalNetPrice>
                  <TotalRequestedPrice >{child?.totalRequestedAmount ? child?.totalRequestedAmount : '-'}</TotalRequestedPrice>
                  <FundsReceived >{child?.FundsReceived ? child?.FundsReceived : '-'}</FundsReceived>
                  <RequestedAmount />
                  <RequestedPerc />
                  <BankAccount />
                </ListOverview>
              )
            })
          }
        </div>
      }
    </div>

  )
};

export default List;

