import { IChargeName, IExchangeRate } from 'Model/Common/types';
import { ICharge } from 'Model/Job/JobCharge';
import { ITemplate } from 'Model/Job/types';
import { ExchangeRateLookupEntity } from 'Model/Master/ExchangeRateLookupEntity';
import axios from 'axios';
import { useEffect, useRef } from 'react';
import { Zoom, toast } from 'react-toastify';

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}


export function shouldRenderOperatingIncome(columns: any) {
  if (columns['OPERATING_INCOME']?.isActive) {
    return true
  }
}

export function shouldRenderRevenue(columns: any) {

}

export function shouldRenderCost(columns: any) {

}

export function shouldRenderServRequest(columns: any) {
  if (columns['SR_NO']?.isActive || columns['SR_STATUS']?.isActive ||
    columns['SERVICE_STATUS']?.isActive) {
    return true
  }
}

export function shouldRenderBilling(columns: any) {
  if (columns['BILLING_STATUS']?.isActive || columns['BILLING_METHOD']?.isActive ||
    columns['PAYMENT_TERM']?.isActive || columns['SALES_INVOICE']?.isActive) {
    return true
  }
}

export function getExchangeRates(list: ExchangeRateLookupEntity[], fromCurrency: string, targetCurrency: string): IExchangeRate | null {
  let rateObj = null;
  if (list) {
    list.forEach((item: any) => {
      if (item.FromCurrencyCode === fromCurrency && item.ToCurrencyCode === targetCurrency) {
        rateObj = item
      }
      if (targetCurrency === fromCurrency) {
        rateObj = {
          ExchangeRate: 1,
          FromCurrencyCode: fromCurrency,
          ToCurrencyCode: targetCurrency
        } as IExchangeRate;
      }
    })
  }
  return rateObj
}
// in case of service template making id as null to avoid duplication
export function filterDuplicate(isDuplicate: any, product: any) {
  if (isDuplicate) {
    product?.servicesAndCharges.forEach((item: any) => {
      let { services, charges } = item
      services.Id = null;
      charges.forEach((charge: any) => {
        charge.Id = null
      })
      return {
        ...item,
        services: {
          ...services
        },
        charges: [...charges]
      };
    })
    return product;
  }
}

// duplicate template obj 

export function duplicateTemplate(template: any) {
  let productList = template.BusinessProducts.map((product: any) => {
    return {
      ...product,
      ServiceTemplateFk: null,
      BusinessProductId: null
    }
  })
  let visibility = template.ServiceTemplateVisibility.map((visibility: any) => {
    return {
      ...visibility,
      ServiceTemplateFk: null,
      Id: null
    }
  })
  return {
    ...template,
    ServiceTemplateDetails: {
      ...template.ServiceTemplateDetails,
      ServiceTemplateId: null,
    },
    BusinessProducts: productList,
    ServiceTemplateVisibility: visibility
  }
}

export function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const useDisableIsRendering = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

export function checkforNonNegativeValue(e: any) {
  if (!/^[0-9]\d*(\.\d+)?$/.test(e.target.value)) {
    e.target.value = "";
  }
}

export function checkforNonZeroValue(e: any, allowZero?: boolean) {
  if (!/^[1-9][0-9]*$/.test(e.target.value)) {
    e.target.value = allowZero ? e.target.value : e.target.value.replace(/^0/, "");
  }
}

export const showToast = (msg: any, type: string, autoClose: number, position: any, theme: any) => {

  const toastConfig = {
    autoClose: autoClose === null ? 2000 : autoClose,
    position: position,
    style: { width: '500px', height: 'max-content' },
    theme: theme ? theme : 'light',
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Zoom
  }

  if (type === "success") {
    toast.success(msg, toastConfig);
  } else if (type === "error") {
    toast.error(msg, toastConfig);
  } else if (type === "info") {
    toast.info(msg, toastConfig);
  }
};


export const validateTemplate = async (templates: ITemplate[], companyCode: string, customerCode: string, isMaster = false): Promise<any> => {
  const checkCustomerCode = isMaster ? true : customerCode

  if (!(templates && companyCode && checkCustomerCode)) {
    throw new Error('Invalid validate template parameters');
  }

  const dolphinErrors: any[] = [];
  const servicesToAdd: ITemplate[] = [];

  await Promise.all(templates.map(async (template: ITemplate) => {

    const { services, charges } = template;

    let validatedCharges: ICharge[] = []

    try {

      const { data: chargesFromAPI } = await axios.get<IChargeName[]>(`/getCharges?company-code=${companyCode}&service-code=${services?.Code}&includeDolphinMapping=true&partyCode=${customerCode}`);

      charges.forEach((charge: ICharge) => {

        const matchedCharge = chargesFromAPI.find(i => i.code === charge.Code)
        const hasMissingDolphinCodes = !(matchedCharge?.revenueServiceCode && matchedCharge?.costServiceCode)

        if (hasMissingDolphinCodes) {

          dolphinErrors.push({
            service: services,
            charge: charge,
            message: `${services.Name} > ${charge.Name} - Note that the charge imported has missing dolphin codes. Please contact finance/accounts to check and resolve`
          });

        } else {
          validatedCharges.push({ ...charge })
        }

      });


    } catch (error) {
      console.error('Error occurred:', error);
    } finally {
      if (validatedCharges?.length) {
        servicesToAdd.push(
          {
            services,
            charges: validatedCharges
          });
      }
    }
  }));

  return { errors: dolphinErrors, services: servicesToAdd };
}

//Convert number as comma seprated string
export function convertToFormattedString(value: number | null | undefined, decimal: number = 2): string {

  if (value == null || isNaN(value)) {
    return "0".padEnd(decimal, "0")
  }

  const numString = value.toFixed(decimal);
  const [beforeDecimal, afterDecimal] = numString.split(".");
  const withCommas = beforeDecimal.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return `${withCommas}.${afterDecimal}`;
}

export const formatAddress = (address: string, city: string, country: string): string =>
  [address, city, country].filter(Boolean).join(", ");

const bpCodeDelimiter = "::";
export function formatBillingPartyCode(code: string, addressId: string) {
  return code + bpCodeDelimiter + addressId;
}

export function splitBillingPartyCode(code: string) {
  return code.split(bpCodeDelimiter);
}

export function getBillingPartyName(bp: any) {
  return bp.Node ? bp.Node : bp.commonName;
}