/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useCallback } from 'react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import axios from 'axios';
import styled from 'styled-components'
import mdmAxios from 'Api/baseUrl';


// eslint-disable-next-line no-unused-vars

const CancelToken = axios.CancelToken;
let cancel: any;

const PegDropdown = (props: any) => {
  const {
    customComponents,
    onChangeDropdown,
    id,
    type,
    index,
    isLookup,
    style,
    url,
    dropdownLabel,
    dropdownValue,
    appendParams,
    params,
    dropdownMap,
    dropdownType,
    placeHolder,
    useDebounce,
    defaultValue,
    dropdownOptions,
    isMandatory,
    removeBorder,
    disabled,
    scrollToTop,
    defaultInputValue,
    isMenuPortalTarget,
    domId,
    value,
    isSearchable,
    removeIsClear,
    dropdownLabelKeys,
    baseUrlIsMdm,
    isMulti,
    appendOptions,
    dataTestId,
    postFetch,
    onClickDropdown,
    dropdownStyle,
    isEdit,
    isLoading,
    isDynamicResult,
    dynamicURL
  } = props;

  const [showError, setShowError] = useState(false);
  const [dropdownData, setDropdownData] = useState([]);
  const [optionMessage, setOptionMessage] = useState('Search');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const apiType = baseUrlIsMdm ? mdmAxios : axios;

  useEffect(() => {
    // this line is to reduce the memory leak in hooks 
    let isMounted = true;
    /* istanbul ignore next */
    if (!isLookup && url && !appendParams) {
      if (isMounted) fetchAxios();
    }
    return () => { isMounted = false };
  }, [isLookup]);

  useEffect(() => {
    if (appendParams && params) {
      /* istanbul ignore next */
      fetchAxios(params)
    }
  }, [params])

  useEffect(() => {
    if (dropdownOptions) {
      setDropdownData(dropdownOptions)
    }
  }, [dropdownOptions])

  /* istanbul ignore next */
  const fetchAxios = (param: any = null) => {
    let dropdownUrl = url;
    if (param) {
      dropdownUrl = `${url}${params}`
    }


    const axiosRequest = async () => {
      await apiType
        .get(dropdownUrl, {
          cancelToken: new CancelToken(function executor(c) {
            cancel = c;
          }),
        })
        .then((response) => {
          if (response && response.data) {
            if (dropdownMap) {
              let responseObject = response.data
              if (dropdownType === 'service.name') {
                setDropdownData(responseObject[0] && responseObject[0][dropdownMap]);
              }
            } else {
              setDropdownData(response.data);
              setOptionMessage('Search')
            }
          }
        });
    };
    axiosRequest();
  };

  /* istanbul ignore next */
  const loadDropdownOptions = useCallback(
    (event: any) => {
      if (cancel !== undefined) {
        cancel();
      }
      if (useDebounce) {
        if (event.length > 2) {
          return apiType
            .get(`${url}${event ? event : ''}`, {
              cancelToken: new CancelToken(function executor(c) {
                cancel = c;
              }),
            })
            .then(async (res) => {
              if (isDynamicResult) {
                if (res.data.length === 0) {
                  const { data } = await apiType
                    .get(`${dynamicURL}${event ? event : ''}`, {
                      cancelToken: new CancelToken(function executor(c) {
                        cancel = c;
                      })
                    })

                  return data
                }
              }

              if (dropdownMap) {
              } else if (!res.data) {
                setOptionMessage('No result')
              } else {
                setOptionMessage('Search')
                if (baseUrlIsMdm) {
                  return res.data.item.map((x: any) => x.LkpParty)
                }

                return res.data;

              }
            });
        } else {
          return new Promise((resolve) => {
            resolve([]);
          });
        }
      } else {
        return apiType
          .get(`${url}${event ? event : ''}`, {
            cancelToken: new CancelToken(function executor(c) {
              cancel = c;
            }),
          })
          .then((res) => {
            if (dropdownMap) {
              return res.data[dropdownMap]
            } else {
              if (baseUrlIsMdm) {
                return res.data.item.map((x: any) => x.LkpParty);
              }
              return res.data
            }
          });
      }
    },
    [url]
  );

  let border = '1px solid #d8d8d';
  /* istanbul ignore next */
  if (isMulti) {
    if (isMandatory && !disabled) {
      border = '1px solid red'
      if (value.length > 0) {
        border = '1px solid #d8d8d'
      }
    }
  } else {
    if (removeBorder) {
      border = '0px solid'
      if (isMandatory && !defaultValue) {
        border = '1px solid red';
      }
    } else {
      border = '1px solid #d8d8d';
      if (isMandatory && !defaultValue) {
        border = '1px solid red';
      }
    }
  }

  let menuPortalStyle = {};
  let controlStyle = {};

  if (dropdownStyle?.menuPortal) {
    menuPortalStyle = dropdownStyle.menuPortal;
  }
  if (dropdownStyle?.control) {
    controlStyle = dropdownStyle.control;
  }

  /* istanbul ignore next */
  const customStyles = {
    menuPortal: (base: any) => ({ ...base, ...menuPortalStyle, zIndex: 999, }),
    control: (provided: any) => ({
      ...provided,
      ...style,
      minHeight: '28px',
      height: (isMandatory && type === 'serviceTemplate') ?
        '28px' :
        (isMandatory || type === 'charge') ? '33px' : isMulti ? 'max-content' : '28px',
      fontSize: '12px',
      border: border,
      borderRadius: 'none',
      whiteSpace: 'nowrap',
      "&:focus": {
        borderColor: "none"
      },
      "&:hover": {
        borderColor: defaultValue ? 'none' : '1px solid red'
      },
      ...controlStyle
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      height: (isMandatory && type === 'serviceTemplate')
        ? '28px'
        : isMandatory ? '33px' : isMulti ? 'max-content' : '28px',
      padding: '0 6px',
      fontSize: '12px',
      alignItems: (isMandatory && type === 'serviceTemplate') ? 'baseline' : 'center'
    }),
    placeholder: (defaultStyles: any) => {
      return {
        ...defaultStyles,
        color: '#000',
      }
    },
    option: (styles: any) => {
      return {
        ...styles,
        fontSize: '12px'
      };
    },

    input: (provided: any) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      height: isMandatory ? '33px' : '28px',
      marginTop: '0px'
    }),
  };

  const handleFocus = () => {
    /* istanbul ignore next */
    setShowError(true);
  }

  const handleBlur = () => {
    /* istanbul ignore next */
    setShowError(false);
  }

  /* istanbul ignore next */
  const onChangeValue = (event: any) => {
    setShowError(false)
    onChangeDropdown && onChangeDropdown(event, id)
  }

  let placeHolderView = placeHolder
  if (defaultValue) {
    placeHolderView = (<FlexView>
      <Value>{defaultValue}</Value>
    </FlexView>)
  }

  let menuPortalTarget = document.getElementById('serviceGrid');
  if (isMenuPortalTarget) {
    menuPortalTarget = document.getElementById(domId);
  }
  if (isLookup) {
    /* istanbul ignore next */
    return (
      <RelativeView style={props.style ? props.style : {}} data-testid={`${dataTestId}Wrapper`}>
        <AsyncSelect
          classNamePrefix="mySelect"
          getOptionLabel={
            (options: any) => {
              if (appendOptions) {
                let label = '';
                dropdownLabelKeys?.forEach((key: any) => {
                  label = `${label} ${options[key]}`
                })
                return label;
              }
              return options[dropdownLabel]
            }
          }
          getOptionValue={(options: any) => options[dropdownValue]}
          loadingMessage={() => 'Loading'}
          noOptionsMessage={() => optionMessage}
          cacheOptions
          isClearable={!removeIsClear}
          isSearchable={isSearchable}
          className="menu-outer-top"
          loadOptions={async (event: any) => await loadDropdownOptions(event)}
          onChange={(event) => onChangeValue(event)}
          menuPlacement={scrollToTop ? 'top' : 'auto'}
          placeholder={placeHolderView}
          styles={customStyles}
          onFocus={handleFocus}
          onBlur={handleBlur}
          menuShouldBlockScroll={true}
          menuPortalTarget={menuPortalTarget}
          isDisabled={disabled}
          value={value}
          isMulti={isMulti}
          defaultInputValue={defaultInputValue}
          data-testid={dataTestId}
          defaultValue={defaultValue}
        />
      </RelativeView>
    );
  } else {
    /* istanbul ignore next */
    return (
      <RelativeView style={props.style ? props.style : {}} data-testid={`${dataTestId}Wrapper`}>
        <Select
          isLoading={isLoading}
          components={customComponents ? customComponents : {}}
          value={isMulti ?
            value :
            (dropdownData && dropdownData.length) && defaultValue ? dropdownData?.find(obj => obj[dropdownValue] === defaultValue) : value ? value : defaultValue}
          classNamePrefix="mySelect"
          styles={customStyles}
          isClearable={!removeIsClear}
          onFocus={handleFocus}
          onBlur={handleBlur}
          options={dropdownData}
          className="menu-outer-top"
          loadingMessage={() => 'Loading'}
          noOptionsMessage={() => optionMessage}
          onChange={(event) => (onChangeDropdown ? onChangeDropdown(event, id, type, index) : '')}
          getOptionLabel={(options: any) => options[dropdownLabel]}
          getOptionValue={(options: any) => options[dropdownValue]}
          menuPlacement={scrollToTop ? 'top' : 'auto'}
          menuShouldBlockScroll={true}
          placeholder={postFetch && defaultValue ? defaultValue : placeHolder}
          menuPortalTarget={menuPortalTarget}
          isDisabled={disabled}
          defaultInputValue={defaultInputValue}
          isMulti={isMulti}
          data-testid={dataTestId}
          onMenuOpen={() => onClickDropdown ? onClickDropdown() : null}
          defaultValue={defaultValue}
        />
      </RelativeView>
    );
  }
};

export default PegDropdown;

const RelativeView = styled.div`
  position: relative;
`

const FlexView = styled.div`
  display: flex;
`
const Value = styled.span`
  font-size: 12px;
  color: #000;
`

