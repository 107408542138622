
import React from 'react';

interface IInputProps {
  id: string;
  onChange: any;
  name?: string;
  disabled?: boolean;
  type?: string;
  index?: number;
  placeholder?: string;
  value?: any;
  defaultValue?: any;
  style?: object;
  isMandatory?: boolean;
  ref?: any;
  onKeyUp?: any;
  onKeyPress?: any;
  onInput?: any;
  onBlur?: any;
  onFocus?: any;
  readOnly?: any;
  nonNegativeNumber?: any;
  nonZeroNumber?: any;
  min?: any;
  max?: any;
  step?: any;
  textAlign?: any;
  checkZeroValue?: any;
  validator?: (value: any) => boolean;
}

export default function PegInputBox(props: IInputProps) {
  let {
    id,
    onChange,
    name,
    disabled,
    type,
    placeholder,
    style,
    defaultValue,
    isMandatory,
    value,
    onKeyUp,
    onKeyPress,
    onInput,
    onBlur,
    onFocus,
    ref,
    readOnly,
    min,
    max,
    step,
    nonZeroNumber,
    nonNegativeNumber,
    textAlign,
    checkZeroValue,
    validator
  } = props;

  let defaultedValue = type === 'number' ? value?.toString() ? value : defaultValue : value ? value : defaultValue
  if (validator && !validator(value)) {
    style = { ...style, border: '1px solid red' }
  }
  if (isMandatory) {
    if (checkZeroValue) {
      if (isNaN(defaultedValue) || defaultedValue === null || defaultedValue === undefined || defaultedValue === '') {
        style = { ...style, border: '1px solid red' }
      }
    } else {
      if (!defaultedValue) {
        style = { ...style, border: '1px solid red' }
      }
      else {
        if (nonNegativeNumber && nonZeroNumber && Number(defaultedValue) <= 0) {
          style = { ...style, border: '1px solid red' }
        }
        else if (nonNegativeNumber && Number(defaultedValue) < 0) {
          style = { ...style, border: '1px solid red' }
        }
        else if (nonZeroNumber && Number(defaultedValue) == 0) {
          style = { ...style, border: '1px solid red' }
        }
      }
    }

  } else {
    if (nonNegativeNumber && Number(defaultedValue) < 0) {
      style = { ...style, border: '1px solid red' }
    }
  }

  return (
    <input
      title=''
      id={id}
      style={style ? { ...style, textAlign: textAlign ? textAlign : 'center' } : { textAlign: textAlign ? textAlign : 'center' }}
      type={type}
      className={'e-input'}
      onChange={onChange}
      onKeyUp={onKeyUp}
      onKeyPress={onKeyPress}
      name={name}
      value={defaultedValue}
      disabled={disabled}
      placeholder={placeholder}
      step={step || 1}
      // defaultValue={defaultedValue}
      ref={ref}
      readOnly={readOnly}
      min={min}
      max={max}
      onInput={onInput}
      onBlur={onBlur}
      onFocus={onFocus}
      pattern='/^[-+]?[0-9]+\.[0-9]+$/'
    />
  );
}
