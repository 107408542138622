import React from 'react';
import { useHistory } from "react-router-dom"
import { FcApproval } from "react-icons/fc";
import PegButton from '../../Controls/Button/PegButton';

const Http200 = () => {
    const history = useHistory();
    return (
        <div className={"flex flex-col items-center m-28"}>
            <div className={"flex flex-row items-center "}>
                <div className={"w-48"}>
                    <FcApproval size="sm" />
                </div>
            </div>
            <h3 className={"font-semibold text-3xl text-black mb-8 mt-8"}>Quotation Created Successfully !</h3>
            <div className={"mt-8"}>
                <PegButton
                    id="btnNext"
                    variant="OutlinePrimary"
                    type="button"
                    onClick={() => history.push('/')}>
                    Home
                </PegButton>
            </div>
        </div>
    )
}

export default Http200;