import { useCallback, useEffect, useMemo, useState } from 'react';
import Dropdown from 'Controls/Dropdown';
import ToolTip from 'Controls/ToolTip/ToolTip';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import _ from 'lodash'
import InputBox from 'Controls/Input/PegInputBox';
import { ReactComponent as DeleteIcon } from 'Assets/delete-item.svg'
import {
  OverviewHeader,
  // list
  ID,
  Delete,
  Add,
  Expand,
  Detail,
  Name,
  BillingParty,
  BillingPartyAddress,
  CustomerService,
  //DateRange
  StartDate,
  EndDate,
  //revenue
  Quantity,
  ReceivedQuantity,
  UOM,
  UnitPrice,
  Currency,
  Amount,
  BillingCurrency,
  ExRate,
  Tax,
  SalesTaxAmt,
  SalesInvoice,
  BillAmount,
  AdjBillAmount,
  //cost
  Supplier,
  EstUnitCost,
  EstCurrency,
  EstExRate,
  EstTaxType,
  EstTax,
  EstAmt,
  EstAmtTax,
  IsBillable,
  BillingStatus,
  BillingOptions,
  // sr request
  SrNo,
  SrStatus,
  ServiceStatus,
  OperatingIncome,
  // eye_share
  EsRefNo,
  EsActualCost,
  //credit note
  CreditNote,
  CreditAmt,
  //cash voucher
  IsCashVoucherNeeded,
  VoucherType,
  VoucherNo,
  BackToBack,
  // remarks
  ExternalRemarks,
  BillingRemarks,
  InternalRemarks,
  Red,
  Green,
  ExternallyEdited,
  ActualCostExRate,
  TextFieldContainer
} from 'Containers/JobGrid/Jobs.styles'
import { convertPricesAndTaxes, setChargeValue, setServiceValue, setTariffDetails, formatJobBillingPartyAddress } from 'Utils/JobGrid';
import { CHARGE, SERVICE, TAX_RATE_TYPES, UnitPriceMinimum, VOUCHER_STATUS, VoucherType as VoucherTypeConst } from 'Constants/Constant';
import axios from 'axios';
import useAxiosCached from 'Api/axiosCached';

import { checkforNonNegativeValue, formatBillingPartyCode, showToast, splitBillingPartyCode } from 'Utils/Generic';
import { BiErrorAlt } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import { BiEditAlt } from "react-icons/bi";
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import 'tippy.js/animations/scale-subtle.css';
import PegModal from 'Controls/PegModal';
import { Color } from 'Utils/Color';
import { IBillingParty } from 'Model/Job/types';
import { getCustomOption, getValueContainer } from 'Components/BillingParty/DropdownComponents';
import { Charge } from 'Model/Job/JobCharge';

type Props = {
  service?: any;
  services?: any;
  charge?: any
  type?: any;
  charges: any;
  jobDetails: any;
  serviceIndex: number;
  chargeIndex: number;
  uomList: any[];
  serviceCode?: string;
  billingParties: any[];
  currencyList: any[];
  supplierList?: any[];
  deleteCharge: (serv: any, char: any) => void;
  disableCost?: boolean;
  disableServiceRequest?: boolean;
  disableOperatingIncome?: boolean;
  disableRevenue?: boolean;
  disableBilling?: boolean;
  taxList: any[];
  salesInvoice: any;
  userDetails: any;
  isValidParty: boolean;
  setValidParty: any;
  columnPreference: any;
  isSupplierInvoiceCNGenerated?: boolean;
  onChangeInput: (event: any, id: any, serviceIndex: any, chargeIndex: any) => void;
  onChangeDate: (event: any, id: any, serviceIndex: any, chargeIndex: any) => void;
  onUpdateCharge: (charge: any, serviceIndex: number, chargeIndex: number) => void;
  onShowTariff: (tariff: any) => void;
  onUpdateService: (service: any, serviceIndex: any) => void;
  editCharge: boolean;
  showTariffModal: boolean;
  onTariffModalConfirm: () => void;
  onTariffModalCancel: () => void;
  onTariffModalOpen: () => void;
  setShowTarriffModal: (value: boolean) => void;
  isReadOnly: boolean;
  updateChargeValidation: (validationKey: string, chargeId: string, value: boolean) => void;
  addToEditable: (payload: any) => void;
  undoB2BwhenInvoiced: () => boolean;
  removeFromEditable: (payload: any) => void;
};


const ChargeLine = (props: Props) => {
  const {
    service,
    charge,
    serviceIndex,
    chargeIndex,
    disableCost,
    disableServiceRequest,
    disableOperatingIncome,
    disableRevenue,
    disableBilling,
    uomList,
    taxList,
    billingParties,
    jobDetails,
    onUpdateCharge,
    salesInvoice,
    userDetails,
    setValidParty,
    onShowTariff,
    editCharge,
    showTariffModal,
    onTariffModalConfirm,
    onTariffModalCancel,
    onTariffModalOpen,
    columnPreference,
    charges,
    services,
    isSupplierInvoiceCNGenerated,
    isReadOnly,
    updateChargeValidation,
    addToEditable,
    removeFromEditable,
    undoB2BwhenInvoiced
  } = props;
  const {
    CurrencyDecimalPlaces,
    CurrencyDecimalPlacesAutoCalculation
  } = userDetails

  const axiosCached = useAxiosCached();

  const [customerService, setCustomerService] = useState([])
  const [chargeList, setChargeList] = useState([])
  // const [chargeDisabled, setChargeDisabled] = useState(true);

  const [billingPartiesData, setBillingPartiesData] = useState<any[]>([]);
  const [invalidBillingParty, setInvalidBillingParty] = useState(false);
  const [dolphinCodeValidation, setDolphinCodeValidation] = useState(false);

  const isDisabled = isReadOnly || (charge.BillingStatus !== undefined && charge.BillingStatus !== "") || !editCharge;

  const decimalPlace = CurrencyDecimalPlaces ? parseInt(CurrencyDecimalPlaces) : 2;
  const autoDecimal = CurrencyDecimalPlacesAutoCalculation ? parseInt(CurrencyDecimalPlacesAutoCalculation) : 2;

  const isEdited = services.every((el: any) => el?.service?.IsExternallyEdited === null);

  const isVoucherApproved: boolean = service?.VoucherStatus === VOUCHER_STATUS.APPROVED;
  const isCashVoucherApproved = service.VoucherType === VoucherTypeConst.Cache && isVoucherApproved;
  const isSupplierInvoiceAvailable = services[serviceIndex]?.supplierDetails?.supplierInvoice?.length > 0;
  const isSupplierCreditNoteAvailable = services[serviceIndex]?.supplierDetails?.creditNote?.length > 0;

  //do we still need to consider voucher?
  const disableQuanity = isDisabled || isCashVoucherApproved || service.BackToBack
  //
  const commonLockingCondition = isReadOnly || service.IsFromMaster || isSupplierInvoiceCNGenerated || isCashVoucherApproved

  const disableCostQuantity = commonLockingCondition || !!charge?.IsCostDistributed || service.BackToBack || !editCharge
  const disableCostUOM = commonLockingCondition || !!charge?.IsCostDistributed || service.BackToBack || !editCharge
  const disableActualCost = commonLockingCondition
  const disableUnitCostnTaxType = commonLockingCondition || !editCharge || service.VoucherStatus == VOUCHER_STATUS.APPROVED

  const canDeleteService = !(isDisabled || isSupplierInvoiceCNGenerated || isVoucherApproved);
  // const canDeleteCharge = canDeleteService && (!(isSupplierInvoiceCNGenerated && !!charge.ReceivedQuantity) && !isCashVoucherApproved)
  const canDeleteCharge = (!(isSupplierInvoiceCNGenerated && !!charge.ReceivedQuantity) && !isCashVoucherApproved && !isDisabled)

  const chargeDolphinCodeStyle = dolphinCodeValidation ? {
    border: '1px solid red'
  } : {};

  useEffect(() => {
    if (charge?.TariffDetails &&
      charge?.TariffDetails?.IsActive &&
      charge?.TariffDetails?.IsTariffErrored === false) {
      removeFromEditable(chargeIndex)
    } else {
      addToEditable(chargeIndex)
    }
  }, [charge?.TariffDetails?.IsActive, charge])

  useEffect(() => {
    if (service?.PriceCurrencyCode !== charge?.PriceCurrencyCode) {
      let chargeObj = setChargeValue(charge, jobDetails, 'PriceCurrencyCode', service?.PriceCurrencyCode)
      setChargeValue(charge, jobDetails, 'PriceExchangeRate', service?.PriceExchangeRate)
      onUpdateCharge(chargeObj, serviceIndex, chargeIndex)
    }
  }, [service, service.PriceCurrencyCode])

  useEffect(() => {
    try {
      if (service?.Code && !charge?.Id) {
        axios.get(`/getCharges?company-code=${jobDetails.CompanyCode}&service-code=${service?.Code}&includeDolphinMapping=true&partyCode=${jobDetails.CustomerCode}&jobid=${jobDetails?.LocalJobId}&includeGSSServices=true`).then((response) => {
          setChargeList(response.data)
        })
      }
    }
    catch (err) {
      console.log(err)
    }
  }, [service?.Code])

  useEffect(() => {
    // const asyncFn = async () => {
    if (service?.CostExchangeRate || service?.CostExchangeRate === 0 || service?.CostCurrencyCode !== charge?.CostCurrencyCode) {
      let chargeObj = setChargeValue(charge, jobDetails, 'CostCurrencyCode', service?.CostCurrencyCode)
      chargeObj = setChargeValue(chargeObj, jobDetails, 'CostExchangeRate', service?.CostExchangeRate)
      onUpdateCharge(chargeObj, serviceIndex, chargeIndex)
    }
    // }
    // asyncFn()
  }, [service?.CostExchangeRate, service?.CostCurrencyCode])

  useEffect(() => {
    if (service?.BillingCurrencyCode !== charge?.BillingCurrencyCode) {
      let chargeObj = setChargeValue(charge, jobDetails, 'BillingCurrencyCode', service?.BillingCurrencyCode)
      onUpdateCharge(chargeObj, serviceIndex, chargeIndex)
    }
  }, [service, service.BillingCurrencyCode])

  useEffect(() => {
    if (service?.BillingExchangeRate || service?.BillingExchangeRate === 0) {
      const chargeObj = setChargeValue(charge, jobDetails, 'BillingExchangeRate', service?.BillingExchangeRate)
      onUpdateCharge(chargeObj, serviceIndex, chargeIndex)
    }
  }, [service, service?.BillingExchangeRate])


  useEffect(() => {
    // const asyncFn = async () => {
    if (billingParties && billingParties.length === 1) {
      let chargeObj = setChargeValue(charge, jobDetails, 'billingParty', billingParties[0])
      onUpdateCharge(chargeObj, serviceIndex, chargeIndex)
    }

    let bpData: any[] = [];
    billingParties.forEach((bp: any) => {
      const bpItem = {
        ...bp,
        code: formatBillingPartyCode(bp.code, bp.operationalProcessPartyId),
        operationalProcessPartyId: bp.operationalProcessPartyId
      };

      bpData.push(bpItem);
    })
    if (bpData.length) setBillingPartiesData(bpData);
    // }
    // asyncFn()
  }, [billingParties])

  useEffect(() => {
    // const asyncFn = async () => {
    const {
      onUpdateService
    } = props

    let isBPSetForCharges = charges.some((charge: any) => charge.OperationalProcessPartyFk)

    if (!isBPSetForCharges) {
      let servObj = setServiceValue(service, jobDetails, "billingMethod", "Credit");

      let paymentTerm = jobDetails?.PaymentTermCode ? jobDetails?.PaymentTermCode :
        userDetails?.PaymentTermCode ? userDetails?.PaymentTermCode : '10';

      servObj = setServiceValue(servObj, jobDetails, "paymentMethod", paymentTerm);
      onUpdateService(servObj, serviceIndex)
    }
    // }
    // asyncFn()
  }, [charge.OperationalProcessPartyFk])

  useEffect(() => {
    // const asyncFn = async () => {
    if (billingParties && billingParties.length === 1) {
      getVatTaxDetails(billingParties[0])
    }

    if (!chargeList.length && charge.GSServiceName) {
      getCharges()
    }
    // }
    // asyncFn()
  }, [charge.Name, billingPartiesData])

  useEffect(() => {
    if (chargeList?.length > 0 && charge?.Name) {
      let selectedCharge: any = chargeList?.find((item: any) => charge.Name === item.name)
      setCustomerService(selectedCharge?.GSServices)

      const chargeHasCustomerServices = !!selectedCharge?.GSServices?.length;
      updateChargeValidation("CustomerService", charge.Code, chargeHasCustomerServices)
    }
  }, [chargeList, charge.Name])

  useEffect(() => {
    if (!charge?.Id && !charge.GSServiceName) {
      let defaultCustomerService = null

      if (customerService?.length === 1) {
        defaultCustomerService = customerService[0]
      }

      const chargeObj = setChargeValue(charge, jobDetails, 'customerService', defaultCustomerService)
      onUpdateCharge(chargeObj, serviceIndex, chargeIndex)
    }
  }, [customerService])



  useEffect(() => {

    if (service?.BackToBack) {
      const quantityObj = { target: { id: 'quantity', type: 'number', value: 1 } }
      let chargeObj = setChargeValue(charge, jobDetails, 'quantity', quantityObj)

      const unitPrice = (_.isNil(charge?.TotalEyeshareAmount) && charge?.TotalEyeshareAmount !== 0)
        ? charge?.UnitCost
        : charge?.TotalEyeshareAmount ?? 0;

      chargeObj = setChargeValue(chargeObj, jobDetails, 'unitPrice', unitPrice)

      onUpdateCharge(chargeObj, serviceIndex, chargeIndex)
    }

  }, [charge.TotalEyeshareAmount, charge.UnitCost])


  const styleClass = "text-left text-xs font-medium text-gray-500 tracking-wider bg-gray-50";
  const inputStyle = {
    border: 'none',
    height: '34px',
    borderRadius: '0px',
    fontSize: '12px'
  }

  const {
    operatingIncome,
    estimatedTaxAmount,
    estimatedAmount,
    purchaseTaxAmount,
    billAmount,
    adjBillAmount,
    billTax,
    amountValue,
  } = convertPricesAndTaxes(jobDetails.CurrencyCode, services[serviceIndex], chargeIndex);

  let Obj = setChargeValue(charge, jobDetails, 'OperatingIncomeActual', operatingIncome);

  useEffect(() => {
    // const asyncFn = async () => {
    if (Obj.OperatingIncomeActual) {
      onUpdateCharge(Obj, serviceIndex, chargeIndex)
    }
    // }
    // asyncFn()
  }, [Obj])

  const getVatTaxDetails = (event: any) => {
    if (charge?.MasterCode) {

      const billingPartyCode = event?.code && splitBillingPartyCode(event.code)

      axiosCached(`/jobService-mapVatTaxDetails?operationalProcessFk=${event?.jobOperationalProcessFk}&billingPartyCode=${billingPartyCode[0]}&companyCode=${jobDetails?.CompanyCode}&gacMasterCode=${charge?.MasterCode}`,
        "post",
        (response: any, fromCache: boolean) => {
          const responseData = response?.response?.data;
          const firstVatType = responseData?.[0]?.vatType;

          if (firstVatType !== null && firstVatType !== undefined) {
            let chargeObj = setChargeValue(charge, jobDetails, 'vatType', response.response?.data[0]?.vatType)
            onUpdateCharge(chargeObj, serviceIndex, chargeIndex)
          }
        });
    }
  }

  const onChangeInputValue = useCallback((event: any) => {

    if (event.target.type === 'number') {
      var t = event.target.value;
      event.target.value = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), decimalPlace + 1)) : t;
    }

    if (event.target?.id === 'estUnitCost' || event.target?.id === 'eyeShareCost') {
      const undoB2Bvalue = undoB2BwhenInvoiced()
      if (undoB2Bvalue) return
    }

    let chargeObj = setChargeValue(charge, jobDetails, event.target?.id, event)

    if (chargeObj?.TariffDetails?.IsActive) {
      chargeObj = setTariffDetails(userDetails, chargeObj)
    }

    if (!charge?.IsCostDistributed && event.target?.id == "quantity") {
      chargeObj = setChargeValue(chargeObj, jobDetails, "costQuantity", event)
    }

    onUpdateCharge(chargeObj, serviceIndex, chargeIndex)


  }, [charge])

  useEffect(() => {
    const isCashVoucher = service?.IsCashVoucherNeeded && service?.VoucherType === VoucherTypeConst.Cache
    const zeroTaxRate = taxList?.find((tax: any) => tax.Code === TAX_RATE_TYPES.ZERO_RATE) ?? null
    const isRateSet = !!charge.CostTaxCode

    if (zeroTaxRate && isCashVoucher && !isRateSet) {
      const chargeObj = setChargeValue(charge, jobDetails, "estimateTax", zeroTaxRate)
      onUpdateCharge(chargeObj, serviceIndex, chargeIndex)
    }
  }, [service?.IsCashVoucherNeeded, service?.VoucherType, taxList])


  const onChangeDropdownValue = useCallback((event: any, id: any) => {
    const {
      onUpdateService
    } = props

    if (id === 'billingParty') {
      setValidParty(true);

      if (charge.Name && charge?.MasterCode) {
        getVatTaxDetails(event)
      }

      let isBPSetForCharges = charges.some((charge: any) => charge.OperationalProcessPartyFk)

      if (!isBPSetForCharges || charges.length === 1) {
        if (event.IsCashCustomer && service.BillingMethodCode !== "Cash") {
          let servObj = setServiceValue(service, jobDetails, "billingMethod", "Cash");
          servObj = setServiceValue(servObj, jobDetails, "paymentMethod", "0");
          onUpdateService(servObj, serviceIndex)
        } else {
          let servObj = setServiceValue(service, jobDetails, "billingMethod", "Credit");
          onUpdateService(servObj, serviceIndex)
        }
      } else {
        if (event.operationalProcessPartyId) {
          if ((event.IsCashCustomer && service.BillingMethodCode !== "Cash") || (!event.IsCashCustomer && service.BillingMethodCode === "Cash")) {
            let chargeObj = setChargeValue(charge, jobDetails, id, null)
            onUpdateCharge(chargeObj, serviceIndex, chargeIndex);
            setInvalidBillingParty(true);
            return;
          } else {
            setInvalidBillingParty(false);
          }
        }
      }
    }
    if (id === 'customerService') {
      if (!event) {
        let servObj = setServiceValue(service, jobDetails, id, {
          code: "",
          name: ""
        })
        onUpdateService(servObj, serviceIndex)
      } else {
        let servObj = setServiceValue(service, jobDetails, id, event)
        onUpdateService(servObj, serviceIndex)
      }
    }

    let chargeObj = setChargeValue(charge, jobDetails, id, event)
    if (chargeObj?.TariffDetails?.IsActive) {
      chargeObj = setTariffDetails(userDetails, chargeObj)
    }

    if (id === 'chargeName') {

      const hasMissingDolphinCodes = !(event?.revenueServiceCode && event?.costServiceCode);

      setDolphinCodeValidation(hasMissingDolphinCodes)
      updateChargeValidation("dolphinCode", charge.Code, hasMissingDolphinCodes)

      chargeNameCascadedUpdate(event, chargeObj)
    }

    onUpdateCharge(chargeObj, serviceIndex, chargeIndex)

    if (!charge?.IsCostDistributed && id == "UOM") {
      chargeObj = setChargeValue(chargeObj, jobDetails, "CostUOM", event)
    }

    if (id === 'estimateTax') {
      undoB2BwhenInvoiced()
    }

  }, [charge, service])

  const chargeNameCascadedUpdate = (event: any, charge: any) => {
    let uomDefault: string | null = null;
    let quantityDefault: number | null = null;

    if (event.code == CHARGE.AIR_FREIGHT_FEE && service.Code == SERVICE.AIRFREIGHT) {
      uomDefault = jobDetails.AirFreightUOM;
      quantityDefault = jobDetails.AirFreightQuantity;
    }
    else if (event.code == CHARGE.SEA_FREIGHT_FEE && service.Code == SERVICE.SEAFREIGHT) {
      uomDefault = jobDetails.SeaFreightUOM;
      quantityDefault = jobDetails.SeaFreightQuantity;
    }

    if (uomDefault) {
      let uom = uomList.find(uom => uom.code === uomDefault);
      if (uom) {
        onChangeDropdownValue(uom, 'UOM')
      }
    }
    if (quantityDefault) {
      onChangeInputValue({ target: { id: 'quantity', type: 'number', value: quantityDefault.toString() } });
    }
  }

  const onClickDropdown = useCallback((type: string) => {
    if (type === 'charge') {
      if (!chargeList.length) {
        getCharges()
      }
    }
  }, [chargeList])

  const getCharges = useCallback(() => {
    try {
      axios.get(`/getCharges?company-code=${jobDetails.CompanyCode}&service-code=${service?.Code}&includeDolphinMapping=true&partyCode=${jobDetails.CustomerCode}&jobid=${jobDetails?.LocalJobId}&includeGSSServices=true`).then((response) => {
        setChargeList(response.data)
      })
    }
    catch (err) {
      console.log(err)
    }
  }, [charge])

  const onDeleteCharge = () => {
    if (canDeleteCharge) {
      props.deleteCharge(serviceIndex, chargeIndex)
    }
  }

  //filtering sales invoice based on id in charge
  const salesInvoiceFilter = salesInvoice && salesInvoice.filter((invoice: any) => invoice.jobServiceId === charge.Id);

  const selectedBPAddress = useMemo(() => {
    let selectedBPAddress = null;
    if (charge.OperationalProcessPartyFk) {
      const selectedBillingParty: any = billingParties.find((bp: any) => bp.operationalProcessPartyId === charge.OperationalProcessPartyFk);
      if (selectedBillingParty) {
        selectedBPAddress = formatJobBillingPartyAddress(selectedBillingParty);
      }
    }
    return selectedBPAddress;
  }, [charge.OperationalProcessPartyFk, billingParties]);

  const ValueContainer = getValueContainer<IBillingParty>();
  const CustomOption = getCustomOption<IBillingParty>(formatJobBillingPartyAddress);


  return (
    <OverviewHeader className={styleClass} id="jobChargeView" data-testid="chargeJobGrid">
      <ID>
        {serviceIndex + 1}.{chargeIndex + 1}
      </ID>
      <ToolTip
        id="deleteToolTip"
        content={'Delete'}
        placement={'right'}
        animation={'scale-subtle'}
        arrow={true}
        duration={200}
        delay={[75, 0]}>
        <Delete>
          <DeleteIcon
            // style={(!(isSupplierInvoiceCNGenerated && !!charge.ReceivedQuantity) && !(isReadOnly || (charge.BillingStatus !== undefined && charge.BillingStatus !== ""))) ? { cursor: 'pointer' } : { cursor: 'not-allowed', opacity: 0.5 }}
            style={(!(isSupplierInvoiceCNGenerated && !!charge.ReceivedQuantity) && !isDisabled && !isCashVoucherApproved) ? { cursor: 'pointer' } : { cursor: 'not-allowed', opacity: 0.5 }}
            onClick={onDeleteCharge} data-testid="deleteCharges" />
        </Delete>
      </ToolTip>
      <Add data-testid="addCharges">
        {charge?.TariffDetails?.IsTariffErrored &&
          <>
            <ToolTip
              id="applyTariffToolTip"
              content={charge?.TariffDetails?.ErrorMessage}
              placement={'right'}
              animation={'scale-subtle'}
              arrow={true}
              duration={200}
              delay={[75, 0]}
            >
              <BiErrorAlt
                data-tip
                data-for='errorIcon'
                style={{ color: 'red' }}
                className="icon-style-md" />
            </ToolTip>
          </>}
      </Add>
      <Expand>
        {(
          charge?.TariffDetails?.IsActive
          && !charge?.TariffDetails?.IsTariffErrored) &&
          <BiEditAlt
            onClick={() => onTariffModalOpen()}
            className="icon-style-md" />
        }
      </Expand>
      <Detail>
        {charge?.TariffDetails?.IsActive &&
          <AiOutlineEye
            onClick={() => onShowTariff(charge)}
            className="icon-style-md" />}
      </Detail>
      {service?.IsExternallyEdited === true ?
        <ExternallyEdited /> : !isEdited ? <ExternallyEdited /> : null
      }
      <Name data-testid="chargeName" data-tooltip-id={"chargeName" + serviceIndex + chargeIndex}>
        <Dropdown
          defaultValue={charge.Name}
          postFetch={true}
          id="chargeName"
          dropdownOptions={chargeList}
          onClickDropdown={(type: string) => onClickDropdown('charge')}
          onChangeDropdown={onChangeDropdownValue}
          label={'name'}
          isLookup={false}
          dropdownLabel={'name'}
          dropdownValue={'name'}
          style={{ width: 199, ...chargeDolphinCodeStyle }}
          placeHolder="Select Charge"
          appendParams={false}
          removeBorder={true}
          isMandatory={true}
          removeIsClear={true}
          isMenuPortalTarget={'true'}
          domId={'jobChargeView'}
          disabled={charge.IsFromMaster || isDisabled || isSupplierInvoiceCNGenerated}
          dataTestId={'chargeName'}
        />
        {dolphinCodeValidation && <ReactTooltip
          id={"chargeName" + serviceIndex + chargeIndex}
          place="right"
          variant="error"
        >
          <span>Note that the charge selected has missing dolphin codes.
            <br />Please contact finance/accounts to check and resolve</span>
        </ReactTooltip>}
      </Name>


      {columnPreference['CUSTOMER_SERVICE']?.isActive && <CustomerService>
        {
          charge.Name && customerService?.length > 0 ?
            <Dropdown
              defaultValue={charge.GSServiceName}
              id={'customerService'}
              dropdownOptions={customerService}
              dropdownLabel={'name'}
              dropdownValue={'name'}
              appendParams={false}
              useDebounce={true}
              style={{ width: 198 }}
              removeBorder={true}
              placeHolder="Select customer"
              onChangeDropdown={onChangeDropdownValue}
              isMenuPortalTarget={'true'}
              type="charge"
              domId={'stickyPosition'}
              disabled={isDisabled}
              isMandatory={true}
            />
            :
            <Dropdown
              defaultValue={charge.GSServiceName}
              id={'customerService'}
              dropdownOptions={customerService}
              dropdownLabel={'name'}
              dropdownValue={'name'}
              isLookup={true}
              appendParams={false}
              useDebounce={true}
              style={{ width: 198 }}
              placeHolder="Select customer"
              onChangeDropdown={onChangeDropdownValue}
              isMenuPortalTarget={'true'}
              removeBorder={true}
              type="charge"
              url={`/getAllGssMapping?partyCode=${jobDetails?.CustomerCode}&company-code=${jobDetails.CompanyCode}&searchText=`}
              domId={'stickyPosition'}
              disabled={isDisabled}
            />
        }
      </CustomerService >}
      {jobDetails.ProductCode === 'BP0004' && columnPreference['START_DATE']?.isActive && <StartDate />}
      {jobDetails.ProductCode === 'BP0004' && columnPreference['END_DATE']?.isActive && <EndDate />}



      {
        !disableOperatingIncome && columnPreference['OPERATING_INCOME']?.isActive &&
        <OperatingIncome groupColor={Color.operatingIncome}>
          {operatingIncome < 0 ?
            <Red>
              {Math.abs(operatingIncome).toFixed(autoDecimal)}
            </Red>
            :
            <Green>
              {operatingIncome.toFixed(autoDecimal)}
            </Green>}
        </OperatingIncome>
      }
      {
        !disableRevenue && columnPreference['BILLING_PARTY']?.isActive &&
        <BillingParty
          groupColor={Color.revenue}
          data-testid="billingParty"
          data-tip
          data-tooltip-id={"billingParty" + chargeIndex}>
          <Dropdown
            customComponents={{ Option: CustomOption, ValueContainer }}
            defaultValue={charge.OperationalProcessPartyFk}
            id={'billingParty'}
            type={'charge'}
            dropdownOptions={billingPartiesData}
            dropdownLabel={'commonName'}
            dropdownValue={'operationalProcessPartyId'}
            appendParams={false}
            useDebounce={true}
            style={{ 'backgroundColor': `${Color.revenue}`, width: 199 }}
            dropdownStyle={{ menuPortal: { width: 400 }, control: { boxShadow: "none" } }}
            removeBorder={true}
            isMandatory={true}
            placeHolder="Select party"
            onChangeDropdown={onChangeDropdownValue}
            isMenuPortalTarget={'true'}
            domId={'jobChargeView'}
            removeIsClear={true}
            disabled={isDisabled}
            dataTestId={'billingParty'}
          />
          {invalidBillingParty && <ReactTooltip
            id={"billingParty" + chargeIndex}
            place="right"
            variant="error"
          // isOpen={invalidBillingParty}
          >
            <span>Service cannot have a combination of Cash customer and non cash customer as billing party</span>
          </ReactTooltip>}
        </BillingParty >
      }
      {
        !disableRevenue && columnPreference['BILLING_PARTY_ADDRESS']?.isActive &&
        <BillingPartyAddress
          groupColor={Color.revenue}
          data-tip
          data-tooltip-id={"billingPartyAddress" + serviceIndex}
        >
          {selectedBPAddress}
          {selectedBPAddress && <ReactTooltip
            id={"billingPartyAddress" + serviceIndex}
          >
            <span>{selectedBPAddress}</span>
          </ReactTooltip>}

        </BillingPartyAddress>
      }
      {
        !disableRevenue && <UOM data-test="uom" groupColor={Color.revenue}>
          <Dropdown
            id="UOM"
            defaultValue={charge.UomCode}
            type={'charge'}
            removeIsClear={true}
            label={'code'}
            dropdownOptions={uomList}
            isLookup={false}
            dropdownLabel={'displayName'}
            dropdownValue={'code'}
            appendParams={false}
            style={{ width: 149, 'backgroundColor': `${Color.revenue}` }}
            placeHolder="Select UOM"
            removeBorder={true}
            isMandatory={true}
            onChangeDropdown={onChangeDropdownValue}
            isMenuPortalTarget={'true'}
            domId={'jobChargeView'}
            disabled={isDisabled}
            dataTestId={'uom'}
          />
        </UOM>
      }
      {
        !disableRevenue && <Quantity groupColor={Color.revenue}>
          <InputBox
            value={charge.Quantity}
            type={'number'}
            placeholder={'Enter Qty'}
            onChange={onChangeInputValue}
            id="quantity"
            isMandatory={true}
            style={{ ...inputStyle, 'backgroundColor': `${Color.revenue}` }}
            onInput={(event: any) => {
              checkforNonNegativeValue(event);
            }}
            disabled={disableQuanity}
            min={"0"}
          />
        </Quantity>
      }
      {
        !disableRevenue && columnPreference['UNIT_PRICE']?.isActive &&
        <UnitPrice groupColor={Color.revenue} data-testid="unitPrice">
          <InputBox
            id="unit"
            name={'unitPrice'}
            value={charge.UnitPrice}
            type={'number'}
            step='any'
            isMandatory={true}
            checkZeroValue={!service?.IsBillable}
            nonNegativeNumber={true}
            nonZeroNumber={service?.IsBillable}
            placeholder={'Enter Unit Price'}
            onChange={onChangeInputValue}
            style={{ ...inputStyle, 'backgroundColor': `${Color.revenue}` }}
            validator={(value) => value >= (service?.IsBillable ? UnitPriceMinimum : 0)}
            disabled={isDisabled || service.BackToBack}
          />
        </UnitPrice>
      }
      {
        !disableRevenue && columnPreference['CURRENCY']?.isActive &&
        <Currency groupColor={Color.revenue} >
          <TextFieldContainer groupColor={Color.revenue}>{charge?.PriceCurrencyCode}</TextFieldContainer>
        </Currency>
      }
      {
        !disableRevenue && columnPreference['AMOUNT']?.isActive &&
        <Amount groupColor={Color.revenue} >
          {amountValue ? amountValue.toFixed(autoDecimal) : 0}
        </Amount>
      }
      {
        !disableRevenue && columnPreference['BILLING_CURRENCY']?.isActive &&
        <BillingCurrency groupColor={Color.revenue} >
          <TextFieldContainer groupColor={Color.revenue}>{charge?.BillingCurrencyCode}</TextFieldContainer>
        </BillingCurrency>
      }
      {
        !disableRevenue && columnPreference['EX_RATE']?.isActive &&
        <ExRate groupColor={Color.revenue} >
          {charge?.BillingExchangeRate}
        </ExRate>
      }
      {
        !disableRevenue && columnPreference['TAX_RATE']?.isActive &&
        <Tax groupColor={Color.revenue} >
          <Dropdown style={{ 'backgroundColor': `${Color.revenue}`, width: 149 }}
            defaultValue={charge.PriceTaxCode}
            id="actualTax"
            type={'charge'}
            label={'Name'}
            isLookup={false}
            dropdownOptions={taxList}
            dropdownLabel={'Name'}
            dropdownValue={'Code'}
            appendParams={false}
            placeHolder="Select Tax"
            removeBorder={true}
            onChangeDropdown={onChangeDropdownValue}
            isMenuPortalTarget={'true'}
            domId={'jobChargeView'}
            disabled={isDisabled}
          />
        </Tax>
      }
      {
        !disableRevenue && columnPreference['SALES_TAX_AMT']?.isActive &&
        <SalesTaxAmt groupColor={Color.revenue} >
          {billTax.toFixed(autoDecimal)}
        </SalesTaxAmt>
      }
      {
        !disableRevenue && columnPreference['BILL_AMT']?.isActive &&
        <BillAmount groupColor={Color.revenue} >
          {billAmount ? billAmount.toFixed(autoDecimal) : amountValue}
        </BillAmount>
      }
      {
        !disableRevenue && columnPreference['ADJ_BILL_AMT']?.isActive &&
        <AdjBillAmount groupColor={Color.revenue} >
          {!!charge?.OffsetAmount ? adjBillAmount.toFixed(autoDecimal) : ''}
        </AdjBillAmount>
      }
      {
        !disableCost && columnPreference['SUPPLIER']?.isActive &&
        <Supplier groupColor={Color.cost} />
      }
      {!disableCost && columnPreference['RECIEVED_QTY']?.isActive && <ReceivedQuantity groupColor={Color.cost}>
        {charge && charge.ReceivedQuantity}
      </ReceivedQuantity>}
      {!disableCost && jobDetails?.ShowCostQuantity && columnPreference['COST_UOM']?.isActive &&
        <UOM data-test="cost-uom" groupColor={Color.cost}>
          <Dropdown
            id="CostUOM"
            defaultValue={charge.CostUomCode}
            type={'charge'}
            removeIsClear={true}
            label={'code'}
            dropdownOptions={uomList}
            isLookup={false}
            dropdownLabel={'displayName'}
            dropdownValue={'code'}
            appendParams={false}
            style={{ width: 149, 'backgroundColor': `${Color.cost}` }}
            placeHolder="Select UOM"
            removeBorder={true}
            isMandatory={true}
            onChangeDropdown={onChangeDropdownValue}
            isMenuPortalTarget={'true'}
            domId={'jobChargeView'}
            disabled={disableCostUOM}
            dataTestId={'cost-uom'}
          />
        </UOM>
      }
      {
        !disableCost && jobDetails?.ShowCostQuantity && columnPreference['COST_QTY']?.isActive && <Quantity groupColor={Color.cost}>
          <InputBox
            value={charge.CostQuantity}
            type={'number'}
            placeholder={'Enter Qty'}
            onChange={onChangeInputValue}
            id="costQuantity"
            isMandatory={true}
            style={{ ...inputStyle, 'backgroundColor': `${Color.cost}` }}
            onInput={(event: any) => {
              checkforNonNegativeValue(event);
            }}
            disabled={disableCostQuantity}
            min={"0"}
          />
        </Quantity>
      }
      {
        !disableCost && columnPreference['UNIT_COST']?.isActive &&
        <EstUnitCost groupColor={Color.cost}>
          <InputBox
            value={charge.UnitCost}
            id="estUnitCost"
            type={'number'}
            min={0}
            checkZeroValue={true}
            isMandatory={true}
            placeholder={'Enter amount'}
            onChange={onChangeInputValue}
            style={{ ...inputStyle, 'backgroundColor': `${Color.cost}` }}
            onInput={(event: any) => {
              checkforNonNegativeValue(event);
            }}
            disabled={disableUnitCostnTaxType}
          />
        </EstUnitCost>
      }
      {
        !disableCost && columnPreference['COST_CURRENCY']?.isActive &&
        <EstCurrency groupColor={Color.cost}>
          <TextFieldContainer groupColor={Color.cost}>{charge?.CostCurrencyCode}</TextFieldContainer>
        </EstCurrency>
      }
      {
        !disableCost && columnPreference['COST_EX_RATE']?.isActive &&
        <EstExRate groupColor={Color.cost}>{charge?.CostExchangeRate}</EstExRate>
      }
      {
        !disableCost && columnPreference['COST_TAX_RATE']?.isActive &&
        <EstTaxType groupColor={Color.cost}>
          <Dropdown
            defaultValue={charge.CostTaxCode}
            id="estimateTax"
            type={'charge'}
            label={'Name'}
            isLookup={false}
            dropdownOptions={taxList}
            dropdownLabel={'Name'}
            dropdownValue={'Code'}
            appendParams={false}
            style={{ 'backgroundColor': `${Color.cost}`, width: 149 }}
            placeHolder="Select Tax Type"
            removeBorder={true}
            onChangeDropdown={onChangeDropdownValue}
            isMenuPortalTarget={'true'}
            domId={'jobChargeView'}
            disabled={disableUnitCostnTaxType}
            dataTestId={'UOM'}
          />
        </EstTaxType >
      }
      {
        !disableCost && columnPreference['PURCHASE_TAX']?.isActive &&
        <EstTax groupColor={Color.cost}>{purchaseTaxAmount.toFixed(autoDecimal)}</EstTax>
      }
      {
        !disableCost && columnPreference['AMT']?.isActive &&
        <EstAmt groupColor={Color.cost}>{estimatedAmount.toFixed(autoDecimal)}</EstAmt>
      }
      {
        !disableCost && columnPreference['AMT_WITH_TAX']?.isActive &&
        <EstAmtTax groupColor={Color.cost}>
          {estimatedTaxAmount.toFixed(autoDecimal)}
        </EstAmtTax>
      }
      {
        !disableCost && columnPreference['SUPPLIER_INVOICE']?.isActive &&
        <EsRefNo groupColor={Color.cost} />
      }
      {
        !disableCost && columnPreference['CREDIT_NOTE']?.isActive &&
        <CreditNote groupColor={Color.cost} />
      }
      {
        !disableCost && columnPreference['CREDIT_AMT']?.isActive &&
        <CreditAmt groupColor={Color.cost}>
          {charge.CreditAmount}
        </CreditAmt>
      }
      {
        !disableCost && columnPreference['ACTUAL_COST']?.isActive &&
        <EsActualCost groupColor={Color.cost}>
          <InputBox
            id="eyeShareCost"
            type={'number'}
            disabled={disableActualCost}
            value={charge.TotalEyeshareAmount}
            placeholder={'Enter amount'}
            onChange={onChangeInputValue}
            style={{ ...inputStyle, 'backgroundColor': `${Color.cost}` }}
            onInput={(event: any) => {
              checkforNonNegativeValue(event);
            }}
          />
        </EsActualCost>
      }
      {
        !disableCost && columnPreference['ACTUAL_COST_EXCHANGE_RATE']?.isActive &&
        <ActualCostExRate groupColor={Color.cost}>
          {charge.EyeShareExchangeRate}
        </ActualCostExRate>
      }
      {!disableCost && columnPreference['IS_VOUCHER_NEEDED']?.isActive && <IsCashVoucherNeeded groupColor={Color.cost} />}
      {!disableCost && columnPreference['VOUCHER_TYPE']?.isActive && <VoucherType groupColor={Color.cost} />}
      {!disableCost && columnPreference['VOUCHER_NUMBER']?.isActive && <VoucherNo groupColor={Color.cost} />}
      {!disableCost && columnPreference['BACK_TO_BACK']?.isActive && <BackToBack groupColor={Color.cost} />}
      {!disableServiceRequest && columnPreference['SR_NO']?.isActive && <SrNo groupColor={Color.serviceRequest} />}
      {!disableServiceRequest && columnPreference['SR_STATUS']?.isActive && <SrStatus groupColor={Color.serviceRequest} />}
      {!disableServiceRequest && columnPreference['SERVICE_STATUS']?.isActive && <ServiceStatus groupColor={Color.serviceRequest} />}
      {
        !disableBilling && columnPreference['IS_BILLABLE']?.isActive &&
        <IsBillable groupColor={Color.billing}>
        </IsBillable>
      }
      {
        !disableBilling && columnPreference['BILLING_STATUS']?.isActive &&
        <BillingStatus groupColor={Color.billing}>
          {charge?.BillingStatus}
        </BillingStatus>
      }
      {!disableBilling && columnPreference['BILLING_METHOD']?.isActive && <BillingOptions groupColor={Color.billing} />}
      {!disableBilling && columnPreference['PAYMENT_TERM']?.isActive && <BillingOptions groupColor={Color.billing} />}
      {
        !disableBilling && columnPreference['SALES_INVOICE']?.isActive &&
        <SalesInvoice groupColor={Color.billing}>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {salesInvoiceFilter && salesInvoiceFilter.map(((el: any, index: any) => {
              return (
                <a style={{ color: '#0645AD' }}
                  href={el.InvoiceLink}
                  target="_blank" rel="noreferrer" key={index}>
                  {
                    el?.InvoiceReference ?
                      <span>
                        {el.InvoiceReference}{index === salesInvoiceFilter.length - 1 ? "" : ","}
                      </span>
                      :
                      ""
                  }
                </a>
              )
            }))}
          </div>
        </SalesInvoice>
      }
      {
        columnPreference['INTERNAL_REMARKS']?.isActive &&
        <InternalRemarks>
          <InputBox
            id="remarks"
            value={charge.InternalRemarks}
            placeholder={'Internal Remarks'}
            onChange={onChangeInputValue}
            style={inputStyle}
            max={1000}
            disabled={isReadOnly}
          />
        </InternalRemarks>
      }
      {
        columnPreference['EXTERNAL_REMARKS']?.isActive &&
        <ExternalRemarks>
          <InputBox
            id="externalRemarks"
            value={charge.ExternalRemarks}
            placeholder={'External Remarks'}
            onChange={onChangeInputValue}
            style={inputStyle}
            max={1000}
            disabled={isReadOnly}
          />
        </ExternalRemarks>
      }
      {columnPreference['BILLING_REMARKS']?.isActive && <BillingRemarks isService />}
      <div>
        <PegModal
          isOpen={showTariffModal}
          closeModal={onTariffModalCancel}
          tertiaryAction={onTariffModalConfirm}
          disableHeader={true}
          isCenter={true}
          modalTitle={"Confirm"}
          tertiaryMsg={"No"}
          buttonText={"Yes"}
          showTemplate={true}
        >
          <div style={{ margin: '25px' }}>{`You are about to make changes to tariffs. Please note that tariffs for one or more lines will be overridden!`}</div>
        </PegModal>
      </div>
    </OverviewHeader >
  )
};

export default ChargeLine;