import React from 'react';
import CargoInfo from './Components/CargoInfo';
import { Bulk, IBulk } from 'Model/Order/BulkCargo';
import { Package, IPackage } from 'Model/Order/Package';
import { Container, IContainer } from 'Model/Order/Container';
import { Overview } from './cargo.styles';
import { CargoDetail } from 'Model/Order/CargoDetail';
import {
  setCargoDetailsValue,
  setPackageValue,
  setBulkValue,
  setContainerValue
} from 'Utils/cargo'
import BulkDetails from './Components/BulkDetails/BulkTableView';
import ContainerDetails from './Components/ContainerDetails/ContainerTableView';
import PackageDetails from './Components/PackageDetails/PackageTableView';

type IState = {
  selectedRoute?: any;
  selectedTabOptions?: any[];
  bulkObject: IBulk,
  containerObject: IContainer,
  packageObject: IPackage,
  cargoDetails: any;
};

type IProps = {
  cargoDetails: any;
  cargoInfoValidation?: any;
  bulkDetailsValidation?: any;
  containerValidation?: any;
  packageDetailsValidation?: any;
  updateCargoDetails: (cargoDetails: CargoDetail) => void;
  addContainerSection: (containerArray: any) => void;
  deleteContainerSection: (containerArray: any) => void;
  addPackageSection: (packageArray: any) => void;
  deletePackageSection: (packageArray: any) => void;
  addBulkSection: (bulkArray: any) => void;
  deleteBulkSection: (bulkArray: any) => void;
};

class CargoDetails extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      cargoDetails: new CargoDetail(),
      bulkObject: new Bulk(),
      containerObject: new Container(),
      packageObject: new Package()
    };
  }

  updateCargo = (key: string, event: any) => {
    let {
      cargoDetails,
      updateCargoDetails
    } = this.props
    let cargoObject = setCargoDetailsValue(cargoDetails, key, event)
    updateCargoDetails(cargoObject)
  }

  addPackage = () => {
    let {
      packageObject
    } = this.state;
    let {
      addPackageSection,
      cargoDetails
    } = this.props
    if (cargoDetails?.PackageDetail) {
      packageObject = {
        ...packageObject,
        PackageId: cargoDetails?.PackageDetail.length + 1
      }
      cargoDetails.PackageDetail.push(packageObject)
      addPackageSection(cargoDetails.PackageDetail)
    }
  }

  deletePackage = (id: number) => {
    let {
      deletePackageSection,
      cargoDetails
    } = this.props
    if (cargoDetails?.PackageDetail) {
      cargoDetails?.PackageDetail.splice(id, 1);
      deletePackageSection(cargoDetails?.PackageDetail)
    }
  }

  updatePackageDetails = (key: string, event: any, id: number) => {
    let {
      cargoDetails,
      updateCargoDetails
    } = this.props
    let updatedPackge = setPackageValue(cargoDetails?.PackageDetail[id], key, event)
    cargoDetails.PackageDetail[id] = updatedPackge;
    updateCargoDetails(cargoDetails)
  }

  addContainers = () => {
    let {
      containerObject
    } = this.state;
    let {
      addContainerSection,
      cargoDetails
    } = this.props
    if (cargoDetails?.ContainerDetail) {
      containerObject = {
        ...containerObject,
        ContainerId: cargoDetails?.ContainerDetail.length + 1
      }
      cargoDetails.ContainerDetail.push(containerObject)
      addContainerSection(cargoDetails.ContainerDetail)
    }
  }

  deleteContainers = (id: number) => {
    let {
      deleteContainerSection,
      cargoDetails
    } = this.props
    if (cargoDetails?.ContainerDetail) {
      cargoDetails?.ContainerDetail.splice(id, 1);
      deleteContainerSection(cargoDetails.ContainerDetail)
    }
  }

  updateContainerDetails = (key: string, event: any, id: number) => {
    let {
      cargoDetails,
      updateCargoDetails
    } = this.props
    let updatedContainerDetail = setContainerValue(cargoDetails?.ContainerDetail[id], key, event)
    cargoDetails.PackageDetail[id] = updatedContainerDetail;
    updateCargoDetails(cargoDetails)
  }

  addBulkDetails = () => {
    let {
      bulkObject
    } = this.state;
    let {
      addBulkSection,
      cargoDetails
    } = this.props
    if (cargoDetails?.BulkCargoDetail) {
      bulkObject = {
        ...bulkObject,
        BulkCargoId: cargoDetails?.BulkCargoDetail.length + 1
      }
      cargoDetails.BulkCargoDetail.push(bulkObject)
      addBulkSection(cargoDetails.BulkCargoDetail)
    }
  }

  deleteBulkDetails = (id: number) => {
    let {
      deleteBulkSection,
      cargoDetails
    } = this.props
    if (cargoDetails?.BulkCargoDetail) {
      cargoDetails?.BulkCargoDetail.splice(id, 1);
      deleteBulkSection(cargoDetails.BulkCargoDetail)
    }
  }

  updateBulkDetails = (key: string, event: any, id: number) => {
    let {
      cargoDetails,
      updateCargoDetails
    } = this.props
    let updatedBulkList = setBulkValue(cargoDetails?.BulkCargoDetail[id], key, event)
    cargoDetails.BulkCargoDetail[id] = updatedBulkList;
    updateCargoDetails(cargoDetails)
  }

  render() {
    const {
      cargoDetails
    } = this.props
    const {
      PackageDetail,
      ContainerDetail,
      BulkCargoDetail
    } = cargoDetails
    return (
      <Overview>
        <CargoInfo
          cargo={cargoDetails}
          onUpdate={(key: string, event: any) => this.updateCargo(key, event)}
        />
        <PackageDetails
          cargo={cargoDetails}
          packageDetails={PackageDetail}
          onDelete={(id: number) => this.deletePackage(id)}
          onUpdate={(key: string, event: any, id: number) => this.updatePackageDetails(key, event, id)}
          onAdd={() => this.addPackage()} />
        <ContainerDetails
          cargo={cargoDetails}
          containerDetails={ContainerDetail}
          onUpdate={(key: string, event: any, id: number) => this.updateContainerDetails(key, event, id)}
          onDelete={(id: number) => this.deleteContainers(id)}
          onAdd={() => this.addContainers()} />
        <BulkDetails
          cargo={cargoDetails}
          bulkDetails={BulkCargoDetail}
          onDelete={(id: number) => this.deleteBulkDetails(id)}
          onUpdate={(key: string, event: any, id: number) => this.updateBulkDetails(key, event, id)}
          onAdd={() => this.addBulkDetails()} />
      </Overview>
    );
  }
}

export default CargoDetails
