import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducers from './Reducers';
import logger from 'redux-logger';


// const middleware = [thunk, logger];
const middleware = [thunk];
const thunkMiddleware = applyMiddleware(...middleware);
const store = createStore(reducers, composeWithDevTools(
    thunkMiddleware
));

export default store;