import React from 'react';
import Lifebuoy from '../../Assets/ReactIcons/Lifebuoy';
import PegButton from '../../Controls/Button/PegButton';

const Http404 = () => {
  return (
    <div className={"flex flex-col items-center m-28"}>
      <div className={"flex flex-row items-center "}>
        <div className={"w-48"}>
          <Lifebuoy />
        </div>
        <h1 className={"font-bold text-8xl text-primary mb-8"}>404!</h1>
      </div>
      <h2 className={"font-semibold text-4xl text-black mb-8"}>Captain, your ship is off course again!</h2>
      <h4 className={"text-lg text-medium"}>The page you are looking for is not available</h4>
      <div className={"mt-8"}>
        <PegButton
          id="btnNext"
          variant="primary"
          type="button">
          Take me home
        </PegButton>
      </div>
    </div>
  )
}

export default Http404;