import React from 'react';
import { connect } from "react-redux";
import VoyageRouteLine from './VoyageRouteLine';
import VoyageRouteHeader from './VoyageRouteHeader';
import PegButton from 'Controls/Button/PegButton';
import { RiAddCircleLine } from 'react-icons/ri';
import { IOptionDetail, OptionDetail } from 'Model/Order/OptionDetail';
import { createVoyageRow, deleteVoyageRow, updateOptionDetails, updateOptionValidations } from 'Redux/Actions/Vessel';


type MyProps = {
  optionDetails?: any;
  createVoyageRow: () => void;
  deleteVoyageRow: (index: number) => void;
  updateOptionDetails: (event: any, key: string, index: number) => void;
  optionValidation?: any
  updateOptionValidations: (errorCount: number) => void;
};

type MyState = {
  option: IOptionDetail | {};
  port: string;
  terminal: string;
  berth: string;
  agent: string;
};

export const EmptyRow = () => {
  return OptionDetail.length === 0 ?
    <div className="flex flex-row justify-center p-10">
      <h3 className={"text-sm text-medium"}>No Options</h3>
    </div> : null
}

class VoyageTableView extends React.Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      option: [],
      port: '',
      terminal: '',
      agent: '',
      berth: ''
    };
  }

  componentDidMount() {
    const {
      createVoyageRow,
      optionDetails
    } = this.props;
    if (!optionDetails.length) {
      createVoyageRow();
    }
  }

  onUpdateValue = (event: any, key: string, index: number) => {
    const {
      updateOptionDetails
    } = this.props;
    updateOptionDetails(event, key, index);

    if (event) {
      if (key === 'port') {
        this.setState({ port: event.name })
      }
      if (key === 'terminal') {
        this.setState({ terminal: event.name })
      }
      if (key === 'berth') {
        this.setState({ berth: event.name })
      }
    } else {
      if (key === 'port') {
        this.setState({ port: '' })
      }
      if (key === 'terminal') {
        this.setState({ terminal: '' })
      }
    }
  };

  handleAddOption = () => {
    const { createVoyageRow } = this.props;

    let errorCount: number = this.checkValidation();
    if (errorCount === 0) {
      createVoyageRow();
    }

  }

  checkValidation = () => {

    let {
      optionDetails,
      optionValidation
    } = this.props;

    let validationObject = optionValidation;

    let errorCount = 0;

    optionDetails?.forEach((option: any) => {
      option.RouteDetail?.forEach((route: any) => {

        for (let key in validationObject) {

          if (!route[key]) {

            validationObject[key].error = true
            errorCount = errorCount + 1;

          }
        }
      })

    })

    this.props.updateOptionValidations(errorCount)

    return errorCount;
  }



  render() {
    const { optionDetails } = this.props;
    const {
      port,
      terminal,
      berth,
    } = this.state;
    return (
      <div className="col-span-2">
        <div className={'flex flex-row-reverse mb-4 gap-x-2'}>
          <div>
            <PegButton id={'btnAddCargo'} variant={'OutlinePrimary'} size={'sm'} onClick={this.handleAddOption}>
              <RiAddCircleLine size="1.2em" className="fill-current text-primar" />
              Add Option
            </PegButton>
          </div>
        </div>
        <VoyageRouteHeader />
        {
          optionDetails && optionDetails.length === 0 ? <> <EmptyRow /> </> : <>
            {optionDetails && optionDetails.map((item: any, index: number) => {
              return (
                <VoyageRouteLine
                  key={index}
                  index={index}
                  optionDetail={item}
                  portName={port}
                  terminalName={terminal}
                  berthName={berth}
                  onUpdateValue={(event: any, key: any) => this.onUpdateValue(event, key, index)}
                  handleDelete={() => { this.props.deleteVoyageRow(index) }}
                />
              )
            })}
          </>

        }
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  createVoyageRow: () => dispatch(createVoyageRow()),
  deleteVoyageRow: (index: number) => dispatch(deleteVoyageRow(index)),
  updateOptionDetails: (event: any, key: string, index: number) => dispatch(updateOptionDetails(event, key, index)),
  updateOptionValidations: (errorCount: number) => dispatch(updateOptionValidations(errorCount))
});

const mapStateToProps = (state: any) => ({
  optionDetails: state.vessel.optionDetails,
  optionValidation: state.vessel.optionsValidation
});
export default connect(mapStateToProps, mapDispatchToProps)(VoyageTableView);
