export interface IBulk {
  BulkCargoId: number;
  Version: number;
  CargoTypeCode: string;
  CargoTypeName: string;
  CargoOperation: string;
  GrossWeight: number | null;
  WeightUomCode: string;
  WeightUomName: string;
  CreatedAt: Date;
  CreatedBy: string;
  ModifiedAt: Date | null;
  ModifiedBy: string;
  IsActive: boolean;
}

export class Bulk implements IBulk {
  BulkCargoId: number;

  Version: number;

  CargoTypeCode: string;

  CargoTypeName: string;

  CargoOperation: string;

  GrossWeight: number | null;

  WeightUomCode: string;

  WeightUomName: string;

  CreatedAt: Date;

  CreatedBy: string;

  ModifiedAt: Date | null;

  ModifiedBy: string;

  IsActive: boolean;

  constructor() {
    this.BulkCargoId = 0;
    this.Version = 0;
    this.CargoTypeCode = '';
    this.CargoTypeName = '';
    this.CargoOperation = '';
    this.GrossWeight = 0;
    this.WeightUomCode = '';
    this.WeightUomName = '';
    this.CreatedAt = new Date();
    this.CreatedBy = '';
    this.ModifiedAt = new Date();
    this.ModifiedBy = '';
    this.IsActive = true;
  }
}


