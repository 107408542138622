import Service from './Service'
import Charge from './Charge'
import { useEffect, useState, useCallback, Fragment } from 'react';
import axios from 'axios';
import { UserDefault } from 'Model/User/UserDefault';
import { isDraftQuotation } from 'Utils/QuotationGrid';
import { ColumnPreferences } from 'Model/Common/ColumnPreferences';
import { IProduct, IQBillingParties, IQuotationDetails, IServiceAndCharges, onShowTariffFunc, onUpdateChargeFunc, onUpdateServiceFunc } from 'Model/QuotationGrid/types';
import { ExchangeRateLookupEntity } from 'Model/Master/ExchangeRateLookupEntity';
import { QuotationCharge } from 'Model/QuotationGrid/QuotationCharge';
import { IChargeName, onDeleteChargeFunc } from 'Model/Common/types';
import isNull from 'lodash/isNull';
import { useDispatch, useSelector } from 'react-redux';
import { Service as ServiceClass } from 'Model/QuotationGrid/QuotationService';
import { onUpdateQuotationServiceDetails } from 'Redux/Actions/QuoteGrid';
import { Charge as ChargeClass } from 'Model/QuotationGrid/QuotationCharge';

interface Prop {
  service: IServiceAndCharges;
  serviceIndex: number;
  serviceOptions: any;
  taxList: any;
  currencyList: any;
  uomList: any;
  billingParties: IQBillingParties[];
  quotationDetails: IQuotationDetails;
  selectedProduct: IProduct;
  disableRevenue: boolean;
  disableCost: boolean;
  selectedList?: number[];
  userDetails: UserDefault;
  onSelectServices?: (index: number) => void;
  exchangeRates: ExchangeRateLookupEntity[];
  disableOperatingIncome: boolean;
  isGridDisabled: boolean;
  onUpdateCharge: onUpdateChargeFunc;
  onUpdateService: onUpdateServiceFunc;
  onDeleteService: (serviceIndex: number) => void;
  onAddCharge: (serviceIndex: number) => void;
  onDeleteCharge: onDeleteChargeFunc;
  onShowTariff: onShowTariffFunc;
  columnPreference: ColumnPreferences;
  updateChargeValidation: (validationKey: string, chargeId: string, value: boolean) => void;
}

const List = (props: Prop) => {
  const {
    service,
    serviceOptions,
    taxList,
    currencyList,
    uomList,
    billingParties,
    selectedProduct,
    selectedList,
    onSelectServices,
    onUpdateCharge,
    onUpdateService,
    serviceIndex,
    onAddCharge,
    onDeleteService,
    onDeleteCharge,
    quotationDetails,
    disableRevenue,
    disableCost,
    disableOperatingIncome,
    exchangeRates,
    userDetails,
    onShowTariff,
    isGridDisabled,
    columnPreference,
    updateChargeValidation
  } = props

  const dispatch = useDispatch();
  const isReadOnly = isGridDisabled || !isDraftQuotation(quotationDetails);

  const [editCharge, setEditCharge] = useState(!isReadOnly);
  const [showTariffModal, setShowTarriffModal] = useState(false);
  const [showCharge, setShowCharge] = useState(true)
  const [chargeList, setChargeList] = useState<IChargeName[]>([]);
  const [editableCharges, setEditableCharges] = useState<number[]>([]);

  const addToEditable = (chargeIndex: number) => {
    setEditableCharges((prev) => [...prev, chargeIndex])
  }

  const removeFromEditable = (chargeIndex: number) => {
    setEditableCharges((prev) => prev.filter((item) => item !== chargeIndex))
  }

  const onTariffModalConfirm = () => {
    setShowTarriffModal(false)
  }

  const onTariffModalOpen = (chargeIndex: number) => {
    addToEditable(chargeIndex)
    setShowTarriffModal(true)
  }

  const onTariffModalCancel = (chargeIndex: number) => {
    removeFromEditable(chargeIndex)
    setShowTarriffModal(false)
  }

  useEffect(() => {
    if (service?.service.Code && quotationDetails?.CustomerCode) {
      axios.get(`/getCharges?company-code=${selectedProduct.ProductDetails?.AssignedToCompanyCode}&service-code=${service?.service.Code}&includeDolphinMapping=true&partyCode=${quotationDetails?.CustomerCode}&quotationid=${quotationDetails?.QuotationId}&includeGSSServices=true`).then((response) => {
        setChargeList(response.data)
      }).catch((error: any) => {
        console.log("Error while calling charge API", error)
      })
    }
  }, [service?.service.Code])

  const onExpandService = () => {
    setShowCharge(!showCharge)
  }

  const onBackToBackSelected = useCallback((svc: any) => {

    if (service?.service?.BackToBack) {
      service.service.PriceCurrencyCode = service.service.CostCurrencyCode
      service.service.PriceCurrencyName = service.service.CostCurrencyName
      service.service.PriceExchangeRate = service.service.CostExchangeRate
    }

    service?.charges?.forEach((charge: any) => {
      charge.BackToBack = svc.BackToBack

      if (svc.BackToBack) {

        charge.Quantity = 1
        charge.PriceCurrencyCode = charge.CostCurrencyCode
        charge.PriceCurrencyName = charge.CostCurrencyName
        charge.PriceExchangeRate = charge.CostExchangeRate
        charge.UnitPrice = charge?.UnitCost

      }
    })

  }, [serviceIndex]);

  const isChargeEditable = (chargeIndex: number) => {
    return editableCharges.includes(chargeIndex)
  }

  const services = useSelector((state: any) => state.QuoteGrid.services);

  const onChangeService = (event: any) => {
    const { service: innerService } = service
    const quoteServiceObj: IServiceAndCharges = {
      charges: [{ ...new ChargeClass() }],
      service: {
        ...new ServiceClass(),
        RowOrder: services.length + 1,
        Name: event.name,
        Code: event.code,
        CostExchangeRate: innerService.CostExchangeRate,
        PriceExchangeRate: innerService.PriceExchangeRate,
        PriceCurrencyCode: innerService.PriceCurrencyCode,
        CostCurrencyCode: innerService.CostCurrencyCode,
        OPTags: event.opTags
      },
    };

    dispatch(onUpdateQuotationServiceDetails(quoteServiceObj, selectedProduct, serviceIndex));
  }

  return (
    <>
      <Service
        service={service?.service}
        charges={service?.charges}
        serviceIndex={serviceIndex}
        selectedProduct={selectedProduct}
        serviceOptions={serviceOptions}
        taxList={taxList}
        exchangeRates={exchangeRates}
        quotationDetails={quotationDetails}
        currencyList={currencyList}
        uomList={uomList}
        billingParties={billingParties}
        showCharge={showCharge}
        onSelectServices={onSelectServices}
        selectedList={selectedList}
        onUpdateCharge={onUpdateCharge}
        onUpdateService={onUpdateService}
        onExpandService={onExpandService}
        onDeleteService={onDeleteService}
        onAddCharge={onAddCharge}
        disableRevenue={disableRevenue}
        disableCost={disableCost}
        disableOperatingIncome={disableOperatingIncome}
        userDetails={userDetails}
        editCharge={editCharge}
        isReadOnly={isReadOnly}
        onBackToBackSelected={onBackToBackSelected}
        columnPreference={columnPreference}
        onChangeService={onChangeService}
      />
      {
        showCharge && service?.charges.map((item: QuotationCharge, index: number) => {
          return (
            <Fragment key={index}
            >
              <Charge
                charge={item}
                serviceIndex={serviceIndex}
                chargeIndex={index}
                service={service?.service}
                serviceAndCharges={service}
                quotationDetails={quotationDetails}
                selectedProduct={selectedProduct}
                taxList={taxList}
                onUpdateCharge={onUpdateCharge}
                onUpdateService={onUpdateService}
                billingParties={billingParties}
                uomList={uomList}
                chargeList={chargeList}
                onDeleteCharge={onDeleteCharge}
                disableRevenue={disableRevenue}
                disableCost={disableCost}
                disableOperatingIncome={disableOperatingIncome}
                userDetails={userDetails}
                onShowTariff={onShowTariff}
                editCharge={isChargeEditable(index) || (isNull(item.Id) && !item.TariffDetails?.IsActive)}
                showTariffModal={showTariffModal}
                setShowTarriffModal={(value: boolean) => setShowTarriffModal(value)}
                onTariffModalConfirm={onTariffModalConfirm}
                onTariffModalCancel={() => onTariffModalCancel(index)}
                onTariffModalOpen={() => onTariffModalOpen(index)}
                isReadOnly={isReadOnly}
                columnPreference={columnPreference}
                updateChargeValidation={updateChargeValidation}
                addToEditable={addToEditable}
                removeFromEditable={removeFromEditable}

              />
            </Fragment>
          )
        })
      }
    </>
  );
};

export default List;
