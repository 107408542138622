import { IRoute, Route } from './Route';

export interface IOptionDetail {
  OptionId?: number;
  AgentRequestStatusFk?: number;
  OptionStatusFk?: number;
  Version?: number;
  PortName?: string;
  PortCode?: string;
  TerminalName?: string;
  TerminalCode?: string;
  BerthName?: string;
  BerthCode?: string;
  QuotationAreaType?: string;
  OptionName?: string;
  OriginAgentCode?: string;
  OriginAgentName?: string;
  DestAgentCode?: string;
  DestAgentName?: string;
  ShipAgentCode?: string;
  ShipAgentName?: string;
  CreatedAt?: Date;
  CreatedBy?: string;
  ModifiedAt?: Date | null;
  ModifiedBy?: string;
  IsActive?: boolean;
  RouteDetail?: IRoute[];
  ServiceDetail?: any[];
}

export class OptionDetail implements IOptionDetail {

  OptionId?: number;

  AgentRequestStatusFk?: number;

  OptionStatusFk?: number;

  Version?: number;

  PortCode?: string;

  PortName?: string;

  TerminalCode?: string;

  TerminalName?: string;

  BerthName?: string;

  BerthCode?: string;

  BusinessAreaCode?: string;

  BusinessAreaName?: string;

  OptionName?: string;

  OriginAgentCode?: string;

  OriginAgentName?: string;

  DestAgentCode?: string;

  DestAgentName?: string;

  ShipAgentCode?: string;

  ShipAgentName?: string;

  CreatedAt?: Date;

  CreatedBy?: string;

  ModifiedAt?: Date | null;

  ModifiedBy?: string;

  IsActive?: boolean;

  RouteDetail?: Route[];

  ServiceDetail?: any[];

  constructor() {
    this.OptionId = 0;
    this.AgentRequestStatusFk = 0;
    this.OptionStatusFk = 0;
    this.Version = 0;
    this.PortName = '';
    this.PortCode = '';
    this.TerminalCode = '';
    this.TerminalName = '';
    this.BerthName = '';
    this.BerthCode = '';
    this.BusinessAreaCode = '';
    this.BusinessAreaName = '';
    this.OptionName = '';
    this.OriginAgentCode = '';
    this.OriginAgentName = '';
    this.DestAgentCode = '';
    this.DestAgentName = '';
    this.ShipAgentCode = '';
    this.ShipAgentName = '';
    this.CreatedAt = new Date();
    this.CreatedBy = '';
    this.ModifiedAt = new Date();
    this.ModifiedBy = '';
    this.IsActive = true;
    this.ServiceDetail = [];
    this.RouteDetail = [new Route()];
  }
}
