import {
  Flex,
  BulkType,
  BulkOperation,
  BulkWeight,
} from "Containers/CargoDetails/cargo.styles";

const Header = (props: any) => {
  return (
    <Flex>
      <BulkType>
        <label className="text-sm font-semibold text-dark">Cargo Type</label>
      </BulkType>

      <BulkOperation>
        <label className="text-sm font-semibold text-dark">Cargo Operations</label>
      </BulkOperation>

      <BulkWeight>
        <label className="text-sm font-semibold text-dark">Total Gross Weight (kg)</label>
      </BulkWeight>
    </Flex>
  );
};

export default Header;
