
import { useCallback, useEffect } from 'react';
import Dropdown from 'Controls/Dropdown';
import { useSelector } from "react-redux";
import ToolTip from 'Controls/ToolTip/ToolTip';
import InputBox from 'Controls/Input/PegInputBox';
import { ReactComponent as DeleteIcon } from 'Assets/delete-item.svg'
import {
  Overview,
  // list
  ID,
  Delete,
  Add,
  Expand,
  //revenue
  Quantity,
  UOM,
  UnitPrice,
  Currency,
  Amount,
  BillingCurrency,
  ExRate,
  Tax,
  SalesTaxAmt,
  BillAmount,
  //cost
  Supplier,
  EstUnitCost,
  EstCurrency,
  EstExRate,
  EstTaxType,
  EstTax,
  EstAmt,
  EstAmtTax,
  // sr request
  OperatingIncome,
  // eye_share
  EsActualCost,
  // remarks
  InternalRemarks,
  Red,
  Green,
  TextFieldContainer,
  CostUOM,
  CostQty
} from 'Containers/JobGrid/Jobs.styles'
import {
  Name
} from '../../template.styles'
import axios from 'axios';
import {
  checkforNonNegativeValue,
  checkforNonZeroValue
} from 'Utils/Generic';
import {
  setChargeValue
} from 'Utils/ServiceTemplate';
import { UnitPriceMinimum } from 'Constants/Constant';

type Props = {
  userDetails: any;
  charge: any;
  service: any;
  chargeIndex: number;
  serviceIndex: number;
  uomList: any[];
  taxList: any[];
  chargeList: any[];
  onDeleteCharge: (serviceIndex: number, chargeIndex: number) => void;
  onUpdateCharge: (servIndex: number, charIndex: number, charObj: any, key: string, value: any) => void;
  onUpdateTemplateCharge: (serviceIndex: number, chargeIndex: number, charge: any) => void;
  disableCost?: boolean;
  disableOperatingIncome?: boolean;
  disableRevenue?: boolean;
  selectedProduct: any;
  isShowCostQty: boolean;
};


const Charge = (props: Props) => {
  const {
    charge,
    service,
    disableCost,
    disableOperatingIncome,
    disableRevenue,
    uomList,
    taxList,
    userDetails,
    chargeIndex,
    serviceIndex,
    onDeleteCharge,
    chargeList,
    onUpdateTemplateCharge,
    isShowCostQty
  } = props;

  let decimalPlace = userDetails?.CurrencyDecimalPlaces ? parseInt(userDetails?.CurrencyDecimalPlaces) : 2;
  let autoDecimal = userDetails?.CurrencyDecimalPlacesAutoCalculation ? parseInt(userDetails?.CurrencyDecimalPlacesAutoCalculation) : 2;
  let defaultCurrencyCode = useSelector((state: any) => state.ServiceTemplates.serviceTemplate)?.ServiceTemplateDetails?.BaseCurrencyCode;
  const styleClass = "text-left text-xs font-medium text-gray-500 tracking-wider bg-gray-50";
  const inputStyle = {
    border: 'none',
    height: '34px',
    borderRadius: '0px',
    fontSize: '12px'
  }

  let estimatedAmount = 0;
  let estimatedTaxAmount = 0;
  let billAmt: any = 0;
  let taxPerc: any = 0;
  let exchange: any = 0;
  let operatingIncome = 0;
  let salesTaxPercentage: any = 0;
  let purchaseTaxAmt: any = 0;
  let costVariance: any = 0;

  const amountValue = charge?.UnitPrice * charge?.Quantity

  if (charge?.CostQuantity && charge?.UnitCost) {
    estimatedAmount = charge?.CostQuantity * charge?.UnitCost
  }

  operatingIncome = (amountValue * charge.PriceExchangeRate) - (estimatedAmount * service.CostExchangeRate);
  if (charge.TotalEyeshareAmount) {
    operatingIncome = (amountValue * charge.PriceExchangeRate) - (charge.TotalEyeshareAmount * service.CostExchangeRate)
  }

  const ChargeObj = setChargeValue(charge, 'OperatingIncomeActual', operatingIncome, null);

  useEffect(() => {
    // const asyncFn = () => {
    if (ChargeObj.OperatingIncomeActual) {
      onUpdateTemplateCharge(serviceIndex, chargeIndex, ChargeObj)
    }
    // }
    // asyncFn()
  }, [ChargeObj])

  estimatedTaxAmount = (estimatedAmount * charge.CostTaxPercent) / 100 + estimatedAmount;
  purchaseTaxAmt = estimatedAmount * charge.CostTaxPercent / 100

  if (amountValue && service?.BillingExchangeRate) {
    if (charge.PriceTaxPercent) {
      taxPerc = (amountValue * charge.PriceTaxPercent) / 100
      salesTaxPercentage = amountValue * (charge.PriceTaxPercent / 100);
    }
    if (taxPerc) {
      billAmt = taxPerc + amountValue;
      if (defaultCurrencyCode === service.PriceCurrencyCode) {
        exchange = billAmt / service?.BillingExchangeRate
      } else {
        if (!service?.BillingExchangeRate) {
          exchange = billAmt
        } else {
          exchange = billAmt * service?.BillingExchangeRate
        }
      }
    } else {
      if (defaultCurrencyCode === service.PriceCurrencyCode) {
        exchange = amountValue / service?.BillingExchangeRate
      } else {
        if (!service?.BillingExchangeRate) {
          exchange = amountValue
        } else {
          exchange = amountValue * service?.BillingExchangeRate
        }
      }
    }
    billAmt = parseFloat(exchange).toFixed(autoDecimal)
  }

  useEffect(() => {
    // const asyncFn = async () => {
    if (service?.PriceCurrencyCode !== charge?.PriceCurrencyCode) {
      let chargeObj = setChargeValue(charge, 'PriceCurrencyCode', service?.PriceCurrencyCode, null)
      setChargeValue(charge, 'PriceExchangeRate', service?.PriceExchangeRate, null)
      onUpdateTemplateCharge(serviceIndex, chargeIndex, chargeObj)
    }
    // }
    // asyncFn()
  }, [service?.PriceExchangeRate, service?.PriceCurrencyCode])

  useEffect(() => {
    // const asyncFn = async () => {
    if (service?.CostExchangeRate || service?.CostExchangeRate === 0 || service?.CostCurrencyCode !== charge?.CostCurrencyCode) {
      let chargeObj = setChargeValue(charge, 'CostCurrencyCode', service?.CostCurrencyCode, null)
      chargeObj = setChargeValue(chargeObj, 'CostExchangeRate', service?.CostExchangeRate, null)
      onUpdateTemplateCharge(serviceIndex, chargeIndex, chargeObj)
    }
    // }
    // asyncFn()
  }, [service?.CostExchangeRate, service?.CostCurrencyCode])

  useEffect(() => {
    // const asyncFn = async () => {
    if (service?.BillingExchangeRate || service?.BillingExchangeRate === 0 || service?.BillingCurrencyCode !== charge?.BillingCurrencyCode) {
      let chargeObj = setChargeValue(charge, 'BillingCurrencyCode', service?.BillingCurrencyCode, null)
      chargeObj = setChargeValue(chargeObj, 'BillingExchangeRate', service?.BillingExchangeRate, null)
      onUpdateTemplateCharge(serviceIndex, chargeIndex, chargeObj)
    }
    // }
    // asyncFn()
  }, [service?.BillingExchangeRate, service?.BillingCurrencyCode])



  costVariance = estimatedAmount - charge.TotalEyeshareAmount;

  const onChangeDropdownValue = useCallback((event: any, id: any) => {
    props.onUpdateCharge(serviceIndex, chargeIndex, charge, id, event)
  }, []);

  const onChangeInputValue = (event: any) => {
    if (event.target.type === 'number') {
      var t = event.target.value;
      event.target.value = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), decimalPlace + 1)) : t;
    }
    props.onUpdateCharge(serviceIndex, chargeIndex, charge, event.target.id, event)
  }


  return (
    <Overview className={styleClass} id="serviceTemplate">
      <Overview>
        <ID>
          {serviceIndex + 1}.{chargeIndex + 1}
        </ID>

        <ToolTip
          id="deleteToolTip"
          content={'Delete'}
          placement={'right'}
          animation={'scale-subtle'}
          arrow={true}
          duration={200}
          delay={[75, 0]}
        >
          <Delete>
            <DeleteIcon
              style={{ cursor: 'pointer' }}
              onClick={() => onDeleteCharge(serviceIndex, chargeIndex)} />
          </Delete>
        </ToolTip>

        <Add />
        <Expand />
        <Name>
          <Dropdown
            id="chargeName"
            defaultValue={charge.Name}
            dropdownOptions={chargeList}
            onChangeDropdown={onChangeDropdownValue}
            label={'name'}
            isLookup={false}
            dropdownLabel={'name'}
            dropdownValue={'name'}
            style={{ width: 199 }}
            placeHolder="Select Charge"
            appendParams={false}
            removeBorder={true}
            isMandatory={true}
            removeIsClear={true}
            isMenuPortalTarget={'true'}
            domId={'serviceTemplate'}
          />
        </Name>
        {/* {selectedProduct.Code === 'BP0004' && <StartDate />}
        {selectedProduct.Code === 'BP0004' && <EndDate />} */}
        {!disableOperatingIncome &&
          <OperatingIncome>
            {
              isNaN(operatingIncome) ? <Green>0.00</Green> : (
                operatingIncome < 0 ?
                  <Red>
                    {Math.abs(operatingIncome).toFixed(autoDecimal)}
                  </Red>
                  :
                  <Green>
                    {operatingIncome.toFixed(autoDecimal)}
                  </Green>
              )
            }
          </OperatingIncome>
        }
        {!disableRevenue && <UOM>
          <Dropdown
            id="UOM"
            defaultValue={charge.UomCode}
            type={'charge'}
            label={'code'}
            dropdownOptions={uomList}
            isLookup={false}
            dropdownLabel={'displayName'}
            dropdownValue={'code'}
            appendParams={false}
            style={{ width: 149 }}
            placeHolder="Select UOM"
            removeBorder={true}
            isMandatory={false}
            onChangeDropdown={onChangeDropdownValue}
            isMenuPortalTarget={'true'}
            domId={'serviceTemplate'}
          />
        </UOM>}
        {!disableRevenue && <Quantity>
          <InputBox
            type={'number'}
            value={charge.Quantity}
            placeholder={'Quantity'}
            onChange={onChangeInputValue}
            id="quantity"
            style={inputStyle}
            isMandatory={false}
            min={"1"}
            onInput={(event: any) => {
              checkforNonZeroValue(event);
            }}
          />
        </Quantity>}
        {!disableRevenue &&
          <UnitPrice>
            <InputBox
              id="unit"
              value={charge.UnitPrice}
              type={'number'}
              isMandatory={false}
              style={inputStyle}
              placeholder={'Unit Price'}
              onChange={onChangeInputValue}
              min={UnitPriceMinimum}
            // onInput={(event: any) => {
            //   checkforNonZeroValue(event);
            // }}
            />
          </UnitPrice>
        }
        {!disableRevenue &&
          <Currency>
            <TextFieldContainer>{charge?.PriceCurrencyCode}</TextFieldContainer>
          </Currency>
        }
        {!disableRevenue &&
          <Amount>
            {amountValue ? amountValue.toFixed(autoDecimal) : 0}
          </Amount>
        }
        {!disableRevenue &&
          <BillingCurrency>
            <TextFieldContainer>{charge?.BillingCurrencyCode}</TextFieldContainer>
          </BillingCurrency>
        }
        {!disableRevenue &&
          <ExRate>
            {charge?.BillingExchangeRate}
          </ExRate>
        }
        {!disableRevenue &&
          <Tax>
            <Dropdown
              id="actualTax"
              defaultValue={charge.PriceTaxCode}
              type={'charge'}
              label={'Name'}
              isLookup={false}
              dropdownOptions={taxList}
              dropdownLabel={'Name'}
              dropdownValue={'Code'}
              appendParams={false}
              style={{ width: 149 }}
              placeHolder="Select Tax"
              removeBorder={true}
              onChangeDropdown={onChangeDropdownValue}
              isMenuPortalTarget={'true'}
              domId={'serviceTemplate'}
            />
          </Tax>
        }
        {
          !disableRevenue &&
          <SalesTaxAmt>
            {salesTaxPercentage.toFixed(autoDecimal)}
          </SalesTaxAmt>
        }

        {!disableRevenue &&
          <BillAmount>
            {billAmt ? billAmt : (amountValue ? amountValue : 0)}
          </BillAmount>
        }
        {!disableCost &&
          <Supplier />
        }
        {
          isShowCostQty && !disableCost && (
            <>
              <CostUOM>
                <Dropdown
                  id="costUOM"
                  defaultValue={charge.CostUomCode}
                  type={'charge'}
                  label={'code'}
                  dropdownOptions={uomList}
                  isLookup={false}
                  dropdownLabel={'displayName'}
                  dropdownValue={'code'}
                  appendParams={false}
                  style={{ width: 149 }}
                  placeHolder="Select UOM"
                  removeBorder={true}
                  isMandatory={false}
                  onChangeDropdown={onChangeDropdownValue}
                  isMenuPortalTarget={'true'}
                  domId={'serviceTemplate'}
                />
              </CostUOM>
              <CostQty>
                <InputBox
                  type={'number'}
                  value={charge.CostQuantity}
                  placeholder={'Quantity'}
                  onChange={onChangeInputValue}
                  id="costQuantity"
                  style={inputStyle}
                  isMandatory={false}
                  min={"1"}
                  onInput={(event: any) => {
                    checkforNonZeroValue(event);
                  }}
                />
              </CostQty>
            </>)
        }
        {!disableCost &&
          <EstUnitCost>
            <InputBox
              id="estUnitCost"
              value={charge.UnitCost}
              type={'number'}
              // isMandatory={true}
              style={inputStyle}
              placeholder={'Unit cost'}
              onChange={onChangeInputValue}
            // onInput={(event: any) => {
            //   checkforNonZeroValue(event, true);
            // }}
            />
          </EstUnitCost>
        }
        {!disableCost &&
          <EstCurrency>
            <TextFieldContainer>{charge?.CostCurrencyCode}</TextFieldContainer>
          </EstCurrency>
        }
        {!disableCost &&
          <EstExRate>{charge?.CostExchangeRate}</EstExRate>
        }
        {!disableCost &&
          <EstTaxType>
            <Dropdown
              id="estimateTax"
              defaultValue={charge.CostTaxCode}
              type={'charge'}
              label={'Name'}
              isLookup={false}
              dropdownOptions={taxList}
              dropdownLabel={'Name'}
              dropdownValue={'Code'}
              appendParams={false}
              style={{ width: 149 }}
              placeHolder="Select Tax Type"
              removeBorder={true}
              onChangeDropdown={onChangeDropdownValue}
              isMenuPortalTarget={'true'}
              domId={'serviceTemplate'}
            />
          </EstTaxType>
        }
        {!disableCost &&
          <EstTax>{purchaseTaxAmt ? purchaseTaxAmt.toFixed(autoDecimal) : 0}</EstTax>
        }
        {!disableCost &&
          <EstAmt>{estimatedAmount.toFixed(autoDecimal)}</EstAmt>
        }
        {!disableCost &&
          <EstAmtTax>
            {estimatedTaxAmount ? estimatedTaxAmount.toFixed(autoDecimal) : 0}
          </EstAmtTax>
        }
        {!disableCost &&
          <EsActualCost>
            <InputBox
              id="eyeShareCost"
              value={charge.TotalEyeshareAmount}
              type={'number'}
              style={inputStyle}
              placeholder={'Enter amount'}
              onChange={onChangeInputValue}
              onInput={(event: any) => {
                checkforNonNegativeValue(event);
              }}
            />
          </EsActualCost>
        }
        <InternalRemarks>
          <InputBox
            id="remarks"
            value={charge.InternalRemarks}
            placeholder={'Remarks'}
            style={inputStyle}
            onChange={onChangeInputValue}
          />
        </InternalRemarks>
      </Overview>
    </Overview>
  )
};

export default Charge;
