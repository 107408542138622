import PegButton from 'Controls/Button/PegButton';
import {
  MarginLeft,
  Flex,
  ErrorMessage
} from '../quote.styles'
import { IServiceAndCharges } from 'Model/QuotationGrid/types';

interface Prop {
  onAddService: () => void;
  onSaveServices: () => void;
  onEditService: () => void;
  onApplyTariff: () => void;
  onDeleteOption: () => void;
  hasMissingFields: boolean;
  selectedList: number[];
  isSaveButtonDisabled: boolean;
}
const Footer = (props: Prop) => {
  const {
    onAddService,
    onSaveServices,
    onEditService,
    hasMissingFields,
    selectedList,
    onApplyTariff,
    isSaveButtonDisabled
  } = props;

  return (
    <div>
      <Flex>
        <PegButton
          id="jobGrid_import"
          type="button"
          variant="Primary"
          styleObject={{ marginRight: 12 }}
          onClick={() => onAddService()}>
          Add Service
        </PegButton>
        <PegButton
          id="jobGrid_import"
          type="button"
          variant="Primary"
          disabled={!Boolean(selectedList.length)}
          styleObject={{ marginRight: 12 }}
          onClick={() => onEditService()}>
          Bulk Edit
        </PegButton>
        <PegButton
          id="jobGrid_delete"
          type="button"
          variant="Danger"
          data-testid="deleteServices"
          disabled={!Boolean(selectedList.length)}
          styleObject={{ marginRight: 12 }}
          onClick={props.onDeleteOption}>
          Delete
        </PegButton>
        <PegButton
          id="jobGrid_import"
          type="button"
          variant="Primary"
          disabled={!Boolean(selectedList.length)}
          styleObject={{ marginRight: 12 }}
          onClick={() => onApplyTariff()}>
          Apply Tariff
        </PegButton>
        <MarginLeft>
          {hasMissingFields && <ErrorMessage>Please fill the required field(s)</ErrorMessage>}
          <PegButton
            id="jobGrid_import"
            data-testid="saveQuotationGrid"
            type="button"
            variant="Primary"
            styleObject={{ marginRight: 12 }}
            disabled={isSaveButtonDisabled}
            onClick={() => onSaveServices()}>
            Save
          </PegButton>
        </MarginLeft>
      </Flex>
    </div>
  );
};

export default Footer;
