import {
  useEffect,
  useState,
  useCallback
} from 'react';
import styled from 'styled-components'
import Loader from './Loader'
import axios from 'axios';
import PegButton from 'Controls/Button/PegButton';
import moment from 'moment';

interface Prop {
  charge: any;
  closeModal: () => void;
  type: string;
}

interface IStylesProps {
  isHeader?: boolean;
  colour?: any;
  showBorder?: boolean;
  isLast?: boolean;
  isBreakdown?: boolean;
}

const TariffModal = (props: Prop) => {
  const {
    charge,
    closeModal,
    type
  } = props
  const [tariffHistory, setTariffHistory] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    getTariffDetails()
  }, [])

  const getTariffDetails = useCallback(() => {
    axios.get(`/${type === 'job' ?
      'jobService-getTariffHistory?jobServiceId=' :
      'quotation-getTariffHistory?serviceId='}${charge?.Id}`).then((response) => {
        setTariffHistory(response.data)
        setLoading(false)
      })
  }, [charge])

  if (loading) {
    return (
      <Overview>
        <Loader gridLoader={true} color={'#aacbe9'} />
      </Overview>
    );
  }
  if (!tariffHistory || !tariffHistory.length) {
    return (
      <Overview>
        <Message>There is no tariff history present</Message>
      </Overview>
    )
  }
  return (
    <Overview>
      <Title>Tariff History</Title>
      <Grid>
        <RenderHeader />
        <RenderList tariffHistory={tariffHistory} />
      </Grid>
      <Footer>
        <PegButton
          id="import_template"
          type="button"
          variant="Primary"
          onClick={closeModal}>
          Close
        </PegButton>
      </Footer>
    </Overview>
  );
};

const RenderList = (props: any) => {
  const {
    tariffHistory
  } = props
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const renderList = (item: any) => {
    if (isOpen && item) {
      const { Breakdown } = item;
      const { UnitPrice, TotalAmount, ...rest } = Breakdown;
      return { 'Unit Price': Breakdown[UnitPrice], 'Total Amount': Breakdown[TotalAmount] }
    }
    return item && item?.Breakdown
  }
  return (
    <div>
      {
        tariffHistory.map((item: any) => {
          return (
            <List>
              <AppliedOn>{item?.TariffAppliedOn ? moment(item?.TariffAppliedOn).format('DD-MM-YYYY') : '-'}</AppliedOn>
              <AppliedBy>{item?.TariffAppliedBy ?? '-'}</AppliedBy>
              <EditedOn>{item?.TariffEditedOn ? moment(item?.TariffEditedOn).format('DD-MM-YYYY') : '-'}</EditedOn>
              <EditedBy>{item?.TariffEditedBy ?? '-'}</EditedBy>
              <Breakdown isBreakdown>
                {Object.keys(renderList(item)).map(key => {
                  return (
                    key != "v_BasicFee" && item?.Breakdown[key] != null && <ul key={key}>
                      <li>
                        {key} - {item?.Breakdown[key]}
                      </li>
                      <Divider />
                    </ul>
                  );
                })}
                <Button onClick={toggle}>
                  {isOpen ? ' Show more' : 'Show less'}
                </Button>
              </Breakdown>
            </List>
          )
        })
      }
    </div>
  )
}
const RenderHeader = () => {
  return (
    <Header>
      <AppliedOn isHeader>Applied On</AppliedOn>
      <AppliedBy isHeader>Applied By</AppliedBy>
      <EditedOn isHeader>Edited On</EditedOn>
      <EditedBy isHeader>Edited By</EditedBy>
      <Breakdown isHeader isBreakdown>Breakdown</Breakdown>
    </Header>
  )
}

export default TariffModal;

const Overview = styled.div<IStylesProps>`
  height: 75vh !important;
  width: 67vw !important;
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
`
const Message = styled.div<IStylesProps>`
  display : flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`
const Footer = styled.div<IStylesProps>`
  width: 100%;
  height: 60px;
  position: absolute;
  border-top: 2px solid #D8D8D8;
  bottom: 0px;
  display: flex;
  justify-content: flex-end;
  padding: 12px;
  padding-right: 24px;
`
const Grid = styled.div<IStylesProps>`
  margin: 24px;
  padding-bottom: 12px;
  height: calc(100% - 130px);
  overflow: auto;
`
const Row = styled.div<IStylesProps>`
  display: flex;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 36px;
  background-color: ${(props) => (props.isHeader ? '#C1C1C1' : '#FFF')};
  border-bottom: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
`;

const Header = styled.div<IStylesProps>`
  display: flex;
  position: sticky;
  width: fit-content;
`
const Title = styled.div<IStylesProps>`
  margin: 24px;
  margin-bottom: 0px;
  font-size: 16px;
`
const EditedOn = styled(Row)`
  width: 150px;
`
const EditedBy = styled(Row)`
  width: 150px !important;
`
const AppliedOn = styled(Row)`
  width: 150px !important;
  border: 1px solid #D8D8D8;
`
const AppliedBy = styled(Row)`
  width: 150px !important;
`
const List = styled.div<IStylesProps>`
  display: flex;
  width: fit-content;
`
const Breakdown = styled(Row)`
  width: 300px !important;
  display: ${(props) => (props.isBreakdown ? 'block' : 'flex')};
  padding-top: ${(props) => (props.isBreakdown ? '7px' : '0px')}
`
const Divider = styled.hr<IStylesProps>`
border: 0.5px solid #DFDFDF;
width: 100% !important
`
const Button = styled.button<IStylesProps>`
color: blue;
`
