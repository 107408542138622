export interface ICrewDetails {
  CrewId: number;
  Version: number;
  CrewMovement: string;
  NoOfCrew: number | null;
  AccomodationType: string;
  NoOfNights: number | null;
  NationalityCode: string;
  NationalityName: string;
  MedicalCondition: string;
  DocumentationServices: string;
  DocumentType: string;
  CreatedAt: Date;
  CreatedBy: string;
  ModifiedAt: Date | null;
  ModifiedBy: string;
  IsActive: boolean;
}

export class CrewDetails implements ICrewDetails {
  CrewId: number;
  Version: number;
  CrewMovement: string;
  NoOfCrew: number | null;
  AccomodationType: string;
  DocumentationServices: string;
  DocumentType: string;
  NoOfNights: number | null;
  NationalityCode: string;
  NationalityName: string;
  MedicalCondition: string;
  CreatedAt: Date;
  CreatedBy: string;
  ModifiedAt: Date | null;
  ModifiedBy: string;
  IsActive: boolean;

  constructor() {
    this.CrewId = 0;
    this.Version = 0;
    this.CrewMovement = '';
    this.NoOfCrew = 0;
    this.AccomodationType = '';
    this.NoOfNights = 0;
    this.DocumentationServices = '';
    this.DocumentType = ';'
    this.NationalityCode = '';
    this.NationalityName = '';
    this.MedicalCondition = '';
    this.CreatedAt = new Date();
    this.CreatedBy = '';
    this.ModifiedAt = new Date();
    this.ModifiedBy = '';
    this.IsActive = true;
  }
}
