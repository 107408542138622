import PegButton from 'Controls/Button/PegButton';
import ReactTooltip from 'react-tooltip';
import { RiCheckLine } from 'react-icons/ri';
import {
  Title,
  Flex,
  GridOptions,
  GroupOptions,
  MarginLeft,
  SummaryDetails
} from '../quote.styles'
import { BsCheck, BsCircle } from 'react-icons/bs';
import { Color } from 'Utils/Color';
import { VerLabel } from 'style';

interface Prop {
  hasMissingFields: boolean;
  disableRevenue: boolean;
  disableCost: boolean;
  disableOperatingIncome: boolean;
  hideImportTemplate: boolean;
  onClickOptions: (type: string) => void;
  onClickImportTemplate: () => void;
  onClickFilterColumn: () => void;
}
const HeaderOptions = (props: Prop) => {
  const {
    hasMissingFields,
    disableRevenue,
    disableCost,
    disableOperatingIncome,
    hideImportTemplate,
    onClickImportTemplate,
    onClickFilterColumn
  } = props

  const ActiveIcon = (prop: { active: boolean }) => {
    return (
      prop.active ? <></> : <BsCheck style={{ color: '#383a3e', height: 20, width: 20 }} />
    )
  }

  return (
    <div>
      <SummaryDetails>
        <Title>Service and Charges</Title>
        <VerLabel>v {process.env.REACT_APP_VERSION_INFO} {process.env.REACT_APP_ENV_NAME}</VerLabel>
      </SummaryDetails>
      <Flex style={{ marginBottom: 16 }}>
        <GroupOptions
          data-tip
          data-for='OperatingIncome'
          colour={Color.operatingIncomeHighlight}
          onClick={() => props.onClickOptions('OpIncome')}>
          <ActiveIcon active={disableOperatingIncome} />
          <span>Op Income</span>

        </GroupOptions>

        <GroupOptions
          data-tip
          data-for='revenue'
          colour={Color.revenueHighlight}
          onClick={() => props.onClickOptions('revenue')}>
          <ActiveIcon active={disableRevenue} />
          <span>Revenue</span>

        </GroupOptions>

        <GroupOptions
          data-tip
          data-for='cost'
          colour={Color.costHighlight}
          onClick={() => props.onClickOptions('cost')}>
          <ActiveIcon active={disableCost} />
          <span>Cost</span>

        </GroupOptions>

        <MarginLeft>
          {!hideImportTemplate ? <PegButton
            id="jobGrid_import"
            type="button"
            variant="Primary"
            styleObject={{ marginLeft: 12 }}
            onClick={props.onClickImportTemplate}>
            Import Template
          </PegButton> : null}
          <PegButton
            id="filter_column"
            type="button"
            variant="Primary"
            styleObject={{ marginLeft: 12 }}
            onClick={onClickFilterColumn}>
            Preferences
          </PegButton>
        </MarginLeft>
      </Flex>
    </div>
  );
};

export default HeaderOptions;
