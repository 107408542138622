
import { GrFormClose } from "react-icons/gr";
import Service from './Service';
import Charge from './Charge';
import PegButton from 'Controls/Button/PegButton';
import {
  Overview,
  Header,
  Footer,
  Content
} from './details.styles';
type Props = {
  type?: string;
  onClose: () => void;
  service: any;
  charge?: any;
  serviceRequestDetails?: any;
};

const Detail = (props: Props) => {
  const {
    service,
    charge,
    serviceRequestDetails,
    type,
    onClose
  } = props;
  return (
    <Overview>
      <Header>
        <GrFormClose
          onClick={() => onClose()}
          className="icon-style" />
      </Header>
      <Content>
        <Service
          service={service}
          type={type}
          serviceRequestDetails={serviceRequestDetails}
        />
        {
          charge?.map((item: any, index: number) => {
            if (item.IsActive) {
              return (
                <Charge
                  key={'detail-charges' + index}
                  service={service}
                  charge={item}
                  type={type}
                />
              )
            } else {
              return null
            }

          })
        }
      </Content>
      <Footer>
        <PegButton
          id="import_template"
          type="button"
          variant="Primary"
          onClick={onClose}>
          Close
        </PegButton>
      </Footer>
    </Overview>
  )
};

export default Detail;
