import { connect } from 'react-redux';
import { updateRouteDetails } from 'Redux/Actions/route';
import RouteDetails from './RouteDetails';
import { IRoute } from 'Model/Order/Route';

const mapDispatchToProps = (dispatch: any) => ({
    updateRouteDetails: (routeDetails: IRoute[]) => dispatch(updateRouteDetails(routeDetails))
});

const mapStateToProps = (state: any) => ({
    freightDetails: state && state.freight && state.freight.FreightDetail ? state.freight.FreightDetail : state,
    routeDetails: state && state.route ? state.route.RouteDetails : []
});

export default connect(mapStateToProps, mapDispatchToProps)(RouteDetails);