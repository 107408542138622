
import {
  useCallback,
} from 'react';
import {
  Overview,
  GroupName,
  Flex,
  // list
  ID,
  Delete,
  Add,
  Expand,
  //revenue
  Quantity,
  UOM,
  UnitPrice,
  Currency,
  Amount,
  BillingCurrency,
  ExRate,
  Tax,
  SalesTaxAmt,
  BillAmount,
  //cost
  Supplier,
  EstUnitCost,
  EstCurrency,
  EstExRate,
  EstTaxType,
  EstTax,
  EstAmt,
  EstAmtTax,
  OperatingIncome,
  // eye_share
  EsActualCost,
  // remarks
  InternalRemarks,
  CostUOM,
  CostQty,
  //input checkbox
} from 'Containers/JobGrid/Jobs.styles'
import {
  NameHeader,
  Group,
  OverviewHeader,
  SecondaryGroup
} from '../../template.styles'
import { JOB_COLUMN } from 'Constants/ColumnFilterConstants';
type Props = {
  disableCost: boolean;
  disableOperatingIncome: boolean;
  disableRevenue: boolean;
  onSelectAll?: () => void;
  isAllSelected?: boolean;
  selectedProduct: any;
  isShowCostQty: boolean;
};

const Header = (props: Props) => {
  const {
    disableCost,
    disableOperatingIncome,
    disableRevenue,
    onSelectAll,
    isAllSelected,
    selectedProduct,
    isShowCostQty
  } = props;
  const styleClass = "text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50";

  const onClickCheckbox = useCallback(() => {
    onSelectAll && onSelectAll()
  }, []);

  return (
    <OverviewHeader className={styleClass}>
      <Overview>
        <Group isSticky showBorder>
          <GroupName />
          <ID isHeader={true} >
            <input
              type="checkbox"
              onChange={onClickCheckbox}
              checked={isAllSelected}
              style={{ cursor: 'pointer', marginRight: '8px' }} />
            {JOB_COLUMN.ID.label}
          </ID>
        </Group>
        <Group showBorder={true}>
          <GroupName />
          <Delete isHeader={true} />
        </Group>
        <Group showBorder={true}>
          <GroupName />
          <Add isHeader={true} />
        </Group>
        <Group showBorder={true}>
          <GroupName />
          <Expand isHeader={true} />
        </Group>
        <SecondaryGroup isSticky showBorder>
          <GroupName />
          <NameHeader isHeader={true} >
            {JOB_COLUMN.NAME.label}
          </NameHeader>
        </SecondaryGroup>
        {!disableOperatingIncome &&
          <Group groupColor="#FFC300">
            <GroupName>IN. INCOME</GroupName>
            <Flex>
              <OperatingIncome isHeader={true} >
                {JOB_COLUMN.OPERATING_INCOME.label}
              </OperatingIncome>
            </Flex>
          </Group>
        }
        {!disableRevenue &&
          <Group groupColor={'#ADD8E6'}>
            <GroupName>Revenue</GroupName>
            <Flex>
              <UOM isHeader={true} >
                {JOB_COLUMN.UOM.label}
              </UOM>
              <Quantity isHeader={true} >
                {JOB_COLUMN.QUANTITY.label}
              </Quantity>
              <UnitPrice isHeader={true} >
                {JOB_COLUMN.UNIT_PRICE.label}
              </UnitPrice>
              <Currency isHeader={true} >
                {JOB_COLUMN.CURRENCY.label}
              </Currency>
              <Amount isHeader={true} >
                {JOB_COLUMN.AMOUNT.label}
              </Amount>
              <BillingCurrency isHeader={true} >
                {JOB_COLUMN.BILLING_CURRENCY.label}
              </BillingCurrency>
              <ExRate isHeader={true} >
                {JOB_COLUMN.EX_RATE.label}
              </ExRate>
              <Tax isHeader={true} >
                {JOB_COLUMN.TAX_RATE.label}
              </Tax>
              <SalesTaxAmt isHeader={true} >
                {JOB_COLUMN.SALES_TAX_AMT.label}
              </SalesTaxAmt>
              <BillAmount isHeader={true} >
                {JOB_COLUMN.BILL_AMT.label}
              </BillAmount>
            </Flex>
          </Group>}
        {!disableCost &&
          <Group groupColor={'#00FF00'}>
            <GroupName>Cost</GroupName>
            <Flex>
              <Supplier isHeader={true} >
                {JOB_COLUMN.SUPPLIER.label}
              </Supplier>
              {isShowCostQty && <>
                <CostUOM isHeader={true} >
                  {JOB_COLUMN.COST_UOM.label}
                </CostUOM>
                <CostQty isHeader={true} >
                  {JOB_COLUMN.COST_QTY.label}
                </CostQty></>}
              <EstUnitCost isHeader={true} >
                {JOB_COLUMN.UNIT_COST.label}
              </EstUnitCost>
              <EstCurrency isHeader={true} >
                {JOB_COLUMN.COST_CURRENCY.label}
              </EstCurrency>
              <EstExRate isHeader={true} >
                {JOB_COLUMN.COST_EX_RATE.label}
              </EstExRate>
              <EstTaxType isHeader={true} >
                {JOB_COLUMN.COST_TAX_RATE.label}
              </EstTaxType>
              <EstTax isHeader={true} >
                {JOB_COLUMN.PURCHASE_TAX.label}
              </EstTax>
              <EstAmt isHeader={true} >
                {JOB_COLUMN.AMT.label}
              </EstAmt>
              <EstAmtTax isHeader={true} >
                {JOB_COLUMN.AMT_WITH_TAX.label}
              </EstAmtTax>
              <EsActualCost isHeader={true} >
                {JOB_COLUMN.ACTUAL_COST.label}
              </EsActualCost>
            </Flex>
          </Group>
        }
        {<Group>
          <GroupName />
          <InternalRemarks isHeader={true} >
            {JOB_COLUMN.INTERNAL_REMARKS.label}
          </InternalRemarks>
        </Group>}
      </Overview>
    </OverviewHeader>
  )
};

export default Header;
