import { DatePickerComponent, DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';


interface DatePickerProps {
  id: string;
  onChange: (event: any, id: any) => void;
  type?: string;
  index?: number;
  name?: string;
  enabled?: boolean;
  value?: string;
  style?: object;
  width?: number;
  isMandatory?: boolean;
  disableBorder?: boolean;
  format?: string;
  minDate?: Date;
  maxDate?: Date;
  timeFormat?: string;
  enableBorder?: boolean;
  dataTestId?: string;
}
export default function PegDatePicker(props: DatePickerProps) {
  const {
    id,
    onChange,
    type,
    width,
    enabled,
    disableBorder,
    isMandatory,
    name,
    value,
    style,
    format,
    minDate,
    maxDate,
    timeFormat,
    enableBorder,
    dataTestId,
  } = props;

  return (
    <div className={(isMandatory && !value) ? 'date-picker-border-red'
      : disableBorder ?
        'peg-date-picker-without-border' : 'peg-date-picker'} data-testid={dataTestId}>
      {type === 'dateTime' ?
        <DateTimePickerComponent
          id={id}
          data-testid={id}
          type={type}
          value={value ? new Date(value) : undefined}
          onChange={/* istanbul ignore next */(event: React.ChangeEvent<HTMLInputElement>) => onChange(event, id)}
          name={name}
          format={format}
          timeFormat={timeFormat}
          min={minDate}
          max={maxDate}
          style={style ? style : {}}
          width={width ? width : ''}
          enabled={enabled} />
        :
        <DatePickerComponent
          id={id}
          data-testid={id}
          type={type}
          format={format}
          min={minDate}
          max={maxDate}
          value={value ? new Date(value) : undefined}
          onChange={/* istanbul ignore next */(event: React.ChangeEvent<HTMLInputElement>) => onChange(event, id)}
          name={name}
          style={style ? style : {}}
          width={width ? width : ''}
          enabled={enabled} />
      }
    </div>

  )
}
