import {
  AlignSelf,
  Self
} from "Containers/BusinessSelection/styles";

interface Prop {
  title: string;
  description?: string;
  centerAlign?: boolean;
}

const WizardHeader = (props: Prop) => {

  const { title, description, centerAlign } = props;
  let ComponentMap = Self
  if (centerAlign) {
    ComponentMap = AlignSelf
  }
  return (
    <ComponentMap className={"flex flex-col"}>
      <h3 className={'text-black font-medium text-lg'}>{title}</h3>
      <h5 className={'text-medium font-light'}>{description}</h5>
    </ComponentMap>
  )
};

export default WizardHeader