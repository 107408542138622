import WizardHeader from 'Components/WizardHeader/WizardHeader';
import CrewTableView from './CrewTableView/CrewTableView';

const CrewDetail = () => {
  return (
    <div>
      <div className="grid grid-cols-2 gap-12 p-8">
        <div className={'col-span-2 flex-col'}>
          <WizardHeader title={"Crew Details"} description={"Enter crew details"} />
        </div>
        <CrewTableView />
      </div>
    </div>
  );
};

export default CrewDetail;
