import {
  UPDATE_SERVICE_GRID,
  GET_SERVICE_GRID,
  ADD_SERVICES,
  DELETE_SERVICES,
  ADD_CHARGES,
  DELETE_CHARGES,
  EDIT_CHARGES,
  EDIT_SERVICES
} from "Redux/ActionTypes/Service";

let initialState = {
  serviceObject: null,
  serviceList: [],
  serviceValidation: {},
  chargeValidation: {}
}

export const services = (state = initialState, action: any) => {
  switch (action.type) {

    case UPDATE_SERVICE_GRID:
      return {
        ...state,
        serviceList: [...action.services]
      };

    default:
      return state
  }
}