import React from 'react'

const CloseIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <path d="M24 3C12.3 3 3 12.3 3 24C3 35.7 12.3 45 24 45C35.7 45 45 35.7 45 24C45 12.3 35.7 3 24 3ZM24 42C14.1 42 6 33.9 6 24C6 14.1 14.1 6 24 6C33.9 6 42 14.1 42 24C42 33.9 33.9 42 24 42Z" fill="black" />
    <path d="M32.1 34.5L24 26.4L15.9 34.5L13.5 32.1L21.6 24L13.5 15.9L15.9 13.5L24 21.6L32.1 13.5L34.5 15.9L26.4 24L34.5 32.1L32.1 34.5Z" fill="black" />
</svg>

const HeaderIcon = () => <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.4062 1.0625H1.59375C0.713535 1.0625 0 1.77604 0 2.65625V14.3438C0 15.224 0.713535 15.9375 1.59375 15.9375H15.4062C16.2865 15.9375 17 15.224 17 14.3438V2.65625C17 1.77604 16.2865 1.0625 15.4062 1.0625ZM15.207 14.3438H1.79297C1.74013 14.3438 1.68946 14.3228 1.6521 14.2854C1.61474 14.248 1.59375 14.1974 1.59375 14.1445V2.85547C1.59375 2.80263 1.61474 2.75196 1.6521 2.7146C1.68946 2.67724 1.74013 2.65625 1.79297 2.65625H15.207C15.2599 2.65625 15.3105 2.67724 15.3479 2.7146C15.3853 2.75196 15.4062 2.80263 15.4062 2.85547V14.1445C15.4062 14.1974 15.3853 14.248 15.3479 14.2854C15.3105 14.3228 15.2599 14.3438 15.207 14.3438ZM13.8125 11.2891V12.0859C13.8125 12.306 13.6341 12.4844 13.4141 12.4844H6.77344C6.5534 12.4844 6.375 12.306 6.375 12.0859V11.2891C6.375 11.069 6.5534 10.8906 6.77344 10.8906H13.4141C13.6341 10.8906 13.8125 11.069 13.8125 11.2891ZM13.8125 8.10156V8.89844C13.8125 9.11847 13.6341 9.29688 13.4141 9.29688H6.77344C6.5534 9.29688 6.375 9.11847 6.375 8.89844V8.10156C6.375 7.88153 6.5534 7.70312 6.77344 7.70312H13.4141C13.6341 7.70312 13.8125 7.88153 13.8125 8.10156ZM13.8125 4.91406V5.71094C13.8125 5.93097 13.6341 6.10938 13.4141 6.10938H6.77344C6.5534 6.10938 6.375 5.93097 6.375 5.71094V4.91406C6.375 4.69403 6.5534 4.51562 6.77344 4.51562H13.4141C13.6341 4.51562 13.8125 4.69403 13.8125 4.91406ZM5.44531 5.3125C5.44531 5.97264 4.91014 6.50781 4.25 6.50781C3.58986 6.50781 3.05469 5.97264 3.05469 5.3125C3.05469 4.65236 3.58986 4.11719 4.25 4.11719C4.91014 4.11719 5.44531 4.65236 5.44531 5.3125ZM5.44531 8.5C5.44531 9.16014 4.91014 9.69531 4.25 9.69531C3.58986 9.69531 3.05469 9.16014 3.05469 8.5C3.05469 7.83986 3.58986 7.30469 4.25 7.30469C4.91014 7.30469 5.44531 7.83986 5.44531 8.5ZM5.44531 11.6875C5.44531 12.3476 4.91014 12.8828 4.25 12.8828C3.58986 12.8828 3.05469 12.3476 3.05469 11.6875C3.05469 11.0274 3.58986 10.4922 4.25 10.4922C4.91014 10.4922 5.44531 11.0274 5.44531 11.6875Z" fill="white" />
</svg>

const CopyIcon = () => <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.2 0.181824C2.77565 0.181824 2.36869 0.349588 2.06863 0.648211C1.76857 0.946833 1.6 1.35185 1.6 1.77417V9.73589C1.6 10.1582 1.76857 10.5632 2.06863 10.8618C2.36869 11.1605 2.77565 11.3282 3.2 11.3282H8C8.42435 11.3282 8.83131 11.1605 9.13137 10.8618C9.43143 10.5632 9.6 10.1582 9.6 9.73589V1.77417C9.6 1.35185 9.43143 0.946833 9.13137 0.648211C8.83131 0.349588 8.42435 0.181824 8 0.181824H3.2ZM2.4 1.77417C2.4 1.56301 2.48429 1.3605 2.63431 1.21119C2.78434 1.06188 2.98783 0.977996 3.2 0.977996H8C8.21217 0.977996 8.41566 1.06188 8.56569 1.21119C8.71571 1.3605 8.8 1.56301 8.8 1.77417V9.73589C8.8 9.94705 8.71571 10.1496 8.56569 10.2989C8.41566 10.4482 8.21217 10.5321 8 10.5321H3.2C2.98783 10.5321 2.78434 10.4482 2.63431 10.2989C2.48429 10.1496 2.4 9.94705 2.4 9.73589V1.77417ZM0 3.36651C7.13475e-06 3.087 0.0739406 2.81242 0.21437 2.57036C0.354799 2.3283 0.556775 2.1273 0.8 1.98754V10.134C0.8 10.6619 1.01071 11.1681 1.38579 11.5414C1.76086 11.9147 2.26957 12.1244 2.8 12.1244H7.7856C7.64517 12.3665 7.4432 12.5675 7.19998 12.7072C6.95675 12.847 6.68085 12.9206 6.4 12.9206H2.8C2.05739 12.9206 1.3452 12.627 0.820101 12.1044C0.294999 11.5818 0 10.873 0 10.134V3.36651Z" fill="black" fillOpacity="0.5" />
</svg>

const MesuresIcon = () => <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.6 0C4.298 0 0 4.02938 0 9C0 10.65 0.475 12.1956 1.302 13.525C1.489 13.8256 1.84533 14 2.21667 14H16.9833C17.3547 14 17.711 13.8256 17.898 13.525C18.725 12.1956 19.2 10.65 19.2 9C19.2 4.02938 14.902 0 9.6 0ZM9.6 2C10.0903 2 10.486 2.31656 10.6107 2.73906C10.5737 2.80969 10.5227 2.87125 10.4957 2.9475L10.1883 3.81219C10.0173 3.92125 9.82267 4 9.60033 4C9.01133 4 8.53367 3.55219 8.53367 3C8.53367 2.44781 9.011 2 9.6 2ZM3.2 11C2.611 11 2.13333 10.5522 2.13333 10C2.13333 9.44781 2.611 9 3.2 9C3.789 9 4.26667 9.44781 4.26667 10C4.26667 10.5522 3.789 11 3.2 11ZM4.8 6C4.211 6 3.73333 5.55219 3.73333 5C3.73333 4.44781 4.211 4 4.8 4C5.389 4 5.86667 4.44781 5.86667 5C5.86667 5.55219 5.389 6 4.8 6ZM13.0257 3.73719L10.9813 9.48719C11.4377 9.85406 11.7333 10.3919 11.7333 11C11.7333 11.3663 11.6207 11.7047 11.4373 12H7.76267C7.57933 11.7047 7.46667 11.3663 7.46667 11C7.46667 9.93937 8.35 9.08031 9.46333 9.01281L11.508 3.2625C11.647 2.87 12.099 2.65469 12.52 2.78844C12.939 2.91938 13.165 3.34437 13.0257 3.73719ZM13.5143 5.52469L14.0317 4.07C14.1473 4.02969 14.2693 4.00031 14.4 4.00031C14.989 4.00031 15.4667 4.44812 15.4667 5.00031C15.4667 5.5525 14.989 6.00031 14.4 6.00031C14.0207 6 13.7037 5.80406 13.5143 5.52469ZM16 11C15.411 11 14.9333 10.5522 14.9333 10C14.9333 9.44781 15.411 9 16 9C16.589 9 17.0667 9.44781 17.0667 10C17.0667 10.5522 16.589 11 16 11Z" fill="white" />
</svg>

const SuccessIcon = () => <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_21_5656)">
        <path d="M5.30063 11.6168L1.07563 7.39177C0.821796 7.13794 0.821796 6.72639 1.07563 6.47253L1.99484 5.55329C2.24867 5.29943 2.66026 5.29943 2.91409 5.55329L5.76025 8.39942L11.8564 2.30329C12.1102 2.04946 12.5218 2.04946 12.7757 2.30329L13.6949 3.22253C13.9487 3.47636 13.9487 3.88792 13.6949 4.14177L6.21987 11.6168C5.96601 11.8706 5.55446 11.8706 5.30063 11.6168Z" fill="#666666" />
    </g>
    <defs>
        <clipPath id="clip0_21_5656">
            <rect width="13" height="13" fill="white" transform="translate(0.885254 0.460083)" />
        </clipPath>
    </defs>
</svg>

export {
    CloseIcon,
    HeaderIcon,
    CopyIcon,
    MesuresIcon,
    SuccessIcon
}