import { FreightDetail } from "Model/Order/FreightDetails"
import { RESET_FREIGHT_STATE } from "Redux/ActionTypes/ActionTypes";
import * as actionTypes from '../ActionTypes/ActionTypes';

let initialState = {
  FreightDetail: new FreightDetail()
}
export const freight = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.FREIGHT_DETAILS: {
      return { ...state, FreightDetail: action.data }
    }

    case RESET_FREIGHT_STATE:
      return {
        FreightDetail: new FreightDetail()
      };

    default:
      return state;
  }
}