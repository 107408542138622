/* eslint-disable react-hooks/exhaustive-deps */

import Modal from "react-modal";
import React, { useRef } from "react";
import {
  customModalStyles,
  customModalCenterStyles,
  StyledSaveEvents,
  StyledError,
  ModalSection,
  ModalBody,
  StyledForm,
  ModalFooter,
  ModalTitle,
  ModalHeader,
  Content,
  customModalSideStyles
} from './modal.styles'

interface IModalProps {
  isOpen: boolean;
  contentLabel?: string;
  content?: string;
  onSubmit?: () => void;
  onCancel?: () => void;
  closeModal?: () => void;
  buttonText?: string;
  autoWidth?: any;
  modalTitle?: any;
  modalContent?: any;
  modalSubContent?: any;
  hasErrored?: any;
  tertiaryMsg?: any;
  tertiaryAction?: () => void;
  disableConfirmButton?: boolean;
  showTemplate?: true;
  children?: React.ReactNode;
  isCenter?: boolean;
  title?: any;
  alertModal?: boolean;
  disableHeader?: boolean;
  disableFooter?: boolean;
  dataTestId?: string;
  isScroll?: boolean;
  isSide?: boolean
}
const PegModal = (props: IModalProps) => {
  const {
    isOpen,
    contentLabel,
    closeModal,
    modalContent,
    modalTitle,
    modalSubContent,
    hasErrored,
    tertiaryMsg,
    tertiaryAction,
    disableConfirmButton,
    buttonText,
    showTemplate,
    isCenter,
    title,
    alertModal,
    disableHeader,
    disableFooter,
    dataTestId,
    isSide = false
  } = props

  const customStyle = (isCenter && customModalCenterStyles) || (isSide && customModalSideStyles) || customModalStyles

  return (
    <React.Fragment>
      {
        isOpen &&
        <Modal
          ariaHideApp={false}
          data-testid={dataTestId}
          isOpen={isOpen}
          onRequestClose={closeModal}
          style={customStyle}

          contentLabel={contentLabel}>
          {
            (!disableHeader && !alertModal) &&
            <ModalHeader>
              {modalTitle ? modalTitle : 'Modal'}
            </ModalHeader>
          }
          {/* dont add maxWidth handle that inside the container */}
          <StyledForm onSubmit={ /* istanbul ignore next */(e) => e.preventDefault()}>
            <ModalBody>
              {
                showTemplate ?
                  <>{props.children}</>
                  :
                  <Content>
                    {title &&
                      <ModalTitle> {title} </ModalTitle>
                    }
                    {modalContent &&
                      <ModalSection>
                        {modalContent}
                      </ModalSection>
                    }
                    {modalSubContent && <ModalSection>
                      {modalSubContent}
                    </ModalSection>}
                  </Content>
              }
              {hasErrored &&
                <StyledError>Something went wrong</StyledError>}
            </ModalBody>
          </StyledForm>

          {
            !alertModal &&
            <ModalFooter rightAlign={true} className="flex">
              <StyledSaveEvents secondary onClick={closeModal} className="flex ml10">
                {tertiaryMsg ? tertiaryMsg : "Cancel"}
              </StyledSaveEvents>
              {
                !disableConfirmButton &&
                <StyledSaveEvents primary onClick={tertiaryAction ? tertiaryAction : closeModal} className="flex ml10" data-testid="deleteServiceCharge">
                  {buttonText ? buttonText : ''}
                </StyledSaveEvents>
              }
            </ModalFooter>
          }

        </Modal>
      }
    </React.Fragment>
  )
};

export default PegModal;
