import React from 'react';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "Utils/AuthConfig";
import { Wrapper, Button, GacLogo, Container, Title, BtnContainer } from './login.styles';
import gacLogo from 'Assets/GAC.svg'


const SignInButton = () => {
    const { instance } = useMsal();
    const handleLogin = async (loginType: string) => {
        if (loginType === "popup") {
            await instance.loginPopup(loginRequest);
        } else if (loginType === "redirect") {
            await instance.loginRedirect(loginRequest);
        } else if (loginType === "ssoSilent") {
            // await instance.ssoSilent(loginRequest);
            instance.acquireTokenSilent(loginRequest).then(function (response) {
                // call API
            }).catch(function (error) {
                // call acquireTokenPopup in case of acquireTokenSilent failure
                // due to consent or interaction required
                if (error.errorCode === "consent_required"
                    || error.errorCode === "interaction_required"
                    || error.errorCode === "login_required" || error.errorCode === 'no_account_error') {
                    instance.acquireTokenPopup(loginRequest).then(
                        function (response) {
                            // call API
                        })
                }
            });
        }
    }

    return (
        <Wrapper>
            <Container>
                <GacLogo src={gacLogo} />
                <Title>GAC Pegasus</Title>
            </Container>
            <BtnContainer>
                <Button onClick={() => handleLogin("ssoSilent")} key="loginRedirect">Login</Button>
                <Button color="#425E9A">Contact Support</Button>
            </BtnContainer>
        </Wrapper>

    )
};
export default SignInButton;