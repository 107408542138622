const VoyageRouteHeader = () => {
  return (
    <div className="grid grid-cols-12 mb-2 gap-4">
      <div className={'col-span-1 flex flex-col space-y-2'}>
        <span className="text-sm font-semibold text-dark"></span>
      </div>
      <div className={'col-span-2 flex flex-col space-y-2'}>
        <label className="text-sm font-semibold text-dark">Port</label>
      </div>

      <div className={'col-span-3 flex flex-col space-y-3'}>
        <label className="text-sm font-semibold text-dark">Terminal</label>
      </div>

      <div className={'col-span-2 flex flex-col space-y-2'}>
        <label className="text-sm font-semibold text-dark">Berth</label>
      </div>

      <div className={'col-span-2 flex flex-col space-y-2'}>
        <label className="text-sm font-semibold text-dark">Agent</label>
      </div>

      <div className={'col-span-2 flex flex-col space-y-2'}>
        <span className="text-sm font-semibold text-dark"></span>
      </div>
    </div>
  );
};

export default VoyageRouteHeader;
