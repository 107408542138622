import { Bulk } from './BulkCargo';
import { Container } from './Container';
import { Package } from './Package';

export interface ICargoDetail {
  CargoId: number;
  Version: number;
  CommodityType: string;
  CommodityName: string;
  HSCode: string;
  HSName: string;
  ReadinessDate: Date | null;
  DangerousCargo: string;
  CreatedAt: Date;
  CreatedBy: string;
  ModifiedAt: Date | null;
  ModifiedBy: string;
  IsActive: boolean;
  PackageDetail: Package[];
  ContainerDetail: Container[];
  BulkCargoDetail: Bulk[];
}

export class CargoDetail implements ICargoDetail {
  CargoId: number;
  Version: number;
  CommodityType: string;
  CommodityName: string;
  HSCode: string;
  HSName: string;
  ReadinessDate: Date | null;
  DangerousCargo: string;
  CreatedAt: Date;
  CreatedBy: string;
  ModifiedAt: Date | null;
  ModifiedBy: string;
  IsActive: boolean;
  PackageDetail: Package[];
  ContainerDetail: Container[];
  BulkCargoDetail: Bulk[];

  constructor() {
    this.CargoId = 0;
    this.Version = 0;
    this.CommodityName = '';
    this.CommodityType = '';
    this.HSCode = '';
    this.HSName = '';
    this.DangerousCargo = '';
    this.ReadinessDate = new Date();
    this.CreatedAt = new Date();
    this.CreatedBy = '';
    this.ModifiedAt = new Date();
    this.ModifiedBy = '';
    this.IsActive = true;
    this.PackageDetail = [];
    this.ContainerDetail = [];
    this.BulkCargoDetail = [];
  }
}
