import PegLabel from 'Controls/Label/PegLabel';
import PegInput from 'Controls/Input/PegInputBox';
import PegDatePicker from 'Controls/DateRange/PegDatePicker';
import Dropdown from 'Controls/Dropdown';
import PegRadioButton from 'Controls/Radio/PegRadioButton';
import { useCallback } from 'react';
type IProps = {
  cargo?: any;
  onUpdate: (key: string, value: any) => void;
};
const CargoInfo = (props: IProps) => {
  const {
    onUpdate,
    cargo
  } = props

  const onChange = useCallback((event: any, key: string) => {
    onUpdate(key, event)
  }, []);

  return (
    <div className="grid grid-cols-2 gap-12">
      <div className="col-span-1">
        <div className="flex flex-row items-center space-y-4">
          <PegLabel id="lblCommodityType" name="commodityType">
            Commodity Type
          </PegLabel>
          <div className="w-8/12">
            <Dropdown
              id={'cargoCommodity'}
              placeHolder={'Select Commodity'}
              isLookup={true}
              dropdownLabel={'name'}
              dropdownValue={'code'}
              appendParams={false}
              getFormattedLabel={true}
              isMandatory={true}
              defaultValue={cargo?.CommodityType}
              url={`/mdm-commodity?searchText=`}
              onChangeDropdown={(event: any) => onChange(event, 'commodityType')}
            />
          </div>
        </div>

        <div className="flex flex-row items-center space-y-4">
          <PegLabel id="lblCommodityName" name="commodityName">
            Commodity Name
          </PegLabel>
          <div className="w-8/12">
            <PegInput
              type={'text'}
              id={'txtCommodityName'}
              name={'commodityName'}
              value={cargo?.CommodityName}
              defaultValue={cargo?.CommodityName}
              onChange={(event: any) => onChange(event, 'commodityName')} />
          </div>
        </div>

        <div className="flex flex-row items-center space-y-4">
          <PegLabel id="lblHSCode" name="hsCode">
            HS Code
          </PegLabel>
          <div className="w-8/12">
            <Dropdown
              id={'hsCode'}
              placeHolder={'Select HS Code'}
              isLookup={true}
              dropdownLabel={'name'}
              dropdownValue={'code'}
              appendParams={false}
              getFormattedLabel={true}
              dropdownMap={'identifiers'}
              defaultValue={cargo?.HSCode}
              isMandatory={true}
              url={`/mdm-hscode?searchText=`}
              onChangeDropdown={(event: any) => onChange(event, 'hsCode')}
            />
          </div>
        </div>
      </div>

      <div className="col-span-1">
        <div className="flex flex-row items-center space-y-4">
          <PegLabel id="lblCargoReadinessDate" name="cargoReadinessDate">
            Cargo Readiness Date
          </PegLabel>
          <span className="w-4/12">
            <PegDatePicker
              id={'txtCargoReadinessDate'}
              name={'cargoReadinessDate'}
              type={'Date'}
              value={cargo?.ReadinessDate}
              onChange={(event: any) => onChange(event, 'readinessDate')} />
          </span>
        </div>
        <div className="flex flex-row items-center space-y-4">
          <PegLabel id="lblDangerousCargo" name="dangerousCargo">
            Dangerous Cargo
          </PegLabel>
          <div className="w-8/12">
            <div className="inline-flex items-center mr-8">
              <PegRadioButton
                id={'radioDangerousCargoYes'}
                onChange={(event: any) => onChange(event, 'dangerousYes')}
                name={'dangerousCargo'}
                option={'Yes'}
                value={'yes'} />
            </div>
            <div className="inline-flex items-center">
              <PegRadioButton
                id={'radioDangerousCargoNo'}
                onChange={(event: any) => onChange(event, 'dangerousNo')}
                name={'dangerousCargo'}
                option={'No'}
                value={'no'} />
            </div>
            <div className="flex items-center"></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CargoInfo;