import {
  GET_USER_DETAIL,
  UPDATE_USER_DETAIL
} from '../ActionTypes/User'

var initialState = {
  userDetail: null,
  isLoading: true
}

export const user = (state = initialState, action: any) => {
  switch (action.type) {

    case GET_USER_DETAIL:
      return {
        ...state,
        isLoading: true
      };

    case UPDATE_USER_DETAIL:
      return {
        ...state,
        userDetail: action.payload,
        isLoading: false
      };

    default:
      return state
  }
}