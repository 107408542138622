export interface IContainer {
  ContainerId: number;
  Version: number;
  ContainerCode: string;
  ContainerName: string;
  ContainerTypeCode: string;
  ContainerTypeName: string;
  ContainerSize: number | null;
  Quantity: number | null;
  GrossWeight: number | null;
  WeightUomCode: string;
  WeightUomName: string;
  CreatedAt: Date;
  CreatedBy: string;
  ModifiedAt: Date | null;
  ModifiedBy: string;
  IsActive: boolean;
}

export class Container implements IContainer {
  ContainerId: number;

  Version: number;

  ContainerCode: string;

  ContainerName: string;

  ContainerTypeCode: string;

  ContainerTypeName: string;

  ContainerSize: number | null;

  Quantity: number | null;

  GrossWeight: number | null;

  WeightUomCode: string;

  WeightUomName: string;

  CreatedAt: Date;

  CreatedBy: string;

  ModifiedAt: Date | null;

  ModifiedBy: string;

  IsActive: boolean;

  constructor() {

    this.ContainerId = 0;
    this.Version = 0;
    this.ContainerCode = '';
    this.ContainerName = '';
    this.ContainerTypeCode = '';
    this.ContainerTypeName = '';
    this.ContainerSize = 0;
    this.Quantity = 0;
    this.GrossWeight = 0;
    this.WeightUomCode = '';
    this.WeightUomName = '';
    this.CreatedAt = new Date();
    this.CreatedBy = '';
    this.ModifiedAt = new Date();
    this.ModifiedBy = '';
    this.IsActive = true;
  }
}
