
import {
  GridOverview
} from '../price.syles';
import List from './List';
import Header from './Header';
import Loader from 'Components/Loader';
import { useEffect, useState } from 'react';
import axios from 'axios';

type Props = {
  priceSummary: any;
  updatePricingSummary: (id: string, key: number, value: any) => void;
  onSelectPriceSummary: (id: number) => void;
};

const PriceGrid = (props: Props) => {
  const {
    priceSummary,
    updatePricingSummary,
    onSelectPriceSummary,
  } = props;
  const [bankOptions, setBankOptions] = useState([])

  useEffect(() => {
    //jobDetail.LocalJobId
    axios.get(`oum-get-bank-account?company-code=GDUB`).then((response) => {
      if (response) {
        setBankOptions(response.data)
      } else {
        setBankOptions([])
      }
    })
  }, [])

  if (!priceSummary.priceSummaryData) {
    return <div><Loader /></div>;
  }
  return (
    <GridOverview>
      <Header />
      {priceSummary?.priceSummaryData.map((item: any, index: number) => {
        return (
          <List
            key={index}
            priceIndex={index}
            priceSummary={item}
            bankOptions={bankOptions}
            onSelectPriceSummary={onSelectPriceSummary}
            updatePricingSummary={updatePricingSummary}
          />
        )
      })}
    </GridOverview>
  )
};

export default PriceGrid;
