
import {
  FooterOverview,
  Left,
  Right,
  AlertMessage
} from "../quotation.styles";
import PegButton from 'Controls/Button/PegButton';

type IProps = {
  onClickNext?: () => void;
  onClickPrevious?: () => void;
  saveAs?: () => void;
  selectedTab?: any;
  selectedBusiness?: any;
  errorCount: number;
};

const Footer = (props: IProps) => {
  const {
    selectedTab,
    onClickNext,
    onClickPrevious,
    saveAs,
    selectedBusiness,
    errorCount
  } = props
  return (
    <FooterOverview>
      <Left>
        {(selectedTab !== 'services' && selectedTab) && <PegButton
          id="footer-back"
          type="button"
          variant="OutlinePrimary">
          {selectedTab === 'customer' ? 'Exit' : 'Save as'}
        </PegButton>}
      </Left>
      <Right>
        {(errorCount > 0) && <AlertMessage>Fill the required fields</AlertMessage>}
        {
          selectedTab &&
          <PegButton
            id="footer-back"
            type="button"
            disabled={selectedTab === 'customer'}
            onClick={() => onClickPrevious && onClickPrevious()}
            variant="OutlinePrimary">
            Back
          </PegButton>
        }
        <PegButton
          id="footer-save"
          type="button"
          disabled={!selectedBusiness.length}
          onClick={() => onClickNext && onClickNext()}
          styleObject={{ marginLeft: 12 }}
          variant="Primary">
          {!selectedTab ? 'Proceed' : selectedTab === 'services' ? 'Save' : 'Next'}
        </PegButton>
      </Right>
    </FooterOverview>
  );
};

export default Footer;
