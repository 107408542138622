import React, { useRef, useState } from 'react';
import { RiArrowDownSLine } from 'react-icons/ri';


interface AccordionProps {
    title: React.ReactNode
    children: React.ReactNode
    className?: string;
    dataTestId?: string;
}

export const AccordionComponent: React.FC<AccordionProps> = ({ title, children, className, dataTestId }) => {

    const contentSpace = useRef(null)
    const [active, setActive] = useState(false)
    const [height, setHeight] = useState(`100vh`)
    const [rotate, setRotate] = useState('transform duration-700 ease')

    function toggleAccordion() {
        setActive(active === false ? true : false)
        // @ts-ignore
        setHeight(active ? `${contentSpace.current.scrollHeight}px` : '0px')
        setRotate(active ? 'transform duration-700 ease' : 'transform duration-700 ease rotate-180')
    }

    return (
        <div className="flex flex-col mb-4 ">
            <button
                data-testid={dataTestId}
                className={`flex flex-row shadow-sm justify-between items-center py-2 px-4 ${active ? "rounded-md" : "rounded-t-md"} w-full border-light-shade border text-dark bg-light hover:bg-primary hover:text-white box-border appearance-none cursor-pointer focus:outline-none`}
                onClick={toggleAccordion}
            >
                <p className="inline-block text-footnote light">{title}</p>
                <RiArrowDownSLine className={`${rotate} inline-block  right`} />
            </button>
            <div
                ref={contentSpace}
                data-testid={title}
                style={{ maxHeight: `${height}`, backgroundColor: 'white' }}
                className={className ? className : `overflow-auto transition-max-height duration-700 ease-in-out rounded-b-md`}
            >
                {children}
            </div>
        </div>
    )
}