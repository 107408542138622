
import {
  TabView,
  TabOption,
  Title
} from '../quotation.styles'

type IProps = {
  label?: any;
  onClick: (tab: any) => void;
  route?: any;
  match?: any;
  selectedTab?: any;
  enableKey?: string;
  isTabEnabled?: any;
  header?: any;
  completedStatus?: any;
};

const Tab = (props: IProps) => {
  const {
    label,
    route,
    onClick,
    selectedTab,
    header,
    completedStatus
  } = props
  const isTabSelected = selectedTab === route;
  return (
    <TabView isSelected={isTabSelected} isCompleted={completedStatus}>
      <TabOption
        // onClick={() => onClick(route)}
        isEnabled={true}>
        <div>{label}</div>
        <Title>{header}</Title>
      </TabOption>
    </TabView >
  );
};

export default Tab;