import React, { useState, useRef, useCallback } from 'react';
import {
  GoogleMap,
  Polygon,
  DrawingManager
} from "@react-google-maps/api";
import {
  MapOverview
} from '../editor.styles';

type Props = {
  center: any;
  terminalPolygon: any[];
  berthPolygon: any[];
  portPolygon: any[];
  selectedBerth: any;
  selectedTerminal: any;
  onDrawPort: (path: any) => void;
  onDrawBerth: (path: any) => void;
  onDrawTerminal: (path: any) => void;
};

const MapView = (props: Props) => {
  const {
    onDrawTerminal,
    onDrawBerth,
    onDrawPort,
    center,
    terminalPolygon,
    berthPolygon,
    portPolygon,
    selectedBerth,
    selectedTerminal
  } = props;
  const polygonRef = useRef<any>(null);
  const listenersRef = useRef<any>([]);
  const [state] = useState<any>({
    drawingMode: "polygon"
  });

  // drawing polygon

  const options: any = {
    drawingControl: false,
    drawingControlOptions: {
      drawingModes: ["polygon"]
    },
    polygonOptions: {
      fillColor: `#2196F3`,
      strokeColor: `#2196F3`,
      fillOpacity: 0.5,
      strokeWeight: 2,
      clickable: true,
      editable: true,
      draggable: true,
      zIndex: 1
    }
  };

  const onPolygonComplete = useCallback(
    function onPolygonComplete(poly: any) {
      const polyArray = poly.getPath().getArray();
      let paths: any = [];
      polyArray.forEach(function (path: any) {
        paths.push({ lat: path.lat(), lng: path.lng() });
      });

      if (selectedTerminal && selectedBerth) {
        onDrawBerth(paths)
      } else if (selectedTerminal && portPolygon?.length) {
        onDrawTerminal(paths);
      } else {
        onDrawPort(paths);
      }
      poly.setMap(null);
    },
    [selectedTerminal, selectedBerth]
  );

  const onEdit = useCallback((_event: any) => {
    if (polygonRef.current) {
      const nextPath: any = polygonRef.current
      if (nextPath) {
        const coords = nextPath.getPath().getArray().map((coord: any) => {
          return {
            lat: coord.lat(),
            lng: coord.lng()
          }
        });
        if (selectedTerminal && selectedBerth) {
          onDrawBerth(coords)
        } else if (selectedTerminal && portPolygon?.length) {
          onDrawTerminal(coords);
        } else {
          onDrawPort(coords);
        }
      }
    }
  }, [onDrawTerminal, onDrawBerth]);

  const onLoad = useCallback((polygon: any) => {
    polygonRef.current = polygon;
    const path: HTMLElement | any = polygon.getPath();
    if (path) {
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    }
  }, [onEdit]
  );

  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis: any) => lis.remove());
    polygonRef.current = null;
  }, []);

  return (
    <MapOverview>
      <GoogleMap
        id="map-canvas"
        mapContainerClassName="app-map"
        center={center}
        zoom={12}>
        <div key={'polygon-PORT'}>
          <Polygon
            editable
            path={portPolygon}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{
              fillColor: '#D21404',
              fillOpacity: 0.4,
              strokeColor: '#D21404',
              strokeOpacity: 1,
              strokeWeight: 1,
            }}
          />
        </div>
        <div key={'polygon-teminal'}>
          <Polygon
            editable
            path={terminalPolygon}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{
              fillColor: '#FDCE2A',
              fillOpacity: 0.6,
              strokeColor: '#FDCE2A',
              strokeOpacity: 1,
              strokeWeight: 1,
            }}
          />
        </div>
        <div key={'polygon-berth'}>
          <Polygon
            editable
            path={berthPolygon}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{
              fillColor: '#63C5DA',
              fillOpacity: 0.6,
              strokeColor: '#63C5DA',
              strokeOpacity: 1,
              strokeWeight: 1,
            }}
          />
        </div>
        {
          <DrawingManager
            drawingMode={state.drawingMode}
            onPolygonComplete={onPolygonComplete}
            options={options}
          />
        }
      </GoogleMap >
    </MapOverview >
  )
};

export default React.memo(MapView)

