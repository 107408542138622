import {
  Overview
} from 'Containers/JobGrid/Jobs.styles';
import ServiceViewMode from './ServiceViewMode'
import ChargeViewMode from './ChargeViewMode'

type Props = {
  service: any;
  charges: any;
  index: number;
  onSelectServices?: any;
};

const Service = (props: Props) => {
  const {
    service,
    charges,
    index
  } = props;
  return (
    <>
      <Overview>
        <ServiceViewMode
          service={service}
          index={index}
        />
      </Overview>
      {
        charges?.map((charge: any, childIndex: number) => {
          return (
            <Overview key={childIndex}>
              <ChargeViewMode
                service={service}
                index={childIndex}
                servIndex={index}
                charge={charge} />
            </Overview>
          )
        })
      }
    </>
  )
};

export default Service;
