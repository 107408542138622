export const FREIGHT_DETAILS = 'FREIGHT_DETAILS';
export const ROUTE_DETAILS = 'ROUTE_DETAILS';
export const RESET_QUOTATION_STATE = 'RESET_QUOTATION_STATE';
export const RESET_CUSTOMER_STATE = 'RESET_CUSTOMER_STATE';
export const RESET_NEW_CUSTOMER_STATE = 'RESET_NEW_CUSTOMER_STATE';
export const RESET_CREW_STATE = 'RESET_CREW_STATE';
export const RESET_CARGO_STATE = 'RESET_CARGO_STATE';
export const RESET_ROUTE_STATE = 'RESET_ROUTE_STATE';
export const RESET_FREIGHT_STATE = 'RESET_FREIGHT_STATE';
export const RESET_SERVICE_STATE = 'RESET_SERVICE_STATE';
export const RESET_VESSEL_STATE = 'RESET_VESSEL_STATE';

// Job Grid

export const SET_JOB_GRID = 'SET_JOB_GRID';
export const SET_ERROR = 'SET_ERROR';
export const SET_VOUCHER_LIST = 'SET_VOUCHER_LIST';
export const SET_JOB_GRID_LOADING = 'SET_JOB_GRID_LOADING';
export const SET_SAVE_DISABLED = 'SET_SAVE_DISABLED';
export const SAVE_JOB_GRID = 'SAVE_JOB_GRID';
export const ADD_SERVICES_JOB_GRID = 'ADD_SERVICES_JOB_GRID';
export const ADD_SERVICE_LOADING = 'ADD_SERVICE_LOADING';
export const ADD_CHARGE_LOADING = 'ADD_CHARGE_LOADING';
export const DELETE_SERVICES_JOB_GRID = 'DELETE_SERVICES_JOB_GRID';
export const ADD_CHARGES_JOB_GRID = 'ADD_CHARGES_JOB_GRID';
export const DELETE_CHARGES_JOB_GRID = 'DELETE_CHARGES_JOB_GRID';
export const EDIT_JOB_GRID = 'EDIT_JOB_GRID';
export const BULK_DELETE_SERVICES = 'BULK_DELETE_SERVICES';
export const BULK_EDIT_CHARGES = 'BULK_EDIT_CHARGES';
export const UPDATE_JOB_SERVICES = 'UPDATE_JOB_SERVICES';
export const UPDATE_JOB_CHARGES = 'UPDATE_JOB_CHARGES';
export const ON_SAVE_JOB_LIST = "ON_SAVE_JOB_LIST";
export const ON_SAVE_LOADING = "ON_SAVE_LOADING";
export const ON_SAVED = "ON_SAVED";
export const UPDATE_JOB_SERVICES_DETAILS = "UPDATE_JOB_SERVICES_DETAILS";
export const ON_BULK_UPDATE = "ON_BULK_UPDATE";
export const ON_ADD_SERVICE_TEMPLATE = "ON_ADD_SERVICE_TEMPLATE";
export const UPDATE_JOB_GRID = "UPDATE_JOB_GRID";
export const UPDATE_ON_APPLY_TARIFF = "UPDATE_ON_APPLY_TARIFF";
export const SET_EXCHANGE_RATE = "SET_EXCHANGE_RATE";
export const RESET_JOB_SERVICES = "RESET_JOB_SERVICES";
export const UPDATE_EDIT_JOB_SERVICES = "UPDATE_EDIT_JOB_SERVICES";
export const SET_REFRESH_MESSAGE = "SET_REFRESH_MESSAGE";
export const UPDATE_JOB_CHARGE_VALIDATION = "UPDATE_JOB_CHARGE_VALIDATION";
// service template

export const SET_SERVICE_TEMPLATE = 'SET_SERVICE_TEMPLATE';
export const UPDATE_SERVICE_TEMPLATE = 'UPDATE_SERVICE_TEMPLATE';
export const ADD_SERVICE_TEMPLATE = 'ADD_SERVICE_TEMPLATE';
export const IS_SERVICE_TEMPLATE_SERVICE_SAVING = 'IS_SERVICE_TEMPLATE_SERVICE_SAVING';
export const SET_SERVICE_ERROR = 'SET_SERVICE_ERROR';
export const SET_SERVICE_LOADING = 'SET_SERVICE_LOADING'
export const SET_VISIBILITY_GRID_LOADING = 'SET_VISIBILITY_GRID_LOADING';
export const SET_VISIBILITY_GRID_DATA = 'SET_VISIBILITY_GRID_DATA';
export const GET_VISIBILITY_GRID_DATA = 'GET_VISIBILITY_GRID_DATA';
export const DELETE_SERVICE_VISIBILITY = 'DELETE_SERVICE_VISIBILITY';
export const REFRESH_SERVICE_VISIBILITY = 'REFRESH_SERVICE_VISIBILITY';

// Quotation Grid 

export const SET_QUOTATION_SERVICES = 'SET_QUOTATION_SERVICES';
export const SET_QUOTATION_ERROR = 'SET_QUOTATION_ERROR';
export const UPDATE_QUOTATION_SERVICE = 'UPDATE_QUOTATION_SERVICE';
export const UPDATE_QUOTATION_CHARGE = 'UPDATE_QUOTATION_CHARGE';
export const ON_ADD_QUOTATION_CHARGE = 'ON_ADD_QUOTATION_CHARGE';
export const ON_ADD_QUOTATION_SERVICE = 'ON_ADD_QUOTATION_SERVICE';
export const ON_DELETE_QUOTATION_SERVICE = 'ON_DELETE_QUOTATION_SERVICE';
export const ON_DELETE_QUOTATION_CHARGE = 'ON_DELETE_QUOTATION_CHARGE';
export const ON_BULK_DELETE_QUOTATION = 'ON_BULK_DELETE_QUOTATION';
export const ON_BULK_EDIT_QUOTATION = 'ON_BULK_EDIT_QUOTATION';
export const UPDATE_SELECTED_PRODUCT = 'UPDATE_SELECTED_PRODUCT';
export const ON_SAVE_QUOTATION_SERVICES = 'ON_SAVE_QUOTATION_SERVICES';
export const IS_QUOTATION_SERVICES_SAVING = 'IS_QUOTATION_SERVICES_SAVING';
export const ON_QUOTATION_SAVED = 'ON_QUOTATION_SAVED';
export const ON_BULK_QUOTATION_UPDATE = 'ON_BULK_QUOTATION_UPDATE';
export const REFRESH_QUOTATION_SERVICES = 'REFRESH_QUOTATION_SERVICES';
export const ON_IMPORT_TEMPLATE = "ON_IMPORT_TEMPLATE";
export const UPDATE_ON_APPLY_TARIFF_QUOTATION = "UPDATE_ON_APPLY_TARIFF_QUOTATION";
export const BULK_DELETE_QUOTATION_SERVICES = "BULK_DELETE_QUOTATION_SERVICES";
export const RESET_QUOTATION_SERVICES = "RESET_QUOTATION_SERVICES";
export const UPDATE_QUOTE_CHARGE_VALIDATION = "UPDATE_QUOTE_CHARGE_VALIDATION";
export const UPDATE_QUOTATION_SERVICES_DETAILS = 'UPDATE_QUOTATION_SERVICES_DETAILS';


// service template grid

export const SET_BUSINESS_PRODUCT = 'SET_BUSINESS_PRODUCT';
export const UPDATE_BUSINESS_PRODUCT = 'UPDATE_BUSINESS_PRODUCT';
export const ON_ADD_TEMPLATE_SERVICE = 'ON_ADD_TEMPLATE_SERVICE';
export const ON_DELETE_TEMPLATE_SERVICE = 'ON_DELETE_TEMPLATE_SERVICE';
export const ON_ADD_TEMPLATE_CHARGE = 'ON_ADD_TEMPLATE_CHARGE';
export const ON_DELETE_TEMPLATE_CHARGE = 'ON_DELETE_TEMPLATE_CHARGE';
export const ON_UPDATE_TEMPLATE_CHARGE = 'ON_UPDATE_TEMPLATE_CHARGE';
export const ON_UPDATE_TEMPLATE_SERVICE = 'ON_UPDATE_TEMPLATE_SERVICE';
export const ON_BULK_DELETE_SERVICE = 'ON_BULK_DELETE_SERVICE';
export const ON_UPDATE_BUSINESS_PRODUCT_DETAILS = 'ON_UPDATE_BUSINESS_PRODUCT_DETAILS';
export const ON_UPDATE_BUSINESS_PRODUCT_SERVICES = 'ON_UPDATE_BUSINESS_PRODUCT_SERVICES';
export const ON_SAVE_SERVICE_CHARGES = 'ON_SAVE_SERVICE_CHARGES';
export const ON_UPDATE_OP_TAGS = 'ON_UPDATE_OP_TAGS';
export const ON_BULK_UPDATE_SERVICE_CHARGES = "ON_BULK_UPDATE_SERVICE_CHARGES";
export const ON_BULK_DELETE_SERVICE_CHARGES = "ON_BULK_DELETE_SERVICE_CHARGES";
export const SET_IS_DUPLICATE = "SET_IS_DUPLICATE";
