import {
  QuotationBoxOverview,
  Description,
  Title,
  Icon,
  IconPosition
} from "../styles"
import { RiCheckLine } from 'react-icons/ri';

interface Prop {
  title: string;
  icon?: any;
  description: String;
  clickEvent?: any;
  onSelect?: any;
  type: string;
  isSelected?: boolean
}

export default function QuotationType(props: Prop) {
  const {
    title,
    description,
    onSelect,
    isSelected,
    type,
    icon
  } = props
  const IconType = icon
  return (
    <QuotationBoxOverview onClick={() => onSelect(type)} isSelected={isSelected}>
      <IconPosition> {isSelected && <RiCheckLine style={{ height: 24, width: 24, color: '#1d659c' }} />}</IconPosition>
      <Icon><IconType style={{ height: 54, width: 54, color: isSelected ? '#1d659c' : '#000' }} /></Icon>
      <Title isSelected={isSelected}>{title}</Title>
      <Description>{description}</Description>
    </QuotationBoxOverview>
  )
}