import { IOPTags } from "./types";

export interface QuotationService {
  BackToBack?: boolean;
  Code?: null | string;
  CostCurrencyCode?: null | string;
  CostCurrencyName?: null | string;
  CostExchangeRate?: null | number;
  CreatedAt?: null | string;
  CreatedBy?: null | string;
  EndDateTime?: null | string;
  Id?: null | number;
  IsActive?: boolean;
  IsCharge?: boolean;
  ModifiedAt?: null | string;
  ModifiedBy?: null | string;
  Name?: string;
  OPTags?: null | IOPTags;
  PriceCurrencyCode?: null | string;
  PriceCurrencyName?: null | string;
  PriceExchangeRate?: null | number;
  PriceSellingExRate?: null | number;
  RowOrder?: number;
  StartDateTime?: string;
  SupplierCode?: string;
  SupplierName?: string;
}
export class Service implements QuotationService {
  BackToBack?: boolean;
  Code?: null | string;
  CostCurrencyCode?: null | string;
  CostCurrencyName?: null | string;
  CostExchangeRate?: null | number;
  CreatedAt: null | string;
  CreatedBy: null | string;
  EndDateTime?: null | string;
  Id?: null | number;
  IsActive?: boolean;
  IsCharge: boolean;
  ModifiedAt: null | string;
  ModifiedBy: null | string;
  Name?: string;
  OPTags?: null | IOPTags;
  PriceCurrencyCode?: null | string;
  PriceCurrencyName?: null | string;
  PriceExchangeRate?: null | number;
  PriceSellingExRate?: null | number;
  RowOrder?: number;
  StartDateTime?: string;
  SupplierCode?: string;
  SupplierName?: string;

  constructor() {
    this.BackToBack = false;
    this.Code = "";
    this.CostCurrencyCode = ""
    this.CostCurrencyName = ""
    this.CostExchangeRate = null;
    this.CreatedAt = "";
    this.CreatedBy = "";
    this.StartDateTime = "";
    this.EndDateTime = "";
    this.IsActive = true;
    this.IsCharge = false;
    this.ModifiedAt = "";
    this.ModifiedBy = "";
    this.ModifiedAt = "";
    this.ModifiedBy = "";
    this.Name = "";
    this.OPTags = null;
    this.PriceCurrencyCode = "";
    this.PriceCurrencyName = "";
    this.PriceExchangeRate = null;
    this.Id = null;
    this.RowOrder = 0;
    this.SupplierCode = "";
    this.SupplierName = "";
    this.PriceSellingExRate = null;
    this.PriceSellingExRate = null;
  }
}




