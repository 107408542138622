import axios from 'axios';
import { getToken } from '../Utils/AuthConfig';
const mdmAppUrl = process.env.REACT_APP_MDM_API_URL;
const subscriptionKey = process.env.REACT_APP_SUBSCRIPTION_KEY;
const CancelToken = axios.CancelToken;

let cancel: any;

const mdmAxios = axios.create({
  baseURL: mdmAppUrl,
  headers: {
    "Ocp-Apim-Subscription-Key": subscriptionKey ? subscriptionKey : '',
    "Ocp-Apim-Trace": "true",
    "Authorization": `Bearer ${getToken('accessToken')}`
  },
  cancelToken: new CancelToken(function executor(c) {
    cancel = c;
  }),
});



export default mdmAxios;
