
import React from 'react';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';

interface IRadioProps {
  id: string;
  name?: string;
  label?: string;
  option: string;
  onChange: any;
  value: string;
  disabled?: boolean;
  checked?: boolean;
  className?: string;
  style?: any;
}
export default function PegRadioButton(props: IRadioProps) {
  const { id, name, onChange, label, value, disabled, checked, option, style } = props;
  return (
    <div className="control-pane">
      <div className="control-section">
        <div className="radiobutton-control" style={style}>
          <h4>{label}</h4>
          <div className="row">
            <RadioButtonComponent
              id={id}
              data-testid="radioTest"
              checked={checked}
              label={option}
              name={name}
              value={value}
              disabled={disabled}
              onChange={onChange} />
          </div>
        </div>
      </div>
    </div>
  );
}
