import { VesselData } from "./types"

export const SECTIONS = {
    KEYITEMS: "keyItems",
    VESSELDETAILS: "vesselDetails",
    VESSELMEASURES: "vesselMeasures"
}

export const COPIED_INDICES = {
    keyItems: undefined,
    vesselDetails: undefined,
    vesselMeasures: undefined,
}

export const getKeyItems = (vesselInfo: VesselData | undefined) => vesselInfo ? ([{
    title: "Vessel Name",
    data: vesselInfo?.name ?? "",
},
{
    title: "Flag",
    data: vesselInfo?.flag ?? "",
}, {
    title: "Previous Names",
    data: vesselInfo?.previousNames ?? "",
}, {
    title: "IMO Number",
    data: vesselInfo?.imoNumber ?? "",
}]) : []

export const getVesselDetails = (vesselInfo: VesselData | undefined) => vesselInfo ? ([{
    title: "Vessel Owner Name",
    data: vesselInfo?.vesselOwner ?? "",
},
{
    title: "Charterer Name",
    data: vesselInfo?.chartererName ?? "",
}, {
    title: "MMSI",
    data: vesselInfo?.mmsi ?? "",
}, {
    title: "Registry Number",
    data: vesselInfo?.registryNumber ?? "",
}, {
    title: "PI Club Party",
    data: vesselInfo?.piClubName ?? "",
}, {
    title: "Call Sign",
    data: vesselInfo?.callSign ?? "",
}, {
    title: "Registry Date",
    data: vesselInfo?.registryDate ?? "",
}, {
    title: "Classification Society Party",
    data: vesselInfo?.classificationSocietyName ?? "",
}]) : []

export const getVesselMeasures = (vesselInfo: VesselData | undefined) => vesselInfo ? ([{
    title: "LOA",
    data: vesselInfo?.loa ?? "",
},
{
    title: "DWT",
    data: vesselInfo?.dwt ?? "",
},
{
    title: "Max Draft",
    data: vesselInfo?.maxDraft ?? "",
},
{
    title: "Reduced Gross Tonnage",
    data: vesselInfo?.reducedGrossTonnage ?? "",
},
{
    title: "Gross Tonnage",
    data: vesselInfo?.grossTonnage ?? "",
},
{
    title: "Formula DWT",
    data: vesselInfo?.formulaDWT ?? "",
},
{
    title: "Suez Net Tonnage",
    data: vesselInfo?.suezNetTonnage ?? "",
},
{
    title: "Geared Vessel Indicator",
    data: vesselInfo?.gearedVessel ?? "",
},
{
    title: "Net Tonnage",
    data: vesselInfo?.netTonnage ?? "",
},
{
    title: "Summer DWT",
    data: vesselInfo?.summerDWT ?? "",
},
{
    title: "Air Draft",
    data: vesselInfo?.gearedVessel ?? "",
},
{
    title: "Panama Net Tonnage",
    data: vesselInfo?.panamaNetTonnage ?? "",
},
{
    title: "LPP",
    data: vesselInfo?.lpp ?? "",
},
{
    title: "Beam Extreme",
    data: vesselInfo?.beamExtreme ?? "",
},
{
    title: "Suez NRT",
    data: vesselInfo?.suezNRT ?? "",
}]) : []