
import React from 'react';
import {
  service,
} from 'Model/Order/Services';
import {
  Servives,
  OptionName
} from './services.styles'
import ServiceGrid from './ServiceGrid'

type MyState = {
  serviceList: service[]
};

type MyProps = {
  store?: any;
  updateServices: (services: any) => void;
  updateVesselServices: (id: any, services: any) => void;
  vesselDetails?: any;
};

class ServiceList extends React.Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      serviceList: []
    };
  }


  updateOptionsList = (id: number, serviceList: any) => {
    this.props.updateVesselServices(id, serviceList);
  }

  render() {
    const {
      vesselDetails,
    } = this.props;

    return (
      <Servives>
        {
          vesselDetails.map((item: any, index: number) => {
            let routeName = (item.RouteDetail.length > 0 && item.RouteDetail[0].PortName) ? item.RouteDetail[0].PortName : `Option - ${index + 1}`;
            return (
              <div key={index}>
                <OptionName>{routeName}</OptionName>
                <ServiceGrid
                  index={index}
                  serviceList={item.ServiceDetail}
                  updateServices={(id: number, serviceList: any) => this.updateOptionsList(id, serviceList)}
                />
              </div>
            )
          })
        }
      </Servives>
    );
  }
}

export default ServiceList;
