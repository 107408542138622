import { ColumnPreferences } from "Model/Common/ColumnPreferences";

export const JOB_COLUMN: ColumnPreferences = {
  ID: { label: 'Id', isActive: true, isDefault: true },
  NAME: { label: 'Name', isActive: true, isDefault: true },
  CUSTOMER_SERVICE: { label: 'Customer Service', isActive: true, isDefault: false },
  START_DATE: { label: 'Start Date', isActive: true, isDefault: false },
  END_DATE: { label: 'End Date', isActive: true, isDefault: false },
  OPERATING_INCOME: { label: 'Operating Income', isActive: true, isDefault: false },
  BILLING_PARTY: { label: 'Billing Party', isActive: true, isDefault: true },
  BILLING_PARTY_ADDRESS: { label: 'Billing Party Address', isActive: true, isDefault: false },
  UOM: { label: 'Price UOM', isActive: true, isDefault: true },
  QUANTITY: { label: 'Price Qty', isActive: true, isDefault: true },
  UNIT_PRICE: { label: 'Unit Price', isActive: true, isDefault: true },
  CURRENCY: { label: 'Price Curr', isActive: true, isDefault: false },
  AMOUNT: { label: 'Price', isActive: true, isDefault: false },
  BILLING_CURRENCY: { label: 'Billing Curr', isActive: true, isDefault: false },
  EX_RATE: { label: 'Bill Ex Rate', isActive: true, isDefault: false },
  TAX_RATE: { label: 'Tax Rate', isActive: true, isDefault: false },
  SALES_TAX_AMT: { label: 'Bill Tax', isActive: true, isDefault: false },
  BILL_AMT: { label: 'Bill', isActive: true, isDefault: false },
  ADJ_BILL_AMT: { label: 'Adjusted Bill', isActive: true, isDefault: false },
  SUPPLIER: { label: 'Supplier', isActive: true, isDefault: true },
  RECIEVED_QTY: { label: 'Recieved Qty', isActive: true, isDefault: false },
  COST_UOM: { label: 'Cost UOM', isActive: true, isDefault: true },
  COST_QTY: { label: 'Cost Qty', isActive: true, isDefault: true },
  UNIT_COST: { label: 'Unit Cost', isActive: true, isDefault: true },
  COST_CURRENCY: { label: 'Cost Curr', isActive: true, isDefault: false },
  COST_EX_RATE: { label: 'Cost Ex Rate', isActive: true, isDefault: false },
  COST_TAX_RATE: { label: 'Cost Tax Rate', isActive: true, isDefault: false },
  PURCHASE_TAX: { label: 'Cost Tax', isActive: true, isDefault: false },
  AMT: { label: 'Est Cost', isActive: true, isDefault: false },
  AMT_WITH_TAX: { label: 'Total Est Cost', isActive: true, isDefault: false },
  SUPPLIER_INVOICE: { label: 'Supplier Inv Doc', isActive: true, isDefault: false },
  CREDIT_NOTE: { label: 'Credit Note Doc', isActive: true, isDefault: false },
  CREDIT_AMT: { label: 'Credit Note', isActive: true, isDefault: false },
  ACTUAL_COST: { label: 'Actual Cost', isActive: true, isDefault: false },
  ACTUAL_COST_EXCHANGE_RATE: { label: 'EyeShare Ex-Rate', isActive: true, isDefault: false },
  IS_VOUCHER_NEEDED: { label: 'Is Voucher', isActive: true, isDefault: false },
  VOUCHER_TYPE: { label: 'Voucher Type', isActive: true, isDefault: false },
  VOUCHER_NUMBER: { label: 'Voucher Number', isActive: true, isDefault: false },
  BACK_TO_BACK: { label: 'Back to Back', isActive: true, isDefault: false },
  SR_NO: { label: 'SR Number', isActive: true, isDefault: false },
  SR_STATUS: { label: 'SR Status', isActive: true, isDefault: false },
  SERVICE_STATUS: { label: 'Service Status', isActive: true, isDefault: false },
  IS_BILLABLE: { label: 'Job Revenue', isActive: true, isDefault: false },
  BILLING_STATUS: { label: 'Billing Status', isActive: true, isDefault: false },
  BILLING_METHOD: { label: 'Billing Method', isActive: true, isDefault: false },
  PAYMENT_TERM: { label: 'Payment Terms', isActive: true, isDefault: false },
  SALES_INVOICE: { label: 'Sales Invoice', isActive: true, isDefault: false },
  INTERNAL_REMARKS: { label: 'Internal Remarks', isActive: true, isDefault: false },
  EXTERNAL_REMARKS: { label: 'External Remarks', isActive: true, isDefault: false },
  BILLING_REMARKS: { label: 'Billing Remarks', isActive: true, isDefault: false }
};



export const QUOTATION_COLUMN: ColumnPreferences = {
  ID: { label: 'Id', isActive: true, isDefault: true },
  NAME: { label: 'Name', isActive: true, isDefault: true },
  CUSTOMER_SERVICE: { label: 'Customer Service', isActive: true, isDefault: false },
  BILLING_PARTY: { label: 'Billing Party', isActive: true, isDefault: true },
  BILLING_PARTY_ADDRESS: { label: 'Billing Party Address', isActive: true, isDefault: false },
  START_DATE: { label: 'Start Date', isActive: true, isDefault: false },
  END_DATE: { label: 'End Date', isActive: true, isDefault: false },
  OPERATING_INCOME: { label: 'Operating Income', isActive: true, isDefault: false },
  UOM: { label: 'UOM', isActive: true, isDefault: true },
  QUANTITY: { label: 'Quantity', isActive: true, isDefault: true },
  UNIT_PRICE: { label: 'Unit Price', isActive: true, isDefault: true },
  PRICE_CURRENCY: { label: 'Price Curr', isActive: true, isDefault: false },
  NET_PRICE: { label: 'Price', isActive: true, isDefault: false },
  TAX_RATE: { label: 'Tax Rate', isActive: true, isDefault: false },
  TAX_AMT: { label: 'Price Tax', isActive: true, isDefault: false },
  NET_PRICE_LOCAL: { label: 'Total Price - Local', isActive: true, isDefault: false },
  NET_PRICE_SELLING: { label: 'Total Price - Selling', isActive: true, isDefault: false },
  SUPPLIER: { label: 'Supplier', isActive: true, isDefault: true },
  UNIT_COST: { label: 'Unit Cost', isActive: true, isDefault: true },
  COST_CURRENCY: { label: 'Cost Curr', isActive: true, isDefault: false },
  COST_TAX_RATE: { label: 'Cost Tax Rate', isActive: true, isDefault: false },
  PURCHASE_TAX: { label: 'Cost Tax', isActive: true, isDefault: false },
  AMT: { label: 'Amount', isActive: true, isDefault: true },
  AMT_WITH_TAX: { label: 'Amount with Tax', isActive: true, isDefault: true },
  TOTAL_COST: { label: 'Total Cost', isActive: true, isDefault: false },
  TOTAL_COST_LOCAL: { label: 'Total Cost - Local', isActive: true, isDefault: false },
  BACK_TO_BACK: { label: 'Back to Back', isActive: true, isDefault: false },
  INTERNAL_REMARKS: { label: 'Internal Remarks', isActive: true, isDefault: false },
  EXTERNAL_REMARKS: { label: 'External Remarks', isActive: true, isDefault: false },
};
