import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { LoaderErrorMessage, LoaderOverview } from 'Containers/JobGrid/Jobs.styles'
import Loader from 'Components/Loader'
import { HeaderIcon, CloseIcon, CopyIcon, SuccessIcon, MesuresIcon } from './icons'
import { SECTIONS, getKeyItems, getVesselDetails, getVesselMeasures, COPIED_INDICES } from "./constants"
import { VesselData } from "./types"
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline'
import { useRouteMatch } from 'react-router'

interface QuotationSummaryProps {
    onClose: () => void
    showVesselMeasureModal: boolean
}

const RenderLoading = ({ error }: { error: boolean }) => <LoaderOverview data-testid="jobgridLoader" role="jobgridLoader">
    {error && <LoaderErrorMessage>Could not load vessel details</LoaderErrorMessage>}

    <Loader
        size={50}
        color={'#adccf4'}
    />
</LoaderOverview>

const Header = ({ title, icon = <HeaderIcon />, open = false }: { title: string, icon?: JSX.Element, open?: boolean }) => <div className='flex gap-3 items-center bg-primary-blue p-3 justify-between w-full'>
    <div className='flex items-center gap-2'>
        {icon || <HeaderIcon />}
        <h1 className='font-bold text-base text-white'>{title}</h1>
    </div>

    <ChevronDownIcon
        color='white'
        className={`${open ? 'rotate-180 transform' : ''
            } h-5 w-5 text-purple-500`}
    />
</div>

function QuotationSummary({ onClose, showVesselMeasureModal }: QuotationSummaryProps) {
    const [copiedIndices, setCopiedIndices] = useState<any>(COPIED_INDICES);

    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<boolean>(false)
    const [vesselInfo, setVesselInfo] = useState<VesselData>()

    const router = useRouteMatch()

    const jobDetails = useSelector((state: any) => state.JobServices.jobDetails)
    const quotationDetails = useSelector((state: any) => state.QuoteGrid.quotationDetails)

    const VesselImoNumber = useMemo(() => (router.path.includes('job-services') && jobDetails?.VesselImoNumber) || (router.path.includes('quotation-services') && quotationDetails.VesselImoNumber), [jobDetails, router.path, quotationDetails])

    const getVesselInfo = async () => {
        try {
            const { data } = await axios.get(`/mdm-get-vessel?code=${VesselImoNumber}`)

            setVesselInfo(data[0])
            setLoading(false)
        } catch (err) {
            console.log("[!] getVesselInfo Error: ", err)

            setLoading(false)
            setError(true)
        }
    }

    useEffect(() => {
        getVesselInfo()
    }, [jobDetails])

    const _renderKeyMesurements = () =>
        <Disclosure>
            {({ open }) => (
                <>
                    <Disclosure.Button className="w-full">
                        <Header title="KEY MEASUREMENTS" open={open} />

                    </Disclosure.Button>
                    <Disclosure.Panel className="text-gray-500">
                        <div className='flex flex-col px-4 divide-y-2'>
                            {getKeyItems(vesselInfo).map((i, idx) =>
                                <div className='flex justify-between py-3 items-center' key={idx} style={{ borderColor: "rgba(0, 0, 0, 0.10)" }}>
                                    <h1 className='text-dark-tint2 text-sm font-bold'>{i.title}</h1>
                                    <span className='text-dark-tint2 text-sm font-normal'>{i.data}</span>
                                </div>)}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>

    const _renderVesselDetails = () =>
        <Disclosure>
            {({ open }) => (
                <>
                    <Disclosure.Button className="w-full">
                        <Header title="VESSEL MAIN DETAILS" open={open} />

                    </Disclosure.Button>
                    <Disclosure.Panel className="text-gray-500">
                        <div className='flex flex-col px-4 divide-y-2'>
                            {getVesselDetails(vesselInfo).map((i, idx) =>
                                <div className='flex justify-between py-3 items-center' key={idx} style={{ borderColor: "rgba(0, 0, 0, 0.10)" }}>
                                    <h1 className='text-dark-tint2 text-sm font-bold'>{i.title}</h1>
                                    <span className='text-dark-tint2 text-sm font-normal'>{i.data}</span>
                                </div>)}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>

    const _renderVesselMeasures = () =>
        <Disclosure>
            {({ open }) => (
                <>
                    <Disclosure.Button className="w-full">
                        <Header title="VESSEL MEASURES" icon={<MesuresIcon />} open={open} />
                    </Disclosure.Button>
                    <Disclosure.Panel className="text-gray-500">
                        <div className='flex flex-col px-4 divide-y-2'>
                            {getVesselMeasures(vesselInfo).map((i, idx) =>
                                <div className='flex justify-between py-3 items-center' key={idx} style={{ borderColor: "rgba(0, 0, 0, 0.10)" }}>
                                    <h1 className='text-dark-tint2 text-sm font-bold'>{i.title}</h1>
                                    <span className='text-dark-tint2 text-sm font-normal'>{i.data}</span>
                                </div>)}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>

    return (
        <Transition appear show={showVesselMeasureModal} as={Fragment}>
            <Dialog as="div" className="relative z-100" onClose={() => { }}>
                <div className="fixed h-full overflow-y-auto" style={{ zIndex: 100, width: "500px", inset: "0 0 auto auto", boxShadow: "-3px 1px 5px 0px rgba(0, 0, 0, 0.25)" }}>
                    <div className="flex min-h-full justify-end">
                        <Dialog.Panel className="w-full max-w-full transform bg-white transition-all min-h-full" >
                            <div className='flex justify-between bg-secondary-teal px-8 py-4 items-center h-auto'>
                                <h1 className='font-bold uppercase text-2xl'>Vessel Dimension</h1>
                                <button onClick={onClose}><CloseIcon /></button>
                            </div>

                            {loading ? <RenderLoading error={error} /> : <Fragment>
                                <div className='p-3 flex flex-col gap-3'>
                                    {_renderKeyMesurements()}
                                    {_renderVesselDetails()}
                                    {_renderVesselMeasures()}
                                </div>
                            </Fragment>}
                        </Dialog.Panel>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default QuotationSummary