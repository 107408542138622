import styled from 'styled-components'
import CircleLoader from "react-spinners/BounceLoader";
import GridLoader from "react-spinners/GridLoader";

interface Prop {
  size?: any;
  color?: any;
  gridLoader?: boolean;
}

const Loader = (props: Prop) => {
  const {
    size,
    color,
    gridLoader,
  } = props
  if (gridLoader) {
    return (
      <Overview data-testid="gridLoader">
        <GridLoader
          size={size}
          color={color}
        />
      </Overview>
    );
  }
  return (
    <Overview data-testid="circleLoader">
      <CircleLoader
        size={size}
        color={color}
      />
    </Overview>
  );
};

export default Loader;

const Overview = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
`
