
import React from 'react';
import Header from './Components/Header';
import ServiceTable from './Components/ServiceTable';
import {
  ServiceGridOverview,
  ServiceGridOptions,
  TableOverview,
  GridOverview,
} from './services.styles';
import {
  serviceObject,
  chargeObject,
  bulkEditTypes
} from 'Constants/Constant';
import PegButton from 'Controls/Button/PegButton';
import { setServiceValue, editServiceObject } from 'Utils/Services';
import PegModal from 'Controls/PegModal'
import EditForm from 'Containers/ServiceGrid/Components/EditForm';


type MyState = {
  serviceList: any[];
  validated: boolean;
  selectedList: any[];
  isAllSelected: boolean;
  expandAll: boolean;
  showDeleteModal: boolean;
  showEditModal: boolean;
  bulkEdit?: any;
};

type MyProps = {
  index: number;
  store?: any;
  updateServices: (id: any, services: any) => void;
  serviceList: any[];
};

class ServiceGrid extends React.Component<MyProps, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      serviceList: [],
      validated: true,
      selectedList: [],
      isAllSelected: false,
      expandAll: false,
      showEditModal: false,
      showDeleteModal: false,
      bulkEdit: bulkEditTypes
    };
  }

  componentDidMount() {
    this.initializeServiceList();
  }

  initializeServiceList = () => {
    let { serviceList } = this.props;
    this.setState({ serviceList: this.props.serviceList })
  };

  onAddService = () => {
    let { updateServices, index } = this.props;
    let { serviceList } = this.state;
    serviceList = [
      ...serviceList,
      {
        ...serviceObject,
        ServiceDetailId: serviceList.length + 1,
        SortOrder: serviceList.length + 1,
        charges: [
          {
            ...chargeObject,
            ServiceDetailId: 1,
            SortOrder: 1
          },
        ],
      },
    ];
    this.setState({ serviceList }, () => updateServices(index, serviceList));
  };

  deleteService = (service: any) => {
    let { serviceList } = this.state;
    let { updateServices, index } = this.props;
    if (serviceList.length > 1) {
      serviceList = serviceList
        .filter((item) => {
          return item.ServiceDetailId !== service.ServiceDetailId;
        })
        .map((item: any, index: number) => {
          return {
            ...item,
            ServiceDetailId: index + 1,
          };
        });
      this.setState({ serviceList }, () => updateServices(index, serviceList));
    }
  };

  onExpandService = (service: any) => {
    let { serviceList } = this.state;
    const id = service.ServiceDetailId - 1;
    let serviceObject = { ...serviceList[id] };
    let tempService: any = {
      ...serviceObject,
      showCharges: !serviceObject.showCharges,
    };
    serviceList[id] = tempService;
    this.setState({ serviceList });
  };

  onAddCharge = (service: any) => {
    let { serviceList } = this.state;
    let { updateServices, index } = this.props;
    const id = service.ServiceDetailId - 1;
    let serviceObject: any = serviceList[id];
    serviceObject = {
      ...serviceObject,
      showCharges: true,
      charges: [
        ...serviceObject.charges,
        { ...chargeObject, ServiceDetailId: serviceObject.charges.length + 1 },
      ],
    };
    serviceList[id] = serviceObject;
    this.setState({ serviceList }, () => updateServices(index, serviceList));
  };

  deleteCharge = (service: any, charge: any) => {
    let { serviceList } = this.state;
    let { updateServices, index } = this.props;
    serviceList = serviceList.map((item) => {
      if (item.ServiceDetailId === service.ServiceDetailId) {
        let chargeArray =
          item.charges && item.charges.length > 1
            ? item.charges
              .filter((itemChild: { ServiceDetailId: any; }) => itemChild.ServiceDetailId !== charge.ServiceDetailId)
              .map((item: any, index: number) => {
                return {
                  ...item,
                  ServiceDetailId: index + 1,
                };
              })
            : item.charges;
        return {
          ...item,
          charges: chargeArray,
        };
      } else {
        return item;
      }
    });
    this.setState({ serviceList }, () => updateServices(index, serviceList));
  };

  onChangeValue = (event: any, key: any, type: string, service: any, charge: any) => {
    const { serviceList } = this.state;
    const { updateServices, index } = this.props
    let selectedId = service.ServiceDetailId - 1;
    let selectedTempService = serviceList[selectedId];
    if (charge) {
      let chargeId = charge.ServiceDetailId - 1;
      let tempSelectedCharge: any = selectedTempService.charges[chargeId];
      let tempObject = setServiceValue(tempSelectedCharge, key, event)
      selectedTempService.charges[chargeId] = tempObject;
    } else {
      let tempObject = setServiceValue(selectedTempService, key, event)
      serviceList[selectedId] = tempObject
    }
    this.setState({ serviceList }, () => updateServices(index, serviceList));
  };

  onPostRequest = async () => {
    const {
      serviceList
    } = this.state
    let isValidated = await this.checkServiceValidation(serviceList)
    this.setState({ validated: isValidated })
    if (isValidated) {
      this.postServiceRequest()
    }
  }

  checkServiceValidation = async (serviceList: any[]) => {
    var isValidated = true;
    await serviceList.forEach((item: any) => {
      let validationObject: object = item.validation;
      item.charges.forEach((charge: any) => {
        Object.keys(charge.validation).forEach(function (key) {
          if (!charge[key] || !Object.keys(charge[key]).length) {
            isValidated = false;
          }
        })
      })
      Object.keys(validationObject).forEach(function (key) {
        if (!item[key] || !Object.keys(item[key]).length) {
          isValidated = false;
        }
      })
    })
    return isValidated;
  }

  postServiceRequest = () => {
    // let services: any = this.constructServiceObject();
  }

  constructServiceObject = (list: any) => {
    let alteredList: any = list
    return alteredList;
  }

  onSelectOptions = (service: any, charge: any) => {
    let {
      selectedList
    } = this.state
    // check whether service presents
    const isServicePresent = selectedList.find((o) => (o.ServiceDetailId === service.ServiceDetailId));
    if (charge) {
      let selectedService: any;
      let chargeArray: any;
      if (!isServicePresent) {
        selectedService = { ServiceDetailId: service.ServiceDetailId, isSelected: false, charges: [] }
        chargeArray = selectedService.charges;
      } else {
        selectedService = selectedList.find((child: any) => child.ServiceDetailId === service.ServiceDetailId);
        chargeArray = selectedService.charges;
      }
      let isChargePresent = chargeArray.find((child: any) => child.ServiceDetailId === charge.ServiceDetailId);
      if (isChargePresent) {
        chargeArray = chargeArray.filter((item: any) => item.ServiceDetailId !== charge.ServiceDetailId);
      } else {
        chargeArray.push({ ServiceDetailId: charge.ServiceDetailId });
      }
      // adding this because if length of service wont be there if you dont select service
      if (selectedList.length) {
        selectedList = selectedList.map(item => {
          if (item.ServiceDetailId === selectedService.ServiceDetailId) {
            return {
              ...item,
              charges: [...chargeArray]
            }
          } else {
            return item
          }
        })
      } else {
        selectedList.push(selectedService)
      }
      this.setState({ selectedList })
    } else {
      if (!isServicePresent) {
        selectedList.push({ ServiceDetailId: service.ServiceDetailId, isSelected: true, charges: [] });
      } else {
        selectedList = selectedList.filter(item => item.ServiceDetailId !== service.ServiceDetailId);
      }
      this.setState({ selectedList })
    }

  }


  deleteSelectedItems = () => {
    let {
      serviceList,
      selectedList,
      isAllSelected
    } = this.state
    if (isAllSelected) {
      this.setState({ serviceList: [], isAllSelected: false, showDeleteModal: false })
    } else {
      let tempList = serviceList.filter(({ ServiceDetailId: id1 }) => !selectedList.some(({ ServiceDetailId: id2 }) => id2 === id1)).map((item, index) => {
        return {
          ...item,
          ServiceDetailId: index + 1
        }
      })
      this.setState({
        serviceList: tempList,
        isAllSelected: false,
        showDeleteModal: false,
        selectedList: []
      })
    }
  }

  onDeleteModalConfirm = () => {
    this.deleteSelectedItems()
  }

  onDeleteModalCancel = () => {
    this.setState({ showDeleteModal: false, selectedList: [], isAllSelected: false })
  }

  onSelectAll = () => {
    this.setState({ isAllSelected: !this.state.isAllSelected },
      () => this.setSelectedArray(this.state.isAllSelected))
  }



  setSelectedArray = (isAllSelected: boolean) => {
    // const {
    //   services
    // } = this.props
    // if (isAllSelected) {
    //   let list: any = []
    //   services.forEach((item: any, index: number) => {
    //     list.push(index)
    //   })
    //   this.setState({ selectedList: list })
    // } else {
    //   this.setState({ selectedList: [] })
    // }
  }


  onExpand = () => {
    let {
      serviceList,
      expandAll
    } = this.state
    serviceList = serviceList.map((service: any) => {
      return {
        ...service,
        showCharges: !expandAll
      }
    })
    this.setState({ serviceList, expandAll: !this.state.expandAll })
  }

  onEditServices = (event: any, key: any) => {
    let {
      bulkEdit
    } = this.state
    let bulkEditObject = editServiceObject(bulkEdit, key, event)
    this.setState({ bulkEdit: bulkEditObject })
  }

  onDeleteOption = () => {
    this.setState({ showDeleteModal: true })
  }

  onEditOption = () => {
    this.setState({ showEditModal: true })
  }

  onEditModalConfirm = () => {
    let {
      selectedList,
      bulkEdit,
      serviceList
    } = this.state
    let selectedIds = selectedList.map(item => item.ServiceDetailId)
    let tempObj = bulkEdit;
    Object.keys(bulkEdit).forEach((key) => {
      if (!bulkEdit[key]) {
        delete bulkEdit[key];
      }
    });
    serviceList = serviceList.map(item => {
      if (selectedIds.includes(item.ServiceDetailId)) {
        return {
          ...item,
          ...tempObj
        }
      } else {
        return item
      }
    })
    this.setState({ serviceList, showEditModal: false, bulkEdit: bulkEditTypes })
  }

  onEditModalCancel = () => {
    this.setState({ showEditModal: false, bulkEdit: bulkEditTypes })
  }

  render() {
    const {
      serviceList,
      validated,
      isAllSelected,
      selectedList,
      showDeleteModal,
      showEditModal,
    } = this.state;

    return (
      <GridOverview id="serviceGrid" style={{ maxWidth: '100%' }}>
        <ServiceGridOverview>
          <ServiceGridOptions>
            <PegButton
              id="servicegrid_addrow"
              type="button"
              variant="Primary"
              onClick={this.onAddService}>
              Add Services
            </PegButton>
            {
              (selectedList && selectedList.length > 0 || isAllSelected) &&
              <PegButton
                id="servicegrid_addrow"
                type="button"
                variant="Danger"
                styleObject={{ marginLeft: '12px' }}
                onClick={this.onDeleteOption}>
                Delete
              </PegButton>
            }

            {
              (selectedList && selectedList.length > 0 && !isAllSelected) &&
              <PegButton
                id="servicegrid_addrow"
                type="button"
                variant="Primary"
                styleObject={{ marginLeft: '12px' }}
                onClick={this.onEditOption}>
                Edit
              </PegButton>
            }

          </ServiceGridOptions>
          <TableOverview>
            <Header
              onSelectAll={() => this.onSelectAll()}
              isAllSelected={isAllSelected}
              onExpand={this.onExpand}
            />
            {serviceList.map((service: any, index: any) => {
              return (
                <ServiceTable
                  key={index}
                  onChangeDropdown={(event: any, key: any, type: string, service: any, charge: any) => this.onChangeValue(event, key, type, service, charge)}
                  onChangeDate={(event: any, key: any, type: string, service: any, charge: any) => this.onChangeValue(event, key, type, service, charge)}
                  onChangeInput={(event: any, key: any, type: string, service: any, charge: any) => this.onChangeValue(event, key, type, service, charge)}
                  service={service}
                  onAddCharge={(service: number) => this.onAddCharge(service)}
                  deleteCharge={(service: any, charge: any) => this.deleteCharge(service, charge)}
                  deleteService={(service: any) => this.deleteService(service)}
                  onExpandService={(service: any) => this.onExpandService(service)}
                  isAllSelected={isAllSelected}
                  selectedList={selectedList}
                  onSelectOptions={(service: any, charge: any) => this.onSelectOptions(service, charge)}
                />
              );
            })}
          </TableOverview>
          {
            <PegModal
              isOpen={showDeleteModal}
              closeModal={this.onDeleteModalCancel}
              tertiaryAction={this.onDeleteModalConfirm}
              modalTitle={"Delete Items"}
              buttonText={"Delete"}
              modalContent={"Are you sure to delete the selected items"}
            />
          }
          {
            <PegModal
              isOpen={showEditModal}
              closeModal={this.onEditModalCancel}
              tertiaryAction={this.onEditModalConfirm}
              modalTitle={"Edit Items"}
              buttonText={"Save"}
              isCenter={true}
              showTemplate={true}>
              <EditForm
                onEditServices={(event: any, key: any) => this.onEditServices(event, key)}
              />
            </PegModal>
          }
        </ServiceGridOverview>
      </GridOverview>
    );
  }
}

export default ServiceGrid;
function index(index: any, serviceList: any[]): void {
  throw new Error('Function not implemented.');
}

